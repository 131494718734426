/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import { useParams } from "react-router-dom";
import moment from "moment";
import {
  logTitle,
  taskStatusGenerate,
  canTenantReviewMaintenance,
} from "../helper";
import { useDispatch } from "react-redux";
import GLOBAL from "../../../../../setup/constants/global";
/* Import configuration ends */

/* Import redux slices component starts */
import {
  showSuccessAlert,
  showErrorAlert,
} from "../../../../../setup/store/slices/globalAlertSlice";
import {
  tenantRespondToT1Maintenance,
  tenantRespondToT2Maintenance,
  t2tenantRespondToPartyMaintenance,
  tenantRespondtoMaintenance,
  tenantRespondToPartyMaintenance,
} from "../../../../../setup/store/slices/maintenanceSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import CustomButton from "../../../../ui/button/CustomButton/CustomButton";
import Image from "../../../../ui/other/Image/Image";
import FileViewerModal from "../../../../ui/modal/FileViewerModal/FileViewerModal";
import NoteModal from "../../../Maintenance/NoteModal/NoteModal";
import AddMaintenanceInspectionReport from "../../../Maintenance/AddMaintenanceInspectionReport/AddMaintenanceInspectionReport";
/* Import local pages and component ends */

/* Component starts */
const TaskLog = (props) => {
  /* Props destructuring starts */
  const {
    isT2,
    isT1,
    status,
    maintenance,
    item,
    takeAction,
    maintenance_id,
    property,
    user,
  } = props;

  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [file, setFile] = useState(false);
  const [showFile, setShowFile] = useState(false);
  const [showCommentModal, setShowCommentModal] = useState(false);
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const dispatch = useDispatch();
  const { propertyId } = useParams();
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  const taskList = maintenance?.phase2_URIC?.items;
  const name = item?.requester
    ? `${item?.requester?.first_name} ${item?.requester?.last_name}`
    : `${item.tenant.first_name} ${item.tenant.last_name}`;
  const role = item?.requester_role?.name;
  const createdAt = item?.createdAt;
  const action = item?.action;
  const currentStatus = item?.current_status;
  const logType = item?.log_type;
  const comment = item?.comment;
  const maintenanceType = maintenance?.type;

  const USER_DATA = localStorage.getItem("user_data")
    ? JSON.parse(localStorage.getItem("user_data"))
    : null;
  const DATE_FORMAT = USER_DATA ? USER_DATA?.date_format : "";
  const TIME_FORMAT = USER_DATA ? USER_DATA?.time_format : "";
  const DATE_TIME_FORMAT = `${DATE_FORMAT} ${TIME_FORMAT}`;
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  // On tenant responding maintenance task
  const tenantReviewingT1Maintenance = async (status = true, v) => {
    // Creating or collecting payload data to be sent
    let payload = {
      maintenance_id,
      type: maintenance?.type,
      status: status ? "accepted" : "rejected",
    };

    if (!status) {
      payload = { ...payload, ...v };
    }
    console.log("Payload", payload);
    // Backend response. Try, Catch
    try {
      let result = {};
      if (isT1) {
        result = await dispatch(tenantRespondToT1Maintenance(payload));
      } else if (isT2) {
        if (maintenance.assigned_role.name === "Facility Manager") {
          result = await dispatch(tenantRespondToT2Maintenance(payload));
        } else {
          result = await dispatch(t2tenantRespondToPartyMaintenance(payload));
        }
      } else {
        if (maintenance.assigned_role.name === "Facility Manager") {
          result = await dispatch(tenantRespondtoMaintenance(payload));
        } else {
          result = await dispatch(tenantRespondToPartyMaintenance(payload));
        }
      }
      console.log("Response", result);

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          dispatch(
            showErrorAlert({
              title: "Error!",
              description: result.payload,
            })
          );
          break;

        case GLOBAL.FULFILLED:
          dispatch(
            showSuccessAlert({
              title: "Success!",
              description: result.payload.message,
            })
          );
          break;

        default:
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };

  // On action with submitting comment
  const actionWithNote = async (note) => {
    setShowCommentModal(false);

    // On tenant rejecting maintenance task
    canTenantReviewMaintenance(item, user) &&
      tenantReviewingT1Maintenance(false, note);
  };
  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="time-line task-log bg_grey">
      {/* Dot */}
      <div className="dot-wrapper">
        <i className="dot"></i>
        <div className="vl"></div>
      </div>

      {/* Title */}
      <div className="detail-wrapper">
        <h3 className="title font_m fw_6">{logTitle(item)}</h3>

        {/* Technician Name and Date */}
        <div className="black-container-sm fw_5 font_s bg_black flex-between">
          <p className="left">
            <span className="font_grey">{role} </span>
            <span className="font_white"> {name}</span>
          </p>
          <p className="right font_grey">
            {moment(createdAt).format(DATE_TIME_FORMAT)}
          </p>
        </div>

        {/* Task List */}
        {true && (
          <>
            <div className="spare-part-quote black-container-lg fw_5 font_s">
              {currentStatus === "rejected_by_tenant" ||
              currentStatus === "visit_scheduled" ? (
                <p className="sub-title font_m fw_6">Rejected Description</p>
              ) : (
                <p className="sub-title font_m fw_6">Task Completed</p>
              )}

              <div className="list-wrapper">
                {item?.data?.items?.map((e, i) => (
                  <div className="complete-task-details" key={i}>
                    {i > 0 && <hr className="divider" />}
                    <h3 className="task-number-txt font_l fw_6 font_blue">
                      {currentStatus === "rejected_by_tenant"
                        ? "Rejection Item "
                        : "Task "}
                      #{i + 1}
                    </h3>
                    <div className="row">
                      <p className="key font_s font_grey">Description</p>
                      <p className="value font_s font_white">
                        {e?.description}
                      </p>
                    </div>
                    {e?.documents?.length > 0 && (
                      <div className="row">
                        <p className="key font_s font_grey">Attachments</p>

                        <div className="value">
                          <div className="attachments">
                            {e?.documents?.map((doc, index) => (
                              <Image
                                key={index}
                                src={doc?.url}
                                videoThumbnail={doc?.type2 === "video"}
                                onClick={() => {
                                  setFile(doc);
                                  setShowFile(true);
                                }}
                                alt="Attachments"
                                objectFit="cover"
                                className="attachment-document-wrapper"
                                imgClass="attachment-document"
                              />
                            ))}
                          </div>
                        </div>
                      </div>
                    )}

                    {e?.documents === undefined && (
                      <p className="value font_s font_yellow">
                        No Attachments Added!
                      </p>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </>
        )}

        {/* Log Footer */}
        <div className="log-footer">
          {/* Action Buttons */}
          {takeAction && (
            <div className="button-wrapper">
              {/* Tenant reviewing maintenance task */}
              {canTenantReviewMaintenance(item, user) && (
                <>
                  <CustomButton
                    onClick={() => tenantReviewingT1Maintenance()}
                    size="m"
                    variant="yellow"
                  >
                    Accept
                  </CustomButton>
                  <CustomButton
                    onClick={() => setShowCommentModal(true)}
                    size="m"
                    variant="red"
                  >
                    Reject
                  </CustomButton>
                </>
              )}
            </div>
          )}
        </div>
      </div>

      {/* Modals */}
      {/* File Viewer Modal */}
      <FileViewerModal
        show={showFile}
        file={file.url}
        type={file.type}
        onClose={() => setShowFile(false)}
      />

      {/* Note Modal */}
      {/* <NoteModal
        title="Reason for rejection"
        inputPlaceholder="Comment"
        showModal={showCommentModal}
        showError={true}
        btnText="Submit"
        actionWithNote={actionWithNote}
        onCancel={() => setShowCommentModal(false)}
      /> */}

      <AddMaintenanceInspectionReport
        showModal={showCommentModal}
        onClose={() => setShowCommentModal(false)}
        onAddReport={(v) => tenantReviewingT1Maintenance(false, v)}
        title="Reject Maintenance"
      />
    </div>
  );
};
/* Component ends */

export default TaskLog;
