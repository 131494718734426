/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import { useDispatch, useSelector } from "react-redux";
import GLOBAL from "../../../../setup/constants/global";
import moment from "moment";
import { renderApplicationStatus } from "./helper";
/* Import configuration ends */

/* Import redux slices component starts */
import { createTransitionedInvoicesByInstalments } from "../../../../setup/store/slices/tenantSlice";
import {
  showErrorAlert,
  showSuccessAlert,
} from "../../../../setup/store/slices/globalAlertSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import RentDetails from "../NewTenantPMReview/sub-component/RentDetails";
import CustomButton from "../../../ui/button/CustomButton/CustomButton";
import ContractDetail from "../NewTenantPMReview/sub-component/ContractDetail";
import BasicDetails from "../NewTenantPMReview/sub-component/BasicDetails";
import CancelResendInvitation from "./sub-component/CancelResendInvitation";
import Status from "./sub-component/Status";
import ContractPDF from "./sub-component/ContractPDF";
/* Import local pages and component ends */

import "./style.scss";
import UnitDetails from "../NewTenantPMReview/sub-component/UnitDetails";

/* Component starts */
const TransitionedTenantInstalment = (props) => {
  /* Props destructuring starts */
  const {
    currency,
    getApplication,
    sequence = [],
    sequenceNumber = [],
    application,
    application_id,
    property,
  } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const dispatch = useDispatch();
  const isAcc = localStorage.getItem("user_role") === "Accountant";
  const isOwner = localStorage.getItem("user_role") === "Owner";
  const isPM = localStorage.getItem("user_role") === "Property Manager";
  const isLO = localStorage.getItem("user_role") === "Lease Officer";
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  const hasAttachedCheques = () => {
    if (application.installment_plan?.cheque && application.installments) {
      const installments = application.installments;
      for (let i = 0; i < installments?.length; i++) {
        if (
          !installments[i].cheque &&
          !getIsDuePassed(installments[i].due_date)
        ) {
          dispatch(
            showErrorAlert({
              title: "Error!",
              description: "Please attach cheque to all upcoming invoices.",
            })
          );
          return false;
        }
      }
    }
    return true;
  };

  const getIsDuePassed = (dueDate) => {
    return moment(dueDate).isBefore(moment());
  };

  const generateInvoices = async () => {
    if (!hasAttachedCheques()) return;

    // Backend response. Try, Catch
    try {
      const result = await dispatch(
        createTransitionedInvoicesByInstalments({ application_id })
      );
      console.log("Response", result);

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          dispatch(
            showErrorAlert({
              title: "Error!",
              description: result?.payload || "",
            })
          );
          break;

        case GLOBAL.FULFILLED:
          dispatch(
            showSuccessAlert({
              title: "Success!",
              description: result?.payload?.message || "",
            })
          );
          getApplication();
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };
  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="container_md transitioned-tenant-accountant-view">
      <div className="my-2">
        <UnitDetails details={application?.application?.unit} index="1" />
      </div>

      {sequence?.map((seq) => {
        switch (seq) {
          case "basic_details":
            return (
              <>
                <BasicDetails
                  details={application?.basic_details}
                  index={sequenceNumber?.indexOf("basic_details") + 2}
                />
              </>
            );

          case "cancel_resend_invitation":
            return (
              <>
                <CancelResendInvitation
                  application={application?.application}
                  getApplication={getApplication}
                />
              </>
            );

          case "invitation_canceled":
            return (
              <>
                <Status text="Invitation Cancelled" type="red" />
              </>
            );

          case "pending_tenant_inv_confirmation":
            return (
              <>
                <Status text="Pending Tenant Invoice Confirmation" />
              </>
            );

          case "installments":
            return (
              <>
                <RentDetails
                  isTransitioned={true}
                  canViewCheque={true}
                  data={application?.installments}
                  canEdit={false}
                  canSend={false}
                  application_id={application_id}
                  instalmentPlan={application?.installment_plan}
                  currency={currency}
                  canAttachCheque={isAcc && application?.can_attach_cheque}
                  onUpdateChequeCallback={getApplication}
                  manuallyFetchPropertySettings={false}
                  manuallyFetchPropertyData={false}
                  property_settings={application?.property_settings}
                  property_data={property}
                  index={
                    sequenceNumber?.indexOf("installment_plan")
                      ? sequenceNumber?.indexOf("installment_plan") + 1
                      : sequenceNumber?.indexOf("installment_plan") + 2
                  }
                />

                {(isOwner || isPM || isLO) &&
                  application.can_send_installment_plan &&
                  application?.application?.status !==
                    "invitation_canceled" && (
                    <div className="generate-inv-btn-wrapper">
                      <CustomButton
                        size="l"
                        variant="yellow"
                        onClick={generateInvoices}
                      >
                        Generate Invoices
                      </CustomButton>
                    </div>
                  )}
              </>
            );

          case "pending_invoice_clearance":
            return (
              <>
                <Status text="Pending invoice clearance. Kindly clear previous invoices" />
              </>
            );

          case "contract_pdf":
            return (
              <>
                <ContractPDF
                  contract={application?.contract_docs?.contract_pdf}
                  index={sequence?.indexOf("contract_pdf") + 2}
                />
              </>
            );

          // case "contract_details":
          //   return (
          //     <>
          //       <ContractDetail
          //         invoices={application.billing_invoices}
          //         index={sequenceNumber.indexOf("contract") + 2}
          //       />
          //     </>
          //   );

          case "invitation_expired":
            return (
              <>
                <Status type="red" text="Invitation Expired" />
              </>
            );

          default:
        }
      })}
    </div>
  );
};
/* Component ends */

export default TransitionedTenantInstalment;
