/**
 *
 */

import React, { useEffect, useState } from "react";

/* Import configuration starts */
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useParams, useNavigate } from "react-router-dom";
import GLOBAL from "../../setup/constants/global";
/* Import configuration ends */

/* Import redux slices component starts */
import {
  fetchInfoTilesListProperty,
  getPropertyInfoTilesList,
} from "../../setup/store/slices/infoTileSlice";
import {
  getPropertyDetail,
  fetchPropertyDetailByID,
} from "../../setup/store/slices/propertySlice";
import { setLoading } from "../../setup/store/slices/unpersistedSlice";
import {
  showSuccessAlert,
  showErrorAlert,
} from "../../setup/store/slices/globalAlertSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import MUI component starts */
/* Import MUI component ends */

/* Import image and SVG starts */
import { BlankImagePlaceholder } from "../../setup/constants/images";
/* Import image and SVG ends */

/* Import local pages and component starts */
import Details from "./local-components/Details";
import Modules from "./local-components/Modules";
import Header from "./local-components/Header";
import Image from "../../components/ui/other/Image/Image";
import OverviewSlider from "../../components/module/Overview/OverviewSlider/OverviewSlider";
import BreadCrumb from "../../components/ui/other/BreadCrumb/BreadCrumb";
/* Import local pages and component ends */

import "./style.scss";
import PropertyImageSlider from "./local-components/PropertyImageSlider";

/* Component starts */
const PropertyDetail = () => {
  /* Props destructuring starts */
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [InfoTiles, setInfoTiles] = useState([]);

  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const navigate = useNavigate();
  const { underCommunity, communityId, propertyId } = useParams();
  const dispatch = useDispatch();
  const propertyInfo = useSelector(getPropertyInfoTilesList);
  const propertyDetail = useSelector(getPropertyDetail);
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  const image = propertyDetail?.image?.url || BlankImagePlaceholder;
  const mgmtMissing =
    propertyDetail?.missing_items?.includes("management_model");
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  /* Component function definition ends */

  /* Component useEffect starts */
  useEffect(() => {
    if (propertyId) {
      (async () => {
        try {
          await dispatch(setLoading(true));
          await dispatch(
            fetchInfoTilesListProperty({ property_id: propertyId })
          );

          const result = await dispatch(
            fetchPropertyDetailByID({ property_id: propertyId })
          );
          switch (result.meta.requestStatus) {
            case GLOBAL.REJECTED:
              dispatch(
                showErrorAlert({
                  title: "Error!",
                  description:
                    typeof result?.payload === "string" ? result?.payload : "",
                })
              );
              navigate("/");
              break;

            case GLOBAL.FULFILLED:
              break;
          }

          await dispatch(setLoading(false));
        } catch (e) {}
      })();
    }
  }, [propertyId]);

  useEffect(() => {
    if (propertyInfo) {
      const info = propertyInfo;
      setInfoTiles(info);
    }
  }, [propertyInfo]);

  /* Component useEffect ends */

  // if (mgmtMissing) {
  //   if (underCommunity === "community")
  //     return (
  //       <Navigate
  //         replace={true}
  //         to={`/community/${communityId}/properties/${propertyId}/management_type/add`}
  //       />
  //     );
  //   return (
  //     <Navigate
  //       replace={true}
  //       to={`/independent/properties/${propertyId}/management_type/add `}
  //     />
  //   );
  // }

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="property-detail-page-wrapper">
      <BreadCrumb type="property_detail" />

      {propertyDetail && Object.keys(propertyDetail).length > 0 && (
        <div className="container_lg">
          <h1 className="font_xl fw_6 ">Property Overview</h1>
          <div className="slider">
            <OverviewSlider propInfoTiles={InfoTiles} type="property" />
          </div>

          <Header property={propertyDetail} />

          <div className="detail-box">
            <div className="image">
              <Image
                className="inner-img w_100 h_100"
                src={image}
                objectFit="cover"
                objectPosition="center center"
                alt="Property Image"
              />

              {/* Image Slider */}
              {/* <PropertyImageSlider images={propertyDetail?.property_images} /> */}
            </div>

            <Details detail={propertyDetail} />
          </div>
          <Modules property={propertyDetail} />
        </div>
      )}
    </div>
  );
};
/* Component ends */

export default PropertyDetail;
