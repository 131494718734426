/*
 * Slice to handle SECURITY
 */

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios, { axiosInstance } from "../axios";
import { API_URL } from "../../config/devKeys";
import {
  setLoading,
  setShowUploadProgress,
  setUploadProgress,
} from "./unpersistedSlice";
import { PLATFORM } from "../../platformUtil";

const initialState = {
  // complainTypes: [],
  routineTasks: [],
  routineTaskTickets: [],
  // secuirtyIncidents: [],
  // tenantsList: [],
  // companyList: [],
  // teamMembersList: [],
  // securityOfficersList: [],
  // tenantSecuirtyComplains: [],
  routineTask: null,
  routineTaskTicket: null,
};

// FETCH ROUTINE REQUEST TASKS LIST - Client Based On Property
export const fetchRoutineTasks = createAsyncThunk(
  "routine/list",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("user_token")}`,
        "Content-Type": "application/json",
      };

      const response = await fetch(API_URL + "/routine/list", {
        method: "POST",
        headers: headers,
        body: JSON.stringify({
          property_id: payload?.property_id,
          status: payload?.status || "Active",
          page: payload?.page,
        }),
      });

      const data = await response.json();
      dispatch(setLoading(false));
      if (data.success) {
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

// ADD Routine Request Task
export const addRoutineTask = createAsyncThunk(
  "routine/create_task",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const formData = new FormData();
      Object.keys(payload).map((key) => {
        if (key === "document") {
          if (PLATFORM !== "web") {
            payload[key].forEach((file) => {
              formData.append(key, {
                name: file.name,
                type: file.type,
                uri:
                  PLATFORM === "android"
                    ? file.uri
                    : file.uri.replace("file://", ""),
              });
            });
          } else {
            payload[key].forEach((file) => {
              formData.append(key, file, file.name);
            });
          }
        } else {
          formData.append(key, payload[key]);
        }
      });

      const headers = {
        Authorization: `Bearer ${localStorage.getItem("user_token")}`,
      };
      if (PLATFORM !== "web") {
        headers["Content-Type"] = "multipart/form-data";
      }

      // const response = await fetch(
      //   // API_URL + "/tenant_security_request/add",
      //   'http://localhost:3000/tenant_security_request/add',
      //   {
      //   method: "POST",
      //   headers: headers,
      //   body: formData,
      // });
      // const data = await response.json();
      dispatch(setShowUploadProgress(true));
      const config = {
        onUploadProgress: ({ loaded, total }) => {
          const progress = (loaded / total) * 100;
          dispatch(setUploadProgress(progress));
        },
        method: "POST",
        url: "/routine/create_task",
        headers,
        transformRequest: (data, headers) => {
          return formData;
        },
        data: formData,
        responseType: "json",
      };

      // const data = await response.json();

      const { data } = await axiosInstance.request(config);

      dispatch(setLoading(false));

      if (data.success) {
        // await dispatch(fetchTenantSecurityComplaints());
        await dispatch(fetchRoutineTasks(payload.property_id));
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));

      return rejectWithValue(error.message);
    }
  }
);

// GET Routine Task Detail Based on ID
export const fetchRoutineTaskOnId1 = createAsyncThunk(
  "routine/get",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      console.log(payload, "[999]");

      dispatch(setLoading(true));
      // const { data } = await axios.post(
      //   `${API_URL}/routine/get?id=${payload.id}`,
      //   {
      //     headers: {
      //       Authorization: `Bearer ${localStorage.getItem("user_token")}`,
      //     },
      //   }
      // );

      const headers = {
        Authorization: `Bearer ${localStorage.getItem("user_token")}`,
        "Content-Type": "application/json",
      };

      const response = await fetch(API_URL + `routine/get?id=${payload.id}`, {
        method: "POST",
        headers: headers,
      });

      const data = await response.json();

      dispatch(setLoading(false));
      if (data.success) {
        return data.data;
      } else {
        rejectWithValue(data.error);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

export const fetchRoutineTaskOnId = createAsyncThunk(
  "routine/get",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      console.log(payload, "[000]");

      dispatch(setLoading(true));
      const { data } = await axios.post(
        `${API_URL}/routine/get_routine_detail`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      dispatch(setLoading(false));
      if (data.success) {
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

// FETCH ROUTINE REQUEST TASKS LIST - Client Based On Property
export const updateRoutineTaskAsExpired = createAsyncThunk(
  "routine/update_complaint",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("user_token")}`,
        "Content-Type": "application/json",
      };

      const response = await fetch(API_URL + "/routine/update_complaint", {
        method: "POST",
        headers: headers,
        body: JSON.stringify({
          routine_id: payload?.routine_id,
          // status: payload?.status,
        }),
      });

      const data = await response.json();
      dispatch(setLoading(false));
      if (data.success) {
        await dispatch(fetchRoutineTasks(payload));
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

// Based on the Particular task - cron generated tickets Flow

// FETCH THE PROPERTY LEVEL ROUTINE TASK -> Generated Tickets  LIST
export const fetchRoutineTaskGeneratedTicketsList = createAsyncThunk(
  "routine/list_Task_Tickets",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      console.log(payload, "[1020]");

      const headers = {
        Authorization: `Bearer ${localStorage.getItem("user_token")}`,
        "Content-Type": "application/json",
      };

      const response = await fetch(API_URL + "/routine/list_Task_Tickets", {
        method: "POST",
        headers: headers,
        body: JSON.stringify({
          property_id: payload?.property_id,
          status: payload?.status || "Open",
          page: payload?.page,
          requester: payload?.requester,
        }),
      });

      const data = await response.json();
      dispatch(setLoading(false));
      if (data.success) {
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

// GET Routine Task - Ticket Detail Based on ID
export const fetchRoutineTaskTicketBasedOnId = createAsyncThunk(
  "routine/get_Task_TicketsDetail",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const { data } = await axios.post(
        `${API_URL}/routine/get_Task_TicketsDetail`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      dispatch(setLoading(false));
      if (data.success) {
        return data.data;
      } else {
        rejectWithValue(data.error);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

// Assigned Member Update Task Ticket With Report & Description
export const assignedMemberUpdateTaskTicket = createAsyncThunk(
  "routine/assigned_MemberUpdate_TaskTicket",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const formData = new FormData();
      Object.keys(payload).map((key) => {
        if (key === "document") {
          if (PLATFORM !== "web") {
            payload[key].forEach((file) => {
              formData.append(key, {
                name: file.name,
                type: file.type,
                uri:
                  PLATFORM === "android"
                    ? file.uri
                    : file.uri.replace("file://", ""),
              });
            });
          } else {
            payload[key].forEach((file) => {
              formData.append(key, file, file.name);
            });
          }
        } else {
          formData.append(key, payload[key]);
        }
      });

      const headers = {
        Authorization: `Bearer ${localStorage.getItem("user_token")}`,
      };
      if (PLATFORM !== "web") {
        headers["Content-Type"] = "multipart/form-data";
      }
      dispatch(setShowUploadProgress(true));
      const config = {
        onUploadProgress: ({ loaded, total }) => {
          const progress = (loaded / total) * 100;
          dispatch(setUploadProgress(progress));
        },
        method: "POST",
        url: "/routine/assigned_MemberUpdate_TaskTicket",
        headers,
        transformRequest: (data, headers) => {
          return formData;
        },
        data: formData,
        responseType: "json",
      };

      // const data = await response.json();

      const { data } = await axiosInstance.request(config);

      dispatch(setLoading(false));

      if (data.success) {
        await dispatch(
          fetchRoutineTaskTicketBasedOnId({
            routine_ticket_id: payload?.routine_ticket_id,
          })
        );
        // await dispatch(fetchRoutineTasks(payload.property_id));
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));

      return rejectWithValue(error.message);
    }
  }
);

// Update Routine Ticket Task - Ticket Report Approved By Requester
export const reviewUpdatedTaskTicket = createAsyncThunk(
  "routine/review_UpdatedTaskTicket",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const { data } = await axios.post(
        `${API_URL}/routine/review_UpdatedTaskTicket`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      dispatch(setLoading(false));
      if (data.success) {
        await dispatch(
          fetchRoutineTaskTicketBasedOnId({
            routine_ticket_id: payload?.routine_ticket_id,
          })
        );
        return data.data;
      } else {
        rejectWithValue(data.error);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

// EDIT Routine Request Task
export const EditRoutineTask = createAsyncThunk(
  "routine/edit_routineTask",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const formData = new FormData();
      Object.keys(payload).map((key) => {
        if (key === "document") {
          if (PLATFORM !== "web") {
            payload[key].forEach((file) => {
              formData.append(key, {
                name: file.name,
                type: file.type,
                uri:
                  PLATFORM === "android"
                    ? file.uri
                    : file.uri.replace("file://", ""),
              });
            });
          } else {
            payload[key].forEach((file) => {
              formData.append(key, file, file.name);
            });
          }
        } else {
          formData.append(key, payload[key]);
        }
      });

      const headers = {
        Authorization: `Bearer ${localStorage.getItem("user_token")}`,
      };
      if (PLATFORM !== "web") {
        headers["Content-Type"] = "multipart/form-data";
      }

      // const response = await fetch(
      //   // API_URL + "/tenant_security_request/add",
      //   'http://localhost:3000/tenant_security_request/add',
      //   {
      //   method: "POST",
      //   headers: headers,
      //   body: formData,
      // });
      // const data = await response.json();
      dispatch(setShowUploadProgress(true));
      const config = {
        onUploadProgress: ({ loaded, total }) => {
          const progress = (loaded / total) * 100;
          dispatch(setUploadProgress(progress));
        },
        method: "POST",
        url: `routine/edit_routineTask?id=${payload?.taskId}`,
        headers,
        transformRequest: (data, headers) => {
          return formData;
        },
        data: formData,
        responseType: "json",
      };

      // const data = await response.json();

      const { data } = await axiosInstance.request(config);

      dispatch(setLoading(false));

      if (data.success) {
        await dispatch(fetchRoutineTaskOnId({ routine_id: payload.taskId }));
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));

      return rejectWithValue(error.message);
    }
  }
);

export const routineSlice = createSlice({
  name: "routine",
  initialState,
  extraReducers: {
    [fetchRoutineTasks.rejected]: (state, action) => {
      state.routineTasks = [];
    },
    [fetchRoutineTasks.fulfilled]: (state, action) => {
      state.routineTasks = action.payload;
    },

    [fetchRoutineTaskGeneratedTicketsList.rejected]: (state, action) => {
      state.routineTaskTickets = [];
    },
    [fetchRoutineTaskGeneratedTicketsList.fulfilled]: (state, action) => {
      state.routineTaskTickets = action.payload;
    },

    [fetchRoutineTaskOnId.rejected]: (state, action) => {
      state.routineTask = null;
    },
    [fetchRoutineTaskOnId.fulfilled]: (state, action) => {
      state.routineTask = action.payload;
    },

    [fetchRoutineTaskTicketBasedOnId.rejected]: (state, action) => {
      state.routineTaskTicket = null;
    },
    [fetchRoutineTaskTicketBasedOnId.fulfilled]: (state, action) => {
      state.routineTaskTicket = action.payload;
    },
  },
});

// FETCH THE PROPERTY LEVEL ROUTINE TASKS LIST
export const getRoutineTaskLists = (state) => state.routine.routineTasks;

// FETCH THE PROPERTY LEVEL ROUTINE TASK -> Generated Tickets  LIST
export const getRoutineTaskTicketLists = (state) =>
  state.routine.routineTaskTickets;

// To Fetch The Task Details Based On ID
export const getRoutineTaskData = (state) => state.routine.routineTask;

// To Fetch The Task ticket Details Based On ID
export const getRoutineTaskTicketData = (state) =>
  state.routine.routineTaskTicket;

export default routineSlice.reducer;
