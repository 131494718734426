/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import { Link, useLocation, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
/* Import configuration ends */

/* Import redux slices component starts */
import {
  fetchUserRoles,
  getUserRoles,
} from "../../../setup/store/slices/masterSlice";
import { fetchUser, getUser } from "../../../setup/store/slices/authSlice";
import {
  fetchAnnoucementsList,
  fetchAnnoucementsListBasedCommunity,
  fetchAnnoucementsListBasedProperty,
  fetchUsersAnnoucementsList,
  getAnnoucementsList,
  getCommunityAnnoucementsList,
  getPropertyAnnoucementsList,
  getUsersAnnoucementsList,
} from "../../../setup/store/slices/annoucementSlice";
import { setLoading } from "../../../setup/store/slices/unpersistedSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import Searchbar from "../../../components/ui/other/Searchbar/Searchbar";
import AnnoucementsTable from "../../../components/module/Annoucemnets/AnnoucementsTable/AnnoucementsTable";
import BreadCrumb from "../../../components/ui/other/BreadCrumb/BreadCrumb";
/* Import local pages and component ends */

// styles sheet
import "./style.scss";
import {
  fetchPropertyDetailByID,
  getPropertyDetail,
} from "../../../setup/store/slices/propertySlice";

/* Component starts */
const Annoucements = (props) => {
  /* Props destructuring starts */
  const {} = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [annoucements, setAnnoucements] = useState();
  const [role, setRole] = useState();

  const [currentPage, setCurrentPage] = useState(1);

  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const { pathname } = useLocation();
  const param = useParams();

  const dispatch = useDispatch();
  const userRoles = useSelector(getUserRoles);
  const user = useSelector(getUser);
  const annoucementList = useSelector(getAnnoucementsList);
  const propertyAnnoucementList = useSelector(getPropertyAnnoucementsList);
  const communityAnnoucementList = useSelector(getCommunityAnnoucementsList);
  const propertyDetail = useSelector(getPropertyDetail);
  // const userAnnoucementList = useSelector(getUsersAnnoucementsList);
  /* Other hooks declaration ends */

  // Redux Selectors

  /* Component variable declaration / object destructure starts */

  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  const loadAnnoucementList = async () => {
    if (pathname.includes("communities")) {
      await dispatch(
        fetchAnnoucementsListBasedCommunity({
          community_id: param?.communityId,
          page: currentPage,
        })
      );
    }
    // else if (pathname.includes("independent") && role !== "Client") {
    //   await dispatch(
    //     fetchUsersAnnoucementsList({
    //       property_id: param?.propertyId,
    //       page: currentPage,
    //     })
    //   );
    // }
    else if (pathname.includes("independent")) {
      await dispatch(
        fetchAnnoucementsListBasedProperty({
          property_id: param?.propertyId,
          page: currentPage,
        })
      );
    } else {
      await dispatch(fetchAnnoucementsList());
    }
  };
  /* Component function definition ends */

  /* Component useEffect starts */

  useEffect(() => {
    loadAnnoucementList();
  }, [pathname]);

  useEffect(() => {
    // if (pathname.includes("independent") && userAnnoucementList) {
    //   setAnnoucements(userAnnoucementList);
    // } else
    if (pathname.includes("communities") && communityAnnoucementList) {
      setAnnoucements(communityAnnoucementList);
    } else if (pathname.includes("independent") && propertyAnnoucementList) {
      setAnnoucements(propertyAnnoucementList);
    } else {
      setAnnoucements(annoucementList);
    }
  }, [
    annoucementList,
    pathname,
    communityAnnoucementList,
    propertyAnnoucementList,
    // userAnnoucementList,
  ]);

  useEffect(() => {
    (async () => {
      try {
        await dispatch(setLoading(true));
        await dispatch(fetchUserRoles());
        await dispatch(fetchUser());
        await dispatch(
          fetchPropertyDetailByID({ property_id: param?.propertyId })
        );
        await dispatch(setLoading(false));
      } catch (e) {}
    })();
  }, []);

  // Setting role after all user roles loaded
  useEffect(() => {
    let userRole = userRoles.filter((d) => d._id === user?.role_id)[0]?.name;
    setRole(userRole);
  }, [userRoles]);

  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */

  return (
    <>
      <BreadCrumb type="client_annoucement" />
      <div className="annoucement-wrapper">
        <div className="heading-wrapper">
          <div className="heading">
            <h2 className="font_xxl fw_5">Announcements</h2>
          </div>

          {/* (role === "Client" ||
            role === "Property Manager" ||
            role === "Owner")  */}

          {(((role === "Client" || role === "Facility Manager") &&
            propertyDetail?.mgmt_model === 1) ||
            ((role === "Client" || role === "Owner") &&
              propertyDetail?.mgmt_model === 0) ||
            ((role === "Client" || role === "Property Manager") &&
              propertyDetail?.mgmt_model === 2)) && (
            <div className="register-wrapper">
              <Link to="add" className="font_m fw_4 d_block link-security">
                + Create Announcement
              </Link>
            </div>
          )}
        </div>

        {/* <Searchbar /> */}

        <AnnoucementsTable
          annoucementListData={
            annoucements?.result ? annoucements?.result : annoucements
          }
          totalCount={
            annoucements?.totalCount ? annoucements?.totalCount : null
          }
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </div>
    </>
  );
};
/* Component End */

export default Annoucements;
