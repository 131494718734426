/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
/* Import configuration ends */

/* Import redux slices component starts */
import { setLoading } from "../../../../setup/store/slices/unpersistedSlice";
import {
  fetchPropertyDetailByID,
  getPropertyDetail,
} from "../../../../setup/store/slices/propertySlice";
import {
  fetchCommunityDetailByID,
  getCommunityDetail,
} from "../../../../setup/store/slices/communitySlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import { skipLink, renderKey } from "./helper";
/* Import local pages and component ends */

import "./style.scss";

/* Component starts */
const BreadCrumb = (props) => {
  /* Props destructuring starts */
  const { type, from } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [pathSplit, setPathSplit] = useState([]);
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const dispatch = useDispatch();
  const propertyDetail = useSelector(getPropertyDetail);
  const communityDetail = useSelector(getCommunityDetail);
  const { propertyId, communityId } = useParams();
  const location = useLocation();
  console.log(location, "[Location]");
  const pathSegments = location?.pathname
    ?.split("/")
    ?.filter((segment) => segment !== ""); // Split path segments

  const pathSegmentsPrev = location?.state?.pathname
    ?.split("/")
    ?.filter((segment) => segment !== ""); // Split path segments
  console.log(pathSegments, "SEG", pathSegmentsPrev);
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  const homeCrumb = ["dashboard", "annoucements", "advertising"];
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  const renderPropertyName = (p) => {
    return p?.property_name || "Property";
  };

  const renderCommunityName = (c) => {
    return c?.community_name || "Community";
  };
  /* Component function definition ends */

  /* Component useEffect starts */
  useEffect(() => {
    if (propertyId) {
      (async () => {
        try {
          await dispatch(setLoading(true));
          await dispatch(fetchPropertyDetailByID({ property_id: propertyId }));
          await dispatch(setLoading(false));
        } catch (e) {}
      })();
    }

    if (communityId) {
      dispatch(fetchCommunityDetailByID({ community_id: communityId }));
    }
  }, [propertyId, communityId]);
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="bread_cumb_wrapper container_lg">
      <ul className="custom-bread-crumb">
        {!pathSegments?.includes("home") ? (
          <>
            <li>
              <Link className="font_m fw_5" to="/">
                Home
              </Link>
            </li>
          </>
        ) : homeCrumb?.some((i) => pathSegments?.includes(i)) ? (
          <>
            <li>
              <Link className="font_m fw_5" to="/">
                Home
              </Link>
            </li>
          </>
        ) : (
          ""
        )}
        {location?.state !== null && location?.state?.pathname ? (
          <>
            {pathSegmentsPrev?.map((segment, index, array) => {
              // Construct the path for each breadcrumb item
              if (skipLink(segment, index, array, type, from)) {
                const breadcrumbPath = `/${pathSegmentsPrev
                  .slice(0, index + 1)
                  .join("/")}`;
                return (
                  <li key={index}>
                    <span className="seperator">&gt;</span>
                    <Link
                      className="font_m fw_5 link-color"
                      to={breadcrumbPath}
                    >
                      {renderKey(segment, index, array, type, from) ===
                      "[[property]]" ? (
                        <>{renderPropertyName(propertyDetail)}</>
                      ) : renderKey(segment, index, array, type, from) ===
                        "[[community]]" ? (
                        <>{renderCommunityName(communityDetail)}</>
                      ) : (
                        <>{renderKey(segment, index, array, type, from)}</>
                      )}
                    </Link>
                  </li>
                );
              }
            })}
          </>
        ) : (
          <>
            {pathSegments?.map((segment, index, array) => {
              // Construct the path for each breadcrumb item
              if (skipLink(segment, index, array, type, from)) {
                const breadcrumbPath = `/${pathSegments
                  .slice(0, index + 1)
                  .join("/")}`;

                return (
                  <li key={index}>
                    <span className="seperator">&gt;</span>
                    <Link
                      className="font_m fw_5 link-color"
                      to={breadcrumbPath}
                    >
                      {renderKey(segment, index, array, type, from) ===
                      "[[property]]" ? (
                        <>{renderPropertyName(propertyDetail)}</>
                      ) : renderKey(segment, index, array, type, from) ===
                        "[[community]]" ? (
                        <>{renderCommunityName(communityDetail)}</>
                      ) : (
                        <>{renderKey(segment, index, array, type, from)}</>
                      )}
                    </Link>
                  </li>
                );
              }
            })}
          </>
        )}
      </ul>
    </div>
  );
};
/* Component ends */

export default BreadCrumb;
