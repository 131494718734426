/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
/* Import configuration ends */

/* Import redux slices component starts */
import {
  fetchT2TenantInfoDetail,
  fetchTenantInfoDetail,
  getTenantInfoDetail,
} from "../../setup/store/slices/tenatDetailSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import Detail from "./local-components/Detail";
import DetailTables from "./local-components/DetailTables";
import BreadCrumb from "../../components/ui/other/BreadCrumb/BreadCrumb";
/* Import local pages and component ends */

// Styles
import "./style.scss";
import {
  fetchPropertyDetailByID,
  getPropertyDetail,
} from "../../setup/store/slices/propertySlice";
import { setLoading } from "../../setup/store/slices/unpersistedSlice";

/* Component starts */
const TenantDetailPage = (props) => {
  /* Props destructuring starts */
  const {} = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const dispatch = useDispatch();
  const { propertyId, tenantId, unitId } = useParams();
  const tenantInfo = useSelector(getTenantInfoDetail);
  const propertyDetail = useSelector(getPropertyDetail);
  // const unitId = searchParams.get("unit_id");
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  /* Component function definition ends */

  /* Component useEffect starts */
  useEffect(() => {
    if (propertyId && tenantId) {
      let payload = {
        propertyId,
        tenantId,
        unitId,
      };

      if (propertyDetail?.mgmt_model === 1) {
        dispatch(fetchT2TenantInfoDetail(payload));
      } else {
        dispatch(fetchTenantInfoDetail(payload));
      }
    }
  }, [propertyId, tenantId, unitId]);

  useEffect(() => {
    (async () => {
      try {
        await dispatch(setLoading(true));

        await dispatch(fetchPropertyDetailByID({ property_id: propertyId }));
        await dispatch(setLoading(false));
      } catch (e) {}
    })();
  }, []);

  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <>
      <BreadCrumb type="exixting_tenant_detail" />
      <div className="tenant-detail-page-wrapper container_md">
        <div className="header">
          <h3 className="font_xxl fw_6 text-center">Tenants Details</h3>
        </div>
        <Detail tenantInfo={tenantInfo} />

        {/* <UnitDetails /> */}

        <DetailTables
          unitInfo={tenantInfo?.unit_info[0]}
          propertyId={propertyId}
        />
      </div>
    </>
  );
};
/* Component ends */

export default TenantDetailPage;
