/**
 * @auther Abdul Ahad <abdulahad.ss@smartdatainc.net>
 * Slice to manage unit
 */

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios, { axiosInstance } from "../axios";
import { PLATFORM } from "../../platformUtil";
import { API_URL } from "../../config/devKeys";
import {
  setLoading,
  setShowUploadProgress,
  setUploadProgress,
} from "./unpersistedSlice";

const initialState = {
  units: [],
  unitDetail: {},
  unitMaintenances: [],
  unitHistory: [],
  vacantUnits: [],
};

export const fetchUnitDetailByID = createAsyncThunk(
  "unit/fetch_detail_by_id",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    const { unit_id } = payload;
    try {
      dispatch(setLoading(true));
      const { data } = await axios.post(
        API_URL + "/unit_card/get",
        { id: unit_id },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      if (data.success) {
        dispatch(setLoading(false));
        return data.data;
      } else {
        dispatch(setLoading(false));
        return rejectWithValue(data.error);
      }
    } catch (error) {
      return rejectWithValue(error?.response.message);
    }
  }
);

export const fetchCanInviteTenant = createAsyncThunk(
  "unit/can_invite_tenant",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const { data } = await axios.get(
        payload?.unit_id
          ? `${API_URL}/unit_card/can_invite_tenant?unit_id=${payload.unit_id}`
          : `${API_URL}/unit_card/can_invite_tenant?property_id=${payload.property_id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      if (data.success) {
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      return rejectWithValue(error?.response.message);
    }
  }
);

export const fetchUnits = createAsyncThunk(
  "unit/list",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const { data } = await axios.post(
        API_URL + "/unit_card/list",

        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      if (data.success) {
        dispatch(setLoading(false));
        return data.data;
      } else {
        dispatch(setLoading(false));
        return rejectWithValue(data.error);
      }
    } catch (error) {
      return rejectWithValue(error?.response.message);
    }
  }
);

export const canInviteTransitionedTenantinUnit = createAsyncThunk(
  "unit_card/is_transitioned_allowed",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const { data } = await axios.get(
        API_URL + `/unit_card/is_transitioned_allowed?unit_id=${payload}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      if (data.success) {
        dispatch(setLoading(false));
        return data.data;
      } else {
        dispatch(setLoading(false));
        return rejectWithValue(data.error);
      }
    } catch (error) {
      return rejectWithValue(error?.response.message);
    }
  }
);

export const fetchUnitHistoryById = createAsyncThunk(
  "unit/get_unit_history",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const { data } = await axios.post(
        API_URL + "/unit_card/get_unit_details",
        // "http://localhost:3000/unit_card/get_unit_details",
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      if (data.success) {
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      return rejectWithValue(error?.response.message);
    }
  }
);

export const addUnit = createAsyncThunk(
  "unit/add",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const { property_id } = payload;
      dispatch(setLoading(true));
      const { data } = await axios.post(API_URL + "/unit_card/add", payload, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("user_token")}`,
        },
      });
      dispatch(setLoading(false));
      if (data.success) {
        dispatch(fetchUnits({ property_id }));
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error.message);
    }
  }
);

export const updateUnit = createAsyncThunk(
  "unit/update",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const { property_id } = payload;
      dispatch(setLoading(true));
      const formData = new FormData();
      Object.keys(payload).map((key) => {
        if (key == "floor_plan") {
          if (PLATFORM !== "web") {
            formData.append("floor_plan", {
              name: payload.floor_plan.name,
              type: payload.floor_plan.type,
              uri:
                PLATFORM === "android"
                  ? payload.floor_plan.uri
                  : payload.floor_plan.uri.replace("file://", ""),
            });
          } else {
            formData.append("floor_plan", payload[key], payload[key].name);
          }
        } else {
          formData.append(key, payload[key]);
        }
      });
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("user_token")}`,
      };
      if (PLATFORM !== "web") {
        headers["Content-Type"] = "multipart/form-data";
      }
      // const response = await fetch(API_URL + "/unit_card/update", {
      //   method: "POST",
      //   headers: headers,
      //   body: formData,
      // });
      // const data = await response.json();
      dispatch(setShowUploadProgress(true));
      const config = {
        onUploadProgress: ({ loaded, total }) => {
          const progress = (loaded / total) * 100;
          dispatch(setUploadProgress(progress));
        },
        method: "POST",
        url: "/unit_card/update",
        headers,
        transformRequest: (data, headers) => {
          return formData;
        },
        data: formData,
        responseType: "json",
      };

      // const data = await response.json();

      const { data } = await axiosInstance.request(config);

      dispatch(setLoading(false));
      if (data.success) {
        await dispatch(fetchUnits({ property_id }));
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error.message);
    }
  }
);

export const deleteUnit = createAsyncThunk(
  "unit/delete",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const { property_id } = payload;
      dispatch(setLoading(true));
      const { data } = await axios.post(
        API_URL + "/unit_card/delete",
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      dispatch(setLoading(false));
      if (data.success) {
        await dispatch(fetchUnits({ property_id }));
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

export const fetchVacantUnits = createAsyncThunk(
  "unit_card/list_active",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const { data } = await axios.get(
        API_URL + "/unit_card/list_active/" + payload.property_id,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      dispatch(setLoading(false));
      if (data.success) {
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

export const fetchUnitMaintenancesById = createAsyncThunk(
  "unit/list_of_maintenance",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const { data } = await axios.post(
        API_URL + "/unit_card/list_maintenance",
        // "http://localhost:3000/unit_card/list_maintenance",
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );

      if (data.success) {
        dispatch(setLoading(false));
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response.message);
    }
  }
);

export const fetchUnitHistoryByUnitId = createAsyncThunk(
  "unit_card/unit_historylist",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const { data } = await axios.post(
        API_URL + "/unit_card/unit_historylist",
        // "http://localhost:3000/unit_card/unit_historylist",
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      if (data.success) {
        dispatch(setLoading(false));
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response.message);
    }
  }
);

const unitSlice = createSlice({
  name: "unit",
  initialState: initialState,
  extraReducers: {
    [fetchUnits.rejected]: (state, action) => {
      state.units = [];
    },
    [fetchUnits.fulfilled]: (state, action) => {
      state.units = action.payload;
    },
    [fetchUnitDetailByID.rejected]: (state, action) => {
      state.unitDetail = {};
    },
    [fetchUnitDetailByID.fulfilled]: (state, action) => {
      state.unitDetail = action.payload;
    },
    [fetchUnitMaintenancesById.rejected]: (state, action) => {
      state.unitMaintenances = [];
    },
    [fetchUnitMaintenancesById.fulfilled]: (state, action) => {
      state.unitMaintenances = action.payload;
    },
    [fetchUnitHistoryByUnitId.rejected]: (state, action) => {
      state.unitHistory = [];
    },
    [fetchUnitHistoryByUnitId.fulfilled]: (state, action) => {
      state.unitHistory = action.payload;
    },

    [fetchVacantUnits.rejected]: (state, action) => {
      state.vacantUnits = [];
    },
    [fetchVacantUnits.fulfilled]: (state, action) => {
      state.vacantUnits = action.payload;
    },
  },
});

export const getUnits = (state) => state.unit.units;

export const getVacantUnits = (state) => state.unit.vacantUnits;

export const getUnitDetail = (state) => state.unit.unitDetail;

export const getUnitHistory = (state) => state.unit.unitHistory;
export const getUnitMaintenances = (state) => state.unit.unitMaintenances;

export default unitSlice.reducer;
