/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import { useDispatch, useSelector } from "react-redux";
import GLOBAL from "../../setup/constants/global";
import { useParams } from "react-router-dom";
/* Import configuration ends */

/* Import redux slices component starts */
import { setLoading } from "../../setup/store/slices/unpersistedSlice";
import { getUser } from "../../setup/store/slices/authSlice";
import {
  fetchPropertyDetailByID,
  getPropertyDetail,
} from "../../setup/store/slices/propertySlice";
import {
  getUnitDetail,
  fetchUnitDetailByID,
  fetchCanInviteTenant,
  fetchUnitHistoryById,
  fetchUnitMaintenancesById,
  getUnitMaintenances,
  fetchUnitHistoryByUnitId,
  getUnitHistory,
} from "../../setup/store/slices/unitSlice";
import {
  showSuccessAlert,
  showErrorAlert,
} from "../../setup/store/slices/globalAlertSlice";
import { vacantT2Unit } from "../../setup/store/slices/tenantSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import MUI component starts */
/* Import MUI component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import UnitDetailBox from "./local-components/UnitDetailBox";
import Header from "./local-components/Header";
import UnitHistory from "./local-components/UnitHistory";
import UnitAgeDetailBox from "./local-components/UnitAgeDetailBox";
import UnitMaintenances from "./local-components/UnitMaintenances";
import ConfirmationModal from "../../components/ui/modal/ConfirmationModal/ConfirmationModal";
/* Import local pages and component ends */

import "./style.scss";
import TabButton from "../../components/ui/other/TabButton/TabButton";
import BreadCrumb from "../../components/ui/other/BreadCrumb/BreadCrumb";
import { ExcalmatoryYellow } from "../../setup/constants/images";

/* Component starts */
const UnitDetails = (props) => {
  /* Props destructuring starts */
  const {} = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [canInviteRes, setCanInviteRes] = useState(false);
  const [permissionsFeched, setPremissionsFetched] = useState(false);
  const [unitHistory, setUnitHistory] = useState([]);

  const [currentKey, setCurrentKey] = useState("unit_history");

  const [currentPage, setCurrentPage] = useState(1);
  const [confirmRemoveTenant, setConfirmRemoveTenant] = useState(false);
  // const [itemsPerPage] = useState(10);
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const dispatch = useDispatch();
  const { propertyId, unitId, communityId } = useParams();
  // Redux Selector
  const unit = useSelector(getUnitDetail);
  const property = useSelector(getPropertyDetail);
  const user = useSelector(getUser);

  const unitMaintenances = useSelector(getUnitMaintenances);
  const unitHistoryData = useSelector(getUnitHistory);

  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */

  // const indexOfLastItem = currentPage * itemsPerPage;
  // const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  // const currentItemsList = filterContracts?.slice(
  //   indexOfFirstItem,
  //   indexOfLastItem
  // );
  const isT2 = property?.mgmt_model === 1;
  const buttonList = [
    { key: "unit_history", label: "Unit History" },
    { key: "unit_maintenance", label: "Unit Maintenance" },
  ];

  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  const getCanInviteTenant = async () => {
    setPremissionsFetched(false);
    try {
      const result = await dispatch(fetchCanInviteTenant({ unit_id: unitId }));
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
        case GLOBAL.FULFILLED:
          setCanInviteRes(result.payload);
          setPremissionsFetched(true);
          break;
      }
    } catch (e) {
      console.error("error", e);
      setPremissionsFetched(true);
    }
  };

  const fetchUnitHist = async () => {
    try {
      const result = await dispatch(fetchUnitHistoryById({ unit_id: unitId }));
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          setUnitHistory([]);
          break;
        case GLOBAL.FULFILLED:
          setUnitHistory(result.payload);
          break;
      }
    } catch (e) {
      console.error("error", e);
    }
  };

  const onTabSelect = (key) => {
    setCurrentKey(key);
  };

  const removeT2Tenant = async () => {
    setConfirmRemoveTenant(false);
    // Creating or collecting payload data to be sent
    const payload = { unit_id: unit?._id };
    console.log("Payload", payload);

    // Backend response. Try, Catch
    try {
      const result = await dispatch(vacantT2Unit(payload));
      console.log("Response", result);

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          dispatch(
            showErrorAlert({
              title: "Error!",
              description:
                typeof result?.payload === "string" ? result?.payload : "",
            })
          );
          break;

        case GLOBAL.FULFILLED:
          dispatch(fetchUnitDetailByID({ unit_id: unitId }));
          setCanInviteRes(true);
          dispatch(
            showSuccessAlert({
              title: "Success!",
              description:
                typeof result?.payload?.message === "string"
                  ? result?.payload?.message
                  : "",
            })
          );

          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };
  /* Component function definition ends */

  /* Component useEffect starts */
  useEffect(() => {
    (async () => {
      try {
        await dispatch(setLoading(true));
        await getCanInviteTenant();
        await dispatch(fetchPropertyDetailByID({ property_id: propertyId }));
        await dispatch(fetchUnitDetailByID({ unit_id: unitId }));
        await fetchUnitHist();
        await dispatch(setLoading(false));
      } catch (e) {}
    })();
  }, []);

  useEffect(() => {
    if (currentKey === "unit_history") {
      dispatch(
        fetchUnitHistoryByUnitId({ unit_id: unitId, page: currentPage })
      );
    } else {
      dispatch(
        fetchUnitMaintenancesById({ unit_id: unitId, page: currentPage })
      );
    }
  }, [currentKey, currentPage]);

  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="unit-detail-page-wrapper">
      <BreadCrumb type="unit_details" />

      {Object.keys(unit).length === 0 && (
        <div className="container_sm access-check">
          <img src={ExcalmatoryYellow} alt="No Access" />
          <p className="font_l fw_6">
            Your access is restricted. Please contact administrative.
          </p>
        </div>
      )}

      {Object.keys(unit).length > 0 && unit.unit_name && (
        <div className="container_md">
          {permissionsFeched && (
            <Header
              unit={unit}
              property={property}
              canInviteRes={canInviteRes}
              setConfirmRemoveTenant={setConfirmRemoveTenant}
              isT2={isT2}
            />
          )}
          <div className="detail-box">
            <UnitDetailBox
              unit={unit}
              user={user}
              property={property}
              unitHistory={unitHistory}
            />
          </div>

          <div className="detail-box my-4">
            <UnitAgeDetailBox unitHistory={unitHistory} />
          </div>

          <TabButton
            buttonList={buttonList}
            currentKey={currentKey}
            onSelect={onTabSelect}
          />

          {currentKey === "unit_history" ? (
            <UnitHistory
              unitHistory={unitHistoryData?.unitHistory}
              totalCount={unitHistoryData?.totalCount}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          ) : (
            <UnitMaintenances
              unitMaintenances={unitMaintenances?.maintenanceData}
              propertyId={propertyId}
              communityId={communityId}
              totalCount={unitMaintenances?.totalCount}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          )}

          {/* <div> 
         
          </div> */}

          {/* {unitHistory?.unit_history?.length > 0 && (
            <UnitHistory unitHistory={unitHistory?.unit_history} />
          )}

          {unitMaintenances?.maintenance?.length > 0 && (
            <UnitMaintenances
              unitMaintenances={unitMaintenances?.maintenance}
              propertyId={propertyId}
              communityId={communityId}
              totalCount={unitMaintenances?.totalCount}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          )} */}
        </div>
      )}

      <ConfirmationModal
        showModal={confirmRemoveTenant}
        description="Are sure you want to remove the tenant from this unit ?"
        title="Confirm Remove Tenant"
        onClose={() => setConfirmRemoveTenant(false)}
        onConfirm={removeT2Tenant}
      />
    </div>
  );
};
/* Component ends */

export default UnitDetails;
