import GLOBAL from "../../../../setup/constants/global";

export const logTitle = (item) => {
  let title = "";
  let role = item?.requester_role?.name;
  let currentStatus = item?.current_status;
  let logType = item?.log_type;
  // return item?.action;

  //   Generating title according to action string
  switch (item?.action) {
    case "Readiness Ticket Accepted":
      title = "Unit Readiness (Unit Estimation Report)";
      break;

    default:
      title = item?.action;
  }

  return title;
};

export const statusColorGenerate = (status) => {
  switch (status) {
    case "New":
      return "font_blue";

    case "Closed":
      return "font_green";

    case "Rejected":
      return "font_red";

    case "Completed":
      return "font_green";

    default:
      return "";
  }
};

export const orderStatusGenerate = (status) => {
  if (status === "Approved") {
    return {
      status: "Order Placed",
      button: "Place Order",
    };
  }
  if (status === "Order Placed") {
    return {
      status: "Parts Recieved",
      button: "Recieve Order",
    };
  }
};

export const taskStatusGenerate = (status) => {
  switch (status) {
    case "technician_acceptance_pending":
    case "Pending":
      return { color: "font_yellow", text: "Pending Technician approval" };

    case "accepted_by_technician":
      return { color: "font_green", text: "Technician Accepted" };

    case "technician_on_the_way":
      return { color: "font_yellow", text: "Technician On The Way" };

    case "technician_on_the_location":
      return { color: "font_yellow", text: "Technician On The Location" };

    case "rejected_by_technician":
      return { color: "font_red", text: "Technician Rejected" };

    case "Completed":
      return { color: "font_blue", text: "Task Completed" };

    case "pm_approval_pending":
      return { color: "font_yellow", text: "PM Approval Pending" };

    case "Approved":
      return { color: "font_green", text: "Approved" };

    case "rejected_by_pm":
      return { color: "font_red", text: "Rejected By PM" };

    default:
      return {};
  }
};

const userActionPermission = (item, user) => {
  const logRequester = item?.requester?._id;
  const logRequesterRole = item?.requester_role?._id;
  const currentUser = user?._id;
  const currentUserRole = user.role_id;
  if (logRequesterRole === currentUserRole && logRequester === currentUser) {
    return true;
  }
  return false;
};

export const ownerAddQuote = (item, user) => {
  const action = item?.action;
  const currentStatus = item?.current_status;
  const logType = item?.log_type;
  const requesterRole = item?.requester_role?.name;
  if (!userActionPermission(item, user)) {
    return false;
  }
  if (
    action === "UIRC phase 2 assigned to Owner" ||
    action === "Owner uploaded report"
  ) {
    return true;
  }
};

export const ownerCompletesTask = (item, user) => {
  const action = item?.action;
  const currentStatus = item?.current_status;
  const logType = item?.log_type;
  const requesterRole = item?.requester_role?.name;
  if (!userActionPermission(item, user)) {
    return false;
  }
  if (
    action === "UIRC phase 2 assigned to Owner" ||
    action === "Quoatation approved To Owner" ||
    action === "Owner uploaded report" ||
    action === "Owner received approval"
  ) {
    return true;
  }
};

// FM to assign technician
export const canAssignTechnician = (item, user) => {
  const action = item?.action;
  const currentStatus = item?.current_status;
  const logType = item?.log_type;
  const requesterRole = item?.requester_role?.name;
  if (!userActionPermission(item, user)) {
    return false;
  }
  if (
    requesterRole !== "Owner" &&
    (action === "Ticket Assigned" ||
      action === "Unit Readiness Ticket Assigned To Manager" ||
      action === "Readiness Ticket false" ||
      // action === "Readiness Ticket Reassigned To FM" ||
      action === "Readiness Ticket Reassigned To Facility Manager" ||
      action === "Unit Readiness card created" ||
      // (action === "Readiness Ticket Reassigned" &&
      //   currentStatus === "Reassigned To FM") ||
      action === "UIRC final ticket created" ||
      action === "Unit Readiness Report Ticket Created" ||
      action === "Final Unit Inspection Ticket Created")
  ) {
    return true;
  }

  return false;
};

export const canFillURICP1_t1 = (item, user) => {
  const action = item?.action;
  const currentStatus = item?.current_status;
  const logType = item?.log_type;
  const requesterRole = item?.requester_role?.name;
  if (!userActionPermission(item, user)) {
    return false;
  }
  if (requesterRole === "Owner" && action === "Unit Readiness card created") {
    return true;
  }
};

export const canFillURICFinl_t1 = (item, user) => {
  const action = item?.action;
  const currentStatus = item?.current_status;
  const logType = item?.log_type;
  const requesterRole = item?.requester_role?.name;
  if (!userActionPermission(item, user)) {
    return false;
  }
  if (
    action === "Final Inspection is assigned to Owner" ||
    action === "UIRC final ticket created" ||
    action === "Final Unit Inspection Ticket Created"
  ) {
    return true;
  }
};

export const canFmRespond2PMrejectUIRC = (item, user) => {
  const action = item?.action;
  const currentStatus = item?.current_status;
  const logType = item?.log_type;
  if (!userActionPermission(item, user)) {
    return false;
  }
  if (
    action === "Readiness Ticket Reassigned" &&
    currentStatus !== "Reassigned To Technician"
  ) {
    return true;
  }
  return false;
};

// MT to take action for UIRC Phase 1 Intial request
export const mtRespondUIRCP1Init = (item, user) => {
  const action = item?.action;
  const currentStatus = item?.current_status;
  const logType = item?.log_type;
  if (!userActionPermission(item, user)) {
    return false;
  }
  if (
    (action === "Technician Assigned" ||
      (action === "Readiness Ticket Reassigned" &&
        currentStatus !== "Reassigned To FM")) &&
    logType === "URIC Initial"
  ) {
    return true;
  }

  return false;
};

// MT to take action for UIRC Phase 1 Final request
export const mtRespondUIRCP1Finl = (item, user) => {
  const action = item?.action;
  const currentStatus = item?.current_status;
  const logType = item?.log_type;
  if (!userActionPermission(item, user)) {
    return false;
  }
  if (
    currentStatus !== "Reassigned To FM" &&
    (action === "Technician Assigned" ||
      action === "Readiness Ticket Reassigned") &&
    logType === "URIC Final"
  ) {
    return true;
  }
};

// MT to fill URIC Phase 1
export const canFillURICP1 = (item, user) => {
  const action = item?.action;
  const currentStatus = item?.current_status;
  const logType = item?.log_type;
  if (!userActionPermission(item, user)) {
    return false;
  }
  if (action === "Readiness Ticket Accepted") {
    return true;
  }
};

// FM to approve URIC Phase 1 Initial form details
export const fmCanApproveURICP1Init = (item, user) => {
  const action = item?.action;
  const currentStatus = item?.current_status;
  const logType = item?.log_type;
  if (!userActionPermission(item, user)) {
    return false;
  }
  if (
    // action === "FM pending initial p1 report review" &&
    // action === "Facility Manager pending initial p1 report review" &&
    currentStatus === "fm_approval_pending" &&
    logType === "URIC Initial"
  ) {
    return true;
  }
};

// FM to approve URIC Phase 1 Final form details
export const fmCanApproveURICP1Finl = (item, user) => {
  const action = item?.action;
  const currentStatus = item?.current_status;
  const logType = item?.log_type;
  if (!userActionPermission(item, user)) {
    return false;
  }
  if (
    // action === "FM pending final p1 report review" &&
    (action === "Facility Manager pending final p1 report review" ||
      action ===
        "Facility Manager Pending Final Unit Inspection Report Review") &&
    logType === "URIC Final"
  ) {
    return true;
  }
};

// Checking if all estimations have been put on UIRC P1
export const putAllEstimations = (items) => {
  return items.every((item, index) => item && !isNaN(item));
};

// FM to review URIC Phase 1 Initial form
export const canReviewUIRCP1Init = (items) => {
  return putAllEstimations(items);
};

// FM to review URIC Phase 1 Final form
export const canReviewUIRCP1Finl = (items) => {
  return putAllEstimations(items);
};

// FM to skip URIC Phase 1 Final form
export const canSkipFinalAsessment = (item, user, finalURICStatus) => {
  const action = item?.action;
  const currentStatus = item?.current_status;
  const logType = item?.log_type;
  if (!userActionPermission(item, user)) {
    return false;
  }
  if (
    (finalURICStatus === "Pending" &&
      currentStatus === "Ticket Assigned to FM") ||
    action === "Final Inspection is assigned to Owner" ||
    action === "UIRC final ticket created" ||
    action === "Final Unit Inspection Ticket Created"
  ) {
    return true;
  }
};

// PM to approve UIRC Phase 1 Initial Card
export const pmCanApproveUIRCP1Init = (item, user) => {
  const action = item?.action;
  const currentStatus = item?.current_status;
  const logType = item?.log_type;
  if (!userActionPermission(item, user)) {
    return false;
  }
  if (
    //action === "UIRC initial assigned to PM"
    action === "UIRC initial assigned to Property Manager" ||
    action === "Initial Unit Inspection Report Assigned To Property Manager"
    // currentStatus === "Ticket Assigned to PM"
  ) {
    return true;
  }
};

// PM to approve UIRC Phase 1 Final Card
export const pmCanApproveUIRCP1Finl = (item, user) => {
  const action = item?.action;
  const currentStatus = item?.current_status;
  const logType = item?.log_type;
  if (!userActionPermission(item, user)) {
    return false;
  }
  if (
    //action === "UIRC Final assigned to PM"
    action === "UIRC Final assigned to Property Manager" ||
    action === "Final Unit Inspection Report Assigned To Property Manager"
  ) {
    return true;
  }
};

// FM to start UIRC Phase 2
export const canStartP2 = (item, user) => {
  const action = item?.action;
  const currentStatus = item?.current_status;
  const logType = item?.log_type;
  if (!userActionPermission(item, user)) {
    return false;
  }
  if (
    // action === "UIRC phase 2 assigned to FM" &&
    // logType === "URIC Phase 2" ||
    // logType === "Additional"
    false
  ) {
    return true;
  }
};

// FM to add quote
export const canAddQuote = (item, user) => {
  const action = item?.action;
  const currentStatus = item?.current_status;
  const logType = item?.log_type;
  if (!userActionPermission(item, user)) {
    return false;
  }
  if (
    //action === "UIRC phase 2 assigned to FM"
    action === "UIRC Phase 2 assigned to Facility Manager" ||
    action === "Unit Readiness Report Assigned To Facility Manager" ||
    action === "Unit Readiness Report Assigned To Facility Manager"
  ) {
    return true;
  }
};

// FM to update quatation
export const fmCanEditQuote = (item, user) => {
  const action = item?.action;
  const currentStatus = item?.current_status;
  const logType = item?.log_type;
  if (!userActionPermission(item, user)) {
    return false;
  }
  if (action === "Quoatation reassigned to Manager") {
    return true;
  }
};

// PM to approve quote
export const pmCanApproveQuote = (item, user) => {
  const action = item?.action;
  const currentStatus = item?.current_status;
  const logType = item?.log_type;
  if (!userActionPermission(item, user)) {
    return false;
  }
  if (
    //action === "Quoatation assigned To PM"
    action === "Quoatation assigned To Property Manager"
  ) {
    return true;
  }
};

// FM to order spare part
export const fmCanChangeOrderStatus = (item, user) => {
  const action = item?.action;
  const currentStatus = item?.current_status;
  const logType = item?.log_type;
  const isPartExist = item?.data?.is_part_exist;
  if (!userActionPermission(item, user)) {
    return false;
  }
  if (
    (isPartExist && action === "Manager received approval") ||
    currentStatus === "Order Placed"
    // (action === "Order status updated by Manager" &&
    //   currentStatus === "Order Placed") ||
    // (action === "Order has been placed" && currentStatus === "Order Placed")
  ) {
    return true;
  }
};

// FM to assign multiple technician to multiple UIRC inspections items
export const fmCanAssignPhase2MT = (item, user) => {
  const action = item?.action;
  const currentStatus = item?.current_status;
  const logType = item?.log_type;
  const isPartExist = item?.data?.is_part_exist;
  if (!userActionPermission(item, user)) {
    return false;
  }
  if (
    (!isPartExist && action === "Manager received approval") ||
    ((action === "Order status updated by Manager" ||
      action === "Order has been recieved") &&
      currentStatus === "Parts Recieved")
  ) {
    return true;
  }
};

const checkTaskAssignedTo = (taskItem, user) => {
  const logRequester = taskItem?.assigned?._id;
  const logRequesterRole = taskItem?.assigned_role?._id;
  const currentUser = user?._id;
  const currentUserRole = user.role_id;
  if (logRequesterRole === currentUserRole && logRequester === currentUser) {
    return true;
  }
  return false;
};

// MT to respont phase 2 task
export const mtCanRespondP2Task = (taskItem, user) => {
  const status = taskItem?.status;
  if (!checkTaskAssignedTo(taskItem, user)) {
    return false;
  }
  if (status === "technician_acceptance_pending" || status === "Pending") {
    return true;
  }
};

// FM to re-assign technician to multiple UIRC inspections items
export const fmCanReAssignPhase2MT = (taskItem, user) => {
  const status = taskItem?.status;
  if (localStorage.getItem("user_role") !== "Facility Manager") {
    return false;
  }
  if (status === "rejected_by_technician" || status === "rejected_by_pm") {
    return true;
  }
};

// MT to change arrival status
export const mtCanChangeArrivalStatus = (taskItem, user) => {
  const status = taskItem?.status;
  if (!checkTaskAssignedTo(taskItem, user)) {
    return false;
  }
  if (
    status === "accepted_by_technician" ||
    status === "technician_on_the_way"
  ) {
    return true;
  }
};

// MT to fill UIRC Phase 2 form
export const mtCanFillUIRCP2 = (taskItem, user) => {
  const status = taskItem?.status;
  if (!checkTaskAssignedTo(taskItem, user)) {
    return false;
  }
  if (status === "technician_on_the_location") {
    return true;
  }
};

// Checking if all items are completed
export const allTaskComplete = (UIRCp2) => {
  const items = UIRCp2.items;
  if (UIRCp2) {
    return (
      items.every((item) => item.status === "Completed") ||
      UIRCp2?.status === "pm_approval_pending" ||
      UIRCp2?.status === "fm_approval_pending"
    );
  }
};

// Checking if all items are approved by FM
export const allItemsApprovedByFM = (UIRCp2) => {
  const items = UIRCp2.items;
  if (UIRCp2) {
    return (
      items.every((item) => item.status === "pm_approval_pending") ||
      UIRCp2?.status === "pm_approval_pending"
    );
  }
};

// FM to respond to completed task
export const fmCanRespondToTask = (taskItem, user) => {
  const status = taskItem?.status;
  if (localStorage.getItem("user_role") !== "Facility Manager") {
    return false;
  }
  if (status === "Completed" || status === "fm_approval_pending") {
    return true;
  }
};

// PM to respond to approved task by FM
export const pmCanRespondToTask = (taskItem, user) => {
  const status = taskItem?.status;
  if (localStorage.getItem("user_role") !== "Property Manager") {
    return false;
  }
  if (status === "pm_approval_pending") {
    return true;
  }
};

export const showActionGroup = (taskItem, user, UIRCp2) => {
  return (
    mtCanRespondP2Task(taskItem, user) ||
    fmCanReAssignPhase2MT(taskItem, user) ||
    mtCanFillUIRCP2(taskItem, user) ||
    mtCanChangeArrivalStatus(taskItem, user) ||
    (allTaskComplete(UIRCp2) && fmCanRespondToTask(taskItem, user)) ||
    (allItemsApprovedByFM(UIRCp2) && pmCanRespondToTask(taskItem, user))
  );
};

// PM to respond to key rejection
export const pmCanRespondKeyReject = (item, user) => {
  const action = item?.action;
  const currentStatus = item?.current_status;
  const logType = item?.log_type;
  if (!userActionPermission(item, user)) {
    return false;
  }
  if (logType === "keys" && currentStatus === "Rejected") {
    return true;
  }
};

export const logActions = (
  logTitle,
  canAssignTechnician,
  mtRespondUIRCP1Init,
  canFillURICP1,
  mtRespondUIRCP1Finl,
  canSkipFinalAsessment,
  canStartP2,
  pmCanApproveUIRCP1Init,
  pmCanApproveUIRCP1Finl,
  orderStatusGenerate,
  fmCanChangeOrderStatus,
  fmCanAssignPhase2MT,
  pmCanApproveQuote,
  fmCanEditQuote,
  pmCanRespondKeyReject,
  canFillURICP1_t1,
  canFillURICFinl_t1,
  ownerAddQuote,
  ownerCompletesTask,
  canFmRespond2PMrejectUIRC,
  canAddQuote
) => {
  console.log("logTitle ==>", logTitle);
  console.log("canAssignTechnician ==>", canAssignTechnician);
  console.log("mtRespondUIRCP1Init ==>", mtRespondUIRCP1Init);
  console.log("canFillURICP1 ==>", canFillURICP1);
  console.log("mtRespondUIRCP1Finl ==>", mtRespondUIRCP1Finl);
  console.log("canSkipFinalAsessment ==>", canSkipFinalAsessment);
  console.log("canStartP2 ==>", canStartP2);
  console.log("pmCanApproveUIRCP1Init ==>", pmCanApproveUIRCP1Init);
  console.log("pmCanApproveUIRCP1Finl ==>", pmCanApproveUIRCP1Finl);
  console.log("orderStatusGenerate ==>", orderStatusGenerate);
  console.log("fmCanChangeOrderStatus ==>", fmCanChangeOrderStatus);
  console.log("fmCanAssignPhase2MT ==>", fmCanAssignPhase2MT);
  console.log("pmCanApproveQuote ==>", pmCanApproveQuote);
  console.log("fmCanEditQuote ==>", fmCanEditQuote);
  console.log("pmCanRespondKeyReject ==>", pmCanRespondKeyReject);
  console.log("canFillURICP1_t1 ==>", canFillURICP1_t1);
  console.log("canFillURICFinl_t1 ==>", canFillURICFinl_t1);
  console.log("ownerAddQuote ==>", ownerAddQuote);
  console.log("ownerCompletesTask ==>", ownerCompletesTask);
  console.log("canFmRespond2PMrejectUIRC ==>", canFmRespond2PMrejectUIRC);
  console.log("canAddQuote ==>", canAddQuote);
};
