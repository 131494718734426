/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import { useNavigate } from "react-router-dom";
/* Import configuration ends */

/* Import redux slices component starts */

/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import MUI component starts */
/* Import MUI component ends */

/* Import image and SVG starts */
import { PersonAddGreen, EditGreen } from "../../../setup/constants/images";
/* Import image and SVG ends */

/* Import local pages and component starts */
import IconButton from "../../../components/ui/button/IconButton/IconButton";
import SuccessModal from "../../../components/ui/modal/SuccessModal/SuccessModal";
import ErrorModal from "../../../components/ui/modal/ErrorModal/ErrorModal";
import TextButton from "../../../components/ui/button/TextButton/TextButton";
/* Import local pages and component ends */

/* Component starts */
const Header = (props) => {
  /* Props destructuring starts */
  const { unit, canInviteRes, setConfirmRemoveTenant, isT2 } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [message, setMessage] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const navigate = useNavigate();
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  const isClient = localStorage.getItem("user_role") === "Client";
  const isOwner = localStorage.getItem("user_role") === "Owner";
  const isFM = localStorage.getItem("user_role") === "Facility Manager";
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */

  // On inviting tenant
  const canInviteTenant = () => {
    if (canInviteRes.can_invite_tenant) {
      return true;
    }
    return false;
  };

  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="header">
      <div className="title-actions">
        <h2 className="font_xxl fw_6">Unit Details</h2>
        <div className="actions">
          {isT2 && (isClient || isFM) && unit?.status === "occupied" && (
            <TextButton
              onClick={() => setConfirmRemoveTenant(true)}
              className="font_m fw_6 remove-tenant-btn"
            >
              {/* Remove Tenant */}
              Move Out Tenant
            </TextButton>
          )}

          {isT2 && isOwner ? (
            ""
          ) : (
            <IconButton
              className="action-btn"
              onClick={() => {
                if (unit?.status !== "vacant") {
                  setMessage("Cannot edit reserved or occupied unit.");
                  setShowErrorModal(true);
                  return;
                }
                navigate(`edit`);
              }}
            >
              {/* <img className="w_100" src={EditWhite} alt="Edit" /> */}
              <img className="w_100" src={EditGreen} alt="Edit" />
            </IconButton>
          )}

          {isT2 && isOwner ? (
            ""
          ) : (
            <IconButton
              onClick={() => {
                if (unit?.status !== "vacant") {
                  setMessage(
                    "Cannot invite tenant if the unit is already reserved or occupied unit."
                  );
                  setShowErrorModal(true);
                  return;
                }
                if (canInviteRes.can_invite_tenant) {
                  navigate("tenant/add/invite", {
                    state: { targetPrice: unit?.target_rent_price },
                  });
                } else {
                  setMessage(canInviteRes.message);
                  setShowErrorModal(true);
                }
              }}
              className="action-btn"
            >
              {/* <img className="w_100" src={PersonAddWhite} alt="Add Tenant" /> */}
              <img className="w_100" src={PersonAddGreen} alt="Add Tenant" />
            </IconButton>
          )}
        </div>
      </div>

      {/* Modals */}

      {/* Success Modal */}
      <SuccessModal
        showModal={showSuccessModal}
        description={message}
        title="Success"
        onClose={() => navigate(-1)}
      />

      {/* Error Modal */}
      <ErrorModal
        showModal={showErrorModal}
        description={message}
        title="Error"
        onClose={() => setShowErrorModal(false)}
      />
    </div>
  );
};
/* Component ends */

export default Header;
