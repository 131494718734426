/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import moment from "moment";
import { useDispatch } from "react-redux";
import { Formik } from "formik";
import { useLocation, useNavigate, useParams } from "react-router-dom";
/* Import configuration ends */

/* Import redux slices component starts */
import {
  showSuccessAlert,
  showErrorAlert,
} from "../../../../../../setup/store/slices/globalAlertSlice";
import { closeSecurityRequestWithoutViolation } from "../../../../../../setup/store/slices/securitySlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import GLOBAL from "../../../../../../setup/constants/global";
import FileViewerModal from "../../../../../ui/modal/FileViewerModal/FileViewerModal";
import CustomButton from "../../../../../ui/button/CustomButton/CustomButton";
import CustomRadioInput from "../../../../../ui/input/CustomRadioInput/CustomRadioInput";
import { pathNamePopped } from "../../../../../../setup/utils/global-helper";
import CustomTextArea from "../../../../../ui/input/CustomTextArea/CustomTextArea";
import Image from "../../../../../ui/other/Image/Image";
import { logTitle } from "../helper";
import IconButton from "../../../../../ui/button/IconButton/IconButton";
import { EyeFilledBlue } from "../../../../../../setup/constants/images";
/* Import local pages and component ends */

/* Component starts */
const PMLog = (props) => {
  /* Props destructuring starts */
  const {
    complaint,
    active = false,
    isLast = false,
    report = null,
    role,
    user,
    navigation,
  } = props;
  /* Props destructuring ends *

  /* Component states and useRef declaration starts */
  const [reason, setReason] = useState("");

  const [showFileViewer, setShowFileViewer] = useState(false);
  const [file, setFile] = useState({});
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { propertyId } = useParams();
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */

  const initialFormValues = {
    comment: "",
  };

  const name = complaint?.user
    ? `${complaint?.user?.first_name} ${complaint?.user?.last_name}`
    : `${complaint.tenant.first_name} ${complaint.tenant.last_name}`;
  // const role = complaint?.user?.role_id?.name;
  const createdAt = complaint?.createdAt;
  const comment = complaint?.comment;
  const reportData = complaint?.report;

  const USER_DATA = localStorage.getItem("user_data")
    ? JSON.parse(localStorage.getItem("user_data"))
    : null;
  const DATE_FORMAT = USER_DATA ? USER_DATA?.date_format : "";
  const TIME_FORMAT = USER_DATA ? USER_DATA?.time_format : "";
  const DATE_TIME_FORMAT = `${DATE_FORMAT} ${TIME_FORMAT}`;
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */

  // To View Voilation
  const handleView = (id) => {
    const newPathname = pathNamePopped(pathname, 4);

    navigate(`${newPathname}/violations/tenants/${id}`, {
      state: {
        pathname: pathname,
      },
    });
  };

  const handleCancel = () => {
    navigate(-1);
  };

  // PM -> Resolved the Request
  const resolveRequest = async (payload) => {
    console.log(payload, "RE_P");

    // Backend response. Try, Catch
    try {
      const result = await dispatch(
        closeSecurityRequestWithoutViolation(payload)
      );

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          dispatch(
            showErrorAlert({
              title: "Error!",
              description: result.payload,
            })
          );
          break;
        case GLOBAL.FULFILLED:
          dispatch(
            showSuccessAlert({
              title: "Success!",
              description: result.payload.message,
            })
          );
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };

  const respondToReport = async (val) => {
    let payload = { request_id: complaint.tenant_security_request_id };

    switch (reason) {
      // case "notify-violator":
      //   // Notify Violator
      //   console.log(payload, "NEED");
      //   break;
      case "issue-violation":
        // Issue Violation
        navigation(
          `${pathNamePopped(pathname, 5)}/${propertyId}/violations/add`,
          {
            state: {
              is_security_incident: false,
              closeWithViolation: true,
              request_id: complaint.tenant_security_request_id,
              pathname: pathname,
            },
          }
        );
        break;
      case "resolved":
        // Resolve
        payload = {
          id: complaint?.tenant_security_request_id,
          comment: val?.comment,
        };

        resolveRequest(payload);
        break;

      default:
    }
  };

  const getActionButtons = () => {
    if (role !== GLOBAL.USER_ROLE.PM) return;

    if (complaint.status === "escalated_to_pm") {
      //   case GLOBAL.SECURITY_STATUS.ESCALATED_TO_PM:

      return (
        <div className="sm-report-form">
          <Formik
            onSubmit={(val) => respondToReport(val)}
            initialValues={initialFormValues}
            validateOnBlur={true}
            enableReinitialize={true}
          >
            {({
              values,
              errors,
              touched,
              handleSubmit,
              handleBlur,
              handleChange,
            }) => (
              <form onSubmit={handleSubmit}>
                <div className="form-wrapper p-3">
                  {/* Creating new Security Incident Report */}
                  <>
                    {/* Radio select the Reason */}
                    <div className="form-group form-group-full d-flex justify-content-between">
                      {/* <CustomRadioInput
                        label="Notify Violator And Close"
                        onSelecting={() => {
                          setReason("notify-violator");
                        }}
                        isSelected={reason === "notify-violator"}
                      /> */}

                      <CustomRadioInput
                        label="Issue Violation And Close"
                        onSelecting={() => {
                          setReason("issue-violation");
                        }}
                        isSelected={reason === "issue-violation"}
                      />

                      <CustomRadioInput
                        label="Resolved"
                        onSelecting={() => {
                          setReason("resolved");
                        }}
                        isSelected={reason === "resolved"}
                      />
                    </div>

                    {/* <div className="select-reason">
                      <CustomRadioInput
                        label="Resolved"
                        onSelecting={() => {
                          setReason("resolved");
                        }}
                        isSelected={reason === "resolved"}
                      />
                    </div> */}

                    {/* Text Area for Reason */}
                    {reason === "resolved" && (
                      //  ||
                      //       reason === "escalated-PM")
                      <div className="form-group form-group-full mt-4">
                        <CustomTextArea
                          name="comment"
                          label="Comment"
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          value={values.comment}
                          errors={errors.comment}
                          touched={touched.comment}
                          required={true}
                        />
                      </div>
                    )}
                  </>
                </div>

                <div className="form-btn-wrapper">
                  <>
                    <CustomButton
                      type="button"
                      size="l"
                      variant="red"
                      // className="btn1"
                      onClick={() => handleCancel()}
                    >
                      Cancel
                    </CustomButton>
                    <CustomButton type="submit" size="l" variant="yellow">
                      Submit
                    </CustomButton>
                  </>
                </div>
              </form>
            )}
          </Formik>
        </div>
      );
    }
  };

  const renderReport = () => {
    return (
      <div className="so-report-wrapper">
        <div>
          <p className="font_m fw_4 font_grey">
            Description :{" "}
            <span className="font_xs fw_4 font_white">
              {complaint?.report?.description}
            </span>
          </p>
        </div>
        {complaint?.report?.document?.length > 0 && (
          <>
            <div className="report-doc-wrapper">
              {complaint?.report?.document?.map((image, index) => (
                <div className="proof-img" key={index}>
                  {/* <img
                    src={image.url}
                    alt="ID-Proof"
                    onClick={() => {
                      setFile(image);
                      setShowFileViewer(true);
                    }}
                  /> */}
                  <Image
                    key={index}
                    className="media"
                    imgClass="thumbnail"
                    src={image?.url}
                    alt="Attatched Media"
                    objectFit="cover"
                    videoThumbnail={image?.type2 === "video"}
                    onClick={() => {
                      setFile(image);
                      setShowFileViewer(true);
                    }}
                  />
                </div>
              ))}
            </div>
          </>
        )}
      </div>
    );
  };
  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <>
      <div className="time-line simple-log">
        {/* Dot */}

        <div className="dot-wrapper">
          <i className="dot"></i>
          <div className="vl"></div>
        </div>

        <div className="detail-wrapper">
          {/* Title */}
          <h3 className="title font_m fw_6">{logTitle(complaint)}</h3>

          {/* Technician Name and Date */}
          <div className="black-container-sm fw_5 font_s bg_black flex-between">
            {/* Name */}
            <p className="left">
              <span className="font_grey">
                {complaint?.user?.role_id?.name}{" "}
              </span>
              <span className="font_white"> {name}</span>
            </p>

            {/* Date and Time */}
            <p className="right font_grey">
              {moment(createdAt).format(DATE_TIME_FORMAT)}
            </p>
          </div>
          {/* Log Footer */}
          <div className="log-footer">
            {/* Comments */}
            {comment && (
              <p className="comments font_s fw-5">
                <span className="font_grey fw_6">Comments: </span>
                <span className="fw_5 font_red"> {comment}</span>
              </p>
            )}

            {complaint?.status === "resolved" && complaint?.violation_id && (
              <p className="currentStatus font_s fw-5 bg_black">
                <span className="font_grey fw_6">View Violation: </span>
                <span className="fw_5">
                  <IconButton
                    className="action-button"
                    onClick={() => handleView(complaint?.violation_id)}
                  >
                    <img src={EyeFilledBlue} alt="Icon" className="w_100" />
                  </IconButton>
                </span>
              </p>
            )}

            {reportData && renderReport()}

            {/* Action Buttons */}
            {active && getActionButtons()}
          </div>
        </div>
      </div>

      {/* MODALS */}

      {/* File viewer modal */}
      <FileViewerModal
        show={showFileViewer}
        type={file.type}
        file={file.url}
        onClose={() => {
          setShowFileViewer(false);
          setFile({});
        }}
      />
    </>
  );
};
/* Component ends */

export default PMLog;
