/**
 * @auther Abdul Ahad <aabdul@dgrnte.com>
 * Slice to handle move-in
 */

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios, { axiosInstance } from "../axios";
import {
  setLoading,
  setShowUploadProgress,
  setUploadProgress,
} from "./unpersistedSlice";
import { API_URL } from "../../config/devKeys";
import { PLATFORM } from "../../platformUtil";
import {
  fetchMaintenanceById,
  fetchMaintenanceListByProperty,
} from "./maintenanceSlice";

const initialState = {
  initialCard: null,
};

export const fillT1FinlCard = createAsyncThunk(
  "movein/owner/submit_final_readiness_card",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const { maintenance_id, type, property_id } = payload;
      dispatch(setLoading(true));
      const formData = new FormData();
      Object.keys(payload).map((key) => {
        if (key === "document") {
          payload[key].forEach((item) => {
            if (PLATFORM !== "web") {
              formData.append(key, {
                name: item.name,
                type: item.type,
                uri:
                  PLATFORM === "android"
                    ? item.uri
                    : item.uri.replace("file://", ""),
              });
            } else {
              formData.append(key, item.file, item.name);
            }
          });
        } else {
          if (key === "description") {
            formData.append(key, JSON.stringify(payload[key]));
          } else {
            formData.append(key, payload[key]);
          }
        }
      });
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("user_token")}`,
      };
      if (PLATFORM !== "web") {
        headers["Content-Type"] = "multipart/form-data";
      }
      // const response = await fetch(
      //   API_URL + "/movein_uric_final/owner/fill_final_card",
      //   {
      //     method: "POST",
      //     headers: headers,
      //     body: formData,
      //   }
      // );
      // const data = await response.json();
      dispatch(setShowUploadProgress(true));
      const config = {
        onUploadProgress: ({ loaded, total }) => {
          const progress = (loaded / total) * 100;
          dispatch(setUploadProgress(progress));
        },
        method: "POST",
        url: "/movein_uric_final/owner/fill_final_card",
        headers,
        transformRequest: (data, headers) => {
          return formData;
        },
        data: formData,
        responseType: "json",
      };

      // const data = await response.json();

      const { data } = await axiosInstance.request(config);

      if (data.success) {
        await dispatch(fetchMaintenanceListByProperty({ property_id, type }));
        await dispatch(fetchMaintenanceById({ maintenance_id }));
        dispatch(setLoading(false));
        return data.data;
      } else {
        dispatch(setLoading(false));
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

export const fillT1InitCard = createAsyncThunk(
  "movein/owner/submit_initial_readiness_card",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const { maintenance_id, type, property_id } = payload;
      dispatch(setLoading(true));
      const formData = new FormData();
      Object.keys(payload).map((key) => {
        if (key === "document") {
          payload[key].forEach((item) => {
            if (PLATFORM !== "web") {
              formData.append(key, {
                name: item.name,
                type: item.type,
                uri:
                  PLATFORM === "android"
                    ? item.uri
                    : item.uri.replace("file://", ""),
              });
            } else {
              formData.append(key, item.file, item.name);
            }
          });
        } else {
          if (key === "description") {
            formData.append(key, JSON.stringify(payload[key]));
          } else {
            formData.append(key, payload[key]);
          }
        }
      });
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("user_token")}`,
      };
      if (PLATFORM !== "web") {
        headers["Content-Type"] = "multipart/form-data";
      }

      console.log(formData, "FORM");

      // const response = await fetch(
      //   API_URL + "/movein/owner/submit_initial_readiness_card",
      //   {
      //     method: "POST",
      //     headers: headers,
      //     body: formData,
      //   }
      // );
      // const data = await response.json();
      dispatch(setShowUploadProgress(true));
      const config = {
        onUploadProgress: ({ loaded, total }) => {
          const progress = (loaded / total) * 100;
          dispatch(setUploadProgress(progress));
        },
        method: "POST",
        url: "/movein/owner/submit_initial_readiness_card",
        headers,
        transformRequest: (data, headers) => {
          return formData;
        },
        data: formData,
        responseType: "json",
      };

      console.log(config, "CONFIG");

      // const data = await response.json();

      const { data } = await axiosInstance.request(config);

      if (data.success) {
        await dispatch(fetchMaintenanceListByProperty({ type, property_id }));
        await dispatch(fetchMaintenanceById({ maintenance_id }));
        dispatch(setLoading(false));
        return data.data;
      } else {
        dispatch(setLoading(false));
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

export const t1markJobDone = createAsyncThunk(
  "movein_uric_final/owner/mark_job_done",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const { maintenance_id, type, property_id } = payload;
      dispatch(setLoading(true));
      const { data } = await axios.post(
        `${API_URL}/movein_uric_final/owner/mark_job_done`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      if (data.success) {
        await dispatch(fetchMaintenanceListByProperty({ property_id, type }));
        await dispatch(fetchMaintenanceById({ maintenance_id }));
        dispatch(setLoading(false));
        return data.data;
      } else {
        dispatch(setLoading(false));
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

export const t1SkipFinl = createAsyncThunk(
  "/movein_uric_final/owner/skip_final_inspection",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const { maintenance_id, type, property_id } = payload;
      dispatch(setLoading(true));
      const { data } = await axios.post(
        `${API_URL}/movein_uric_final/owner/skip_final_inspection`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      if (data.success) {
        await dispatch(fetchMaintenanceListByProperty({ type, property_id }));
        await dispatch(fetchMaintenanceById({ maintenance_id }));
        dispatch(setLoading(false));
        return data.data;
      } else {
        dispatch(setLoading(false));
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

export const assignMoveInTechnicianToCard = createAsyncThunk(
  "move_in/assign_technician_to_card",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const { maintenance_id, type, property_id } = payload;
      dispatch(setLoading(true));
      const { data } = await axios.post(
        `${API_URL}/movein/assign_technician_to_card`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      if (data.success) {
        await dispatch(fetchMaintenanceListByProperty({ type, property_id }));
        await dispatch(fetchMaintenanceById({ maintenance_id }));
        dispatch(setLoading(false));
        return data.data;
      } else {
        dispatch(setLoading(false));
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

export const FMrespondMoveInRequest = createAsyncThunk(
  "move_in/fm_respond_request",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const { maintenance_id, type } = payload;
      dispatch(setLoading(true));
      const { data } = await axios.post(
        `${API_URL}/movein/respond_movein_request`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      if (data.success) {
        await dispatch(fetchMaintenanceListByProperty({ type }));
        await dispatch(fetchMaintenanceById({ maintenance_id }));
        dispatch(setLoading(false));
        return data.data;
      } else {
        dispatch(setLoading(false));
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

export const MTrespondMoveInRequest = createAsyncThunk(
  "move_in/mt_respond_request",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const { maintenance_id, type, property_id } = payload;
      dispatch(setLoading(true));
      const { data } = await axios.post(
        `${API_URL}/movein/technician_respond_to_card`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      if (data.success) {
        await dispatch(fetchMaintenanceListByProperty({ type, property_id }));
        await dispatch(fetchMaintenanceById({ maintenance_id }));
        dispatch(setLoading(false));
        return data.data;
      } else {
        dispatch(setLoading(false));
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

export const mtFillInitialReport = createAsyncThunk(
  "move_in/technician_fill_initial_card",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const { maintenance_id, type, property_id } = payload;
      dispatch(setLoading(true));

      const formData = new FormData();
      Object.keys(payload).map((key) => {
        if (key === "document") {
          payload[key].forEach((item) => {
            if (PLATFORM !== "web") {
              formData.append(key, {
                name: item.name,
                type: item.type,
                uri:
                  PLATFORM === "android"
                    ? item.uri
                    : item.uri.replace("file://", ""),
              });
            } else {
              formData.append(key, item.file, item.name);
            }
          });
        } else {
          if (key === "description") {
            // stringifying array of string
            formData.append(key, JSON.stringify(payload[key]));
          } else {
            formData.append(key, payload[key]);
          }
        }
      });
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("user_token")}`,
      };
      if (PLATFORM !== "web") {
        headers["Content-Type"] = "multipart/form-data";
      }
      // const response = await fetch(
      //   API_URL + "/movein/technician_fill_initial_card",
      //   {
      //     method: "POST",
      //     headers: headers,
      //     body: formData,
      //   }
      // );
      // const data = await response.json();
      dispatch(setShowUploadProgress(true));
      const config = {
        onUploadProgress: ({ loaded, total }) => {
          const progress = (loaded / total) * 100;
          dispatch(setUploadProgress(progress));
        },
        method: "POST",
        url: "/movein/technician_fill_initial_card",
        headers,
        transformRequest: (data, headers) => {
          return formData;
        },
        data: formData,
        responseType: "json",
      };

      // const data = await response.json();

      const { data } = await axiosInstance.request(config);

      if (data.success) {
        await dispatch(fetchMaintenanceListByProperty({ type, property_id }));
        await dispatch(fetchMaintenanceById({ maintenance_id }));
        dispatch(setLoading(false));
        return data.data;
      } else {
        dispatch(setLoading(false));
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

export const assignMTtoFinalCard = createAsyncThunk(
  "move_in/fm_respond_uirc_final_request",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const { maintenance_id, type, property_id } = payload;
      dispatch(setLoading(true));
      const { data } = await axios.post(
        `${API_URL}/movein_uric_final/assign_technician_to_final_card`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      if (data.success) {
        await dispatch(fetchMaintenanceListByProperty({ type, property_id }));
        await dispatch(fetchMaintenanceById({ maintenance_id }));
        dispatch(setLoading(false));
        return data.data;
      } else {
        dispatch(setLoading(false));
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

export const FMrespondReadinessCard = createAsyncThunk(
  "move_in/fm_respond_readiness_card",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const { maintenance_id, type, property_id } = payload;
      dispatch(setLoading(true));
      const { data } = await axios.post(
        `${API_URL}/movein/fm_respond_readiness_card`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      if (data.success) {
        await dispatch(fetchMaintenanceListByProperty({ property_id, type }));
        await dispatch(fetchMaintenanceById({ maintenance_id }));
        dispatch(setLoading(false));
        return data.data;
      } else {
        dispatch(setLoading(false));
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

export const mtRespondToFinalCard = createAsyncThunk(
  "move_in/mt_respond_final_card_request",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const { maintenance_id, type, property_id } = payload;
      dispatch(setLoading(true));
      const { data } = await axios.post(
        `${API_URL}/movein_uric_final/technician_respond_to_final_card`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      if (data.success) {
        await dispatch(fetchMaintenanceListByProperty({ property_id, type }));
        await dispatch(fetchMaintenanceById({ maintenance_id }));
        dispatch(setLoading(false));
        return data.data;
      } else {
        dispatch(setLoading(false));
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

export const mtFillFinalReport = createAsyncThunk(
  "move_in/technician_fill_final_card",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const { maintenance_id, type, property_id } = payload;
      dispatch(setLoading(true));
      const formData = new FormData();
      Object.keys(payload).map((key) => {
        if (key === "document") {
          payload[key].forEach((item) => {
            if (PLATFORM !== "web") {
              formData.append(key, {
                name: item.name,
                type: item.type,
                uri:
                  PLATFORM === "android"
                    ? item.uri
                    : item.uri.replace("file://", ""),
              });
            } else {
              formData.append(key, item.file, item.name);
            }
          });
        } else {
          if (key === "description") {
            // stringifying array of string
            formData.append(key, JSON.stringify(payload[key]));
          } else {
            formData.append(key, payload[key]);
          }
        }
      });
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("user_token")}`,
      };
      if (PLATFORM !== "web") {
        headers["Content-Type"] = "multipart/form-data";
      }
      // const response = await fetch(
      //   API_URL + "/movein_uric_final/technician_fill_final_card",
      //   {
      //     method: "POST",
      //     headers: headers,
      //     body: formData,
      //   }
      // );
      // const data = await response.json();
      dispatch(setShowUploadProgress(true));
      const config = {
        onUploadProgress: ({ loaded, total }) => {
          const progress = (loaded / total) * 100;
          dispatch(setUploadProgress(progress));
        },
        method: "POST",
        url: "/movein_uric_final/technician_fill_final_card",
        headers,
        transformRequest: (data, headers) => {
          return formData;
        },
        data: formData,
        responseType: "json",
      };

      // const data = await response.json();

      const { data } = await axiosInstance.request(config);

      if (data.success) {
        await dispatch(fetchMaintenanceListByProperty({ property_id, type }));
        await dispatch(fetchMaintenanceById({ maintenance_id }));
        dispatch(setLoading(false));
        return data.data;
      } else {
        dispatch(setLoading(false));
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

export const fmUpdateInitialCard = createAsyncThunk(
  "move_in/fm_update_initial_card",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const { maintenance_id, type, property_id } = payload;
      dispatch(setLoading(true));
      const { data } = await axios.post(
        `${API_URL}/movein/fm_update_initial_readiness_card`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      if (data.success) {
        await dispatch(fetchMaintenanceListByProperty({ property_id, type }));
        await dispatch(fetchMaintenanceById({ maintenance_id }));
        dispatch(setLoading(false));
        return data.data;
      } else {
        dispatch(setLoading(false));
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

export const fmUpdateFinalCard = createAsyncThunk(
  "move_in/fm_update_final_card",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const { maintenance_id, type, property_id } = payload;
      dispatch(setLoading(true));
      const { data } = await axios.post(
        `${API_URL}/movein_uric_final/fm_update_final_readiness_card`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      if (data.success) {
        await dispatch(fetchMaintenanceListByProperty({ property_id, type }));
        await dispatch(fetchMaintenanceById({ maintenance_id }));
        dispatch(setLoading(false));
        return data.data;
      } else {
        dispatch(setLoading(false));
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

export const skipFinalUIRCP1 = createAsyncThunk(
  "move_in/fm_skip_final_readiness_card",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const { maintenance_id, type, property_id } = payload;
      dispatch(setLoading(true));
      const { data } = await axios.post(
        `${API_URL}/movein_uric_final/fm_skip_final_readiness_card`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      if (data.success) {
        await dispatch(fetchMaintenanceListByProperty({ property_id, type }));
        await dispatch(fetchMaintenanceById({ maintenance_id }));
        dispatch(setLoading(false));
        return data.data;
      } else {
        dispatch(setLoading(false));
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

export const addQuote = createAsyncThunk(
  "move_in/quote_add",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const { maintenance_id, type, property_id } = payload;
      dispatch(setLoading(true));
      const formData = new FormData();
      Object.keys(payload).map((key) => {
        if (key === "part") {
          // stringifying array of string
          formData.append(key, JSON.stringify(payload[key]));
        } else {
          formData.append(key, payload[key]);
        }
      });
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("user_token")}`,
      };
      if (PLATFORM !== "web") {
        headers["Content-Type"] = "multipart/form-data";
      }
      // const response = await fetch(API_URL + "/quote/add", {
      //   method: "POST",
      //   headers: headers,
      //   body: formData,
      // });
      // const data = await response.json();
      dispatch(setShowUploadProgress(true));
      const config = {
        onUploadProgress: ({ loaded, total }) => {
          const progress = (loaded / total) * 100;
          dispatch(setUploadProgress(progress));
        },
        method: "POST",
        url: "/quote/add",
        headers,
        transformRequest: (data, headers) => {
          return formData;
        },
        data: formData,
        responseType: "json",
      };

      // const data = await response.json();

      const { data } = await axiosInstance.request(config);

      if (data.success) {
        await dispatch(fetchMaintenanceListByProperty({ property_id, type }));
        await dispatch(fetchMaintenanceById({ maintenance_id }));
        dispatch(setLoading(false));
        return data.data;
      } else {
        dispatch(setLoading(false));
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

export const addT2Quote = createAsyncThunk(
  "move_in/add_t2_quotation",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const { maintenance_id, type } = payload;
      dispatch(setLoading(true));
      const formData = new FormData();
      Object.keys(payload).map((key) => {
        if (key === "part") {
          // stringifying array of string
          formData.append(key, JSON.stringify(payload[key]));
        } else {
          formData.append(key, payload[key]);
        }
      });
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("user_token")}`,
      };
      if (PLATFORM !== "web") {
        headers["Content-Type"] = "multipart/form-data";
      }
      // const response = await fetch(API_URL + "/quote/add_t2_quotation", {
      //   method: "POST",
      //   headers: headers,
      //   body: formData,
      // });
      // const data = await response.json();
      dispatch(setShowUploadProgress(true));
      const config = {
        onUploadProgress: ({ loaded, total }) => {
          const progress = (loaded / total) * 100;
          dispatch(setUploadProgress(progress));
        },
        method: "POST",
        url: "/quote/add_t2_quotation",
        headers,
        transformRequest: (data, headers) => {
          return formData;
        },
        data: formData,
        responseType: "json",
      };

      // const data = await response.json();

      const { data } = await axiosInstance.request(config);

      if (data.success) {
        await dispatch(fetchMaintenanceListByProperty({ type }));
        await dispatch(fetchMaintenanceById({ maintenance_id }));
        dispatch(setLoading(false));
        return data.data;
      } else {
        dispatch(setLoading(false));
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

export const updateQuote = createAsyncThunk(
  "move_in/quote_update",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const { maintenance_id, type, property_id } = payload;
      dispatch(setLoading(true));
      const formData = new FormData();
      Object.keys(payload).map((key) => {
        if (key === "part") {
          // stringifying array of string
          formData.append(key, JSON.stringify(payload[key]));
        } else {
          formData.append(key, payload[key]);
        }
      });
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("user_token")}`,
      };
      if (PLATFORM !== "web") {
        headers["Content-Type"] = "multipart/form-data";
      }
      // const response = await fetch(API_URL + "/quote/update", {
      //   method: "POST",
      //   headers: headers,
      //   body: formData,
      // });
      // const data = await response.json();
      dispatch(setShowUploadProgress(true));
      const config = {
        onUploadProgress: ({ loaded, total }) => {
          const progress = (loaded / total) * 100;
          dispatch(setUploadProgress(progress));
        },
        method: "POST",
        url: "/quote/update",
        headers,
        transformRequest: (data, headers) => {
          return formData;
        },
        data: formData,
        responseType: "json",
      };

      // const data = await response.json();

      const { data } = await axiosInstance.request(config);

      if (data.success) {
        await dispatch(fetchMaintenanceListByProperty({ property_id, type }));
        await dispatch(fetchMaintenanceById({ maintenance_id }));
        dispatch(setLoading(false));
        return data.data;
      } else {
        dispatch(setLoading(false));
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

export const pmRespondInitialCard = createAsyncThunk(
  "move_in/pm_respond_readiness_card",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const { maintenance_id, type, property_id } = payload;
      dispatch(setLoading(true));
      const { data } = await axios.post(
        `${API_URL}/movein/pm_respond_readiness_card`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      if (data.success) {
        await dispatch(fetchMaintenanceListByProperty({ property_id, type }));
        await dispatch(fetchMaintenanceById({ maintenance_id }));
        dispatch(setLoading(false));
        return data.data;
      } else {
        dispatch(setLoading(false));
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

export const fmRespondFinalCard = createAsyncThunk(
  "move_in/fm_respond_final_readiness_card",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const { maintenance_id, type, property_id } = payload;
      dispatch(setLoading(true));
      const { data } = await axios.post(
        `${API_URL}/movein_uric_final/fm_respond_final_readiness_card`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      if (data.success) {
        await dispatch(fetchMaintenanceListByProperty({ property_id, type }));
        await dispatch(fetchMaintenanceById({ maintenance_id }));
        dispatch(setLoading(false));
        return data.data;
      } else {
        dispatch(setLoading(false));
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

export const pmRespondFinalCard = createAsyncThunk(
  "move_in/pm_respond_final_readiness_card",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const { maintenance_id, type, property_id } = payload;
      dispatch(setLoading(true));
      const { data } = await axios.post(
        `${API_URL}/movein_uric_final/pm_respond_final_readiness_card`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      if (data.success) {
        await dispatch(fetchMaintenanceListByProperty({ property_id, type }));
        await dispatch(fetchMaintenanceById({ maintenance_id }));
        dispatch(setLoading(false));
        return data.data;
      } else {
        dispatch(setLoading(false));
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

export const pmGenerateCostInvoice = createAsyncThunk(
  "move_in/pm_generate_cost_invoice",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const { maintenance_id, type } = payload;
      dispatch(setLoading(true));
      const { data } = await axios.post(
        `${API_URL}/quote/create_invoice_against_quotation`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      if (data.success) {
        await dispatch(fetchMaintenanceListByProperty({ type }));
        await dispatch(fetchMaintenanceById({ maintenance_id }));
        dispatch(setLoading(false));
        return data.data;
      } else {
        dispatch(setLoading(false));
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

export const pmRespondQuote = createAsyncThunk(
  "move_in/t3_respond_quotation_request",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const { maintenance_id, type, property_id } = payload;
      dispatch(setLoading(true));
      const { data } = await axios.post(
        `${API_URL}/quote/t3_respond_quotation_request`,
        // `http://localhost:3000/quote/respond_quotation_request`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      if (data.success) {
        await dispatch(fetchMaintenanceListByProperty({ property_id, type }));
        await dispatch(fetchMaintenanceById({ maintenance_id }));
        dispatch(setLoading(false));
        return data.data;
      } else {
        dispatch(setLoading(false));
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

export const changeOrderStatus = createAsyncThunk(
  "move_in/fm_change_order_status",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const { maintenance_id, type, property_id } = payload;
      dispatch(setLoading(true));
      const { data } = await axios.post(
        `${API_URL}/quote/change_order_status`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      if (data.success) {
        await dispatch(fetchMaintenanceListByProperty({ type, property_id }));
        await dispatch(fetchMaintenanceById({ maintenance_id }));
        dispatch(setLoading(false));
        return data.data;
      } else {
        dispatch(setLoading(false));
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

export const assignMTPhase2 = createAsyncThunk(
  "move_in/assign_technician_for_maintenance_phase_2",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const { maintenance_id, type, property_id } = payload;
      dispatch(setLoading(true));
      const { data } = await axios.post(
        `${API_URL}/movein_phase2/assign_technician_for_maintenance`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      if (data.success) {
        await dispatch(fetchMaintenanceListByProperty({ type, property_id }));
        await dispatch(fetchMaintenanceById({ maintenance_id }));
        dispatch(setLoading(false));
        return data.data;
      } else {
        dispatch(setLoading(false));
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

export const mtRespondP2Task = createAsyncThunk(
  "move_in/technician_respond_to_maintenance_phase_2",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const { maintenance_id, type, property_id } = payload;
      dispatch(setLoading(true));
      const { data } = await axios.post(
        `${API_URL}/movein_phase2/technician_respond_to_maintenance`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      if (data.success) {
        await dispatch(fetchMaintenanceListByProperty({ property_id, type }));
        await dispatch(fetchMaintenanceById({ maintenance_id }));
        dispatch(setLoading(false));
        return data.data;
      } else {
        dispatch(setLoading(false));
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

export const mtChangeStatusP2 = createAsyncThunk(
  "move_in/technician_change_status_maintenance_phase_2",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const { maintenance_id, type, property_id } = payload;
      dispatch(setLoading(true));
      const { data } = await axios.post(
        `${API_URL}/movein_phase2/technician_change_maintenance_status`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      if (data.success) {
        await dispatch(fetchMaintenanceListByProperty({ property_id, type }));
        await dispatch(fetchMaintenanceById({ maintenance_id }));
        dispatch(setLoading(false));
        return data.data;
      } else {
        dispatch(setLoading(false));
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

export const mtFillP2Report = createAsyncThunk(
  "move_in/technician_fill_phase2_card",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const { maintenance_id, type, property_id } = payload;
      dispatch(setLoading(true));
      const formData = new FormData();
      Object.keys(payload).map((key) => {
        if (key === "document") {
          payload[key].forEach((item) => {
            if (PLATFORM !== "web") {
              formData.append(key, {
                name: item.name,
                type: item.type,
                uri:
                  PLATFORM === "android"
                    ? item.uri
                    : item.uri.replace("file://", ""),
              });
            } else {
              formData.append(key, item, item.name);
            }
          });
        } else {
          formData.append(key, payload[key]);
        }
      });
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("user_token")}`,
      };
      if (PLATFORM !== "web") {
        headers["Content-Type"] = "multipart/form-data";
      }
      // const response = await fetch(
      //   API_URL + "/movein_phase2/technician_fill_phase2_card",
      //   {
      //     method: "POST",
      //     headers: headers,
      //     body: formData,
      //   }
      // );
      // const data = await response.json();
      dispatch(setShowUploadProgress(true));
      const config = {
        onUploadProgress: ({ loaded, total }) => {
          const progress = (loaded / total) * 100;
          dispatch(setUploadProgress(progress));
        },
        method: "POST",
        url: "/movein_phase2/technician_fill_phase2_card",
        headers,
        transformRequest: (data, headers) => {
          return formData;
        },
        data: formData,
        responseType: "json",
      };

      // const data = await response.json();

      const { data } = await axiosInstance.request(config);

      if (data.success) {
        await dispatch(fetchMaintenanceListByProperty({ property_id, type }));
        await dispatch(fetchMaintenanceById({ maintenance_id }));
        dispatch(setLoading(false));
        return data.data;
      } else {
        dispatch(setLoading(false));
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

export const fmAssignMTOnReject = createAsyncThunk(
  "move_in/assign_individual_technician_for_maintenance",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const { maintenance_id, type, property_id } = payload;
      dispatch(setLoading(true));
      const { data } = await axios.post(
        `${API_URL}/movein_phase2/assign_individual_technician_for_maintenance`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      if (data.success) {
        await dispatch(fetchMaintenanceListByProperty({ property_id, type }));
        await dispatch(fetchMaintenanceById({ maintenance_id }));
        dispatch(setLoading(false));
        return data.data;
      } else {
        dispatch(setLoading(false));
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

export const fmRespondP2 = createAsyncThunk(
  "move_in/fm_respond_phase_2",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const { maintenance_id, type, property_id } = payload;
      dispatch(setLoading(true));
      const { data } = await axios.post(
        `${API_URL}/movein_phase2/fm_response_to_phase2`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      if (data.success) {
        await dispatch(fetchMaintenanceListByProperty({ property_id, type }));
        await dispatch(fetchMaintenanceById({ maintenance_id }));
        dispatch(setLoading(false));
        return data.data;
      } else {
        dispatch(setLoading(false));
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

export const pmRespondP2 = createAsyncThunk(
  "move_in/pm_respond_phase_2",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const { maintenance_id, type, property_id } = payload;
      dispatch(setLoading(true));
      const { data } = await axios.post(
        `${API_URL}/movein_phase2/pm_response_to_phase2`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      if (data.success) {
        await dispatch(fetchMaintenanceListByProperty({ property_id, type }));
        await dispatch(fetchMaintenanceById({ maintenance_id }));
        dispatch(setLoading(false));
        return data.data;
      } else {
        dispatch(setLoading(false));
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

export const moveinSlice = createSlice({
  name: "movein",
  initialState,
});

export default moveinSlice.reducer;
