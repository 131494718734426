/**
 *
 */

import React from "react";

/* Import configuration starts */
/* Import configuration ends */

/* Import redux slices component starts */
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
import { ExcalmatoryYellow } from "../../../setup/constants/images";
/* Import image and SVG ends */

/* Import local pages and component starts */
/* Import local pages and component ends */

/* Component starts */
const MissingTeamDetail = (props) => {
  /* Props destructuring starts */
  const { missingMemberDetails } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="missing-wrapper">
      <h3 className="font_m font_yellow">
        This Property don't have Team Members of following role assigned to it
        for full functioning of the app. Please onboard them to use all the
        features of the platform :-
      </h3>

      {missingMemberDetails?.map((role, index) => (
        <div className="missing-item">
          <img src={ExcalmatoryYellow} alt="" className="icon-wrapper" />
          <div key={index} dangerouslySetInnerHTML={{ __html: role }} />
        </div>
      ))}
    </div>
  );
};
/* Component ends */

export default MissingTeamDetail;
