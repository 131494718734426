/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
/* Import configuration ends */

/* Import redux slices component starts */
import { getUser } from "../../../../../setup/store/slices/authSlice";
import {
  fetchT2TenantInvoiceListBasedOnUnit,
  fetchTenantInvoiceListBasedOnUnit,
  getTenantInvoicesListBasedOnUnit,
} from "../../../../../setup/store/slices/invoiceSlice";
import {
  fetchUnitDetailByID,
  getUnitDetail,
} from "../../../../../setup/store/slices/unitSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
import { WarningRoundedBlue } from "../../../../../setup/constants/images";
/* Import image and SVG ends */

/* Import local pages and component starts */
import TabButton from "../../../../ui/other/TabButton/TabButton";
// import Searchbar from "../../../../ui/other/Searchbar/Searchbar";
import UnPaidInvoicing from "./sub-component/UnPaidInvoicing";
import PaidInvoicing from "./sub-component/PaidInvoicing";
/* Import local pages and component ends */

// Styles
import "./style.scss";

/* Component starts */
const TenantInvoicing = (props) => {
  /* Props destructuring starts */
  const {} = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [filteredInvoice, setFilteredInvoice] = useState();
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const dispatch = useDispatch();
  const { unitId, tab } = useParams();
  const user = useSelector(getUser);
  const tenantInvocies = useSelector(getTenantInvoicesListBasedOnUnit);
  const unitData = useSelector(getUnitDetail);

  const navigate = useNavigate();
  const { pathname } = useLocation();
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */

  const buttonList = [
    { key: "unpaid", label: "Unpaid Invoices" },
    {
      key: "paid",
      label: "Paid Invoices",
    },
  ];

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItemsList = filteredInvoice?.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  const onTabSelect = (key) => {
    const pathArr = pathname.split("/");
    pathArr.pop();
    navigate(pathArr.join("/") + `/${key}`);

    // setCurrentKey(key);
  };
  /* Component function definition ends */

  /* Component useEffect starts */
  useEffect(() => {
    // if (currentKey === "paid") {
    //   // dispatch(
    //   //   fetchTenantInvoiceListByUnit({
    //   //     tenant_id: user?._id,
    //   //     unit_id: unitId,
    //   //     status: "paid",
    //   //     page: currentPage,
    //   //   })
    //   // );
    //   dispatch(
    //     fetchTenantInvoiceListBasedOnUnit({
    //       tenant_id: user?._id,
    //       unit_id: unitId,
    //       // status: "paid",
    //       page: currentPage,
    //     })
    //   );
    // } else {
    // dispatch(
    //   fetchTenantInvoiceListByUnit({
    //     tenant_id: user?._id,
    //     unit_id: unitId,
    //     status: "unpaid",
    //     page: currentPage,
    //   })
    // );

    if (unitData?.property_id?.mgmt_model === 1) {
      dispatch(
        fetchT2TenantInvoiceListBasedOnUnit({
          tenant_id: user?._id,
          unit_id: unitId,
        })
      );
    } else {
      dispatch(
        fetchTenantInvoiceListBasedOnUnit({
          tenant_id: user?._id,
          unit_id: unitId,
          // status: "unpaid",
          //page: currentPage,
        })
      );
    }

    // }
  }, [tab, unitData]);

  useEffect(() => {
    dispatch(fetchUnitDetailByID({ unit_id: unitId }));
  }, []);

  useEffect(() => {
    if (tab === "unpaid") {
      let unpaidInv = tenantInvocies?.invoice_info?.filter(
        (item) =>
          item?.status === "unpaid" ||
          item?.status === "not_collected" ||
          item?.status === "partial paid" ||
          item?.status === "partially_collected"
      );

      setFilteredInvoice(unpaidInv);
    } else if (tab === "paid") {
      let paidInv = tenantInvocies?.invoice_info?.filter(
        (item) =>
          item?.status === "paid" ||
          item?.status === "collected" ||
          item?.status === "void"
      );

      setFilteredInvoice(paidInv);
    }
  }, [tab, tenantInvocies]);

  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="tenant-invoicing-wrapper">
      <div className="heading">
        <h2 className="font_xxl fw_6 text-start">Invoicing</h2>
      </div>

      {/* <Searchbar /> */}

      {tenantInvocies?.is_under_process && (
        <div className="under_process_wrapper bg_grey">
          <img src={WarningRoundedBlue} alt="Alert" className="w_100" />
          <p className="font_l fw_4">
            Dear Valued Tenant, we are in the process of completing your
            onboarding to the Unit, if you see any past invoice that previously
            paid, it will take a few days to get your account up to date
          </p>
        </div>
      )}

      {/* TAB BUTTONS */}
      <TabButton
        buttonList={buttonList}
        currentKey={tab}
        onSelect={onTabSelect}
      />

      {/* Display Table Data Based On The Current Tab */}

      {tab === "unpaid" ? (
        <UnPaidInvoicing
          list={currentItemsList}
          totalCount={filteredInvoice?.length}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          currency={unitData?.currency}
        />
      ) : (
        <PaidInvoicing
          list={currentItemsList}
          totalCount={filteredInvoice?.length}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          currency={unitData?.currency}
        />
      )}
    </div>
  );
};
/* Component ends */

export default TenantInvoicing;
