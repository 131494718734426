import GLOBAL from "../../../../setup/constants/global";

const tenantMaintenanceTabName = (tab) => {
  let tabName = "";
  switch (tab) {
    case "new":
      tabName = GLOBAL.MAINTENANCE.NEW;
      break;

    case "ongoing":
      tabName = GLOBAL.MAINTENANCE.ONGOING;
      break;

    case "spare_parts":
      tabName = GLOBAL.MAINTENANCE.SPARE_PARTS;
      break;

    case "completed":
      tabName = GLOBAL.MAINTENANCE.COMPLETED;
      break;

    case "rejected":
      tabName = GLOBAL.MAINTENANCE.REJECTED;
      break;

    case "escalated":
      tabName = GLOBAL.MAINTENANCE.ESCALATED;
      break;

    case "move_in":
      tabName = GLOBAL.MAINTENANCE.MOVE_IN;
      break;

    case "move_out":
      tabName = GLOBAL.MAINTENANCE.MOVE_OUT;
      break;

    case "units":
      tabName = GLOBAL.MAINTENANCE.UNITS;
      break;

    case "common_area":
      tabName = GLOBAL.MAINTENANCE.COMMON_AREA;
      break;

    case "closed":
      tabName = GLOBAL.MAINTENANCE.CLOSED;
      break;

    default:
      break;
  }

  return encodeURIComponent(tabName);
};

export const generateNavigation = (notification) => {
  const reference = notification?.reference;
  const underCommunity = !!reference?.community_id;
  const communityId = reference?.community_id || "";
  const propertyId = reference?.property_id || "";
  const unitId = reference?.unit_id || "";
  const advertisementId = reference?.advertisement_id || "";
  const invoiceId = reference.invoice_id || "";
  const intMaintenanceId = reference.maintenance_id || "";
  const maintenanceId = reference.maintenance_id || "";
  const tab = reference?.tab || "";
  const securityId = reference?.security_id || "";

  let generatedItemLink = "";
  const propertyLink = underCommunity
    ? `communities/${communityId}/properties/${propertyId}`
    : `independent/properties/${propertyId}`;

  switch (notification?.type) {
    case "community":
      return `/communities/${communityId}/`;

    case "unit_list":
      generatedItemLink = `/${propertyLink}/units`;
      break;

    case "unit":
      generatedItemLink = `/${propertyLink}/units/${unitId}`;
      break;

    case "property":
      generatedItemLink = `/${propertyLink}`;
      break;

    case "property_setting":
      generatedItemLink = `/${propertyLink}/settings`;
      break;

    case "property_invoice":
      generatedItemLink = `/${propertyLink}/invoicing/?type=Properties/${invoiceId}`;
      break;

    case "party_invoice":
      generatedItemLink = `/${propertyLink}/invoicing/?type=Parties/${invoiceId}`;
      break;

    case "tenant_invoice":
      generatedItemLink = `/${propertyLink}/invoicing/?type=Tenants/${invoiceId}`;
      break;

    case "internal_rating":
    case "internal_quotation":
    case "internal_maintenance":
      generatedItemLink = `/${propertyLink}/internal/maintenances/${tab}/${intMaintenanceId}`;
      break;

    case "tenant_rating":
    case "move_in":
    case "maintenance":
    case "move_out":
    case "quotation":
      generatedItemLink = `/${propertyLink}/maintenances/${tenantMaintenanceTabName(
        tab
      )}/${maintenanceId}`;
      break;

    case "invoice":
      generatedItemLink = `/license/manage/invoices/${invoiceId}`;
      break;

    case "team_onboarding":
      generatedItemLink = `/${propertyLink}/teams`;
      break;

    default:
      break;
  }

  return generatedItemLink;
};

export const generateNavigationTenant = (notification) => {
  const reference = notification?.reference;
  const underCommunity = !!reference?.community_id;
  const communityId = reference?.community_id || "";
  const propertyId = reference?.property_id || "";
  const unitId = reference?.unit_id || "";
  const advertisementId = reference?.advertisement_id || "";
  const invoiceId = reference.invoice_id || "";
  const intMaintenanceId = reference.maintenance_id || "";
  const maintenanceId = reference.maintenance_id || "";
  const securityId = reference?.security_id || "";
  const moveOutId = reference?.move_out_id || "";
  const contractId = reference?.contract_id || "";

  let generatedItemLink = "";
  const unitLink = `tenant/unit/${unitId}`;

  switch (notification?.type) {
    case "maintenance":
      generatedItemLink = `/${unitLink}/maintenance_requests/${maintenanceId}`;
      break;

    case "move_out":
      generatedItemLink = `/${unitLink}/contracts/${contractId}/moveout/${moveOutId}/clearence`;
      break;

    case "tenant_invoice":
      generatedItemLink = `/${unitLink}/invoicing/${invoiceId}`;
      break;

    default:
      break;
  }

  return generatedItemLink;
};
