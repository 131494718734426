/**
 *
 */

import React, { useEffect, useState } from "react";

/* Import configuration starts */
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { useParams } from "react-router-dom";
/* Import configuration ends */

/* Import redux slices component starts */
import { addMgmtModel } from "../../../../setup/store/slices/propertySlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import MUI component starts */
/* Import MUI component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import ErrorModal from "../../../ui/modal/ErrorModal/ErrorModal";
import CustomRadioInput from "../../../ui/input/CustomRadioInput/CustomRadioInput";
import TextButton from "../../../ui/button/TextButton/TextButton";
import CustomButton from "../../../ui/button/CustomButton/CustomButton";
/* Import local pages and component ends */

import "./style.scss";

/* Component starts */
const AddManagementType = (props) => {
  /* Props destructuring starts */
  const { setSelectedMgmtModel = () => {}, onSuccess, onCancel } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [selected, setSelected] = useState(null);
  const [showError, setShowError] = useState(false);
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const { propertyId } = useParams();
  // Redux Selector
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  const property_id = searchParams.get("property_id") || propertyId;
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  const onAddingMgmtModel = async () => {
    if (selected !== null) {
      setSelectedMgmtModel(selected);

      // Creating or collecting payload data to be sent
      const payload = {
        property_id: property_id,
        model: selected,
      };

      console.log("Payload > ", payload);

      const result = await dispatch(addMgmtModel(payload));
      console.log(result);
      onSuccess();
    }
  };
  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="add-management-type-wrapper-main">
      <h2 className="heading text-center font_xxl mb-5">
        Select Management Type
      </h2>
      <div className="form-wrapper">
        {/* <p className="font_l">How are you going to manage this property ?</p> */}

        {/* Radio select Management Model select */}
        <div className="select-mgmt-model w_100">
          {/* Signle User */}
          <div className="form-group">
            <div className="radio_wrapper">
              <CustomRadioInput
                label="Single User"
                isSelected={selected == 0}
                onSelecting={() => setSelected(0)}
              />
              <p className="font_s fw_6 description">
                For individual owner, managing fewer units.
              </p>
            </div>
            <h4 className="font_l font_yellow fw_6 transparent">Coming Soon</h4>
          </div>

          {/* FMC */}
          <div className="form-group">
            <div className="radio_wrapper">
              <CustomRadioInput
                label="Facility Management Company"
                isSelected={selected == 1}
                onSelecting={() => setSelected(1)}
              />

              <p className="font_s fw_6 description">
                For Facility management companies, that focus on Maintenance
                Only.
              </p>
            </div>
            <h4 className="font_l font_yellow fw_6 transparent">Coming Soon</h4>
          </div>

          {/* Real Estate */}
          <div className="form-group">
            <div className="radio_wrapper">
              <CustomRadioInput
                label="Real Estate"
                isSelected={selected == 2}
                onSelecting={() => setSelected(2)}
              />

              <p className="font_s fw_6 description">
                Designed for Real Estate companies that want manage a large
                portfolio of units, including, Facility Manangment, Security,
                and More.
              </p>
            </div>
            <h4 className="font_l font_yellow fw_6 transparent">Coming Soon</h4>
          </div>
        </div>
      </div>

      {/* Buttons. Submit, Cancel */}
      <div className="button-wrapper text-center">
        <CustomButton onClick={onAddingMgmtModel} size="l">
          Next
        </CustomButton>
        <TextButton className="fw_6" onClick={onCancel}>
          Cancel
        </TextButton>
      </div>

      {/* Modal */}
      {/* Error modal */}
      <ErrorModal
        showModal={showError}
        title="Missing Management Type"
        description="Select a Management Type for managing this property"
        onClose={() => {
          setShowError(false);
        }}
      />
    </div>
  );
};
/* Component ends */

export default AddManagementType;
