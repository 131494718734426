/**
 * This component is a Modal that is shown on success
 */

import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Formik } from "formik";
/* Import react bootstrap component starts */
import { Modal } from "react-bootstrap";
/* Import react bootstrap component ends */

/* Import image and SVG starts */
import { XGreen } from "../../../../../setup/constants/images";
/* Import image and SVG ends */

/* Import local pages and component starts */
import CustomTextArea from "../../../../ui/input/CustomTextArea/CustomTextArea";
import CustomFileInput from "../../../../ui/input/CustomFileInput/CustomFileInput";
import CustomButton from "../../../../ui/button/CustomButton/CustomButton";
import TextButton from "../../../../ui/button/TextButton/TextButton";
import Image from "../../../../ui/other/Image/Image";
import IconButton from "../../../../ui/button/IconButton/IconButton";
/* Import local pages and component ends */

import "./style.scss";
import { getUserTheme } from "../../../../../setup/store/slices/authSlice";

/* Component starts */
const AssigneeAddReportModal = (props) => {
  /* Props destructuring starts */
  const {
    showModal,
    size = "lg",
    onClose,
    title,
    prevReport,
    onSubmitReport,
    noImages = false,
    request_id,
    routine_id,
    routineTask = false,
  } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration Start */
  const fieldValue = {
    description: prevReport !== null ? prevReport?.description : "",
  };
  const [prevImages, setPrevImages] = useState([]);

  const [formValue, setFormValue] = useState(fieldValue);
  const [docs, setDocs] = useState([]);
  const [prevDocs, setPrevDocs] = useState([]);
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */

  // Redux Selector
  const dispatch = useDispatch();
  const formRef = useRef();

  const userTheme = useSelector(getUserTheme);
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */

  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */

  // Adding Document field
  const addMoreDoc = (formRef, setFormValue, docs, setDocs) => {
    const length = docs?.length;
    const currentFormState = { ...formRef.current.values };

    setFormValue(() => {
      // Generating new field name according to array indexes
      currentFormState[`document_${length}`] = null;
      return currentFormState;
    });
    setDocs((p) => [...p, length]);
  };

  // Remove Document field
  const removeDoc = (formRef, setFormValue, docs, setDocs) => {
    const lastIdx = docs.length - 1;
    const currentFormState = { ...formRef.current.values };

    setFormValue(() => {
      delete currentFormState[`document_${lastIdx}`];
      return currentFormState;
    });

    setDocs((prevState) => {
      const prevFields = [...prevState];
      prevFields.pop();
      return prevFields;
    });
  };

  // Removing Document from Previous document
  const handleRemove = (index) => {
    setPrevDocs((prev) => {
      const arr = [...prev];
      arr.splice(index, 1);
      return arr;
    });
  };

  /* Component function definition ends */

  /* Component useEffect Start */
  useEffect(() => {
    if (
      prevReport &&
      prevReport?.document &&
      prevReport?.document?.length > 0
    ) {
      setPrevDocs([...prevReport?.document]);
    }
  }, [prevReport]);

  console.log();

  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <Modal
      size={size}
      centered
      show={showModal}
      className="so-report-modal-wrapper"
    >
      <Modal.Body
        className={`${userTheme === "dark" ? "bg_darkTheme" : "bg_lightTheme"}`}
      >
        <div className="modal-header-custom">
          <p className="font_l fw_6 font_grey text-start">{title}</p>
        </div>
        <div className="modal-body-custom">
          <Formik
            onSubmit={(values) => {
              if (prevReport === null) {
                const payload = {
                  request_id,
                };
                const document = [];
                for (let i = 0; i < docs?.length; i++) {
                  document.push(values[`document_${docs[i]}`]);
                }

                payload["document"] = document;
                payload["description"] = values?.description;
                payload["previous_docs"] = JSON.stringify(
                  prevImages.map((item) => item.raw_url)
                );

                console.log(payload);
                onSubmitReport(payload);
              } else {
                const payload = {
                  request_id: !routineTask && request_id,
                  routine_id: routineTask && routine_id,
                };
                const document = [];

                for (let i = 0; i < docs?.length; i++) {
                  document.push(values[`document_${docs[i]}`]);
                }

                payload["document"] = document;
                payload["description"] = values?.description;
                payload["previous_docs"] = JSON.stringify(
                  prevDocs.map((item) => item?.raw_url)
                );
                console.log(prevDocs, "Payload", payload);
                onSubmitReport(payload);
              }
            }}
            initialValues={formValue}
            validateOnBlur={true}
            enableReinitialize={true}
            innerRef={formRef}
          >
            {({
              values,
              errors,
              touched,
              handleBlur,
              handleChange,
              handleSubmit,
              setFieldValue,
              setFieldTouched,
            }) => (
              <form onSubmit={handleSubmit}>
                {/* Form Fields */}
                <div className="form-wrapper form-wrapper-flex">
                  {/* Description */}
                  <div className="form-group form-group-full">
                    <CustomTextArea
                      name="description"
                      label="Description"
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      value={values.description}
                      errors={errors.description}
                      touched={touched.description}
                      required={true}
                    />
                  </div>

                  {prevReport?.document &&
                    prevReport?.document?.length > 0 &&
                    prevDocs.length > 0 && (
                      // <>
                      //   <p className="font_m fw_4 my-2">Previous Documents</p>
                      //   <div className="form-group form-group-full report-doc-wrapper bg_black">
                      //     {prevDocs?.map((image, idx) => (
                      //       <div key={idx}>
                      //         <span
                      //           className="cancel-btn font_xs fw_6"
                      //           onClick={() => handleRemove(idx)}
                      //         >
                      //           X
                      //         </span>
                      //         <img src={image.url} alt="Report-Image" />
                      //       </div>
                      //     ))}
                      //   </div>
                      // </>

                      <div className="prev-docs-section">
                        <h3 className="font_blue font_m fw_5">
                          Previous Attachments
                        </h3>
                        <div className="prev-docs">
                          {prevDocs.map((item, index) => (
                            <div key={index} className="doc-wrapper">
                              <IconButton
                                onClick={() => handleRemove(index)}
                                className="delete-btn"
                              >
                                <img src={XGreen} alt="Delete" />
                              </IconButton>
                              <Image
                                objectFit="cover"
                                src={item?.url}
                                videoThumbnail={item?.type2 === "video"}
                              />
                            </div>
                          ))}
                        </div>
                      </div>
                    )}

                  {/* Attachments */}
                  {!noImages && (
                    <div className="attachment w_100 mt-3">
                      <h3 className="font_blue font_m fw_5">Add Attachments</h3>
                      {docs?.length === 0 && (
                        <h3 className="font_yellow font_m fw_5 text-center attachments-not-added">
                          No attachment added !
                        </h3>
                      )}
                      {docs?.map((item, index) => (
                        <div
                          key={index}
                          className={index >= 1 ? "add-image mt-2" : ""}
                        >
                          <CustomFileInput
                            name={`document_${item}`}
                            label="Attachment"
                            setFieldTouched={setFieldTouched}
                            setFieldValue={setFieldValue}
                            value={values[`document_${item}`]}
                            touched={touched[`document_${item}`]}
                            errors={errors[`document_${item}`]}
                            formikState={true}
                            acceptable={true}
                            acceptType="image/*,video/*"
                          />
                        </div>
                      ))}

                      {/* Add or Remove Field Action */}
                      <div className="action-btn w_100 text-end">
                        <TextButton
                          onClick={() =>
                            addMoreDoc(formRef, setFormValue, docs, setDocs)
                          }
                          className="fw_6 font_m font_blue v_middle"
                        >
                          + Add More
                        </TextButton>
                        {docs.length > 0 && (
                          <TextButton
                            onClick={() =>
                              removeDoc(formRef, setFormValue, docs, setDocs)
                            }
                            className="fw_6 font_m font_red v_middle mx-2"
                          >
                            Remove
                          </TextButton>
                        )}
                      </div>
                    </div>
                  )}
                </div>

                {/* Submit and Cancel */}
                <div className="button-wrapper text-center">
                  <>
                    <CustomButton type="submit" size="l">
                      Submit
                    </CustomButton>
                    <CustomButton
                      // TextButton
                      // type="button"
                      // type="submit"
                      size="l"
                      variant="red"
                      onClick={onClose}
                      className="custom-text-btn"
                    >
                      Cancel
                    </CustomButton>
                  </>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </Modal.Body>
    </Modal>
  );
};
/* Component ends */

export default AssigneeAddReportModal;
