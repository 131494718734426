import React, { useEffect, useState } from "react";

/* Import configuration starts */
/* Import configuration ends */

/* Import redux slices component starts */
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
/* Import local pages and component ends */

/* Component starts */
import "./style.scss";

const CustomDateSelect = (props) => {
  /* Props destructuring starts */
  const { label = "Select Date", required = true, date, setDate } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [daysInCurrentMonth, setDaysInCurrentMonth] = useState([]);
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */

  const handleDayChange = (e) => {
    // setDate(Number(e.target.value));
    const selectedDay = Number(e.target.value);
    const today = new Date();
    const currentMonth = today.getMonth(); // Get the current month (0-11)

    // Create a new Date object with the selected day, current month, and current year
    const newDate = new Date(today.getFullYear(), currentMonth, selectedDay);
    setDate(newDate);
  };

  /* Component function definition ends */

  /* Component useEffect starts */

  useEffect(() => {
    const today = new Date();
    const currentYear = today.getFullYear();
    const currentMonth = today.getMonth() + 1; // Get current month (1-12)

    // Calculate the number of days in the current month
    const daysInMonth = new Date(currentYear, currentMonth, 0).getDate();

    // Create an array of days for the current month
    const daysArray = Array.from({ length: daysInMonth }, (_, i) => i + 1);
    setDaysInCurrentMonth(daysArray);
  }, []); // Empty dependency array means this runs once on component mount

  /* Component useEffect ends */

  return (
    <div className="time-select-container">
      <label className="font_s font_grey">
        {label}
        {required && <span className="font_m font_red"> *</span>}
      </label>
      <select
        value={date.getDate()}
        onChange={handleDayChange}
        className="select-wrap"
      >
        {daysInCurrentMonth?.map((day) => (
          <option key={day} value={day}>
            {day}
          </option>
        ))}
      </select>
    </div>
  );
};

export default CustomDateSelect;
