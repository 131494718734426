/**
 *
 */

import moment from "moment";
import React, { useState, useEffect } from "react";
import { Table } from "react-bootstrap";
import GLOBAL from "../../../../../setup/constants/global";

import IconButton from "../../../../ui/button/IconButton/IconButton";
import {
  EditGreen,
  ExcalmatoryYellow,
  EyeFilledBlue,
} from "../../../../../setup/constants/images";
import PaymentModal from "../../../Invoicing/ClientInvoicing/PaymentModal/PaymentModal";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { pathNamePopped } from "../../../../../setup/utils/global-helper";

/* Import configuration starts */
/* Import configuration ends */

/* Import redux slices component starts */
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
/* Import local pages and component ends */

/* Component starts */
const OutStandingInvoiceList = (props) => {
  /* Props destructuring starts */
  const { data, index, property, fetchContractDetails } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [invoiceData, setInvoiceData] = useState();
  const [showModal, setShowModal] = useState(false);
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const navigate = useNavigate();
  const location = useLocation();
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  const USER_DATA = localStorage.getItem("user_data")
    ? JSON.parse(localStorage.getItem("user_data"))
    : null;
  const DATE_FORMAT = USER_DATA ? USER_DATA?.date_format : "";
  const TIME_FORMAT = USER_DATA ? USER_DATA?.time_format : "";
  const DATE_TIME_FORMAT = `${DATE_FORMAT} ${TIME_FORMAT}`;

  const isOwner = localStorage.getItem("user_role") === "Owner";
  const isAcc = localStorage.getItem("user_role") === "Accountant";
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */

  const onClose = () => {
    setShowModal(!showModal);
  };

  const onSuccess = () => {
    setShowModal(!showModal);
    navigate(-1);
    // location.reload();
  };

  const handleReportView = (item) => {
    const newPathname = pathNamePopped(location?.pathname, 3);

    navigate(`${newPathname}/invoicing/Tenant/${item?.type}/${item?._id}`, {
      state: { pathname: location?.pathname },
    });
    // navigate(`${item?._id}`);
  };

  const handlePaymentModal = (item) => {
    setInvoiceData(item);
    setShowModal(!showModal);
  };

  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <>
      <div className="section">
        <h3 className="table-heading font_m mx-4">
          {index} - Tenant OutStanding Invoices
        </h3>
        <div className="custom-table-wrapper">
          {data?.length > 0 && (
            <Table
              className="custom-table font_s unit-table"
              borderless
              responsive
            >
              <thead>
                <tr>
                  <th>Invoice No.</th>
                  {/* <th>Unit</th> */}
                  <th>Type</th>
                  <th>Description</th>
                  <th>Total Amount</th>
                  <th>Status</th>
                  <th>Due Date</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {data?.map((item, index) => (
                  <tr key={index}>
                    {/* Name */}
                    <td>{item?.invoice_no}</td>

                    {/* title */}
                    <td>
                      {item?.type?.charAt(0)?.toUpperCase() +
                        item?.type?.slice(1)}
                    </td>

                    {/* Type */}
                    <td>
                      {item?.description !== undefined
                        ? item?.description
                        : "-"}
                    </td>

                    <td>
                      {item?.total_amount?.toLocaleString("en-US")}{" "}
                      {item?.currency}
                    </td>

                    <td
                      className={`${
                        item?.status === "paid" || item?.status === "collected"
                          ? "font_green"
                          : item?.status === "void" ||
                            item?.status === "adjusted" ||
                            item?.status === "settled"
                          ? "font_yellow"
                          : "font_red"
                      }`}
                    >
                      {item?.status === "paid" && "Paid"}
                      {item?.status === "collected" && "Collected"}
                      {item?.status === "partially_collected" &&
                        "Partially Collected"}
                      {item?.status === "not_collected" && "Not Collected"}
                      {item?.status === "partially_settled" &&
                        "Partially Settled"}
                      {item?.status === "settled" && "Settled"}
                      {item?.status === "partial-paid" && "Partial Paid"}
                      {item?.status === "unpaid" && "Unpaid"}
                      {item?.status === "void" && "Void"}
                      {item?.status === "adjusted" && "Adjusted"}
                    </td>

                    {/* Date of birth */}
                    <td>{moment(item?.due_date).format(DATE_FORMAT)}</td>
                    {/* File */}

                    {!item?.is_payable_by_cheque ? (
                      <td>
                        {(isOwner || isAcc) &&
                          (item?.status === "unpaid" ||
                            item?.status === "partial paid" ||
                            item?.status === "not_collected" ||
                            item?.status === "partially_collected") && (
                            // !item?.is_payable_by_cheque &&
                            <IconButton
                              onClick={() => handlePaymentModal(item)}
                              className="action-button "
                            >
                              <img
                                src={EditGreen}
                                className="h_100 mx-2"
                                alt="View Details"
                              />
                            </IconButton>
                          )}
                      </td>
                    ) : item?.is_payable_by_cheque && item?.type === "rent" ? (
                      <td>
                        {(item?.status === "unpaid" ||
                          item?.status === "partial paid") &&
                          item?.is_payable_by_cheque && (
                            <IconButton
                              onClick={() => handleReportView(item)}
                              className="action-button "
                            >
                              <img
                                // src={EditWhite}
                                src={ExcalmatoryYellow}
                                className="h_100 mx-2"
                                alt="View Details"
                              />
                            </IconButton>
                          )}
                      </td>
                    ) : (
                      <td>
                        {(item?.status === "unpaid" ||
                          item?.status === "partial paid") &&
                          item?.is_payable_by_cheque &&
                          item?.type !== "rent" && (
                            <IconButton
                              onClick={() => handlePaymentModal(item)}
                              className="action-button "
                            >
                              <img
                                src={EditGreen}
                                className="h_100 mx-2"
                                alt="View Details"
                              />
                            </IconButton>
                          )}
                      </td>
                    )}

                    {/* <td>
                    <IconButton
                      className="file-icon"
                      onClick={() => {
                        setFile(item.image);
                        setShowFile(true);
                      }}
                    >
                      <img src={DocumentGreen} alt="File" />
                    </IconButton>
                  </td> */}
                    {/* Action */}
                    {/* {approval && (
                    <td className="action-td">
                      {item.status ===
                        GLOBAL.TENANT_APPLICATION_STATUS.DATA_PENDING && (
                        <div className="action-btn-wrapper">
                          <button
                            onClick={() => onApprove(item)}
                            className="font_s fw_5 font_green accept"
                          >
                            Accept
                          </button>
                          <button
                            onClick={() => onReject(item)}
                            className="font_s fw_5 font_red reject"
                          >
                            Reject
                          </button>
                        </div>
                      )}
                      {item?.status ===
                        GLOBAL.TENANT_APPLICATION_STATUS.DATA_APPROVED && (
                        <p className="font_s font_green fw_5">Approved</p>
                      )}
                      {item?.status ===
                        GLOBAL.TENANT_APPLICATION_STATUS.DATA_REJECTED && (
                        <p className="font_s font_red fw_5">Rejected</p>
                      )}
                    </td>
                  )} */}
                  </tr>
                ))}
              </tbody>
            </Table>
          )}

          {data?.length === 0 && (
            <div className=" text-center">
              <h3 className=" font_m font_yellow fw_6">No Data</h3>
            </div>
          )}
        </div>
      </div>

      {/* MODALS */}
      <PaymentModal
        showModal={showModal}
        onClose={onClose}
        onSuccess={onSuccess}
        newType="tenant-Other"
        invoiceData={invoiceData}
        property_id={property?._id}
        afterPayment={() => {
          fetchContractDetails();
        }}
      />
    </>
  );
};
/* Component ends */

export default OutStandingInvoiceList;
