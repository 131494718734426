export const initialValues = {
  location: "",

  category: "",
  subcategory: "",
  innercategory: "",
  severity: "",

  description: "",
};

export const validations = (
  values,
  location,
  docList,
  subCategoryCount,
  innerCategoryCount
) => {
  const error = {};
  if (location === "") {
    error.location = "Location Required";
  }
  if (location?.name !== "In Unit") {
    if (values.category === "") {
      error.category = "Category Required";
    }
    if (subCategoryCount > 0 && values.subcategory === "") {
      error.subcategory = "Sub Category Required";
    }
    if (innerCategoryCount && values.innercategory === "") {
      error.innercategory = "Inner Category Required";
    }
  }

  if (values.description === "") {
    error.description = "Description Required";
  }
  docList.forEach((item, index) => {
    // const maxSize = 5 * 1024 * 1024; // 5 MB in bytes

    if (values[`document_${item}`] === null) {
      error[`document_${item}`] = "Document Required";
    }

    // if (values[`document_${item}`]?.size > maxSize) {
    //   error[`document_${item}`] =
    //     "File size is too large. Please upload a file smaller than or equal to 5 MB.";
    // }
  });
  return error;
};

export const addDoc = (formRef, docList, setInitialFormValues, setDocList) => {
  // const length = docList.length;
  const generetedID =
    docList.length === 0 ? 0 : docList[docList.length - 1] + 1;
  const currentFormState = { ...formRef.current.values };
  setInitialFormValues(() => {
    // Generating new field name according to array indexes
    // currentFormState[`document_${length}`] = null;
    // return currentFormState;
    currentFormState[`document_${generetedID}`] = null;
    return currentFormState;
  });
  setDocList((p) => [...p, generetedID]);
};

export const removeDoc = (
  formRef,
  docList,
  setInitialFormValues,
  setDocList,
  index
) => {
  const removeID = docList[index];
  const currentFormState = { ...formRef.current.values };

  setInitialFormValues(() => {
    delete currentFormState[`document_${removeID}`];
    return currentFormState;
  });

  setDocList((prevState) => {
    const prevFields = [...prevState];
    prevFields.splice(index, 1);
    return prevFields;
  });
};
