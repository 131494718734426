/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
/* Import configuration ends */

/* Import redux slices component starts */
import { fetchUser, getUser } from "../../../setup/store/slices/authSlice";
import {
  fetchUserRoles,
  getUserRoles,
} from "../../../setup/store/slices/masterSlice";
import { setLoading } from "../../../setup/store/slices/unpersistedSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import MUI component starts */
/* Import MUI component ends */

/* Import image and SVG starts */
import {
  DashboardOccupation,
  DashboardContract,
  DashboardFinance,
  DashboardMaintenanceInUnit,
  DashboardMaintenanceOutUnit,
  DashboardRoutine,
  DashboardSecurity,
  DashboardSpareParts,
  DashboardFacility,
  DashboardRating,
  DashboardTeams,
  DashboardViolation,
  DashboardMove,
  DashboardMoveIn,
} from "../../../setup/constants/images";
/* Import image and SVG ends */

/* Import local pages and component starts */
import IconButton from "../../../components/ui/button/IconButton/IconButton";
/* Import local pages and component ends */

/* Component starts */
const Modules = (props) => {
  /* Props destructuring starts */
  const {} = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [role, setRole] = useState();

  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userRoles = useSelector(getUserRoles);
  const user = useSelector(getUser);
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  /* Component function definition ends */

  /* Component useEffect starts */
  useEffect(() => {
    (async () => {
      try {
        await dispatch(setLoading(true));
        await dispatch(fetchUserRoles());
        await dispatch(fetchUser());
        await dispatch(setLoading(false));
      } catch (e) {}
    })();
  }, []);

  // Setting role after all user roles loaded
  useEffect(() => {
    let userRole = userRoles?.filter((d) => d?._id === user?.role_id)[0]?.name;
    setRole(userRole);
  }, [userRoles]);
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <>
      <div className="options">
        {/* Occupations */}
        {[
          "Client",
          "Owner",
          "Property Management Company",
          "Property Manager",
          "Accountant",
        ].includes(role) && (
          <IconButton
            className="box d_block"
            onClick={() => {
              navigate("occupancy");
            }}
          >
            <div className="icon text-center mx-auto">
              <img
                className="h_100"
                src={DashboardOccupation}
                alt="Unit Icon"
              />
            </div>
            <p className="text font_m fw_6 font_grey">Occupancy</p>
          </IconButton>
        )}

        {/* Contracts */}
        {[
          "Client",
          "Owner",
          "Property Management Company",
          "Property Manager",
          "Accountant",
        ].includes(role) && (
          <IconButton
            className="box d_block"
            onClick={() => {
              navigate("contracts");
            }}
          >
            <div className="icon text-center mx-auto">
              <img className="h_100" src={DashboardContract} alt="Unit Icon" />
            </div>
            <p className="text font_m fw_6 font_grey">Contracts</p>
          </IconButton>
        )}

        {/* Financial  */}
        {[
          "Client",
          "Owner",
          "Property Management Company",
          "Property Manager",
          "Accountant",
        ].includes(role) && (
          <IconButton
            className="box d_block"
            onClick={() => {
              navigate("financial");
            }}
          >
            <div className="icon text-center mx-auto">
              <img className="h_100" src={DashboardFinance} alt="Unit Icon" />
            </div>
            <p className="text font_m fw_6 font_grey">Financial</p>
          </IconButton>
        )}

        {/* Maintenance In Unit */}
        {[
          "Client",
          "Owner",
          "Property Manager",
          "Facility Management Company",
          "Facility Manager",
          "Maintenance Technician",
          "Third-Party Company",
          "Third Party Manager",
          "Third Party Technician",
        ].includes(role) && (
          <IconButton
            className="box d_block"
            onClick={() => {
              navigate("tenant-maintenance");
            }}
          >
            <div className="icon text-center mx-auto">
              <img
                className="h_100 tenant-main"
                src={DashboardMaintenanceInUnit}
                alt="Unit Icon"
              />
            </div>
            <p className="text font_m fw_6 font_grey">
              Tenant <br /> Maintenance
            </p>
          </IconButton>
        )}

        {/* Maintenance Out Unit */}
        {[
          "Client",
          "Owner",
          "Property Manager",
          "Facility Management Company",
          "Facility Manager",
          "Maintenance Technician",
          "Third-Party Company",
          "Third Party Manager",
          "Third Party Technician",
        ].includes(role) && (
          <IconButton
            className="box d_block"
            onClick={() => {
              navigate("internal-maintenance");
            }}
          >
            <div className="icon text-center mx-auto">
              <img
                className="h_100"
                src={DashboardMaintenanceOutUnit}
                alt="Unit Icon"
              />
            </div>
            <p className="text font_m fw_6 font_grey">
              Internal <br /> Maintenance
            </p>
          </IconButton>
        )}

        {/* Security */}
        {[
          "Client",
          "Owner",
          "Property Manager",
          "Security Management Company",
          "Security Manager",
          "Security Officer",
        ].includes(role) && (
          <IconButton
            className="box d_block"
            onClick={() => {
              navigate("security");
            }}
          >
            <div className="icon text-center mx-auto">
              <img className="h_100" src={DashboardSecurity} alt="Unit Icon" />
            </div>
            <p className="text font_m fw_6 font_grey">Security</p>
          </IconButton>
        )}

        {/* Spare Parts */}
        {[
          "Client",
          "Owner",
          "Property Manager",
          "Facility Management Company",
          "Facility Manager",
          "Third-Party Company",
          "Third Party Manager",
        ].includes(role) && (
          <IconButton
            className="box d_block"
            onClick={() => {
              navigate("spare-parts");
            }}
          >
            <div className="icon text-center mx-auto">
              <img
                className="h_100"
                src={DashboardSpareParts}
                alt="Maintenance Icon"
              />
            </div>
            <p className="text font_m fw_6 font_grey">Spare Parts</p>
          </IconButton>
        )}

        {/* Ratings */}
        {[
          "Client",
          "Owner",
          "Property Management Company",
          "Property Manager",
          "Facility Management Company",
          "Facility Manager",
          "Security Management Company",
          "Security Manager",
          "Third-Party Company",
          "Third Party Manager",
        ].includes(role) && (
          <IconButton
            className="box d_block"
            onClick={() => {
              navigate("ratings");
            }}
          >
            <div className="icon text-center mx-auto">
              <img
                className="h_100"
                src={DashboardRating}
                alt="Internal Icon"
              />
            </div>
            <p className="text font_m fw_6 font_grey">Ratings</p>
          </IconButton>
        )}

        {/* Teams  */}
        {[
          "Client",
          "Owner",
          "Property Management Company",
          "Property Manager",
          "Facility Management Company",
          "Facility Manager",
          "Security Management Company",
          "Security Manager",
          "Third-Party Company",
          "Third Party Manager",
        ].includes(role) && (
          <IconButton
            className="box d_block"
            onClick={() => {
              navigate("teams");
            }}
          >
            <div className="icon text-center mx-auto">
              <img className="h_100" src={DashboardTeams} alt="Unit Icon" />
            </div>
            <p className="text font_m fw_6 font_grey">Teams</p>
          </IconButton>
        )}

        {/* Voilation */}
        {[
          "Accountant",
          "Client",
          "Facility Management Company",
          "Facility Manager",
          "Owner",
          "Property Manager",
          "Security Management Company",
          "Security Manager",
          "Security Officer",
          "Third Party Manager",
          "Third Party Technician",
        ].includes(role) && (
          <IconButton
            className="box d_block"
            onClick={() => {
              navigate("violations");
            }}
          >
            <div className="icon text-center mx-auto">
              <img className="h_100" src={DashboardViolation} alt="Unit Icon" />
            </div>
            <p className="text font_m fw_6 font_grey">Violations</p>
          </IconButton>
        )}

        {/* Move In */}
        {[
          "Accountant",
          "Client",
          "Owner",
          "Property Management Company",
          "Property Manager",
          "Security Management Company",
          "Security Manager",
          "Security Officer",
        ].includes(role) && (
          <IconButton
            className="box d_block"
            onClick={() => {
              navigate("move-in");
            }}
          >
            <div className="icon text-center mx-auto">
              <img
                className="h_100"
                src={DashboardMoveIn}
                alt="Internal Icon"
              />
            </div>
            <p className="text font_m fw_6 font_grey">Move In</p>
          </IconButton>
        )}

        {/* Move Out */}
        {[
          "Accountant",
          "Client",
          "Owner",
          "Property Management Company",
          "Property Manager",
          "Security Management Company",
          "Security Manager",
          "Security Officer",
        ].includes(role) && (
          <IconButton
            className="box d_block"
            onClick={() => {
              navigate("move-out");
            }}
          >
            <div className="icon text-center mx-auto">
              <img className="h_100" src={DashboardMove} alt="Internal Icon" />
            </div>
            <p className="text font_m fw_6 font_grey">Move Out</p>
          </IconButton>
        )}
      </div>

      <hr className="divider" />

      <div className="future-options">
        {/* Routine */}
        {[
          "Client",
          "Owner",
          "Property Manager",
          "Facility Management Company",
          "Facility Manager",
          "Security Management Company",
          "Security Manager",
          "Security Officer",
          "Third-Party Company",
          "Third Party Manager",
        ].includes(role) && (
          <IconButton
            className="box d_block"
            // onClick={() => {
            //   navigate("routine");
            // }}
          >
            <div className="icon text-center mx-auto">
              <img
                className="h_100"
                src={DashboardRoutine}
                alt="Routine Icon"
              />
            </div>
            <p className="text font_m fw_6 font_grey">Routine</p>
            <span className="text font_m fw_6 font_yellow my-2">
              Comming Soon!
            </span>
          </IconButton>
        )}

        {/* Shared Facilities */}
        {[
          "Client",
          "Owner",
          "Property Manager",
          "Security Management Company",
          "Security Manager",
          "Security Officer",
        ].includes(role) && (
          <IconButton
            className="box d_block"
            // onClick={() => {
            //   navigate("shared-facilities");
            // }}
          >
            <div className="icon text-center mx-auto">
              <img
                className="h_100"
                src={DashboardFacility}
                alt="Routine Icon"
              />
            </div>
            <p className="text font_m fw_6 font_grey">
              Shared <br /> Facilities
            </p>
            <span className="text font_m fw_6 font_yellow my-2">
              Comming Soon!
            </span>
          </IconButton>
        )}
      </div>
    </>
  );
};
/* Component ends */

export default Modules;
