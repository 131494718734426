/**
 *
 */

import React, { useState, useEffect, useRef } from "react";

/* Import configuration starts */
import { Formik } from "formik";
import { useSelector, useDispatch } from "react-redux";
import GLOBAL from "../../../../setup/constants/global";
import { useParams } from "react-router-dom";
import {
  addMore,
  remove,
  intialFormState,
  formValidation,
  moveInMultipleTechnician,
} from "./helper";
/* Import configuration ends */

/* Import redux slices component starts */
import {
  listTechnicians,
  getTechnicians,
} from "../../../../setup/store/slices/maintenanceSlice";
import {
  assignMoveInTechnicianToCard,
  assignMTtoFinalCard,
  assignMTPhase2,
} from "../../../../setup/store/slices/moveInSlice";
import {
  showSuccessAlert,
  showErrorAlert,
} from "../../../../setup/store/slices/globalAlertSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
import { Modal } from "react-bootstrap";
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import CustomButton from "../../../ui/button/CustomButton/CustomButton";
import TextButton from "../../../ui/button/TextButton/TextButton";
import CustomSelectInput from "../../../ui/input/CustomSelectInput/CustomSelectInput";
import IconButton from "../../../ui/button/IconButton/IconButton";
import CustomTextArea from "../../../ui/input/CustomTextArea/CustomTextArea";
import { DeleteGrey } from "../../../../setup/constants/images";
/* Import local pages and component ends */

import "./style.scss";
import { getUserTheme } from "../../../../setup/store/slices/authSlice";

/* Component starts */
const AssignTechnicianMoveInModal = (props) => {
  /* Props destructuring starts */
  const {
    property,
    maintenance_id,
    showModal,
    size = "lg",
    onClose,
    maintenanceType,
    addMore = false,
    initialURIC = {},
    finalURIC = {},
    UIRCP2 = null,
    assignOnUIRCP1Reject,
    callBack = null,
    type = "",
    noteLabel = "Note",
    noteValidation = false,
    taskHeading = true,
    title = "Assign Technician",
    additionalTask,
  } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [formValues, setFormValues] = useState(intialFormState);
  const [technicianCount, setTechnicianCount] = useState([0]);
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const dispatch = useDispatch();
  const formRef = useRef();
  const { propertyId } = useParams();

  // Redux Selector
  const technicians = useSelector(getTechnicians);
  const userTheme = useSelector(getUserTheme);
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  const initialURICStatus = initialURIC?.status;
  const finalURICStatus = finalURIC?.status;
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  // On assiging technician for UIRC Phase 1 Initial
  const assignMTOnUIRCP1Initial = async (tech, note) => {
    onClose();
    const payload = {
      maintenance_id: maintenance_id,
      type: maintenanceType,
      technician: tech,
      notes: note,
      property_id: propertyId,
    };

    try {
      const result = await dispatch(assignMoveInTechnicianToCard(payload));

      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          dispatch(
            showErrorAlert({
              title: "Error!",
              description: result.payload,
            })
          );
          break;

        case GLOBAL.FULFILLED:
          dispatch(
            showSuccessAlert({
              title: "Success!",
              description: result.payload.message,
            })
          );
          break;
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  // On assiging technician for UIRC Phase 1 Final
  const assignMTOnUIRCP1Final = async (tech, note) => {
    // Creating or collecting payload data to be sent
    onClose();
    const payload = {
      maintenance_id: maintenance_id,
      type: maintenanceType,
      technician: tech,
      notes: note,
      property_id: propertyId,
    };

    // Backend response. Try, Catch
    try {
      const result = await dispatch(assignMTtoFinalCard(payload));

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          dispatch(
            showErrorAlert({
              title: "Error!",
              description: result.payload,
            })
          );
          break;

        case GLOBAL.FULFILLED:
          dispatch(
            showSuccessAlert({
              title: "Success!",
              description: result.payload.message,
            })
          );
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };

  // On assiging technician for UIRC Phase 1 Final
  const assignMTOnUIRCP2 = async (mts) => {
    // Creating or collecting payload data to be sent
    const technician = [];
    if (type.toLowerCase() === "additional") {
      technicianCount.forEach((item, index) => {
        technician.push({
          description: additionalTask[index]?.description,
          item_id: additionalTask[index]?._id,
          technician: mts[`technician_${item}`],
        });
      });
    } else {
      technicianCount.forEach((item, index) => {
        technician.push({
          item_id: UIRCP2?.items[index]?._id,
          technician: mts[`technician_${item}`],
        });
      });
    }

    const payload = {
      property_id: propertyId,
      maintenance_id,
      notes: mts.note,
      technician: JSON.stringify(technician),
      log_type:
        type.toLowerCase() === "additional" ? "additional" : "URIC Phase 2",
    };

    // Backend response. Try, Catch
    try {
      const result = await dispatch(assignMTPhase2(payload));

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          dispatch(
            showErrorAlert({
              title: "Error!",
              description: result.payload,
            })
          );
          break;

        case GLOBAL.FULFILLED:
          dispatch(
            showSuccessAlert({
              title: "Success!",
              description: result.payload.message,
            })
          );
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };

  // On submit and assiging technician
  const onSubmit = async (values) => {
    if (callBack) {
      const technician = [];
      technicianCount.forEach((item, index) => {
        technician.push({
          _id: values[`technician_${item}`],
        });
      });
      callBack(technician, values["note"]);
    } else if (type === "Reject and Assign" && assignOnUIRCP1Reject) {
      // For Rejecting UIRC Phase 1 Intial
      await assignOnUIRCP1Reject(values["technician_0"], values["note"]);
    } else if (type === "URIC Final") {
      // For Assigining Technician on UIRC Phase 1 Final
      assignMTOnUIRCP1Final(values["technician_0"], values["note"]);
    } else if (
      type === "URIC Phase 2" ||
      type.toLowerCase() === "additional" ||
      type.toLowerCase() === "order"
    ) {
      // For Assigining Technician on UIRC Phase 2
      assignMTOnUIRCP2(values);
    } else {
      // For Assigining Technician on UIRC Phase 1 Intial
      assignMTOnUIRCP1Initial(values["technician_0"], values["note"]);
    }
  };
  /* Component function definition ends */

  /* Component useEffect starts */
  // Fetching Technicians available on selected property
  useEffect(() => {
    if (property) {
      dispatch(listTechnicians({ property_id: property._id }));
    }
  }, [property]);

  // Prefilling form count for move in multiple technician
  useEffect(() => {
    if (
      maintenanceType === "Move In" &&
      type.toLowerCase() === "additional" &&
      additionalTask &&
      additionalTask.length > 0
    ) {
      moveInMultipleTechnician(
        additionalTask,
        setTechnicianCount,
        setFormValues
      );
    } else if (
      maintenanceType === "Move In" &&
      UIRCP2 &&
      UIRCP2?.items.length > 0
    ) {
      moveInMultipleTechnician(
        UIRCP2?.items,
        setTechnicianCount,
        setFormValues
      );
    }
  }, [maintenanceType, finalURIC, finalURICStatus, type, additionalTask]);
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <Modal
      size={size}
      centered
      show={showModal}
      className="assign-technician-modal-wrapper"
    >
      <Modal.Body
        className={`${
          userTheme === "dark"
            ? "bg_darkTheme"
            : "bg_lightTheme font_lightTheme"
        }`}
      >
        <div className="modal-header-custom">
          <p
            className={`font_l fw_6 ${
              userTheme !== "dark" && "font_blue"
            } font_white text-center`}
          >
            {title}
          </p>
        </div>
        <div className="modal-body-custom">
          <Formik
            onSubmit={onSubmit}
            initialValues={formValues}
            validate={(values) =>
              formValidation(values, technicianCount, noteValidation, noteLabel)
            }
            validateOnBlur={true}
            enableReinitialize={true}
            innerRef={formRef}
          >
            {({
              values,
              errors,
              touched,
              handleBlur,
              handleChange,
              handleSubmit,
              setFieldValue,
            }) => (
              <form onSubmit={handleSubmit}>
                {/* Form Fields */}
                <div className="form-wrapper form-wrapper-flex">
                  <div className="form-group-wrapper">
                    {/* Primary Task */}
                    {type.toLowerCase() !== "additional" &&
                      technicianCount?.map((itm, idx) => (
                        <div key={idx}>
                          {taskHeading && (
                            <p className="font_blue fw_6 font_m">
                              Task #{idx + 1}
                            </p>
                          )}

                          {/* Item Description */}
                          {maintenanceType === "Move In" &&
                            finalURICStatus === "Approved" &&
                            finalURIC?.items.length > 0 && (
                              <p className="item">
                                <span className="font_s fw_5 font_yellow">
                                  Task Description :
                                </span>{" "}
                                <span className="font_s font_grey fw_5">
                                  {UIRCP2?.items[idx].description}
                                </span>
                              </p>
                            )}

                          {/* Select Technician */}
                          <div className="form-group">
                            <CustomSelectInput
                              name={`technician_${idx}`}
                              label="Technician"
                              handleBlur={handleBlur}
                              handleChange={handleChange}
                              valueExtractor={(item) =>
                                `${item?.first_name} ${item?.last_name}`
                              }
                              optionValueExtractor={(item) => item?._id}
                              options={technicians}
                              value={values[`technician_${idx}`]}
                              touched={touched[`technician_${idx}`]}
                              errors={errors[`technician_${idx}`]}
                              formikState={true}
                              emptyOption={true}
                            />
                          </div>

                          <hr />
                        </div>
                      ))}

                    {/* Additional Task */}
                    {type.toLowerCase() === "additional" &&
                      additionalTask?.map((itm, idx) => (
                        <div key={idx}>
                          {taskHeading && (
                            <p className="font_blue fw_6 font_m">
                              Task #{idx + 1}
                            </p>
                          )}

                          {/* Item Description */}
                          {maintenanceType === "Move In" &&
                            finalURICStatus === "Approved" && (
                              <p className="item">
                                <span className="font_s fw_5 font_yellow">
                                  Task Description :{" "}
                                </span>
                                <span className="font_s font_grey fw_5">
                                  {additionalTask[idx].description}
                                </span>
                              </p>
                            )}

                          {/* Select Technician */}
                          <div className="form-group">
                            <CustomSelectInput
                              name={`technician_${idx}`}
                              label="Technician"
                              handleBlur={handleBlur}
                              handleChange={handleChange}
                              valueExtractor={(item) =>
                                `${item?.first_name} ${item?.last_name}`
                              }
                              optionValueExtractor={(item) => item?._id}
                              options={technicians}
                              value={values[`technician_${idx}`]}
                              touched={touched[`technician_${idx}`]}
                              errors={errors[`technician_${idx}`]}
                              formikState={true}
                              emptyOption={true}
                            />
                          </div>

                          <hr />
                        </div>
                      ))}

                    {(addMore ||
                      (maintenanceType && maintenanceType !== "Move In")) && (
                      <div className="action-btn">
                        <TextButton
                          onClick={() =>
                            addMore(
                              formRef,
                              setFormValues,
                              technicianCount,
                              setTechnicianCount
                            )
                          }
                          className="fw_6 font_m font_blue v_middle"
                        >
                          + Add More
                        </TextButton>
                        <TextButton
                          onClick={() =>
                            remove(
                              formRef,
                              setFormValues,
                              technicianCount,
                              setTechnicianCount
                            )
                          }
                          className="fw_6 font_m font_red v_middle"
                        >
                          Remove
                        </TextButton>
                      </div>
                    )}
                    {/* Note */}
                    <div className="form-group-full">
                      <CustomTextArea
                        name={`note`}
                        label={noteLabel}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        value={values[`note`]}
                        touched={touched[`note`]}
                        errors={errors[`note`]}
                        required={noteValidation}
                      />
                    </div>
                  </div>
                </div>

                {/* Submit and Cancel */}
                <div className="form-btn-wrapper">
                  <>
                    <CustomButton
                      variant="red"
                      size="m"
                      onClick={() => {
                        setFormValues(intialFormState);
                        onClose();
                      }}
                    >
                      Cancel
                    </CustomButton>
                    <CustomButton type="submit" size="m">
                      {title === "Reject And Assign Technician" ? "Reject" : "Assign"}
                    </CustomButton>
                  </>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </Modal.Body>
    </Modal>
  );
};
/* Component ends */

export default AssignTechnicianMoveInModal;
