/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
/* Import configuration ends */

/* Import redux slices component starts */
import {
  fetchSecurityIncidents,
  getSecurityComplaints,
  getSecurityIncidents,
  fetchSecurityComplaintRequests,
} from "../../../setup/store/slices/securitySlice";

import {
  fetchPropertyDetailByID,
  getPropertyDetail,
} from "../../../setup/store/slices/propertySlice";
import { setLoading } from "../../../setup/store/slices/unpersistedSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import Searchbar from "../../../components/ui/other/Searchbar/Searchbar";
import SecurityTable from "../../../components/module/SecurityManage/ClientSecurityManage/SecurityTable/SecurityTable";
import TabButton from "../../../components/ui/other/TabButton/TabButton";
import SecurityIncidentTable from "../../../components/module/SecurityManage/ClientSecurityManage/SecurityIncidentTable/SecurityIncidentTable";
import BreadCrumb from "../../../components/ui/other/BreadCrumb/BreadCrumb";
/* Import local pages and component ends */

// styles sheet
import "./style.scss";

/* Component starts */
const ClientSecurity = (props) => {
  /* Props destructuring starts */
  const {} = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [buttonLists, setButtonLists] = useState([]);
  const [subButtonLists, setSubButtonLists] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { propertyId, securityListTab, securityListSubTab } = useParams();
  /* Other hooks declaration ends */

  // Redux Selectors
  const property = useSelector(getPropertyDetail);
  const complainList = useSelector(getSecurityComplaints);
  const incidentList = useSelector(getSecurityIncidents);
  const pathnameArrList = pathname.split("/");
  /* Component variable declaration / object destructure starts */

  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  const onTabSelect = (key) => {
    const pathnameArr = pathname.split("/");
    pathnameArr.pop();
    pathnameArr.pop();
    navigate(pathnameArr.join("/") + "/" + key + "/open");
    if (key === "closed") {
      navigate(pathnameArr.join("/") + "/" + key + "/complaints");
    }

    // setCurrentKey(key);
    // const tabPath = pathname.split("/");
    // // tabPath.pop();
    // navigate(tabPath.join("/") + "/" + key);
  };

  const onSubTabSelect = (key) => {
    const pathnameArr = pathname.split("/");
    pathnameArr.pop();
    navigate(pathnameArr.join("/") + "/" + key);
    // setCurrentSubKey(key);
  };

  /* Component function definition ends */

  /* Component useEffect starts */

  useEffect(() => {
    (async () => {
      try {
        await dispatch(setLoading(true));
        await dispatch(fetchPropertyDetailByID({ property_id: propertyId }));
        await dispatch(setLoading(false));
      } catch (e) {}
    })();
  }, [propertyId]);

  useEffect(() => {
    if (securityListTab === "incidents" || securityListTab === "closed") {
      if (securityListSubTab === "open") {
        dispatch(
          fetchSecurityIncidents({
            property_id: propertyId,
            page: currentPage,
          })
        );
      }

      if (
        pathnameArrList?.includes("incidents") &&
        securityListTab === "closed"
      ) {
        dispatch(
          fetchSecurityIncidents({
            property_id: propertyId,
            status: "Closed",
            page: currentPage,
          })
        );
      }
    }
    if (securityListTab === "complaints" || securityListTab === "closed") {
      if (pathnameArrList?.includes("closed")) {
        dispatch(
          fetchSecurityComplaintRequests({
            property_id: propertyId,
            status: "resolved",
            page: currentPage,
          })
        );
      } else {
        dispatch(
          fetchSecurityComplaintRequests({
            property_id: propertyId,
            page: currentPage,
          })
        );
      }
    }
  }, [currentPage, securityListTab, securityListSubTab]);

  useEffect(() => {
    if (property) {
      if (property?.mgmt_model === 2) {
        setButtonLists([
          { key: "complaints", label: "Complaints" },
          { key: "incidents", label: "Incidents" },
          { key: "closed", label: "Closed" },
        ]);
        setSubButtonLists([
          { key: "complaints", label: "Complaints" },
          {
            key: "incidents",
            label: "Incident",
          },
        ]);
      } else {
        setButtonLists([
          { key: "complaints", label: "Complaints" },
          { key: "closed", label: "Closed" },
        ]);
      }
    }
  }, [property]);

  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */

  return (
    <>
      <BreadCrumb type="client_security" />

      <div className="security">
        <div className="heading">
          <h2 className="font_xxl fw_6">Security</h2>
          {property?.mgmt_model === 2 &&
            pathnameArrList?.includes("incidents") && (
              <Link to="add" className="font_m fw_6 d_block link">
                + Create Security Incident
              </Link>
            )}
        </div>

        {/* <Searchbar /> */}

        {/* TAB BUTTONS */}
        <TabButton
          buttonList={buttonLists}
          currentKey={securityListTab}
          onSelect={onTabSelect}
        />

        {securityListTab === "complaints" ? (
          <SecurityTable
            complainList={complainList?.securityComplaints}
            totalCount={complainList?.total}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        ) : securityListTab === "incidents" ? (
          <SecurityIncidentTable
            incidentList={incidentList?.securityIncident}
            totalCount={incidentList?.totalCount}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        ) : (
          <>
            <TabButton
              buttonList={subButtonLists}
              currentSubKey={securityListSubTab}
              onSelect={onSubTabSelect}
            />
            {securityListSubTab === "complaints" ? (
              <SecurityTable
                complainList={complainList?.securityComplaints}
                totalCount={complainList?.total}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
              />
            ) : (
              <SecurityIncidentTable
                incidentList={incidentList?.securityIncident}
                totalCount={incidentList?.totalCount}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
              />
            )}
          </>
        )}
      </div>
    </>
  );
};
/* Component End */

export default ClientSecurity;
