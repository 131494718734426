/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import { useNavigate, Link } from "react-router-dom";
import { useSelector } from "react-redux";
/* Import configuration ends */

/* Import redux slices component starts */
import { getUser, getUserTheme } from "../../setup/store/slices/authSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
import { RightArrowOutlineGrey } from "../../setup/constants/images";
/* Import image and SVG ends */

/* Import local pages and component starts */
import BreadCrumb from "../../components/ui/other/BreadCrumb/BreadCrumb";
import DateTimeChangeFormatModal from "../../components/module/AccountSettings/DateTimeChangeFormatModal/DateTimeChangeFormatModal";
/* Import local pages and component ends */

import "./style.scss";

/* Component starts */
const SettingsPage = (props) => {
  /* Props destructuring starts */
  const {} = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [openDateTimeChangeModal, setOpenDateTimeChangeModal] = useState(false);
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const navigate = useNavigate();
  // Redux Selector
  const user = useSelector(getUser);
  const isTenant = localStorage.getItem("user_role") === "Tenant";
  const isClient = localStorage.getItem("user_role") === "Client";
  const userTheme = useSelector(getUserTheme);
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <>
      {!isTenant && <BreadCrumb type="settings_page" />}
      <div className="settings-page-wrapper container_sm">
        <h2 className="heading text-center font_xxl fw_6">Settings</h2>
        <div className="settings-content-wrapper">
          {/* Links */}
          <div className="links">
            {/* Manage License */}
            {isClient && (
              <div className="link-wrapper">
                <Link className="link font_m fw_5 d_block" to="manageLicense">
                  Manage License
                  <img
                    src={RightArrowOutlineGrey}
                    className="icon"
                    alt="Right arrow icon"
                  />
                </Link>
              </div>
            )}

            {/* Manage Cards */}
            {isClient && (
              <div className="link-wrapper">
                <Link className="link font_m fw_5 d_block" to="manageCard">
                  Manage Cards
                  <img
                    src={RightArrowOutlineGrey}
                    className="icon"
                    alt="Right arrow icon"
                  />
                </Link>
              </div>
            )}

            {/* Payment Settings */}
            <div className="link-wrapper">
              {isTenant && (
                <Link className="link font_m fw_5 d_block" to="auto_payment">
                  Payment Settings
                  <img
                    src={RightArrowOutlineGrey}
                    className="icon"
                    alt="Right arrow icon"
                  />
                </Link>
              )}
              {isClient && (
                <>
                  <Link className="link font_m fw_5 d_block" to="payment">
                    Payment Settings
                    <img
                      src={RightArrowOutlineGrey}
                      className="icon"
                      alt="Right arrow icon"
                    />
                  </Link>
                </>
              )}
              {/* )} */}
            </div>

            {/* Change Password */}
            <div className="link-wrapper">
              <Link
                className="link font_m fw_5 d_block"
                to="password/change"
                state={user?.email}
              >
                Change Password
                <img
                  src={RightArrowOutlineGrey}
                  className="icon"
                  alt="Right arrow icon"
                />
              </Link>
            </div>

            {/* Change Theme */}
            <div className="link-wrapper">
              <Link
                className="link font_m fw_5 d_block"
                to="theme"
                // to="password/change"
                // state={user.email}
              >
                Change Theme
                <img
                  src={RightArrowOutlineGrey}
                  className="icon"
                  alt="Right arrow icon"
                />
              </Link>
            </div>

            {/* Change Date and Time Format */}
            <div className="link-wrapper">
              <div
                onClick={() => setOpenDateTimeChangeModal(true)}
                className={`link-date font_m fw_5 d_block ${
                  userTheme !== "dark" ? "font_lightTheme" : "font_white"
                } `}
              >
                Change Date and Time Format
                <img
                  src={RightArrowOutlineGrey}
                  className="icon"
                  alt="Right arrow icon"
                />
              </div>
            </div>

            {/* Account Suspension */}
            {isClient && (
              <div className="link-wrapper">
                <Link
                  className="link font_m fw_5 d_block"
                  to="account-suspension"
                  // to="password/change"
                  // state={user.email}
                >
                  {/* Account Suspension */}
                  Account Pause
                  <img
                    src={RightArrowOutlineGrey}
                    className="icon"
                    alt="Right arrow icon"
                  />
                </Link>
              </div>
            )}

            {/* Help and Support */}
            {/* <div className="link-wrapper">
              <Link className="link font_m fw_5 d_block" to="helpAndSupport">
                Help and Support
                <img
                  src={RightArrowOutlineGrey}
                  className="icon"
                  alt="Right arrow icon"
                />
              </Link>
            </div> */}

            {/* FAQs */}
            {/* <div className="link-wrapper">
              <Link className="link font_m fw_5 d_block" to="FAQs">
                Frequently Asked Questions
                <img
                  src={RightArrowOutlineGrey}
                  className="icon"
                  alt="Right arrow icon"
                />
              </Link>
            </div> */}
          </div>
        </div>

        <DateTimeChangeFormatModal
          showModal={openDateTimeChangeModal}
          onClose={() => setOpenDateTimeChangeModal(false)}
        />
      </div>
    </>
  );
};
/* Component ends */

export default SettingsPage;
