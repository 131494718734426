import React, { useEffect, useRef } from "react";

/* Import configuration starts */
import { browserSupported } from "./helper";
import { useLocation, useNavigate } from "react-router-dom";
import CustomRoutes from "../routes";
import { useSelector, useDispatch } from "react-redux";
import { setLocale } from "../i18n";
// import { useCustomEventListener } from "react-custom-events";
import { Amplify, Auth } from "aws-amplify";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { requestForToken } from "../config/firebase";
import SunCalc from "suncalc";
import useBrowserInfo from "./useBrowserInfo";

/* Import configuration ends */

/* Import redux slice starts */
import { selectedLocale } from "../store/slices/localeSlice";
import { logout, setBrowser, getBrowser } from "../store/slices/masterSlice";
import {
  setTokenExpiredModal,
  getLoading,
} from "../store/slices/unpersistedSlice";
import { fetchSetting } from "../store/slices/settingSlice";
import {
  closeAlert,
  getShowAlert,
  getAlertType,
  getAlertDescription,
  getAlertTitle,
} from "../store/slices/globalAlertSlice";
import {
  getUser,
  getAccessToken,
  setUser,
  getUserTheme,
  setUserRole,
} from "../store/slices/authSlice";
/* Import redux slice ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import SuccessModal from "../../components/ui/modal/SuccessModal/SuccessModal";
import WarningModal from "../../components/ui/modal/WarningModal/WarningModal";
import ErrorModal from "../../components/ui/modal/ErrorModal/ErrorModal";
import Loader from "../../components/ui/other/Loader/Loader";
import Notification from "../../components/module/Notificationx/Notification/Notification";
import { useState } from "react";
import { BgShadeSignUp } from "../constants/images";
import { getAutomaticTheme } from "../utils/global-helper";
import NotSupportedBrowser from "./NotSupportedBrowser";
/* Import local pages and component ends */

import "./style.scss";

/* Component starts */
const FrontEnd = (props) => {
  /* Props destructuring starts */
  const { settings, fetchSettings } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const isSessionExpired = useRef(false);
  const [autoTheme, setAutoTheme] = useState("");
  const [showBrowserCompatibleError, setShowBrowserCompatibleError] =
    useState(false);
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { info, loaded } = useBrowserInfo();

  // Using custom event listner for axios interceptor
  // useCustomEventListener("session_expired", async (data) => {
  //   await dispatch(logout());
  //   await dispatch(setTokenExpiredModal(true));
  //   await dispatch(setUser(null));
  //   navigate("/signin");
  //   console.log("Your session is expired");
  // });

  // Redux Selector
  const locale = useSelector(selectedLocale);
  const showAlert = useSelector(getShowAlert);
  const alertType = useSelector(getAlertType);
  const alertDescription = useSelector(getAlertDescription);
  const alertTitle = useSelector(getAlertTitle);
  const isLoading = useSelector(getLoading);
  const user = useSelector(getUser);
  const accessToken = useSelector(getAccessToken);
  const browser = useSelector(getBrowser);
  const userTheme = useSelector(getUserTheme);

  const [localUser, setLocalUser] = useState(
    JSON.parse(localStorage.getItem("user_data"))
  );

  const [localUserRole, setLocalUserRole] = useState(
    localStorage.getItem("user_role")
  );
  console.log(userTheme, "THEME");

  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  // if (loaded) console.log("Your Browser is", browser);
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  // On close global alert
  const closeGlobalAlert = () => {
    dispatch(closeAlert());
  };

  const getAutomaticTheme = () => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const { latitude, longitude } = position.coords;

        const now = new Date();

        // Get sun phase times for today, in the current user's location
        const { sunrise, sunset } = SunCalc.getTimes(now, latitude, longitude);

        // Compare sunrise and sunset with current time
        if (now < sunrise || now > sunset) {
          // Dark mode: Before sunrise, or after sunset
          console.log("DARK");
          setAutoTheme("bg_darkgrey");
        } else {
          // Light mode: Any other time
          setAutoTheme("light_theme");
          console.log("LIGHT");
        }
      },
      (err) => {
        console.log(err, "ERR");
        setAutoTheme("light_theme");
      }
    );
  };

  /* Component function definition ends */

  /* Component useEffect starts */
  useEffect(() => {
    if (locale) {
      setLocale(locale);
      document.body.dir = locale === "ar" ? "rtl" : "ltr";
    }
  }, [locale]);

  // Fetching user settings on initial load
  useEffect(() => {
    fetchSettings({ init: true });
  }, []);

  // Initializing AWS Amplify on load of user settings
  useEffect(() => {
    if (settings.awsRegion && settings.awsRegion != "") {
      Amplify.configure({
        Auth: {
          region: settings.awsRegion,
          userPoolId: settings.awsPoolId,
          userPoolWebClientId: settings.awsClientId,
        },
      });

      Auth.configure();
    }
  }, [settings]);

  // On changing routes or switching path
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  // Logging Access Token and User Details
  useEffect(() => {
    // console.log("User : ", user);
    // console.log("Access Token : ", accessToken);
  }, [user, accessToken]);

  useEffect(() => {
    if (userTheme === "automatic") getAutomaticTheme();
  }, [userTheme, autoTheme]);

  useEffect(() => {
    dispatch(setUser(localUser));
    dispatch(setUserRole(localUserRole));
  }, []);

  useEffect(() => {
    if (loaded) {
      dispatch(setBrowser(info));
      setShowBrowserCompatibleError(!browserSupported(info));
    }
  }, [loaded]);
  /* Component useEffect ends */

  // if (loaded && !browserSupported(info)) {
  //   return <NotSupportedBrowser data={info} />;
  // }

  /* ************* Component rendering. JSX code ************* */
  return (
    <div
      className={`front-end-wrapper 
        ${showBrowserCompatibleError ? "disable-scroll" : ""}
    ${
      userTheme === "light"
        ? "light_theme"
        : userTheme === "automatic" // ADD function to get the theme automatically
        ? autoTheme
        : "bg_darkgrey"
    }
    `}
    >
      {showBrowserCompatibleError && (
        <NotSupportedBrowser
          data={info}
          onClose={() => {
            setShowBrowserCompatibleError(false);
          }}
        />
      )}
      {/* BACKGROUND DOTS */}
      <div className="blur-blue" style={{ top: "3%", left: "-5%" }}></div>
      <div className="blur-cherry" style={{ top: "10%", right: "7%" }}></div>
      <div className="blur-blue" style={{ top: "82%", left: "3%" }}></div>

      {/* BACKGROUND DOTS */}

      <div className="bgShade">
        <img src={BgShadeSignUp} alt="Background-Image" />
      </div>

      {/* <Notification /> */}
      {/* Global Alert */}
      {/* Success Alert */}
      {showAlert && alertType === "success" && (
        <SuccessModal
          showModal={showAlert}
          title={alertTitle}
          description={alertDescription}
          onClose={closeGlobalAlert}
        />
      )}
      {/* Error Alert */}
      {showAlert && alertType === "error" && (
        <ErrorModal
          showModal={showAlert}
          title={alertTitle}
          description={alertDescription}
          onClose={closeGlobalAlert}
        />
      )}
      {/* Success Alert */}
      {showAlert && alertType === "warning" && (
        <WarningModal
          showModal={showAlert}
          title={alertTitle}
          description={alertDescription}
          onClose={closeGlobalAlert}
        />
      )}

      {/* Loader */}
      {isLoading && <Loader />}

      <CustomRoutes />
    </div>
  );
};
/* Component ends */

// Redux Map State To Compnent Props
const mapStateToProps = (state) => {
  return {
    isLoading: state.master.loading,
    accessToken: state.auth.accessToken,
    settings: state.setting.allSettings,
  };
};

// Redux Map Dispatch To Compnent Props
const mapDispatchToProps = (dispatch) => {
  return {
    fetchSettings: bindActionCreators(fetchSetting, dispatch),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(FrontEnd);
