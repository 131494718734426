/**
 *
 */

import React, { useState } from "react";

/* Import configuration starts */
import { useNavigate } from "react-router-dom";

/* Import configuration ends */

/* Import redux slices component starts */

/* Import redux slices component ends */

/* Import react bootstrap component starts */
import { Table } from "react-bootstrap";
/* Import react bootstrap component ends */

/* Import image and SVG starts */
import {
  EditGreen,
  EyeFilledBlue,
} from "../../../../../../../setup/constants/images";
/* Import image and SVG ends */

/* Import local pages and component starts */
import IconButton from "../../../../../../ui/button/IconButton/IconButton";
import moment from "moment";
import PaymentModal from "../../../PaymentModal/PaymentModal";
import Pagination from "../../../../../../ui/other/Pagination/Pagination";
/* Import local pages and component ends */

/* Component starts */
const ContractsInvoicing = (props) => {
  /* Props destructuring starts */
  const {
    partiesInvoice,
    property,
    currentPage,
    setCurrentPage,
    totalCount,
    invoiceAggregation,
  } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [showModal, setShowModal] = useState(false);
  // const [showFileViewer, setShowFileViewer] = useState(false);
  // const [file, setFile] = useState({});
  const [invoiceData, setInvoiceData] = useState();
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const navigate = useNavigate();

  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  const USER_DATA = localStorage.getItem("user_data")
    ? JSON.parse(localStorage.getItem("user_data"))
    : null;
  const DATE_FORMAT = USER_DATA ? USER_DATA?.date_format : "";
  const TIME_FORMAT = USER_DATA ? USER_DATA?.time_format : "";
  const DATE_TIME_FORMAT = `${DATE_FORMAT} ${TIME_FORMAT}`;

  const isOwner = localStorage.getItem("user_role") === "Owner";
  const isFM = localStorage.getItem("user_role") === "Facility Manager";
  const isAccountant = localStorage.getItem("user_role") === "Accountant";
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */

  const onClose = () => {
    setShowModal(!showModal);
  };

  const onSuccess = () => {
    setShowModal(!showModal);
    navigate(-1);
  };

  const handlePaymentModal = (item) => {
    setInvoiceData(item);
    setShowModal(!showModal);
  };

  const handleViewReport = (item) => {
    // const newPathname = pathNamePopped(pathname, 2);
    // navigate(`${newPathname}/detail?type=${currentKey}/${item?._id}`);
    navigate(`${item?._id}`);
  };

  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <>
      <div className="custom-table-wrapper parties-invoice-table">
        {partiesInvoice?.length > 0 && (
          <Table className="custom-table font_s" borderless responsive>
            {/* Table Heading */}
            <thead>
              <tr>
                <th>ID</th>
                <th>Date & Time</th>
                <th>Issuer</th>
                <th>Total</th>
                {/* <th>Remarks</th> */}
                <th>Status</th>
                <th className="text-start">Action</th>
              </tr>
            </thead>
            {/* Table Body */}
            <tbody>
              {partiesInvoice?.map((item) => (
                <tr key={item._id}>
                  {/* ID */}
                  <td>{item?.invoice_number}</td>

                  {/*  Date */}
                  <td>{moment(item?.due_date).format(DATE_TIME_FORMAT)}</td>

                  {/* Issuer Name */}
                  <td>{item?.issuer !== undefined ? item?.issuer : "-"}</td>

                  {/* Total */}
                  <td>
                    {item?.total} {property?.currency}
                  </td>

                  {/* Remarks */}
                  {/* <td>{item?.remarks !== undefined ? item?.remarks : "-"}</td> */}
                  {/* Status */}
                  <td
                    className={`${
                      item?.status === "Paid"
                        ? "font_green"
                        : item?.status === "Unpaid"
                        ? "font_yellow"
                        : "font_red"
                    }`}
                  >
                    {item?.status}
                  </td>

                  {/* Actions */}
                  <td className="text-start txt-nowrap">
                    <IconButton
                      onClick={() => handleViewReport(item)}
                      className="action-button"
                    >
                      <img
                        src={EyeFilledBlue}
                        className="h_100"
                        alt="View Details"
                      />
                    </IconButton>

                    {((isOwner && property?.mgmt_model === 0) ||
                      ((isFM || isAccountant) && property?.mgmt_model === 1) ||
                      (isAccountant && property?.mgmt_model === 2)) &&
                      (item?.status === "Unpaid" ||
                        item?.status === "Partial Paid") && (
                        <IconButton
                          onClick={() => handlePaymentModal(item)}
                          className="action-button "
                        >
                          <img
                            // src={EditWhite}
                            src={EditGreen}
                            className="h_100 mx-2"
                            alt="View Details"
                          />
                        </IconButton>
                      )}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}

        {partiesInvoice?.length === 0 && (
          <div className=" text-center">
            <h3 className=" font_m font_yellow fw_6">No Data</h3>
          </div>
        )}

        <PaymentModal
          showModal={showModal}
          onClose={onClose}
          onSuccess={onSuccess}
          invoiceData={invoiceData}
          property_id={property?._id}
          currency={property?.currency}
        />
      </div>

      {/* PAGINATION */}
      {partiesInvoice?.length > 0 && (
        <Pagination
          itemsPerPage="10"
          totalItems={totalCount}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          type="invoice"
          currency={property?.currency}
          paid={invoiceAggregation?.paid_Other_amount}
          unPaid={invoiceAggregation?.unpaid_Other_amount}
          overdue={invoiceAggregation?.overDue_Other_amount}
          totalAmount={invoiceAggregation?.total_amount}
          voidAmount={invoiceAggregation?.void_Other_amount}
        />
      )}
    </>
  );
};
/* Component ends */

export default ContractsInvoicing;
