/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import { useNavigate, useLocation } from "react-router-dom";
import moment from "moment";
import GLOBAL from "../../../../../setup/constants/global";
import { useDispatch } from "react-redux";
import {
  logTitle,
  pmReqDoc,
  canRecieveKeys,
  pmReviewClearence,
  canSkipDocSubmission,
  canSkipKeyRecieve,
  canGoToMaintenance,
  pmViewClearenceDoc,
  canOwnerIssueClearence,
  canViewMoveOutMaintenance,
  canViewClearenceDetails,
} from "../helper";
import { useParams } from "react-router-dom";
/* Import configuration ends */

/* Import redux slices component starts */
import {
  specifyReqDoc,
  acceptKeys,
  skipMoveoutStep,
} from "../../../../../setup/store/slices/moveOutSlice";
import {
  showErrorAlert,
  showSuccessAlert,
} from "../../../../../setup/store/slices/globalAlertSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
import { EyeFilledBlue } from "../../../../../setup/constants/images";
/* Import image and SVG ends */

/* Import local pages and component starts */
import CustomButton from "../../../../ui/button/CustomButton/CustomButton";
import UtilityDocNameAddModal from "../../../Tenant/UtilityDocNameAddModal/UtilityDocNameAddModal";
import ESignDocModal from "../../../Tenant/ESignDocModal/ESignDocModal";
import NoteModal from "../../../Maintenance/NoteModal/NoteModal";
import ClearenceDocGroupBlackCard from "./ClearenceDocGroupBlackCard";
/* Import local pages and component ends */

/* Component starts */
const SimpleLog = (props) => {
  /* Props destructuring starts */
  const { item, takeAction, moveoutId, user, getMoveoutDetail, moveOut } =
    props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [showUtilityDocModal, setShowUtilityDocModal] = useState();
  const [openClearence, setOpenClearence] = useState(false);
  const [step, setStep] = useState("");
  const [showSkipNote, setShowSkipNote] = useState(false);
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const dispatch = useDispatch();
  const { propertyId } = useParams();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  const name = item?.requester
    ? `${item?.requester?.first_name} ${item?.requester?.last_name}`
    : `${item?.tenant?.first_name} ${item?.tenant?.last_name}`;
  const role = item?.requester_role?.name;
  const createdAt = item?.createdAt;

  const action = item?.action;

  const comment = item?.comment;

  const USER_DATA = localStorage.getItem("user_data")
    ? JSON.parse(localStorage.getItem("user_data"))
    : null;
  const DATE_FORMAT = USER_DATA ? USER_DATA?.date_format : "";
  const TIME_FORMAT = USER_DATA ? USER_DATA?.time_format : "";
  const DATE_TIME_FORMAT = `${DATE_FORMAT} ${TIME_FORMAT}`;
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  // Sending documents name required for clearence
  const sendDocNames = async (docNames) => {
    // Creating or collecting payload data to be sent
    const payload = {
      move_out_id: moveoutId,
      description: JSON.stringify(docNames),
      property_id: propertyId,
    };
    console.log("Payload", payload);

    // Backend response. Try, Catch
    try {
      const result = await dispatch(specifyReqDoc(payload));
      console.log("Response", result);
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          dispatch(
            showErrorAlert({
              title: "Error!",
              description: result.payload,
            })
          );
          break;

        case GLOBAL.FULFILLED:
          dispatch(
            showSuccessAlert({
              title: "Success!",
              description: result.payload.message,
            })
          );
          break;
      }
      // Handling success response
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };

  // On recieving keys
  const handleRecieveKey = async () => {
    // Creating or collecting payload data to be sent
    const payload = {
      move_out_id: moveoutId,
      property_id: propertyId,
    };
    console.log("Payload", payload);

    // Backend response. Try, Catch
    try {
      const result = await dispatch(acceptKeys(payload));
      console.log("Response", result);

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          dispatch(
            showErrorAlert({
              title: "Error!",
              description: result.payload,
            })
          );
          break;

        case GLOBAL.FULFILLED:
          getMoveoutDetail();
          dispatch(
            showSuccessAlert({
              title: "Success!",
              description: result.payload.message,
            })
          );
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };

  // On skipping steps
  const skipWithNote = async (comment) => {
    // Creating or collecting payload data to be sent
    const payload = {
      move_out_id: moveoutId,
      step,
      comment,
    };
    console.log("Payload", payload);

    // Backend response. Try, Catch
    try {
      const result = await dispatch(skipMoveoutStep(payload));
      console.log("Response", result);

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          dispatch(
            showErrorAlert({
              title: "Error!",
              description:
                typeof result?.payload === "string" ? result?.payload : "",
            })
          );
          break;

        case GLOBAL.FULFILLED:
          getMoveoutDetail();
          dispatch(
            showSuccessAlert({
              title: "Success!",
              description:
                typeof result?.payload?.message === "string"
                  ? result?.payload?.message
                  : "",
            })
          );
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };
  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="time-line simple-log">
      {/* Dot */}
      <div className="dot-wrapper">
        <i className="dot bg_blue"></i>
        <div className="vl"></div>
      </div>

      <div className="detail-wrapper">
        {/* Title */}
        <h3 className="title font_m fw_6">{logTitle(item)}</h3>

        {/* Technician Name and Date */}
        <div className="black-container-sm fw_5 font_s bg_black flex-between">
          {/* Name */}
          <p className="left">
            <span className="font_grey">{role} </span>
            <span className="font_white"> {name}</span>
          </p>

          {/* Date and Time */}
          <p className="right font_grey">
            {moment(createdAt).format(DATE_TIME_FORMAT)}
          </p>
        </div>

        {canViewMoveOutMaintenance(item, user) && (
          <div class="black-container-sm fw_5 font_s bg_black flex-between view-inspection-report">
            <p class="left">
              <span class="font_grey">Unit Inspection Ticket</span>
            </p>
            <p class="right">
              <button
                type="button"
                class="custom-icon-btn"
                onClick={() => {
                  navigate(`maintenance/${item?.maintenance_id}`);
                }}
              >
                <img src={EyeFilledBlue} class="icon" alt="" />
              </button>
            </p>
          </div>
        )}

        {canViewClearenceDetails(item, user) && (
          <div class="black-container-sm fw_5 font_s bg_black flex-between view-inspection-report">
            <p class="left">
              <span class="font_grey">Clearence Details</span>
            </p>
            <p class="right">
              <button
                type="button"
                class="custom-icon-btn"
                onClick={() => {
                  navigate(`review/owner`);
                }}
              >
                <img src={EyeFilledBlue} class="icon" alt="" />
              </button>
            </p>
          </div>
        )}

        {/* Log Footer */}
        <div className="log-footer">
          {/* Comments */}
          {(action === "Utility clearnace skipped." ||
            action === "Keys collection skipped.") &&
            comment && (
              <>
                <p className="comments font_s fw-5">
                  <span className="font_grey fw_6">Comments: </span>
                  <span className="fw_5 font_red"> {comment}</span>
                </p>
                {/* {reportData?.document?.length > 0 && (
                <div className="so-report-wrapper">
                  <p className="font_yellow fw_4 font_m my-2">Attachments</p>
                  <div className="report-doc-wrapper">
                    {reportData?.document?.map((image, index) => (
                      <div className="proof-img" key={index}>
                        <Image
                          key={index}
                          className="media"
                          imgClass="thumbnail"
                          src={image?.url}
                          videoThumbnail={image?.type2 === "video"}
                          alt="Attatched Media"
                          objectFit="cover"
                          onClick={() => {
                            setFile(image);
                            setShowFileViewer(true);
                          }}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              )} */}
              </>
            )}

          {/* Action Buttons */}
          {takeAction && (
            <div className="button-wrapper">
              {/* {pmReqDoc(item, user) && (
                <CustomButton
                  onClick={() => setShowUtilityDocModal(true)}
                  size="m"
                  variant="yellow"
                >
                  Request for document
                </CustomButton>
              )} */}

              {canRecieveKeys(item, user) && (
                <>
                  <CustomButton
                    size="m"
                    variant="yellow"
                    onClick={() => handleRecieveKey()}
                  >
                    {/* Recieve keys */}
                    Key Received
                  </CustomButton>
                </>
              )}

              {pmReviewClearence(item, user) && (
                <CustomButton
                  size="m"
                  variant="yellow"
                  onClick={() => {
                    setOpenClearence(true);
                  }}
                >
                  Review Clearence
                </CustomButton>
              )}

              {canOwnerIssueClearence(item, user) && (
                <CustomButton
                  size="m"
                  variant="yellow"
                  onClick={() => {
                    navigate("review/owner");
                  }}
                >
                  {/* Issue Clearence */}
                  Account Final Settlement
                </CustomButton>
              )}

              {canSkipDocSubmission(item, user) && (
                <CustomButton
                  onClick={() => {
                    setStep("utility_clearance");
                    setShowSkipNote(true);
                  }}
                  size="m"
                  variant="yellow"
                >
                  Skip Utility Clearence
                </CustomButton>
              )}

              {canSkipKeyRecieve(item, user) && (
                <CustomButton
                  onClick={() => {
                    setStep("keys_collection");
                    setShowSkipNote(true);
                  }}
                  size="m"
                  variant="yellow"
                >
                  {/* Skip Key Recieve */}
                  Skip Key Collection
                </CustomButton>
              )}

              {canGoToMaintenance(item, user) && (
                <CustomButton
                  onClick={() =>
                    navigate(`maintenance/${item?.maintenance_id}`)
                  }
                  variant="yellow"
                  size="m"
                >
                  View Maintenance
                </CustomButton>
              )}

              {pmViewClearenceDoc(item, user) &&
                moveOut?.clearance_certificate_pdf &&
                moveOut?.clearance_certificate_audit_logs && (
                  <ClearenceDocGroupBlackCard
                    auditLog={moveOut?.clearance_certificate_audit_logs}
                    doc={moveOut?.clearance_certificate_pdf}
                  />
                )}
            </div>
          )}
        </div>
      </div>

      {/* Modals */}
      {/* Utility Document Clearence Modal */}
      <UtilityDocNameAddModal
        showModal={showUtilityDocModal}
        onClose={() => setShowUtilityDocModal(false)}
        getDocNames={sendDocNames}
      />

      {pmReviewClearence(item, user) && (
        <ESignDocModal
          showModal={openClearence}
          type="clearance_cert"
          move_out_id={moveoutId}
          onCompleted={() => {
            // navigate(-1);
            const s = pathname.split("/");
            s.pop();
            navigate(s.join("/"));
            setOpenClearence(false);
            getMoveoutDetail();
          }}
          onClose={() => {
            setOpenClearence(false);
          }}
        />
      )}

      <NoteModal
        showModal={showSkipNote}
        title="Skip with comment"
        btnText="Skip"
        onCancel={() => setShowSkipNote(false)}
        actionWithNote={skipWithNote}
      />
    </div>
  );
};
/* Component ends */

export default SimpleLog;
