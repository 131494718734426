/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import { useDispatch, useSelector } from "react-redux";
import GLOBAL from "../../../../../setup/constants/global";
/* Import configuration ends */

/* Import redux slices component starts */
import { setLoading } from "../../../../../setup/store/slices/unpersistedSlice";
import {
  fetchPropertyDetailByID,
  getPropertyDetail,
  updatePropertySettings,
} from "../../../../../setup/store/slices/propertySlice";
import {
  showErrorAlert,
  showSuccessAlert,
} from "../../../../../setup/store/slices/globalAlertSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
import { WarningRoundedOutlineYellow } from "../../../../../setup/constants/images";
/* Import image and SVG ends */

/* Import local pages and component starts */
import CustomCurrencyInput from "../../../../ui/input/CustomCurrencyInput/CustomCurrencyInput";
import CustomRadioInput from "../../../../ui/input/CustomRadioInput/CustomRadioInput";
import CustomButton from "../../../../ui/button/CustomButton/CustomButton";
import CustomCheckInput from "../../../../ui/input/CustomCheckInput/CustomCheckInput";
/* Import local pages and component ends */

/* Component starts */
const TenantApplicationFees = (props) => {
  /* Props destructuring starts */
  const { user, settings, property_id } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [acceptFees, setAcceptFees] = useState();
  const [settingChanged, setSettingChanged] = useState(false);
  const [updatedFeeAmount, setUpdatedFeeAmount] = useState("");
  const [collectVat, setCollectVat] = useState(false);
  const [collectTax, setCollectTax] = useState(false);
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const dispatch = useDispatch();
  const propertyData = useSelector(getPropertyDetail);
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  // On updating setting
  const onUpdate = async () => {
    if (acceptFees && parseInt(updatedFeeAmount) === 0) {
      dispatch(
        showErrorAlert({
          title: "Error!",
          description: "Please enter a valid amount for application fees.",
        })
      );
      return;
    }

    // Creating or collecting payload data to be sent
    const payload = {
      application_fee: acceptFees,
      application_fee_amount: updatedFeeAmount,
      application_fee_collect_vat: collectVat,
      application_fee_collect_tax: collectTax,
      property_id,
    };

    console.log("Payload", payload);

    // Backend response. Try, Catch
    try {
      const result = await dispatch(updatePropertySettings(payload));
      console.log("Result", result);

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          dispatch(
            showErrorAlert({
              title: "Error!",
              description: result.payload,
            })
          );
          break;

        case GLOBAL.FULFILLED:
          dispatch(
            showSuccessAlert({
              title: "Settings Updated!",
              description: result.payload.message,
            })
          );
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };
  /* Component function definition ends */

  /* Component useEffect starts */
  // On initial load or when all the settings is loaded
  useEffect(() => {
    if (settings) {
      setAcceptFees(settings.application_fee);
      setUpdatedFeeAmount(settings.application_fee_amount + "");
      setCollectVat(settings.application_fee_collect_vat);
      setCollectTax(settings.application_fee_collect_tax);
    }
  }, [settings]);

  // If any setting is changed
  useEffect(() => {
    setSettingChanged(false);
    if (settings.application_fee !== acceptFees) {
      setSettingChanged(true);
    }
    if (settings.application_fee_amount !== parseInt(updatedFeeAmount)) {
      setSettingChanged(true);
    }
    if (settings.application_fee_collect_vat !== collectVat) {
      setSettingChanged(true);
    }
    if (settings.application_fee_collect_tax !== collectTax) {
      setSettingChanged(true);
    }
  }, [acceptFees, settings, updatedFeeAmount, collectTax, collectVat]);

  useEffect(() => {
    (async () => {
      try {
        await dispatch(setLoading(true));
        await dispatch(fetchPropertyDetailByID({ property_id: property_id }));
        await dispatch(setLoading(false));
      } catch (e) {}
    })();
  }, []);

  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="tenant-application-fees option">
      {/* Label */}
      <div className="label">
        <p className="font_m fw_5">Tenant Application Fees</p>
      </div>

      {/* Setting */}
      <div className="setting">
        <div className="row1">
          {/* Accepting tenant fee */}
          <div className="accept-fee-wrapper">
            <p className="font_s fw_5 font_grey accept-fee mb_1">
              Charge Tenants Application Fee ?
            </p>
            <div className="radio-wrapper">
              <CustomRadioInput
                label="Yes"
                className="radio-input"
                isSelected={acceptFees}
                onSelecting={() => setAcceptFees(true)}
              />
              <CustomRadioInput
                label="No"
                className="radio-input"
                isSelected={!acceptFees}
                onSelecting={() => {
                  setAcceptFees(false);
                  setUpdatedFeeAmount(0);
                }}
              />
            </div>
          </div>

          {/* Tenant fee amount */}
          {acceptFees && (
            <div className="application-fee-amt mt_3-900">
              <p className="amt-label fw_5 font_grey font_s mb_1">
                Tenant Application Fee Amount ({propertyData.currency})
              </p>
              <CustomCurrencyInput
                variant="2"
                emptyValue="0"
                value={updatedFeeAmount}
                currency={propertyData?.currency}
                setValue={setUpdatedFeeAmount}
                inputClass="currency-input setting-input font_xs lg"
              />
            </div>
          )}
        </div>

        <div className="row2 mt_2 d_flex">
          {acceptFees && (
            <>
              {settings?.collect_vat_enabled && (
                <div className="tax-check mr_3">
                  <p className="font_xs font_grey">Collect VAT</p>
                  <CustomCheckInput
                    variant="2"
                    display="block"
                    value={collectVat}
                    setValue={(v) => {
                      setCollectVat(v);
                    }}
                  />
                </div>
              )}
              {settings?.collect_tax_enabled && (
                <div className="tax-check">
                  <p className="font_xs font_grey">Collect Tax</p>
                  <CustomCheckInput
                    variant="2"
                    display="block"
                    value={collectTax}
                    setValue={(v) => {
                      setCollectTax(v);
                    }}
                  />
                </div>
              )}
            </>
          )}
        </div>

        {settingChanged && (
          <div className="save-btn-wrapper mt_2">
            <CustomButton onClick={onUpdate} className="save-btn" size="s">
              Save
            </CustomButton>
          </div>
        )}
      </div>
    </div>
  );
};
/* Component ends */

export default TenantApplicationFees;
