/**
 *
 */

import React from "react";
/* Import configuration starts */
import moment from "moment";
import { useNavigate } from "react-router-dom";
/* Import configuration ends */

/* Import redux slices component starts */
/* Import redux slices component ends */

/* Import react bootstrap component starts */
import { Table } from "react-bootstrap";
/* Import react bootstrap component ends */

/* Import image and SVG starts */
import { EyeFilledBlue } from "../../../../../setup/constants/images";
/* Import image and SVG ends */

/* Import local pages and component starts */
import { customMoment } from "../../../../../setup/utils/global-helper";
import IconButton from "../../../../ui/button/IconButton/IconButton";
import Pagination from "../../../../ui/other/Pagination/Pagination";
/* Import local pages and component ends */

// Styles
import "./style.scss";

/* Component starts */
const ExpiredTasks = (props) => {
  /* Props destructuring starts */
  const { taskLists, totalCount, currentPage, setCurrentPage, property } =
    props;

  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const navigate = useNavigate();
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */

  const USER_DATA = localStorage.getItem("user_data")
    ? JSON.parse(localStorage.getItem("user_data"))
    : null;
  const DATE_FORMAT = USER_DATA ? USER_DATA?.date_format : "";
  const TIME_FORMAT = USER_DATA ? USER_DATA?.time_format : "";
  const DATE_TIME_FORMAT = `${DATE_FORMAT} ${TIME_FORMAT}`;

  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */

  // function getDate(date) {
  //   let theDate = new Date(Date.parse(date));

  //   return (
  //     theDate.toLocaleDateString() + " " + theDate.toLocaleTimeString("en-US")
  //   );
  // }

  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */

  return (
    <>
      <div className="custom-table-wrapper pending-task-table-wrapper">
        {taskLists?.length > 0 && (
          <Table className="custom-table font_s" borderless responsive>
            {/* Table Heading */}
            <thead>
              <tr>
                <th>ID</th>
                <th>Start Date</th>
                <th>End Date</th>
                <th>Subject</th>
                <th>Type</th>
                <th>Frequency</th>
                <th>Assigned to</th>
                <th>Status</th>
                <th>Actions</th>
              </tr>
            </thead>
            {/* Table Body */}
            <tbody>
              {taskLists?.map((item, id) => (
                <tr key={item?._id}>
                  {/* ID */}

                  <td>{item?.task_id}</td>

                  {/* Start Date */}
                  {/* <td>{moment(item?.startdate).format(DATE_TIME_FORMAT)}</td> */}
                  <td>
                    {customMoment(
                      item?.startdate,
                      DATE_FORMAT,
                      true,
                      property?.offset
                    )}
                  </td>

                  {/* End Date */}
                  <td>
                    {/* //{moment(item?.enddate).format(DATE_TIME_FORMAT)} */}

                    {customMoment(
                      item?.enddate,
                      DATE_FORMAT,
                      true,
                      property?.offset
                    )}
                  </td>

                  {/* Subject */}
                  <td>{item?.subject}</td>

                  {/* Type */}
                  <td>{item?.type}</td>

                  {/* Frequency */}
                  <td>{item?.frequency}</td>

                  {/* Assigned To */}
                  <td>
                    {item?.assigned
                      ? `${item?.assigned?.first_name} ${item?.assigned?.last_name}`
                      : "-"}
                  </td>

                  {/* Next Due Date & Time */}
                  <td className="font_red">{item?.status}</td>

                  {/* Actions */}
                  <td className="d-flex justify-content-between gap-1">
                    <IconButton
                      onClick={() =>
                        navigate(item?._id, {
                          state: { taskTab: true },
                        })
                      }
                      className="action-button"
                    >
                      <img
                        src={EyeFilledBlue}
                        className="h_100"
                        alt="View Details"
                      />
                    </IconButton>
                    {/* <IconButton
                  className="action-button"
                >
                  <img src={EditGreen} className="h_100" alt="View Details" />
                </IconButton>
                <IconButton
                 
                  className="action-button"
                >
                  <img src={DeleteRed} className="h_100" alt="View Details" />
                </IconButton> */}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}

        {taskLists?.length === 0 && (
          <div className=" text-center">
            <h3 className=" font_m font_yellow fw_6">No Data</h3>
          </div>
        )}
      </div>

      {taskLists?.length > 0 && (
        <Pagination
          itemsPerPage="10"
          totalItems={totalCount}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      )}
    </>
  );
};
/* Component Ends */
export default ExpiredTasks;
