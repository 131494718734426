/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import {
  logTitle,
  canTPTStrtWrkReq4Parts,
  canMTStrtWrkReq4Parts,
} from "../helper";
import moment from "moment";
import GLOBAL from "../../../../../setup/constants/global";
import { useDispatch, useSelector } from "react-redux";
/* Import configuration ends */

/* Import redux slices component starts */
import {
  showSuccessAlert,
  showErrorAlert,
} from "../../../../../setup/store/slices/globalAlertSlice";
import {
  t2tpTechnicianStartWork,
  t2TechnicianStartWork,
  technicianAddSpareParts,
  tpTechnicianStartWork,
  technicianStartWork,
} from "../../../../../setup/store/slices/internalMaintenanceSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import FileViewerModal from "../../../../ui/modal/FileViewerModal/FileViewerModal";
import Image from "../../../../ui/other/Image/Image";
import CustomButton from "../../../../ui/button/CustomButton/CustomButton";
import SparePartQuoteModal from "../../../Maintenance/SparePartQuoteModal/SparePartQuoteModal";
import CustomRadioInput from "../../../../ui/input/CustomRadioInput/CustomRadioInput";
import SparePartRequestModal from "../../../Maintenance/SparePartRequestModal/SparePartRequestModal";
/* Import local pages and component ends */

/* Component starts */
const InspectionReportLog = (props) => {
  /* Props destructuring starts */
  const {
    property,
    maintenance_id,
    maintenance,
    item,
    status,
    isT1,
    takeAction,
    user,
    isT2,
    isTPT,
  } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [file, setFile] = useState({});
  const [showFile, setShowFile] = useState(false);
  const [addQuotation, setAddQuotation] = useState(false);
  const [totalEstimation, setTotalEstimation] = useState(0);
  const [mtOnLocationAction, setMtOnLocationAction] = useState(0);
  const [requestSpareParts, setRequestSpareParts] = useState(false);
  // const [estimationArray, setEstimationArray] = useState([]);

  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const dispatch = useDispatch();
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  const name = item?.requester
    ? `${item?.requester?.first_name} ${item?.requester?.last_name}`
    : `${item.tenant.first_name} ${item.tenant.last_name}`;
  const role = item?.requester_role?.name;
  const createdAt = item?.createdAt;
  const initialURIC = maintenance?.initial_URIC;
  const maintenanceType = maintenance?.type;
  const unitCard = maintenance?.unit_card;
  const USER_DATA = localStorage.getItem("user_data")
    ? JSON.parse(localStorage.getItem("user_data"))
    : null;
  const DATE_FORMAT = USER_DATA ? USER_DATA?.date_format : "";
  const TIME_FORMAT = USER_DATA ? USER_DATA?.time_format : "";
  const DATE_TIME_FORMAT = `${DATE_FORMAT} ${TIME_FORMAT}`;
  const quote = maintenance?.quotes;
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  const calculateTotalEstimation = (items) => {
    const total = items?.reduce((acc, item) => acc + item?.estimate, 0);
    setTotalEstimation(total);
  };

  // On MT request for spare parts
  const mtRequestSpareparts = async (parts, document) => {
    setRequestSpareParts(false);
    // Creating or collecting payload data to be sent
    const payload = {
      maintenance_id,
      type: maintenanceType,
      part: JSON.stringify(parts),
      description: parts,
      document,
    };
    console.log("Payload", payload);

    // Backend response. Try, Catch
    try {
      let result = await dispatch(technicianAddSpareParts(payload));
      console.log("Response", result);

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          dispatch(
            showErrorAlert({
              title: "Error!",
              description: result.payload,
            })
          );
          break;

        case GLOBAL.FULFILLED:
          dispatch(
            showSuccessAlert({
              title: "Success!",
              description: result.payload.message,
            })
          );
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };

  // On MT start work immediately
  const techStartWork = async () => {
    // Creating or collecting payload data to be sent
    const payload = {
      maintenance_id,
      type: maintenanceType,
    };
    console.log("Payload", payload);

    // Backend response. Try, Catch
    try {
      let result;
      if (isTPT) {
        if (isT2) {
          result = await dispatch(t2tpTechnicianStartWork(payload));
        } else {
          result = await dispatch(tpTechnicianStartWork(payload));
        }
      } else {
        if (isT2) {
          result = await dispatch(t2TechnicianStartWork(payload));
        } else {
          result = await dispatch(technicianStartWork(payload));
        }
      }
      console.log("Response", result);

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          dispatch(
            showErrorAlert({
              title: "Error!",
              description: result.payload,
            })
          );
          break;

        case GLOBAL.FULFILLED:
          dispatch(
            showSuccessAlert({
              title: "Success!",
              description: result.payload.message,
            })
          );
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };

  // On MT start work immediately or rquest for spare parts
  const mtOnLocationSubmit = () => {
    if (mtOnLocationAction === 0) {
      techStartWork();
    } else {
      setRequestSpareParts(true);
    }
  };
  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="time-line inspection-log">
      {/* Dot */}

      <div className="dot-wrapper">
        <i className="dot"></i>
        <div className="vl"></div>
      </div>

      <div className="detail-wrapper">
        {/* Title */}
        <h3 className="title font_m fw_6">{logTitle(item)}</h3>

        {/* Technician Name and Date */}
        <div className="black-container-sm fw_5 font_s bg_black flex-between">
          {/* Name */}
          <p className="left">
            <span className="font_grey">{role} </span>
            <span className="font_white"> {name}</span>
          </p>

          {/* Date and Time */}
          <p className="right font_grey">
            {moment(createdAt).format(DATE_TIME_FORMAT)}
          </p>
        </div>

        <div className="uirc-detail">
          {/* Details */}
          <div className="black-container-sm uirc-data">
            {item?.data?.items?.map((estimateItem, index) => (
              <div className="report-item">
                {/* Header */}
                <h2 className="font_m fw_6 font_blue report-item-heading">
                  Report Item #{index + 1}
                </h2>
                <div className="r font_s fw_5">
                  <p className="c1 font_grey">Description</p>
                  {/* <p className="c2 font_grey">Estimate ({property.currency})</p> */}
                </div>

                {/* Body */}
                <div key={index} className="r font_s fw_5">
                  <div className="c1">
                    <p className="font_m fw_5 font_white">
                      {estimateItem?.description}
                    </p>

                    <h3 className="attachments-txt fw_5 font_s font_grey">
                      Attachments
                    </h3>

                    {estimateItem?.documents?.length > 0 ? (
                      <div className="doc-row">
                        {estimateItem?.documents?.map((doc, index) => (
                          <Image
                            onClick={() => {
                              setFile(doc);
                              setShowFile(true);
                            }}
                            src={doc?.url}
                            videoThumbnail={doc?.type2 === "video"}
                            className="doc-item"
                            objectFit="cover"
                          />
                        ))}
                      </div>
                    ) : (
                      <p className="font_xxs font_yellow fw_4">
                        No Attachments Added!
                      </p>
                    )}
                  </div>

                  {/* <div className="c2">
                    <input
                      type="text"
                      className="transparent-input fw_5"
                      readOnly={true}
                      value={estimateItem?.estimate?.toLocaleString("en-US")}
                    />
                  </div> */}
                </div>
              </div>
            ))}
            <hr />

            {/* <div className="my-2 r font_s fw_5">
              <p className="font_m fw_5 c1 font_grey">Total Amount </p>
              <p className="font_m fw_6 c2 font_blue">
                {totalEstimation?.toLocaleString("en-US")} ({property?.currency}
                )
              </p>
            </div> */}
          </div>
        </div>

        {takeAction && (
          <div className="button-wrapper">
            {/* MT starting work or request for spare parts */}
            {!isT1 &&
              (canTPTStrtWrkReq4Parts(item, user) ||
                canMTStrtWrkReq4Parts(item, user)) && (
                <div className="radio-select-section">
                  <div className="radio-container spare-parts-start-work-radio w_50">
                    <CustomRadioInput
                      isSelected={mtOnLocationAction === 0}
                      onSelecting={() => setMtOnLocationAction(0)}
                      label="Start work immediately"
                    />
                    <CustomRadioInput
                      isSelected={mtOnLocationAction === 1}
                      onSelecting={() => setMtOnLocationAction(1)}
                      label="I need Parts or Service"
                    />
                  </div>
                  <CustomButton
                    onClick={() => mtOnLocationSubmit()}
                    variant="yellow"
                    size="m"
                  >
                    Submit
                  </CustomButton>
                </div>
              )}
          </div>
        )}
      </div>

      {/* File Viewer Modal */}
      <FileViewerModal
        show={showFile}
        file={file.url}
        type={file.type}
        onClose={() => {
          setShowFile(false);
          setFile({});
        }}
      />

      {takeAction && (
        <>
          {/* Spare parts modal */}
          <SparePartRequestModal
            showModal={requestSpareParts}
            callback={mtRequestSpareparts}
            onClose={() => setRequestSpareParts(false)}
          />

          {/* Spare Part Quotation Modal */}
          <SparePartQuoteModal
            showModal={addQuotation}
            onClose={() => setAddQuotation(false)}
            type="normal"
            maintenance_id={maintenance_id}
            maintenanceType={maintenanceType}
            unitCard={unitCard}
            user={user}
            data={quote}
            currentLog={item}
            isT1={isT1}
            tenantId={maintenance?.tenant_id?._id}
          />
        </>
      )}
    </div>
  );
};
/* Component ends */

export default InspectionReportLog;
