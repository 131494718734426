/**
 *
 */

import moment from "moment";

/* Import configuration starts */
import React, { useState } from "react";
import GLOBAL from "../../../../../setup/constants/global";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
/* Import configuration ends */

/* Import redux slices component starts */
import {
  assignedMemberUpdateTaskTicket,
  reviewUpdatedTaskTicket,
} from "../../../../../setup/store/slices/routineSlice";
import {
  showErrorAlert,
  showSuccessAlert,
} from "../../../../../setup/store/slices/globalAlertSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import {
  canAssigneeAddReport,
  canRequesterTakeActionOnTicket,
  logTitle,
} from "../helper";
import CustomButton from "../../../../ui/button/CustomButton/CustomButton";
import AssigneeAddReportModal from "../AssigneeAddReportModal/AssigneeAddReportModal";
import NoteModal from "../../../Maintenance/NoteModal/NoteModal";
import FileViewerModal from "../../../../ui/modal/FileViewerModal/FileViewerModal";
import Image from "../../../../ui/other/Image/Image";
/* Import local pages and component ends */

/* Component starts */
const SimpleLog = (props) => {
  /* Props destructuring starts */
  const { task, item, takeAction, user } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [openReportForm, setOpenReportForm] = useState(false);
  const [openNote, setOpenNote] = useState(false);

  const [showFileViewer, setShowFileViewer] = useState(false);
  const [file, setFile] = useState({});

  const [takeActionOnTicket, setTakeActionOnTicket] = useState();
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const dispatch = useDispatch();
  const { taskId, subTab } = useParams();
  const navigate = useNavigate();

  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  const name = item?.assigned
    ? `${item?.requester?.first_name} ${item?.requester?.last_name}`
    : item?.log_type === "simple-log"
    ? `${item?.requester?.first_name} ${item?.requester?.last_name}`
    : `-`;
  const role = item?.requester_role?.name;
  const createdAt = item?.createdAt;
  const comment = item?.comment;
  const reportData = item?.report;

  const USER_DATA = localStorage.getItem("user_data")
    ? JSON.parse(localStorage.getItem("user_data"))
    : null;
  const DATE_FORMAT = USER_DATA ? USER_DATA?.date_format : "";
  const TIME_FORMAT = USER_DATA ? USER_DATA?.time_format : "";
  const DATE_TIME_FORMAT = `${DATE_FORMAT} ${TIME_FORMAT}`;
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */

  // REPORT DATA FOR INTERNAL MAINTENANVCE TICKET
  const renderReport = () => {
    return (
      <div className="so-report-wrapper">
        {reportData?.description && (
          <div>
            <p className="font_m fw_4 font_grey">
              Description :{" "}
              <span className="font_xs fw_4 font_white">
                {reportData?.description}
              </span>
            </p>
          </div>
        )}
        {reportData?.report_image?.length > 0 && (
          <div>
            <div className="report-doc-wrapper">
              {/* {reportData?.report_image?.map((image, index) => (
                <div className="proof-img" key={index}>
                  <Image
                    key={index}
                    className="media"
                    imgClass="thumbnail"
                    src={image?.url}
                    videoThumbnail={
                      image?.type2 === "video" || image?.type === "mp4"
                    }
                    alt="Attatched Media"
                    objectFit="cover"
                    onClick={() => {
                      setFile(image);
                      setShowFileViewer(true);
                    }}
                  />
                </div>
              ))} */}
              {reportData?.report_image?.map((image, index) => (
                <div className="proof-img" key={index}>
                  <Image
                    key={index}
                    className="media"
                    imgClass="thumbnail"
                    src={image?.url}
                    videoThumbnail={image?.type2 === "video"}
                    alt="Attatched Media"
                    objectFit="cover"
                    onClick={() => {
                      setFile(image);
                      setShowFileViewer(true);
                    }}
                  />
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    );
  };

  // Assignee respond to Routine request ticket
  const assigneeRespondToRoutineTask = async (accept = true, comment = "") => {
    // Creating or collecting payload data to be sent
    const payload = {
      routine_id: task?.routine_id?._id,
      status: accept ? "Approved" : "Revision",
      comment,
      // taskId,
      routine_ticket_id: taskId,
    };
    console.log("Payload", payload);

    // Backend response. Try, Catch
    try {
      const result = await dispatch(reviewUpdatedTaskTicket(payload));
      console.log("Response", result);

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          dispatch(
            showErrorAlert({
              title: "Error!",
              description: result.payload,
            })
          );
          break;

        case GLOBAL.FULFILLED:
          dispatch(
            showSuccessAlert({
              title: "Success!",
              description: result.payload.message,
            })
          );
          navigate(-1);
          break;

        default:
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };

  // On action with note
  const actionWithNote = (note) => {
    setOpenNote(false);
    // On MT/TPT respond to maintenance request

    canRequesterTakeActionOnTicket(item, user) &&
      takeActionOnTicket === "Approved" &&
      assigneeRespondToRoutineTask(true, note);

    canRequesterTakeActionOnTicket(item, user) &&
      takeActionOnTicket === "Revision" &&
      assigneeRespondToRoutineTask(false, note);

    // canFMReviewQuotation(item, user) && fmReviewQuotation(false, note);
  };

  const onSubmitReport = async (val) => {
    setOpenReportForm(!openReportForm);
    let payload = {
      ...val,
      // taskId,
      routine_ticket_id: taskId,
    };

    console.log(payload, "[GAGSGS]");

    // Backend response. Try, Catch
    try {
      const result = await dispatch(assignedMemberUpdateTaskTicket(payload));

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          dispatch(
            showErrorAlert({
              title: "Error!",
              description: result.payload,
            })
          );
          break;
        case GLOBAL.FULFILLED:
          dispatch(
            showSuccessAlert({
              title: "Success!",
              description: result.payload.message,
            })
          );
          // navigate(-1)
          break;

        default:
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };

  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="time-line simple-log">
      {/* Dot */}

      <div className="dot-wrapper">
        <i className="dot"></i>
        <div className="vl"></div>
      </div>

      <div className="detail-wrapper">
        {/* Title */}
        <h3 className="title font_m fw_6">{logTitle(item)}</h3>

        {/* Technician Name and Date */}
        <div className="black-container-sm fw_5 font_s bg_black flex-between">
          {/* Name */}

          <p className="left">
            <span className="font_grey">{role} </span>
            <span className="font_white"> {name}</span>
          </p>

          {/* Date and Time */}
          <p className="right font_grey">
            {moment(createdAt).format(DATE_TIME_FORMAT)}
          </p>
        </div>

        {/* Log Footer */}
        <div className="log-footer">
          {/* Comments */}
          {comment && (
            <p className="comments font_s fw-5">
              <span className="font_grey fw_6">Comments: </span>
              <span className="fw_5 font_red"> {comment}</span>
            </p>
          )}

          {reportData && renderReport()}

          {/* Action Buttons */}
          {takeAction && (
            <div className="button-wrapper gap-2">
              {/* Assignee  Completing Routine Task Ticket */}
              {canAssigneeAddReport(item, user) && subTab !== "active" && (
                <CustomButton
                  onClick={() => setOpenReportForm(true)}
                  variant="yellow"
                  size="m"
                >
                  Add Report
                </CustomButton>
              )}

              {/* Requester Completing Routine Task Ticket */}
              {canRequesterTakeActionOnTicket(item, user) && (
                <>
                  <CustomButton
                    onClick={() => {
                      setOpenNote(true);
                      setTakeActionOnTicket("Approved");
                    }}
                    variant="yellow"
                    size="m"
                  >
                    Approve
                  </CustomButton>

                  <CustomButton
                    onClick={() => {
                      setOpenNote(true);
                      setTakeActionOnTicket("Revision");
                    }}
                    variant="red"
                    size="m"
                  >
                    Revision
                  </CustomButton>
                </>
              )}
            </div>
          )}
        </div>
      </div>

      {/* Handove Item Modal */}
      {/* Assignee Add REPORT MODAL */}
      <AssigneeAddReportModal
        showModal={openReportForm}
        setShowModal={setOpenReportForm}
        title={`${item?.requester_role?.name} Report`}
        onClose={() => setOpenReportForm(false)}
        routine_id={task?.routine_id?._id}
        routineTask={true}
        onSubmitReport={(val) => onSubmitReport(val)}
      />

      <NoteModal
        showModal={openNote}
        actionWithNote={actionWithNote}
        title="Approve Assignee Report"
        inputPlaceholder="Comment"
        btnText={takeActionOnTicket === "Approved" ? "Approve" : "Revision"}
        showError={true}
        onCancel={() => setOpenNote(false)}
      />

      {/* File viewer modal */}
      <FileViewerModal
        show={showFileViewer}
        type={file?.type}
        file={file?.url}
        onClose={() => {
          setShowFileViewer(false);
          setFile({});
        }}
      />
    </div>
  );
};
/* Component ends */

export default SimpleLog;
