/**
 *
 */

import React, { useState, useEffect, useRef } from "react";
/* Import configuration starts */
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Formik } from "formik";
import { initialValues, validations } from "./helper";
import GLOBAL from "../../../../setup/constants/global";
/* Import configuration ends */

/* Import redux slices component starts */
import { addVisitorsPass } from "../../../../setup/store/slices/guestPass";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import CustomRadioInput from "../../../ui/input/CustomRadioInput/CustomRadioInput";
import CustomTextInput from "../../../ui/input/CustomTextInput/CustomTextInput";
import CustomFileInput from "../../../ui/input/CustomFileInput/CustomFileInput";
import CustomDateInput from "../../../ui/input/CustomDateInput/CustomDateInput";
import CustomDateTimeInput from "../../../ui/input/CustomDateTimeInput/CustomDateTimeInput";
import CustomSelectInput from "../../../ui/input/CustomSelectInput/CustomSelectInput";
import CustomButton from "../../../ui/button/CustomButton/CustomButton";
import TextButton from "../../../ui/button/TextButton/TextButton";
import CustomPhoneInput from "../../../ui/input/CustomPhoneInput/CustomPhoneInput";
import ErrorModal from "../../../ui/modal/ErrorModal/ErrorModal";
import SuccessModal from "../../../ui/modal/SuccessModal/SuccessModal";
/* Import local pages and component ends */

// Styles
import "./style.scss";

/* Component starts */

const AddGuestPass = (props) => {
  /* Props destructuring starts */

  const { onSuccess, onCancel, contractorPassReasons, guestPassReasons, unit } =
    props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [typeOfPass, setTypeOfPass] = useState("Contractor");
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [modalDesc, setModalDesc] = useState("");
  const [formValues, setFormValues] = useState(initialValues);
  const [generatedReasonList, setGeneratedReasonList] = useState([]);
  const formikRef = useRef();
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const dispatch = useDispatch();
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */

  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  const onSubmit = async (values) => {
    const payload = {
      ...values,
      property_id: unit?.property_id?._id,
      tenant_id: unit?.tenant?._id,
      unit_no: unit?._id,
      type: typeOfPass,
    };
    if (!values?.image) {
      delete payload["image"];
    }

    // Backend response. Try, Catch
    try {
      const result = await dispatch(addVisitorsPass(payload));

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          setModalDesc(result.payload);
          setShowErrorModal(true);
          break;
        case GLOBAL.FULFILLED:
          setModalDesc(result.payload.message);
          setShowSuccessModal(true);
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };
  /* Component function definition ends */

  /* Component useEffect starts */
  useEffect(() => {
    if (guestPassReasons?.length > 0 && contractorPassReasons?.length > 0) {
      if (typeOfPass === "Contractor") {
        setGeneratedReasonList([...contractorPassReasons]);
        formikRef.current.setFieldValue("reason", "");
      } else if (typeOfPass === "Guest") {
        setGeneratedReasonList([...guestPassReasons]);
        formikRef.current.setFieldValue("reason", "");
      }
    }
  }, [typeOfPass, contractorPassReasons, guestPassReasons]);
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */

  return (
    <div className="add-guestPass-module-wrapper">
      <h2 className="heading text-center font_xxl mb-5">Add Guest Pass</h2>

      <div className="add-new-form">
        <Formik
          onSubmit={onSubmit}
          initialValues={formValues}
          validate={validations}
          validateOnBlur={true}
          enableReinitialize={true}
          innerRef={formikRef}
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
            setFieldValue,
            setFieldTouched,
          }) => (
            <form onSubmit={handleSubmit}>
              <div className="form-wrapper">
                {/* Radio select the Type Of Guest Pass */}
                <div className="form-group form-group-full w_100">
                  <p className="font_m fw_5 font_grey w_100">
                    Add Guest pass as contractor or guest?
                  </p>
                  <CustomRadioInput
                    label="Contractor"
                    onSelecting={() => {
                      setTypeOfPass("Contractor");
                    }}
                    isSelected={typeOfPass === "Contractor"}
                  />
                  <CustomRadioInput
                    label="Guest"
                    onSelecting={() => {
                      setTypeOfPass("Guest");
                    }}
                    isSelected={typeOfPass === "Guest"}
                  />
                </div>

                {/* Input for First Name */}
                <div className="form-group">
                  <CustomTextInput
                    name="visitor_firstname"
                    label="First Name"
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    touched={touched.visitor_firstname}
                    errors={errors.visitor_firstname}
                    value={values.visitor_firstname}
                    required={true}
                  />
                </div>

                {/* Input for Last Name */}
                <div className="form-group">
                  <CustomTextInput
                    name="visitor_lastname"
                    label="Last Name"
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    touched={touched.visitor_lastname}
                    errors={errors.visitor_lastname}
                    value={values.visitor_lastname}
                    required={true}
                  />
                </div>

                {/* Input for Phone Number */}
                <div className="form-group">
                  <CustomPhoneInput
                    name="mobile_number"
                    label="Phone Number"
                    setFieldValue={setFieldValue}
                    setFieldTouched={setFieldTouched}
                    touched={touched.mobile_number}
                    errors={errors.mobile_number}
                    value={values.mobile_number}
                    required={false}
                  />
                </div>

                {/* Select Reason For Access */}
                <div className="form-group">
                  <CustomSelectInput
                    label="Select Reason For Access"
                    name="reason"
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    options={generatedReasonList}
                    value={values.reason}
                    errors={errors.reason}
                    touched={touched.reason}
                    valueExtractor={(d) => d?.name}
                    required={true}
                  />
                </div>

                {/* Input for License Plate Number */}
                <div className="form-group">
                  <CustomTextInput
                    name="car_number"
                    label="Enter License Plate Number"
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    touched={touched.car_number}
                    errors={errors.car_number}
                    value={values.car_number}
                    required={false}
                  />
                </div>

                {/* Input for Photo */}
                <div className="form-group">
                  <CustomFileInput
                    name="image"
                    label="Upload Photo"
                    setFieldValue={setFieldValue}
                    setFieldTouched={setFieldTouched}
                    formikState={true}
                    touched={touched.image}
                    errors={errors.image}
                    value={values.image}
                    required={false}
                  />
                </div>

                {/* Input for Start Date */}
                <div className="form-group">
                  <CustomDateTimeInput
                    name="start_date"
                    label="Start Date"
                    setFieldValue={setFieldValue}
                    setFieldTouched={setFieldTouched}
                    touched={touched.start_date}
                    errors={errors.start_date}
                    value={values.start_date}
                    required={true}
                  />
                </div>

                {/* Input for End Date */}
                <div className="form-group">
                  <CustomDateTimeInput
                    name="end_date"
                    label="End Date"
                    setFieldValue={setFieldValue}
                    setFieldTouched={setFieldTouched}
                    touched={touched.end_date}
                    errors={errors.end_date}
                    value={values.end_date}
                    required={true}
                  />
                </div>
              </div>

              <div className="button-wrapper text-center">
                <CustomButton type="submit" size="l">
                  Add
                </CustomButton>
                <TextButton
                  type="button"
                  onClick={onCancel}
                  className="font_m fw_6"
                >
                  Cancel
                </TextButton>
              </div>
            </form>
          )}
        </Formik>
      </div>

      {/* Success Modal */}
      <SuccessModal
        showModal={showSuccessModal}
        description={modalDesc}
        title="Successfully Created"
        onClose={() => {
          onSuccess();
          setShowSuccessModal(false);
        }}
      />

      {/* Error Modal */}
      <ErrorModal
        showModal={showErrorModal}
        description={modalDesc}
        title="Error"
        onClose={() => setShowErrorModal(false)}
      />
    </div>
  );
};
/* Component ends */

export default AddGuestPass;
