/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
/* Import configuration ends */

/* Import redux slices component starts */
import {
  fetchCommunityProperties,
  getCommunityProperties,
} from "../../setup/store/slices/propertySlice";
import {
  fetchInfoTilesListCommunity,
  getCommunityInfoTilesList,
} from "../../setup/store/slices/infoTileSlice";
import {
  fetchUserRoles,
  getUserRoles,
} from "../../setup/store/slices/masterSlice";
import { fetchUser, getUser } from "../../setup/store/slices/authSlice";
import {
  fetchCommunityDetailByID,
  getCommunityDetail,
} from "../../setup/store/slices/communitySlice";
import { setLoading } from "../../setup/store/slices/unpersistedSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
import { BlankImagePlaceholder } from "../../setup/constants/images";
/* Import image and SVG ends */

/* Import local pages and component starts */
import DetailBox from "./local-components/DetailBox";
import CommunityProperties from "./local-components/CommunityProperties";
import Heading from "./local-components/Heading";
import Image from "../../components/ui/other/Image/Image";
import OverviewSlider from "../../components/module/Overview/OverviewSlider/OverviewSlider";
import Searchbar from "../../components/ui/other/Searchbar/Searchbar";
import Modules from "./local-components/Modules";
/* Import local pages and component ends */

import "./style.scss";
import BreadCrumb from "../../components/ui/other/BreadCrumb/BreadCrumb";

/* Component starts */
const CommunityDetail = (props) => {
  /* Props destructuring starts */
  const {} = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [InfoTiles, setInfoTiles] = useState([]);
  const [role, setRole] = useState();

  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const dispatch = useDispatch();
  const { communityId } = useParams();

  // Redux Selector
  const userRoles = useSelector(getUserRoles);
  const user = useSelector(getUser);
  const community = useSelector(getCommunityDetail);
  const properties = useSelector(getCommunityProperties);

  const communityInfo = useSelector(getCommunityInfoTilesList);

  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  const image = community?.image?.url || BlankImagePlaceholder;
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  /* Component function definition ends */

  /* Component useEffect starts */
  // Fetching all properties under the selected community on load of seleted community
  useEffect(() => {
    (async () => {
      try {
        await dispatch(setLoading(true));
        await dispatch(fetchUserRoles());
        await dispatch(fetchUser());
        await dispatch(setLoading(false));
      } catch (e) {}
    })();
    if (community) {
      dispatch(fetchCommunityProperties(community?._id));

      dispatch(fetchInfoTilesListCommunity({ community_id: communityId }));
    }
  }, [community, communityId]);

  // Setting role after all user roles loaded
  useEffect(() => {
    let userRole = userRoles?.filter((d) => d?._id === user?.role_id)[0]?.name;
    setRole(userRole);
  }, [userRoles]);

  useEffect(() => {
    if (communityInfo) {
      const info = communityInfo;
      setInfoTiles(info);
    }
  }, [communityInfo]);

  /* Component useEffect ends */
  useEffect(() => {
    dispatch(fetchCommunityDetailByID({ community_id: communityId }));
  }, []);

  /* ************* Component rendering. JSX code ************* */
  return (
    <>
      <BreadCrumb type="community_detail" />
      <div className="community-detail-page-wrapper">
        <div className="container_lg">
          <h1 className="font_xl fw_6">Community Overview</h1>
          {/* <div className="search-bar">
            <Searchbar />
          </div> */}

          <div className="slider">
            <OverviewSlider communityInfo={InfoTiles} path="community" />
          </div>

          <div className="p-4 m-2 community-wrap">
            <Heading community={community} />

            <div className="detail-box">
              <div className="image">
                <Image
                  className="image-inner w_100 h_100"
                  src={image}
                  objectFit="cover"
                  objectPosition="center center"
                  alt="Community Image"
                />
              </div>

              <DetailBox community={community} />
            </div>
            <div>
              {role === "Client" && <Modules communityID={communityId} />}
            </div>
          </div>
        </div>

        {/* Community Properties */}
        <div className="container_lg">
          <CommunityProperties community={community} properties={properties} />
        </div>
      </div>
    </>
  );
};
/* Component ends */

export default CommunityDetail;
