/**
 *
 */

import React, { useState, useEffect, useRef } from "react";

/* Import configuration starts */
import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

// import { initialValues, validations } from "./helper";
import { propertyInvoiceValidations } from "../helper";
/* Import configuration ends */

/* Import redux slices component starts */
import { setLoading } from "../../../../../../setup/store/slices/unpersistedSlice";
import { createPropertyInvoice } from "../../../../../../setup/store/slices/propertyInvoiceSlice";
import {
  fetchPropertyDetailByID,
  fetchPropertySettings,
  getPropertyDetail,
  getPropertySettings,
} from "../../../../../../setup/store/slices/propertySlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */

import GLOBAL from "../../../../../../setup/constants/global";
import CustomTextInput from "../../../../../ui/input/CustomTextInput/CustomTextInput";
import CustomDateInput from "../../../../../ui/input/CustomDateInput/CustomDateInput";
import CustomButton from "../../../../../ui/button/CustomButton/CustomButton";
import TextButton from "../../../../../ui/button/TextButton/TextButton";
import CustomTextArea from "../../../../../ui/input/CustomTextArea/CustomTextArea";
import CustomSelectInput from "../../../../../ui/input/CustomSelectInput/CustomSelectInput";
import CustomFileInput from "../../../../../ui/input/CustomFileInput/CustomFileInput";
import ErrorModal from "../../../../../ui/modal/ErrorModal/ErrorModal";
import SuccessModal from "../../../../../ui/modal/SuccessModal/SuccessModal";
import CustomCurrencyInput from "../../../../../ui/input/CustomCurrencyInput/CustomCurrencyInput";
import CustomCheckInput from "../../../../../ui/input/CustomCheckInput/CustomCheckInput";
/* Import local pages and component ends */

/* Component starts */
const CreatePropertyInvoice = (props) => {
  /* Props destructuring starts */

  const { onSuccess, onCancel, type } = props;

  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [createDate, setCreateDate] = useState(new Date());
  const [dueDate, setDueDate] = useState(new Date());
  const [selectedType, setSelectedType] = useState();
  const [selectedService, setSelectedService] = useState();
  const [collectVat, setCollectVat] = useState(false);
  const [collectTax, setCollectTax] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [modalDesc, setModalDesc] = useState("");

  const [typeValue, setTypeValue] = useState([]);
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const dispatch = useDispatch();
  const { propertyId } = useParams();
  // UseRef
  const originalInvoice = useRef(null);
  const propertyDetail = useSelector(getPropertyDetail);
  const settings = useSelector(getPropertySettings);
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */

  let initialFormValues = {
    description: "",
    quantity: "",
    total: "",
    service_name: "",
    type: "",
    invoice_num: "",
  };

  const typeList = [{ name: "Utility" }, { name: "Other" }];
  const serviceList = [
    { name: "Electricity" },
    { name: "Sewerage" },
    { name: "Water" },
    { name: "GAS" },
    { name: "District Cooling" },
    { name: "Telecommunication" },
  ];
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */

  const onSubmit = async (values) => {
    const payload = {
      property_id: propertyId,
      invoice_num: values.invoice_num,
      type: selectedType?.name,
      service_name: selectedService?.name || "",
      description: values.description,
      quantity: values.quantity,
      total: values.total,
      // collect_tax: collectTax,
      // collect_vat: collectVat,
      create_date: createDate,
      due_date: dueDate,
    };

    if (collectVat) {
      payload["collect_vat"] = collectVat;
    }

    if (collectTax) {
      payload["collect_tax"] = collectTax;
    }

    if (originalInvoice) {
      payload["invoice_doc"] = originalInvoice.current;
    }

    console.log(payload, "[PAY-123]");

    // Backend response. Try, Catch
    try {
      const result = await dispatch(createPropertyInvoice(payload));

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          setModalDesc(result.payload);
          setShowErrorModal(true);
          break;

        case GLOBAL.FULFILLED:
          setModalDesc(result.payload.message);
          setShowSuccessModal(true);
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };

  /* Component function definition ends */

  /* Component useEffect starts */
  useEffect(() => {
    if (propertyId) {
      (async () => {
        try {
          await dispatch(setLoading(true));
          await dispatch(fetchPropertyDetailByID(propertyId));
          await dispatch(fetchPropertySettings({ property_id: propertyId }));
          await dispatch(setLoading(false));
        } catch (e) {}
      })();
    }
  }, [propertyId]);

  useEffect(() => {
    if (propertyDetail?.mgmt_model === 1) {
      setTypeValue([{ name: "Other" }]);
    } else {
      setTypeValue([{ name: "Utility" }, { name: "Other" }]);
    }
  }, [propertyDetail]);

  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */

  return (
    <div className="add-property-invoice-module-wrapper">
      <h2 className="heading text-center font_xxl mb-5">
        Create {type} Invoice
      </h2>

      <div className="add-new-form">
        <Formik
          onSubmit={onSubmit}
          initialValues={initialFormValues}
          validate={(values) => propertyInvoiceValidations(values)}
          validateOnBlur={true}
          enableReinitialize={true}
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
            setFieldValue,
            setFieldTouched,
          }) => (
            <form onSubmit={handleSubmit}>
              <div className="form-wrapper">
                {/* Creating Invoice */}
                <>
                  {/* Input for Invoice Number */}
                  <div className="form-group">
                    <CustomTextInput
                      name="invoice_num"
                      label="Invoice Number"
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      touched={touched.invoice_num}
                      errors={errors.invoice_num}
                      value={values.invoice_num}
                      required={true}
                    />
                  </div>

                  {/* Select Type */}

                  <div className="form-group">
                    <CustomSelectInput
                      name="type"
                      label="Type"
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                      valueExtractor={(item) => item?.name}
                      setValue={setSelectedType}
                      options={typeValue}
                      value={selectedType?.name}
                      touched={touched.type}
                      errors={errors.type}
                    />
                  </div>

                  {/* Select Utility Name */}
                  {selectedType?.name === "Utility" && (
                    <div className="form-group">
                      <CustomSelectInput
                        name="service_name"
                        label="Service Name"
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        valueExtractor={(item) => item?.name}
                        setValue={setSelectedService}
                        options={serviceList}
                        value={selectedService?.name}
                        touched={touched.service_name}
                        errors={errors.service_name}
                        emptyOption={false}
                      />
                    </div>
                  )}

                  {/* Text Area for Description */}
                  <div className="form-group form-group-full">
                    <CustomTextArea
                      name="description"
                      label="Description"
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      value={values.description}
                      errors={errors.description}
                      touched={touched.description}
                      required={true}
                    />
                  </div>

                  {/* Input for Quantity */}
                  <div className="form-group">
                    <CustomTextInput
                      name="quantity"
                      label="Quantity"
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      touched={touched.quantity}
                      errors={errors.quantity}
                      value={values.quantity}
                    />
                  </div>

                  {/* Input for Total */}
                  <div className="form-group">
                    <CustomCurrencyInput
                      name="total"
                      label={`Total (${propertyDetail?.currency})`}
                      setFieldValue={setFieldValue}
                      setFieldTouched={setFieldTouched}
                      touched={touched.total}
                      errors={errors.total}
                      value={values.total}
                      currency={propertyDetail?.currency}
                      required={true}
                    />
                    {(settings?.collect_tax_enabled ||
                      settings?.collect_vat_enabled) && (
                      <>
                        {settings?.collect_tax_enabled && (
                          <div className="check">
                            <CustomCheckInput
                              value={collectTax}
                              setValue={setCollectTax}
                            />
                            <span className="font_s font_grey check-text">
                              Collect Tax ({settings?.collect_tax_value}%)
                            </span>
                          </div>
                        )}

                        {settings?.collect_vat_enabled && (
                          <div className="check">
                            <CustomCheckInput
                              value={collectVat}
                              setValue={setCollectVat}
                            />
                            <span className="font_s font_grey check-text">
                              Collect VAT ({settings?.collect_vat_value}%)
                            </span>
                          </div>
                        )}
                      </>
                    )}
                  </div>

                  {/* Input for Create Date */}
                  <div className="form-group">
                    <CustomDateInput
                      label="Create Date"
                      value={createDate}
                      minDate={false}
                      customOnchange={(date) => setCreateDate(date)}
                      required={true}
                    />
                  </div>

                  {/* Input for Due Date */}
                  <div className="form-group">
                    <CustomDateInput
                      label="Due Date"
                      value={dueDate}
                      minDate={false}
                      customOnchange={(date) => setDueDate(date)}
                      required={true}
                    />
                  </div>

                  {/* Input for Payment Date */}
                  {/* <div className="form-group">
                    <CustomDateInput
                      label="Payment Date"
                      value={payDate}
                      minDate={false}
                      customOnchange={(date) => setPayDate(date)}
                      required={true}
                    />
                  </div> */}

                  {/* Select Payment Method */}
                  {/* <div className="form-group">
                    <CustomSelectInput
                      name="method"
                      label="Payment Method"
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                      valueExtractor={(item) => item?.name}
                      setValue={setSelectedPaymentType}
                      options={paymentTypeList}
                      value={selectedPaymentType?.name}
                      touched={touched.method}
                      errors={errors.method}
                      // formikState={false}
                      emptyOption={false}
                    />
                  </div> */}

                  {/* Input for Invoice Upload */}
                  <div className="form-group">
                    <CustomFileInput
                      label="Original Invoice Upload"
                      name="originalInvoice"
                      files={originalInvoice}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      errors={errors.originalInvoice}
                      touched={touched.originalInvoice}
                      values={values.originalInvoice}
                      required={false}
                      formikState={false}
                    />
                  </div>

                  {/* Input for Receipt Upload */}
                  {/* <div className="form-group">
                    <CustomFileInput
                      label="Original Receipt Upload"
                      name="originalReceipt"
                      files={originalReceipt}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      errors={errors.originalReceipt}
                      touched={touched.originalReceipt}
                      values={values.originalReceipt}
                      required={false}
                      formikState={false}
                    />
                  </div> */}
                </>
              </div>

              <div className="button-wrapper text-center">
                <>
                  <CustomButton type="submit" size="l">
                    Create
                  </CustomButton>
                  <TextButton
                    type="button"
                    onClick={onCancel}
                    className="font_m fw_6"
                  >
                    Cancel
                  </TextButton>
                </>
              </div>
            </form>
          )}
        </Formik>
      </div>

      {/* Success Modal */}
      <SuccessModal
        showModal={showSuccessModal}
        description={modalDesc}
        title="Successfully Created"
        onClose={() => {
          onSuccess();
          setShowSuccessModal(false);
        }}
      />

      {/* Error Modal */}
      <ErrorModal
        showModal={showErrorModal}
        description={modalDesc}
        title="Error"
        onClose={() => setShowErrorModal(false)}
      />
    </div>
  );
};
/* Component ends */

export default CreatePropertyInvoice;
