/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import {
  logTitle,
  canOwnerStartMaintenance,
  canOwnerCreateQuotation,
} from "../helper";
import moment from "moment";
import GLOBAL from "../../../../../setup/constants/global";
import { useDispatch, useSelector } from "react-redux";
/* Import configuration ends */

/* Import redux slices component starts */
import {
  showSuccessAlert,
  showErrorAlert,
} from "../../../../../setup/store/slices/globalAlertSlice";
import { ownerRespondToT1Maintenance } from "../../../../../setup/store/slices/maintenanceSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import FileViewerModal from "../../../../ui/modal/FileViewerModal/FileViewerModal";
import Image from "../../../../ui/other/Image/Image";
import CustomButton from "../../../../ui/button/CustomButton/CustomButton";
/* Import local pages and component ends */

/* Component starts */
const MaintenanceFinalReportLog = (props) => {
  /* Props destructuring starts */
  const {
    property,
    maintenance_id,
    maintenance,
    item,
    status,
    isT1,
    takeAction,
    user,
  } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [file, setFile] = useState({});
  const [showFile, setShowFile] = useState(false);
  const [totalEstimation, setTotalEstimation] = useState(0);
  // const [estimationArray, setEstimationArray] = useState([]);

  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const dispatch = useDispatch();
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  const name = item?.requester
    ? `${item?.requester?.first_name} ${item?.requester?.last_name}`
    : `${item.tenant.first_name} ${item.tenant.last_name}`;
  const role = item?.requester_role?.name;
  const createdAt = item?.createdAt;
  const initialURIC = maintenance?.initial_URIC;
  const maintenanceType = maintenance?.type;
  const unitCard = maintenance?.unit_card;
  const USER_DATA = localStorage.getItem("user_data")
    ? JSON.parse(localStorage.getItem("user_data"))
    : null;
  const DATE_FORMAT = USER_DATA ? USER_DATA?.date_format : "";
  const TIME_FORMAT = USER_DATA ? USER_DATA?.time_format : "";
  const DATE_TIME_FORMAT = `${DATE_FORMAT} ${TIME_FORMAT}`;
  const quote = maintenance?.quotes;
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  const calculateTotalEstimation = (items) => {
    const total = items?.reduce((acc, item) => acc + item?.estimate, 0);
    setTotalEstimation(total);
  };
  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="time-line maintenance-final-log">
      {/* Dot */}

      <div className="dot-wrapper">
        <i className="dot"></i>
        <div className="vl"></div>
      </div>

      <div className="detail-wrapper">
        {/* Title */}
        <h3 className="title font_m fw_6">{logTitle(item)}</h3>

        {/* Technician Name and Date */}
        <div className="black-container-sm fw_5 font_s bg_black flex-between">
          {/* Name */}
          <p className="left">
            <span className="font_grey">{role} </span>
            <span className="font_white"> {name}</span>
          </p>

          {/* Date and Time */}
          <p className="right font_grey">
            {moment(createdAt).format(DATE_TIME_FORMAT)}
          </p>
        </div>

        <div className="uirc-detail">
          {/* Details */}
          <div className="black-container-sm uirc-data">
            {item?.data?.items?.map((estimateItem, index) => (
              <div className="report-item">
                {/* Header */}
                <h2 className="font_m fw_6 font_blue report-item-heading">
                  Report Item #{index + 1}
                </h2>
                <div className="r font_s fw_5">
                  <p className="c1 font_grey">Description</p>
                  {/* <p className="c2 font_grey">Estimate ({property.currency})</p> */}
                </div>

                {/* Body */}
                <div key={index} className="r font_s fw_5">
                  <div className="c1">
                    <p className="font_m fw_5 font_white">
                      {estimateItem?.description}
                    </p>

                    <h3 className="attachments-txt fw_5 font_s font_grey">
                      Attachments
                    </h3>

                    {estimateItem?.documents?.length > 0 ? (
                      <div className="doc-row">
                        {estimateItem?.documents?.map((doc, index) => (
                          <Image
                            onClick={() => {
                              setFile(doc);
                              setShowFile(true);
                            }}
                            src={doc?.url}
                            videoThumbnail={doc?.type2 === "video"}
                            className="doc-item"
                            objectFit="cover"
                          />
                        ))}
                      </div>
                    ) : (
                      <p className="font_xxs font_yellow fw_4">
                        No Attachments Added!
                      </p>
                    )}
                  </div>

                  {/* <div className="c2">
                    <input
                      type="text"
                      className="transparent-input fw_5"
                      readOnly={true}
                      value={estimateItem?.estimate?.toLocaleString("en-US")}
                    />
                  </div> */}
                </div>
              </div>
            ))}
            <hr />

            {/* <div className="my-2 r font_s fw_5">
              <p className="font_m fw_5 c1 font_grey">Total Amount </p>
              <p className="font_m fw_6 c2 font_blue">
                {totalEstimation?.toLocaleString("en-US")} ({property?.currency}
                )
              </p>
            </div> */}
          </div>
        </div>

        {takeAction && <div className="button-wrapper"></div>}
      </div>

      {/* File Viewer Modal */}
      <FileViewerModal
        show={showFile}
        file={file.url}
        type={file.type}
        onClose={() => {
          setShowFile(false);
          setFile({});
        }}
      />
    </div>
  );
};
/* Component ends */

export default MaintenanceFinalReportLog;
