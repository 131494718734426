/**
 * @auther Abdul Ahad <aabdul@dgrnte.com>
 * Slice to handle internal maintenance
 */

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API_URL } from "../../config/devKeys";
import axios, { axiosInstance } from "../axios";
import {
  setLoading,
  setShowUploadProgress,
  setUploadProgress,
} from "./unpersistedSlice";
import { PLATFORM } from "../../platformUtil";

const initialState = {
  maintenance: null,
  maintenanceList: [],
  maintenanceLocations: [],
  maintenanceCategories: [],
  maintenanceSubCategories: [],
  maintenanceInnerCategories: [],
  technicians: [],
  maintenanceSpareList: [],
};

export const fetchInternalMaintenanceLocations = createAsyncThunk(
  "internal_maintenance/locations",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const { data } = await axios.get(
        // API_URL +
        //   `/internal_maintenance_location/list?property_id=${payload.property_id}`
        `${API_URL}/internal_maintenance_location/list?property_id=${payload.property_id}`
      );
      if (data.success) {
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      return rejectWithValue(error?.response);
    }
  }
);

export const addInternalQuote = createAsyncThunk(
  "internal_maintenance/quote_add",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const { maintenance_id } = payload;
      dispatch(setLoading(true));
      const formData = new FormData();
      Object.keys(payload).map((key) => {
        if (key === "part") {
          // stringifying array of string
          formData.append(key, JSON.stringify(payload[key]));
        } else {
          formData.append(key, payload[key]);
        }
      });
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("user_token")}`,
      };
      if (PLATFORM !== "web") {
        headers["Content-Type"] = "multipart/form-data";
      }
      // const response = await fetch(API_URL + "/internal_quote/add", {
      //   method: "POST",
      //   headers: headers,
      //   body: formData,
      // });
      // const data = await response.json();
      dispatch(setShowUploadProgress(true));
      const config = {
        onUploadProgress: ({ loaded, total }) => {
          const progress = (loaded / total) * 100;
          dispatch(setUploadProgress(progress));
        },
        method: "POST",
        url: "/internal_quote/add",
        headers,
        transformRequest: (data, headers) => {
          return formData;
        },
        data: formData,
        responseType: "json",
      };

      // const data = await response.json();

      const { data } = await axiosInstance.request(config);

      if (data.success) {
        await dispatch(fetchInternalMaintenanceById({ maintenance_id }));
        dispatch(setLoading(false));
        return data.data;
      } else {
        dispatch(setLoading(false));
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

export const addInternalQuoteT3 = createAsyncThunk(
  "internal_quote/t3_add_internal_quotation",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const { maintenance_id } = payload;
      dispatch(setLoading(true));
      const formData = new FormData();
      Object.keys(payload).map((key) => {
        if (key === "part") {
          // stringifying array of string
          formData.append(key, JSON.stringify(payload[key]));
        } else {
          formData.append(key, payload[key]);
        }
      });
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("user_token")}`,
      };
      if (PLATFORM !== "web") {
        headers["Content-Type"] = "multipart/form-data";
      }
      // const response = await fetch(API_URL + "/internal_quote/add", {
      //   method: "POST",
      //   headers: headers,
      //   body: formData,
      // });
      // const data = await response.json();
      dispatch(setShowUploadProgress(true));
      const config = {
        onUploadProgress: ({ loaded, total }) => {
          const progress = (loaded / total) * 100;
          dispatch(setUploadProgress(progress));
        },
        method: "POST",
        url: "/internal_quote/t3_add_internal_quotation",
        headers,
        transformRequest: (data, headers) => {
          return formData;
        },
        data: formData,
        responseType: "json",
      };

      // const data = await response.json();

      const { data } = await axiosInstance.request(config);

      if (data.success) {
        await dispatch(fetchInternalMaintenanceById({ maintenance_id }));
        dispatch(setLoading(false));
        return data.data;
      } else {
        dispatch(setLoading(false));
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

export const addT2InternalQuote = createAsyncThunk(
  "internal_maintenance/add_internal_t2_quotation",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const { maintenance_id } = payload;
      dispatch(setLoading(true));
      const formData = new FormData();
      Object.keys(payload).map((key) => {
        if (key === "part") {
          // stringifying array of string
          formData.append(key, JSON.stringify(payload[key]));
        } else {
          formData.append(key, payload[key]);
        }
      });
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("user_token")}`,
      };
      if (PLATFORM !== "web") {
        headers["Content-Type"] = "multipart/form-data";
      }
      // const response = await fetch(
      //   API_URL + "/internal_quote/add_internal_t2_quotation",
      //   {
      //     method: "POST",
      //     headers: headers,
      //     body: formData,
      //   }
      // );
      // const data = await response.json();
      dispatch(setShowUploadProgress(true));
      const config = {
        onUploadProgress: ({ loaded, total }) => {
          const progress = (loaded / total) * 100;
          dispatch(setUploadProgress(progress));
        },
        method: "POST",
        url: "/internal_quote/add_internal_t2_quotation",
        headers,
        transformRequest: (data, headers) => {
          return formData;
        },
        data: formData,
        responseType: "json",
      };

      // const data = await response.json();

      const { data } = await axiosInstance.request(config);

      if (data.success) {
        await dispatch(fetchInternalMaintenanceById({ maintenance_id }));
        dispatch(setLoading(false));
        return data.data;
      } else {
        dispatch(setLoading(false));
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

export const ownerRespondToT1InternalMaintenance = createAsyncThunk(
  "internal_maintenance/owner_respond",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const formData = new FormData();
      Object.keys(payload).map((key) => {
        if (key === "document") {
          payload[key].forEach((item) => {
            if (PLATFORM !== "web") {
              formData.append(key, {
                name: item.name,
                type: item.type,
                uri:
                  PLATFORM === "android"
                    ? item.uri
                    : item.uri.replace("file://", ""),
              });
            } else {
              formData.append(key, item.file, item.name);
            }
          });
        } else {
          if (key === "description") {
            // stringifying array of string
            formData.append(key, JSON.stringify(payload[key]));
          } else {
            formData.append(key, payload[key]);
          }
        }
      });
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("user_token")}`,
      };
      if (PLATFORM !== "web") {
        headers["Content-Type"] = "multipart/form-data";
      }
      // const response = await fetch(
      //   API_URL + "/internal_maintenance/action_by_owner",
      //   {
      //     method: "POST",
      //     headers: headers,
      //     body: formData,
      //   }
      // );
      // const data = await response.json();
      dispatch(setShowUploadProgress(true));
      const config = {
        onUploadProgress: ({ loaded, total }) => {
          const progress = (loaded / total) * 100;
          dispatch(setUploadProgress(progress));
        },
        method: "POST",
        url: "/internal_maintenance/action_by_owner",
        headers,
        transformRequest: (data, headers) => {
          return formData;
        },
        data: formData,
        responseType: "json",
      };

      // const data = await response.json();

      const { data } = await axiosInstance.request(config);

      dispatch(setLoading(false));
      if (data.success) {
        await dispatch(
          fetchInternalMaintenanceById({
            maintenance_id: payload.maintenance_id,
          })
        );
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

export const mtAddInspectionT2 = createAsyncThunk(
  "internal_maintenance/t2_mt_add_initial_report",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const formData = new FormData();
      Object.keys(payload).map((key) => {
        if (key === "document") {
          payload[key].forEach((item) => {
            if (PLATFORM !== "web") {
              formData.append(key, {
                name: item.name,
                type: item.type,
                uri:
                  PLATFORM === "android"
                    ? item.uri
                    : item.uri.replace("file://", ""),
              });
            } else {
              formData.append(key, item.file, item.name);
            }
          });
        } else {
          if (key === "description") {
            // stringifying array of string
            formData.append(key, JSON.stringify(payload[key]));
          } else {
            formData.append(key, payload[key]);
          }
        }
      });
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("user_token")}`,
      };
      if (PLATFORM !== "web") {
        headers["Content-Type"] = "multipart/form-data";
      }
      // const response = await fetch(
      //   API_URL + "/internal_maintenance/action_by_owner",
      //   {
      //     method: "POST",
      //     headers: headers,
      //     body: formData,
      //   }
      // );
      // const data = await response.json();
      dispatch(setShowUploadProgress(true));
      const config = {
        onUploadProgress: ({ loaded, total }) => {
          const progress = (loaded / total) * 100;
          dispatch(setUploadProgress(progress));
        },
        method: "POST",
        url: "/internal_maintenance/t2_mt_add_initial_report",
        headers,
        transformRequest: (data, headers) => {
          return formData;
        },
        data: formData,
        responseType: "json",
      };

      // const data = await response.json();

      const { data } = await axiosInstance.request(config);

      dispatch(setLoading(false));
      if (data.success) {
        await dispatch(
          fetchInternalMaintenanceById({
            maintenance_id: payload.maintenance_id,
          })
        );
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

export const tptAddInspectionT2 = createAsyncThunk(
  "internal_maintenance/t2_party_technician_addinitial_report",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const formData = new FormData();
      Object.keys(payload).map((key) => {
        if (key === "document") {
          payload[key].forEach((item) => {
            if (PLATFORM !== "web") {
              formData.append(key, {
                name: item.name,
                type: item.type,
                uri:
                  PLATFORM === "android"
                    ? item.uri
                    : item.uri.replace("file://", ""),
              });
            } else {
              formData.append(key, item.file, item.name);
            }
          });
        } else {
          if (key === "description") {
            // stringifying array of string
            formData.append(key, JSON.stringify(payload[key]));
          } else {
            formData.append(key, payload[key]);
          }
        }
      });
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("user_token")}`,
      };
      if (PLATFORM !== "web") {
        headers["Content-Type"] = "multipart/form-data";
      }
      // const response = await fetch(
      //   API_URL + "/internal_maintenance/action_by_owner",
      //   {
      //     method: "POST",
      //     headers: headers,
      //     body: formData,
      //   }
      // );
      // const data = await response.json();
      dispatch(setShowUploadProgress(true));
      const config = {
        onUploadProgress: ({ loaded, total }) => {
          const progress = (loaded / total) * 100;
          dispatch(setUploadProgress(progress));
        },
        method: "POST",
        url: "/internal_maintenance/t2_party_technician_addinitial_report",
        headers,
        transformRequest: (data, headers) => {
          return formData;
        },
        data: formData,
        responseType: "json",
      };

      // const data = await response.json();

      const { data } = await axiosInstance.request(config);

      dispatch(setLoading(false));
      if (data.success) {
        await dispatch(
          fetchInternalMaintenanceById({
            maintenance_id: payload.maintenance_id,
          })
        );
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

export const fetchInternalMaintenanceById = createAsyncThunk(
  "internal_maintenance/get",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const { data } = await axios.post(
        API_URL + "/internal_maintenance/get",
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      dispatch(setLoading(false));
      if (data.success) {
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

export const fetchInternalMaintenanceCategories = createAsyncThunk(
  "internal_maintenance/categories",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const { data } = await axios.get(
        `${API_URL}/internal_maintenance_category/list?property_id=${payload.property_id}`
      );
      if (data.success) {
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      return rejectWithValue(error?.response);
    }
  }
);

export const fetchInternalMaintenanceSubCategories = createAsyncThunk(
  "internal_maintenance/sub_categories",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const { data } = await axios.get(
        `${API_URL}/internal_maintenance_sub_category/list?id=${payload}`
      );
      if (data.success) {
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      return rejectWithValue(error?.response);
    }
  }
);

export const fetchInternalMaintenanceInnerCategories = createAsyncThunk(
  "internal_maintenance/inner_categories",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const { data } = await axios.get(
        `${API_URL}/internal_maintenance_inner_category/list?id=${payload}`
      );
      if (data.success) {
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      return rejectWithValue(error?.response);
    }
  }
);

export const createInternalMaintenanceReq = createAsyncThunk(
  "internal_maintenance/create",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const { property_id } = payload;
      dispatch(setLoading(true));
      const formData = new FormData();
      Object.keys(payload).map((key) => {
        if (key === "document") {
          if (PLATFORM !== "web") {
            payload[key].forEach((file) => {
              formData.append(key, {
                name: file.name,
                type: file.type,
                uri:
                  PLATFORM === "android"
                    ? file.uri
                    : file.uri.replace("file://", ""),
              });
            });
          } else {
            payload[key].forEach((file) => {
              formData.append(key, file, file.name);
            });
          }
        } else {
          formData.append(key, payload[key]);
        }
      });
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("user_token")}`,
      };
      if (PLATFORM !== "web") {
        headers["Content-Type"] = "multipart/form-data";
      }
      // const response = await fetch(API_URL + "/internal_maintenance/add", {
      //   method: "POST",
      //   headers: headers,
      //   body: formData,
      // });
      // const data = await response.json();

      dispatch(setShowUploadProgress(true));
      const config = {
        onUploadProgress: ({ loaded, total }) => {
          const progress = (loaded / total) * 100;
          dispatch(setUploadProgress(progress));
        },
        method: "POST",
        url: "/internal_maintenance/add",
        headers,
        transformRequest: (data, headers) => {
          return formData;
        },
        data: formData,
        responseType: "json",
      };

      // const data = await response.json();

      const { data } = await axiosInstance.request(config);

      dispatch(setLoading(false));
      if (data.success) {
        dispatch(
          fetchAllNewInternalMaintenance({
            property_id,
            page: 1,
            limit: 20,
          })
        );
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

export const fetchAllNewInternalMaintenance = createAsyncThunk(
  "internal_maintenance/list_all_new",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const { data } = await axios.post(
        API_URL + "/internal_maintenance/list_all_new",
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      dispatch(setLoading(false));
      if (data.success) {
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

export const fetchAllOngoingInternalMaintenance = createAsyncThunk(
  "internal_maintenance/list_all_ongoing",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const { data } = await axios.post(
        API_URL + "/internal_maintenance/list_all_ongoing",
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );

      dispatch(setLoading(false));
      if (data.success) {
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

export const fetchAllSparePartsInternalMaintenance = createAsyncThunk(
  "internal_maintenance/list_all_spareParts",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const { data } = await axios.post(
        API_URL + "/internal_maintenance/list_all_spareParts",
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );

      dispatch(setLoading(false));
      if (data.success) {
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

export const fetchAllCompletedInternalMaintenance = createAsyncThunk(
  "internal_maintenance/list_all_completed",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const { data } = await axios.post(
        API_URL + "/internal_maintenance/list_all_completed",
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      dispatch(setLoading(false));
      if (data.success) {
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

// Rejected Lists
export const fetchAllRejectedInternalMaintenance = createAsyncThunk(
  "internal_maintenance/list_all_rejected",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const { data } = await axios.post(
        API_URL + "/internal_maintenance/list_all_rejected",
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      dispatch(setLoading(false));
      if (data.success) {
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

export const fetchAllClosedInternalMaintenance = createAsyncThunk(
  "internal_maintenance/list_all_closed",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const { data } = await axios.post(
        API_URL + "/internal_maintenance/list_all_closed",
        // 'http://localhost:3000/internal_maintenance/list_all_closed',
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      dispatch(setLoading(false));
      if (data.success) {
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

/**
 * Maintenance thunk
 */

const createThunk = (name, end_point) => {
  return createAsyncThunk(
    name,
    async (payload, { rejectWithValue, getState, dispatch }) => {
      try {
        const { data } = await axios.post(
          API_URL + end_point,
          // `http://localhost:3000/${end_point}`,
          // "http://localhost:3000" + end_point,
          payload,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("user_token")}`,
            },
          }
        );
        if (data.success) {
          await dispatch(
            fetchAllNewInternalMaintenance({
              type: "Maintenance",
              status: payload.type,
            })
          );
          await dispatch(
            fetchInternalMaintenanceById({
              maintenance_id: payload.maintenance_id,
            })
          );
          return data.data;
        } else {
          return rejectWithValue(data.error.message);
        }
      } catch (error) {
        return rejectWithValue(error?.response);
      }
    }
  );
};

export const ownerGenerateCostInvoice = createAsyncThunk(
  "internal_maintenance/create_invoice_against_internal_quotation",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const { data } = await axios.post(
        `${API_URL}/quote/create_invoice_against_internal_quotation`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      dispatch(setLoading(false));
      if (data.success) {
        if (payload.maintenance_id) {
          await dispatch(
            fetchInternalMaintenanceById({
              maintenance_id: payload.maintenance_id,
            })
          );
        }
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

/**
 * Maintenance T3 with FM
 */

export const fmRespondToT3Maintenance = createThunk(
  "internal_maintenance/t3/fm_respond",
  "/internal_maintenance/action_by_fm"
);

export const fmAssignTechnician = createThunk(
  "internal_maintenance/t3/fm_assign_technician",
  "/internal_maintenance/assign_technician"
);

export const technicianTakeAction = createThunk(
  "internal_maintenance/t3/technician_take_action",
  "/internal_maintenance/technician_take_action"
);

export const markTechnicianOnTheWay = createThunk(
  "internal_maintenance/t3/mark_tech_on_the_way",
  "/internal_maintenance/mark_technician_on_the_way"
);

export const technicianCheckedIn = createThunk(
  "internal_maintenance/t3/technician_checked_in",
  "/internal_maintenance/technician_checked_in"
);

export const technicianAddSpareParts = createThunk(
  "internal_maintenance/t3/technician_add_spare_parts",
  "/internal_maintenance/addSpartParts"
);

export const fmRespondToSpareParts = createThunk(
  "internal_maintenance/t3/fm_respond_to_spare_parts",
  "/internal_maintenance/respond_to_spare_parts"
);

export const pmRespondToQuotes = createThunk(
  "internal_maintenance/t3/pm_respond_to_quotes",
  "/internal_quote/respond_quotation_request"
);
export const changeOrderStatus = createThunk(
  "internal_maintenance/t3/change_order_status",
  "/internal_quote/change_order_status"
);

export const technicianStartWork = createThunk(
  "internal_maintenance/t3/technician_started_work",
  "/internal_maintenance/technician_started_work"
);

export const _technicianMarkJobComplete = createThunk(
  "internal_maintenance/t3/technician_completed_the_work",
  "/internal_maintenance/technician_completed_the_work"
);

// MT Completed the Work
export const technicianMarkJobComplete = createAsyncThunk(
  "internal_maintenance/t3/technician_completed_the_work",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const formData = new FormData();
      Object.keys(payload).map((key) => {
        if (key === "document") {
          payload[key].forEach((item) => {
            if (PLATFORM !== "web") {
              formData.append(key, {
                name: item.name,
                type: item.type,
                uri:
                  PLATFORM === "android"
                    ? item.uri
                    : item.uri.replace("file://", ""),
              });
            } else {
              formData.append(key, item.file, item.name);
            }
          });
        } else {
          if (key === "description") {
            // stringifying array of string
            formData.append(key, JSON.stringify(payload[key]));
          } else {
            formData.append(key, payload[key]);
          }
        }
      });
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("user_token")}`,
      };
      if (PLATFORM !== "web") {
        headers["Content-Type"] = "multipart/form-data";
      }
      dispatch(setShowUploadProgress(true));
      const config = {
        onUploadProgress: ({ loaded, total }) => {
          const progress = (loaded / total) * 100;
          dispatch(setUploadProgress(progress));
        },
        method: "POST",
        url: "/internal_maintenance/technician_completed_the_work",
        headers,
        transformRequest: (data, headers) => {
          return formData;
        },
        data: formData,
        responseType: "json",
      };

      // const data = await response.json();

      const { data } = await axiosInstance.request(config);

      dispatch(setLoading(false));
      if (data.success) {
        await dispatch(
          fetchInternalMaintenanceById({
            maintenance_id: payload.maintenance_id,
          })
        );
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

export const fmRespondToInternalMaintenance = createThunk(
  "internal_maintenance/t3/fm_respond_to_work",
  "/internal_maintenance/fm_respond_to_work"
);

export const pmRespondToInternalMaintenance = createThunk(
  "internal_maintenance/t3/pm_respond_to_work",
  "/internal_maintenance/pm_respond_to_work"
);

export const reassignMaintenanceTicket = createThunk(
  "internal_maintenance/t3/reassign_internal_ticket",
  "/internal_maintenance/reassign_internal_ticket"
);

/**
 * Maintenance T3 with TPM
 */

export const tpmRespondToT3Maintenance = createThunk(
  "internal_maintenance/t3/action_by_3PM",
  "/internal_maintenance/action_by_3PM"
);

export const tpmAssignTechnician = createThunk(
  "internal_maintenance/t3/assign_third_party_technician",
  "/internal_maintenance/assign_third_party_technician"
);

export const tpTechnicianTakeAction = createThunk(
  "internal_maintenance/t3/party_technician_take_action",
  "/internal_maintenance/party_technician_take_action"
);

export const tpTechnicianMarkOnTheWay = createThunk(
  "internal_maintenance/t3/party_technician_on_the_way",
  "/internal_maintenance/party_technician_on_the_way"
);

export const tpTechnicianCheckedIn = createThunk(
  "internal_maintenance/t3/party_technician_checked_in",
  "/internal_maintenance/party_technician_checked_in"
);

export const tpTechnicianStartWork = createThunk(
  "internal_maintenance/t3/party_technician_started_work",
  "/internal_maintenance/party_technician_started_work"
);

export const _tpTechnicianMarkJobComplete = createThunk(
  "internal_maintenance/t3/party_technician_completed_the_work",
  "/internal_maintenance/party_technician_completed_the_work"
);

export const pmApproveFinalMaintenanceByTpt = createThunk(
  "internal_maintenance/t3/pm_respond_to_party_work",
  "/internal_maintenance/pm_respond_to_party_work"
);

export const pmApproveFinalMaintenanceByMt = createThunk(
  "internal_maintenance/t3/pm_respond_to_work",
  "/internal_maintenance/pm_respond_to_work"
);

// MT Completed the Work
export const tpTechnicianMarkJobComplete = createAsyncThunk(
  "internal_maintenance/t3/party_technician_completed_the_work",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const formData = new FormData();
      Object.keys(payload).map((key) => {
        if (key === "document") {
          payload[key].forEach((item) => {
            if (PLATFORM !== "web") {
              formData.append(key, {
                name: item.name,
                type: item.type,
                uri:
                  PLATFORM === "android"
                    ? item.uri
                    : item.uri.replace("file://", ""),
              });
            } else {
              formData.append(key, item.file, item.name);
            }
          });
        } else {
          if (key === "description") {
            // stringifying array of string
            formData.append(key, JSON.stringify(payload[key]));
          } else {
            formData.append(key, payload[key]);
          }
        }
      });
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("user_token")}`,
      };
      if (PLATFORM !== "web") {
        headers["Content-Type"] = "multipart/form-data";
      }
      dispatch(setShowUploadProgress(true));
      const config = {
        onUploadProgress: ({ loaded, total }) => {
          const progress = (loaded / total) * 100;
          dispatch(setUploadProgress(progress));
        },
        method: "POST",
        url: "/internal_maintenance/party_technician_completed_the_work",
        headers,
        transformRequest: (data, headers) => {
          return formData;
        },
        data: formData,
        responseType: "json",
      };

      // const data = await response.json();

      const { data } = await axiosInstance.request(config);

      dispatch(setLoading(false));
      if (data.success) {
        await dispatch(
          fetchInternalMaintenanceById({
            maintenance_id: payload.maintenance_id,
          })
        );
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

export const _t2FmRespondToJob = createThunk(
  "internal_maintenance/t2/t2_fm_respond_to_job",
  "/internal_maintenance/t2_fm_respond_to_job"
);

export const t2FmRespondToJob = createAsyncThunk(
  "internal_maintenance/t2/t2_fm_respond_to_job",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const formData = new FormData();
      Object.keys(payload).map((key) => {
        if (key === "document") {
          payload[key].forEach((item) => {
            if (PLATFORM !== "web") {
              formData.append(key, {
                name: item.name,
                type: item.type,
                uri:
                  PLATFORM === "android"
                    ? item.uri
                    : item.uri.replace("file://", ""),
              });
            } else {
              formData.append(key, item.file, item.name);
            }
          });
        } else {
          if (key === "description") {
            // stringifying array of string
            formData.append(key, JSON.stringify(payload[key]));
          } else {
            formData.append(key, payload[key]);
          }
        }
      });
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("user_token")}`,
      };
      if (PLATFORM !== "web") {
        headers["Content-Type"] = "multipart/form-data";
      }
      dispatch(setShowUploadProgress(true));
      const config = {
        onUploadProgress: ({ loaded, total }) => {
          const progress = (loaded / total) * 100;
          dispatch(setUploadProgress(progress));
        },
        method: "POST",
        url: "/internal_maintenance/t2_fm_respond_to_job",
        headers,
        transformRequest: (data, headers) => {
          return formData;
        },
        data: formData,
        responseType: "json",
      };

      // const data = await response.json();

      const { data } = await axiosInstance.request(config);

      dispatch(setLoading(false));
      if (data.success) {
        await dispatch(
          fetchInternalMaintenanceById({
            maintenance_id: payload.maintenance_id,
          })
        );
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

export const t2FmCancelMaintenanceWork = createThunk(
  "internal_maintenance/t2/t2_fm_cancel_maintenance",
  "/internal_maintenance/t2_fm_cancel_maintenance"
);

/*
 INTERNAL MAINTENANCE T2 FLOW
*/

// FM respond - ticket
export const fmRespondToT2InternalMaintenance = createThunk(
  "internal_maintenance/t2/t2_action_by_fm",
  "/internal_maintenance/t2_action_by_fm"
);

// FM respond - quote
export const fmRespondToT2Quote = createThunk(
  "internal_quote/t2_respond_quotation_request",
  "/internal_quote/t2_respond_quotation_request"
);

// FM Reject - ticket
export const t2FmRejectInternalMaintenanceticket = createThunk(
  "internal_maintenance/t2/t2_fm_reject_maintenance",
  "/internal_maintenance/t2_fm_reject_maintenance"
);

// FM Assign Technician
export const fmAssignT2Technician = createThunk(
  "internal_maintenance/t2/t2_fm_respond_ticket",
  "/internal_maintenance/t2_fm_assign_maintenance_technician"
);

// Technician respond to ticket
export const t2TechnicianTakeAction = createThunk(
  "internal_maintenance/t2/t2_technician_respond_ticket",
  "/internal_maintenance/t2_technician_take_action"
);

// Technician respond - On the Way
export const t2MarkTechnicianOnTheWay = createThunk(
  "internal_maintenance/t2/mark_t2_technician_on_the_way",
  "/internal_maintenance/mark_t2_technician_on_the_way"
);

// Technician Respond - Checked In
export const t2TechnicianCheckedIn = createThunk(
  "internal_maintenance/t2/t2_technician_checked_in",
  "/internal_maintenance/t2_technician_checked_in"
);

// Technician Respond - Checked In
export const t3TechnicianCheckedIn = createThunk(
  "internal_maintenance/t3/t3_technician_checked_in",
  "/internal_maintenance/t3_technician_checked_in"
);

// Techniciian Respond - Started Work
export const t2TechnicianStartWork = createThunk(
  "internal_maintenance/t2/t2_technician_started_work",
  "/internal_maintenance/t2_technician_started_work"
);

// Started the WORK ( MT )
export const _t2TechnicianStartWork = createAsyncThunk(
  "internal_maintenance/t2/t2_technician_started_work",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const formData = new FormData();
      Object.keys(payload).map((key) => {
        if (key === "document") {
          payload[key].forEach((item) => {
            if (PLATFORM !== "web") {
              formData.append(key, {
                name: item.name,
                type: item.type,
                uri:
                  PLATFORM === "android"
                    ? item.uri
                    : item.uri.replace("file://", ""),
              });
            } else {
              formData.append(key, item.file, item.name);
            }
          });
        } else {
          if (key === "description") {
            // stringifying array of string
            formData.append(key, JSON.stringify(payload[key]));
          } else {
            formData.append(key, payload[key]);
          }
        }
      });
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("user_token")}`,
      };
      if (PLATFORM !== "web") {
        headers["Content-Type"] = "multipart/form-data";
      }
      dispatch(setShowUploadProgress(true));
      const config = {
        onUploadProgress: ({ loaded, total }) => {
          const progress = (loaded / total) * 100;
          dispatch(setUploadProgress(progress));
        },
        method: "POST",
        url: "/internal_maintenance/t2_technician_started_work",
        headers,
        transformRequest: (data, headers) => {
          return formData;
        },
        data: formData,
        responseType: "json",
      };

      // const data = await response.json();

      const { data } = await axiosInstance.request(config);

      dispatch(setLoading(false));
      if (data.success) {
        await dispatch(
          fetchInternalMaintenanceById({
            maintenance_id: payload.maintenance_id,
          })
        );
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

// MT Completed the Work
export const t2TechnicianMarkJobComplete = createAsyncThunk(
  "internal_maintenance/t2/t2_technician_completed_the_work",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const formData = new FormData();
      Object.keys(payload).map((key) => {
        if (key === "document") {
          payload[key].forEach((item) => {
            if (PLATFORM !== "web") {
              formData.append(key, {
                name: item.name,
                type: item.type,
                uri:
                  PLATFORM === "android"
                    ? item.uri
                    : item.uri.replace("file://", ""),
              });
            } else {
              formData.append(key, item.file, item.name);
            }
          });
        } else {
          if (key === "description") {
            // stringifying array of string
            formData.append(key, JSON.stringify(payload[key]));
          } else {
            formData.append(key, payload[key]);
          }
        }
      });
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("user_token")}`,
      };
      if (PLATFORM !== "web") {
        headers["Content-Type"] = "multipart/form-data";
      }
      dispatch(setShowUploadProgress(true));
      const config = {
        onUploadProgress: ({ loaded, total }) => {
          const progress = (loaded / total) * 100;
          dispatch(setUploadProgress(progress));
        },
        method: "POST",
        url: "/internal_maintenance/t2_technician_completed_the_work",
        headers,
        transformRequest: (data, headers) => {
          return formData;
        },
        data: formData,
        responseType: "json",
      };

      // const data = await response.json();

      const { data } = await axiosInstance.request(config);

      dispatch(setLoading(false));
      if (data.success) {
        await dispatch(
          fetchInternalMaintenanceById({
            maintenance_id: payload.maintenance_id,
          })
        );
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

/*
 INTERNAL MAINTENANCE T2 FLOW - Third party Comapny
*/

// TPM respond - ticket
export const tpmRespondToT2Maintenance = createThunk(
  "internal_maintenance/t2/t2_3pm_respond_ticket",
  "/internal_maintenance/action_by_t2_3PM"
);

// TPM Assign Technician
export const tpmAssignT2Technician = createThunk(
  "internal_maintenance/t2/assign_t2_third_party_technician",
  "/internal_maintenance/assign_t2_third_party_technician"
);

// 3PTech Respond to ticket
export const t2tpTechnicianTakeAction = createThunk(
  "internal_maintenance/t2/t2_3Ptechnician_respond_ticket",
  "/internal_maintenance/t2_party_technician_take_action"
);

// 3PTechnician respond - On the Way
export const t2tptMarkTechnicianOnTheWay = createThunk(
  "internal_maintenance/t2/t2_party_technician_on_the_way",
  "/internal_maintenance/t2_party_technician_on_the_way"
);

// 3PTechnician Respond - Check In
export const t2tpTechnicianCheckedIn = createThunk(
  "internal_maintenance/t2/t2_party_technician_checked_in",
  "/internal_maintenance/t2_party_technician_checked_in"
);

// 3PTechnician Respond - Started Work.
export const t2tpTechnicianStartWork = createThunk(
  "internal_maintenance/t2/t2_party_technician_started_work",
  "/internal_maintenance/t2_party_technician_started_work"
);

// Started the WORK ( MT - 3Party )
export const _t2tpTechnicianStartWork = createAsyncThunk(
  "internal_maintenance/t2/t2_party_technician_started_work",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const formData = new FormData();
      Object.keys(payload).map((key) => {
        if (key === "document") {
          payload[key].forEach((item) => {
            if (PLATFORM !== "web") {
              formData.append(key, {
                name: item.name,
                type: item.type,
                uri:
                  PLATFORM === "android"
                    ? item.uri
                    : item.uri.replace("file://", ""),
              });
            } else {
              formData.append(key, item.file, item.name);
            }
          });
        } else {
          if (key === "description") {
            // stringifying array of string
            formData.append(key, JSON.stringify(payload[key]));
          } else {
            formData.append(key, payload[key]);
          }
        }
      });
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("user_token")}`,
      };
      if (PLATFORM !== "web") {
        headers["Content-Type"] = "multipart/form-data";
      }
      dispatch(setShowUploadProgress(true));
      const config = {
        onUploadProgress: ({ loaded, total }) => {
          const progress = (loaded / total) * 100;
          dispatch(setUploadProgress(progress));
        },
        method: "POST",
        url: "/internal_maintenance/t2_party_technician_started_work",
        headers,
        transformRequest: (data, headers) => {
          return formData;
        },
        data: formData,
        responseType: "json",
      };

      // const data = await response.json();

      const { data } = await axiosInstance.request(config);

      dispatch(setLoading(false));
      if (data.success) {
        await dispatch(
          fetchInternalMaintenanceById({
            maintenance_id: payload.maintenance_id,
          })
        );
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

// 3PTechnician Respond - Mark Job Done.
export const t2tpTechnicianMarkJobComplete1 = createThunk(
  "internal_maintenance/t2/t2_technician_completed_the_work",
  "/internal_maintenance/t2_technician_completed_the_work"
);

// Mark Job Done ( MT - 3Party )
export const t2tpTechnicianMarkJobComplete = createAsyncThunk(
  "internal_maintenance/t2/t2_technician_completed_the_work",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const formData = new FormData();
      Object.keys(payload).map((key) => {
        if (key === "document") {
          payload[key].forEach((item) => {
            if (PLATFORM !== "web") {
              formData.append(key, {
                name: item.name,
                type: item.type,
                uri:
                  PLATFORM === "android"
                    ? item.uri
                    : item.uri.replace("file://", ""),
              });
            } else {
              formData.append(key, item.file, item.name);
            }
          });
        } else {
          if (key === "description") {
            // stringifying array of string
            formData.append(key, JSON.stringify(payload[key]));
          } else {
            formData.append(key, payload[key]);
          }
        }
      });
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("user_token")}`,
      };
      if (PLATFORM !== "web") {
        headers["Content-Type"] = "multipart/form-data";
      }
      dispatch(setShowUploadProgress(true));
      const config = {
        onUploadProgress: ({ loaded, total }) => {
          const progress = (loaded / total) * 100;
          dispatch(setUploadProgress(progress));
        },
        method: "POST",
        url: "/internal_maintenance/t2_technician_completed_the_work",
        headers,
        transformRequest: (data, headers) => {
          return formData;
        },
        data: formData,
        responseType: "json",
      };

      // const data = await response.json();

      const { data } = await axiosInstance.request(config);

      dispatch(setLoading(false));
      if (data.success) {
        await dispatch(
          fetchInternalMaintenanceById({
            maintenance_id: payload.maintenance_id,
          })
        );
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

export const internalMaintenanceSlice = createSlice({
  name: "internalMaintenance",
  initialState,
  reducers: {
    clearInternalMaintenance: (state, action) => {
      state.maintenance = null;
    },
  },
  extraReducers: {
    [fetchInternalMaintenanceLocations.fulfilled]: (state, action) => {
      state.maintenanceLocations = action.payload;
    },
    [fetchInternalMaintenanceCategories.fulfilled]: (state, action) => {
      state.maintenanceCategories = action.payload;
    },
    [fetchInternalMaintenanceSubCategories.fulfilled]: (state, action) => {
      state.maintenanceSubCategories = action.payload;
    },
    [fetchInternalMaintenanceInnerCategories.fulfilled]: (state, action) => {
      state.maintenanceInnerCategories = action.payload;
    },
    [fetchInternalMaintenanceById.fulfilled]: (state, action) => {
      state.maintenance = action.payload;
    },
    [fetchAllNewInternalMaintenance.fulfilled]: (state, action) => {
      state.maintenanceList = action.payload;
    },
    [fetchAllOngoingInternalMaintenance.fulfilled]: (state, action) => {
      state.maintenanceList = action.payload;
    },

    [fetchAllSparePartsInternalMaintenance.fulfilled]: (state, action) => {
      state.maintenanceSpareList = action.payload;
    },

    [fetchAllCompletedInternalMaintenance.fulfilled]: (state, action) => {
      state.maintenanceList = action.payload;
    },

    [fetchAllRejectedInternalMaintenance.fulfilled]: (state, action) => {
      state.maintenanceList = action.payload;
    },

    [fetchAllClosedInternalMaintenance.fulfilled]: (state, action) => {
      state.maintenanceList = action.payload;
    },
  },
});

export const { clearInternalMaintenance } = internalMaintenanceSlice.actions;

export const getInternalMaintenanceList = (state) =>
  state.internalMaintenance.maintenanceList;

export const getInternalMaintenanceSpareList = (state) =>
  state.internalMaintenance.maintenanceSpareList;

export const getInternalMaintenance = (state) =>
  state.internalMaintenance.maintenance;
export const getInternalMaintenanceLocations = (state) =>
  state.internalMaintenance.maintenanceLocations;
export const getInternalMaintenanceCategories = (state) =>
  state.internalMaintenance.maintenanceCategories;
export const getInternalMaintenanceSubCategories = (state) =>
  state.internalMaintenance.maintenanceSubCategories;
export const getInternalMaintenanceInnerCategories = (state) =>
  state.internalMaintenance.maintenanceInnerCategories;

export default internalMaintenanceSlice.reducer;
