/**
 *
 */

import React, { useState, useEffect, useRef } from "react";

/* Import configuration starts */
import GLOBAL from "../../../../setup/constants/global";
import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { addDoc, initialValues, removeDoc, validations } from "./helper";
import { useParams } from "react-router-dom";
import moment from "moment";
/* Import configuration ends */

/* Import redux slices component starts */
import { setLoading } from "../../../../setup/store/slices/unpersistedSlice";
import {
  fetchPropertyActiveTeamManagerMembers,
  fetchPropertyActiveTeamMembers,
  fetchPropertyDetailByID,
  getActiveManagerMembers,
  getActiveMembers,
  getPropertyDetail,
} from "../../../../setup/store/slices/propertySlice";
import { addRoutineTask } from "../../../../setup/store/slices/routineSlice";
import {
  showErrorAlert,
  showSuccessAlert,
} from "../../../../setup/store/slices/globalAlertSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import CustomTextInput from "../../../../components/ui/input/CustomTextInput/CustomTextInput";
import CustomTextArea from "../../../../components/ui/input/CustomTextArea/CustomTextArea";
import CustomButton from "../../../../components/ui/button/CustomButton/CustomButton";
import TextButton from "../../../../components/ui/button/TextButton/TextButton";
import CustomRadioInput from "../../../ui/input/CustomRadioInput/CustomRadioInput";
import CustomSelectInput from "../../../ui/input/CustomSelectInput/CustomSelectInput";
import CustomFileInput from "../../../ui/input/CustomFileInput/CustomFileInput";
import CustomTimeMinuteSelect from "../../../ui/input/CustomTimeSelect/CustomTimeMinuteSelect";
import CustomDateSelect from "../../../ui/input/CustomDateSelect/CustomDateSelect";
import CustomDateInput from "../../../ui/input/CustomDateInput/CustomDateInput";
/* Import local pages and component ends */

// Styles
import "./style.scss";

/* Component starts */
const AddTask = (props) => {
  /* Props destructuring starts */
  const { onSuccess, onCancel } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [initialFormValues, setInitialFormValues] = useState(initialValues);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(null);

  const [firstRequestDate, setFirstRequestDate] = useState(new Date());

  const [member, setMember] = useState("Team");
  const [teamMember, setTeamMember] = useState();

  const [selectFrequency, setSelectFrequency] = useState();
  const [selectedWeekDay, setSelectedWeekDay] = useState();

  const [docList, setDocList] = useState([]);

  const [hours, setHours] = useState("00");
  const [minutes, setMinutes] = useState("00");

  const [date, setDate] = useState(new Date());
  const [error, setError] = useState("");
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const dispatch = useDispatch();
  const formRef = useRef();
  const { propertyId } = useParams();
  const getActiveMembersList = useSelector(getActiveMembers);
  const getActiveManagersList = useSelector(getActiveManagerMembers);
  const propertyDetail = useSelector(getPropertyDetail);
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */

  const frequencyList = [
    { id: 1, frequency: "Daily" },
    { id: 2, frequency: "Weekly" },
    { id: 3, frequency: "Monthly" },
    { id: 4, frequency: "Quarterly" },
    { id: 5, frequency: "Semi Annual" },
    { id: 6, frequency: "Annual" },
  ];

  const selectWeekDay = [
    { id: 0, name: "Sunday" },
    { id: 1, name: "Monday" },
    { id: 2, name: "Tuesday" },
    { id: 3, name: "Wednesday" },
    { id: 4, name: "Thursday" },
    { id: 5, name: "Friday" },
    { id: 6, name: "Saturday" },
  ];

  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */

  // Form validations
  const formValidation = (values) => {
    return validations(values);
  };

  const onSubmit = async (values) => {
    const payload = {
      property_id: propertyId,
      type: member,
      assigned: teamMember?._id,
      subject: values.subject,
      description: values.description,
      startdate: moment(startDate)?.format("MM-DD-YYYY"),
      enddate: moment(endDate)?.format("MM-DD-YYYY"),
      frequency: selectFrequency?.frequency,
      assigned_role: teamMember?.role_id?._id,
      asset_number: values.asset_number,
      asset_location: values.asset_location,
    };

    const document = [];

    docList.forEach((item, index) => {
      if (values[`document_${item}`]) {
        document.push(values[`document_${item}`]);
      }
    });
    payload["document"] = document;

    if (selectFrequency?.frequency === "Daily") {
      payload["daily_time"] = `${hours}:${minutes}`;
    }

    if (selectFrequency?.frequency === "Weekly") {
      payload["weekly_days"] = selectedWeekDay?.id;
      payload["daily_time"] = `${hours}:${minutes}`;
    }

    if (selectFrequency?.frequency === "Monthly") {
      payload["monthly_date"] = date;
      payload["daily_time"] = `${hours}:${minutes}`;
    }

    if (selectFrequency?.frequency === "Quarterly") {
      payload["firstRequest_date"] = firstRequestDate;
      payload["daily_time"] = `${hours}:${minutes}`;
    }

    if (selectFrequency?.frequency === "Semi Annual") {
      payload["firstRequest_date"] = firstRequestDate;
      payload["daily_time"] = `${hours}:${minutes}`;
    }

    if (selectFrequency?.frequency === "Annual") {
      payload["firstRequest_date"] = firstRequestDate;
      payload["daily_time"] = `${hours}:${minutes}`;
    }

    console.log(payload, "[TASK-PAY]");

    // Backend response. Try, Catch
    try {
      const result = await dispatch(addRoutineTask(payload));

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          dispatch(
            showErrorAlert({
              title: "Error!",
              description: result.payload,
            })
          );
          break;

        case GLOBAL.FULFILLED:
          dispatch(
            showSuccessAlert({
              title: "Success!",
              description: result.payload.message,
            })
          );
          onSuccess();
          break;

        default:
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };

  /* Component function definition ends */

  /* Component useEffect starts */
  useEffect(() => {
    (async () => {
      try {
        await dispatch(setLoading(true));
        // await dispatch(fetchPropertyDetailByID({ property_id: propertyId }));
        if (member === "Team") {
          await dispatch(
            fetchPropertyActiveTeamMembers({ property_id: propertyId })
          );
        } else {
          await dispatch(
            fetchPropertyActiveTeamManagerMembers({ property_id: propertyId })
          );
        }
        await dispatch(fetchPropertyDetailByID(propertyId));
        await dispatch(setLoading(false));
      } catch (e) {}
    })();
  }, [propertyId, member]);

  useEffect(() => {
    // Get the current date
    const currentDate = new Date();

    // Set the end date to the next day
    const nextDay = new Date(currentDate);
    nextDay.setDate(currentDate.getDate() + 1);

    // Update the state with the next day
    setEndDate(nextDay);
  }, []);

  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="add-task-module-wrapper">
      <h2 className="heading text-center font_xxl mb-5">
        Internal Routine Form
      </h2>

      <div className="add-new-form">
        <Formik
          onSubmit={onSubmit}
          initialValues={initialFormValues}
          validate={formValidation}
          validateOnBlur={true}
          enableReinitialize={true}
          innerRef={formRef}
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
            setFieldValue,
            setFieldTouched,
          }) => (
            <form onSubmit={handleSubmit}>
              <div className="form-wrapper">
                {/* Creating new Routine Task */}
                <>
                  {/* Radio select the Voilator */}
                  <div className="form-group form-group-full w_100">
                    <p className="font_m font_grey w_100">
                      Assign this request to team member or another party ?
                    </p>
                    <CustomRadioInput
                      label="Team Member"
                      onSelecting={() => {
                        setMember("Team");
                      }}
                      isSelected={member === "Team"}
                    />

                    <CustomRadioInput
                      label="Another Party"
                      onSelecting={() => {
                        setMember("Another Party");
                      }}
                      isSelected={member === "Another Party"}
                    />
                  </div>

                  {member === "Team" ? (
                    <>
                      <div className="form-group">
                        <CustomSelectInput
                          name="team-member"
                          label="Select Team Members"
                          handleBlur={handleBlur}
                          handleChange={handleChange}
                          valueExtractor={(item) =>
                            `${item?.first_name} ${item?.last_name} - ( ${item?.role_id?.name} ) - ${item?.business_name}`
                          }
                          setValue={setTeamMember}
                          options={getActiveMembersList}
                          value={teamMember}
                          formikState={false}
                          emptyOption={true}
                        />
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="form-group">
                        <CustomSelectInput
                          name="manager"
                          label="Select Manager"
                          handleBlur={handleBlur}
                          handleChange={handleChange}
                          valueExtractor={(item) =>
                            `${item?.first_name} ${item?.last_name} - ( ${item?.role_id?.name} ) - ${item?.business_name}`
                          }
                          setValue={setTeamMember}
                          options={getActiveManagersList}
                          value={teamMember}
                          formikState={false}
                          emptyOption={true}
                        />
                      </div>
                    </>
                  )}

                  {/* Input for Subject */}
                  <div className="form-group">
                    <CustomTextInput
                      name="subject"
                      label="Enter Subject"
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      touched={touched.subject}
                      errors={errors.subject}
                      value={values.subject}
                    />
                  </div>

                  {/* Text Area for Description */}
                  <div className="form-group form-group-full">
                    <CustomTextArea
                      name="description"
                      label="Enter Description"
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      value={values.description}
                      errors={errors.description}
                      touched={touched.description}
                      required={true}
                    />
                  </div>

                  {/* Input for Start Date */}
                  <div className="form-group">
                    <CustomDateInput
                      name="start_date"
                      label="Start Date"
                      setFieldValue={setFieldValue}
                      setFieldTouched={setFieldTouched}
                      value={startDate}
                      customOnchange={(date) => setStartDate(date)}
                      canSetOffset={true}
                      canSetTimezone={true}
                      offset={propertyDetail?.offset}
                      timeZone={propertyDetail?.time_zone}
                    />
                  </div>

                  {/* Input for End Date */}
                  <div className="form-group">
                    <CustomDateInput
                      label="End Date"
                      setFieldValue={setFieldValue}
                      setFieldTouched={setFieldTouched}
                      value={endDate}
                      canSetOffset={true}
                      canSetTimezone={true}
                      offset={propertyDetail?.offset}
                      timeZone={propertyDetail?.time_zone}
                      minDate={true}
                      // minDateValue={(() => {
                      //   var day = new Date(startDate);
                      //   var nextDay = new Date(day);
                      //   nextDay.setDate(day.getDate() + 1);
                      //   return nextDay;
                      // })()}
                      customOnchange={(date) => setEndDate(date)}
                      required={true}
                    />
                  </div>

                  <div className="form-group">
                    <CustomSelectInput
                      name="frequency"
                      label="Select Frequency"
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                      valueExtractor={(item) => item?.frequency}
                      setValue={setSelectFrequency}
                      options={frequencyList}
                      value={selectFrequency}
                      formikState={false}
                      emptyOption={true}
                    />
                  </div>

                  {selectFrequency?.frequency === "Daily" && (
                    <div className="form-group daily-time">
                      <div className="time-input">
                        <CustomTimeMinuteSelect
                          label="Select Time Of Day"
                          hours={hours}
                          setHours={setHours}
                          minutes={minutes}
                          setMinutes={setMinutes}
                          handleChange={handleChange}
                          timezone={propertyDetail?.time_zone}
                        />
                      </div>
                    </div>
                  )}

                  {selectFrequency?.frequency === "Weekly" && (
                    <>
                      <div style={{ flex: "1" }}>
                        <CustomSelectInput
                          name="weekday"
                          label="Select Day Of Week"
                          handleBlur={handleBlur}
                          handleChange={handleChange}
                          valueExtractor={(item) => item?.name}
                          setValue={setSelectedWeekDay}
                          options={selectWeekDay}
                          value={selectedWeekDay}
                          formikState={false}
                          emptyOption={true}
                          required={true}
                        />
                      </div>

                      <div className="form-group daily-time">
                        <div className="time-input">
                          <CustomTimeMinuteSelect
                            label="Select Time Of Day"
                            hours={hours}
                            setHours={setHours}
                            minutes={minutes}
                            setMinutes={setMinutes}
                            handleChange={handleChange}
                            timezone={propertyDetail?.time_zone}
                          />
                        </div>
                      </div>
                    </>
                  )}

                  {selectFrequency?.frequency === "Monthly" && (
                    <>
                      <div className="form-group">
                        {/* New Date Number Select Option */}
                        <div className="time-input">
                          <CustomDateSelect
                            label="Select Day Of Month"
                            date={date}
                            setDate={setDate}
                            error={error}
                            setError={setError}
                            handleChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="form-group daily-time">
                        <div className="time-input">
                          <CustomTimeMinuteSelect
                            label="Select Time Of Day"
                            hours={hours}
                            setHours={setHours}
                            minutes={minutes}
                            setMinutes={setMinutes}
                            handleChange={handleChange}
                            timezone={propertyDetail?.time_zone}
                          />
                        </div>
                      </div>
                    </>
                  )}

                  {selectFrequency?.frequency === "Quarterly" && (
                    <>
                      {/* Input for Start Date */}
                      <div className="form-group">
                        <CustomDateInput
                          name="start_date"
                          label="First Request Date"
                          setFieldValue={setFieldValue}
                          setFieldTouched={setFieldTouched}
                          value={firstRequestDate}
                          customOnchange={(date) => setFirstRequestDate(date)}
                          canSetOffset={true}
                          canSetTimezone={true}
                          offset={propertyDetail?.offset}
                          timeZone={propertyDetail?.time_zone}
                        />
                      </div>
                      <div className="form-group daily-time">
                        <div className="time-input">
                          <CustomTimeMinuteSelect
                            label="Select Time Of Day"
                            hours={hours}
                            setHours={setHours}
                            minutes={minutes}
                            setMinutes={setMinutes}
                            handleChange={handleChange}
                            timezone={propertyDetail?.time_zone}
                          />
                        </div>
                      </div>
                    </>
                  )}

                  {selectFrequency?.frequency === "Semi Annual" && (
                    <>
                      {/* Input for Start Date */}
                      <div className="form-group">
                        <CustomDateInput
                          name="start_date"
                          label="First Request Date"
                          setFieldValue={setFieldValue}
                          setFieldTouched={setFieldTouched}
                          value={firstRequestDate}
                          customOnchange={(date) => setFirstRequestDate(date)}
                          canSetOffset={true}
                          canSetTimezone={true}
                          offset={propertyDetail?.offset}
                          timeZone={propertyDetail?.time_zone}
                        />
                      </div>
                      <div className="form-group daily-time">
                        <div className="time-input">
                          <CustomTimeMinuteSelect
                            label="Select Time Of Day"
                            hours={hours}
                            setHours={setHours}
                            minutes={minutes}
                            setMinutes={setMinutes}
                            handleChange={handleChange}
                            timezone={propertyDetail?.time_zone}
                          />
                        </div>
                      </div>
                    </>
                  )}

                  {selectFrequency?.frequency === "Annual" && (
                    <>
                      {/* Input for Start Date */}
                      <div className="form-group">
                        <CustomDateInput
                          name="start_date"
                          label="First Request Date"
                          setFieldValue={setFieldValue}
                          setFieldTouched={setFieldTouched}
                          value={firstRequestDate}
                          customOnchange={(date) => setFirstRequestDate(date)}
                          canSetOffset={true}
                          canSetTimezone={true}
                          offset={propertyDetail?.offset}
                          timeZone={propertyDetail?.time_zone}
                        />
                      </div>
                      <div className="form-group daily-time">
                        <div className="time-input">
                          <CustomTimeMinuteSelect
                            label="Select Time Of Day"
                            hours={hours}
                            setHours={setHours}
                            minutes={minutes}
                            setMinutes={setMinutes}
                            handleChange={handleChange}
                            timezone={propertyDetail?.time_zone}
                          />
                        </div>
                      </div>
                    </>
                  )}

                  {/* Input for Asset Number */}
                  <div className="form-group">
                    <CustomTextInput
                      name="asset_number"
                      label="Enter Asset Number"
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      touched={touched.asset_number}
                      errors={errors.asset_number}
                      value={values.asset_number}
                      required={false}
                    />
                  </div>

                  {/* Input for Asset Location */}
                  <div className="form-group">
                    <CustomTextInput
                      name="asset_location"
                      label="Enter Asset Location"
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      touched={touched.asset_location}
                      errors={errors.asset_location}
                      value={values.asset_location}
                      required={false}
                    />
                  </div>

                  <div className="doc-list-wrapper">
                    <h3 className="font_m fw_6">Photos and Videos</h3>

                    <div className="doc-list">
                      {docList?.length === 0 && (
                        <h3 className="text-center font_m font_yellow fw_6 no-attachments">
                          No photos or videos attached!
                        </h3>
                      )}

                      {docList?.map((item, index) => (
                        <div className="form-group doc" key={index}>
                          <div className="file-input-wrapper">
                            <CustomFileInput
                              name={`document_${item}`}
                              label="Document"
                              setFieldValue={setFieldValue}
                              setFieldTouched={setFieldTouched}
                              formikState={true}
                              touched={touched[`document_${item}`]}
                              errors={errors[`document_${item}`]}
                              value={values[`document_${item}`]}
                              required={true}
                              acceptable={true}
                              acceptType="image/*,video/*"
                            />
                          </div>

                          {docList?.length > 1 && (
                            <div className="remove-btn-wrapper">
                              <TextButton
                                className="button font_m fw_6"
                                variant="red"
                                onClick={() => {
                                  removeDoc(
                                    formRef,
                                    docList,
                                    setInitialFormValues,
                                    setDocList,
                                    index
                                  );
                                }}
                              >
                                Remove
                              </TextButton>
                            </div>
                          )}
                        </div>
                      ))}
                    </div>

                    <div className="doc_action_btn-wrapper add-btn-wrapper">
                      {(docList?.length === 0 ||
                        values[`document_${docList[docList.length - 1]}`]) && (
                        <CustomButton
                          className="font_m fw_6 add-btn"
                          variant="outline"
                          size="s"
                          onClick={() => {
                            addDoc(
                              formRef,
                              docList,
                              setInitialFormValues,
                              setDocList
                            );
                          }}
                        >
                          + Add
                        </CustomButton>
                      )}
                    </div>
                  </div>
                </>
              </div>

              <div className="button-wrapper text-center">
                <>
                  <CustomButton type="submit" size="l">
                    Create
                  </CustomButton>
                  <TextButton
                    type="button"
                    onClick={onCancel}
                    className="font_m fw_6"
                  >
                    Cancel
                  </TextButton>
                </>
              </div>
            </form>
          )}
        </Formik>
      </div>
    </div>
  );
};
/* Component Ends */
export default AddTask;
