/**
 *
 */

import React, { useState } from "react";

/* Import configuration starts */
import { useSelector } from "react-redux";
import moment from "moment/moment";

/* Import configuration ends */

/* Import redux slices component starts */
import { getUserTheme } from "../../../../../../setup/store/slices/authSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
import { Modal } from "react-bootstrap";
/* Import react bootstrap component ends */

/* Import image and SVG starts */
import {
  EyeFilledBlue,
  XGreen,
} from "../../../../../../setup/constants/images";
/* Import image and SVG ends */

/* Import local pages and component starts */
import IconButton from "../../../../../ui/button/IconButton/IconButton";
import FileViewerModal from "../../../../../ui/modal/FileViewerModal/FileViewerModal";
/* Import local pages and component ends */

import "./style.scss";

/* Component starts */
const InvoiceDetailModal = (props) => {
  /* Props destructuring starts */
  const { showModal, detail, size = "lg", onClose, currency } = props;
  console.log(detail, "?DET");
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [file, setFile] = useState(null);
  const [showFileViewer, setShowFileViewer] = useState(false);
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const userTheme = useSelector(getUserTheme);
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  // let detail = detailData?.item;
  const paidAmount =
    detail?.total_amount?.toLocaleString("en-US") ||
    detail?.cheque_amount?.toLocaleString("en-US");
  const paidVia =
    detail?.payed_via ||
    detail?.payment_details?.payment_method ||
    detail?.payment_method;

  const USER_DATA = localStorage.getItem("user_data")
    ? JSON.parse(localStorage.getItem("user_data"))
    : null;
  const DATE_FORMAT = USER_DATA ? USER_DATA?.date_format : "";

  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  // On viewing file
  const viewFile = (file) => {
    onClose();
    setFile(file || file[0]);
    setShowFileViewer(true);
  };
  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <>
      <Modal
        size={size}
        centered
        show={showModal}
        className="detail-view-modal-wrapper"
      >
        <Modal.Body
          //className="custom-modal-body"
          className={`custom-modal-body ${
            userTheme === "dark" ? "bg_darkTheme" : "bg_lightTheme"
          }`}
        >
          {/* Header */}
          <div className="close-button-wrapper">
            <IconButton onClick={onClose} className="close-button">
              <img className="h_100" src={XGreen} alt="Close" />
            </IconButton>

            <p
              className={`fw_5 font_m mb-2 ${
                userTheme !== "dark" && "font_blue"
              } font_white text-center`}
            >
              {detail?.status === "refunded" ? "Refunded" : "Payment"}{" "}
              Transaction Details
            </p>
          </div>

          {/* Body */}

          {(paidVia === "Cash" || paidVia === "cash") && (
            <div className="details-content">
              <div className="right">
                <div
                  //className="wrapper bg_grey"
                  className={`wrapper ${
                    userTheme === "dark" ? "bg_grey" : "bg_lightThemeDetail"
                  }`}
                >
                  {/* Payment Amount */}

                  {/* <div className="custom_row">
                    <p className="key font_grey font_s">Paid Amount</p>
                    <p className="val font_s font_blue">{paidAmount}</p>
                  </div> */}

                  {/* Payment Via */}

                  <div className="custom_row">
                    <p className="key font_grey font_s">
                      {" "}
                      {detail?.status === "refunded" ? "Refunded" : "Paid"} Via
                    </p>
                    <p
                      className={`val font_s ${
                        userTheme === "light" || userTheme === "automatic"
                          ? "font_lightTheme"
                          : "font_white"
                      } `}
                    >
                      {paidVia?.charAt(0).toUpperCase() + paidVia?.slice(1)}
                    </p>
                  </div>

                  {/* Receipt Number */}

                  <div className="custom_row">
                    <p className="key font_grey font_s">Transaction ID</p>
                    <p
                      className={`val font_s ${
                        userTheme === "light" || userTheme === "automatic"
                          ? "font_lightTheme"
                          : "font_white"
                      } `}
                    >
                      {detail?.transaction_id !== undefined
                        ? detail?.transaction_id
                        : ""}
                    </p>
                  </div>

                  {/* Receipt Number */}

                  <div className="custom_row">
                    <p className="key font_grey font_s">Receipt Number</p>
                    <p
                      className={`val font_s ${
                        userTheme === "light" || userTheme === "automatic"
                          ? "font_lightTheme"
                          : "font_white"
                      } `}
                    >
                      {detail?.receipt_number !== undefined
                        ? detail?.receipt_number
                        : ""}
                    </p>
                  </div>

                  {/* Receipt Amount */}

                  <div className="custom_row">
                    <p className="key font_grey font_s">Receipt Amount</p>
                    <p className="val font_s font_blue">
                      {paidAmount || detail?.payment_amount}{" "}
                      {detail?.currency || currency}
                    </p>
                  </div>

                  {/* Receipt Date */}

                  <div className="custom_row">
                    <p className="key font_grey font_s">Receipt Date</p>
                    <p
                      className={`val font_s ${
                        userTheme === "light" || userTheme === "automatic"
                          ? "font_lightTheme"
                          : "font_white"
                      } `}
                    >
                      {moment(detail?.receipt_date).format(DATE_FORMAT)}
                    </p>
                  </div>

                  {/* Remarks */}

                  <div className="custom_row">
                    <p className="key font_grey font_s">Remarks</p>
                    <p
                      className={`val font_s ${
                        userTheme === "light" || userTheme === "automatic"
                          ? "font_lightTheme"
                          : "font_white"
                      } `}
                    >
                      {detail?.remarks !== undefined ? detail?.remarks : ""}
                    </p>
                  </div>

                  {/* Transanction Date */}

                  <div className="custom_row">
                    <p className="key font_grey font_s">
                      {detail?.status === "refunded" ? "Refunded" : "Received"}{" "}
                      Date
                    </p>
                    <p
                      className={`val font_s ${
                        userTheme === "light" || userTheme === "automatic"
                          ? "font_lightTheme"
                          : "font_white"
                      } `}
                    >
                      {moment(detail?.createdAt).format(DATE_FORMAT)}
                    </p>
                  </div>

                  {/* Received By */}

                  {detail?.status !== "refunded" && (
                    <div className="custom_row">
                      <p className="key font_grey font_s">Received By</p>
                      <p className="val font_s font_blue">
                        {detail?.received_by?.first_name}{" "}
                        {detail?.received_by?.last_name}(
                        {detail?.received_by_role?.name})
                      </p>
                    </div>
                  )}

                  {detail?.status === "refunded" && (
                    <>
                      <div className="custom_row">
                        <p className="key font_grey font_s">Refunded By</p>
                        <p className="val font_s font_blue">
                          {detail?.refunded_by?.first_name}{" "}
                          {detail?.refunded_by?.last_name}(
                          {detail?.refunded_by_role?.name})
                        </p>
                      </div>

                      {/* <div className="custom_row">
                        <p className="key font_grey font_s">Refunded To</p>
                        <p className="val font_s font_yellow">
                          {detailData?.tenant}
                        </p>
                      </div> */}
                    </>
                  )}

                  {/* Transaction Receipt */}

                  {(detail?.document || detail?.document[0]) && (
                    <div className="custom_row">
                      <p className="key font_grey font_s">
                        Transaction Receipt
                      </p>
                      {/* {detail?.document?.map((doc, idx) => (
                      <>
                        <p className="val font_s" key={idx}>
                          <IconButton
                            onClick={() => viewFile(doc)}
                            className="view"
                          >
                            <img className="h_100" src={EyeFilledBlue} alt="" />
                          </IconButton>
                        </p>
                      </>
                    ))} */}

                      {(detail?.document || detail?.document[0]) && (
                        <>
                          <p className="val font_s">
                            <IconButton
                              onClick={() =>
                                viewFile(
                                  detail?.document[0] || detail?.document
                                )
                              }
                              className="view"
                            >
                              <img
                                className="h_100"
                                src={EyeFilledBlue}
                                alt=""
                              />
                            </IconButton>
                          </p>
                        </>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}

          {(paidVia === "Cheque" || paidVia === "cheque") && (
            <div className="details-content">
              <div className="right">
                <div
                  className={`wrapper ${
                    userTheme === "dark" ? "bg_grey" : "bg_lightThemeDetail"
                  }`}
                >
                  {/* Cheque Amount */}

                  <div className="custom_row">
                    <p className="key font_grey font_s">Cheque Amount</p>
                    <p className="val font_s font_blue">
                      {detail?.total_amount?.toLocaleString("en-US") ||
                        detail?.cheque_amount?.toLocaleString("en-US")}{" "}
                      {currency}
                    </p>
                  </div>

                  {/* Payment Via */}

                  <div className="custom_row">
                    <p className="key font_grey font_s">
                      {detail?.status === "refunded" ? "Refunded" : "Paid"} Via
                    </p>
                    <p
                      className={`val font_s ${
                        userTheme === "light" || userTheme === "automatic"
                          ? "font_lightTheme"
                          : "font_white"
                      } `}
                    >
                      {paidVia?.charAt(0).toUpperCase() + paidVia?.slice(1)}
                    </p>
                  </div>

                  {/* Receipt Number */}

                  <div className="custom_row">
                    <p className="key font_grey font_s">Transaction ID</p>
                    <p
                      className={`val font_s ${
                        userTheme === "light" || userTheme === "automatic"
                          ? "font_lightTheme"
                          : "font_white"
                      } `}
                    >
                      {detail?.transaction_id !== undefined
                        ? detail?.transaction_id
                        : ""}
                    </p>
                  </div>

                  {/* Cheque Number */}

                  <div className="custom_row">
                    <p className="key font_grey font_s">Cheque Number</p>
                    <p
                      className={`val font_s ${
                        userTheme === "light" || userTheme === "automatic"
                          ? "font_lightTheme"
                          : "font_white"
                      } `}
                    >
                      {detail?.cheque_number !== undefined
                        ? detail?.cheque_number
                        : ""}
                    </p>
                  </div>

                  {/* Account Number */}

                  <div className="custom_row">
                    <p className="key font_grey font_s">Account Number</p>
                    <p
                      className={`val font_s ${
                        userTheme === "light" || userTheme === "automatic"
                          ? "font_lightTheme"
                          : "font_white"
                      } `}
                    >
                      {detail?.account_number !== undefined
                        ? detail?.account_number
                        : ""}
                    </p>
                  </div>

                  {/* Issuer Name */}

                  <div className="custom_row">
                    <p className="key font_grey font_s">Issuer Name</p>
                    <p
                      className={`val font_s ${
                        userTheme === "light" || userTheme === "automatic"
                          ? "font_lightTheme"
                          : "font_white"
                      } `}
                    >
                      {detail?.issuer_name !== undefined
                        ? detail?.issuer_name
                        : ""}
                    </p>
                  </div>

                  {/* Bank Name */}

                  <div className="custom_row">
                    <p className="key font_grey font_s">Bank Name</p>
                    <p
                      className={`val font_s ${
                        userTheme === "light" || userTheme === "automatic"
                          ? "font_lightTheme"
                          : "font_white"
                      } `}
                    >
                      {detail?.bank_name !== undefined ? detail?.bank_name : ""}
                    </p>
                  </div>

                  {/* Cheque Date */}

                  <div className="custom_row">
                    <p className="key font_grey font_s">Cheque Date</p>
                    <p
                      className={`val font_s ${
                        userTheme === "light" || userTheme === "automatic"
                          ? "font_lightTheme"
                          : "font_white"
                      } `}
                    >
                      {moment(detail?.cheque_date).format(DATE_FORMAT)}
                    </p>
                  </div>

                  {/* Remarks */}

                  <div className="custom_row">
                    <p className="key font_grey font_s">Remarks</p>
                    <p
                      className={`val font_s ${
                        userTheme === "light" || userTheme === "automatic"
                          ? "font_lightTheme"
                          : "font_white"
                      } `}
                    >
                      {detail?.remarks !== undefined ? detail?.remarks : "-"}
                    </p>
                  </div>

                  {/* Transanction Date */}

                  <div className="custom_row">
                    <p className="key font_grey font_s">
                      {detail?.status === "refunded"
                        ? "Refunded"
                        : "Cheque Received"}{" "}
                      Date
                    </p>
                    <p
                      className={`val font_s ${
                        userTheme === "light" || userTheme === "automatic"
                          ? "font_lightTheme"
                          : "font_white"
                      } `}
                    >
                      {moment(detail?.createdAt).format(DATE_FORMAT)}
                    </p>
                  </div>

                  {/* Received By */}

                  {detail?.status !== "refunded" && detail?.received_by && (
                    <div className="custom_row">
                      <p className="key font_grey font_s">Received By</p>
                      <p className="val font_s font_blue">
                        {detail?.received_by?.first_name}{" "}
                        {detail?.received_by?.last_name}(
                        {detail?.received_by_role?.name})
                      </p>
                    </div>
                  )}

                  {detail?.status === "refunded" && detail?.refunded_by && (
                    <>
                      <div className="custom_row">
                        <p className="key font_grey font_s">Refunded By</p>
                        <p className="val font_s font_blue">
                          {detail?.refunded_by?.first_name}{" "}
                          {detail?.refunded_by?.last_name}(
                          {detail?.refunded_by_role?.name})
                        </p>
                      </div>

                      {/* <div className="custom_row">
                        <p className="key font_grey font_s">Refunded To</p>
                        <p className="val font_s font_yellow">
                          {detailData?.tenant}
                        </p>
                      </div> */}
                    </>
                  )}

                  {/* Transaction Receipt */}

                  {(detail?.document || detail?.document[0]) && (
                    <div className="custom_row">
                      <p className="key font_grey font_s">
                        Transaction Receipt
                      </p>
                      {/* {detail?.document?.map((doc, idx) => (
                        <>
                          <p className="val font_s" key={idx}>
                            <IconButton
                              onClick={() => viewFile(doc)}
                              className="view"
                            >
                              <img
                                className="h_100"
                                src={EyeFilledBlue}
                                alt=""
                              />
                            </IconButton>
                          </p>
                        </>
                      ))} */}

                      {(detail?.document || detail?.document[0]) && (
                        <>
                          <p className="val font_s">
                            <IconButton
                              onClick={() =>
                                viewFile(
                                  detail?.document[0] || detail?.document
                                )
                              }
                              className="view"
                            >
                              <img
                                className="h_100"
                                src={EyeFilledBlue}
                                alt=""
                              />
                            </IconButton>
                          </p>
                        </>
                      )}
                    </div>
                  )}
                  {/* )} */}

                  {/* Cheque Remarks */}

                  {detail?.cheque_reason && (
                    <div className="custom_row">
                      <p className="key font_grey font_s">Cheque Reason</p>
                      <p className="val font_s font_red">
                        {detail?.cheque_reason}
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}

          {(paidVia === "Online" || paidVia === "online") && (
            <div className="details-content">
              <div className="right">
                <div
                  //className="wrapper bg_grey"
                  className={`wrapper ${
                    userTheme === "dark" ? "bg_grey" : "bg_lightThemeDetail"
                  }`}
                >
                  {/* Type*/}

                  <div className="custom_row">
                    <p className="key font_grey font_s">Type</p>
                    <p className="val font_s font_blue">
                      {detail?.type?.charAt(0).toUpperCase() +
                        detail?.type?.slice(1)}
                    </p>
                  </div>

                  {/* Payment Amount */}

                  <div className="custom_row">
                    <p className="key font_grey font_s">
                      {detail?.status === "refunded" ? "Refunded" : "Paid"}{" "}
                      Amount
                    </p>
                    <p className="val font_s font_blue">
                      {detail?.total_amount?.toLocaleString("en-US")}{" "}
                      {detail?.currency || currency}
                    </p>
                  </div>

                  {/* Payment Via */}

                  <div className="custom_row">
                    <p className="key font_grey font_s">
                      {detail?.status === "refunded" ? "Refunded" : "Paid"} Via
                    </p>
                    <p
                      className={`val font_s ${
                        userTheme === "light" || userTheme === "automatic"
                          ? "font_lightTheme"
                          : "font_white"
                      } `}
                    >
                      {paidVia?.charAt(0).toUpperCase() + paidVia?.slice(1)}
                    </p>
                  </div>

                  {/* Status */}

                  <div className="custom_row">
                    <p className="key font_grey font_s">Status</p>
                    <p className="val font_s font_blue">
                      {detail?.status?.charAt(0).toUpperCase() +
                        detail?.status?.slice(1)}
                    </p>
                  </div>

                  {/* Receipt Number */}

                  <div className="custom_row">
                    <p className="key font_grey font_s">Transaction Id</p>
                    <p
                      className={`val font_s ${
                        userTheme === "light" || userTheme === "automatic"
                          ? "font_lightTheme"
                          : "font_white"
                      } `}
                    >
                      {detail?.transaction_id}
                    </p>
                  </div>

                  {detail?.status === "refunded" && (
                    <div className="custom_row">
                      <p className="key font_grey font_s">Remarks</p>
                      <p
                        className={`val font_s ${
                          userTheme === "light" || userTheme === "automatic"
                            ? "font_lightTheme"
                            : "font_white"
                        } `}
                      >
                        {detail?.refund_reason}
                      </p>
                    </div>
                  )}

                  {detail?.status === "refunded" && (
                    <div className="custom_row">
                      <p className="key font_grey font_s">Payment Status</p>
                      <p
                        className={`val font_s ${
                          detail?.payment_details?.status === "succeeded"
                            ? "font_green"
                            : "font_red"
                        } `}
                      >
                        {detail?.payment_details?.status
                          ?.charAt(0)
                          .toUpperCase() +
                          detail?.payment_details?.status?.slice(1)}
                      </p>
                    </div>
                  )}

                  {/* ASSOSIATED INVOICES */}
                  {detail?.associated_invoices?.length > 0 && (
                    <>
                      <div className="custom_row">
                        <p className="key font_yellow font_m fw_6">
                          Associated Invoices
                        </p>
                      </div>

                      {detail?.associated_invoices?.map((inv, i) => (
                        <div key={i}>
                          <div className="custom_row">
                            <p className="key font_blue font_s fw_6">
                              #{i + 1}
                            </p>
                          </div>
                          {/* Invoice Number */}
                          <div className="custom_row" key={i}>
                            <p className="key font_grey font_s">
                              Invoice Number
                            </p>
                            <p
                              className={`val font_s ${
                                userTheme === "light" ||
                                userTheme === "automatic"
                                  ? "font_lightTheme"
                                  : "font_white"
                              } `}
                            >
                              {inv?.invoice_no}
                            </p>
                          </div>

                          {/* Type */}
                          <div className="custom_row" key={i}>
                            <p className="key font_grey font_s">Type</p>
                            <p
                              className={`val font_s ${
                                userTheme === "light" ||
                                userTheme === "automatic"
                                  ? "font_lightTheme"
                                  : "font_white"
                              } `}
                            >
                              {inv?.type?.charAt(0).toUpperCase() +
                                inv?.type?.slice(1)}
                            </p>
                          </div>

                          {/* Total Amount */}
                          <div className="custom_row" key={i}>
                            <p className="key font_grey font_s">
                              Total Amount (vat/tax)
                            </p>
                            <p className={`val font_s font_blue `}>
                              {`${inv?.total_amount?.toLocaleString("en-US")} ${
                                inv?.currency
                              }`}
                            </p>
                          </div>

                          {/* Invoice Date */}
                          <div className="custom_row" key={i}>
                            <p className="key font_grey font_s">Invoice Date</p>
                            <p
                              className={`val font_s ${
                                userTheme === "light" ||
                                userTheme === "automatic"
                                  ? "font_lightTheme"
                                  : "font_white"
                              } `}
                            >
                              {moment(inv?.invoice_date).format(DATE_FORMAT)}
                            </p>
                          </div>

                          {detail?.associated_invoices?.length >= 1 && <hr />}
                        </div>
                      ))}
                    </>
                  )}
                </div>
              </div>
            </div>
          )}

          {(paidVia === "deposit" || paidVia === "settled") && (
            <div className="details-content">
              <div className="right">
                <div
                  className={`wrapper ${
                    userTheme === "dark" ? "bg_grey" : "bg_lightThemeDetail"
                  }`}
                >
                  {/* Payment Via */}

                  <div className="custom_row">
                    <p className="key font_grey font_s">
                      {detail?.status === "refunded" ? "Refunded" : "Paid"} Via
                    </p>
                    <p
                      className={`val font_s ${
                        userTheme === "light" || userTheme === "automatic"
                          ? "font_lightTheme"
                          : "font_white"
                      } `}
                    >
                      {paidVia?.charAt(0).toUpperCase() + paidVia?.slice(1)}
                    </p>
                  </div>

                  {/* Transaction Number */}

                  <div className="custom_row">
                    <p className="key font_grey font_s">Transaction ID</p>
                    <p
                      className={`val font_s ${
                        userTheme === "light" || userTheme === "automatic"
                          ? "font_lightTheme"
                          : "font_white"
                      } `}
                    >
                      {detail?.transaction_id !== undefined
                        ? detail?.transaction_id
                        : ""}
                    </p>
                  </div>

                  {/* Transaction Date */}

                  <div className="custom_row">
                    <p className="key font_grey font_s">Transaction Date</p>
                    <p
                      className={`val font_s ${
                        userTheme === "light" || userTheme === "automatic"
                          ? "font_lightTheme"
                          : "font_white"
                      } `}
                    >
                      {moment(detail?.createdAt).format(DATE_FORMAT)}
                    </p>
                  </div>

                  {/* Deposit ID */}

                  <div className="custom_row">
                    <p className="key font_grey font_s">Deposit ID</p>
                    <p className="val font_s font_blue">
                      {detail?.deposit_id !== undefined
                        ? detail?.deposit_id
                        : "-"}
                    </p>
                  </div>

                  {/* Receipt Amount */}

                  <div className="custom_row">
                    <p className="key font_grey font_s">Adjusted Amount</p>
                    <p className="val font_s font_blue">
                      {paidAmount} {detail?.currency || currency}
                    </p>
                  </div>

                  {/* Remarks */}

                  <div className="custom_row">
                    <p className="key font_grey font_s">Remarks</p>
                    <p
                      className={`val font_s ${
                        userTheme === "light" || userTheme === "automatic"
                          ? "font_lightTheme"
                          : "font_white"
                      } `}
                    >
                      {detail?.remarks !== undefined ? detail?.remarks : ""}
                    </p>
                  </div>

                  {/* Received By */}

                  {detail?.status !== "refunded" && (
                    <div className="custom_row">
                      <p className="key font_grey font_s">Received By</p>
                      <p className="val font_s font_blue">
                        {detail?.received_by?.first_name}{" "}
                        {detail?.received_by?.last_name}(
                        {detail?.received_by_role?.name})
                      </p>
                    </div>
                  )}

                  {detail?.status === "refunded" && (
                    <>
                      <div className="custom_row">
                        <p className="key font_grey font_s">Refunded By</p>
                        <p className="val font_s font_blue">
                          {detail?.refunded_by?.first_name}{" "}
                          {detail?.refunded_by?.last_name}(
                          {detail?.refunded_by_role?.name})
                        </p>
                      </div>

                      {/* <div className="custom_row">
                        <p className="key font_grey font_s">Refunded To</p>
                        <p className="val font_s font_yellow">
                          {detailData?.tenant}
                        </p>
                      </div> */}
                    </>
                  )}
                </div>
              </div>
            </div>
          )}

          {paidVia === "wire" && (
            <div className="details-content">
              <div className="right">
                <div
                  //className="wrapper bg_grey"
                  className={`wrapper ${
                    userTheme === "dark" ? "bg_grey" : "bg_lightThemeDetail"
                  }`}
                >
                  {/* Payment Amount */}

                  <div className="custom_row">
                    <p className="key font_grey font_s">Sender Name</p>
                    <p className="val font_s font_blue">
                      {detail?.sender_name !== undefined
                        ? detail?.sender_name
                        : "-"}
                    </p>
                  </div>

                  {/* Payment Via */}

                  <div className="custom_row">
                    <p className="key font_grey font_s">
                      {detail?.status === "refunded" ? "Refunded" : "Paid"} Via
                    </p>
                    <p
                      className={`val font_s ${
                        userTheme === "light" || userTheme === "automatic"
                          ? "font_lightTheme"
                          : "font_white"
                      } `}
                    >
                      {paidVia?.charAt(0).toUpperCase() + paidVia?.slice(1)}
                    </p>
                  </div>

                  {/* Receipt Number */}

                  <div className="custom_row">
                    <p className="key font_grey font_s">Transaction ID</p>
                    <p
                      className={`val font_s ${
                        userTheme === "light" || userTheme === "automatic"
                          ? "font_lightTheme"
                          : "font_white"
                      } `}
                    >
                      {detail?.transaction_id !== undefined
                        ? detail?.transaction_id
                        : ""}
                    </p>
                  </div>

                  {/* Receipt Number */}

                  <div className="custom_row">
                    <p className="key font_grey font_s">Reference Number</p>
                    <p
                      className={`val font_s ${
                        userTheme === "light" || userTheme === "automatic"
                          ? "font_lightTheme"
                          : "font_white"
                      } `}
                    >
                      {detail?.reference_no !== undefined
                        ? detail?.reference_no
                        : ""}
                    </p>
                  </div>

                  {/* Receipt Amount */}

                  <div className="custom_row">
                    <p className="key font_grey font_s">Receipt Amount</p>
                    <p className="val font_s font_blue">
                      {paidAmount} {detail?.currency || currency}
                    </p>
                  </div>

                  {/* Remarks */}

                  <div className="custom_row">
                    <p className="key font_grey font_s">Remarks</p>
                    <p
                      className={`val font_s ${
                        userTheme === "light" || userTheme === "automatic"
                          ? "font_lightTheme"
                          : "font_white"
                      } `}
                    >
                      {detail?.remarks !== undefined ? detail?.remarks : ""}
                    </p>
                  </div>

                  {/* Transanction Date */}

                  <div className="custom_row">
                    <p className="key font_grey font_s">Transfer Date</p>
                    <p
                      className={`val font_s ${
                        userTheme === "light" || userTheme === "automatic"
                          ? "font_lightTheme"
                          : "font_white"
                      } `}
                    >
                      {moment(detail?.transfer_date).format(DATE_FORMAT)}
                    </p>
                  </div>

                  {/* Received By */}

                  {detail?.status !== "refunded" && (
                    <div className="custom_row">
                      <p className="key font_grey font_s">Received By</p>
                      <p className="val font_s font_blue">
                        {detail?.received_by?.first_name}{" "}
                        {detail?.received_by?.last_name}(
                        {detail?.received_by_role?.name})
                      </p>
                    </div>
                  )}

                  {detail?.status === "refunded" && (
                    <>
                      <div className="custom_row">
                        <p className="key font_grey font_s">Refunded By</p>
                        <p className="val font_s font_blue">
                          {detail?.refunded_by?.first_name}{" "}
                          {detail?.refunded_by?.last_name}(
                          {detail?.refunded_by_role?.name})
                        </p>
                      </div>

                      {/* <div className="custom_row">
                        <p className="key font_grey font_s">Refunded To</p>
                        <p className="val font_s font_yellow">
                          {detailData?.tenant}
                        </p>
                      </div> */}
                    </>
                  )}
                </div>
              </div>
            </div>
          )}

          {paidVia === "money order" && (
            <div className="details-content">
              <div className="right">
                <div
                  //className="wrapper bg_grey"
                  className={`wrapper ${
                    userTheme === "dark" ? "bg_grey" : "bg_lightThemeDetail"
                  }`}
                >
                  {/* Payment Amount */}

                  <div className="custom_row">
                    <p className="key font_grey font_s">Sender Name</p>
                    <p className="val font_s font_blue">
                      {detail?.sender_name !== undefined
                        ? detail?.sender_name
                        : "-"}
                    </p>
                  </div>

                  {/* Payment Via */}

                  <div className="custom_row">
                    <p className="key font_grey font_s">
                      {detail?.status === "refunded" ? "Refunded" : "Paid"} Via
                    </p>
                    <p
                      className={`val font_s ${
                        userTheme === "light" || userTheme === "automatic"
                          ? "font_lightTheme"
                          : "font_white"
                      } `}
                    >
                      {paidVia?.charAt(0).toUpperCase() + paidVia?.slice(1)}
                    </p>
                  </div>

                  {/* Receipt Number */}

                  <div className="custom_row">
                    <p className="key font_grey font_s">Transaction ID</p>
                    <p
                      className={`val font_s ${
                        userTheme === "light" || userTheme === "automatic"
                          ? "font_lightTheme"
                          : "font_white"
                      } `}
                    >
                      {detail?.transaction_id !== undefined
                        ? detail?.transaction_id
                        : ""}
                    </p>
                  </div>

                  {/* Receipt Number */}

                  <div className="custom_row">
                    <p className="key font_grey font_s">Money Order Number</p>
                    <p
                      className={`val font_s ${
                        userTheme === "light" || userTheme === "automatic"
                          ? "font_lightTheme"
                          : "font_white"
                      } `}
                    >
                      {detail?.money_order_no !== undefined
                        ? detail?.money_order_no
                        : ""}
                    </p>
                  </div>

                  {/* Receipt Amount */}

                  <div className="custom_row">
                    <p className="key font_grey font_s">Receipt Amount</p>
                    <p className="val font_s font_blue">
                      {paidAmount} {detail?.currency || currency}
                    </p>
                  </div>

                  {/* Remarks */}

                  <div className="custom_row">
                    <p className="key font_grey font_s">Remarks</p>
                    <p
                      className={`val font_s ${
                        userTheme === "light" || userTheme === "automatic"
                          ? "font_lightTheme"
                          : "font_white"
                      } `}
                    >
                      {detail?.remarks !== undefined ? detail?.remarks : ""}
                    </p>
                  </div>

                  {/* Transanction Date */}

                  <div className="custom_row">
                    <p className="key font_grey font_s">Money Order Date</p>
                    <p
                      className={`val font_s ${
                        userTheme === "light" || userTheme === "automatic"
                          ? "font_lightTheme"
                          : "font_white"
                      } `}
                    >
                      {moment(detail?.money_order_date).format(DATE_FORMAT)}
                    </p>
                  </div>

                  {/* Received By */}

                  {detail?.status !== "refunded" && (
                    <div className="custom_row">
                      <p className="key font_grey font_s">Received By</p>
                      <p className="val font_s font_blue">
                        {detail?.received_by?.first_name}{" "}
                        {detail?.received_by?.last_name}(
                        {detail?.received_by_role?.name})
                      </p>
                    </div>
                  )}

                  {detail?.status === "refunded" && (
                    <div className="custom_row">
                      <p className="key font_grey font_s">Refunded By</p>
                      <p className="val font_s font_blue">
                        {detail?.refunded_by?.first_name}{" "}
                        {detail?.refunded_by?.last_name}(
                        {detail?.refunded_by_role?.name})
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}

          {paidVia === "cashier cheque" && (
            <div className="details-content">
              <div className="right">
                <div
                  //className="wrapper bg_grey"
                  className={`wrapper ${
                    userTheme === "dark" ? "bg_grey" : "bg_lightThemeDetail"
                  }`}
                >
                  {/* Payment Amount */}

                  <div className="custom_row">
                    <p className="key font_grey font_s">Bank Name</p>
                    <p className="val font_s font_blue">
                      {detail?.bank_name !== undefined
                        ? detail?.bank_name
                        : "-"}
                    </p>
                  </div>

                  {/* Payment Via */}

                  <div className="custom_row">
                    <p className="key font_grey font_s">
                      {detail?.status === "refunded" ? "Refunded" : "Paid"} Via
                    </p>
                    <p
                      className={`val font_s ${
                        userTheme === "light" || userTheme === "automatic"
                          ? "font_lightTheme"
                          : "font_white"
                      } `}
                    >
                      {paidVia?.charAt(0).toUpperCase() + paidVia?.slice(1)}
                    </p>
                  </div>

                  {/* Receipt Number */}

                  <div className="custom_row">
                    <p className="key font_grey font_s">Transaction ID</p>
                    <p
                      className={`val font_s ${
                        userTheme === "light" || userTheme === "automatic"
                          ? "font_lightTheme"
                          : "font_white"
                      } `}
                    >
                      {detail?.transaction_id !== undefined
                        ? detail?.transaction_id
                        : ""}
                    </p>
                  </div>

                  {/* Receipt Number */}

                  <div className="custom_row">
                    <p className="key font_grey font_s">Cheque Number</p>
                    <p
                      className={`val font_s ${
                        userTheme === "light" || userTheme === "automatic"
                          ? "font_lightTheme"
                          : "font_white"
                      } `}
                    >
                      {detail?.cheque_no !== undefined ? detail?.cheque_no : ""}
                    </p>
                  </div>

                  {/* Receipt Amount */}

                  <div className="custom_row">
                    <p className="key font_grey font_s">
                      Casheir's Cheque Amount
                    </p>
                    <p className="val font_s font_blue">
                      {paidAmount} {detail?.currency || currency}
                    </p>
                  </div>

                  {/* Remarks */}

                  <div className="custom_row">
                    <p className="key font_grey font_s">Remarks</p>
                    <p
                      className={`val font_s ${
                        userTheme === "light" || userTheme === "automatic"
                          ? "font_lightTheme"
                          : "font_white"
                      } `}
                    >
                      {detail?.remarks !== undefined ? detail?.remarks : ""}
                    </p>
                  </div>

                  {/* Transanction Date */}

                  <div className="custom_row">
                    <p className="key font_grey font_s">Money Order Date</p>
                    <p
                      className={`val font_s ${
                        userTheme === "light" || userTheme === "automatic"
                          ? "font_lightTheme"
                          : "font_white"
                      } `}
                    >
                      {moment(detail?.cashier_cheque_date).format(DATE_FORMAT)}
                    </p>
                  </div>

                  {/* Received By */}

                  {detail?.status !== "refunded" && (
                    <div className="custom_row">
                      <p className="key font_grey font_s">Received By</p>
                      <p className="val font_s font_blue">
                        {detail?.received_by?.first_name}{" "}
                        {detail?.received_by?.last_name}(
                        {detail?.received_by_role?.name})
                      </p>
                    </div>
                  )}

                  {detail?.status === "refunded" && (
                    <>
                      <div className="custom_row">
                        <p className="key font_grey font_s">Refunded By</p>
                        <p className="val font_s font_blue">
                          {detail?.refunded_by?.first_name}{" "}
                          {detail?.refunded_by?.last_name}(
                          {detail?.refunded_by_role?.name})
                        </p>
                      </div>

                      {/* <div className="custom_row">
                        <p className="key font_grey font_s">Refunded To</p>
                        <p className="val font_s font_yellow">
                          {detailData?.tenant}
                        </p>
                      </div> */}
                    </>
                  )}
                </div>
              </div>
            </div>
          )}

          {paidVia === "point of sale" && (
            <div className="details-content">
              <div className="right">
                <div
                  //className="wrapper bg_grey"
                  className={`wrapper ${
                    userTheme === "dark" ? "bg_grey" : "bg_lightThemeDetail"
                  }`}
                >
                  {/* Payment Amount */}

                  <div className="custom_row">
                    <p className="key font_grey font_s">POS Transaction ID</p>
                    <p className="val font_s font_blue">
                      {detail?.pos_transaction_id !== undefined
                        ? detail?.pos_transaction_id
                        : "-"}
                    </p>
                  </div>

                  {/* Payment Via */}

                  <div className="custom_row">
                    <p className="key font_grey font_s">
                      {detail?.status === "refunded" ? "Refunded" : "Paid"} Via
                    </p>
                    <p
                      className={`val font_s ${
                        userTheme === "light" || userTheme === "automatic"
                          ? "font_lightTheme"
                          : "font_white"
                      } `}
                    >
                      {paidVia?.charAt(0).toUpperCase() + paidVia?.slice(1)}
                    </p>
                  </div>

                  {/* Receipt Number */}

                  <div className="custom_row">
                    <p className="key font_grey font_s">Transaction ID</p>
                    <p
                      className={`val font_s ${
                        userTheme === "light" || userTheme === "automatic"
                          ? "font_lightTheme"
                          : "font_white"
                      } `}
                    >
                      {detail?.transaction_id !== undefined
                        ? detail?.transaction_id
                        : ""}
                    </p>
                  </div>

                  {/* Receipt Amount */}

                  <div className="custom_row">
                    <p className="key font_grey font_s">POS Amount</p>
                    <p className="val font_s font_blue">
                      {paidAmount} {detail?.currency || currency}
                    </p>
                  </div>

                  {/* Remarks */}

                  <div className="custom_row">
                    <p className="key font_grey font_s">POS Status</p>
                    <p
                      className={`val font_s ${
                        userTheme === "light" || userTheme === "automatic"
                          ? "font_lightTheme"
                          : "font_white"
                      } `}
                    >
                      {detail?.status !== undefined
                        ? detail?.status?.charAt(0).toUpperCase() +
                          detail?.status?.slice(1)
                        : ""}
                    </p>
                  </div>

                  {/* Remarks */}

                  <div className="custom_row">
                    <p className="key font_grey font_s">Remarks</p>
                    <p
                      className={`val font_s ${
                        userTheme === "light" || userTheme === "automatic"
                          ? "font_lightTheme"
                          : "font_white"
                      } `}
                    >
                      {detail?.remarks !== undefined ? detail?.remarks : ""}
                    </p>
                  </div>

                  {/* Transanction Date */}

                  <div className="custom_row">
                    <p className="key font_grey font_s">POS Transaction Date</p>
                    <p
                      className={`val font_s ${
                        userTheme === "light" || userTheme === "automatic"
                          ? "font_lightTheme"
                          : "font_white"
                      } `}
                    >
                      {moment(detail?.pos_transaction_date).format(DATE_FORMAT)}
                    </p>
                  </div>

                  {/* Received By */}

                  {detail?.status !== "refunded" && (
                    <div className="custom_row">
                      <p className="key font_grey font_s">Received By</p>
                      <p className="val font_s font_blue">
                        {detail?.received_by?.first_name}{" "}
                        {detail?.received_by?.last_name}(
                        {detail?.received_by_role?.name})
                      </p>
                    </div>
                  )}

                  {detail?.status === "refunded" && (
                    <>
                      <div className="custom_row">
                        <p className="key font_grey font_s">Refunded By</p>
                        <p className="val font_s font_blue">
                          {detail?.refunded_by?.first_name}{" "}
                          {detail?.refunded_by?.last_name}(
                          {detail?.refunded_by_role?.name})
                        </p>
                      </div>

                      {/* <div className="custom_row">
                        <p className="key font_grey font_s">Refunded To</p>
                        <p className="val font_s font_yellow">
                          {detailData?.tenant}
                        </p>
                      </div> */}
                    </>
                  )}
                </div>
              </div>
            </div>
          )}
        </Modal.Body>
      </Modal>

      {/* FILE VEIWER */}
      <FileViewerModal
        show={showFileViewer}
        file={file?.url}
        type={file?.type}
        onClose={() => {
          setShowFileViewer(false);
          setFile(null);
        }}
      />
    </>
  );
};
/* Component ends */

export default InvoiceDetailModal;
