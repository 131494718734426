/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import { useSelector } from "react-redux";
/* Import configuration ends */

/* Import redux slices component starts */
import {
  getIsTPM,
  getIsTPT,
} from "../../../../../setup/store/slices/authSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import SimpleLog from "./SimpleLog";
import QuotationLog from "./QuotationLog";
import TaskLog from "./TaskLog";
import SparePartLog from "./SparePartLog";
import GLOBAL from "../../../../../setup/constants/global";
import MaintenanceFinalReportLog from "./MaintenanceFinalReportLog";
import InspectionReportLog from "./InspectionReportLog";
/* Import local pages and component ends */

/* Component starts */
const TimeLineLists = (props) => {
  /* Props destructuring starts */
  const {
    maintenance_id,
    maintenance,
    logs,
    mgmtModel,
    isT1,
    isT2,
    isT3,
    status,
    property,
    user,
    managers,
    technicians,
    currency,
    tenants,
  } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  // const isTPM = useSelector(getIsTPM);
  // const isTPT = useSelector(getIsTPT);
  const isTPM = localStorage.getItem("user_role") === "Third Party Manager";
  const isTPT = localStorage.getItem("user_role") === "Third Party Technician";
  const requestAssignedToTPM =
    maintenance?.assigned_role?.name === "Third Party Manager" ? true : false;
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  const generateLogType = (item) => {
    const action = item?.action;
    const currentStatus = item?.current_status;
    const logType = item?.log_type;
    const requesterRole = item?.requester_role?.name;

    // Quotaion
    if (
      // action === "Quoatation assigned To PM" ||
      action === "Quoatation assigned To Property Manager" ||
      action === "Quoatation Approved By Facility Manager" ||
      (action === "Quoatation submitted succesfully" &&
        requesterRole === "Owner") ||
      action === "Quoatation submitted succesfully"
    ) {
      return "quotation";
    }

    // // Task
    // if (action === "Maintenance completed by Owner") {
    //   return "simple";
    // }

    // Spare part
    if (action === "Spare parts request assigned To Manager") {
      return "spare_part";
    }

    if (currentStatus === "Approved" && logType === "quoatation")
      return "simple";

    if (
      (logType === "quoatation" && currentStatus === "Order Placed") ||
      (logType === "quoatation" && currentStatus === "Parts Recieved")
    ) {
      return "simple";
    }

    if (currentStatus === "fm_approval_pending") {
      return "simple";
    }

    if (
      currentStatus === "pm_approval_pending" &&
      requesterRole === "Property Manager"
    ) {
      return "simple";
    }

    // if (
    //   // (action === "Maintenance completed by Owner" ||
    //   //   action === "Maintenance accepted by PM") &&
    //   currentStatus === "rating_pending" &&
    //   maintenance?.status === "Completed"
    // ) {
    //   return "simple";
    // }
    if (currentStatus === "inspection_report_added") {
      return "inspection-report-log";
    }

    if (action === "Maintenance completed by Owner") {
      return "final-report";
    }

    if (isT2 && action === "Maintenance Technician starts the work") {
      return "final-report";
    }

    if (isT2 && currentStatus === "waiting_for_fm_approval") {
      return "final-report";
    }

    if (
      isT3 &&
      (action === "Maintenance Technician completed the job" ||
        currentStatus === "waiting_for_pm_approval")
    ) {
      return "final-report";
    }

    return "simple";
  };
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */

  // To get the Company Detail For Rating
  const getCompany = () => {
    let company = maintenance?.info?.find(
      (c) => c.role === GLOBAL.USER_ROLE.FMC || c.role === GLOBAL.USER_ROLE.TPC
    );
    return company;
  };

  // To get the Technician Detail For Rating
  const getTechnician = () => {
    let tech = maintenance?.info?.find(
      (c) => c.role === GLOBAL.USER_ROLE.MT || c.role === GLOBAL.USER_ROLE.TPT
    );
    return tech;
  };
  /* Component function definition ends */

  /* Component useEffect starts */

  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="timeline-list-wrapper">
      {/* Time Line List */}
      {logs?.map((item, index) => (
        <div className="log-wrapper" key={index}>
          {/* Simple Log */}
          {generateLogType(item) === "simple" && (
            <SimpleLog
              isT1={isT1}
              isT2={isT2}
              isT3={isT3}
              status={status}
              maintenance={maintenance}
              item={item}
              takeAction={index === 0}
              maintenance_id={maintenance_id}
              property={property}
              user={user}
              managers={managers}
              technicians={technicians}
              isTPM={isTPM}
              isTPT={isTPT}
              toRateCompany={getCompany()}
              toRateTech={getTechnician()}
              tenants={tenants}
            />
          )}

          {generateLogType(item) === "inspection-report-log" && (
            <InspectionReportLog
              isT1={isT1}
              isT2={isT2}
              isT3={isT3}
              status={status}
              maintenance={maintenance}
              item={item}
              takeAction={index === 0}
              maintenance_id={maintenance_id}
              property={property}
              user={user}
              managers={managers}
              technicians={technicians}
              isTPM={isTPM}
              isTPT={isTPT}
              tenants={tenants}
            />
          )}

          {/* Maintenance Final Report Log */}
          {generateLogType(item) === "final-report" && (
            <MaintenanceFinalReportLog
              isT1={isT1}
              isT2={isT2}
              isT3={isT3}
              requestAssignedToTPM={requestAssignedToTPM}
              status={status}
              maintenance={maintenance}
              item={item}
              takeAction={index === 0}
              maintenance_id={maintenance_id}
              property={property}
              user={user}
              managers={managers}
              technicians={technicians}
              isTPM={isTPM}
              isTPT={isTPT}
              toRateCompany={getCompany()}
              toRateTech={getTechnician()}
              tenants={tenants}
            />
          )}

          {/* Quotation Log */}
          {generateLogType(item) === "quotation" && (
            <QuotationLog
              isT1={isT1}
              isT2={isT2}
              isT3={isT3}
              status={status}
              maintenance={maintenance}
              item={item}
              takeAction={index === 0}
              maintenance_id={maintenance_id}
              property={property}
              user={user}
              currency={currency}
            />
          )}

          {/* Spare Part Log */}
          {generateLogType(item) === "spare_part" && (
            <SparePartLog
              isT1={isT1}
              isT3={isT3}
              status={status}
              maintenance={maintenance}
              item={item}
              takeAction={index === 0}
              maintenance_id={maintenance_id}
              property={property}
              user={user}
            />
          )}
        </div>
      ))}
    </div>
  );
};
/* Component ends */

export default TimeLineLists;
