/**
 *
 */

import React from "react";

/* Import configuration starts */
import { useParams } from "react-router-dom";
/* Import configuration ends */

/* Import redux slices component starts */
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import TimeLineListings from "./sub-component/TimeLineListings";
import DetailCard from "./sub-component/DetailCard";
/* Import local pages and component ends */

import "./style.scss";

/* Component starts */
const RoutineTaskDetail = (props) => {
  /* Props destructuring starts */
  const { property, user, taskDetail, taskTab } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const { tab } = useParams();
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="security-complaint-report-component-wrapper container_sm">
      <h1 className="text-center font_xxl mb-4">
        Routine {tab === "task" ? "Task" : "Request"} Detail
      </h1>

      {/* Details */}
      <DetailCard taskDetail={taskDetail} taskTab={taskTab} user={user} />

      {/* Timeline */}
      <TimeLineListings
        task={taskDetail}
        logs={taskDetail?.logs}
        property={property}
        user={user}
      />
    </div>
  );
};
/* Component ends */

export default RoutineTaskDetail;
