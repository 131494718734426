/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import { useDispatch, useSelector } from "react-redux";
// import { buttonList } from "./helper";

import { useLocation, useNavigate, useParams } from "react-router-dom";
/* Import configuration ends */

/* Import redux slices component starts */
import { setLoading } from "../../../../setup/store/slices/unpersistedSlice";
import {
  fetchAllClosedMaintenance,
  fetchAllCompletedMaintenance,
  fetchAllEscalatedMaintenance,
  fetchAllInsideUnitMaintenance,
  fetchAllNewMaintenance,
  fetchAllOngoingMaintenance,
  fetchAllOutsideUnitMaintenance,
  fetchAllRejectedMaintenance,
  fetchAllSparePartMaintenance,
  fetchMaintenanceListByProperty,
  getMaintenanceEscaletedList,
  getMaintenanceList,
  getMaintenanceSpareList,
} from "../../../../setup/store/slices/maintenanceSlice";
import {
  fetchPropertyDetailByID,
  getPropertyDetail,
} from "../../../../setup/store/slices/propertySlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import GLOBAL from "../../../../setup/constants/global";
import TabButton from "../../../ui/other/TabButton/TabButton";
import NewRequest from "./sub-component/NewRequest";
import OnGoingRequest from "./sub-component/OnGoingRequest";
import SparePartsRequest from "./sub-component/SparePartsRequest";
import CompletedRequest from "./sub-component/CompletedRequest";
import RejectedRequest from "./sub-component/RejectedRequest";
import EscalaltedRequest from "./sub-component/EscalatedRequest";
import MoveInRequest from "./sub-component/MoveInRequest";
import MoveOutRequest from "./sub-component/MoveOutRequest";
import CommonAreaRequest from "./sub-component/CommonAreaRequest";
import UnitsRequest from "./sub-component/UnitsRequest";
import ClosedRequest from "./sub-component/ClosedRequest";
/* Import local pages and component ends */

// Styles
import "./style.scss";

/* Component starts */
const ManageMaintenance = (props) => {
  /* Props destructuring starts */
  const { maintenances } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [buttonLists, setButtonLists] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { propertyId, tab } = useParams();
  const { pathname } = useLocation();
  // Redux Selector
  const maintenanceList = useSelector(getMaintenanceList);
  const maintenanceSpareList = useSelector(getMaintenanceSpareList);
  const maintenanceEscalatedList = useSelector(getMaintenanceEscaletedList);

  const propData = useSelector(getPropertyDetail);

  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  const onTabSelect = (key) => {
    const tabPath = pathname.split("/");
    tabPath.pop();
    navigate(tabPath.join("/") + "/" + key);
    setCurrentPage(1);
    // setCurrentKey(key);
  };
  /* Component function definition ends */

  /* Component useEffect starts */
  useEffect(() => {
    switch (tab) {
      case GLOBAL.MAINTENANCE.NEW:
        dispatch(
          fetchAllNewMaintenance({
            property_id: propertyId,
            page: currentPage,
            limit: 10,
          })
        );
        break;

      case GLOBAL.MAINTENANCE.ONGOING:
        dispatch(
          fetchAllOngoingMaintenance({
            property_id: propertyId,
            page: currentPage,
            limit: 10,
          })
        );
        break;

      case GLOBAL.MAINTENANCE.SPARE_PARTS:
        dispatch(
          fetchAllSparePartMaintenance({
            property_id: propertyId,
            page: currentPage,
            limit: 10,
          })
        );
        break;

      case GLOBAL.MAINTENANCE.COMPLETED:
        dispatch(
          fetchAllCompletedMaintenance({
            property_id: propertyId,
            page: currentPage,
            limit: 10,
          })
        );
        break;

      case GLOBAL.MAINTENANCE.REJECTED:
        dispatch(
          fetchAllRejectedMaintenance({
            property_id: propertyId,
            page: currentPage,
            limit: 10,
          })
        );
        break;

      case GLOBAL.MAINTENANCE.ESCALATED:
        dispatch(
          fetchAllEscalatedMaintenance({
            property_id: propertyId,
            page: currentPage,
            limit: 10,
          })
        );
        break;

      case GLOBAL.MAINTENANCE.MOVE_IN:
        dispatch(
          fetchMaintenanceListByProperty({
            type: tab,
            property_id: propertyId,
            page: currentPage,
          })
        );
        break;

      case GLOBAL.MAINTENANCE.MOVE_OUT:
        dispatch(
          fetchMaintenanceListByProperty({
            type: tab,
            property_id: propertyId,
            page: currentPage,
          })
        );
        break;

      case GLOBAL.MAINTENANCE.UNITS:
        dispatch(
          fetchAllInsideUnitMaintenance({
            property_id: propertyId,
            page: currentPage,
            limit: 10,
          })
        );
        break;

      case GLOBAL.MAINTENANCE.COMMON_AREA:
        dispatch(
          fetchAllOutsideUnitMaintenance({
            property_id: propertyId,
            page: currentPage,
            limit: 10,
          })
        );
        break;

      case GLOBAL.MAINTENANCE.CLOSED:
        dispatch(
          fetchAllClosedMaintenance({
            property_id: propertyId,
            page: currentPage,
            limit: 10,
          })
        );

        break;

      default:
        // dispatch(
        //   fetchAllClosedMaintenance({
        //     property_id: propertyId,
        //     page: currentPage,
        //     // limit: 20,
        //   })
        // );
        break;
    }
  }, [tab, propertyId, currentPage]);

  useEffect(() => {
    if (propertyId) {
      (async () => {
        try {
          await dispatch(setLoading(true));
          await dispatch(fetchPropertyDetailByID());
          await dispatch(setLoading(false));
        } catch (e) {}
      })();
    }
  }, [propertyId]);

  useEffect(() => {
    if (propData) {
      if (propData?.mgmt_model === 0) {
        setButtonLists([
          { key: GLOBAL.MAINTENANCE.NEW, label: "New" },
          { key: GLOBAL.MAINTENANCE.ONGOING, label: "Ongoing" },
          {
            key: GLOBAL.MAINTENANCE.SPARE_PARTS,
            label: "Spare Parts & Services",
          },
          { key: GLOBAL.MAINTENANCE.COMPLETED, label: "Completed" },
          { key: GLOBAL.MAINTENANCE.REJECTED, label: "Rejected" },
          { key: GLOBAL.MAINTENANCE.MOVE_IN, label: "Move In" },
          { key: GLOBAL.MAINTENANCE.MOVE_OUT, label: "Move Out" },
          { key: GLOBAL.MAINTENANCE.UNITS, label: "Units" },
          { key: GLOBAL.MAINTENANCE.COMMON_AREA, label: "Common Area" },
          { key: GLOBAL.MAINTENANCE.CLOSED, label: "Closed" },
        ]);
      } else if (propData?.mgmt_model === 1) {
        setButtonLists([
          { key: GLOBAL.MAINTENANCE.NEW, label: "New" },
          { key: GLOBAL.MAINTENANCE.ONGOING, label: "Ongoing" },
          {
            key: GLOBAL.MAINTENANCE.SPARE_PARTS,
            label: "Spare Parts & Services",
          },
          { key: GLOBAL.MAINTENANCE.COMPLETED, label: "Completed" },
          { key: GLOBAL.MAINTENANCE.REJECTED, label: "Rejected" },
          // { key: GLOBAL.MAINTENANCE.ESCALATED, label: "Escalated" },
          { key: GLOBAL.MAINTENANCE.UNITS, label: "Units" },
          { key: GLOBAL.MAINTENANCE.COMMON_AREA, label: "Common Area" },
          { key: GLOBAL.MAINTENANCE.CLOSED, label: "Closed" },
        ]);
      } else {
        setButtonLists([
          { key: GLOBAL.MAINTENANCE.NEW, label: "New" },
          { key: GLOBAL.MAINTENANCE.ONGOING, label: "Ongoing" },
          {
            key: GLOBAL.MAINTENANCE.SPARE_PARTS,
            label: "Spare Parts & Services",
          },
          { key: GLOBAL.MAINTENANCE.COMPLETED, label: "Completed" },
          { key: GLOBAL.MAINTENANCE.REJECTED, label: "Rejected" },
          { key: GLOBAL.MAINTENANCE.ESCALATED, label: "Escalated" },
          { key: GLOBAL.MAINTENANCE.MOVE_IN, label: "Move In" },
          { key: GLOBAL.MAINTENANCE.MOVE_OUT, label: "Move Out" },
          { key: GLOBAL.MAINTENANCE.UNITS, label: "Units" },
          { key: GLOBAL.MAINTENANCE.COMMON_AREA, label: "Common Area" },
          { key: GLOBAL.MAINTENANCE.CLOSED, label: "Closed" },
        ]);
      }
    }
  }, [propData]);

  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="manage-maintenance-wrapper">
      <div className="heading">
        <h2 className="font_xxl fw_6">Maintenances</h2>
      </div>

      {/* TAB BUTTONS */}
      <TabButton
        buttonList={buttonLists}
        currentKey={tab}
        onSelect={onTabSelect}
      />

      {/* Display Table Data Based On The Current Tab */}

      {tab === GLOBAL.MAINTENANCE.NEW ? (
        <NewRequest
          maintenances={maintenanceList?.maintenance}
          totalCount={maintenanceList?.total}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      ) : tab === GLOBAL.MAINTENANCE.ONGOING ? (
        <OnGoingRequest
          maintenances={maintenanceList?.maintenance}
          totalCount={maintenanceList?.total}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      ) : tab === GLOBAL.MAINTENANCE.SPARE_PARTS ? (
        <SparePartsRequest
          maintenances={maintenanceSpareList?.maintenance}
          totalCount={maintenanceSpareList?.total}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      ) : tab === GLOBAL.MAINTENANCE.COMPLETED ? (
        <CompletedRequest
          maintenances={maintenanceList?.maintenance}
          totalCount={maintenanceList?.total}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      ) : tab === GLOBAL.MAINTENANCE.REJECTED ? (
        <RejectedRequest
          maintenances={maintenanceList?.maintenance}
          totalCount={maintenanceList?.total}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      ) : tab === GLOBAL.MAINTENANCE.ESCALATED ? (
        <EscalaltedRequest
          maintenances={maintenanceEscalatedList?.maintenance}
          totalCount={maintenanceEscalatedList?.total}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      ) : tab === GLOBAL.MAINTENANCE.MOVE_IN ? (
        <MoveInRequest
          maintenances={maintenances?.maintenance}
          totalCount={maintenanceList?.totalCount}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      ) : tab === GLOBAL.MAINTENANCE.MOVE_OUT ? (
        <MoveOutRequest
          maintenances={maintenances?.maintenance}
          totalCount={maintenanceList?.totalCount}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      ) : tab === GLOBAL.MAINTENANCE.UNITS ? (
        <UnitsRequest
          maintenances={maintenanceList?.maintenance}
          totalCount={maintenanceList?.total}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      ) : tab === GLOBAL.MAINTENANCE.COMMON_AREA ? (
        <CommonAreaRequest
          maintenances={maintenanceList?.maintenance}
          totalCount={maintenanceList?.total}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      ) : (
        <ClosedRequest
          maintenances={maintenanceList?.maintenance}
          totalCount={maintenanceList?.total}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      )}
    </div>
  );
};
/* Component ends */

export default ManageMaintenance;
