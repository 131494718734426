/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import moment from "moment";
import { useNavigate } from "react-router-dom";
/* Import configuration ends */

/* Import redux slices component starts */
/* Import redux slices component ends */

/* Import react bootstrap component starts */
import { Table } from "react-bootstrap";
/* Import react bootstrap component ends */

/* Import image and SVG starts */
import { EyeFilledBlue } from "../../../../../../../setup/constants/images";
/* Import image and SVG ends */

/* Import local pages and component starts */
import GLOBAL from "../../../../../../../setup/constants/global";
import IconButton from "../../../../../../ui/button/IconButton/IconButton";
import Pagination from "../../../../../../ui/other/Pagination/Pagination";
/* Import local pages and component ends */

// Styles
// import "./style.scss";

/* Component starts */
const OthersViolations = (props) => {
  /* Props destructuring starts */
  const { violationLists, totalCount, currentPage, setCurrentPage } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const navigate = useNavigate();
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  const USER_DATA = localStorage.getItem("user_data")
    ? JSON.parse(localStorage.getItem("user_data"))
    : null;
  const DATE_FORMAT = USER_DATA ? USER_DATA?.date_format : "";
  const TIME_FORMAT = USER_DATA ? USER_DATA?.time_format : "";
  const DATE_TIME_FORMAT = `${DATE_FORMAT} ${TIME_FORMAT}`;
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  const handleViewReport = async (id) => {
    // Add the clicked item to cookies
    // const openedItems = cookies.openedItems || [];
    // if (!openedItems.includes(id)) {
    //   openedItems.push(id);
    //   setCookie("openedItems", openedItems);
    // }

    navigate(`${id}`);
  };
  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */

  return (
    <>
      <div className="custom-table-wrapper voilation-table">
        {violationLists?.length > 0 && (
          <Table className="custom-table font_s" borderless responsive>
            {/* Table Heading */}
            <thead>
              <tr>
                <th>ID</th>
                <th>To</th>
                <th>Subject</th>
                {/* <th>Type</th> */}
                <th>Date & Time</th>

                <th className="text-end">Action</th>
              </tr>
            </thead>
            {/* Table Body */}
            <tbody>
              {violationLists?.map((item, index) => (
                <tr key={item?._id}>
                  {/* Complain ID */}
                  <td>{item?.violation_id}</td>

                  {/* To */}
                  <td>{item?.issued_to}</td>

                  {/* Description */}
                  <td>{item?.title}</td>

                  {/* Status */}
                  {/* <td>
                    {item?.is_security_incident !== undefined
                      ? item?.is_security_incident.toString()
                      : "-"}
                  </td> */}

                  {/* Date And Time */}
                  <td>{moment(item?.createdAt).format(DATE_TIME_FORMAT)}</td>

                  {/* Actions */}
                  <td className="text-end txt-nowrap">
                    <IconButton
                      onClick={() => handleViewReport(item?._id)}
                      className="action-button"
                    >
                      <img
                        src={EyeFilledBlue}
                        className="h_100"
                        alt="View Details"
                      />
                    </IconButton>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}

        {violationLists?.length === 0 && (
          <div className=" text-center">
            <h3 className=" font_m font_yellow fw_6">No Data</h3>
          </div>
        )}
      </div>

      {/* PAGINATION */}
      {violationLists?.length > 0 && (
        <Pagination
          itemsPerPage="10"
          totalItems={totalCount}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      )}
    </>
  );
};
/* Component Ends */
export default OthersViolations;
