export const initialValues = {
  subject: "",
  description: "",
  asset_number: "",
  asset_location: "",
};

export const validations = (values) => {
  const errorFields = {};

  /* Validations for Subject Starts */
  if (values.subject === "") {
    errorFields.subject = "Subject is required";
  }

  /* Validations for Subject Ends */

  /* Validations for Description Starts */
  if (values.description === "") {
    errorFields.description = "Description is required";
  }
  /* Validations for Description Ends */

  return errorFields;
};

export const addDoc = (formRef, docList, setInitialFormValues, setDocList) => {
  // const length = docList.length;
  const generetedID =
    docList.length === 0 ? 0 : docList[docList.length - 1] + 1;
  const currentFormState = { ...formRef.current.values };
  setInitialFormValues(() => {
    // Generating new field name according to array indexes
    // currentFormState[`document_${length}`] = null;
    // return currentFormState;
    currentFormState[`document_${generetedID}`] = null;
    return currentFormState;
  });
  setDocList((p) => [...p, generetedID]);
};

export const removeDoc = (
  formRef,
  docList,
  setInitialFormValues,
  setDocList,
  index
) => {
  const removeID = docList[index];
  const currentFormState = { ...formRef.current.values };

  setInitialFormValues(() => {
    delete currentFormState[`document_${removeID}`];
    return currentFormState;
  });

  setDocList((prevState) => {
    const prevFields = [...prevState];
    prevFields.splice(index, 1);
    return prevFields;
  });
};

export const loadInitialValue = (
  passData,
  setInitialFormValues,
  setStartDate,
  setEndDate
) => {
  console.log(passData, "[PASS-DATa]");

  setInitialFormValues({
    subject: passData?.subject || "",
    description: passData?.description || "",
    asset_number: passData?.asset_number || "",
    asset_location: passData?.asset_location || "",
    frequency : passData?.frequency || '',
    image: passData?.image || "",
  });
  setStartDate(
    passData?.startdate ? new Date(passData?.startdate) : new Date()
  );
  setEndDate(passData?.enddate ? new Date(passData?.enddate) : new Date());
};
