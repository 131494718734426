/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import { useDispatch } from "react-redux";
import GLOBAL from "../../../../setup/constants/global";
import { Link, useParams, useNavigate } from "react-router-dom";
import {
  disabledStatusSwitch,
  canResendInvitation,
  canCancelInvitation,
} from "./helper";
/* Import configuration ends */

/* Import redux slices component starts */
import { updateApprovalStatus } from "../../../../setup/store/slices/teamSlice";
import { updateMemberStatus } from "../../../../setup/store/slices/teamSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
import Table from "react-bootstrap/Table";
/* Import react bootstrap component ends */

/* Import image and SVG starts */
import {
  DeleteGrey,
  DeleteRed,
  EyeFilledBlue,
} from "../../../../setup/constants/images";
/* Import image and SVG ends */

/* Import local pages and component starts */
import SliderButton from "../../../ui/button/SliderButton/SliderButton";
import SuccessModal from "../../../ui/modal/SuccessModal/SuccessModal";
import ErrorModal from "../../../ui/modal/ErrorModal/ErrorModal";
import IconButton from "../../../ui/button/IconButton/IconButton";
import TeamDetailModal from "../../../ui/modal/TeamDetailModal/TeamDetailModal";
import StatusHighLight from "../StatusHighLight/StatusHighLight";
import TextButton from "../../../ui/button/TextButton/TextButton";
import ORTableUnderOwner from "../ORTableUnderOwner/ORTableUnderOwner";
import { Accordion } from "react-bootstrap";
/* Import local pages and component ends */

import "./style.scss";

/* Component starts */
const OwnerTable = (props) => {
  /* Props destructuring starts */
  const {
    owners,
    canInvite,
    setSelectedTeamMember,
    showWarning,
    isT2,
    isT3,
    userRoles,
  } = props;

  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [detail, setDetail] = useState(null);
  const [modalDesc, setModalDesc] = useState("");
  const [showApprove, setShowApprove] = useState(false);
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const dispatch = useDispatch();
  const { propertyId } = useParams();
  const navigate = useNavigate();
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  const showSlider = owners?.length > 1 && true;
  const isClient = localStorage.getItem("user_role") === "Client";
  const user = localStorage.getItem("user_data")
    ? JSON.parse(localStorage.getItem("user_data"))
    : null;
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  // Checking if the current user can approve the request
  const canUserApprove = (approvers) => {
    return approvers.some((item) => item === user.role_id);
  };

  // On view detail
  const onViewDetail = (teamMember) => {
    setDetail(teamMember);
    setShowDetailsModal(true);
    setShowApprove(
      teamMember?.status === GLOBAL.USER_STATUS.PENDING_APPROVAL &&
        canUserApprove(teamMember?.approver_roles)
    );
  };

  // On toggle activating or suspending
  const toggleActiveSuspend = (user, status) => {
    if (status) {
      changeStatus(user, GLOBAL.USER_STATUS.ACTIVE);
    } else if (isT2 && !status) {
      changeStatus(user, GLOBAL.USER_STATUS.DEACTIVATED);
    } else {
      setModalDesc(
        "Cannot deactivate. Need to have atleast one active Owner. To automatically deactivate, you need to activate a deactivated Owner."
      );
      setShowErrorModal(true);
    }
  };

  // On switching owner activate or deactivate status
  const changeStatus = async (owner, status) => {
    // Collecting or creating payload data to be sent
    const payload = {
      member_id: owner?.application_id,
      status: status,
      property_id: propertyId,
    };

    // Backend response. Try, Catch
    try {
      const result = await dispatch(updateMemberStatus(payload));

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          setModalDesc(result.payload);
          setShowErrorModal(true);
          break;

        case GLOBAL.FULFILLED:
          setModalDesc(result.payload.message);
          setShowSuccessModal(true);
          break;
      }
    } catch (e) {
      // Handling error response
      console.error(e.message);
    }
  };

  // On deleting selected owner
  const onDelete = (owner) => {
    changeStatus(owner, GLOBAL.USER_STATUS.DEACTIVATED);
  };

  // On viewing details of seleted owner
  const onView = (owner) => {
    setDetail(owner);
    setShowDetailsModal(true);
  };

  // On rejecting or approving request
  const requestAction = async (teamMember, action) => {
    // Collecting or creating payload data to be sent
    const payload = {
      approval_id: teamMember?.request_approval_id,
      status: action,
      property_id: propertyId,
    };

    // Backend response. Try, Catch
    try {
      const result = await dispatch(updateApprovalStatus(payload));

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          setModalDesc(result.payload);
          setShowDetailsModal(false);
          setShowErrorModal(true);
          break;

        case GLOBAL.FULFILLED:
          setModalDesc(result.payload.message);
          setShowDetailsModal(false);
          setShowSuccessModal(true);
          break;
      }
    } catch (e) {
      // Handling error response
      console.error(e.message);
    }
  };
  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="owner-table-module-wrapper">
      <div className="main-heading">
        <h3 className="fw_6 font_xl">Owner</h3>
        {isClient && (
          <p
            onClick={() => {
              canInvite(GLOBAL.USER_ROLE.OWNER, "owner/add");
            }}
            // to="owner/add"
            className="fw_6 font_m font_blue"
          >
            + Add Owner
          </p>
        )}
      </div>

      <div className="wrapper">
        {owners?.map((item, index) => (
          <div className="company_wrapper_outer">
            <Accordion>
              <Accordion.Item eventKey={index}>
                <Accordion.Header>
                  <div className="custom-table-wrapper">
                    <Table
                      className="custom-table font_s"
                      borderless
                      responsive
                    >
                      {/* Table Heading */}
                      <thead>
                        <tr>
                          <th>ID</th>
                          <th>Name</th>
                          <th></th>
                          <th>Status</th>
                          <th className="">Action</th>
                        </tr>
                      </thead>
                      {/* Table Body */}
                      <tbody>
                        <tr key={index}>
                          {/* ID */}
                          <td>
                            <p className="col-different1">#{index + 1}</p>
                          </td>

                          {/* Name */}
                          <td>
                            <p className="col-different2">
                              {item?.first_name + " " + item?.last_name}
                            </p>
                          </td>

                          {/* Status */}
                          <td>
                            {(showSlider || item?.status === "expired") && (
                              <div className="col-different3">
                                <SliderButton
                                  active={
                                    item?.status === GLOBAL.USER_STATUS.ACTIVE
                                  }
                                  disabled={disabledStatusSwitch(item?.status)}
                                  onChange={(currentState) =>
                                    isClient &&
                                    toggleActiveSuspend(item, currentState)
                                  }
                                />
                              </div>
                            )}
                          </td>

                          {/* Invitaion Status */}
                          <td className="fw_6">
                            <p className="col-different4">
                              <StatusHighLight status={item?.status} />
                            </p>
                          </td>

                          {/* Actions */}
                          <td className="">
                            <IconButton
                              onClick={() => onView(item)}
                              className="action-button"
                            >
                              <img
                                src={EyeFilledBlue}
                                className="h_100"
                                alt="View Details"
                              />
                            </IconButton>
                            {/* //GLOBAL.USER_STATUS.SUSPENDED */}
                            {item?.status === "suspended" && (
                              <IconButton
                                onClick={() => onDelete(item)}
                                className="action-button"
                              >
                                <img
                                  src={DeleteRed}
                                  className="h_100"
                                  alt="Delete"
                                />
                              </IconButton>
                            )}

                            {canResendInvitation(item.status) && (
                              <TextButton
                                onClick={() => {
                                  setSelectedTeamMember(item);
                                  showWarning("resend");
                                }}
                                className="font_m v_middle fw_6 action-txt-btn"
                              >
                                Resend
                              </TextButton>
                            )}
                            {canCancelInvitation(item.status) && (
                              <TextButton
                                onClick={() => {
                                  setSelectedTeamMember(item);
                                  showWarning("cancel");
                                }}
                                className="font_m v_middle fw_6 font_red action-txt-btn"
                              >
                                Cancel
                              </TextButton>
                            )}

                            {isT3 &&
                              item?.status === GLOBAL.USER_STATUS.ACTIVE && (
                                <TextButton
                                  onClick={() =>
                                    navigate(`owner/${item?._id}/or/add`)
                                  }
                                  className="add-or-btn fw_6 font_m"
                                >
                                  Add Owner Representative
                                </TextButton>
                              )}
                          </td>
                        </tr>
                      </tbody>
                    </Table>

                    {/* If No PMC added yet */}
                    {owners?.length === undefined && (
                      <h3 className="not-added-yet text-center font_m font_yellow fw_6">
                        No OWNER added yet
                      </h3>
                    )}
                  </div>
                </Accordion.Header>
                <Accordion.Body>
                  <div className="company_wrapper">
                    {/* Company Table */}

                    {(item?.status === GLOBAL.USER_STATUS.ACTIVE ||
                      item?.status === GLOBAL.USER_STATUS.SUSPENDED) &&
                      item?.subMembers && <div className="bottom-arrow"></div>}

                    {/* ************************************************************************************************************************************************ */}

                    {/* Owner Representative Table */}
                    {isT3 &&
                      (item?.status === GLOBAL.USER_STATUS.ACTIVE ||
                        item?.status === GLOBAL.USER_STATUS.SUSPENDED) && (
                        <div className="inner-wrapper inner-wrapper-1">
                          <ORTableUnderOwner
                            ownerList={owners}
                            subMembers={
                              item?.subMembers
                                ? item?.subMembers["Owner Representative"]
                                : []
                            }
                            parent={item}
                            userRoles={userRoles}
                            disabledStatusSwitch={disabledStatusSwitch}
                            canResendInvitation={canResendInvitation}
                            canCancelInvitation={canCancelInvitation}
                            setSelectedTeamMember={setSelectedTeamMember}
                            toggleActiveSuspend={toggleActiveSuspend}
                            onViewDetail={onViewDetail}
                            onDelete={onDelete}
                            canInvite={canInvite}
                            showWarning={showWarning}
                          />
                        </div>
                      )}
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
        ))}

        {/* If No PMC added yet */}
        {(!owners || owners?.length === 0) && (
          <h3 className="not-added-yet text-center font_m font_yellow fw_6">
            No Owner added yet
          </h3>
        )}
      </div>

      {/* Modals */}
      {/* Success Modal */}
      <SuccessModal
        showModal={showSuccessModal}
        title="Success"
        description={modalDesc}
        onClose={() => {
          setShowSuccessModal(false);
          setModalDesc("");
        }}
      />

      {/* Error Modal */}
      <ErrorModal
        showModal={showErrorModal}
        title="Error"
        description={modalDesc}
        onClose={() => {
          setShowErrorModal(false);
          setModalDesc("");
        }}
      />

      {/* Team Detail Modal */}
      <TeamDetailModal
        showModal={showDetailsModal}
        detail={detail}
        showApprovalReq={showApprove}
        requestAction={requestAction}
        user={user}
        onClose={() => setShowDetailsModal(false)}
      />
    </div>
  );
};
/* Component ends */

export default OwnerTable;
