/**
 *
 */

import React, { useEffect } from "react";

/* Import configuration starts */
import { useParams } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
/* Import configuration ends */

/* Import redux slices component starts */
import { setLoading } from "../../setup/store/slices/unpersistedSlice";
import {
  fetchPropertyDetailByID,
  getPropertyDetail,
} from "../../setup/store/slices/propertySlice";
import { getUser } from "../../setup/store/slices/authSlice";
import {
  fetchIncidentBasedOnId,
  getSecurityIncidentData,
} from "../../setup/store/slices/securitySlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import BreadCrumb from "../../components/ui/other/BreadCrumb/BreadCrumb";
import SecurityIncidentDetail from "../../components/module/SecurityManage/ClientSecurityManage/SecurityIncidentDetail/SecurityIncidentDetail";

/* Import local pages and component ends */

import "./style.scss";

/* Component starts */
const SecurityIncidentDetailPage = (props) => {
  /* Props destructuring starts */
  const {} = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  // const { state } = useLocation();
  const { id, propertyId } = useParams();
  const dispatch = useDispatch();
  const incidentData = useSelector(getSecurityIncidentData);
  const property = useSelector(getPropertyDetail);
  const user = useSelector(getUser);
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  const type = incidentData?.type;
  const mgmtModel = incidentData?.level;
  const isT1 = mgmtModel === "t1";
  const isT2 = mgmtModel === "t2";
  const isT3 = mgmtModel === "t3";
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  /* Component function definition ends */

  /* Component useEffect starts */
  useEffect(() => {
    if (id) {
      dispatch(fetchIncidentBasedOnId(id));
    }
  }, [id, dispatch]);

  useEffect(() => {
    (async () => {
      try {
        await dispatch(setLoading(true));
        await dispatch(fetchPropertyDetailByID({ property_id: propertyId }));
        await dispatch(setLoading(false));
      } catch (e) {}
    })();
  }, [propertyId]);

  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <>
      <BreadCrumb type="security_incident_detail" />
      <div className="security-incident-page-wrapper">
        <SecurityIncidentDetail
          isT1={isT1}
          isT2={isT2}
          isT3={isT3}
          mgmtModel={mgmtModel}
          type={type}
          incident={incidentData}
          incident_id={id}
          property={property}
          user={user}
        />
      </div>
    </>
  );
};
/* Component ends */

export default SecurityIncidentDetailPage;
