export const fieldValue = {};

// Validations
export const validation = (values, desc, setTotalEstimationValue) => {
  const error = {};
  let total = 0;

  // Description and Estimation starts
  desc.forEach((itm, idx) => {
    const description = "description_" + itm;
    const estimate = "estimate_" + itm;

    // ********************** Creating Object Keys **********************
    if (values[description] === "") {
      error[description] = "Description is required";
    }

    if (values[estimate] === "") {
      error[estimate] = "Estimation is required";
    }

    total +=
      values[estimate] && values[estimate] !== ""
        ? parseFloat(values[estimate])
        : 0;
  });
  // Description and Estimation ends
  
  setTotalEstimationValue(total);
  return error;
};

// Form prefill when reviewed by FM
export const prefill = (
  data,
  setFormValue,
  setDesc,
  setTotalEstimationValue
) => {
  const descriptionContainer = {};
  const formCount = [];
  let total = 0;

  data?.forEach((item, index) => {
    descriptionContainer[`description_${index}`] = item.description;
    descriptionContainer[`estimate_${index}`] = item.estimate;
    total +=
      item.estimate && item.estimate !== "" ? parseFloat(item.estimate) : 0;
    formCount.push(index);
  });

  setTotalEstimationValue(total);
  setFormValue(descriptionContainer);
  setDesc(formCount);
};
