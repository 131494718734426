export const initialValues = {
  short_desc: "",
  long_desc: "",
  location_found: "",
  found_by: "",
  document_0: null,
};

export const validations = (values, docList) => {
  const error = {};
  /* Validation for Short Description starts */
  if (values.short_desc === "") {
    error.short_desc = "Short Description is required";
  }
  /* Validation for Short Description ends */
  /* ******************************************************************************* */

  /* Validation for Long Description starts */
  if (values.long_desc === "") {
    error.long_desc = "Long Description is required";
  }
  /* Validation for Long Description ends */
  /* ******************************************************************************* */

  /* Validation for Location Found starts */
  if (values.location_found === " ") {
    error.location_found = "Location Found is required";
  }
  /* Validation for Location Found ends */
  /* ******************************************************************************* */

  /* Validation for Found By starts */
  if (values.found_by === " ") {
    error.found_by = "Found By is required";
  }
  /* Validation for Found By ends */
  /* ******************************************************************************* */

  docList.forEach((item, index) => {
    // const maxSize = 5 * 1024 * 1024; // 5 MB in bytes

    if (values[`document_${item}`] === null) {
      error[`document_${item}`] = "Document Required";
    }

    // if (values[`document_${item}`]?.size > maxSize) {
    //   error[`document_${item}`] =
    //     "File size is too large. Please upload a file smaller than or equal to 5 MB.";
    // }
  });

  return error;
};

// Field Prop option extractor
export const fieldPropExtractor = (option, index) => {
  if (option?.photos?.length > 0) return option?.photos[index];
  else return undefined;
};

export const addDoc = (formRef, docList, setInitialFormValues, setDocList) => {
  // const length = docList.length;
  const generetedID =
    docList.length === 0 ? 0 : docList[docList.length - 1] + 1;
  const currentFormState = { ...formRef.current.values };
  setInitialFormValues(() => {
    // Generating new field name according to array indexes
    // currentFormState[`document_${length}`] = null;
    // return currentFormState;
    currentFormState[`document_${generetedID}`] = null;
    return currentFormState;
  });
  setDocList((p) => [...p, generetedID]);
};

export const removeDoc = (
  formRef,
  docList,
  setInitialFormValues,
  setDocList,
  index
) => {
  const removeID = docList[index];
  const currentFormState = { ...formRef.current.values };

  setInitialFormValues(() => {
    delete currentFormState[`document_${removeID}`];
    return currentFormState;
  });

  setDocList((prevState) => {
    const prevFields = [...prevState];
    prevFields.splice(index, 1);
    return prevFields;
  });
};
