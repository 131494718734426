/**
 *
 */

import React from "react";
/* Import configuration starts */
import moment from "moment";
import { useNavigate } from "react-router-dom";
/* Import configuration ends */

/* Import redux slices component starts */
/* Import redux slices component ends */

/* Import react bootstrap component starts */
import { Table } from "react-bootstrap";
/* Import react bootstrap component ends */

/* Import image and SVG starts */
import { EyeFilledBlue } from "../../../../../setup/constants/images";
/* Import image and SVG ends */

/* Import local pages and component starts */
import IconButton from "../../../../ui/button/IconButton/IconButton";
import Pagination from "../../../../ui/other/Pagination/Pagination";
/* Import local pages and component ends */

// Styles
import "./style.scss";

/* Component starts */
const OpenTasks = (props) => {
  /* Props destructuring starts */
  const { ticketLists, totalCount, currentPage, setCurrentPage } = props;

  /* Props destruc/turing ends */

  /* Component states and useRef declaration starts */
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const navigate = useNavigate();
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */

  const USER_DATA = localStorage.getItem("user_data")
    ? JSON.parse(localStorage.getItem("user_data"))
    : null;
  const DATE_FORMAT = USER_DATA ? USER_DATA?.date_format : "";
  const TIME_FORMAT = USER_DATA ? USER_DATA?.time_format : "";
  const DATE_TIME_FORMAT = `${DATE_FORMAT} ${TIME_FORMAT}`;

  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */

  // function getDate(date) {
  //   let theDate = new Date(Date.parse(date));

  //   return (
  //     theDate.toLocaleDateString() + " " + theDate.toLocaleTimeString("en-US")
  //   );
  // }

  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */

  return (
    <>
      <div className="custom-table-wrapper open-task-table-wrapper">
        {ticketLists?.length > 0 && (
          <Table className="custom-table font_s" borderless responsive>
            {/* Table Heading */}
            <thead>
              <tr>
                <th>ID</th>
                <th>Ticket Created Date & Time</th>
                <th>Subject</th>
                <th>Sub Status</th>
                {/* <th>Frequency</th>
                <th>Task Id</th>
                <th>Assigned to</th> */}
                <th>Actions</th>
              </tr>
            </thead>
            {/* Table Body */}
            <tbody>
              {ticketLists?.map((item) => (
                <tr key={item?._id}>
                  {/* ID */}

                  <td>{item?.ticket_id}</td>

                  {/* Start Date */}
                  {/* <td>{item?.start_date}</td> */}

                  {/* End Date */}
                  {/* <td>{item?.end_date}</td> */}

                  {/* Created Date & Time */}
                  <td>{moment(item?.createdAt).format(DATE_TIME_FORMAT)}</td>

                  {/* Subject */}
                  <td>{item?.routine_id?.subject}</td>

                  {/* Status */}
                  <td className="font_yellow">
                    {item?.subStatus ? item?.subStatus : "-"}
                  </td>

                  {/* Frequency */}
                  {/* <td>{item?.routine_id?.frequency}</td> */}

                  {/* Main Task ID */}
                  {/* <td>{item?.routine_id?.task_id}</td> */}

                  {/* Assigned To */}
                  {/* <td>{item?.assigned?.first_name}</td> */}

                  {/* Actions */}
                  <td className="d-flex justify-content-between gap-1">
                    <IconButton
                      onClick={() =>
                        navigate(item?._id, {
                          state: { task: false },
                        })
                      }
                      className="action-button"
                    >
                      <img
                        src={EyeFilledBlue}
                        className="h_100"
                        alt="View Details"
                      />
                    </IconButton>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}

        {ticketLists?.length === 0 && (
          <div className=" text-center">
            <h3 className=" font_m font_yellow fw_6">No Data</h3>
          </div>
        )}
      </div>

      {ticketLists?.length > 0 && (
        <Pagination
          itemsPerPage="10"
          totalItems={totalCount}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      )}
    </>
  );
};
/* Component Ends */
export default OpenTasks;
