export const initialValues = {
  incident_sub: "",
  location: "",
  description: "",
  companyName: "",
  fullName: "",
  email: "",
  mobile: "",
  company: "",
  document_0: null,
};

export const validations = (values, voilator, docList) => {
  const errorFields = {};

  /* Validations for Incident Subject Starts */
  if (values.incident_sub === "") {
    errorFields.incident_sub = "Incident Subject is required";
  }

  /* Validations for Incident Subject Ends */

  /* Validations for Location Starts */
  if (values.location === "") {
    errorFields.location = "Location is required";
  }

  /* Validations for Location Ends */

  /* Validations for Description Starts */
  if (values.description === "") {
    errorFields.description = "Description is required";
  }
  /* Validations for Description Ends */

  /* Validations for Others Starts */
  if (voilator === "Others" && values.fullName === "") {
    errorFields.fullName = "Full Name is required";
  }

  /* Validations for Others Ends */

  docList.forEach((item, index) => {
    // const maxSize = 5 * 1024 * 1024; // 5 MB in bytes

    if (values[`document_${item}`] === null) {
      errorFields[`document_${item}`] = "Document Required";
    }

    // if (values[`document_${item}`]?.size > maxSize) {
    //   error[`document_${item}`] =
    //     "File size is too large. Please upload a file smaller than or equal to 5 MB.";
    // }
  });

  return errorFields;
};

// Field Prop option extractor
export const fieldPropExtractor = (option, index) => {
  if (option?.photos?.length > 0) return option?.photos[index];
  else return undefined;
};

export const addDoc = (formRef, docList, setInitialFormValues, setDocList) => {
  // const length = docList.length;
  const generetedID =
    docList.length === 0 ? 0 : docList[docList.length - 1] + 1;
  const currentFormState = { ...formRef.current.values };
  setInitialFormValues(() => {
    // Generating new field name according to array indexes
    // currentFormState[`document_${length}`] = null;
    // return currentFormState;
    currentFormState[`document_${generetedID}`] = null;
    return currentFormState;
  });
  setDocList((p) => [...p, generetedID]);
};

export const removeDoc = (
  formRef,
  docList,
  setInitialFormValues,
  setDocList,
  index
) => {
  const removeID = docList[index];
  const currentFormState = { ...formRef.current.values };

  setInitialFormValues(() => {
    delete currentFormState[`document_${removeID}`];
    return currentFormState;
  });

  setDocList((prevState) => {
    const prevFields = [...prevState];
    prevFields.splice(index, 1);
    return prevFields;
  });
};
