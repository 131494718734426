export const typeData = ["Spare Parts"];

export const costCoveredByData = ["Tenant"];

export const fieldValue = {
  part_name_0: "",
  quantity_0: "",
  description_0: "",
};

// Validations
export const validation = (values, formCountIndex, imgList) => {
  const error = {};

  formCountIndex.forEach((itm, idx) => {
    // ********************** Generating Object Keys **********************
    const part_name = "part_name_" + itm;
    const description = "description_" + itm;
    const quantity = "quantity_" + itm;

    // ********************** Validations starts from here **********************
    /* Part Name Validation Starts */
    if (values[part_name] === "") {
      error[part_name] = "Part Name is required";
    }
    /* Part Name Validation End */

    /* Description Validation Starts */
    if (values[description] === "") {
      error[description] = "Description is required";
    }
    if (values[description] !== "" && values[description]?.length < 10) {
      error[description] = "Minimum 10 characters are required";
    }
    /* Description Validation End */

    /* Quantity Validation Starts */
    if (values[quantity] === "") {
      error[quantity] = "Quantity is required";
    }
    /* Quantity Validation End */

    imgList[idx].forEach((docItem, docIndex) => {
      const document = `document_${idx}_${docItem}`;

      if (values[document] === null) {
        error[document] = "Attachment is required";
      }
    });
  });

  return error;
};

// export const addPartDetail = (formRef, setFormValue, list, setList) => {
//   const generetedID = list.length === 0 ? 0 : list[list.length - 1] + 1;
//   const currentFormState = { ...formRef.current.values };

//   setFormValue(() => {
//     // Generating new field name according to array indexes
//     currentFormState[`part_name_${generetedID}`] = "";
//     currentFormState[`quantity_${generetedID}`] = "";
//     currentFormState[`description_${generetedID}`] = "";
//     return currentFormState;
//   });
//   setList((p) => [...p, generetedID]);
// };

export const addPartDetail = (
  formRef,
  setFormValue,
  list,
  setList,
  setImgList
) => {
  const generetedID = list.length === 0 ? 0 : list[list.length - 1] + 1;
  const currentFormState = { ...formRef.current.values };

  setFormValue(() => {
    // Generating new field name according to array indexes
    currentFormState[`part_name_${generetedID}`] = "";
    currentFormState[`quantity_${generetedID}`] = "";
    currentFormState[`description_${generetedID}`] = "";
    return currentFormState;
  });
  setList((p) => [...p, generetedID]);
  setImgList((p) => [...p, []]);
};

export const addMoreImg = (
  formRef,
  setFormValue,
  imgList,
  setImgList,
  partDetailKey,
  index
) => {
  const generatedID =
    imgList.length === 0 ? 0 : imgList[imgList.length - 1] + 1;
  const currentFormState = { ...formRef.current.values };

  setFormValue(() => {
    // Generating new field name according to array indexes
    currentFormState[`document_${partDetailKey}_${generatedID}`] = null;
    return currentFormState;
  });

  setImgList((p) => {
    const cloneArray = [...p];
    cloneArray[index].push(generatedID);
    return cloneArray;
  });
};

export const removePartDetail = (
  formRef,
  setFormValue,
  list,
  setList,
  imgList,
  setImgList,
  index
) => {
  const removeID = list[index];
  const currentFormState = { ...formRef.current.values };

  imgList[index].forEach((item, index) => {
    delete currentFormState[`document_${removeID}_${item}`];
  });

  setFormValue(() => {
    delete currentFormState[`part_name_${removeID}`];
    delete currentFormState[`quantity_${removeID}`];
    delete currentFormState[`description_${removeID}`];
    return currentFormState;
  });

  setList((prevState) => {
    const prevFields = [...prevState];
    prevFields.splice(index, 1);
    return prevFields;
  });

  setImgList((prevState) => {
    const prevFields = [...prevState];
    prevFields.splice(index, 1);
    return prevFields;
  });
};

export const removeImg = (
  formRef,
  setFormValue,
  imgList,
  setImgList,
  list,
  partDetailIndex,
  imgIndex
) => {
  const removePartKey = list[partDetailIndex];
  const removeDocKey = imgList[partDetailIndex][imgIndex];
  const currentFormState = { ...formRef.current.values };

  setFormValue(() => {
    delete currentFormState[`document_${removePartKey}_${removeDocKey}`];
    return currentFormState;
  });

  setImgList((p) => {
    const cloneArray = [...p];
    cloneArray[partDetailIndex].splice(imgIndex, 1);
    return cloneArray;
  });
};
