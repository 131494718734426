import React, { useState, useEffect, useRef } from "react";

/* Import configuration starts */
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { validEmail } from "../../../setup/utils/validations";
import { Formik } from "formik";
import GLOBAL from "../../../setup/constants/global";
import I from "../../../setup/i18n/languageConst";
import { useTranslation } from "react-i18next";
import { Toastify } from "../../../setup/utils/toast";
import jwtDecode from "jwt-decode";
/* Import configuration ends */

/* Import redux slices starts */
import {
  showSuccessAlert,
  showErrorAlert,
  showWarningAlert,
} from "../../../setup/store/slices/globalAlertSlice";
import {
  tenantVerifyMobile,
  tenantVerifyEmail,
  resendTenantMobileOtp,
  resendTenantEmailOtp,
  searchTenant,
} from "../../../setup/store/slices/tenantSlice";
import {
  fetchUserRoles,
  getUserRoles,
} from "../../../setup/store/slices/masterSlice";
import { getAuthLoading } from "../../../setup/store/slices/unpersistedSlice";
import { tenantSetPassword } from "../../../setup/store/slices/authSlice";
import { setLoading } from "../../../setup/store/slices/unpersistedSlice";
/* Import redux slices ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
import { ArrowPointRightBlack } from "../../../setup/constants/images";
/* Import image and SVG ends */

/* Import local pages and component starts */
import CustomTextInput from "../../../components/ui/input/CustomTextInput/CustomTextInput";
import CustomPasswordInput from "../../../components/ui/input/CustomPasswordInput/CustomPasswordInput";

import CustomButton from "../../../components/ui/button/CustomButton/CustomButton";
import SuccessModal from "../../../components/ui/modal/SuccessModal/SuccessModal";
import ConfirmationOTPModal from "../../../components/ui/modal/ConfirmationOTPModal/ConfirmationOTPModal";
import CustomCheckInput from "../../../components/ui/input/CustomCheckInput/CustomCheckInput";
import TextButton from "../../../components/ui/button/TextButton/TextButton";
import ErrorModal from "../../../components/ui/modal/ErrorModal/ErrorModal";
import CustomPhoneInput from "../../../components/ui/input/CustomPhoneInput/CustomPhoneInput";
/* Import local pages and component ends */

const initialValues = {
  email: "",
  password: "",
  confirm_password: "",
};

/* Component starts */
const SignUpForm = (props) => {
  /* Props destructuring starts */
  const { setShowTerms } = props;
  /* Props destructuring ends */

  /* Component states and useRefs declaration starts */
  const [errorWhileSubmiting, setErrorWhileSubmiting] = useState(null);
  const [registeredEmail, setRegisteredEmail] = useState("");
  const [registeredPassword, setRegisteredPassword] = useState("");
  const [signUpText, setSignUpText] = useState("Submit");
  const [token, setToken] = useState("");
  const [terms, setTerms] = useState(false);
  const [termsError, setTermsError] = useState(false);
  // Modal state
  const [showMobileVerificationModal, setShowMobileVerificationModal] =
    useState(false);
  const [showEmailVerificationModal, setShowEmailVerificationModal] =
    useState(false);

  // useRefs declarations
  const formikPasswordValidation = useRef(false);
  const [formState, setFormState] = useState(initialValues);
  /* Component states and useRefs declaration ends */

  /* Other hooks declaration starts */
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { encodedToken } = useParams();
  const tenantDetails = jwtDecode(encodedToken);
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  // Form validations
  const formValidation = (values) => {
    const errorFields = {};

    /* Validation for email starts */
    if (values.email === "") {
      errorFields.email = "Email is required";
    } else if (values.email && !validEmail(values.email)) {
      errorFields.email = "Email is not valid";
    }
    /* Validation for email ends */

    /* Validation for Password starts */
    if (values.password === "") {
      errorFields.password = "Password is required";
    } else if (values.password && formikPasswordValidation.current) {
      errorFields.password = true;
    }
    /* Validation for Password ends */

    /* Validation for Confirm Password starts */
    if (values.confirm_password === "") {
      errorFields.confirm_password = "Confirm Password is required";
    } else if (
      values.confirm_password &&
      values.password !== values.confirm_password
    ) {
      errorFields.confirm_password =
        "Confirm Password doesn't match with the password";
    }
    /* Validation for Confirm Password ends */

    return errorFields;
  };

  // On verifying OTP sent to registered mobile number
  const onVerifyingMobile = async (otp) => {
    if (otp.length != 6) {
      Toastify("error", "Enter valid OTP");
      return;
    }

    // Creating or gathering payload data to be sent
    const payload = {
      otp: otp,
      email: registeredEmail,
    };

    // Backend Response. Try, Catch
    try {
      const result = await dispatch(tenantVerifyMobile(payload));
      setShowMobileVerificationModal(false);
      // Handling success response
      switch (result.meta.requestStatus) {
        case "rejected":
          Toastify("error", result.payload);
          dispatch(
            showErrorAlert({
              title: "Error!",
              description: result.payload,
            })
          );
          break;

        case "fulfilled":
          setErrorWhileSubmiting("Please verify your email");
          setSignUpText("Verify Email");
          // dispatch(
          //   showSuccessAlert({
          //     title: "Success!",
          //     description: result.payload.message,
          //   })
          // );
          onSubmit({ email: registeredEmail, password: registeredPassword });
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };

  // On resending verification OTP sent to registered mobile number
  const onResendMobileVerificationCode = async () => {
    // Creating or collecting payload data to be sent
    const payload = {
      email: registeredEmail,
    };

    //Backend respose. Try, Catch
    try {
      const result = await dispatch(resendTenantMobileOtp(payload));

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          Toastify("error", result.payload);
          break;

        case GLOBAL.FULFILLED:
          Toastify("success", result.payload.message);
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };

  // Handling on signing up
  const onSignUp = async (values, userExisted) => {
    setErrorWhileSubmiting(null);
    if (userExisted) return;

    // Creating or gathering payload data to be sent
    const payload = {
      username: values?.email,
      new_password: values?.password,
    };
    console.log("Payload", payload);

    // Backend Response. Try, Catch
    try {
      const result = await dispatch(tenantSetPassword(payload));
      console.log("Result", result);
      // setSubmitting(false);

      // Handling success response
      switch (result.meta.requestStatus) {
        case "rejected":
          setErrorWhileSubmiting(result.payload.error.message);
          if (result?.payload?.data?.code === "MOBILE_NOT_VERIFIED") {
            setShowMobileVerificationModal(true);
          } else if (result?.payload?.data?.code === "EMAIL_NOT_VERIFIED") {
            setToken(result?.payload?.data?.token);
            setShowEmailVerificationModal(true);
          }
          break;
        case "fulfilled":
          dispatch(
            showSuccessAlert({
              title: "Success!",
              description:
                "Password is successfully set. Please log in to accept the invitation.",
            })
          );
          navigate(`/signin/tenant/invite/${encodedToken}`);
          break;
      }
    } catch (error) {
      // Handling error response
      // setSubmitting(false);
    }
  };

  // Searching user with email if already existed or not
  const searchAccount = async (email) => {
    const payload = `username=${encodeURIComponent(email.toLowerCase())}`;
    try {
      const result = await dispatch(searchTenant(payload));
      switch (result?.payload?.is_password_set) {
        case false:
          return false;
          break;

        case true:
          dispatch(
            showErrorAlert({
              title: "Error!",
              description: "Tenant already exist in the system.",
            })
          );
          return result?.payload?.is_password_set;
          break;
      }
    } catch (error) {
      console.log(error);
    }
  };

  // On verifying OTP sent to registered email
  const onVerifyEmail = async (otp) => {
    if (otp.length != 6) {
      Toastify("error", "Invalid OTP");
      return;
    }

    // Creating or collecting payload data to be sent
    const payload = {
      otp: otp,
      token: token,
    };

    // Backend response. Try, Catch
    try {
      const result = await dispatch(tenantVerifyEmail(payload));
      setShowEmailVerificationModal(false);

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          Toastify("error", result.payload);
          dispatch(
            showErrorAlert({
              title: "Error!",
              description: result.payload,
            })
          );
          break;

        case GLOBAL.FULFILLED:
          setErrorWhileSubmiting("You can set up your password now");
          setSignUpText("Set Up Password");
          onSubmit({ email: registeredEmail, password: registeredPassword });
          // dispatch(
          //   showSuccessAlert({
          //     title: "Success!",
          //     description: result.payload.message,
          //   })
          // );
          break;
      }
    } catch (error) {
      // Handling error response
    }
  };

  // On resending verification OTP sent to registered mobile number
  const onResendEmailVerificationCode = async () => {
    // Creating or collecting payload data to be sent
    const payload = {
      email: registeredEmail,
      token: token,
    };

    //Backend respose. Try, Catch
    try {
      const result = await dispatch(resendTenantEmailOtp(payload));

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          Toastify("error", result.payload);
          break;

        case GLOBAL.FULFILLED:
          Toastify("success", result.payload.message);
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };

  // On submitting form
  const onSubmit = async (values) => {
    if (!terms) {
      dispatch(
        showErrorAlert({
          title: "Cannot proceed!",
          description: "Please confirm terms and conditions",
        })
      );
      return;
    }
    setRegisteredEmail(values.email.toLowerCase());
    setRegisteredPassword(values.password);
    try {
      const userExisted = await searchAccount(values?.email);
      if (userExisted) {
        navigate(`/signin/tenant/invite/${encodedToken}`);
      } else {
        await onSignUp(values, userExisted);
      }
      console.log("userExisted", userExisted);
    } catch (error) {
      console.log(error);
    }
  };

  const prefillEmail = () => {
    if (tenantDetails?.email) {
      setFormState((p) => ({
        ...p,
        email: tenantDetails?.email || "",
      }));
    }
  };
  /* Component function definition ends */

  /* Component useEffect starts */
  useEffect(() => {
    (async () => {
      try {
        await dispatch(setLoading(true));
        await dispatch(fetchUserRoles());
        await dispatch(setLoading(false));
      } catch (e) {}
    })();
  }, []);

  useEffect(() => {
    prefillEmail();
  }, [encodedToken]);
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="w_100">
      {/* Sign up form */}
      <Formik
        onSubmit={onSubmit}
        initialValues={formState}
        validate={formValidation}
        validateOnBlur={true}
        enableReinitialize={true}
      >
        {({
          values,
          errors,
          handleChange,
          handleSubmit,
          handleBlur,
          touched,
          setFieldValue,
          isSubmitting,
        }) => (
          <form onSubmit={handleSubmit}>
            <div className="form-group-wrapper">
              {/* Input for Email Address */}
              <div className="form-group">
                <CustomTextInput
                  type="text"
                  label="First Name"
                  value={tenantDetails?.first_name}
                  readOnly={true}
                />
              </div>

              {/* Input for Email Address */}
              <div className="form-group">
                <CustomTextInput
                  type="text"
                  label="Last Name"
                  value={tenantDetails?.last_name}
                  readOnly={true}
                />
              </div>

              {/* Input for Email Address */}
              <div className="form-group">
                <CustomPhoneInput
                  type="text"
                  label="Phone Number"
                  value={`${tenantDetails?.countrycode} ${tenantDetails?.mobile}`}
                  readOnly={true}
                  disableDropdown={true}
                />
              </div>

              {/* Input for Email Address */}
              <div className="form-group">
                <CustomTextInput
                  type="text"
                  label="Email Address"
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  value={values.email}
                  name="email"
                  errors={errors.email}
                  touched={touched.email}
                  readOnly={true}
                />
              </div>

              {/* Input for Password */}
              <div className="form-group">
                <CustomPasswordInput
                  name="password"
                  label="Password"
                  formikPasswordValidation={formikPasswordValidation}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  value={values.password}
                  errors={errors.password}
                  touched={touched.password}
                />
              </div>

              {/* Input for Confirm Password */}
              <div className="form-group">
                <CustomPasswordInput
                  name="confirm_password"
                  label="Confirm Password"
                  showPasswordValidationErr={false}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  value={values.confirm_password}
                  errors={errors.confirm_password}
                  touched={touched.confirm_password}
                />
              </div>
            </div>

            {/* If any error occured from backend while submitting */}
            {errorWhileSubmiting && (
              <p className="error-while-submitting font_m font_red text-center w_100">
                {errorWhileSubmiting}
              </p>
            )}

            <div className="terms-conditions-wrapper">
              <CustomCheckInput
                value={terms}
                // setValue={setTerms}
              />
              {/* <p className="font_m text">
                <span>Accept</span>{" "}
                <TextButton
                  onClick={() => {
                    setShowTerms(true);
                    setTerms(!terms);
                  }}
                >
                  Terms and Conditions
                </TextButton>
              </p> */}
              <p className="font_m text">
                Accept
                <span
                  className="font_blue fw_6 mx-1 pe-auto"
                  onClick={() => {
                    setShowTerms(true);
                    setTerms(!terms);
                  }}
                >
                  Terms and Conditions
                </span>
              </p>
            </div>

            {/* Submit button and Sign up loading */}
            <div className="button-wrapper w_100">
              <CustomButton size="l" className="custom-btn-2" type="submit">
                {signUpText}
                <img src={ArrowPointRightBlack} className="arrow icon" alt="" />
              </CustomButton>
            </div>
          </form>
        )}
      </Formik>

      {/* Mobile Verification OTP Phone modal */}
      <ConfirmationOTPModal
        showModal={showEmailVerificationModal}
        title="Enter Confirmation Code"
        size="lg"
        description="Enter the OTP that has been sent to your registered Email"
        onVerify={onVerifyEmail}
        onResend={onResendEmailVerificationCode}
        onCancel={() => {
          setShowEmailVerificationModal(false);
        }}
      />

      {/* Email Verification OTP Email modal */}
      <ConfirmationOTPModal
        showModal={showMobileVerificationModal}
        title="Enter Confirmation Code"
        size="lg"
        description="Enter the OTP that has been sent to your registered mobile number"
        onVerify={onVerifyingMobile}
        onResend={onResendMobileVerificationCode}
        onCancel={() => {
          setShowMobileVerificationModal(false);
        }}
      />
    </div>
  );
};
/* Component ends */

export default SignUpForm;
