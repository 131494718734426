/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import { useParams } from "react-router-dom";
import moment from "moment";
import { logTitle } from "../helper";
import { useDispatch } from "react-redux";
import GLOBAL from "../../../../../setup/constants/global";
import {
  pmCanApproveQuote,
  taskStatusGenerate,
  mtCanRespondP2Task,
  fmCanReAssignPhase2MT,
  mtCanFillUIRCP2,
  fmCanRespondToTask,
  allTaskComplete,
  showActionGroup,
  pmCanRespondToTask,
  allItemsApprovedByFM,
  mtCanChangeArrivalStatus,
} from "../helper";
/* Import configuration ends */

/* Import redux slices component starts */
import {
  showSuccessAlert,
  showErrorAlert,
} from "../../../../../setup/store/slices/globalAlertSlice";
import {
  mtRespondP2Task,
  fmAssignMTOnReject,
  fmRespondP2,
  pmRespondP2,
  mtChangeStatusP2,
} from "../../../../../setup/store/slices/moveInSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import UIRCP2 from "../../UIRCP2/UIRCP2";
import CustomButton from "../../../../ui/button/CustomButton/CustomButton";
import NoteModal from "../../NoteModal/NoteModal";
import AssignTechnicianMoveInModal from "../../AssignTechnicianMoveInModal/AssignTechnicianMoveInModal";
import Image from "../../../../ui/other/Image/Image";
import FileViewerModal from "../../../../ui/modal/FileViewerModal/FileViewerModal";
/* Import local pages and component ends */

/* Component starts */
const TaskLog = (props) => {
  /* Props destructuring starts */
  const {
    isT1,
    status,
    maintenance,
    item,
    takeAction,
    maintenance_id,
    property,
    user,
  } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [isApproved, setIsApproved] = useState(null);
  const [assignMT, setAssignMT] = useState(false);
  const [openNote, setOpenNote] = useState(false);
  const [currentTaskItem, setCurrentTaskItem] = useState(null);
  const [showUIRCP2Form, setShowUIRCP2Form] = useState(false);
  const [showFile, setShowFile] = useState(false);
  const [file, setFile] = useState(false);
  const [filteredTask, setFilteredTask] = useState([]);
  const [additionalTasks, setAdditionalTasks] = useState([]);
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const dispatch = useDispatch();
  const { propertyId } = useParams();
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  const taskList = maintenance?.phase2_URIC?.items;
  const name = item?.requester
    ? `${item?.requester?.first_name} ${item?.requester?.last_name}`
    : `${item.tenant.first_name} ${item.tenant.last_name}`;
  const role = item?.requester_role?.name;
  const createdAt = item?.createdAt;
  const action = item?.action;
  const currentStatus = item?.current_status;
  const logType = item?.log_type;
  const comment = item?.comment;
  const maintenanceType = maintenance?.type;
  const UIRCp2 = maintenance?.phase2_URIC;

  const USER_DATA = localStorage.getItem("user_data")
    ? JSON.parse(localStorage.getItem("user_data"))
    : null;
  const DATE_FORMAT = USER_DATA ? USER_DATA?.date_format : "";
  const TIME_FORMAT = USER_DATA ? USER_DATA?.time_format : "";
  const DATE_TIME_FORMAT = `${DATE_FORMAT} ${TIME_FORMAT}`;
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  // On performing respond action with note such as Reject and Approve
  const respondAction = (value) => {
    // On re-assigning MT by FM
    mtCanRespondP2Task(currentTaskItem, user) && mtRespondtoP2Task(value);
    // On responding to Phase 2 task by FM
    fmCanRespondToTask(currentTaskItem, user) && fmRespondingToTask(value);
    // On responding to Phase 2 task by PM
    pmCanRespondToTask(currentTaskItem, user) && pmRespondingToTask(value);
  };

  // On assigning Technician
  const assignMTMasterFunction = (value, note) => {
    // On re-assigning MT by FM
    fmCanReAssignPhase2MT(currentTaskItem, user) && reAssignMT(value, note);
    // On assigning MT while rejecting task
    fmCanRespondToTask(currentTaskItem, user) &&
      assignMTOnRejectJob(value, note);
  };

  // On responding to Phase 2 task by MT
  const mtRespondtoP2Task = async (notes) => {
    setIsApproved(null);
    setCurrentTaskItem(null);
    setOpenNote(false);

    // Creating or collecting payload data to be sent
    const payload = {
      property_id: propertyId,
      maintenance_id,
      item_id: currentTaskItem._id,
      status: isApproved ? "Accepted" : "Rejected",
      notes,
      type: maintenanceType,
    };

    // Backend response. Try, Catch
    try {
      const result = await dispatch(mtRespondP2Task(payload));

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          dispatch(
            showErrorAlert({
              title: "Error!",
              description: result.payload,
            })
          );
          break;

        case GLOBAL.FULFILLED:
          dispatch(
            showSuccessAlert({
              title: "Success!",
              description: result.payload.message,
            })
          );
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };

  // On re-assigning MT by FM
  const reAssignMT = async (value, note) => {
    if (!currentTaskItem) return;
    setAssignMT(false);
    setCurrentTaskItem(null);
    const [mt] = value;
    // Creating or collecting payload data to be sent
    const payload = {
      maintenance_id,
      technician: mt?._id,
      item_id: currentTaskItem._id,
      note,
      type: maintenanceType,
      property_id: propertyId,
    };

    // Backend response. Try, Catch
    try {
      const result = await dispatch(fmAssignMTOnReject(payload));

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          dispatch(
            showErrorAlert({
              title: "Error!",
              description: result.payload,
            })
          );
          break;

        case GLOBAL.FULFILLED:
          dispatch(
            showSuccessAlert({
              title: "Success!",
              description: result.payload.message,
            })
          );
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };

  // On responding to Phase 2 task by FM
  const fmRespondingToTask = async (note) => {
    setIsApproved(null);
    setCurrentTaskItem(null);
    setOpenNote(false);

    // Creating or collecting payload data to be sent
    const payload = {
      maintenance_id,
      item_id: currentTaskItem._id,
      readiness_card: UIRCp2._id,
      status: isApproved ? "Approved" : "Rejected",
      note,
      type: maintenanceType,
      property_id: propertyId,
    };

    // Backend response. Try, Catch
    try {
      const result = await dispatch(fmRespondP2(payload));

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          dispatch(
            showErrorAlert({
              title: "Error!",
              description: result.payload,
            })
          );
          break;

        case GLOBAL.FULFILLED:
          dispatch(
            showSuccessAlert({
              title: "Success!",
              description: result.payload.message,
            })
          );
          break;

        default:
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };

  // On responding to Phase 2 task by PM
  const pmRespondingToTask = async (note) => {
    setIsApproved(null);
    setCurrentTaskItem(null);
    setOpenNote(false);

    // Creating or collecting payload data to be sent
    const payload = {
      maintenance_id,
      item_id: currentTaskItem._id,
      phase2_card: UIRCp2._id,
      status: isApproved ? "Approved" : "Rejected",
      note,
      type: maintenanceType,
      property_id: propertyId,
    };

    // Backend response. Try, Catch
    try {
      const result = await dispatch(pmRespondP2(payload));

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          dispatch(
            showErrorAlert({
              title: "Error!",
              description: result.payload,
            })
          );
          break;

        case GLOBAL.FULFILLED:
          dispatch(
            showSuccessAlert({
              title: "Success!",
              description: result.payload.message,
            })
          );
          break;

        default:
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };

  // On assigning MT while rejecting task
  const assignMTOnRejectJob = async (value, note) => {
    setIsApproved(null);
    setCurrentTaskItem(null);
    setAssignMT(false);

    const [mt] = value;
    // Creating or collecting payload data to be sent
    const payload = {
      maintenance_id,
      item_id: currentTaskItem._id,
      readiness_card: UIRCp2._id,
      technician: mt._id,
      status: "Rejected",
      note,
      type: maintenanceType,
      property_id: propertyId,
    };

    // Backend response. Try, Catch
    try {
      const result = await dispatch(fmRespondP2(payload));

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          dispatch(
            showErrorAlert({
              title: "Error!",
              description: result.payload,
            })
          );
          break;

        case GLOBAL.FULFILLED:
          dispatch(
            showSuccessAlert({
              title: "Success!",
              description: result.payload.message,
            })
          );
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };

  // On changing MT arrival status
  const changeMTArrivalStatus = async (item_id, status) => {
    // Creating or collecting payload data to be sent
    const payload = {
      maintenance_id,
      type: maintenance?.type,
      item_id,
      status,
    };

    // Backend response. Try, Catch
    try {
      const result = await dispatch(mtChangeStatusP2(payload));

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          dispatch(
            showErrorAlert({
              title: "Error!",
              description:
                typeof result?.payload === "string" ? result?.payload : "",
            })
          );
          break;

        case GLOBAL.FULFILLED:
          dispatch(
            showSuccessAlert({
              title: "Success!",
              description:
                typeof result?.payload?.message === "string"
                  ? result?.payload?.message
                  : "",
            })
          );
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };
  /* Component function definition ends */

  /* Component useEffect starts */
  // Filetring out and removing additional tasks
  useEffect(() => {
    if (taskList && taskList.length > 0) {
      setFilteredTask(taskList.filter((item) => item.type !== "Additional"));
      if (logType.toLowerCase() === "additional") {
        setAdditionalTasks(
          taskList.filter((item) => item.type && item.type === "Additional")
        );
      }
    }
  }, [taskList]);
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="time-line task-log bg_grey">
      {/* Dot */}
      <div className="dot-wrapper">
        <i className="dot"></i>
        <div className="vl"></div>
      </div>

      <div className="detail-wrapper">
        {/* Title */}
        <h3 className="title font_m fw_6">{logTitle(item)}</h3>

        {/* Technician Name and Date */}
        <div className="black-container-sm fw_5 font_s bg_black flex-between">
          <p className="left">
            <span className="font_grey">{role} </span>
            <span className="font_white"> {name}</span>
          </p>
          <p className="right font_grey">
            {moment(createdAt).format(DATE_TIME_FORMAT)}
          </p>
        </div>

        {/* Task List */}
        <div className="spare-part-quote black-container-lg fw_5 font_s">
          <p className="sub-title font_m fw_6">Task List</p>

          <div className="part-list bg_black">
            {/* If log type is not Additional, or if this log does not contain additional task */}
            {logType.toLowerCase() !== "additional" &&
              filteredTask?.map(
                (taskItem, index) =>
                  console.log(taskItem, "ADD-1") || (
                    <div key={index} className="part">
                      <h3 className="part-title font_m fw_6 font_blue">
                        Task #{index + 1}
                      </h3>
                      {/* Row 1 */}
                      <div className="r r1">
                        <p className="label font_s fw_6 font_grey">
                          Assigned To
                        </p>
                        <p className="value font_s fw_6">
                          {taskItem?.assigned?.first_name}{" "}
                          {taskItem?.assigned?.last_name}
                        </p>
                      </div>

                      {/* Row 2 */}
                      <div className="r r2">
                        <p className="label font_s fw_6 font_grey">
                          Task Description
                        </p>
                        <p className="value font_s fw_6">
                          {taskItem?.description}
                        </p>
                      </div>

                      {/* Row 3 */}
                      {taskItem?.work_complete && (
                        <div className="r r2">
                          <p className="label font_s fw_6 font_grey">
                            {/* Work Completed */}
                            Task Completion Report
                          </p>
                          <p className="value font_s fw_6">
                            {taskItem?.work_complete}
                          </p>
                        </div>
                      )}

                      {taskItem?.note && (
                        <div className="r r2">
                          <p className="label font_s fw_6 font_grey">
                            {/* Work Completed */}
                            Task Rejection Note
                          </p>
                          <p className="value font_s fw_6 font_red">
                            {taskItem?.note}
                          </p>
                        </div>
                      )}

                      {taskItem?.document && (
                        <div className="r r2 attachment-row">
                          <p className="label font_s fw_6 font_grey">
                            Attachments
                          </p>
                          {taskItem?.document?.length > 0 && (
                            <div className="value font_s fw_6">
                              {taskItem?.document?.map((mediaItem, index) => (
                                <Image
                                  className="task-attachment"
                                  key={index}
                                  src={mediaItem?.url}
                                  objectFit="cover"
                                  videoThumbnail={mediaItem?.type === "mp4"}
                                  onClick={() => {
                                    setFile(mediaItem);
                                    setShowFile(true);
                                  }}
                                />
                              ))}
                            </div>
                          )}

                          {taskItem?.document?.length === 0 && (
                            <p className="font_yellow">No Attachments Added!</p>
                          )}
                        </div>
                      )}

                      {/* Row 4 */}
                      <div className="r r5 flex-row-2">
                        {/* Status */}
                        <div className="c">
                          <p className="label font_s fw_6 font_grey">Status</p>
                          <p className="value font_s fw_6">
                            <span
                              className={`${
                                taskStatusGenerate(taskItem?.status).color
                              }`}
                            >
                              {taskStatusGenerate(taskItem?.status).text}
                            </span>
                          </p>
                        </div>
                        {/* Action */}
                        {takeAction &&
                          showActionGroup(taskItem, user, UIRCp2) && (
                            <div className="c">
                              <p className="label font_s fw_6 font_grey">
                                Action
                              </p>
                              <div className="value font_s fw_6 button-wrapper">
                                {/* MT responding to task request */}
                                {mtCanRespondP2Task(taskItem, user) && (
                                  <>
                                    <CustomButton
                                      size="m"
                                      variant="outline"
                                      onClick={() => {
                                        setCurrentTaskItem(taskItem);
                                        setIsApproved(true);
                                        setOpenNote(true);
                                      }}
                                    >
                                      Accept
                                    </CustomButton>
                                    <CustomButton
                                      size="m"
                                      variant="red"
                                      onClick={() => {
                                        setCurrentTaskItem(taskItem);
                                        setIsApproved(false);
                                        setOpenNote(true);
                                      }}
                                    >
                                      Reject
                                    </CustomButton>
                                  </>
                                )}

                                {/* MT on the way */}
                                {mtCanChangeArrivalStatus(taskItem, user) && (
                                  <CustomButton
                                    size="m"
                                    variant="outline"
                                    onClick={() => {
                                      changeMTArrivalStatus(
                                        taskItem?._id,
                                        taskItem?.status ===
                                          "accepted_by_technician"
                                          ? "technician_on_the_way"
                                          : "technician_on_the_location"
                                      );
                                    }}
                                  >
                                    {taskItem?.status ===
                                    "accepted_by_technician"
                                      ? "MT On the way"
                                      : "MT Arrived"}
                                  </CustomButton>
                                )}

                                {/* FM re-assigning MT after MT rejects */}
                                {fmCanReAssignPhase2MT(taskItem, user) && (
                                  <CustomButton
                                    size="m"
                                    variant="outline"
                                    onClick={() => {
                                      setCurrentTaskItem(taskItem);
                                      setAssignMT(true);
                                    }}
                                  >
                                    Assign Technician
                                  </CustomButton>
                                )}

                                {/* MT filling UIRC Phase 2 form */}
                                {mtCanFillUIRCP2(taskItem, user) && (
                                  <CustomButton
                                    size="m"
                                    variant="outline"
                                    onClick={() => {
                                      setCurrentTaskItem(taskItem);
                                      setShowUIRCP2Form(true);
                                    }}
                                  >
                                    Complete Task
                                  </CustomButton>
                                )}

                                {/* FM responding to task completed */}
                                {fmCanRespondToTask(taskItem, user) &&
                                  allTaskComplete(UIRCp2) && (
                                    <>
                                      <CustomButton
                                        size="m"
                                        variant="outline"
                                        onClick={() => {
                                          setCurrentTaskItem(taskItem);
                                          setIsApproved(true);
                                          setOpenNote(true);
                                        }}
                                      >
                                        Accept
                                      </CustomButton>
                                      <CustomButton
                                        size="m"
                                        variant="red"
                                        onClick={() => {
                                          setCurrentTaskItem(taskItem);
                                          setIsApproved(false);
                                          setAssignMT(true);
                                        }}
                                      >
                                        Reject
                                      </CustomButton>
                                    </>
                                  )}

                                {/* PM responding to task completed */}
                                {pmCanRespondToTask(taskItem, user) &&
                                  allItemsApprovedByFM(UIRCp2) && (
                                    <>
                                      <CustomButton
                                        size="m"
                                        variant="outline"
                                        onClick={() => {
                                          setCurrentTaskItem(taskItem);
                                          setIsApproved(true);
                                          setOpenNote(true);
                                        }}
                                      >
                                        Accept
                                      </CustomButton>
                                      <CustomButton
                                        size="m"
                                        variant="red"
                                        onClick={() => {
                                          setCurrentTaskItem(taskItem);
                                          setIsApproved(false);
                                          setOpenNote(true);
                                        }}
                                      >
                                        Reject
                                      </CustomButton>
                                    </>
                                  )}
                              </div>
                            </div>
                          )}
                      </div>
                    </div>
                  )
              )}

            {/* If log type is Additional, or if this log contain additional task */}
            {logType.toLowerCase() === "additional" &&
              additionalTasks?.map(
                (taskItem, index) =>
                  console.log(taskItem, "ADD-2") || (
                    <div key={index} className="part">
                      <h3 className="part-title font_m fw_6 font_blue">
                        Task #{index + 1}
                      </h3>
                      {/* Row 1 */}
                      <div className="r r1">
                        <p className="label font_s fw_6 font_grey">
                          Assigned To
                        </p>
                        <p className="value font_s fw_6">
                          {taskItem?.assigned?.first_name}{" "}
                          {taskItem?.assigned?.last_name}
                        </p>
                      </div>

                      {/* Row 2 */}
                      <div className="r r2">
                        <p className="label font_s fw_6 font_grey">
                          Task Description
                        </p>
                        <p className="value font_s fw_6">
                          {taskItem?.description}
                        </p>
                      </div>

                      {/* Row 3 */}
                      {taskItem?.work_complete && (
                        <div className="r r2">
                          <p className="label font_s fw_6 font_grey">
                            {/* Work Completed */}
                            Task Completion Report
                          </p>
                          <p className="value font_s fw_6">
                            {taskItem?.work_complete}
                          </p>
                        </div>
                      )}

                      {taskItem?.note && (
                        <div className="r r2">
                          <p className="label font_s fw_6 font_grey">
                            {/* Work Completed */}
                            Task Rejection Note
                          </p>
                          <p className="value font_s fw_6 font_red">
                            {taskItem?.note}
                          </p>
                        </div>
                      )}

                      {taskItem?.document && (
                        <div className="r r2 attachment-row">
                          <p className="label font_s fw_6 font_grey">
                            Attachments
                          </p>
                          {taskItem?.document?.length > 0 && (
                            <div className="value font_s fw_6">
                              {taskItem?.document?.map((mediaItem, index) => (
                                <Image
                                  className="task-attachment"
                                  key={index}
                                  src={mediaItem?.url}
                                  objectFit="cover"
                                  videoThumbnail={mediaItem?.type === "mp4"}
                                  onClick={() => {
                                    setFile(mediaItem);
                                    setShowFile(true);
                                  }}
                                />
                              ))}
                            </div>
                          )}

                          {taskItem?.document?.length === 0 && (
                            <p className="font_yellow">No Attachments Added!</p>
                          )}
                        </div>
                      )}

                      {/* Row 4 */}
                      <div className="r r5 flex-row-2">
                        {/* Status */}
                        <div className="c">
                          <p className="label font_s fw_6 font_grey">Status</p>
                          <p className="value font_s fw_6">
                            <span
                              className={`${
                                taskStatusGenerate(taskItem?.status).color
                              }`}
                            >
                              {taskStatusGenerate(taskItem?.status).text}
                            </span>
                          </p>
                        </div>
                        {/* Action */}
                        {takeAction &&
                          showActionGroup(taskItem, user, UIRCp2) && (
                            <div className="c">
                              <p className="label font_s fw_6 font_grey">
                                Action
                              </p>
                              <div className="value font_s fw_6 button-wrapper">
                                {/* MT responding to task request */}
                                {mtCanRespondP2Task(taskItem, user) && (
                                  <>
                                    <CustomButton
                                      size="m"
                                      variant="outline"
                                      onClick={() => {
                                        setCurrentTaskItem(taskItem);
                                        setIsApproved(true);
                                        setOpenNote(true);
                                      }}
                                    >
                                      Accept
                                    </CustomButton>
                                    <CustomButton
                                      size="m"
                                      variant="red"
                                      onClick={() => {
                                        setCurrentTaskItem(taskItem);
                                        setIsApproved(false);
                                        setOpenNote(true);
                                      }}
                                    >
                                      Reject
                                    </CustomButton>
                                  </>
                                )}

                                {/* MT on the way */}
                                {mtCanChangeArrivalStatus(taskItem, user) && (
                                  <CustomButton
                                    size="m"
                                    variant="outline"
                                    onClick={() => {
                                      changeMTArrivalStatus(
                                        taskItem?._id,
                                        taskItem?.status ===
                                          "accepted_by_technician"
                                          ? "technician_on_the_way"
                                          : "technician_on_the_location"
                                      );
                                    }}
                                  >
                                    {taskItem?.status ===
                                    "accepted_by_technician"
                                      ? "MT On the way"
                                      : "MT Arrived"}
                                  </CustomButton>
                                )}

                                {/* FM re-assigning MT after MT rejects */}
                                {fmCanReAssignPhase2MT(taskItem, user) && (
                                  <CustomButton
                                    size="m"
                                    variant="outline"
                                    onClick={() => {
                                      setCurrentTaskItem(taskItem);
                                      setAssignMT(true);
                                    }}
                                  >
                                    Assign Technician
                                  </CustomButton>
                                )}

                                {/* MT filling UIRC Phase 2 form */}
                                {mtCanFillUIRCP2(taskItem, user) && (
                                  <CustomButton
                                    size="m"
                                    variant="outline"
                                    onClick={() => {
                                      setCurrentTaskItem(taskItem);
                                      setShowUIRCP2Form(true);
                                    }}
                                  >
                                    Complete Task
                                  </CustomButton>
                                )}

                                {/* FM responding to task completed */}
                                {fmCanRespondToTask(taskItem, user) &&
                                  allTaskComplete(UIRCp2) && (
                                    <>
                                      <CustomButton
                                        size="m"
                                        variant="outline"
                                        onClick={() => {
                                          setCurrentTaskItem(taskItem);
                                          setIsApproved(true);
                                          setOpenNote(true);
                                        }}
                                      >
                                        Accept
                                      </CustomButton>
                                      <CustomButton
                                        size="m"
                                        variant="red"
                                        onClick={() => {
                                          setCurrentTaskItem(taskItem);
                                          setIsApproved(false);
                                          setAssignMT(true);
                                        }}
                                      >
                                        Reject
                                      </CustomButton>
                                    </>
                                  )}

                                {/* PM responding to task completed */}
                                {pmCanRespondToTask(taskItem, user) &&
                                  allItemsApprovedByFM(UIRCp2) && (
                                    <>
                                      <CustomButton
                                        size="m"
                                        variant="outline"
                                        onClick={() => {
                                          setCurrentTaskItem(taskItem);
                                          setIsApproved(true);
                                          setOpenNote(true);
                                        }}
                                      >
                                        Accept
                                      </CustomButton>
                                      <CustomButton
                                        size="m"
                                        variant="red"
                                        onClick={() => {
                                          setCurrentTaskItem(taskItem);
                                          setIsApproved(false);
                                          setOpenNote(true);
                                        }}
                                      >
                                        Reject
                                      </CustomButton>
                                    </>
                                  )}
                              </div>
                            </div>
                          )}
                      </div>
                    </div>
                  )
              )}
          </div>
        </div>

        {/* Log Footer */}
        <div className="log-footer">
          {takeAction && (
            <div className="button-wrapper">
              {/* PM can approve quote */}
              {pmCanApproveQuote(item, user) && (
                <>
                  <CustomButton
                    onClick={() => {
                      setIsApproved(true);
                      setOpenNote(true);
                    }}
                    size="m"
                    variant="yellow"
                  >
                    Approve
                  </CustomButton>
                  <CustomButton
                    onClick={() => {
                      setIsApproved(false);
                      setOpenNote(true);
                    }}
                    variant="red"
                    size="m"
                  >
                    Reject
                  </CustomButton>
                </>
              )}
            </div>
          )}
        </div>
      </div>

      {/* Modals */}
      {/* Adding Note Modal */}
      <NoteModal
        showModal={openNote}
        title="Note"
        btnText={isApproved ? "Approve" : "Reject"}
        onCancel={() => setOpenNote(false)}
        actionWithNote={respondAction}
      />

      {/* Assign Technician Modal */}
      {takeAction && (
        <AssignTechnicianMoveInModal
          showModal={assignMT}
          maintenanceType={maintenanceType}
          callBack={assignMTMasterFunction}
          taskHeading={false}
          property={property}
          onClose={() => {
            setAssignMT(false);
            setCurrentTaskItem(null);
          }}
        />
      )}

      {/* UIRC Phase 2 Modal */}
      <UIRCP2
        maintenance_id={maintenance_id}
        maintenanceType={maintenanceType}
        showModal={showUIRCP2Form}
        UIRCp2={UIRCp2}
        onClose={() => {
          setShowUIRCP2Form(false);
          setCurrentTaskItem(null);
        }}
        currentTaskItem={currentTaskItem}
        setCurrentTaskItem={setCurrentTaskItem}
      />

      {/* File Viewer Modal */}
      <FileViewerModal
        show={showFile}
        file={file.url}
        type={file.type}
        onClose={() => setShowFile(false)}
      />
    </div>
  );
};
/* Component ends */

export default TaskLog;
