/**
 *
 */

import React, { useState, useEffect, useRef } from "react";

/* Import configuration starts */
import { Formik } from "formik";
import { useSelector, useDispatch } from "react-redux";
import { formatCurrency } from "../../../../setup/utils/global-helper";
import GLOBAL from "../../../../setup/constants/global";
import { useParams } from "react-router-dom";
import { fieldValue, validation, prefill } from "./helper";
/* Import configuration ends */

/* Import redux slices component starts */
import {
  getUser,
  getUserTheme,
} from "../../../../setup/store/slices/authSlice";
import {
  showSuccessAlert,
  showErrorAlert,
} from "../../../../setup/store/slices/globalAlertSlice";
import {
  FMrespondReadinessCard,
  fmRespondFinalCard,
} from "../../../../setup/store/slices/moveInSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
import { Modal } from "react-bootstrap";
/* Import react bootstrap component ends */

/* Import image and SVG starts */
import {
  RandomProperty,
  XGreen,
  XWhite,
} from "../../../../setup/constants/images";
/* Import image and SVG ends */

/* Import local pages and component starts */
import Image from "../../../ui/other/Image/Image";
import CustomCurrencyInput from "../../../ui/input/CustomCurrencyInput/CustomCurrencyInput";
import CustomButton from "../../../ui/button/CustomButton/CustomButton";
import CustomTextInput from "../../../ui/input/CustomTextInput/CustomTextInput";
import CustomNumberInput from "../../../ui/input/CustomNumberInput/CustomNumberInput";
import FileViewerModal from "../../../ui/modal/FileViewerModal/FileViewerModal";
/* Import local pages and component ends */

import "./style.scss";

/* Component starts */
const UIRCEstimationReviseModal = (props) => {
  /* Props destructuring starts */
  const {
    maintenance_id,
    showModal,
    size = "lg",
    onClose,
    reviewByFM,
    data = null,
    maintenance,
    item,
    setFile,
    setShowFile,
    setShowUIRCRviewModal,
    logType,
    currency,
  } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [formValue, setFormValue] = useState(fieldValue);
  const [desc, setDesc] = useState([]);
  const [totalEstimationValue, setTotalEstimationValue] = useState(0);
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const dispatch = useDispatch();
  const formRef = useRef();
  const { propertyId } = useParams();

  // Redux Selector
  const user = useSelector(getUser);
  const userTheme = useSelector(getUserTheme);
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  const docs = item?.data?.document;
  const uircItems = item?.data?.items;
  const initialURIC = maintenance?.initial_URIC;
  const finalUIRC = maintenance?.final_URIC;
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  const onSubmit = async (values) => {
    onClose();
    // Creating or collecting payload data to be sent
    const payload = {
      maintenance_id,
      type: maintenance?.type,
      log_id: item?._id,
      status: "Approved",
    };
    if (data) {
      const items = [];
      data?.items?.forEach((item, index) => {
        items.push({
          item_id: item?._id,
          estimate: values[`estimate_${index}`],
        });
      });
      payload["items"] = JSON.stringify(items);
    }
    if (logType === "URIC Initial") {
      payload["readiness_card"] = initialURIC?._id;
    } else {
      payload["readiness_card"] = finalUIRC?._id;
    }
    console.log("Payload", payload);

    // Backend response. Try, Catch
    try {
      let result = {};
      if (logType === "URIC Initial") {
        result = await dispatch(FMrespondReadinessCard(payload));
      } else {
        result = await dispatch(fmRespondFinalCard(payload));
      }
      console.log("Response", result);

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          dispatch(
            showErrorAlert({
              title: "Error!",
              description: result?.payload || "",
            })
          );
          break;

        case GLOBAL.FULFILLED:
          dispatch(
            showSuccessAlert({
              title: "Success!",
              description: result?.payload?.message || "",
            })
          );
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
    console.log(data);
    console.log(values);
  };
  /* Component function definition ends */

  /* Component useEffect starts */
  useEffect(() => {
    if (data && data?.items?.length > 0) {
      prefill(data?.items, setFormValue, setDesc, setTotalEstimationValue);
    }
  }, [data, maintenance]);
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <Modal
      size={size}
      centered
      show={showModal}
      className="uirc-p1-review-modal-wrapper"
    >
      <Modal.Body
        className={`${userTheme === "dark" ? "bg_darkTheme" : "bg_lightTheme"}`}
      >
        <div className="modal-header-custom">
          <p
            className={`font_l fw_6 ${
              userTheme !== "dark" && "font_blue"
            } font_white text-center`}
          >
            UIRC Estimation Review
          </p>
        </div>
        <div className="modal-body-custom">
          <Formik
            onSubmit={onSubmit}
            initialValues={formValue}
            validate={(values) =>
              validation(values, desc, setTotalEstimationValue)
            }
            validateOnBlur={true}
            enableReinitialize={true}
            innerRef={formRef}
          >
            {({
              values,
              errors,
              touched,
              handleBlur,
              handleChange,
              handleSubmit,
              setFieldValue,
              setFieldTouched,
            }) => (
              <form onSubmit={handleSubmit}>
                {/* Form Fields */}
                <div className="form-wrapper form-wrapper-flex">
                  {/* Description and Estimate */}
                  <div className="w_100 description">
                    {!reviewByFM && (
                      <h3 className="font_blue font_m fw_5">
                        Task Description
                      </h3>
                    )}
                    {desc?.length === 0 && (
                      <h3 className="font_yellow font_m fw_5 text-center attachments-not-added">
                        No Task Description added !
                      </h3>
                    )}
                    {desc.map((item, index) => (
                      <>
                      <div className="uirc-item">
                        <div className="item-wrapper" key={index}>
                          <p className="title-number font_s font_blue">
                            Task #{index + 1}
                          </p>
                          <div className="form-group-full">
                            <CustomTextInput
                              label="Task Description"
                              name={`description_${item}`}
                              handleBlur={handleBlur}
                              handleChange={handleChange}
                              value={values[`description_${item}`]}
                              touched={touched[`description_${item}`]}
                              errors={errors[`description_${item}`]}
                              readOnly={true}
                            />
                          </div>

                          <div className="form-group">
                            <CustomCurrencyInput
                              label={"Estimate (" + currency + ")"}
                              setFieldValue={setFieldValue}
                              setFieldTouched={setFieldTouched}
                              name={`estimate_${item}`}
                              value={values[`estimate_${item}`]}
                              touched={touched[`estimate_${item}`]}
                              errors={errors[`estimate_${item}`]}
                              currency={currency}
                              required={true}
                            />
                            {/* <CustomNumberInput
                              label="Estimate"
                              name={`estimate_${item}`}
                              handleBlur={handleBlur}
                              handleChange={handleChange}
                              value={values[`estimate_${item}`]}
                              touched={touched[`estimate_${item}`]}
                              errors={errors[`estimate_${item}`]}
                            /> */}
                          </div>
                        </div>

                        <div className="attachment w_100">
                          <h3 className="font_blue font_m fw_5">Attachments</h3>
                          {uircItems[index]?.documents?.documents?.length ===
                            0 && (
                            <h3 className="font_yellow font_m fw_5 text-center attachments-not-added">
                              No attachment added !
                            </h3>
                          )}

                          {uircItems[index]?.documents?.length > 0 && (
                            <div className="attachment-wrapper">
                              {uircItems[index]?.documents?.map(
                                (docItem, docIndex) => (
                                  <>
                                    <Image
                                      key={docIndex}
                                      imgClass="thumbnail"
                                      src={docItem?.url}
                                      videoThumbnail={
                                        docItem?.type2 === "video"
                                      }
                                      className="attachment"
                                      alt="Attatched Media"
                                      objectFit="cover"
                                      onClick={() => {
                                        setFile(docItem);
                                        setShowFile(true);
                                        setShowUIRCRviewModal(false);
                                      }}
                                    />
                                    {/* <div
                                    onClick={() => {
                                      setFile(docItem);
                                      setShowFile(true);
                                      setShowUIRCRviewModal(false);
                                    }}
                                    style={{
                                      backgroundImage: `url("${docItem.url}")`,
                                    }}
                                    key={docIndex}
                                    className="attachment"
                                  ></div> */}
                                  </>
                                )
                              )}
                            </div>
                          )}
                        </div>
                      </div>

                      <hr />
                      </>
                    ))}
                  </div>
                </div>

                <div className="total-wrapper">
                  <div className="left">
                    <p className="font_m">
                      <span className="font_blue font_m fw_5">Total : </span>
                      <span className="font_blue font_m fw_5">
                        {formatCurrency(totalEstimationValue, currency)}
                      </span>
                    </p>
                  </div>
                </div>

                {/* Submit and Cancel */}
                <div className="button-wrapper">
                  <>
                    <CustomButton
                      variant="red"
                      size="m"
                      onClick={onClose}
                      className="btn1"
                    >
                      Cancel
                    </CustomButton>
                    <CustomButton type="submit" size="m">
                      Submit
                    </CustomButton>
                  </>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </Modal.Body>
    </Modal>
  );
};
/* Component ends */

export default UIRCEstimationReviseModal;
