/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import GLOBAL from "../../../../setup/constants/global";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment/moment";
import { formatCurrency } from "../../../../setup/utils/global-helper";
import { useParams } from "react-router-dom";
/* Import configuration ends */

/* Import redux slices component starts */
import { setLoading } from "../../../../setup/store/slices/unpersistedSlice";
import {
  fetchPropertyDetailByID,
  getPropertyDetail,
} from "../../../../setup/store/slices/propertySlice";
import { getUserTheme } from "../../../../setup/store/slices/authSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
import { Modal } from "react-bootstrap";
/* Import react bootstrap component ends */

/* Import image and SVG starts */
import {
  EyeFilledBlue,
  XGreen,
  XWhite,
} from "../../../../setup/constants/images";
/* Import image and SVG ends */

/* Import local pages and component starts */
import IconButton from "../../button/IconButton/IconButton";
import FileViewerModal from "../FileViewerModal/FileViewerModal";
/* Import local pages and component ends */

import "./style.scss";

/* Component starts */
const ContractDetailModal = (props) => {
  /* Props destructuring starts */
  const { showModal, detail, size = "md", onClose, user } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [file, setFile] = useState(null);
  const [showFileViewer, setShowFileViewer] = useState(false);
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const dispatch = useDispatch();
  const { propertyId } = useParams();
  const property = useSelector(getPropertyDetail);
  const userTheme = useSelector(getUserTheme);
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  const business_name = detail?.business_name;
  const contractStartDate = detail?.contract_start_date;
  const contractEndDate = detail?.contract_end_date;
  const contractCopyImg = detail?.contract_copy_img;
  const contractNumber = detail?.contract_number;
  const contractValue = detail?.contract_value;

  const USER_DATA = localStorage.getItem("user_data")
    ? JSON.parse(localStorage.getItem("user_data"))
    : null;
  const DATE_FORMAT = USER_DATA ? USER_DATA?.date_format : "";
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  // On viewing file
  const viewFile = (file) => {
    setFile(file);
    setShowFileViewer(true);
  };
  /* Component function definition ends */

  /* Component useEffect starts */
  useEffect(() => {
    (async () => {
      try {
        await dispatch(setLoading(true));
        await dispatch(fetchPropertyDetailByID({ property_id: propertyId }));
        await dispatch(setLoading(false));
      } catch (e) {}
    })();
  }, []);
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  if (!showFileViewer)
    return (
      <Modal
        size={size}
        centered
        show={showModal}
        className="contract-detail-modal-wrapper"
      >
        <Modal.Body
          className={`custom-modal-body ${
            userTheme === "dark" ? "bg_darkTheme" : "bg_lightTheme"
          }`}
        >
          {/* Header */}
          <div className="close-button-wrapper">
            <IconButton onClick={onClose} className="close-button">
              <img className="h_100" src={XGreen} alt="Close" />
            </IconButton>
          </div>

          {/* Body */}
          <div className="details-content">
            <div className="right">
              <div
                //className="wrapper bg_grey"
                className={`wrapper ${
                  userTheme === "dark" ? "bg_grey" : "bg_lightThemeDetail"
                }`}
              >
                {/* Business Name */}
                {business_name && (
                  <div className="custom_row">
                    <p className="key font_grey font_s">Business Name</p>
                    <p
                      className={`val font_s ${
                        (userTheme === "light" || userTheme === "automatic") &&
                        "font_lightTheme"
                      } `}
                    >
                      {business_name}
                    </p>
                  </div>
                )}

                {/* Contract Start Date */}
                {contractStartDate && (
                  <div className="custom_row">
                    <p className="key font_grey font_s">Contract Start Date</p>
                    <p
                      className={`val font_s ${
                        (userTheme === "light" || userTheme === "automatic") &&
                        "font_lightTheme"
                      } `}
                    >
                      {moment(contractStartDate).format(DATE_FORMAT)}
                    </p>
                  </div>
                )}

                {/* Contract End Date */}
                {contractEndDate && (
                  <div className="custom_row">
                    <p className="key font_grey font_s">Contract End Date</p>
                    <p
                      className={`val font_s ${
                        (userTheme === "light" || userTheme === "automatic") &&
                        "font_lightTheme"
                      } `}
                    >
                      {moment(contractEndDate).format(DATE_FORMAT)}
                    </p>
                  </div>
                )}

                {/* Contract Copy Image */}
                {contractCopyImg && (
                  <div className="custom_row">
                    <p className="key font_grey font_s">Contract Copy Image</p>
                    <p className="val font_s">
                      <IconButton
                        onClick={() => viewFile(contractCopyImg)}
                        className="view"
                      >
                        <img className="h_100" src={EyeFilledBlue} alt="" />
                      </IconButton>
                    </p>
                  </div>
                )}

                {/* Contract Number */}
                {contractNumber && (
                  <div className="custom_row">
                    <p className="key font_grey font_s">Contract Number</p>
                    <p className="val font_blue font_s">{contractNumber}</p>
                  </div>
                )}

                {/* Contract Value */}
                {contractValue && (
                  <div className="custom_row">
                    <p className="key font_grey font_s">Contract Value</p>
                    <p className="val font_blue font_s">
                      {formatCurrency(contractValue, property.currency)}
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );

  if (showFileViewer)
    return (
      <FileViewerModal
        show={showFileViewer}
        file={file?.url}
        type={file?.type}
        onClose={() => {
          setShowFileViewer(false);
          setFile(null);
        }}
      />
    );
};
/* Component ends */

export default ContractDetailModal;
