/**
 *
 */

import React, { useState, useEffect, useRef } from "react";

/* Import configuration starts */
import { Formik } from "formik";
import { typeList, utilityList, validations } from "../helper";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import GLOBAL from "../../../../../../setup/constants/global";
// import { initialValues, validations } from "./helper";
/* Import configuration ends */

/* Import redux slices component starts */
import { setLoading } from "../../../../../../setup/store/slices/unpersistedSlice";
import {
  fetchTenantUnits,
  getTenantUnits,
} from "../../../../../../setup/store/slices/tenantInfoSlice";
import { addTenantInvoice } from "../../../../../../setup/store/slices/invoiceSlice";
import {
  showSuccessAlert,
  showErrorAlert,
} from "../../../../../../setup/store/slices/globalAlertSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */

// import GLOBAL from "../../../../setup/constants/global";
import CustomTextInput from "../../../../../ui/input/CustomTextInput/CustomTextInput";
import CustomDateInput from "../../../../../ui/input/CustomDateInput/CustomDateInput";
import CustomButton from "../../../../../ui/button/CustomButton/CustomButton";
import TextButton from "../../../../../ui/button/TextButton/TextButton";
import CustomTextArea from "../../../../../ui/input/CustomTextArea/CustomTextArea";
import CustomSelectInput from "../../../../../ui/input/CustomSelectInput/CustomSelectInput";
import CustomNumberInput from "../../../../../ui/input/CustomNumberInput/CustomNumberInput";
import CustomCurrencyInput from "../../../../../ui/input/CustomCurrencyInput/CustomCurrencyInput";
import { getUserCurrency } from "../../../../../../setup/store/slices/authSlice";
import {
  fetchPropertyDetailByID,
  fetchPropertySettings,
  getPropertyDetail,
  getPropertySettings,
} from "../../../../../../setup/store/slices/propertySlice";
import CustomCheckInput from "../../../../../ui/input/CustomCheckInput/CustomCheckInput";
/* Import local pages and component ends */

// Styles
// import "./style.scss";

/* Component starts */

const CreateTenantInvoice = (props) => {
  /* Props destructuring starts */

  const { onSuccess, onCancel, type, moveout, unitId, state } = props;

  /* Props destructuring ends */

  let initFormValues = {
    invoice_num: "",
    unit_number: "",
    description: "",
    quantity: "",
    total: "",
    type: "",
    utility: "",
  };

  /* Component states and useRef declaration starts */
  const [collectVat, setCollectVat] = useState(false);
  const [collectTax, setCollectTax] = useState(false);
  const [formRefValues, setFormRefValue] = useState({});
  const [initialFormValues, setInitialFormValues] = useState(initFormValues);
  const [showUtilityList, setShowUtilityList] = useState(false);
  const [tenantName, setTenantName] = useState({});
  const [unitName, setUnitName] = useState();
  const [typeValue, setTypeValue] = useState([]);
  // const [showSuccessModal, setShowSuccessModal] = useState(false);
  // const [modalDesc, setModalDesc] = useState("");
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const unitList = useSelector(getTenantUnits);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const currency = useSelector(getUserCurrency);
  const propertyDetail = useSelector(getPropertyDetail);
  const { propertyId } = useParams();
  const settings = useSelector(getPropertySettings);
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */

  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  const changeFormVal = (key, value) => {
    const f = { ...formRefValues };
    f[key] = value;
    setInitialFormValues(f);
  };

  const onSubmit = async (values) => {
    // Creating or collecting payload data to be sent
    const payload = {
      property_id: propertyId,
      unit_id: state?.fromInvoice ? state?.unitID : values.unit_number,
      tenant_id: state?.fromInvoice ? state?.tenantId : tenantName._id || "",
      type: state?.fromInvoice ? "other" : values?.type?.toLowerCase(),
      description: values.description,
      // quantity: values.quantity,
      total: values.total,
      collect_tax: collectTax,
      collect_vat: collectVat,
    };

    if (state?.applicationId) {
      payload["application_id"] = state?.applicationId;
    }

    console.log(payload, "[PAY]");

    if (showUtilityList) {
      payload["utility_name"] = values?.utility;
    }

    // Backend response. Try, Catch
    try {
      const result = await dispatch(addTenantInvoice(payload));

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          dispatch(
            showErrorAlert({
              title: "Error!",
              description: result.payload,
            })
          );
          break;

        case GLOBAL.FULFILLED:
          dispatch(
            showSuccessAlert({
              title: "Success!",
              description: result.payload.message,
            })
          );
          navigate(-1);
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };
  /* Component function definition ends */

  /* Component useEffect starts */
  useEffect(() => {
    dispatch(
      fetchTenantUnits({
        property_id: propertyId,
      })
    );
  }, [propertyId]);

  useEffect(() => {
    if (formRefValues?.type === "Utility") {
      setShowUtilityList(true);
    } else {
      setShowUtilityList(false);
    }
    // *******

    if (formRefValues?.unit_number && unitList?.length > 0) {
      const name = unitList?.filter(
        (item) => item?._id === formRefValues?.unit_number
      )[0]?.tenant;

      setTenantName(name);
      setUnitName();
    } else {
      setTenantName({});
    }
    // *******
    if (moveout || state?.fromInvoice) {
      changeFormVal("unit_number", unitId);
    }
  }, [formRefValues]);

  useEffect(() => {
    if (state !== undefined) {
      const name = unitList?.filter((item) => item?._id === state?.unitID)[0]
        ?.tenant;
      const unit = unitList?.filter((item) => item?._id === state?.unitID);

      setTenantName(name);
      setUnitName(`${unit[0]?.unit_name} - ${unit[0]?.unit_number}`);
    }
  }, [state, unitList]);

  useEffect(() => {
    if (propertyId) {
      (async () => {
        try {
          await dispatch(setLoading(true));
          await dispatch(fetchPropertyDetailByID(propertyId));
          await dispatch(fetchPropertySettings({ property_id: propertyId }));
          await dispatch(setLoading(false));
        } catch (e) {}
      })();
    }
  }, [propertyId]);

  useEffect(() => {
    if (propertyDetail?.mgmt_model === 0 || propertyDetail?.mgmt_model === 1) {
      setTypeValue([{ name: "other" }]);
    } else {
      setTypeValue([
        { name: "utility" },
        { name: "shared facility" },
        { name: "other" },
      ]);
    }
  }, [propertyDetail]);

  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */

  return (
    <div className="add-tenant-invoice-module-wrapper">
      <h2 className="heading text-center font_xxl mb-5">
        Create {type} Invoice
      </h2>

      <div className="add-new-form">
        <Formik
          onSubmit={onSubmit}
          initialValues={initialFormValues}
          validate={(values) =>
            validations(values, showUtilityList, state?.fromInvoice)
          }
          validateOnBlur={true}
          innerRef={(d) =>
            d ? setFormRefValue(d.values) : setFormRefValue({})
          }
          enableReinitialize={true}
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
            setFieldValue,
            setFieldTouched,
          }) => (
            <form onSubmit={handleSubmit}>
              <div className="form-wrapper">
                {/* Creating Invoice */}
                <>
                  <div className="form-group form-group-full tenant-detail">
                    <p className="font_m fw_4 font_grey">Tenant Name</p>
                    <p className="font_m fw_4">
                      {tenantName?.first_name} {tenantName?.last_name}
                    </p>
                  </div>

                  <div className="form-group">
                    {state?.fromInvoice ? (
                      <CustomTextInput
                        label="Unit Number"
                        value={unitName}
                        required={true}
                        // readOnly={true}
                        disabled={true}
                      />
                    ) : (
                      <CustomSelectInput
                        name="unit_number"
                        label="Unit Number"
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        valueExtractor={(item) =>
                          `${item?.unit_name} - ${item?.unit_number}`
                        }
                        optionValueExtractor={(item) => item?._id}
                        options={unitList}
                        touched={touched.unit_number}
                        errors={errors.unit_number}
                        value={unitName}
                        disabled={moveout}
                      />
                    )}
                  </div>

                  <div className="form-group">
                    {state?.fromInvoice ? (
                      <CustomTextInput
                        label="Type"
                        value={state?.fromInvoice && "Other"}
                        required={true}
                        // readOnly={true}
                        disabled={true}
                      />
                    ) : (
                      <CustomSelectInput
                        name="type"
                        label="Type"
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        valueExtractor={(item) =>
                          item?.name?.charAt(0).toUpperCase() +
                          item?.name?.slice(1)
                        }
                        options={typeValue}
                        value={values.type}
                        touched={touched.type}
                        errors={errors.type}
                      />
                    )}
                  </div>

                  <div className="form-group">
                    {showUtilityList && (
                      <CustomSelectInput
                        name="utility"
                        label="Utility Type"
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        valueExtractor={(item) => item}
                        optionValueExtractor={(item) => item}
                        options={utilityList}
                        touched={touched.utility}
                        errors={errors.utility}
                        value={values.utility}
                      />
                    )}
                  </div>

                  {/* Text Area for Description */}
                  <div className="form-group form-group-full">
                    <CustomTextArea
                      name="description"
                      label="Description"
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      value={values.description}
                      errors={errors.description}
                      touched={touched.description}
                      required={true}
                    />
                  </div>

                  {/* Input for Quantity */}
                  {/* <div className="form-group">
                    <CustomNumberInput
                      name="quantity"
                      label="Quantity"
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      touched={touched.quantity}
                      errors={errors.quantity}
                      value={values.quantity}
                    />
                  </div> */}

                  {/* Input for Total */}
                  {/* <div className="form-group">
                    <CustomNumberInput
                      name="total"
                      label="Total"
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      touched={touched.total}
                      errors={errors.total}
                      value={values.total}
                    />
                  </div> */}

                  <div className="form-group">
                    <CustomCurrencyInput
                      name="total"
                      label={`Total (${propertyDetail?.currency})`}
                      setFieldValue={setFieldValue}
                      setFieldTouched={setFieldTouched}
                      touched={touched.total}
                      errors={errors.total}
                      value={values.total}
                      currency={propertyDetail?.currency}
                      required={true}
                    />

                    {(settings?.collect_tax_enabled ||
                      settings?.collect_vat_enabled) && (
                      <>
                        {settings?.collect_tax_enabled && (
                          <div className="check">
                            <CustomCheckInput
                              value={collectTax}
                              setValue={setCollectTax}
                            />
                            <span className="font_s font_grey check-text">
                              Collect Tax ({settings?.collect_tax_value}%)
                            </span>
                          </div>
                        )}

                        {settings?.collect_vat_enabled && (
                          <div className="check">
                            <CustomCheckInput
                              value={collectVat}
                              setValue={setCollectVat}
                            />
                            <span className="font_s font_grey check-text">
                              Collect VAT ({settings?.collect_vat_value}%)
                            </span>
                          </div>
                        )}
                      </>
                    )}
                  </div>

                  {/* Input for Create Date */}
                  {/* <div className="form-group">
                    <CustomDateInput
                      label="Create Date"
                      value={createDate}
                      minDate={false}
                      customOnchange={(date) => setCreateDate(date)}
                      required={true}
                    />
                  </div> */}

                  {/* Input for End Date */}
                  {/* <div className="form-group">
                    <CustomDateInput
                      label="Due Date"
                      value={dueDate}
                      minDate={false}
                      customOnchange={(date) => setDueDate(date)}
                      required={true}
                    />
                  </div> */}
                </>
              </div>

              <div className="button-wrapper text-center">
                <>
                  <CustomButton type="submit" size="l">
                    Create
                  </CustomButton>
                  <TextButton
                    type="button"
                    onClick={onCancel}
                    className="font_m fw_6"
                  >
                    Cancel
                  </TextButton>
                </>
              </div>
            </form>
          )}
        </Formik>
      </div>
    </div>
  );
};
/* Component ends */

export default CreateTenantInvoice;
