/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import { useParams } from "react-router-dom";
import moment from "moment";
import { logTitle, canMTMarkJobComplete } from "../helper";
import { useDispatch } from "react-redux";
import GLOBAL from "../../../../../setup/constants/global";
/* Import configuration ends */

/* Import redux slices component starts */
import {
  showSuccessAlert,
  showErrorAlert,
} from "../../../../../setup/store/slices/globalAlertSlice";
import {
  t2TechnicianMarkJobComplete,
  technicianMarkJobComplete,
} from "../../../../../setup/store/slices/maintenanceSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import CustomButton from "../../../../ui/button/CustomButton/CustomButton";
/* Import local pages and component ends */

/* Component starts */
const HandoverItemLog = (props) => {
  /* Props destructuring starts */
  const {
    isT1,
    status,
    maintenance,
    item,
    takeAction,
    maintenance_id,
    property,
    user,
    isT2,
  } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [showNote, setShowNote] = useState(false);
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const dispatch = useDispatch();
  const { propertyId } = useParams();
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  const taskList = maintenance?.phase2_URIC?.items;
  const name = item?.requester
    ? `${item?.requester?.first_name} ${item?.requester?.last_name}`
    : `${item.tenant.first_name} ${item.tenant.last_name}`;
  const role = item?.requester_role?.name;
  const createdAt = item?.createdAt;
  const action = item?.action;
  const currentStatus = item?.current_status;
  const logType = item?.log_type;
  const comment = item?.comment;
  const maintenanceType = maintenance?.type;
  const UIRCp2 = maintenance?.phase2_URIC;

  const itemHandOverReport = item?.data?.items_to_handover || item?.data?.items;

  const USER_DATA = localStorage.getItem("user_data")
    ? JSON.parse(localStorage.getItem("user_data"))
    : null;
  const DATE_FORMAT = USER_DATA ? USER_DATA?.date_format : "";
  const TIME_FORMAT = USER_DATA ? USER_DATA?.time_format : "";
  const DATE_TIME_FORMAT = `${DATE_FORMAT} ${TIME_FORMAT}`;
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  // On MT mark job complete
  const mtMarkJobComplete = async () => {
    // Creating or collecting payload data to be sent
    const payload = {
      maintenance_id,
      type: maintenanceType,
    };

    // Backend response. Try, Catch
    try {
      let result;
      if (isT2) {
        result = await dispatch(t2TechnicianMarkJobComplete(payload));
      } else {
        result = await dispatch(technicianMarkJobComplete(payload));
      }

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          dispatch(
            showErrorAlert({
              title: "Error!",
              description: result.payload,
            })
          );
          break;

        case GLOBAL.FULFILLED:
          dispatch(
            showSuccessAlert({
              title: "Success!",
              description: result.payload.message,
            })
          );
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };
  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="time-line spare-part-log">
      {/* Dot */}

      <div className="dot-wrapper">
        <i className="dot"></i>
        <div className="vl"></div>
      </div>

      <div className="detail-wrapper">
        {/* Title */}
        <h3 className="title font_m fw_6">{logTitle(item)}</h3>

        {/* Technician Name and Date */}
        <div className="black-container-sm fw_5 font_s bg_black flex-between">
          <p className="left">
            <span className="font_grey">{role} </span>
            <span className="font_white"> {name}</span>
          </p>
          <p className="right font_grey">
            {moment(createdAt).format(DATE_TIME_FORMAT)}
          </p>
        </div>

        {/* Task List */}
        <div className="spare-part-quote black-container-lg fw_5 font_s">
          <p className="sub-title font_m fw_6">Items</p>

          <div className="part-list bg_black">
            {/* If log type is not Additional, or if this log does not contain additional task */}
            {itemHandOverReport?.map((part, index) => (
              <div key={index} className="part">
                <h3 className="part-title font_m fw_6 font_blue">
                  Item #{index + 1}
                </h3>
                {/* Row 1 */}
                <div className="r r1">
                  <p className="value font_s fw_6">{part}</p>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Log Footer */}
        <div className="log-footer">
          {takeAction && (
            <div className="button-wrapper">
              {/* MT mark job complete */}
              {canMTMarkJobComplete(item, user) && (
                <CustomButton
                  onClick={() => mtMarkJobComplete()}
                  variant="yellow"
                  size="m"
                >
                  Mark Job Complete
                </CustomButton>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
/* Component ends */

export default HandoverItemLog;
