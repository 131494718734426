/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import moment from "moment";
import GLOBAL from "../../../../setup/constants/global";
import { joinAddress } from "../../../../setup/utils/global-helper";
/* Import configuration ends */

/* Import redux slices component starts */
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
import { LogoPrimary } from "../../../../setup/constants/images";
/* Import image and SVG ends */

/* Import local pages and component starts */
import PaymentMethod from "../../Payment/PaymentMethod/PaymentMethod";
import TransactionStatus from "../../Payment/TransactionStatus/TransactionStatus";
import UserCardDetail from "../../../ui/other/UserCardDetail/UserCardDetail";
/* Import local pages and component ends */

import "./style.scss";

/* Component starts */
const ClientInvoiceReceipt = (props) => {
  /* Props destructuring starts */
  const { invoice, settings, user } = props;

  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [duration, setDuration] = useState("");
  const [transactionStatus, setTransactionStatus] = useState({});
  const [showTransactionStatus, setShowTransactionStatus] = useState(false);
  const [selectedCard, setSelectedCard] = useState({});
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */

  const USER_DATA = localStorage.getItem("user_data")
    ? JSON.parse(localStorage.getItem("user_data"))
    : null;
  const DATE_FORMAT = USER_DATA ? USER_DATA?.date_format : "";
  const TIME_FORMAT = USER_DATA ? USER_DATA?.time_format : "";
  const DATE_TIME_FORMAT = `${DATE_FORMAT} ${TIME_FORMAT}`;

  const billingName = `${user?.first_name} ${user?.last_name}`;
  const billingAddress = joinAddress({
    address1: user?.address_line1,
    address2: user?.address_line2,
    city: user?.city,
    state: user?.state,
    country: user?.country,
  });
  const number = invoice?.invoice_no;
  const invoiceType =
    invoice?.type?.charAt(0).toUpperCase() + invoice?.type?.slice(1);
  const transactionId = invoice?.transaction_details?.transaction_id;
  const date =
    invoice?.invoice_date && moment(invoice.invoice_date).format(DATE_FORMAT);
  const dueDate =
    invoice?.due_date && moment(invoice.due_date).format(DATE_FORMAT);
  const status = invoice?.status;
  const licenseCount = invoice?.number_of_license;
  const totalAmt = invoice?.total_amount?.toLocaleString("en-US") + " USD";
  const taxAmt = invoice?.tax_amount + " USD";
  const excludeTaxAmt = invoice?.total_amount - parseInt(invoice?.tax_amount);
  const isProrated =
    parseInt(settings?.perUnitPrice) !== parseInt(invoice.total_amount);
  const receiptDate =
    status === GLOBAL.PAID &&
    moment.unix(invoice.transaction_details.created).format(DATE_TIME_FORMAT);

  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  /* Component function definition ends */

  /* Component useEffect starts */
  useEffect(() => {
    if (invoice.invoice_date) {
      let invoiceDuration = "";
      if (
        moment(invoice.invoice_date)
          .endOf("month")
          .diff(moment(invoice.invoice_date), "M") > 0
      ) {
        invoiceDuration = `${moment(invoice.invoice_date)
          .endOf("month")
          .diff(moment(invoice.invoice_date), "M")} Month`;
      } else {
        invoiceDuration = `${moment(invoice.invoice_date)
          .endOf("month")
          .diff(moment(invoice.invoice_date), "d")} Days`;
      }
      setDuration(invoiceDuration);
    }
  }, [invoice]);
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  // On generating transaction status after payment
  if (showTransactionStatus) {
    return (
      <div className="receipt-component-wrapper">
        <TransactionStatus
          onSuccessClose={() => {
            setShowTransactionStatus(false);
          }}
          onFailedClose={() => {
            setShowTransactionStatus(false);
          }}
          status={transactionStatus}
          setShowTransactionStatus={setShowTransactionStatus}
        />
      </div>
    );
  }

  return (
    <div className="receipt-component-wrapper">
      <h2 className="heading text-center font_xxl mb-5">Receipt</h2>

      {/* Receipt */}
      <div className="receipt-body">
        {/* Logo and Print */}
        <div className="logo-print">
          {/* Logo */}
          <div className="logo-wrapper">
            <img src={LogoPrimary} alt="Logo" />
          </div>
        </div>

        {/* Invoice Number and Transaction ID */}
        <div className="sec sec-flex sec-1">
          {/* Invoice Number */}
          <p className="font_l fw_6 font_grey">
            Invoice Number: <span className="font_blue">{number}</span>
          </p>

          {/* Transaction ID */}
          {transactionId && (
            <p className="font_l fw_6 font_grey transaction-id">
              Transaction ID: <span className="font_blue">{transactionId}</span>
            </p>
          )}
        </div>

        {status === GLOBAL.PAID && (
          <div className="sec sec-flex sec-1 mt-1">
            <p className="font_m fw_6 font_grey">
              Payment method:{" "}
              <span className="fw_5 font_blue">{` ${
                invoice?.transaction_details?.brand
                  ? invoice?.transaction_details?.brand
                      ?.charAt(0)
                      .toUpperCase() +
                    invoice?.transaction_details?.brand?.slice(1)
                  : "Visa"
              } card ending ${invoice?.transaction_details?.last4}`}</span>
            </p>
          </div>
        )}

        <hr />

        {/* Name and Address */}
        <div className="name-address">
          {/* Name */}
          <div className="name">
            <p className="font_m left fw_6 font_grey">Name: </p>
            <p className="font_m right fw_5">{billingName}</p>
          </div>

          {/* Address */}
          <div className="address">
            <p className="font_m left fw_6 font_grey">Address: </p>
            <p className="font_m right fw_5">{billingAddress}</p>
          </div>
        </div>

        <hr />

        {/* Licenses and Amount */}
        <div className="sec sec-flex sec-2">
          {/* Licenses */}
          <div className="licenses left-sec">
            <p className="font_m fw_6 font_grey">Licenses</p>
            <p className="font_m fw_5 val">{licenseCount}</p>
          </div>

          {/* Amount */}
          <div className="amount right-sec">
            <p className="font_m fw_6 font_grey">License Amount</p>
            <p className="font_m fw_5 val">
              Amount :{" "}
              <span className="font_blue">
                {excludeTaxAmt?.toLocaleString("en-US")} USD
              </span>{" "}
              {isProrated && invoice?.type === "purchase" && (
                <span className="font_s fw_5 font_yellow">( Prorated )</span>
              )}
            </p>
            <span className="font_s fw_4">
              TAX : <span>{taxAmt}</span>
            </span>

            <hr className="divide" />
            <span className="font_s fw_4">
              Total : <span className="font_blue fw_6">{totalAmt}</span>
            </span>
          </div>
        </div>

        <hr />

        {/* Invoice Date and Receipt Date */}
        <div className="sec sec-flex sec-3">
          {/* Invoice Date */}
          <div className="inv-date left-sec">
            <p className="font_m fw_6 font_grey">Invoice Date</p>
            <p className="font_m fw_5 val">{date}</p>
          </div>

          {/* Receipt Date */}
          {receiptDate && (
            <div className="receipt-date right-sec">
              <p className="font_m fw_6 font_grey">Receipt Date & Time</p>
              <p className="font_m fw_5 val">{receiptDate}</p>
            </div>
          )}
        </div>

        <hr />

        {/* Status */}
        {/* <div className="sec sec-flex sec-3">
          {/* Status Title *
          <div className="left-sec">
            <p className="font_m fw_6 font_grey">Status</p>
          </div>

          {/* Status Value *
          <div className="right-sec">
            {status === GLOBAL.UNPAID ? (
              <p className="font_m fw_6 font_yellow">{status?.toUpperCase()}</p>
            ) : (
              <p className="font_m fw_6 font_blue">{status?.toUpperCase()}</p>
            )}
          </div>
        </div> */}

        <div className="sec sec-flex sec-2">
          {/* Status */}
          <div className="licenses left-sec">
            <p className="font_m fw_6 font_grey">Status</p>
            <p className="font_m fw_5 val">
              {status === GLOBAL.UNPAID ? (
                <p className="font_m fw_6 font_yellow">
                  {status?.toUpperCase()}
                </p>
              ) : (
                <p className="font_m fw_6 font_blue">{status?.toUpperCase()}</p>
              )}
            </p>
          </div>

          {/* Type */}
          <div className="amount right-sec">
            <p className="font_m fw_6 font_grey">Type</p>
            <p className="font_m fw_5 font_blue val">{invoiceType}</p>
          </div>
        </div>

        <hr />

        {/* Duration and Due Date */}
        <div className="sec sec-flex sec-5">
          {/* Duration */}
          <div className="left-sec">
            <p className="font_m fw_6 font_grey">Duration</p>
            <p className="font_m fw_5 val">{duration}</p>
          </div>

          {/* Due Date */}
          {status === GLOBAL.UNPAID && (
            <div className="right-sec">
              <p className="font_m fw_6 font_grey">Due Date</p>
              <p className="font_m fw_5 val">{dueDate}</p>
            </div>
          )}
        </div>

        <hr />

        {/*  */}
        {/* Payment Done By Card - Card Details */}
        {status === GLOBAL.PAID && (
          <div className="sec sec-flex">
            <UserCardDetail size="l" cardInfo={invoice?.transaction_details} />
          </div>
        )}
      </div>

      {/* If invoice is not paid */}
      {status === GLOBAL.UNPAID && (
        <PaymentMethod
          size="l"
          setTransactionStatus={setTransactionStatus}
          setShowTransactionStatus={setShowTransactionStatus}
          receiverStripeKey={settings?.stripePublicKey}
          payload={{ user_billing_invoice_id: invoice._id }}
          paymentType={GLOBAL.PAYMENT_TYPE.INVOICE}
        />
      )}
    </div>
  );
};
/* Component ends */

export default ClientInvoiceReceipt;
