/**
 *
 */

import React, { useState, useEffect, useRef } from "react";

/* Import configuration starts */
import { Formik } from "formik";
import { useSelector, useDispatch } from "react-redux";
import GLOBAL from "../../../../setup/constants/global";
import {
  fieldValue,
  validation,
  addPartDetail,
  removePartDetail,
  addMoreImg,
  removeImg,
} from "./helper";
import { useParams } from "react-router-dom";
/* Import configuration ends */

/* Import redux slices component starts */
/* Import redux slices component ends */

/* Import react bootstrap component starts */
import { Modal } from "react-bootstrap";
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import CustomButton from "../../../ui/button/CustomButton/CustomButton";
import TextButton from "../../../ui/button/TextButton/TextButton";
import CustomTextArea from "../../../ui/input/CustomTextArea/CustomTextArea";
import CustomTextInput from "../../../ui/input/CustomTextInput/CustomTextInput";
import CustomNumberInput from "../../../ui/input/CustomNumberInput/CustomNumberInput";
import CustomFileInput from "../../../ui/input/CustomFileInput/CustomFileInput";
/* Import local pages and component ends */

import "./style.scss";
import { getUserTheme } from "../../../../setup/store/slices/authSlice";

/* Component starts */
const SparePartRequestModal = (props) => {
  /* Props destructuring starts */
  const { showModal, size = "lg", onClose, callback } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [formValue, setFormValue] = useState(fieldValue);
  const [partDetails, setPartDetails] = useState([0]);
  const [imgList, setImgList] = useState([[]]);
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const formRef = useRef();
  const userTheme = useSelector(getUserTheme);
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  // On updating move in type spare parts quotations
  const onSubmit = async (values) => {
    if (partDetails?.length === 0) {
      return;
    }

    // Creating or collecting payload data to be sent
    // const payload = {
    //   part: [],
    // };

    const parts = [];
    const document = [];

    // Parts List
    // partDetails.forEach((item, index) => {
    //   const partEach = {};
    //   partEach["part_name"] = values[`part_name_${item}`];
    //   partEach["description"] = values[`description_${item}`];
    //   partEach["quantity"] = values[`quantity_${item}`];
    //   payload.part.push(partEach);
    // });
    // console.log("payload.part", payload.part);

    partDetails.forEach((partItem, partIndx) => {
      parts.push({
        part_name: values[`part_name_${partItem}`],
        description: values[`description_${partItem}`],
        quantity: values[`quantity_${partItem}`],
      });

      imgList[partIndx].forEach((docItm, docIndex) => {
        const file = values[`document_${partItem}_${docItm}`];
        document.push({
          file,
          name: encodeURIComponent(`${partIndx}@file_name_${file.name}`),
        });
      });
    });
    console.log("Parts List Submitted", parts);
    console.log("Attachment List Submitted", document);
    callback(parts, document);
  };
  /* Component function definition ends */

  /* Component useEffect starts */
  // console.log("partDetails", partDetails);
  // console.log("imgList", imgList);
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */

  /* ************* ********************************** ************* */

  return (
    <Modal
      size={size}
      centered
      show={showModal}
      className="spare-part-request-modal-wrapper"
    >
      <Modal.Body
        className={`${
          userTheme === "dark"
            ? "bg_darkTheme"
            : "bg_lightTheme font_lightTheme"
        }`}
      >
        <div className="modal-header-custom">
          <p
            className={`font_l fw_6 text-center ${
              userTheme !== "dark" && "font_blue"
            } font_white`}
          >
            Request Parts & Service
          </p>
        </div>
        <div className="modal-body-custom">
          <Formik
            onSubmit={onSubmit}
            initialValues={formValue}
            validate={(values) => validation(values, partDetails, imgList)}
            validateOnBlur={true}
            enableReinitialize={true}
            innerRef={formRef}
          >
            {({
              values,
              errors,
              touched,
              handleBlur,
              handleChange,
              handleSubmit,
              setFieldValue,
              setFieldTouched,
            }) => (
              <form onSubmit={handleSubmit}>
                {/* Form Fields */}
                <div className="form-wrapper form-wrapper-flex ">
                  {/* Spare Part Form List */}
                  <div className="form-list-wrapper w_100">
                    {partDetails?.map((item, index) => (
                      <div className="part-wrapper">
                        <div className="part-wrapper-inner">
                          <div key={index} className="form-each">
                            <h3 className="w_100 font_blue fw_6 font_m">
                              Item #{index + 1}
                            </h3>

                            {/* Input for Part Name */}
                            <div className="form-group">
                              <CustomTextInput
                                label="Part/Service Name"
                                name={`part_name_${item}`}
                                value={values[`part_name_${item}`]}
                                errors={errors[`part_name_${item}`]}
                                touched={touched[`part_name_${item}`]}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                              />
                            </div>

                            {/* Input for Quantity */}
                            <div className="form-group">
                              <CustomNumberInput
                                label="Quantity"
                                name={`quantity_${item}`}
                                value={values[`quantity_${item}`]}
                                errors={errors[`quantity_${item}`]}
                                touched={touched[`quantity_${item}`]}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                disableDot={true}
                              />
                            </div>

                            {/* Input for Description */}
                            <div className="form-group form-group-full">
                              <CustomTextArea
                                label="Description"
                                name={`description_${item}`}
                                value={values[`description_${item}`]}
                                errors={errors[`description_${item}`]}
                                touched={touched[`description_${item}`]}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                              />
                            </div>
                          </div>

                          {false && (
                            <div className="img-list">
                              <h3 className="add-attachments font_blue font_m fw_5">
                                Attachments For Item #{index + 1}
                              </h3>
                              {imgList[index]?.length === 0 && (
                                <h3 className="no-img-added-txt text-center font_yellow font_m fw_6">
                                  No Images Added !
                                </h3>
                              )}
                              {imgList[index]?.map((docItem, docIndex) => (
                                <div className="doc_item">
                                  <div className="form-goup-flex">
                                    <CustomFileInput
                                      name={`document_${item}_${docItem}`}
                                      label="Attachment"
                                      setFieldTouched={setFieldTouched}
                                      setFieldValue={setFieldValue}
                                      value={
                                        values[`document_${item}_${docItem}`]
                                      }
                                      touched={
                                        touched[`document_${item}_${docItem}`]
                                      }
                                      errors={
                                        errors[`document_${item}_${docItem}`]
                                      }
                                      formikState={true}
                                      acceptable={true}
                                      acceptType="image/*,video/*"
                                    />
                                  </div>

                                  {imgList[index]?.length > 0 && (
                                    <TextButton
                                      onClick={() =>
                                        removeImg(
                                          formRef,
                                          setFormValue,
                                          imgList,
                                          setImgList,
                                          partDetails,
                                          index,
                                          docIndex
                                        )
                                      }
                                      className="fw_6 font_xs font_red v_middle remove-img-btn"
                                    >
                                      Remove Attachment
                                    </TextButton>
                                  )}
                                </div>
                              ))}
                              <div className="add-img-btn-wrapper text-end">
                                <TextButton
                                  onClick={() =>
                                    addMoreImg(
                                      formRef,
                                      setFormValue,
                                      imgList[index],
                                      setImgList,
                                      partDetails[index],
                                      index
                                    )
                                  }
                                  className="fw_6 font_xs font_blue v_middle add-img-btn"
                                >
                                  + Add Attachments
                                </TextButton>
                              </div>
                            </div>
                          )}
                        </div>

                        {partDetails.length > 0 && (
                          <div className="remove-btn-wrapper">
                            <TextButton
                              onClick={() =>
                                removePartDetail(
                                  formRef,
                                  setFormValue,
                                  partDetails,
                                  setPartDetails,
                                  imgList,
                                  setImgList,
                                  index
                                )
                              }
                              className="fw_6 font_m font_red v_middle"
                            >
                              Remove Item #{index + 1}
                            </TextButton>
                          </div>
                        )}
                      </div>
                    ))}
                  </div>

                  {/* Add or Remove Field Action */}
                  <div className="action-btn w_100 text-end">
                    <TextButton
                      onClick={() =>
                        addPartDetail(
                          formRef,
                          setFormValue,
                          partDetails,
                          setPartDetails,
                          setImgList
                        )
                      }
                      className="fw_6 font_m font_blue v_middle"
                    >
                      + Add Part
                    </TextButton>
                  </div>
                </div>

                {/* Submit and Cancel */}
                <div className="button-wrapper">
                  <>
                    <CustomButton
                      variant="red"
                      size="m"
                      onClick={onClose}
                      className="btn1"
                    >
                      Cancel
                    </CustomButton>
                    <CustomButton type="submit" size="m">
                      Submit
                    </CustomButton>
                  </>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </Modal.Body>
    </Modal>
  );
};
/* Component ends */

export default SparePartRequestModal;
