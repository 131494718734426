/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import moment from "moment";
import { useLocation, useNavigate } from "react-router-dom";
/* Import configuration ends */

/* Import redux slices component starts */
/* Import redux slices component ends */

/* Import react bootstrap component starts */
import { Table } from "react-bootstrap";
/* Import react bootstrap component ends */

/* Import image and SVG starts */
import { EyeFilledBlue } from "../../../../../setup/constants/images";
/* Import image and SVG ends */

/* Import local pages and component starts */

import { pathNamePopped } from "../../../../../setup/utils/global-helper";
import IconButton from "../../../../ui/button/IconButton/IconButton";
/* Import local pages and component ends */

/* Component starts */
const ViolationList = (props) => {
  /* Props destructuring starts */
  const { data, index } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const { pathname } = useLocation();
  const navigate = useNavigate();
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  const USER_DATA = localStorage.getItem("user_data")
    ? JSON.parse(localStorage.getItem("user_data"))
    : null;
  const DATE_FORMAT = USER_DATA ? USER_DATA?.date_format : "";
  const TIME_FORMAT = USER_DATA ? USER_DATA?.time_format : "";
  const DATE_TIME_FORMAT = `${DATE_FORMAT} ${TIME_FORMAT}`;
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  const handleView = (item) => {
    const newPathname = pathNamePopped(pathname, 3);
    console.log(newPathname, "[SATHY]");

    navigate(`${newPathname}/violations/tenants/${item?._id}`, {
      state: { pathname: pathname },
    });
  };
  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="section">
      <h3 className="table-heading font_m mx-4">
        {index} - Tenant Violation Details
      </h3>
      <div className="custom-table-wrapper">
        {data?.length > 0 && (
          <Table
            className="custom-table font_s unit-table"
            borderless
            responsive
          >
            <thead>
              <tr>
                <th>ID</th>
                <th>Title</th>
                {/* <th>Security</th> */}
                <th>Date And Time</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {data?.map((item, index) => (
                <tr key={index}>
                  {/* Name */}
                  <td>{item?.violation_id}</td>

                  {/* title */}
                  <td>{item?.title}</td>

                  {/* Type */}
                  {/* <td>
                    {item?.is_security_incident !== null
                      ? item?.is_security_incident?.toString()
                      : "-"}
                  </td> */}

                  {/* Date of birth */}
                  <td>{moment(item?.createdAt).format(DATE_TIME_FORMAT)}</td>
                  {/* Action */}
                  <td>
                    <IconButton
                      className="file-icon"
                      onClick={() => {
                        handleView(item);
                      }}
                    >
                      <img src={EyeFilledBlue} alt="File" />
                    </IconButton>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}

        {data?.length === 0 && (
          <div className=" text-center">
            <h3 className=" font_m font_yellow fw_6">No Data</h3>
          </div>
        )}
      </div>
    </div>
  );
};
/* Component ends */

export default ViolationList;
