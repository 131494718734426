/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { formValues, loadInitialValue, validations } from "./helper";
import GLOBAL from "../../../../setup/constants/global";
/* Import configuration ends */

/* Import Redux Slices Starts */
import { updateEmergencyContact } from "../../../../setup/store/slices/emergencyContactSlice";
import { showErrorAlert } from "../../../../setup/store/slices/globalAlertSlice";
import { getUserTheme } from "../../../../setup/store/slices/authSlice";
/* Import Redux Slices Ends */

/* Import react bootstrap component starts */
import { Modal } from "react-bootstrap";
/* Import react bootstrap component ends */

/* Import MUI component starts */
/* Import MUI component ends */

/* Import image and SVG starts */

/* Import image and SVG ends */

/* Import local pages and component starts */
import CustomButton from "../../../ui/button/CustomButton/CustomButton";
import CustomTextInput from "../../../ui/input/CustomTextInput/CustomTextInput";
import CustomPhoneInput from "../../../ui/input/CustomPhoneInput/CustomPhoneInput";
/* Import local pages and component ends */

import "./style.scss";

/* Component starts */
const EditEmergencyContactModal = (props) => {
  /* Props destructuring starts */
  const {
    showModal,
    size = "lg",
    onClose,
    settings,
    getPropertySettingsDetail,
    contact,
  } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [initialFormValues, setInitialFormValues] = useState(formValues);
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const dispatch = useDispatch();
  const userTheme = useSelector(getUserTheme);
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  // Form Validation
  const formValidation = (values) => {
    return validations(values);
  };

  // On adding emergency contact
  const onSubmit = async (values) => {
    const payload = {
      _id: contact?._id,
      department: values.department,
      countrycode: values?.mobile?.split(" ")[0],
      mobile: values?.mobile?.split(" ")[1],
    };

    // Backend response. Try, Catch
    try {
      const result = await dispatch(updateEmergencyContact(payload));

      //   Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          dispatch(
            showErrorAlert({
              title: "Error!",
              description: result.payload,
            })
          );
          onClose();
          break;
        case GLOBAL.FULFILLED:
          onClose();
          getPropertySettingsDetail();
          break;

        default:
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };
  /* Component function definition ends */

  /* Component useEffect starts */
  // On initial load or when all the settings is loaded
  useEffect(() => {}, [settings]);

  useEffect(() => {
    if (contact) {
      loadInitialValue(contact, setInitialFormValues);
    }
  }, [contact]);

  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <Modal
      size={size}
      centered
      show={showModal}
      className="emergency-contract-modal-main-wrapper"
    >
      <Modal.Body
        className={`${userTheme === "dark" ? "bg_darkTheme" : "bg_lightTheme"}`}
      >
        <div className="modal-header-custom">
          <h2 className="text-center font_blue font_xl fw_6">
            Edit Emergency Contact
          </h2>
        </div>
        <div className="modal-body-custom">
          <div className="contract-list">
            <Formik
              onSubmit={onSubmit}
              initialValues={initialFormValues}
              validate={formValidation}
              validateOnBlur={true}
              enableReinitialize={true}
            >
              {({
                values,
                errors,
                touched,
                handleBlur,
                handleChange,
                handleSubmit,
                setFieldValue,
                setFieldTouched,
              }) => (
                <form onSubmit={handleSubmit}>
                  <div className="form-wrapper">
                    {/* Input for Department */}
                    <div className="form-group">
                      <CustomTextInput
                        name="department"
                        label="Department"
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        touched={touched.department}
                        errors={errors.department}
                        value={values.department}
                      />
                    </div>

                    <div
                      className={`form-group ${
                        (userTheme === "light" || userTheme === "automatic") &&
                        "otherTheme"
                      } `}
                    >
                      <CustomPhoneInput
                        name="mobile"
                        label="Phone Number"
                        setFieldValue={setFieldValue}
                        setFieldTouched={setFieldTouched}
                        touched={touched.mobile}
                        errors={errors.mobile}
                        value={values.mobile}
                        required={true}
                      />
                    </div>
                  </div>

                  <div className="button-wrapper text-center">
                    <CustomButton type="submit" size="m">
                      Edit
                    </CustomButton>
                    <CustomButton size="m" variant="red" onClick={onClose}>
                      Close
                    </CustomButton>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
/* Component ends */

export default EditEmergencyContactModal;
