import React, { useEffect } from "react";
/* Import configuration starts */
import { Link, Navigate, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
/* Import configuration ends */

/* Import redux slices component starts */
import { getAuthLoading } from "../../setup/store/slices/unpersistedSlice";
import {
  fetchUser,
  getUser,
  setUserRole,
} from "../../setup/store/slices/authSlice";
import {
  fetchUserRoles,
  getUserRoles,
} from "../../setup/store/slices/masterSlice";
import {
  getTokenExpiredModal,
  setTokenExpiredModal,
} from "../../setup/store/slices/unpersistedSlice";
import {
  getNotificationToken,
  registerToken,
} from "../../setup/store/slices/notificationSlice";
import { setLoading } from "../../setup/store/slices/unpersistedSlice";
/* Import redux slices component ends */

/* Import image and SVG starts */
import { LogoPrimary } from "../../setup/constants/images";
import {
  LogoWhite,
  BgShadeSignUp,
  SignUpLogo,
  DGRNTEtext,
  ArrowPointRightBlack,
} from "../../setup/constants/images";
/* Import image and SVG ends */

/* Import local pages and component starts */
import SignInForm from "./local-components/SignInForm";
import WarningModal from "../../components/ui/modal/WarningModal/WarningModal";
/* Import local pages and component ends */

import "./style.scss";

/* Component starts */
const SignIn = () => {
  /* Component states declaration starts */
  /* Component states declaration ends */

  /* Other hooks declaration starts */
  // const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // Redux Selector
  const authLoading = useSelector(getAuthLoading);
  const tokenExpiredModal = useSelector(getTokenExpiredModal);
  const user = useSelector(getUser);
  const userRoles = useSelector(getUserRoles);
  const notificationToken = useSelector(getNotificationToken);
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  const sendNotificationToken = async () => {
    // Creating or collecting payload data to be sent
    const payload = {
      device: "web",
      token: notificationToken,
    };

    // Backend response. Try, Catch
    try {
      const result = await dispatch(registerToken(payload));
      console.log("Response", result);

      // Handling success response
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };

  // Set user role while any user sign in
  const setUserRoleOnLogin = async (roleId) => {
    // let role = userRoles.filter((d) => d._id === roleId)[0];
    // console.log("Role ID", roleId);
    dispatch(setUserRole(roleId.name));
    sendNotificationToken();
  };
  /* Component function definition ends */

  /* Component useEffect starts */
  useEffect(() => {
    (async () => {
      try {
        await dispatch(setLoading(true));
        await dispatch(fetchUser());
        await dispatch(fetchUserRoles());
        await dispatch(setLoading(false));
      } catch (e) {}
    })();
  }, []);
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  // If user is logged in then navigating back
  if (user && localStorage.getItem("user_token")) {
    return <Navigate to="/" />;
  }

  return (
    <div className="signin-outer-wrapper">
      <div className="bgShade">
        <img src={BgShadeSignUp} alt="" />
      </div>
      <div className="image">
        <div className="inner-wrapper">
          <div className="text-logo">
            <img src={SignUpLogo} className="logo-icon" alt="" />
            <img src={DGRNTEtext} alt="" />
          </div>
          <h2 className="heading font-darkgreen fw_6">
            Start your journey with us
          </h2>
          <h3 className="content font-darkgreen">
            Discover the power of collaboration and create lasting relationships
            that transcend boundaries
          </h3>
        </div>
      </div>

      <div className="form-wrapper">
        <div
          className="blur-blue"
          style={{ top: "7%", left: "35%", zIndex: "10" }}
        ></div>
        <div
          className="blur-cherry"
          style={{ top: "-3%", right: "5%", zIndex: "10" }}
        ></div>
        <div
          className="blur-blue"
          style={{ top: "75%", left: "97%", zIndex: "10" }}
        ></div>
        <div
          className="blur-cherry"
          style={{ top: "90%", right: "50%", zIndex: "10" }}
        ></div>
        <div className="logo-wrapper">
          {/* <img src={LogoWhite} alt="Logo" /> */}
          <h1 className="big-h1 font-darkgreen">Sign In</h1>
          <p className="font_xl font-darkgreen">
            Don't have an account ?{" "}
            <span
              className="font-darkgreen fw_6 login"
              style={{ cursor: "pointer" }}
              onClick={() => navigate("/signup")}
            >
              Sign up as client
            </span>
          </p>
        </div>
        <SignInForm setUserRoleOnLogin={setUserRoleOnLogin} />
        {/* {!authLoading && (
          <div className="footer text-center">
            <Link className="font_m" to="/signin">
              Return back to Sign In
            </Link>
          </div>
        )} */}
      </div>

      {/* <div className="sign-in-wrapper container_sm">
        <div className="center bg_grey">
          <div className="logo-wrapper text-center">
            <img src={LogoPrimary} alt="Logo" />
          </div>
          <SignInForm setUserRoleOnLogin={setUserRoleOnLogin} />
          {!authLoading && (
            <div className="footer text-end">
              Don't have an account? <Link to="/signup">Sign up as client</Link>
            </div>
          )}
        </div>
      </div> */}
      <WarningModal
        showModal={tokenExpiredModal}
        title="Session Expired"
        description="Your session has been expired. Please Log In again."
        onClose={async () => {
          await dispatch(setTokenExpiredModal(false));
        }}
      />
    </div>
  );
};
/* Component ends */

export default SignIn;
