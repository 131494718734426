/**
 *
 */

import Reac, { useRef, useState, useEffect } from "react";

/* Import configuration starts */
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams, useParams } from "react-router-dom";
/* Import configuration ends */

/* Import redux slices component starts */
import {
  fetchLicenseAmount,
  getPayableAmount,
  licenseAssignToProperty,
} from "../../../../setup/store/slices/licenseSlice";
import {
  getSetting,
  fetchSetting,
} from "../../../../setup/store/slices/settingSlice";
import { fetchUser, getUser } from "../../../../setup/store/slices/authSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import MUI component starts */
/* Import MUI component ends */

/* Import image and SVG starts */
import { WarningOutlineRed } from "../../../../setup/constants/images";
/* Import image and SVG ends */

/* Import local pages and component starts */
import CounterInput from "../../../ui/input/CounterInput/CounterInput";
import TextButton from "../../../ui/button/TextButton/TextButton";
import CustomButton from "../../../ui/button/CustomButton/CustomButton";
import PurchaseLicense from "../PurchaseLicense/PurchaseLicense";
import ErrorModal from "../../../ui/modal/ErrorModal/ErrorModal";
import SuccessModal from "../../../ui/modal/SuccessModal/SuccessModal";
/* Import local pages and component ends */

import "./style.scss";

/* Component starts */
const AssignLicenseModule = (props) => {
  /* Props destructuring starts */
  const { onSuccess, onCancel, successModal = false } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [licenseCount, setLicenseCount] = useState(1);
  const [perUnitProrated, setPerUnitProrated] = useState(1);
  const [numLicenseToPay, setNumLicenseToPay] = useState(1);
  const [showPurchaseLicense, setShowPurchaseLicense] = useState(false);
  const [showError, setShowError] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [message, setMessage] = useState({});
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const { propertyId } = useParams();

  // Redux Selector
  const payableAmount = useSelector(getPayableAmount);
  const user = useSelector(getUser);
  const settings = useSelector(getSetting);
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  // On changing licence quantity
  const onLicenseCountChange = (num) => {
    setNumLicenseToPay(num - user.available_license);
  };

  // After all process of license purchase
  const afterTransaction = () => {
    setShowPurchaseLicense(false);
  };

  // On cancelling purchase
  const onCancelPurchase = () => {
    setShowPurchaseLicense(false);
  };

  // On clicking purchase button
  const onPurchase = () => {
    setShowPurchaseLicense(true);
  };

  // On assigning license to the property
  const onAssign = async () => {
    // If license selected is less than one
    if (parseInt(licenseCount) < 1) {
      setMessage({
        title: "Error",
        desc: "You cannot assign 0 license. Please select atleast 1 to assign to property",
      });
      setShowError(true);
      return;
    }

    // If license quantity that user is going to assign is greater than the license available in user account
    if (parseInt(licenseCount) > user.available_license) {
      setMessage({
        title: "Insufficient License",
        desc: "You do not have enough license. Please purchase license to assign it to the property.",
      });
      setShowError(true);
      return;
    }

    // Creating or collecting payload data to be sent
    const payload = {
      property_id: searchParams.get("property_id") || propertyId,
      number_of_license: licenseCount,
    };

    // Backend Response. Try, Catch
    try {
      const result = await dispatch(licenseAssignToProperty(payload));

      // Handling success response
      switch (result.meta.requestStatus) {
        case "rejected":
          setMessage({
            title: "Error",
            desc: result?.payload?.error?.message,
          });
          setShowError(true);
          break;
        case "fulfilled":
          if (successModal) {
            setShowSuccess(true);
            setMessage({
              title: "Success",
              desc: "License successfully assigned to the property",
            });
          } else {
            onSuccess();
          }
          break;
      }
    } catch (err) {
      // Handling error response
      console.error(err.message);
    }
  };
  /* Component function definition ends */

  /* Component useEffect starts */
  // Initial load
  useEffect(() => {
    dispatch(fetchUser());
    dispatch(fetchLicenseAmount(1));
    dispatch(fetchSetting({ init: true }));
  }, []);

  // On getting the actual licence quantity subtracting the available license
  useEffect(() => {
    const getTimeoutData = setTimeout(async () => {
      await dispatch(fetchLicenseAmount(numLicenseToPay));
    }, 1000);

    return () => {
      clearTimeout(getTimeoutData);
    };
  }, [numLicenseToPay]);

  // Calculating the prorated amount
  useEffect(() => {
    let amountToPay = payableAmount / numLicenseToPay;
    setPerUnitProrated(
      amountToPay % 1 == 0 ? amountToPay : amountToPay.toFixed(2)
    );
  }, [payableAmount]);
  /* Component useEffect ends */
  console.log("user user user", user);
  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="assign-license-module-wrapper">
      {/* Not showing purchase window, just select number of license and assign to property */}

      <h2 className="heading text-start font_xxl mb-5">Assign License</h2>

      {!showPurchaseLicense && (
        <>
          <div className="form-wrapper">
            <CounterInput
              num={licenseCount}
              setNum={setLicenseCount}
              label="Number of Licenses"
              onNumChange={onLicenseCountChange}
            />
          </div>

          {user && (
            <>
              {/* If license quantity selected to be assigned in property is greater than the license available in user account */}
              {licenseCount > user.available_license && (
                <div className="warning-wrapper">
                  <div className="warning-wrapper-text bg_grey">
                    <div className="warning-text">
                      <img src={WarningOutlineRed} alt="Warning Icon" />
                      <p className="font_s fw_5 font_red">
                        Currently you have
                        <span className="font_red fw_7">
                          {" "}
                          {user.available_license}{" "}
                        </span>
                        Licenses available. You need to subscribe to additional
                        licenses.
                      </p>
                    </div>
                    <div className="prorated-wrapper">
                      <div className="prorated">
                        <p className="font_s">
                          Per License Amount:{" "}
                          <span className="fw_7">
                            ${perUnitProrated}{" "}
                            {parseInt(settings?.perUnitPrice) !==
                              parseInt(perUnitProrated) && (
                              <span className="font_yellow fw_4">
                                (Prorated)
                              </span>
                            )}
                          </span>
                        </p>
                      </div>
                      <div className="total-license-amt">
                        <p className="font_s font_grey">
                          {numLicenseToPay} x ${perUnitProrated} ={" "}
                          <span className="font_blue fw_7">
                            ${payableAmount}
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {/* Add license or Cancel */}
              <div className="button-wrapper">
                {licenseCount > user.available_license ? (
                  <CustomButton size="l" onClick={onPurchase}>
                    + Add
                  </CustomButton>
                ) : (
                  <CustomButton size="l" onClick={onAssign}>
                    Assign
                  </CustomButton>
                )}

                <TextButton
                  onClick={onCancel}
                  size="l"
                  className="fw_6 w_100 font_m"
                >
                  Skip
                </TextButton>
              </div>
            </>
          )}
        </>
      )}

      {/* Showing purchase license */}
      {user && showPurchaseLicense && (
        <PurchaseLicense
          defaultNumLicences={licenseCount - user.available_license}
          onCancel={onCancelPurchase}
          afterTransaction={afterTransaction}
        />
      )}

      {/* Modal */}
      {/* Error modal */}
      <ErrorModal
        showModal={showError}
        title={message.title}
        description={message.desc}
        onClose={() => {
          setShowError(false);
        }}
      />

      {/* Success Modal */}
      <SuccessModal
        showModal={showSuccess}
        title={message.title}
        description={message.desc}
        onClose={() => {
          onSuccess();
          setShowSuccess(false);
        }}
      />
    </div>
  );
};
/* Component ends */

export default AssignLicenseModule;
