/**
 * @auther Abdul Ahad <aabdul@dgrnte.com>
 * Slice to handle maintenance
 */

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API_URL } from "../../config/devKeys";
import {
  setLoading,
  setShowUploadProgress,
  setUploadProgress,
} from "./unpersistedSlice";
import axios, { axiosInstance } from "../axios";
import { PLATFORM } from "../../platformUtil";

const initialState = {
  maintenance: null,
  maintenanceList: [],
  maintenanceSpareList: [],
  maintenanceLocations: [],
  maintenanceCategories: [],
  maintenanceSubCategories: [],
  maintenanceInnerCategories: [],
  maintenanceOuterCategories: [],
  technicians: [],
  managers: [],
  maintenanceEscalatedList: [],
};

/**
 * T3 Maintenance with FM
 */
export const fmRespondToT3Maintenance = createAsyncThunk(
  "maintenance/t3/fm_respond",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const { data } = await axios.post(
        API_URL + "/maintenance/action_by_fm",
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      if (data.success) {
        await dispatch(
          fetchMaintenanceListByProperty({
            type: "Maintenance",
            status: payload.type,
          })
        );
        await dispatch(
          fetchMaintenanceById({ maintenance_id: payload.maintenance_id })
        );
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      return rejectWithValue(error?.response);
    }
  }
);

// Cancel The Ticket By FM
export const fmCancelT3MaintenanceTicket = createAsyncThunk(
  "maintenance/t3/manager_cancel_request",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const { data } = await axios.post(
        API_URL + "/maintenance/manager_cancel_request",
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      dispatch(setLoading(false));
      if (data.success) {
        await dispatch(
          fetchMaintenanceListByProperty({
            type: "Maintenance",
            status: payload.type,
          })
        );
        await dispatch(
          fetchMaintenanceById({ maintenance_id: payload.maintenance_id })
        );
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

export const fmAssignTechnician = createAsyncThunk(
  "maintenance/t3/fm_assign_technician",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const { data } = await axios.post(
        API_URL + "/maintenance/assign_maintenance_technician",
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      if (data.success) {
        await dispatch(
          fetchMaintenanceListByProperty({
            type: "Maintenance",
            status: payload.type,
          })
        );
        await dispatch(
          fetchMaintenanceById({ maintenance_id: payload.maintenance_id })
        );
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      return rejectWithValue(error?.response);
    }
  }
);

export const technicianTakeAction = createAsyncThunk(
  "maintenance/t3/technician_take_action",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const { data } = await axios.post(
        API_URL + "/maintenance/technician_take_action",
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      if (data.success) {
        await dispatch(
          fetchMaintenanceListByProperty({
            type: "Maintenance",
            status: payload.type,
          })
        );
        await dispatch(
          fetchMaintenanceById({ maintenance_id: payload.maintenance_id })
        );
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      return rejectWithValue(error?.response);
    }
  }
);

export const markTechnicianOnTheWay = createAsyncThunk(
  "maintenance/t3/mark_tech_on_the_way",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const { data } = await axios.post(
        API_URL + "/maintenance/mark_technician_on_the_way",
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      if (data.success) {
        await dispatch(
          fetchMaintenanceListByProperty({
            type: "Maintenance",
            status: payload.type,
          })
        );
        await dispatch(
          fetchMaintenanceById({ maintenance_id: payload.maintenance_id })
        );
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      return rejectWithValue(error?.response);
    }
  }
);

export const technicianMarkAsNoAccess = createAsyncThunk(
  "maintenance/t3/technician_mark_as_no_access",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const { data } = await axios.post(
        API_URL + "/maintenance/technician_mark_as_no_access",
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      if (data.success) {
        await dispatch(
          fetchMaintenanceListByProperty({
            type: "Maintenance",
            status: payload.type,
          })
        );
        await dispatch(
          fetchMaintenanceById({ maintenance_id: payload.maintenance_id })
        );
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      return rejectWithValue(error?.response);
    }
  }
);

export const tenantRescheduleMaintenance = createAsyncThunk(
  "maintenance/t3/tenant_reschedule_maintenance",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const { data } = await axios.post(
        API_URL + "/maintenance/tenant_reschedule_maintenance",
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      if (data.success) {
        await dispatch(
          fetchMaintenanceListByProperty({
            type: "Maintenance",
            status: payload.type,
          })
        );
        await dispatch(
          fetchMaintenanceById({ maintenance_id: payload.maintenance_id })
        );
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      return rejectWithValue(error?.response);
    }
  }
);

export const technicianCheckedIn = createAsyncThunk(
  "maintenance/t3/technician_checked_in",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const { data } = await axios.post(
        API_URL + "/maintenance/technician_checked_in",
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      if (data.success) {
        await dispatch(
          fetchMaintenanceListByProperty({
            type: "Maintenance",
            status: payload.type,
          })
        );
        await dispatch(
          fetchMaintenanceById({ maintenance_id: payload.maintenance_id })
        );
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      return rejectWithValue(error?.response);
    }
  }
);

// Maintenance Technician Add Inspection Report..
export const mtAddInspection_t3_tenant = createAsyncThunk(
  "maintenance/t3_mt_upload_initial_inspection",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const formData = new FormData();

      Object.keys(payload).map((key) => {
        if (key === "document") {
          payload[key].forEach((item) => {
            if (PLATFORM !== "web") {
              formData.append(key, {
                name: item.name,
                type: item.type,
                uri:
                  PLATFORM === "android"
                    ? item.uri
                    : item.uri.replace("file://", ""),
              });
            } else {
              formData.append(key, item.file, item.name);
            }
          });
        } else {
          if (key === "description") {
            formData.append(key, JSON.stringify(payload[key]));
          } else {
            formData.append(key, payload[key]);
          }
        }
      });
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("user_token")}`,
      };
      if (PLATFORM !== "web") {
        headers["Content-Type"] = "multipart/form-data";
      }

      dispatch(setShowUploadProgress(true));
      const config = {
        onUploadProgress: ({ loaded, total }) => {
          const progress = (loaded / total) * 100;
          dispatch(setUploadProgress(progress));
        },
        method: "POST",
        url: "/maintenance/t3_mt_upload_initial_inspection",
        headers,
        transformRequest: (data, headers) => {
          return formData;
        },
        data: formData,
        responseType: "json",
      };

      const { data } = await axiosInstance.request(config);

      dispatch(setLoading(false));
      if (data.success) {
        await dispatch(
          fetchMaintenanceListByProperty({
            type: "Maintenance",
            status: payload.type,
          })
        );
        await dispatch(
          fetchMaintenanceById({ maintenance_id: payload.maintenance_id })
        );
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

export const technicianStartWork = createAsyncThunk(
  "maintenance/t3/technician_started_work",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const { data } = await axios.post(
        API_URL + "/maintenance/technician_started_work",
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      if (data.success) {
        await dispatch(
          fetchMaintenanceListByProperty({
            type: "Maintenance",
            status: payload.type,
          })
        );
        await dispatch(
          fetchMaintenanceById({ maintenance_id: payload.maintenance_id })
        );
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      return rejectWithValue(error?.response);
    }
  }
);

export const _technicianAddSpareParts = createAsyncThunk(
  "maintenance/t3/technician_add_spare_parts",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const { data } = await axios.post(
        API_URL + "/maintenance/addSpartParts",
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      if (data.success) {
        await dispatch(
          fetchMaintenanceListByProperty({
            type: "Maintenance",
            status: payload.type,
          })
        );
        await dispatch(
          fetchMaintenanceById({ maintenance_id: payload.maintenance_id })
        );
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      return rejectWithValue(error?.response);
    }
  }
);

export const technicianAddSpareParts = createAsyncThunk(
  "maintenance/t3/technician_add_spare_parts",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const formData = new FormData();
      Object.keys(payload).map((key) => {
        if (key === "document") {
          payload[key].forEach((item) => {
            if (PLATFORM !== "web") {
              formData.append(key, {
                name: item.name,
                type: item.type,
                uri:
                  PLATFORM === "android"
                    ? item.uri
                    : item.uri.replace("file://", ""),
              });
            } else {
              formData.append(key, item.file, item.name);
            }
          });
        } else {
          if (key === "description") {
            // stringifying array of string
            formData.append(key, JSON.stringify(payload[key]));
          } else {
            formData.append(key, payload[key]);
          }
        }
      });
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("user_token")}`,
      };
      if (PLATFORM !== "web") {
        headers["Content-Type"] = "multipart/form-data";
      }
      // const response = await fetch(API_URL + "/maintenance/owner_respond", {
      //   method: "POST",
      //   headers: headers,
      //   body: formData,
      // });
      // const data = await response.json();
      dispatch(setShowUploadProgress(true));
      const config = {
        onUploadProgress: ({ loaded, total }) => {
          const progress = (loaded / total) * 100;
          dispatch(setUploadProgress(progress));
        },
        method: "POST",
        url: "/maintenance/addSpartParts",
        headers,
        transformRequest: (data, headers) => {
          return formData;
        },
        data: formData,
        responseType: "json",
      };

      // const data = await response.json();

      const { data } = await axiosInstance.request(config);

      dispatch(setLoading(false));
      if (data.success) {
        await dispatch(
          fetchMaintenanceListByProperty({
            type: "Maintenance",
            status: payload.type,
          })
        );
        await dispatch(
          fetchMaintenanceById({ maintenance_id: payload.maintenance_id })
        );
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

export const fmRespondToSpareParts = createAsyncThunk(
  "maintenance/t3/fm_respond_to_spare_parts",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const { data } = await axios.post(
        API_URL + "/maintenance/respond_to_spare_parts",
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      if (data.success) {
        await dispatch(
          fetchMaintenanceListByProperty({
            type: "Maintenance",
            status: payload.type,
          })
        );
        await dispatch(
          fetchMaintenanceById({ maintenance_id: payload.maintenance_id })
        );
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      return rejectWithValue(error?.response);
    }
  }
);

export const technicianHandoverItems = createAsyncThunk(
  "maintenance/t3/technician_handover_items",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const { data } = await axios.post(
        API_URL + "/maintenance/technician_handover_items",
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      if (data.success) {
        await dispatch(
          fetchMaintenanceListByProperty({
            type: "Maintenance",
            status: payload.type,
          })
        );
        await dispatch(
          fetchMaintenanceById({ maintenance_id: payload.maintenance_id })
        );
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      return rejectWithValue(error?.response);
    }
  }
);

export const technicianMarkJobComplete = createAsyncThunk(
  "maintenance/t3/technician_completed_the_work",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const formData = new FormData();

      Object.keys(payload).map((key) => {
        if (key === "document") {
          payload[key].forEach((item) => {
            if (PLATFORM !== "web") {
              formData.append(key, {
                name: item.name,
                type: item.type,
                uri:
                  PLATFORM === "android"
                    ? item.uri
                    : item.uri.replace("file://", ""),
              });
            } else {
              formData.append(key, item.file, item.name);
            }
          });
        } else {
          if (key === "description") {
            formData.append(key, JSON.stringify(payload[key]));
          } else {
            formData.append(key, payload[key]);
          }
        }
      });
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("user_token")}`,
      };
      if (PLATFORM !== "web") {
        headers["Content-Type"] = "multipart/form-data";
      }

      dispatch(setShowUploadProgress(true));
      const config = {
        onUploadProgress: ({ loaded, total }) => {
          const progress = (loaded / total) * 100;
          dispatch(setUploadProgress(progress));
        },
        method: "POST",
        url: "/maintenance/technician_completed_the_work",
        headers,
        transformRequest: (data, headers) => {
          return formData;
        },
        data: formData,
        responseType: "json",
      };

      const { data } = await axiosInstance.request(config);

      dispatch(setLoading(false));

      if (data.success) {
        await dispatch(
          fetchMaintenanceListByProperty({
            type: "Maintenance",
            status: payload.type,
          })
        );
        await dispatch(
          fetchMaintenanceById({ maintenance_id: payload.maintenance_id })
        );
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      return rejectWithValue(error?.response);
    }
  }
);

export const tenantRespondtoMaintenance = createAsyncThunk(
  "maintenance/t3/tenant_respond_to_maintenance",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      // const { data } = await axios.post(
      //   API_URL + "/maintenance/tenant_respond_to_maintenance",
      //   payload,
      //   {
      //     headers: {
      //       Authorization: `Bearer ${localStorage.getItem("user_token")}`,
      //     },
      //   }
      // );

      dispatch(setLoading(true));
      const formData = new FormData();
      Object.keys(payload).map((key) => {
        if (key === "document") {
          payload[key].forEach((item) => {
            if (PLATFORM !== "web") {
              formData.append(key, {
                name: item.name,
                type: item.type,
                uri:
                  PLATFORM === "android"
                    ? item.uri
                    : item.uri.replace("file://", ""),
              });
            } else {
              formData.append(key, item.file, item.name);
            }
          });
        } else {
          if (key === "description") {
            formData.append(key, JSON.stringify(payload[key]));
          } else {
            formData.append(key, payload[key]);
          }
        }
      });
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("user_token")}`,
      };
      if (PLATFORM !== "web") {
        headers["Content-Type"] = "multipart/form-data";
      }

      dispatch(setShowUploadProgress(true));
      const config = {
        onUploadProgress: ({ loaded, total }) => {
          const progress = (loaded / total) * 100;
          dispatch(setUploadProgress(progress));
        },
        method: "POST",
        url: "/maintenance/tenant_respond_to_maintenance",
        headers,
        transformRequest: (data, headers) => {
          return formData;
        },
        data: formData,
        responseType: "json",
      };

      const { data } = await axiosInstance.request(config);

      dispatch(setLoading(false));

      if (data.success) {
        await dispatch(
          fetchMaintenanceListByProperty({
            type: "Maintenance",
            status: payload.type,
          })
        );
        await dispatch(
          fetchMaintenanceById({ maintenance_id: payload.maintenance_id })
        );
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      return rejectWithValue(error?.response);
    }
  }
);

export const reassignMaintenanceTicket = createAsyncThunk(
  "maintenance/t3/reassign_maintenance_ticket",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const { data } = await axios.post(
        API_URL + "/maintenance/reassign_maintenance_ticket",
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      if (data.success) {
        await dispatch(
          fetchMaintenanceListByProperty({
            type: "Maintenance",
            status: payload.type,
          })
        );
        await dispatch(
          fetchMaintenanceById({ maintenance_id: payload.maintenance_id })
        );
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      return rejectWithValue(error?.response);
    }
  }
);

// T3 - Third Party Company
export const tptAddInspection_t3_tenant = createAsyncThunk(
  "maintenance/t2_party_technician_add_initial_report",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const formData = new FormData();

      Object.keys(payload).map((key) => {
        if (key === "document") {
          payload[key].forEach((item) => {
            if (PLATFORM !== "web") {
              formData.append(key, {
                name: item.name,
                type: item.type,
                uri:
                  PLATFORM === "android"
                    ? item.uri
                    : item.uri.replace("file://", ""),
              });
            } else {
              formData.append(key, item.file, item.name);
            }
          });
        } else {
          if (key === "description") {
            formData.append(key, JSON.stringify(payload[key]));
          } else {
            formData.append(key, payload[key]);
          }
        }
      });
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("user_token")}`,
      };
      if (PLATFORM !== "web") {
        headers["Content-Type"] = "multipart/form-data";
      }

      dispatch(setShowUploadProgress(true));
      const config = {
        onUploadProgress: ({ loaded, total }) => {
          const progress = (loaded / total) * 100;
          dispatch(setUploadProgress(progress));
        },
        method: "POST",
        url: "/maintenance/t3_tpt_upload_initial_inspection",
        headers,
        transformRequest: (data, headers) => {
          return formData;
        },
        data: formData,
        responseType: "json",
      };

      const { data } = await axiosInstance.request(config);

      dispatch(setLoading(false));
      if (data.success) {
        await dispatch(
          fetchMaintenanceListByProperty({
            type: "Maintenance",
            status: payload.type,
          })
        );
        await dispatch(
          fetchMaintenanceById({ maintenance_id: payload.maintenance_id })
        );
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

/**
 * Maintenance T1
 */

export const ownerRespondToT1Maintenance = createAsyncThunk(
  "maintenance/t1/owner_respond",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const formData = new FormData();
      Object.keys(payload).map((key) => {
        if (key === "document") {
          payload[key].forEach((item) => {
            if (PLATFORM !== "web") {
              formData.append(key, {
                name: item.name,
                type: item.type,
                uri:
                  PLATFORM === "android"
                    ? item.uri
                    : item.uri.replace("file://", ""),
              });
            } else {
              formData.append(key, item.file, item.name);
            }
          });
        } else {
          if (key === "description") {
            // stringifying array of string
            formData.append(key, JSON.stringify(payload[key]));
          } else {
            formData.append(key, payload[key]);
          }
        }
      });
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("user_token")}`,
      };
      if (PLATFORM !== "web") {
        headers["Content-Type"] = "multipart/form-data";
      }
      // const response = await fetch(API_URL + "/maintenance/owner_respond", {
      //   method: "POST",
      //   headers: headers,
      //   body: formData,
      // });
      // const data = await response.json();
      dispatch(setShowUploadProgress(true));
      const config = {
        onUploadProgress: ({ loaded, total }) => {
          const progress = (loaded / total) * 100;
          dispatch(setUploadProgress(progress));
        },
        method: "POST",
        url: "/maintenance/owner_respond",
        headers,
        transformRequest: (data, headers) => {
          return formData;
        },
        data: formData,
        responseType: "json",
      };

      // const data = await response.json();

      const { data } = await axiosInstance.request(config);

      dispatch(setLoading(false));
      if (data.success) {
        await dispatch(
          fetchMaintenanceListByProperty({
            type: "Maintenance",
            status: payload.type,
          })
        );
        await dispatch(
          fetchMaintenanceById({ maintenance_id: payload.maintenance_id })
        );
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

// Cancel The Ticket By OWNER
export const ownerCancelT1MaintenanceTicket = createAsyncThunk(
  "maintenance/t1/owner_cancel_maintenance",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const { data } = await axios.post(
        API_URL + "/maintenance/owner_cancel_maintenance",
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      dispatch(setLoading(false));
      if (data.success) {
        await dispatch(
          fetchMaintenanceListByProperty({
            type: "Maintenance",
            status: payload.type,
          })
        );
        await dispatch(
          fetchMaintenanceById({ maintenance_id: payload.maintenance_id })
        );
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

export const tenantRespondToT1Maintenance = createAsyncThunk(
  "maintenance/t1/tenant_respond",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const formData = new FormData();
      Object.keys(payload).map((key) => {
        if (key === "document") {
          payload[key].forEach((item) => {
            if (PLATFORM !== "web") {
              formData.append(key, {
                name: item.name,
                type: item.type,
                uri:
                  PLATFORM === "android"
                    ? item.uri
                    : item.uri.replace("file://", ""),
              });
            } else {
              formData.append(key, item.file, item.name);
            }
          });
        } else {
          if (key === "description") {
            formData.append(key, JSON.stringify(payload[key]));
          } else {
            formData.append(key, payload[key]);
          }
        }
      });
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("user_token")}`,
      };
      if (PLATFORM !== "web") {
        headers["Content-Type"] = "multipart/form-data";
      }
      dispatch(setShowUploadProgress(true));
      const config = {
        onUploadProgress: ({ loaded, total }) => {
          const progress = (loaded / total) * 100;
          dispatch(setUploadProgress(progress));
        },
        method: "POST",
        url: "/maintenance/tenant_respond",
        headers,
        transformRequest: (data, headers) => {
          return formData;
        },
        data: formData,
        responseType: "json",
      };

      const { data } = await axiosInstance.request(config);

      dispatch(setLoading(false));

      if (data.success) {
        await dispatch(
          fetchMaintenanceListByProperty({
            type: "Maintenance",
            status: payload.type,
          })
        );
        await dispatch(
          fetchMaintenanceById({ maintenance_id: payload.maintenance_id })
        );
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      return rejectWithValue(error?.response);
    }
  }
);

/**
 *
 */

const createThunk = (name, end_point) => {
  return createAsyncThunk(
    name,
    async (payload, { rejectWithValue, getState, dispatch }) => {
      try {
        const { data } = await axios.post(API_URL + end_point, payload, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        });
        if (data.success) {
          await dispatch(
            fetchMaintenanceListByProperty({
              type: "Maintenance",
              status: payload.type,
            })
          );
          await dispatch(
            fetchMaintenanceById({ maintenance_id: payload.maintenance_id })
          );
          return data.data;
        } else {
          return rejectWithValue(data.error.message);
        }
      } catch (error) {
        return rejectWithValue(error?.response);
      }
    }
  );
};

export const ownerMarkOnTheWay = createThunk(
  "maintenance/t1/owner_on_the_way",
  "/maintenance/owner_on_the_way"
);

export const ownerMarkCheckedIn = createThunk(
  "maintenance/t1/owner_check_in",
  "/maintenance/owner_check_in"
);

export const ownerMarkAsNoAccess = createThunk(
  "maintenance/t1/owner_mark_no_access",
  "/maintenance/owner_mark_no_access"
);

export const tenantRescheduleT1Maintenance = createThunk(
  "maintenance/t1/tenant_reschedule_t1_maintenance",
  "/maintenance/tenant_reschedule_t1_maintenance"
);

/**
 *    T3 maintenance with TPM starts from here
 */

export const tpmRespondToT3Maintenance = createThunk(
  "maintenance/t3/tpm_respond",
  "/maintenance/action_by_3PM"
);

export const tpmAssignTechnician = createThunk(
  "maintenance/t3/tpm_assign_technician",
  "/maintenance/assign_third_party_technician"
);

export const tpTechnicianTakeAction = createThunk(
  "maintenance/t3/tpTechnician_take_action",
  "/maintenance/party_technician_take_action"
);

export const tptMarkTechnicianOnTheWay = createThunk(
  "maintenance/t3/tpt_mark_tech_on_the_way",
  "/maintenance/party_technician_on_the_way"
);

export const tpTechnicianMarkAsNoAccess = createThunk(
  "maintenance/t3/tpTechnician_mark_as_no_access",
  "/maintenance/party_technician_mark_no_access"
);

export const tpTechnicianCheckedIn = createThunk(
  "maintenance/t3/tpTechnician_checked_in",
  "/maintenance/party_technician_checked_in"
);

export const tpTechnicianStartWork = createThunk(
  "maintenance/t3/tpTechnician_started_work",
  "/maintenance/party_technician_started_work"
);

export const tpTechnicianHandoverItems = createThunk(
  "maintenance/t3/tpTechnician_handover_items",
  "/maintenance/party_technician_handover_items"
);

export const tpTechnicianMarkJobComplete1 = createThunk(
  "maintenance/t3/tpTechnician_completed_the_work",
  "/maintenance/party_technician_completed_the_work"
);

export const tpTechnicianMarkJobComplete = createAsyncThunk(
  "maintenance/t3/tpTechnician_completed_the_work",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const formData = new FormData();
      Object.keys(payload).map((key) => {
        if (key === "document") {
          payload[key].forEach((item) => {
            if (PLATFORM !== "web") {
              formData.append(key, {
                name: item.name,
                type: item.type,
                uri:
                  PLATFORM === "android"
                    ? item.uri
                    : item.uri.replace("file://", ""),
              });
            } else {
              formData.append(key, item.file, item.name);
            }
          });
        } else {
          if (key === "description") {
            // stringifying array of string
            formData.append(key, JSON.stringify(payload[key]));
          } else {
            formData.append(key, payload[key]);
          }
        }
      });
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("user_token")}`,
      };
      if (PLATFORM !== "web") {
        headers["Content-Type"] = "multipart/form-data";
      }
      // const response = await fetch(API_URL + "/maintenance/owner_respond", {
      //   method: "POST",
      //   headers: headers,
      //   body: formData,
      // });
      // const data = await response.json();
      dispatch(setShowUploadProgress(true));
      const config = {
        onUploadProgress: ({ loaded, total }) => {
          const progress = (loaded / total) * 100;
          dispatch(setUploadProgress(progress));
        },
        method: "POST",
        url: "/maintenance/party_technician_completed_the_work",
        headers,
        transformRequest: (data, headers) => {
          return formData;
        },
        data: formData,
        responseType: "json",
      };

      // const data = await response.json();

      const { data } = await axiosInstance.request(config);

      dispatch(setLoading(false));
      if (data.success) {
        await dispatch(
          fetchMaintenanceListByProperty({
            type: "Maintenance",
            status: payload.type,
          })
        );
        await dispatch(
          fetchMaintenanceById({ maintenance_id: payload.maintenance_id })
        );
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

export const partyTenantRecheduleMaintenance = createThunk(
  "maintenance/t3/tpTenantRecheduleMaintenance",
  "/maintenance/party_tenant_reschedule_maintenance"
);

export const tenantRespondToPartyMaintenance = createThunk(
  "maintenance/t3/tenantRespondToPartyMaintenance",
  "/maintenance/party_tenant_respond_to_maintenance"
);
/**
 *    T3 maintenance with TPM ends here
 */

/**
 *    T2 maintenance with FM starts here
 */

export const fmRespondToT2Maintenance = createThunk(
  "maintenance/t2/fm_respond",
  "/maintenance/t2_action_by_fm"
);

export const fmAssignT2Technician = createThunk(
  "maintenance/t2/fm_assign_technician",
  "/maintenance/t2_fm_assign_maintenance_technician"
);

export const t2TechnicianTakeAction = createThunk(
  "maintenance/t2/technician_take_action",
  "/maintenance/t2_technician_take_action"
);

export const t2MarkTechnicianOnTheWay = createThunk(
  "maintenance/t2/mark_tech_on_the_way",
  "/maintenance/mark_t2_technician_on_the_way"
);

export const t2TechnicianMarkAsNoAccess = createThunk(
  "maintenance/t2/technician_mark_as_no_access",
  "/maintenance/t2_technician_mark_as_no_access"
);

export const t2TechnicianCheckedIn = createThunk(
  "maintenance/t2/Technician_checked_in",
  "/maintenance/t2_technician_checked_in"
);

export const t2TechnicianStartWork = createThunk(
  "maintenance/t2/technician_started_work",
  "/maintenance/t2_technician_started_work"
);

export const t2TechnicianHandoverItems = createThunk(
  "maintenance/t2/technician_handover_items",
  "/maintenance/t2_technician_handover_items"
);

// export const t2TechnicianMarkJobComplete = createThunk(
//   "maintenance/t2/technician_completed_the_work",
//   "/maintenance/t2_technician_completed_the_work"
// );

export const t2TechnicianMarkJobComplete = createAsyncThunk(
  "maintenance/t2/technician_completed_the_work",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const formData = new FormData();
      Object.keys(payload).map((key) => {
        if (key === "document") {
          payload[key].forEach((item) => {
            if (PLATFORM !== "web") {
              formData.append(key, {
                name: item.name,
                type: item.type,
                uri:
                  PLATFORM === "android"
                    ? item.uri
                    : item.uri.replace("file://", ""),
              });
            } else {
              formData.append(key, item.file, item.name);
            }
          });
        } else {
          if (key === "description") {
            // stringifying array of string
            formData.append(key, JSON.stringify(payload[key]));
          } else {
            formData.append(key, payload[key]);
          }
        }
      });
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("user_token")}`,
      };
      if (PLATFORM !== "web") {
        headers["Content-Type"] = "multipart/form-data";
      }
      // const response = await fetch(API_URL + "/maintenance/owner_respond", {
      //   method: "POST",
      //   headers: headers,
      //   body: formData,
      // });
      // const data = await response.json();
      dispatch(setShowUploadProgress(true));
      const config = {
        onUploadProgress: ({ loaded, total }) => {
          const progress = (loaded / total) * 100;
          dispatch(setUploadProgress(progress));
        },
        method: "POST",
        url: "/maintenance/t2_technician_completed_the_work",
        headers,
        transformRequest: (data, headers) => {
          return formData;
        },
        data: formData,
        responseType: "json",
      };

      // const data = await response.json();

      const { data } = await axiosInstance.request(config);

      dispatch(setLoading(false));
      if (data.success) {
        await dispatch(
          fetchMaintenanceListByProperty({
            type: "Maintenance",
            status: payload.type,
          })
        );
        await dispatch(
          fetchMaintenanceById({ maintenance_id: payload.maintenance_id })
        );
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

export const t2TenantRecheduleMaintenance = createThunk(
  "maintenance/t2/tpTenantRecheduleMaintenance",
  "/maintenance/t2_tenant_reschedule_maintenance"
);

// export const tenantRespondToT2Maintenance = createThunk(
//   "maintenance/t2/tenantRespondToMaintenance",
//   "/maintenance/t2_tenant_respond_to_maintenance"
// );

/**
 *    T2 maintenance with FM ends here
 */

/**
 *    T2 maintenance with TPM starts from here
 */

export const tpmRespondToT2Maintenance = createThunk(
  "maintenance/t2/tpm_respond",
  "/maintenance/action_by_t2_3PM"
);

export const tpmAssignT2Technician = createThunk(
  "maintenance/t2/tpm_assign_technician",
  "/maintenance/assign_t2_third_party_technician"
);

export const t2tpTechnicianTakeAction = createThunk(
  "maintenance/t2/tpTechnician_take_action",
  "/maintenance/t2_party_technician_take_action"
);

export const fmRespondingToTPMQuote = createThunk(
  "quote/t2_respond_quotation_request",
  "/quote/t2_respond_quotation_request"
);

export const t2tptMarkTechnicianOnTheWay = createThunk(
  "maintenance/t2/tpt_mark_tech_on_the_way",
  "/maintenance/t2_party_technician_on_the_way"
);

export const t2tpTechnicianMarkAsNoAccess = createThunk(
  "maintenance/t2/tpTechnician_mark_as_no_access",
  "/maintenance/t2_technician_mark_as_no_access"
);

export const t2tpTechnicianCheckedIn = createThunk(
  "maintenance/t2/tpTechnician_checked_in",
  "/maintenance/t2_party_technician_checked_in"
);

export const t2tpTechnicianStartWork = createThunk(
  "maintenance/t2/tpTechnician_started_work",
  "/maintenance/t2_party_technician_started_work"
);

export const t2tpTechnicianHandoverItems = createThunk(
  "maintenance/t2/tpTechnician_handover_items",
  "/maintenance/t2_party_technician_handover_items"
);

// export const t2tpTechnicianMarkJobComplete = createThunk(
//   "maintenance/t2/tpTechnician_completed_the_work",
//   "/maintenance/t2_party_technician_completed_the_work"
// );

export const t2tpTechnicianMarkJobComplete = createAsyncThunk(
  "maintenance/t2/tpTechnician_completed_the_work",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const formData = new FormData();
      Object.keys(payload).map((key) => {
        if (key === "document") {
          payload[key].forEach((item) => {
            if (PLATFORM !== "web") {
              formData.append(key, {
                name: item.name,
                type: item.type,
                uri:
                  PLATFORM === "android"
                    ? item.uri
                    : item.uri.replace("file://", ""),
              });
            } else {
              formData.append(key, item.file, item.name);
            }
          });
        } else {
          if (key === "description") {
            // stringifying array of string
            formData.append(key, JSON.stringify(payload[key]));
          } else {
            formData.append(key, payload[key]);
          }
        }
      });
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("user_token")}`,
      };
      if (PLATFORM !== "web") {
        headers["Content-Type"] = "multipart/form-data";
      }
      // const response = await fetch(API_URL + "/maintenance/owner_respond", {
      //   method: "POST",
      //   headers: headers,
      //   body: formData,
      // });
      // const data = await response.json();
      dispatch(setShowUploadProgress(true));
      const config = {
        onUploadProgress: ({ loaded, total }) => {
          const progress = (loaded / total) * 100;
          dispatch(setUploadProgress(progress));
        },
        method: "POST",
        url: "/maintenance/t2_party_technician_completed_the_work",
        headers,
        transformRequest: (data, headers) => {
          return formData;
        },
        data: formData,
        responseType: "json",
      };

      // const data = await response.json();

      const { data } = await axiosInstance.request(config);

      dispatch(setLoading(false));
      if (data.success) {
        await dispatch(
          fetchMaintenanceListByProperty({
            type: "Maintenance",
            status: payload.type,
          })
        );
        await dispatch(
          fetchMaintenanceById({ maintenance_id: payload.maintenance_id })
        );
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

export const t2partyTenantRecheduleMaintenance = createThunk(
  "maintenance/t2/tpTenantRecheduleMaintenance",
  "/maintenance/t2_party_tenant_reschedule_maintenance"
);

// export const t2tenantRespondToPartyMaintenance = createThunk(
//   "maintenance/t2/tenantRespondToPartyMaintenance",
//   "/maintenance/t2_party_tenant_respond_to_maintenance"
// );

/**
 *    T2 maintenance with TPM ends here
 */

export const createMaintenanceReq = createAsyncThunk(
  "maintenance/create",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const { property_id, unit } = payload;
      dispatch(setLoading(true));
      const formData = new FormData();
      Object.keys(payload).map((key) => {
        if (key === "document") {
          if (PLATFORM !== "web") {
            payload[key].forEach((file) => {
              formData.append(key, {
                name: file.name,
                type: file.type,
                uri:
                  PLATFORM === "android"
                    ? file.uri
                    : file.uri.replace("file://", ""),
              });
            });
          } else {
            payload[key].forEach((file) => {
              formData.append(key, file, file.name);
            });
          }
        } else {
          formData.append(key, payload[key]);
        }
      });
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("user_token")}`,
      };
      if (PLATFORM !== "web") {
        headers["Content-Type"] = "multipart/form-data";
      }
      // const response = await fetch(API_URL + "/maintenance/add", {
      //   method: "POST",
      //   headers: headers,
      //   body: formData,
      // });
      // const data = await response.json();
      dispatch(setShowUploadProgress(true));
      const config = {
        onUploadProgress: ({ loaded, total }) => {
          const progress = (loaded / total) * 100;
          dispatch(setUploadProgress(progress));
        },
        method: "POST",
        url: "/maintenance/add",
        headers,
        transformRequest: (data, headers) => {
          return formData;
        },
        data: formData,
        responseType: "json",
      };

      // const data = await response.json();

      const { data } = await axiosInstance.request(config);

      dispatch(setLoading(false));
      if (data.success) {
        await dispatch(fetchMaintenanceListByUnit({ unit_id: unit }));
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

export const ownerAddT1MaintenanceInspectionReport = createAsyncThunk(
  "maintenance/owner_add_t1_maintenance_inspection_report",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const formData = new FormData();
      // Object.keys(payload).map((key) => {
      //   if (key === "document") {
      //     if (PLATFORM !== "web") {
      //       payload[key].forEach((file) => {
      //         formData.append(key, {
      //           name: file.name,
      //           type: file.type,
      //           uri:
      //             PLATFORM === "android"
      //               ? file.uri
      //               : file.uri.replace("file://", ""),
      //         });
      //       });
      //     } else {
      //       payload[key].forEach((file) => {
      //         formData.append(key, file, file.name);
      //       });
      //     }
      //   } else {
      //     formData.append(key, payload[key]);
      //   }
      // });
      Object.keys(payload).map((key) => {
        if (key === "document") {
          payload[key].forEach((item) => {
            if (PLATFORM !== "web") {
              formData.append(key, {
                name: item.name,
                type: item.type,
                uri:
                  PLATFORM === "android"
                    ? item.uri
                    : item.uri.replace("file://", ""),
              });
            } else {
              formData.append(key, item.file, item.name);
            }
          });
        } else {
          if (key === "description") {
            formData.append(key, JSON.stringify(payload[key]));
          } else {
            formData.append(key, payload[key]);
          }
        }
      });
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("user_token")}`,
      };
      if (PLATFORM !== "web") {
        headers["Content-Type"] = "multipart/form-data";
      }

      dispatch(setShowUploadProgress(true));
      const config = {
        onUploadProgress: ({ loaded, total }) => {
          const progress = (loaded / total) * 100;
          dispatch(setUploadProgress(progress));
        },
        method: "POST",
        url: "/maintenance/owner_add_t1_maintenance_inspection_report",
        headers,
        transformRequest: (data, headers) => {
          return formData;
        },
        data: formData,
        responseType: "json",
      };

      const { data } = await axiosInstance.request(config);

      dispatch(setLoading(false));
      if (data.success) {
        await dispatch(
          fetchMaintenanceListByProperty({
            type: "Maintenance",
            status: payload.type,
          })
        );
        await dispatch(
          fetchMaintenanceById({ maintenance_id: payload.maintenance_id })
        );
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

export const mtAddInspection_t2_tenant = createAsyncThunk(
  "maintenance/t2_mt_add_initial_report",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const formData = new FormData();
      // Object.keys(payload).map((key) => {
      //   if (key === "document") {
      //     if (PLATFORM !== "web") {
      //       payload[key].forEach((file) => {
      //         formData.append(key, {
      //           name: file.name,
      //           type: file.type,
      //           uri:
      //             PLATFORM === "android"
      //               ? file.uri
      //               : file.uri.replace("file://", ""),
      //         });
      //       });
      //     } else {
      //       payload[key].forEach((file) => {
      //         formData.append(key, file, file.name);
      //       });
      //     }
      //   } else {
      //     formData.append(key, payload[key]);
      //   }
      // });
      Object.keys(payload).map((key) => {
        if (key === "document") {
          payload[key].forEach((item) => {
            if (PLATFORM !== "web") {
              formData.append(key, {
                name: item.name,
                type: item.type,
                uri:
                  PLATFORM === "android"
                    ? item.uri
                    : item.uri.replace("file://", ""),
              });
            } else {
              formData.append(key, item.file, item.name);
            }
          });
        } else {
          if (key === "description") {
            formData.append(key, JSON.stringify(payload[key]));
          } else {
            formData.append(key, payload[key]);
          }
        }
      });
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("user_token")}`,
      };
      if (PLATFORM !== "web") {
        headers["Content-Type"] = "multipart/form-data";
      }

      dispatch(setShowUploadProgress(true));
      const config = {
        onUploadProgress: ({ loaded, total }) => {
          const progress = (loaded / total) * 100;
          dispatch(setUploadProgress(progress));
        },
        method: "POST",
        url: "/maintenance/t2_mt_add_initial_report",
        headers,
        transformRequest: (data, headers) => {
          return formData;
        },
        data: formData,
        responseType: "json",
      };

      const { data } = await axiosInstance.request(config);

      dispatch(setLoading(false));
      if (data.success) {
        await dispatch(
          fetchMaintenanceListByProperty({
            type: "Maintenance",
            status: payload.type,
          })
        );
        await dispatch(
          fetchMaintenanceById({ maintenance_id: payload.maintenance_id })
        );
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

export const tenantRespondToT2Maintenance = createAsyncThunk(
  "maintenance/t2/tenantRespondToMaintenance",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const formData = new FormData();
      Object.keys(payload).map((key) => {
        if (key === "document") {
          payload[key].forEach((item) => {
            if (PLATFORM !== "web") {
              formData.append(key, {
                name: item.name,
                type: item.type,
                uri:
                  PLATFORM === "android"
                    ? item.uri
                    : item.uri.replace("file://", ""),
              });
            } else {
              formData.append(key, item.file, item.name);
            }
          });
        } else {
          if (key === "description") {
            formData.append(key, JSON.stringify(payload[key]));
          } else {
            formData.append(key, payload[key]);
          }
        }
      });
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("user_token")}`,
      };
      if (PLATFORM !== "web") {
        headers["Content-Type"] = "multipart/form-data";
      }

      dispatch(setShowUploadProgress(true));
      const config = {
        onUploadProgress: ({ loaded, total }) => {
          const progress = (loaded / total) * 100;
          dispatch(setUploadProgress(progress));
        },
        method: "POST",
        url: "/maintenance/t2_tenant_respond_to_maintenance",
        headers,
        transformRequest: (data, headers) => {
          return formData;
        },
        data: formData,
        responseType: "json",
      };

      const { data } = await axiosInstance.request(config);

      dispatch(setLoading(false));
      if (data.success) {
        await dispatch(
          fetchMaintenanceListByProperty({
            type: "Maintenance",
            status: payload.type,
          })
        );
        await dispatch(
          fetchMaintenanceById({ maintenance_id: payload.maintenance_id })
        );
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

export const tptAddInspection_t2_tenant = createAsyncThunk(
  "maintenance/t2_party_technician_add_initial_report",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const formData = new FormData();

      Object.keys(payload).map((key) => {
        if (key === "document") {
          payload[key].forEach((item) => {
            if (PLATFORM !== "web") {
              formData.append(key, {
                name: item.name,
                type: item.type,
                uri:
                  PLATFORM === "android"
                    ? item.uri
                    : item.uri.replace("file://", ""),
              });
            } else {
              formData.append(key, item.file, item.name);
            }
          });
        } else {
          if (key === "description") {
            formData.append(key, JSON.stringify(payload[key]));
          } else {
            formData.append(key, payload[key]);
          }
        }
      });
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("user_token")}`,
      };
      if (PLATFORM !== "web") {
        headers["Content-Type"] = "multipart/form-data";
      }

      dispatch(setShowUploadProgress(true));
      const config = {
        onUploadProgress: ({ loaded, total }) => {
          const progress = (loaded / total) * 100;
          dispatch(setUploadProgress(progress));
        },
        method: "POST",
        url: "/maintenance/t2_party_technician_add_initial_report",
        headers,
        transformRequest: (data, headers) => {
          return formData;
        },
        data: formData,
        responseType: "json",
      };

      const { data } = await axiosInstance.request(config);

      dispatch(setLoading(false));
      if (data.success) {
        await dispatch(
          fetchMaintenanceListByProperty({
            type: "Maintenance",
            status: payload.type,
          })
        );
        await dispatch(
          fetchMaintenanceById({ maintenance_id: payload.maintenance_id })
        );
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

export const t2tenantRespondToPartyMaintenance = createAsyncThunk(
  "maintenance/t2/tenantRespondToPartyMaintenance",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const formData = new FormData();
      Object.keys(payload).map((key) => {
        if (key === "document") {
          payload[key].forEach((item) => {
            if (PLATFORM !== "web") {
              formData.append(key, {
                name: item.name,
                type: item.type,
                uri:
                  PLATFORM === "android"
                    ? item.uri
                    : item.uri.replace("file://", ""),
              });
            } else {
              formData.append(key, item.file, item.name);
            }
          });
        } else {
          if (key === "description") {
            formData.append(key, JSON.stringify(payload[key]));
          } else {
            formData.append(key, payload[key]);
          }
        }
      });
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("user_token")}`,
      };
      if (PLATFORM !== "web") {
        headers["Content-Type"] = "multipart/form-data";
      }

      dispatch(setShowUploadProgress(true));
      const config = {
        onUploadProgress: ({ loaded, total }) => {
          const progress = (loaded / total) * 100;
          dispatch(setUploadProgress(progress));
        },
        method: "POST",
        url: "/maintenance/t2_party_tenant_respond_to_maintenance",
        headers,
        transformRequest: (data, headers) => {
          return formData;
        },
        data: formData,
        responseType: "json",
      };

      const { data } = await axiosInstance.request(config);

      dispatch(setLoading(false));
      if (data.success) {
        await dispatch(
          fetchMaintenanceListByProperty({
            type: "Maintenance",
            status: payload.type,
          })
        );
        await dispatch(
          fetchMaintenanceById({ maintenance_id: payload.maintenance_id })
        );
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

export const assignTechnician = createAsyncThunk(
  "maintenance/assign_technician",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const { maintenance_id, type, property_id } = payload;
      dispatch(setLoading(true));
      const { data } = await axios.post(
        `${API_URL}/maintenance/assign_technician`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      if (data.success) {
        await dispatch(fetchMaintenanceListByProperty({ property_id, type }));
        await dispatch(fetchMaintenanceById({ maintenance_id }));
        dispatch(setLoading(false));
        return data.data;
      } else {
        dispatch(setLoading(false));
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

export const listTechnicians = createAsyncThunk(
  "maintenance/technicians/list",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const { property_id } = payload;
      dispatch(setLoading(true));
      const { data } = await axios.get(
        `${API_URL}/maintenance/technician/${property_id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      dispatch(setLoading(false));
      if (data.success) {
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

export const fetchMaintenanceListByProperty = createAsyncThunk(
  "maintenance/listByProperty",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const { property_id } = payload;
      dispatch(setLoading(true));
      const { data } = await axios.post(
        API_URL + "/maintenance/list",
        // 'http://localhost:3000/maintenance/list',
        { property_id: property_id, type: payload.type, page: payload?.page },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      dispatch(setLoading(false));
      if (data.success) {
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

export const fetchMaintenanceById = createAsyncThunk(
  "maintenance/get",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const { data } = await axios.post(API_URL + "/maintenance/get", payload, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("user_token")}`,
        },
      });
      dispatch(setLoading(false));
      if (data.success) {
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

// Cancel Maintenance Ticket By Tenant
export const cancelMaintenanceTicketByTenant = createAsyncThunk(
  "maintenance/cancel_request",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const { data } = await axios.post(
        API_URL + "/maintenance/cancel_request",
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      dispatch(setLoading(false));
      if (data.success) {
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

export const fetchMaintenanceListByUnit = createAsyncThunk(
  "maintenance/listByUnit",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const { data } = await axios.post(
        API_URL + "/maintenance/list_by_unit",
        // 'http://localhost:3000/maintenance/list_by_unit',
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      dispatch(setLoading(false));
      if (data.success) {
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

export const fetchMaintenanceLocations = createAsyncThunk(
  "maintenance/locations",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const { data } = await axios.get(API_URL + "/maintenance_location/list");
      if (data.success) {
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      return rejectWithValue(error?.response);
    }
  }
);

export const fetchMaintenanceOutOfUnitCategories = createAsyncThunk(
  "maintenance/out_of_unit_categories",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const { data } = await axios.get(
        `${API_URL}/maintenance_req_out_unit/list`
      );
      if (data.success) {
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      return rejectWithValue(error?.response);
    }
  }
);

export const markMaintenanceJobComplete = createAsyncThunk(
  "maintenance/mark_complete",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const { property_id } = payload;
      const { data } = await axios.post(
        API_URL + "/maintenance/accept",
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      if (data.success) {
        await dispatch(
          fetchMaintenanceListByProperty({ property_id, type: payload.type })
        );
        await dispatch(
          fetchMaintenanceById({ maintenance_id: payload.maintenance_id })
        );
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      return rejectWithValue(error?.response);
    }
  }
);

export const fetchMaintenanceCategories = createAsyncThunk(
  "maintenance/categories",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const { data } = await axios.get(
        `${API_URL}/maintenance_category/list?id=${payload}`
      );
      if (data.success) {
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      return rejectWithValue(error?.response);
    }
  }
);

export const fetchMaintenanceSubCategories = createAsyncThunk(
  "maintenance/sub_categories",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const { data } = await axios.get(
        `${API_URL}/maintenance_subcategory/list?id=${payload}`
      );
      if (data.success) {
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      return rejectWithValue(error?.response);
    }
  }
);

export const fetchMaintenanceInnerCategories = createAsyncThunk(
  "maintenance/inner_categories",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const { data } = await axios.get(
        `${API_URL}/maintenance_innercategory/list?id=${payload}`
      );
      if (data.success) {
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      return rejectWithValue(error?.response);
    }
  }
);

export const fetchAllNewMaintenance = createAsyncThunk(
  "maintenance/list_all_new",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const { data } = await axios.post(
        API_URL + "/maintenance/list_all_new",
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      dispatch(setLoading(false));
      if (data.success) {
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

export const fetchAllOngoingMaintenance = createAsyncThunk(
  "maintenance/list_all_ongoing",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const { data } = await axios.post(
        API_URL + "/maintenance/list_all_ongoing",
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      dispatch(setLoading(false));
      if (data.success) {
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

export const fetchAllCompletedMaintenance = createAsyncThunk(
  "maintenance/list_all_completed",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const { data } = await axios.post(
        API_URL + "/maintenance/list_all_completed",
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      dispatch(setLoading(false));
      if (data.success) {
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

export const fetchAllRejectedMaintenance = createAsyncThunk(
  "maintenance/list_all_rejected",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const { data } = await axios.post(
        API_URL + "/maintenance/list_all_rejected",
        // 'http://localhost:3000/maintenance/list_all_rejected',
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      dispatch(setLoading(false));
      if (data.success) {
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

export const fetchAllEscalatedMaintenance = createAsyncThunk(
  "maintenance/list_all_escalated",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const { data } = await axios.post(
        API_URL + "/maintenance/list_all_escalated",
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      dispatch(setLoading(false));
      if (data.success) {
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

export const fetchAllClosedMaintenance = createAsyncThunk(
  "maintenance/list_all_closed",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const { data } = await axios.post(
        API_URL + "/maintenance/list_all_closed",
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      dispatch(setLoading(false));
      if (data.success) {
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

export const fetchAllInsideUnitMaintenance = createAsyncThunk(
  "maintenance/list_all_inside_unit",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const { data } = await axios.post(
        API_URL + "/maintenance/list_all_inside_unit",
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      dispatch(setLoading(false));
      if (data.success) {
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

export const fetchAllOutsideUnitMaintenance = createAsyncThunk(
  "maintenance/list_all_outside_unit",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const { data } = await axios.post(
        API_URL + "/maintenance/list_all_outside_unit",
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      dispatch(setLoading(false));
      if (data.success) {
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

export const fetchAllSparePartMaintenance = createAsyncThunk(
  "maintenance/list_all_spareParts",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const { data } = await axios.post(
        API_URL + "/maintenance/list_all_spareParts",
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      dispatch(setLoading(false));
      if (data.success) {
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

export const listManagers = createAsyncThunk(
  "maintenance/managers/list",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const { property_id } = payload;
      dispatch(setLoading(true));
      const { data } = await axios.get(
        `${API_URL}/maintenance/get_other_team/${property_id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      dispatch(setLoading(false));
      if (data.success) {
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

export const maintenanceSlice = createSlice({
  name: "maintenance",
  initialState,
  extraReducers: {
    [fetchMaintenanceById.fulfilled]: (state, action) => {
      state.maintenance = action.payload;
    },
    [fetchMaintenanceListByProperty.fulfilled]: (state, action) => {
      state.maintenanceList = action.payload;
    },
    [fetchMaintenanceListByProperty.rejected]: (state, action) => {
      state.maintenanceList = [];
    },
    [fetchMaintenanceListByUnit.fulfilled]: (state, action) => {
      state.maintenanceList = action.payload;
    },
    [fetchMaintenanceListByUnit.rejected]: (state, action) => {
      state.maintenanceList = [];
    },
    [fetchMaintenanceLocations.fulfilled]: (state, action) => {
      state.maintenanceLocations = action.payload;
    },
    [fetchMaintenanceCategories.fulfilled]: (state, action) => {
      state.maintenanceCategories = action.payload;
    },
    [fetchMaintenanceOutOfUnitCategories.fulfilled]: (state, action) => {
      state.maintenanceOuterCategories = action.payload;
    },
    [fetchMaintenanceSubCategories.fulfilled]: (state, action) => {
      state.maintenanceSubCategories = action.payload;
    },
    [fetchMaintenanceInnerCategories.fulfilled]: (state, action) => {
      state.maintenanceInnerCategories = action.payload;
    },
    [listTechnicians.fulfilled]: (state, action) => {
      state.technicians = action.payload;
    },
    [fetchAllNewMaintenance.fulfilled]: (state, action) => {
      state.maintenanceList = action.payload;
    },
    [fetchAllNewMaintenance.rejected]: (state, action) => {
      state.maintenanceList = [];
    },
    [fetchAllOngoingMaintenance.fulfilled]: (state, action) => {
      state.maintenanceList = action.payload;
    },
    [fetchAllOngoingMaintenance.rejected]: (state, action) => {
      state.maintenanceList = [];
    },
    [fetchAllCompletedMaintenance.fulfilled]: (state, action) => {
      state.maintenanceList = action.payload;
    },
    [fetchAllCompletedMaintenance.rejected]: (state, action) => {
      state.maintenanceList = [];
    },
    [fetchAllRejectedMaintenance.fulfilled]: (state, action) => {
      state.maintenanceList = action.payload;
    },
    [fetchAllRejectedMaintenance.rejected]: (state, action) => {
      state.maintenanceList = [];
    },
    [fetchAllEscalatedMaintenance.fulfilled]: (state, action) => {
      state.maintenanceEscalatedList = action.payload;
    },
    [fetchAllEscalatedMaintenance.rejected]: (state, action) => {
      state.maintenanceEscalatedList = [];
    },
    [fetchAllClosedMaintenance.fulfilled]: (state, action) => {
      state.maintenanceList = action.payload;
    },
    [fetchAllClosedMaintenance.rejected]: (state, action) => {
      state.maintenanceList = [];
    },
    [fetchAllInsideUnitMaintenance.fulfilled]: (state, action) => {
      state.maintenanceList = action.payload;
    },
    [fetchAllInsideUnitMaintenance.rejected]: (state, action) => {
      state.maintenanceList = [];
    },
    [fetchAllOutsideUnitMaintenance.fulfilled]: (state, action) => {
      state.maintenanceList = action.payload;
    },
    [fetchAllOutsideUnitMaintenance.rejected]: (state, action) => {
      state.maintenanceList = [];
    },
    [fetchAllSparePartMaintenance.fulfilled]: (state, action) => {
      state.maintenanceSpareList = action.payload;
    },
    [fetchAllSparePartMaintenance.rejected]: (state, action) => {
      state.maintenanceSpareList = [];
    },
    [listManagers.fulfilled]: (state, action) => {
      state.managers = action.payload;
    },
  },
});

export const getMaintenanceList = (state) => state.maintenance.maintenanceList;

export const getMaintenanceSpareList = (state) =>
  state.maintenance.maintenanceSpareList;

export const getMaintenanceEscaletedList = (state) =>
  state.maintenance.maintenanceEscalatedList;

export const getMaintenance = (state) => state.maintenance.maintenance;
export const getTechnicians = (state) => state.maintenance.technicians;
export const getManagers = (state) => state.maintenance.managers;
export const getMaintenanceLocations = (state) =>
  state.maintenance.maintenanceLocations;
export const getMaintenanceCategories = (state) =>
  state.maintenance.maintenanceCategories;
export const getMaintenanceSubCategories = (state) =>
  state.maintenance.maintenanceSubCategories;
export const getMaintenanceInnerCategories = (state) =>
  state.maintenance.maintenanceInnerCategories;
export const getMaintenanceOuterCategories = (state) =>
  state.maintenance.maintenanceOuterCategories;

export default maintenanceSlice.reducer;
