/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import GLOBAL from "../../setup/constants/global";
/* Import configuration ends */

/* Import redux slices component starts */
import { setLoading } from "../../setup/store/slices/unpersistedSlice";
import { fetchTenantApplicationByIdNew } from "../../setup/store/slices/tenantSlice";

import {
  fetchPropertyDetailByID,
  getPropertyDetail,
} from "../../setup/store/slices/propertySlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import NewTenantPMReview from "../../components/module/Tenant/NewTenantPMReview/NewTenantPMReview";
// import NewTenantPMReviewNewResp from "../../components/module/RnD/NewTenantPMReview/NewTenantPMReview";
import BreadCrumb from "../../components/ui/other/BreadCrumb/BreadCrumb";
/* Import local pages and component ends */

/* Component starts */
const NewTenantPMReviewPage = (props) => {
  /* Props destructuring starts */
  const {} = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [application, setApplication] = useState();
  const [sequence, setSequence] = useState([]);
  const [sequenceNumber, setSequenceNumber] = useState(null);
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const { propertyId, applicationId } = useParams();
  const dispatch = useDispatch();
  const property = useSelector(getPropertyDetail);
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */

  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  const initializeSequenceNumber = (data) => {
    let s = [];
    const isResidential = data?.is_unit_residential;
    const isCommercial = !data?.is_unit_residential;

    s.push("basic_details");
    if (isCommercial) {
      s.push("commercial_details");
    }
    if (data?.ids?.length > 0) {
      s.push("ids");
    }
    if (isResidential && data?.household_members?.length > 0) {
      s.push("household_members");
    }
    if (data?.disability) {
      s.push("disability");
    }
    if (data?.vehicles?.length > 0) {
      s.push("vehicles");
    }
    if (data?.pets?.length > 0) {
      s.push("pets");
    }
    if (data?.rental_history?.length > 0) {
      s.push("rental_history");
    }
    if (data?.application_fee) {
      s.push("application_fee");
    }
    s.push("installment_plan");
    s.push("initial_agreement");
    s.push("contract");
    if (data?.utilities?.length > 0) {
      s.push("utilities");
    }
    s.push("final_agreement");
    // s.push("application_rejected");
    s.push("maintenance");
    s.push("keys");

    setSequenceNumber(s);
  };

  const getApplication = async () => {
    try {
      const result = await dispatch(
        fetchTenantApplicationByIdNew(applicationId)
      );

      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          setSequence([]);
          setApplication();
          break;
        case GLOBAL.FULFILLED:
          initializeSequenceNumber(result.payload.data);
          setSequence(result.payload.sequence);
          setApplication(result.payload.data);
          break;
      }
    } catch (e) {
      console.error("error", e);
    }
  };

  const utilityRejected = () => {
    if (sequence.includes("pending_tenant_utility_submittion")) {
      return true;
    }
    return false;
  };
  /* Component function definition ends */

  /* Component useEffect starts */
  // Fetching tenant application data with the state recieved from routing while navigating here
  useEffect(() => {
    (async () => {
      try {
        await dispatch(setLoading(true));
        await dispatch(
          fetchPropertyDetailByID({
            property_id: propertyId,
          })
        );
        await dispatch(setLoading(false));
      } catch (e) {}
    })();
    if (applicationId) {
      getApplication();
    }
  }, [dispatch, applicationId]);

  /* ************* Component rendering. JSX code ************* */
  return (
    <div>
      <BreadCrumb type="onboard_application_pm_review" />

      {sequenceNumber && application && sequence?.length > 0 && (
        <NewTenantPMReview
          sequence={sequence}
          application={application}
          application_id={applicationId}
          getApplication={getApplication}
          sequenceNumber={sequenceNumber}
          utilityRejected={utilityRejected}
          property={property}
        />
      )}
    </div>
  );
};
/* Component ends */

export default NewTenantPMReviewPage;
