/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
/* Import configuration ends */

/* Import redux slices component starts */
import {
  fetchMaintenanceListByUnit,
  getMaintenanceList,
} from "../../setup/store/slices/maintenanceSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import TenantMaintenanceTable from "../../components/module/TenantMaintenance/TenantMaintenanceTable/TenantMaintenanceTable";
/* Import local pages and component ends */

// styles sheet
import "./style.scss";

/* Component starts */
const TenantMaintenance = (props) => {
  /* Props destructuring starts */
  const {} = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [currentPage, setCurrentPage] = useState(1);
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const dispatch = useDispatch();
  const { unitId } = useParams();
  // Redux Selectors
  const maintenanceList = useSelector(getMaintenanceList);
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  const fetchMaintenanceList = async () => {
    let payload = { unit_id: unitId, page: currentPage, limit: 10 };
    await dispatch(fetchMaintenanceListByUnit(payload));
  };
  /* Component function definition ends */

  /* Component useEffect starts */
  useEffect(() => {
    fetchMaintenanceList();
  }, [currentPage]);
  /* Component useEffect ends */
  /* ************* Component rendering. JSX code ************* */

  return (
    <div className="tenant-maintenance-wrapper">
      <div className="heading-wrapper">
        <div className="heading">
          <h2 className="font_xxl fw_5">Maintenances</h2>
        </div>
        <div className="register-wrapper">
          <Link to="add" className="font_m fw_4 d_block link">
            + Request Maintenance
          </Link>
        </div>
      </div>

      <TenantMaintenanceTable
        maintenanceList={maintenanceList?.maintenance}
        totalCount={maintenanceList?.totalCount}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
    </div>
  );
};
/* Component End */

export default TenantMaintenance;
