import React, { useEffect } from "react";
import { useState } from "react";

/* Import react bootstrap component starts */
import { Modal } from "react-bootstrap";
/* Import react bootstrap component ends */

/* Import image and SVG starts */
import {
  EditGreen,
  EditWhite,
  XGreen,
  XWhite,
} from "../../../../setup/constants/images";
/* Import image and SVG ends */

/* Import local pages and component starts */
import CustomButton from "../../../ui/button/CustomButton/CustomButton";
import TextButton from "../../../ui/button/TextButton/TextButton";
import CustomTextInput from "../../../ui/input/CustomTextInput/CustomTextInput";
/* Import local pages and component ends */

import "./style.scss";
import { getUserTheme } from "../../../../setup/store/slices/authSlice";
import { useSelector } from "react-redux";

/* Component starts */
const EditUtilityDocumentsModal = (props) => {
  /* Props destructuring starts */
  const { showModal, onSave, size = "md", onClose, docList } = props;
  /* Props destructuring ends */

  const [editMode, setEditMode] = useState({ enable: false, index: null });
  const [docListCopy, setDocListCopy] = useState([]);
  const [errorFields, setErrorFields] = useState([]);
  const [error, setError] = useState(false);

  const userTheme = useSelector(getUserTheme);

  const handleChangeDoc = (index, value) => {
    setDocListCopy((prevState) => {
      const newDocList = [...prevState];
      newDocList[index] = value;
      return newDocList;
    });
  };

  const toggleEditMode = (switchEdit = false, index) => {
    if (switchEdit) {
      setEditMode({ enable: true, index });
    } else {
      // if (docListCopy[index] (index === docListCopy.length - 1)) {
      //   setDocListCopy((prevState) => {
      //     const newArr = [...prevState];
      //     newArr.splice(index, 1);
      //     return newArr;
      //   });
      // } else {
      // }
      setEditMode({ enable: false, index: null });

      // setDocListCopy(docList);
    }
  };

  const handleClose = () => {
    setEditMode({ enable: false, index: null });
    setDocListCopy(docList.length > 0 ? docList : [""]);
    onClose();
  };

  const addField = () => {
    setDocListCopy((prevState) => [...prevState, ""]);
    setEditMode({ enable: true, index: docListCopy.length });
  };

  const validation = () => {
    let errorFound = false;
    let errorIndx = [];
    for (let i = 0; i < docListCopy.length; i++) {
      if (docListCopy[i] === "") {
        errorIndx.push(true);
        errorFound = true;
      } else {
        errorIndx.push(false);
      }
    }
    setErrorFields(errorIndx);
    setError(errorFound);
    if (errorFound) {
      return false;
    } else {
      return true;
    }
  };

  const removeField = (index) => {
    setDocListCopy((prevState) => {
      const newArr = [...prevState];
      newArr.splice(index, 1);
      return newArr;
    });
  };

  useEffect(() => {
    setDocListCopy(docList.length > 0 ? docList : [""]);
  }, [docList]);
  /* ************* Component rendering. JSX code ************* */
  return (
    <Modal
      size={size}
      centered
      show={showModal}
      className="utility-doc-registration-modal"
    >
      <Modal.Body
        className={`${userTheme === "dark" ? "bg_darkTheme" : "bg_lightTheme"}`}
      >
        <div className="modal-header-custom">
          <h2 className="text-center font_blue font_l">
            Registration Documents
          </h2>
        </div>
        <div className="modal-body-custom">
          {docListCopy?.map((item, index) => (
            <div className="doc-wrapper">
              <div
                className={`doc ${editMode.index === index ? "underline" : ""}`}
                key={index}
              >
                {/* <input type="text" readOnly={true} /> */}

                {/* <input
                  type="text"
                  className="font_s font_yellow"
                  value={item}
                  readOnly={editMode.index !== index}
                  onChange={(e) => {
                    handleChangeDoc(index, e.target.value);
                  }}
                /> */}
                <div className="input-field">
                  <CustomTextInput
                    type="text"
                    label={
                      <span
                        className={`font_xs fw_5 ${
                          editMode.index === index ? "font_blue" : "font_grey"
                        }`}
                      >
                        Registration Document
                      </span>
                    }
                    className="font_s font_yellow w_100"
                    value={item}
                    required={false}
                    inputClass={`default-state ${
                      editMode.index === index ? "on-edit" : ""
                    }`}
                    // glowOnFocus={false}
                    // borderBlue={editMode.index === index}
                    readOnly={editMode.index !== index}
                    handleChange={(e) => {
                      handleChangeDoc(index, e.target.value);
                    }}
                  />
                </div>

                <div className="actions">
                  {editMode?.enable ? (
                    <>
                      {editMode.index === index && (
                        <img
                          className="icon"
                          onClick={() => toggleEditMode(false, index)}
                          src={XGreen}
                          alt="Cancel"
                        />
                      )}
                    </>
                  ) : (
                    <img
                      className="icon"
                      onClick={() => toggleEditMode(true, index)}
                      src={EditGreen}
                      alt="Edit"
                    />
                  )}
                  {docListCopy.length > 1 && (
                    <TextButton
                      className="remove"
                      onClick={() => {
                        removeField(index);
                      }}
                    >
                      <span className="font_m fw_6 font_red">Remove</span>
                    </TextButton>
                  )}
                </div>
              </div>
              {error && errorFields[index] && (
                <p className="font_xs font_red w_100 error_text">
                  Cannot be empty
                </p>
              )}
            </div>
          ))}
          <div className="actions">
            {docListCopy[docListCopy.length - 1] !== "" && (
              <TextButton onClick={addField}>
                <span className="font_m fw_6 font_blue">Add</span>
              </TextButton>
            )}
          </div>
        </div>
        <div className="modal-footer-custom">
          <CustomButton
            size="m"
            variant="primary"
            onClick={() => {
              if (validation()) {
                setEditMode({ enable: false, index: null });
                onSave(docListCopy);
              }
            }}
          >
            Save
          </CustomButton>
          <CustomButton size="m" variant="red" onClick={handleClose}>
            Close
          </CustomButton>
        </div>
      </Modal.Body>
    </Modal>
  );
};
/* Component ends */

export default EditUtilityDocumentsModal;
