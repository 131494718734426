/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import GLOBAL from "../../../../setup/constants/global";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
/* Import configuration ends */

/* Import redux slices component starts */
import { setLoading } from "../../../../setup/store/slices/unpersistedSlice";
import {
  fetchPropertyDetailByID,
  getPropertyDetail,
} from "../../../../setup/store/slices/propertySlice";
import { addUnit } from "../../../../setup/store/slices/unitSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import MUI component starts */
/* Import MUI component ends */

/* Import image and SVG starts */
import { WarningOutlineRed } from "../../../../setup/constants/images";
/* Import image and SVG ends */

/* Import local pages and component starts */
import SuccessModal from "../../../ui/modal/SuccessModal/SuccessModal";
import ErrorModal from "../../../ui/modal/ErrorModal/ErrorModal";
import CounterInput from "../../../ui/input/CounterInput/CounterInput";
import CustomButton from "../../../ui/button/CustomButton/CustomButton";
import TextButton from "../../../ui/button/TextButton/TextButton";
import BreadCrumb from "../../../ui/other/BreadCrumb/BreadCrumb";
/* Import local pages and component ends */

import "./style.scss";
import { getIsCLIENT } from "../../../../setup/store/slices/authSlice";

/* Component starts */
const AddUnitModule = (props) => {
  /* Props destructuring starts */
  const { onSuccess, onCancel } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [unitCount, setUnitCount] = useState(1);
  const [message, setMessage] = useState("");
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);

  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { propertyId } = useParams();

  // Redux Selector
  const property = useSelector(getPropertyDetail);
  const isClient =
    localStorage.getItem("user_role") === GLOBAL.USER_ROLE.CLIENT;
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  // On adding units to property
  const onAddingUnits = async () => {
    if (parseInt(unitCount) < 1) {
      setMessage({
        title: "Error",
        desc: "You cannot add 0 unit. Please select atleast 1 to add unit",
      });
      setShowErrorModal(true);
      return;
    }

    // Collection or creating payload data to be sent
    const payload = {
      number_of_units: unitCount,
      property_id: propertyId,
    };

    // Backend response. Try, Catch
    try {
      const result = await dispatch(addUnit(payload));

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          setMessage(result.payload);
          setShowErrorModal(true);
          break;
        case GLOBAL.FULFILLED:
          setMessage(
            "Units successfully added to the property. Please edit these units to activate it."
          );
          setShowSuccessModal(true);
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  const validation = (num) => {
    if (num < 1) {
      return "Please add atleast 1 unit";
    }
  };
  /* Component function definition ends */

  /* Component useEffect starts */
  useEffect(() => {
    (async () => {
      try {
        await dispatch(setLoading(true));
        await dispatch(fetchPropertyDetailByID({ property_id: propertyId }));
        await dispatch(setLoading(false));
      } catch (e) {}
    })();
  }, []);
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="add-unit-module-wrapper">
      <BreadCrumb type="add_unit" />

      <div className="container_sm">
        <h2 className="heading text-center font_xxl fw_6">Add Units</h2>
        <div className="form-wrapper">
          <CounterInput
            label="No. Of Units"
            num={unitCount}
            setNum={setUnitCount}
            validation={validation}
          />
        </div>

        {unitCount > property?.unused_license && (
          <div className="warning-wrapper">
            <div className="warning-wrapper-text bg_grey">
              <img
                className="icon"
                src={WarningOutlineRed}
                alt="Warning Icon"
              />
              <p className="font_yellow font_s m-0">
                You do not have sufficient license assigned to this property.
                Please assign license to this property add units to this
                Property.
              </p>
            </div>
            <div className="button-wrapper text-center"></div>
          </div>
        )}

        <div className="button-wrapper text-center">
          {unitCount > property?.unused_license ? (
            <>
              {isClient && (
                <CustomButton
                  size="l"
                  onClick={() => navigate("/settings/manageLicense")}
                >
                  + Assign License
                </CustomButton>
              )}
            </>
          ) : (
            <CustomButton
              onClick={onAddingUnits}
              size="l"
              // disable={unitCount <= 1}
            >
              Add Units
            </CustomButton>
          )}

          <TextButton onClick={onCancel} className="font_m fw_6">
            Cancel
          </TextButton>
        </div>
      </div>

      {/* Modals */}
      {/* Success Modal */}
      <SuccessModal
        showModal={showSuccessModal}
        title="Success"
        description={message}
        onClose={onSuccess}
      />

      {/* Error Modal */}
      <ErrorModal
        showModal={showErrorModal}
        title="Error"
        // title={message.title}
        description={message.desc}
        onClose={() => setShowErrorModal(false)}
      />
    </div>
  );
};
/* Component ends */

export default AddUnitModule;
