/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
/* Import configuration ends */

/* Import redux slices component starts */
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
/* Import local pages and component ends */

/* Component starts */
const DetailSection1 = (props) => {
  /* Props destructuring starts */
  const { type, maintenance } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const requestId = maintenance?.request_id;
  const unitName = maintenance?.unit_card?.unit_name;

  const category = maintenance?.category?.category_name;
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  /* Component function definition ends */

  /* Component useEffect starts */

  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="section section1">
      <p className="name font_m fw_6 font_grey">
        {" "}
        Ticket No <span className="font_blue fw_6">{requestId}</span>
      </p>

      {type !== "Move In" && (
        <p className="summary font_m font_grey fw_4">
          Description <br />
          <span>{maintenance?.description}</span>
        </p>
      )}

      {/* Ticket and Unit Number */}

      {(category === "Move In" || category === "Move Out") && (
        <>
          <hr className="section-divider" />

          <div className="category-column sub-category">
            <p className="name font_grey">Unit</p>
            <p className="value font_blue">{unitName || "-"}</p>
          </div>
        </>
      )}
    </div>
  );
};
/* Component ends */

export default DetailSection1;
