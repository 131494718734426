/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
/* Import configuration ends */

/* Import redux slices component starts */
/* Import redux slices component ends */

/* Import react bootstrap component starts */
import { Table } from "react-bootstrap";
/* Import react bootstrap component ends */

/* Import image and SVG starts */
import { EyeFilledBlue } from "../../../../../setup/constants/images";
/* Import image and SVG ends */

/* Import local pages and component starts */
import IconButton from "../../../../ui/button/IconButton/IconButton";
import Pagination from "../../../../ui/other/Pagination/Pagination";
import moment from "moment";
import { useNavigate } from "react-router-dom";
/* Import local pages and component ends */

/* Component starts */
const CompletedRequest = (props) => {
  /* Props destructuring starts */
  const { internalMaintenances, totalCount, currentPage, setCurrentPage } =
    props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const navigate = useNavigate();
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  const USER_DATA = localStorage.getItem("user_data")
    ? JSON.parse(localStorage.getItem("user_data"))
    : null;
  const DATE_FORMAT = USER_DATA ? USER_DATA?.date_format : "";
  const TIME_FORMAT = USER_DATA ? USER_DATA?.time_format : "";
  const DATE_TIME_FORMAT = `${DATE_FORMAT} ${TIME_FORMAT}`;
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <>
      <div className="custom-table-wrapper completeRequest-table">
        {internalMaintenances?.length > 0 && (
          <Table className="custom-table font_s" borderless responsive>
            {/* Table Heading */}
            <thead>
              <tr>
                <th>ID</th>
                <th>Date & Time</th>
                <th>Requester</th>
                <th>Unit#</th>
                <th>Location</th>
                <th>Category</th>
                <th>Severity</th>
                <th>Technician</th>
                <th>Action Requeired By</th>
                <th className="text-center">Action</th>
              </tr>
            </thead>
            {/* Table Body */}
            <tbody>
              {internalMaintenances?.map((item) => (
                <tr key={item?._id}>
                  {/* ID */}
                  <td>{item?.request_id}</td>

                  {/*  Date */}
                  <td>{moment(item?.createdAt).format(DATE_TIME_FORMAT)}</td>

                  {/* Requester Name */}
                  <td>
                    {item?.requester?.first_name} {item?.requester?.last_name}
                  </td>

                  <td>
                    {item?.unit_card?.unit_name
                      ? item?.unit_card?.unit_name
                      : "-"}
                  </td>

                  {/* Location */}
                  <td>{item?.location?.name}</td>

                  {/* Category */}
                  <td>
                    {item?.category !== undefined
                      ? item?.category?.category_name
                      : "-"}
                  </td>

                  {/* Severity */}
                  <td>
                    {item?.severity !== undefined
                      ? item?.severity?.severity
                      : "-"}
                  </td>

                  {/* Technician */}
                  <td>
                    {item?.technician !== undefined ? item?.technician : "-"}
                  </td>

                  {/* Action Requeired By */}
                  <td>
                    {item?.action_by
                      ? `${item?.action_by?.first_name} ${item?.action_by?.last_name}`
                      : "-"}
                  </td>

                  {/* Actions */}
                  <td className="text-center txt-nowrap">
                    <IconButton
                      // onClick={() =>
                      //   navigate("/tenant/security/details", {
                      //     state: { item },
                      //   })
                      // }
                      onClick={() => navigate(item?._id)}
                      className="action-button"
                    >
                      <img
                        src={EyeFilledBlue}
                        className="h_100"
                        alt="View Details"
                      />
                    </IconButton>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}

        {internalMaintenances?.length === 0 && (
          <div className=" text-center">
            <h3 className=" font_m font_yellow fw_6">No Data</h3>
          </div>
        )}
      </div>

      {internalMaintenances?.length > 0 && (
        <Pagination
          itemsPerPage="10"
          totalItems={totalCount}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      )}
    </>
  );
};
/* Component ends */

export default CompletedRequest;
