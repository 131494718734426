/**
 *
 */

import React, { useState, useEffect, useRef } from "react";

/* Import configuration starts */
import GLOBAL from "../../../../setup/constants/global";
import { useDispatch, useSelector } from "react-redux";
import { Formik } from "formik";
import { initialValues, unitValidations, sizeMeasurements } from "./helper";
import { useParams } from "react-router-dom";
/* Import configuration ends */

/* Import redux slices component starts */
import { setLoading } from "../../../../setup/store/slices/unpersistedSlice";
import {
  fetchUnitTypes,
  getUnitTypes,
} from "../../../../setup/store/slices/masterSlice";
import { updateUnit } from "../../../../setup/store/slices/unitSlice";
import { getUser } from "../../../../setup/store/slices/authSlice";
import {
  getPropertyDetail,
  fetchPropertyDetailByID,
} from "../../../../setup/store/slices/propertySlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import MUI component starts */
/* Import MUI component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import CustomFileInput from "../../../ui/input/CustomFileInput/CustomFileInput";
import CustomCurrencyInput from "../../../ui/input/CustomCurrencyInput/CustomCurrencyInput";
import CustomSelectInput from "../../../ui/input/CustomSelectInput/CustomSelectInput";
import CustomTextInput from "../../../ui/input/CustomTextInput/CustomTextInput";
import CustomTextArea from "../../../ui/input/CustomTextArea/CustomTextArea";
import CustomNumberInput from "../../../ui/input/CustomNumberInput/CustomNumberInput";
import TextButton from "../../../ui/button/TextButton/TextButton";
import CustomButton from "../../../ui/button/CustomButton/CustomButton";
import SuccessModal from "../../../ui/modal/SuccessModal/SuccessModal";
import ErrorModal from "../../../ui/modal/ErrorModal/ErrorModal";
import BreadCrumb from "../../../ui/other/BreadCrumb/BreadCrumb";
/* Import local pages and component ends */

import "./style.scss";

/* Component starts */
const EditUnitModule = (props) => {
  /* Props destructuring starts */
  const { onSuccess, onCancel, unit } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [filteredUnitTypes, setFilteredUnitTypes] = useState([]);
  const [selectedUnitType, setSelectedUnitType] = useState(null);
  const [selectedUnitMeasurement, setSelectedUnitMeasurement] = useState(null);
  const [initialFormValues, setInitialFormValues] = useState(initialValues());
  const [isResidential, setIsResidential] = useState(false);
  const [message, setMessage] = useState("");
  const [showErrModal, setShowErrModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  // useRef
  const floor_plan = useRef(null);
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const dispatch = useDispatch();
  const { propertyId, unitId } = useParams();

  // Redux Selector
  const unitTypes = useSelector(getUnitTypes);
  const property = useSelector(getPropertyDetail);
  const user = useSelector(getUser);
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */

  const propertyModal = property?.mgmt_model;

  const excludeBedroomOption = [
    "1 Bedroom Apartment",
    "2 Bedroom Apartment",
    "3 Bedroom Apartment",
    "4 Bedroom Apartment",
  ];
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  // Form validations
  const formValidation = (values) => {
    return unitValidations(
      values,
      isResidential,
      selectedUnitType,
      excludeBedroomOption,
      propertyModal
    );
  };

  // On updating unit
  const onUpdate = async (values) => {
    const {
      unit_name,
      unit_type,
      unit_floor,
      unit_size,
      toilet,
      store,
      target_rent_price,
      unit_description,
      note,
    } = values;

    // Creating or collecting payload data to be sent
    const payload = {
      unit_name,
      unit_type,
      unit_floor,
      unit_size,
      toilet,
      store,
      target_rent_price: target_rent_price ? target_rent_price : "",
      unit_description,
      note,
      property_id: propertyId,
      unit_type: selectedUnitType._id,
      unit_id: unit._id,
      size_measurement: selectedUnitMeasurement.type,
    };

    if (floor_plan?.current && !floor_plan?.current?.url) {
      payload["floor_plan"] = floor_plan.current;
    }

    if (isResidential) {
      payload["kitchen"] = values.kitchen;
      payload["bathroom"] = values.bathroom;
      payload["living_room"] = values.living_room;
      payload["bedroom"] = values.bedroom;
      payload["maids_room"] = values.maids_room;
      payload["balcony"] = values.balcony;
      payload["laundry"] = values.laundry;
    } else {
      payload["pantry"] = values.pantry;
      payload["showroom"] = values.showroom;
      payload["terrace"] = values.terrace;
      payload["offices"] = values.offices;
    }

    console.log("Payload", payload);

    // Backend response. Try, Catch
    try {
      const result = await dispatch(updateUnit(payload));

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          setMessage(result.payload);
          setShowErrModal(true);
          break;
        case GLOBAL.FULFILLED:
          setMessage("Unit updated successfully");
          setShowSuccessModal(true);
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };
  /* Component function definition ends */

  /* Component useEffect starts */
  // Fetching all unit types on initial load
  useEffect(() => {
    (async () => {
      try {
        await dispatch(setLoading(true));
        await dispatch(fetchUnitTypes());
        await dispatch(fetchPropertyDetailByID({ property_id: propertyId }));
        await dispatch(setLoading(false));
      } catch (e) {}
    })();
  }, []);

  // Setting default form values
  useEffect(() => {
    if (unit) {
      setInitialFormValues(initialValues(unit));
      floor_plan.current = unit?.floor_plan;
    }
  }, [unit]);

  // Setting unit type list according to property type
  useEffect(() => {
    if (property && unitTypes && Object.keys(property).length > 0) {
      if (property?.property_type[0]?.name !== GLOBAL.PROPERTY_TYPE.MIX_USE) {
        const fUnitTypes = unitTypes?.filter((unitType) => {
          return (
            unitType?.property_type_id?._id === property?.property_type[0]?._id
          );
        });
        setFilteredUnitTypes(fUnitTypes);
      } else {
        setFilteredUnitTypes(unitTypes);
      }
    }
  }, [unitTypes, property]);

  // Setting default selected unit type
  useEffect(() => {
    if (filteredUnitTypes?.length > 0) {
      if (unit?.unit_type) {
        const selectedUT = filteredUnitTypes?.filter((unitType) => {
          return unitType?._id === unit?.unit_type?._id;
        })[0];
        setSelectedUnitType(selectedUT);
      } else {
        setSelectedUnitType(filteredUnitTypes[0]);
      }
    }
  }, [unit, filteredUnitTypes]);

  // Setting default selected unit type
  useEffect(() => {
    if (sizeMeasurements?.length > 0) {
      if (unit?.size_measurement) {
        let measure = sizeMeasurements?.find(
          (s) => s?.type === unit?.size_measurement
        );
        setSelectedUnitMeasurement(measure);
      } else {
        setSelectedUnitMeasurement(sizeMeasurements[0]);
      }
    }
  }, [unit, sizeMeasurements]);

  // Setting if the unit creating is residential or not
  useEffect(() => {
    if (selectedUnitType) {
      setIsResidential(
        selectedUnitType?.property_type_id?.name ===
          GLOBAL.PROPERTY_TYPE.RESIDENTIAL
      );
    }
  }, [selectedUnitType]);
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <>
      <div className="container_sm edit-unit-module-wrapper">
        <h2 className="heading text-center font_xxl fw_6">Edit Unit</h2>

        {/* Form */}
        <Formik
          onSubmit={onUpdate}
          initialValues={initialFormValues}
          validate={formValidation}
          validateOnBlur={true}
          enableReinitialize={true}
        >
          {({
            values,
            errors,
            handleChange,
            handleSubmit,
            handleBlur,
            touched,
            isSubmitting,
            setFieldValue,
            setFieldTouched,
          }) => (
            <form className="form" onSubmit={handleSubmit}>
              <div className="form-wrapper">
                <h3 className="unit-num w_100 font_l fw_6">
                  #{unit?.unit_number}
                </h3>

                {/* Input for Unit Name */}
                <div className="form-group">
                  <CustomTextInput
                    label="Unit Name"
                    name="unit_name"
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    value={values.unit_name}
                    touched={touched.unit_name}
                    errors={errors.unit_name}
                  />
                </div>

                {/* Input for Unit Type */}
                <div className="form-group">
                  <CustomSelectInput
                    name="unit_type"
                    label="Unit Type"
                    handleBlur={handleBlur}
                    onChange={handleChange}
                    valueExtractor={(item) => item?.name}
                    setValue={setSelectedUnitType}
                    options={filteredUnitTypes}
                    value={selectedUnitType}
                    formikState={false}
                    emptyOption={false}
                  />
                </div>

                {/* Input for Unit Description */}
                <div className="form-group form-group-full">
                  <CustomTextArea
                    label="Unit Description"
                    name="unit_description"
                    required={false}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    value={values.unit_description}
                    touched={touched.unit_description}
                    errors={errors.unit_description}
                  />
                </div>

                {/* Input for Unit Floor */}
                <div className="form-group">
                  <CustomTextInput
                    label="Unit Floor"
                    name="unit_floor"
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    value={values.unit_floor}
                    touched={touched.unit_floor}
                    errors={errors.unit_floor}
                  />
                </div>

                <div className="form-group form-group-flex">
                  {/* Input for Unit Size */}
                  <div className="form-group-inner ">
                    <CustomNumberInput
                      label={"Unit Size"}
                      // label={
                      //   "Unit Size (" + selectedUnitMeasurement?.type + ")" || ""
                      // }
                      name="unit_size"
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      value={values.unit_size}
                      touched={touched.unit_size}
                      errors={errors.unit_size}
                      disableDot={false}
                    />
                  </div>
                  {/* Input for Unit Measurement */}
                  <div className="form-group-inner">
                    <CustomSelectInput
                      name="size_measurement"
                      label="Unit Measurement"
                      handleBlur={handleBlur}
                      onChange={handleChange}
                      valueExtractor={(item) => item?.name}
                      options={sizeMeasurements}
                      setValue={setSelectedUnitMeasurement}
                      value={selectedUnitMeasurement}
                      formikState={false}
                      emptyOption={false}
                    />
                  </div>
                </div>

                {/* Input for Unit Measurement */}
                {/* <div className="form-group">
                  <CustomSelectInput
                    name="size_measurement"
                    label="Unit Measurement"
                    handleBlur={handleBlur}
                    onChange={handleChange}
                    valueExtractor={(item) => item?.name}
                    options={sizeMeasurements}
                    setValue={setSelectedUnitMeasurement}
                    value={selectedUnitMeasurement}
                    formikState={false}
                    emptyOption={false}
                  />
                </div> */}

                {/* Input for Toilet Count */}
                <div className="form-group">
                  <CustomNumberInput
                    label="Toilet"
                    name="toilet"
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    value={values.toilet}
                    touched={touched.toilet}
                    errors={errors.toilet}
                  />
                </div>

                {/* Input for Store Count */}
                <div className="form-group">
                  <CustomNumberInput
                    label="Store"
                    name="store"
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    value={values.store}
                    touched={touched.store}
                    errors={errors.store}
                  />
                </div>

                {/* If Residential Units */}
                {isResidential && (
                  <>
                    {/* Input for Kitchen Count */}
                    <div className="form-group">
                      <CustomNumberInput
                        label="Kitchen"
                        name="kitchen"
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        value={values.kitchen}
                        touched={touched.kitchen}
                        errors={errors.kitchen}
                      />
                    </div>

                    {/* Input for Bed Room Count */}
                    {!excludeBedroomOption?.includes(
                      selectedUnitType?.name
                    ) && (
                      <div className="form-group">
                        <CustomNumberInput
                          label="Bedroom"
                          name="bedroom"
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          value={values.bedroom}
                          touched={touched.bedroom}
                          errors={errors.bedroom}
                        />
                      </div>
                    )}

                    {/* Input for Bathroom Count */}
                    <div className="form-group">
                      <CustomNumberInput
                        label="Bathroom"
                        name="bathroom"
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        value={values.bathroom}
                        touched={touched.bathroom}
                        errors={errors.bathroom}
                      />
                    </div>

                    {/* Input for Living Room Count */}
                    <div className="form-group">
                      <CustomNumberInput
                        label="Living Room"
                        name="living_room"
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        value={values.living_room}
                        touched={touched.living_room}
                        errors={errors.living_room}
                      />
                    </div>

                    {/* Input for Maids Room Count */}
                    <div className="form-group">
                      <CustomNumberInput
                        label="Maid's Room"
                        name="maids_room"
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        value={values.maids_room}
                        touched={touched.maids_room}
                        errors={errors.maids_room}
                      />
                    </div>

                    {/* Input for Balcony Count */}
                    <div className="form-group">
                      <CustomNumberInput
                        label="Balcony"
                        name="balcony"
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        value={values.balcony}
                        touched={touched.balcony}
                        errors={errors.balcony}
                      />
                    </div>

                    {/* Input for Laundry Count */}
                    <div className="form-group">
                      <CustomNumberInput
                        label="Laundry"
                        name="laundry"
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        value={values.laundry}
                        touched={touched.laundry}
                        errors={errors.laundry}
                      />
                    </div>
                  </>
                )}

                {/* If Commercial Unit */}
                {!isResidential && (
                  <>
                    {/* Input for Pantry Count */}
                    <div className="form-group">
                      <CustomNumberInput
                        label="Pantry"
                        name="pantry"
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        value={values.pantry}
                        touched={touched.pantry}
                        errors={errors.pantry}
                      />
                    </div>

                    {/* Input for Showroom Count */}
                    <div className="form-group">
                      <CustomNumberInput
                        label="Showroom"
                        name="showroom"
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        value={values.showroom}
                        touched={touched.showroom}
                        errors={errors.showroom}
                      />
                    </div>

                    {/* Input for Terrace Count */}
                    <div className="form-group">
                      <CustomNumberInput
                        label="Terrace"
                        name="terrace"
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        value={values.terrace}
                        touched={touched.terrace}
                        errors={errors.terrace}
                      />
                    </div>

                    {/* Input for Offices Count */}
                    <div className="form-group">
                      <CustomNumberInput
                        label="Offices"
                        name="offices"
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        value={values.offices}
                        touched={touched.offices}
                        errors={errors.offices}
                      />
                    </div>
                  </>
                )}

                {/* Input for Target Rent Price */}
                {propertyModal !== 1 && (
                  <div className="form-group">
                    <CustomCurrencyInput
                      name="target_rent_price"
                      label={`Target Rent Price (${property?.currency})`}
                      currency={property?.currency}
                      setFieldTouched={setFieldTouched}
                      setFieldValue={setFieldValue}
                      value={values.target_rent_price}
                      touched={touched.target_rent_price}
                      errors={errors.target_rent_price}
                    />
                  </div>
                )}

                {/* Input for Unit Floor Plan Price */}
                <div className="form-group">
                  <CustomFileInput
                    label="Floor Plan"
                    name="floor_plan"
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    errors={errors.floor_plan}
                    touched={touched.floor_plan}
                    files={floor_plan}
                    required={false}
                    formikState={false}
                  />
                </div>

                {/* Input for Note */}
                <div className="form-group form-group-full">
                  <CustomTextArea
                    label="Note"
                    name="note"
                    required={false}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    value={values.note}
                    touched={touched.note}
                    errors={errors.note}
                  />
                </div>
              </div>
              {/* Save and Close Button */}
              <div className="button-wrapper text-center">
                <CustomButton type="submit" size="l">
                  Update
                </CustomButton>
                <TextButton onClick={onCancel} className="font_m fw_6">
                  Cancel
                </TextButton>
              </div>
            </form>
          )}
        </Formik>

        {/* Modals */}
        {/* Success Modal */}
        <SuccessModal
          showModal={showSuccessModal}
          title="Success"
          description={message}
          onClose={onSuccess}
        />

        {/* Error Modal */}
        <ErrorModal
          showModal={showErrModal}
          title="Error"
          description={message}
          onClose={() => {
            setShowErrModal(false);
          }}
        />
      </div>
    </>
  );
};
/* Component ends */

export default EditUnitModule;
