import React, { useState } from "react";

/* Import configuration starts */
/* Import configuration ends */

/* Import redux slices component starts */
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
/* Import local pages and component ends */

/* Component starts */
import "./style.scss";

const CustomTimeMinuteSelect = (props) => {
  /* Props destructuring starts */
  const {
    label = "Select Time",
    required = true,
    hours,
    setHours,
    minutes,
    setMinutes,
  } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  // const [hours, setHours] = useState("00");
  // const [minutes, setMinutes] = useState("00");
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  const handleHourChange = (event) => {
    setHours(event.target.value);
  };

  const handleMinuteChange = (event) => {
    setMinutes(event.target.value);
  };

  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  return (
    <div className="time-select-container">
      <label className="font_s font_grey">
        {label}
        {required && <span className="font_m font_red"> *</span>}
      </label>
      <select value={hours} onChange={handleHourChange} className="select-wrap">
        {Array.from({ length: 24 }, (_, i) => (
          <option key={i} value={String(i).padStart(2, "0")}>
            {String(i).padStart(2, "0")}
          </option>
        ))}
      </select>
      {/* <span>:</span> */}
      <select
        value={minutes}
        onChange={handleMinuteChange}
        className="select-wrap"
      >
        {Array.from({ length: 12 }, (_, i) => (
          <option key={i} value={String(i * 5).padStart(2, "0")}>
            {String(i * 5).padStart(2, "0")}
          </option>
        ))}
      </select>
    </div>
  );
};

export default CustomTimeMinuteSelect;
