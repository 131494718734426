/**
 *
 */

import React, { useState, useEffect, useRef } from "react";

/* Import configuration starts */
import { useDispatch, useSelector } from "react-redux";
import { Formik } from "formik";
import GLOBAL from "../../../../setup/constants/global";
import { initialValues, validations } from "./helper";
/* Import configuration ends */

/* Import redux slices component starts */
import { inviteMember } from "../../../../setup/store/slices/teamSlice";
import {
  fetchUserRoles,
  getUserRoles,
} from "../../../../setup/store/slices/masterSlice";
import { setLoading } from "../../../../setup/store/slices/unpersistedSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import SuccessModal from "../../../ui/modal/SuccessModal/SuccessModal";
import ErrorModal from "../../../ui/modal/ErrorModal/ErrorModal";
import CustomTextInput from "../../../ui/input/CustomTextInput/CustomTextInput";
import CustomPhoneInput from "../../../ui/input/CustomPhoneInput/CustomPhoneInput";
import CustomFileInput from "../../../ui/input/CustomFileInput/CustomFileInput";
import CustomButton from "../../../ui/button/CustomButton/CustomButton";
import TextButton from "../../../ui/button/TextButton/TextButton";
import CustomTextArea from "../../../ui/input/CustomTextArea/CustomTextArea";
/* Import local pages and component ends */

import "./style.scss";

/* Component starts */
const EditPM = (props) => {
  /* Props destructuring starts */
  const { onSuccess, onCancel, parentUserId, property } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [initialFormValues, setInitialFormValues] = useState(initialValues);
  const [message, setMessage] = useState("");
  const [showErrModal, setShowErrModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [pmRole, setPmRole] = useState();

  // useRef
  const customPhoneNum = useRef({ code: "", num: "" });
  const resume = useRef(null);
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const dispatch = useDispatch();

  // Redux Slice
  const userRoles = useSelector(getUserRoles);
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  // Form validations
  const formValidation = (values) => {
    return validations(values, customPhoneNum);
  };

  // Handling on submit
  const onSubmit = async (values) => {
    // Creating or collecting payload data to be sent
    const payload = {
      role_id: pmRole._id,
      parent_user_id: parentUserId,
      property_id: property._id,
      first_name: values.first_name.trim(),
      last_name: values.last_name.trim(),
      email: values.email.toLowerCase(),
      countrycode: customPhoneNum.current.code,
      mobile: customPhoneNum.current.num,
      business_name: values.business_name,
      employee_id: values.employee_id,
      notes: values.notes,
      profession: values.profession,
    };
    if (resume.current) {
      payload["resume"] = resume.current;
    }

    // Backend response. Try, Catch
    try {
      // Handling success response
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };
  /* Component function definition ends */

  /* Component useEffect starts */
  // Fetching all user roles on initial load
  useEffect(() => {
    (async () => {
      try {
        await dispatch(setLoading(true));
        await dispatch(fetchUserRoles());
        await dispatch(setLoading(false));
      } catch (e) {}
    })();
  }, []);

  // Setting PM role after all user roles loaded
  useEffect(() => {
    let userRole = userRoles.filter((d) => d.name == GLOBAL.USER_ROLE.PM)[0];
    setPmRole(userRole);
  }, [userRoles]);
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="edit-pm-module-wrapper">
      <h2 className="heading text-center font_xxl mb-5">
        Edit Property Manager
      </h2>

      <div className="add-new-form">
        <Formik
          onSubmit={onSubmit}
          initialValues={initialFormValues}
          validate={formValidation}
          validateOnBlur={true}
          enableReinitialize={true}
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
          }) => (
            <form onSubmit={handleSubmit}>
              <div className="form-wrapper">
                {/* Input for First Name */}
                <div className="form-group">
                  <CustomTextInput
                    name="first_name"
                    label="First Name"
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    touched={touched.first_name}
                    errors={errors.first_name}
                    value={values.first_name}
                  />
                </div>

                {/* Input for Last Name */}
                <div className="form-group">
                  <CustomTextInput
                    name="last_name"
                    label="Last Name"
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    touched={touched.last_name}
                    errors={errors.last_name}
                    value={values.last_name}
                  />
                </div>

                {/* Input for Email */}
                <div className="form-group">
                  <CustomTextInput
                    name="email"
                    label="Email"
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    touched={touched.email}
                    errors={errors.email}
                    value={values.email}
                  />
                </div>

                {/* Input for Phone */}
                <div className="form-group">
                  <CustomPhoneInput
                    name="mobile"
                    label={"Phone Number"}
                    customPhoneNum={customPhoneNum}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    value={values.mobile}
                    countryCodeEditable={true}
                    errors={errors.mobile}
                    touched={touched.mobile}
                  />
                </div>

                {/* Input for Company Name */}
                <div className="form-group">
                  <CustomTextInput
                    name="business_name"
                    label="Company Name"
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    touched={touched.business_name}
                    errors={errors.business_name}
                    value={values.business_name}
                  />
                </div>

                {/* Input for Company Name */}
                <div className="form-group">
                  <CustomTextInput
                    name="profession"
                    label="Profession"
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    touched={touched.profession}
                    errors={errors.profession}
                    value={values.profession}
                    readOnly={true}
                  />
                </div>

                {/* Input for Employee ID */}
                <div className="form-group">
                  <CustomTextInput
                    name="employee_id"
                    label="Employee ID"
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    touched={touched.employee_id}
                    errors={errors.employee_id}
                    value={values.employee_id}
                  />
                </div>

                {/* Input for Resume */}
                <div className="form-group">
                  <CustomFileInput
                    label="Upload Resume"
                    name="resume"
                    files={resume}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    errors={errors.resume}
                    touched={touched.resume}
                    values={values.resume}
                    required={false}
                    formikState={false}
                  />
                </div>

                {/* Text Area for Notes */}
                <div className="form-group form-group-full">
                  <CustomTextArea
                    name="notes"
                    label="Notes"
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    value={values.notes}
                    errors={errors.notes}
                    touched={touched.notes}
                    required={false}
                  />
                </div>
              </div>

              <div className="button-wrapper text-center">
                <CustomButton type="submit" size="l">
                  Add
                </CustomButton>
                <TextButton
                  type="button"
                  onClick={onCancel}
                  className="font_m fw_6"
                >
                  Cancel
                </TextButton>
              </div>
            </form>
          )}
        </Formik>

        {/* Modals */}
        {/* Error Modal */}
        <ErrorModal
          showModal={showErrModal}
          title="Error"
          description={message}
          onClose={() => {
            setShowErrModal(false);
          }}
        />

        {/* Success Modal */}
        <SuccessModal
          showModal={showSuccessModal}
          title="Success"
          description={message}
          onClose={() => {
            onSuccess();
          }}
        />
      </div>
    </div>
  );
};
/* Component ends */

export default EditPM;
