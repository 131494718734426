export const initialSequence = [
  {
    title: "Add Property",
    key: "add_property",
  },
  {
    title: "Assign License",
    key: "add_license",
  },
  {
    title: "Select Management Model",
    key: "add_mgmt",
  },
];
