/**
 * @author Abdul Ahad <aabdul@dgrnte.com>
 * Slice to manage invoice
 */

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios, { axiosInstance } from "../axios";
import { API_URL } from "../../config/devKeys";
import {
  setLoading,
  setShowUploadProgress,
  setUploadProgress,
} from "./unpersistedSlice";
import { PLATFORM } from "../../platformUtil";

const initialState = {
  invoices: [],
  invoicesBasedProperty: [],
  invoiceAggregation: [],
  invoice: null,
  depositInvoices: [],
  accountantMoveoutDetails: null,
  tenantInvoices: {},
};

export const fetchInvoices = createAsyncThunk(
  "invoice/get",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const { data } = await axios.get(API_URL + "/license/invoices", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("user_token")}`,
        },
      });
      dispatch(setLoading(false));
      if (data.success) {
        return data.data;
      } else {
        return rejectWithValue(data);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

// FETCH INVOICES LIST BASED ON PROPERTY ID - { CLIENT }
export const fetchInvoicesBasedOnProperty = createAsyncThunk(
  "invoice/tenant/listBasedOnProperty",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const { data } = await axios.post(
        API_URL + "/invoice/tenant/listBasedOnProperty",
        // "http://localhost:3000/invoice/tenant/listBasedOnProperty",
        { ...payload },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      dispatch(setLoading(false));
      if (data.success) {
        return data.data;
      } else {
        rejectWithValue(data.error);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

// FETCH INVOICES AGGREGATION LIST BASED ON PROPERTY ID - { CLIENT }
export const fetchInvoicesAggregationBasedOnProperty = createAsyncThunk(
  "invoice/tenant_rent_invoice_aggrigation/listBasedOnProperty",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const { data } = await axios.post(
        API_URL + "/invoice/tenant/rent_invoice_aggrigation",
        // "http://localhost:3000/invoice/tenant/rent_invoice_aggrigation",
        { ...payload },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      dispatch(setLoading(false));
      if (data.success) {
        return data.data;
      } else {
        rejectWithValue(data.error);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

// FETCH TENANT INVOICES BASED ON INVOICE_ID
export const fetchTenantInvoiceById = createAsyncThunk(
  "invoice/tenant/get",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const { data } = await axios.post(
        API_URL + "/invoice/tenant/get",
        { invoice_id: payload },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      dispatch(setLoading(false));
      if (data.success) {
        return data.data;
      } else {
        return rejectWithValue(data);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

export const fetchMoveoutDetailsForAccountant = createAsyncThunk(
  "invoice/accountant/moveout/get",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const { data } = await axios.post(
        API_URL + "/invoice/tenant/moveout/info",
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      dispatch(setLoading(false));
      if (data.success) {
        return data.data;
      } else {
        return rejectWithValue(data);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

export const addTenantInvoice = createAsyncThunk(
  "invoice/tenant/add",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const { data } = await axios.post(
        API_URL + "/invoice/tenant/add",
        // "http://localhost:3000/invoice/tenant/add",
        { ...payload },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      dispatch(setLoading(false));
      if (data.success) {
        dispatch(
          fetchInvoicesBasedOnProperty({
            property_id: payload.property_id,
            type: payload.type,
          })
        );
        return data.data;
      } else {
        return rejectWithValue(data);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

// Tenant Invoice Payment - ONLINE / CARD
export const payTenantInvoiceOnline = createAsyncThunk(
  "invoice/tenant/pay_online",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const { data } = await axios.post(
        API_URL + "/invoice/tenant/pay_online",
        // "http://localhost:3000/invoice/tenant/pay_online",
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      dispatch(setLoading(false));
      if (data.success) {
        dispatch(fetchTenantInvoiceById(payload?.invoice_id));
        return data.data;
      } else {
        return rejectWithValue(data);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

export const initiateOnlineRefund = createAsyncThunk(
  "invoice/tenant/refund_invoice_online",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const { data } = await axios.post(
        API_URL + "/invoice/tenant/refund_invoice_online",
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      dispatch(setLoading(false));
      if (data.success) {
        await dispatch(fetchTenantInvoiceById(payload?.invoice_id));
        return data.data;
      } else {
        return rejectWithValue(data);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

export const initiateOfflineRefund = createAsyncThunk(
  "invoice/tenant/refund_invoice_offline",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const formData = new FormData();
      Object.keys(payload).map((key) => {
        if (key == "invoice_doc") {
          if (PLATFORM !== "web") {
            formData.append("invoice_doc", {
              name: payload.invoice_doc.name,
              type: payload.invoice_doc.type,
              uri:
                PLATFORM === "android"
                  ? payload.invoice_doc.uri
                  : payload.invoice_doc.uri.replace("file://", ""),
            });
          } else {
            formData.append("invoice_doc", payload[key], payload[key].name);
          }
        } else {
          formData.append(key, payload[key]);
        }
      });

      const headers = {
        Authorization: `Bearer ${localStorage.getItem("user_token")}`,
      };
      if (PLATFORM !== "web") {
        headers["Content-Type"] = "multipart/form-data";
      }
      // const response = await fetch(
      //   API_URL + "/invoice/tenant/refund_invoice_offline",
      //   {
      //     method: "POST",
      //     headers: headers,
      //     body: formData,
      //   }
      // );
      // const data = await response.json();
      dispatch(setShowUploadProgress(true));
      const config = {
        onUploadProgress: ({ loaded, total }) => {
          const progress = (loaded / total) * 100;
          dispatch(setUploadProgress(progress));
        },
        method: "POST",
        url: "/invoice/tenant/refund_invoice_offline",
        headers,
        transformRequest: (data, headers) => {
          return formData;
        },
        data: formData,
        responseType: "json",
      };

      // const data = await response.json();

      const { data } = await axiosInstance.request(config);

      dispatch(setLoading(false));
      if (data.success) {
        await dispatch(fetchTenantInvoiceById(payload?.invoice_id));
        return data.data;
      } else {
        return rejectWithValue(data);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

export const adjustFromDeposit = createAsyncThunk(
  "invoice/moveout/adjust_deposit",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const { data } = await axios.post(
        API_URL + "/invoice/moveout/adjust_deposit",
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      dispatch(setLoading(false));
      if (data.success) {
        return data.data;
      } else {
        return rejectWithValue(data);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

export const updateTenantInvoice = createAsyncThunk(
  "invoice/tenant/invoice_status_update",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const { fetchDetail } = payload;
      dispatch(setLoading(true));
      const formData = new FormData();
      Object.keys(payload).map((key) => {
        if (key == "invoice_doc") {
          if (PLATFORM !== "web") {
            formData.append(key, {
              name: payload[key].name,
              type: payload[key].type,
              uri:
                PLATFORM === "android"
                  ? payload[key].uri
                  : payload[key].uri.replace("file://", ""),
            });
          } else {
            formData.append(key, payload[key], payload[key].name);
          }
        } else {
          formData.append(key, payload[key]);
        }
      });
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("user_token")}`,
      };
      if (PLATFORM !== "web") {
        headers["Content-Type"] = "multipart/form-data";
      }
      // const response = await fetch(
      //   API_URL + "/invoice/tenant/invoice_status_update",
      //   {
      //     method: "POST",
      //     headers: headers,
      //     body: formData,
      //   }
      // );
      // const data = await response.json();
      dispatch(setShowUploadProgress(true));
      const config = {
        onUploadProgress: ({ loaded, total }) => {
          const progress = (loaded / total) * 100;
          dispatch(setUploadProgress(progress));
        },
        method: "POST",
        url: "/invoice/tenant/invoice_status_update",
        headers,
        transformRequest: (data, headers) => {
          return formData;
        },
        data: formData,
        responseType: "json",
      };

      // const data = await response.json();

      const { data } = await axiosInstance.request(config);

      dispatch(setLoading(false));
      if (data.success) {
        if (fetchDetail) {
        } else {
          dispatch(
            fetchInvoicesBasedOnProperty({
              type: payload?.type,
              property_id: payload?.property_id,
            })
          );
          dispatch(
            fetchInvoicesAggregationBasedOnProperty({
              type: payload?.type,
              property_id: payload?.property_id,
            })
          );
        }
        return data.data;
      } else {
        return rejectWithValue(data);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

// New api for getting deposit list
export const fetchActiveDepositInvoiceList = createAsyncThunk(
  "invoice/tenant/listByUnit",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const { data } = await axios.post(
        API_URL + "/invoice/tenant/get_the_active_deposits",
        // 'http://localhost:3000/invoice/tenant/get_the_active_deposits',
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      dispatch(setLoading(false));
      if (data.success) {
        return data.data;
      } else {
        return rejectWithValue(data);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

export const fetchTenantInvoiceListByUnit = createAsyncThunk(
  "invoice/tenant/listByUnit",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const { data } = await axios.post(
        API_URL + "/invoice/tenant/listByUnit",
        // 'http://localhost:3000/invoice/tenant/listByUnit',
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      dispatch(setLoading(false));
      if (data.success) {
        return data.data;
      } else {
        return rejectWithValue(data);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

export const fetchTenantInvoiceListBasedOnUnit = createAsyncThunk(
  "tenant/invoices",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const { data } = await axios.post(
        API_URL + "/tenant_info/get_tenant_invoice_details",
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      dispatch(setLoading(false));
      if (data.success) {
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response.data.error.message);
    }
  }
);

export const fetchT2TenantInvoiceListBasedOnUnit = createAsyncThunk(
  "tenant/t2_invoices",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const { data } = await axios.post(
        API_URL + "/tenant_info/unit/tenant_t2_invoice_details",
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      dispatch(setLoading(false));
      if (data.success) {
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response.data.error.message);
    }
  }
);

export const invoiceSlice = createSlice({
  name: "invoice",
  initialState,
  extraReducers: {
    [fetchTenantInvoiceListByUnit.fulfilled]: (state, action) => {
      state.invoices = action.payload;
    },
    [fetchTenantInvoiceListByUnit.rejected]: (state, action) => {
      state.invoices = [];
    },

    [fetchTenantInvoiceListBasedOnUnit.fulfilled]: (state, action) => {
      state.tenantInvoices = action.payload;
    },
    [fetchTenantInvoiceListBasedOnUnit.rejected]: (state, action) => {
      state.tenantInvoices = {};
    },

    [fetchT2TenantInvoiceListBasedOnUnit.fulfilled]: (state, action) => {
      state.tenantInvoices = action.payload;
    },
    [fetchT2TenantInvoiceListBasedOnUnit.rejected]: (state, action) => {
      state.tenantInvoices = {};
    },

    [fetchInvoices.fulfilled]: (state, action) => {
      state.invoices = action.payload;
    },
    [fetchMoveoutDetailsForAccountant.fulfilled]: (state, action) => {
      state.accountantMoveoutDetails = action.payload;
    },
    [fetchMoveoutDetailsForAccountant.rejected]: (state, action) => {
      state.accountantMoveoutDetails = null;
    },

    [fetchInvoicesBasedOnProperty.rejected]: (state, action) => {
      state.invoicesBasedProperty = [];
    },
    [fetchInvoicesBasedOnProperty.fulfilled]: (state, action) => {
      state.invoicesBasedProperty = action.payload;
    },

    [fetchInvoicesAggregationBasedOnProperty.rejected]: (state, action) => {
      state.invoiceAggregation = [];
    },
    [fetchInvoicesAggregationBasedOnProperty.fulfilled]: (state, action) => {
      state.invoiceAggregation = action.payload;
    },

    [fetchTenantInvoiceById.rejected]: (state, action) => {
      state.invoice = null;
    },
    [fetchTenantInvoiceById.fulfilled]: (state, action) => {
      state.invoice = action.payload;
    },

    // Deposit List
    [fetchActiveDepositInvoiceList.fulfilled]: (state, action) => {
      state.depositInvoices = action.payload;
    },
    [fetchActiveDepositInvoiceList.rejected]: (state, action) => {
      state.depositInvoices = [];
    },
  },
});

export const getInvoices = (state) => state.invoice.invoices;

// GET INVOICES BASED On PROPERTY_ID
export const getInvoicesBasedProperty = (state) =>
  state.invoice.invoicesBasedProperty;

// GET INVOICES AGGREGATION BASED On PROPERTY_ID
export const getInvoicesAggregationBasedProperty = (state) =>
  state.invoice.invoiceAggregation;

// GET INVOICES BASED On INVOICE_ID
export const getTenantInvoice = (state) => state.invoice.invoice;

export const getAccountantMoveOut = (state) =>
  state.invoice.accountantMoveoutDetails;

// GET INVOICES BASED On INVOICE_ID
export const getActiveDepositInvoice = (state) => state.invoice.depositInvoices;

// Get Tenant Invoices Based On Unit
export const getTenantInvoicesListBasedOnUnit = (state) =>
  state.invoice.tenantInvoices;

export default invoiceSlice.reducer;
