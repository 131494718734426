/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
/* Import configuration ends */

/* Import redux slices component starts */
import {
  clientAccountEnable,
  clientAccountSuspension,
  clientAccountSuspensionInitiate,
  getIsTENANT,
  getUser,
} from "../../setup/store/slices/authSlice";
// import {
//   showErrorAlert,
//   showSuccessAlert,
// } from "../../setup/store/slices/globalAlertSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import SliderButton from "../../components/ui/button/SliderButton/SliderButton";
import BreadCrumb from "../../components/ui/other/BreadCrumb/BreadCrumb";
import ConfirmationModal from "../../components/ui/modal/ConfirmationModal/ConfirmationModal";
import ConfirmationOTPModal from "../../components/ui/modal/ConfirmationOTPModal/ConfirmationOTPModal";
import SuccessModal from "../../components/ui/modal/SuccessModal/SuccessModal";
import GLOBAL from "../../setup/constants/global";
import ErrorModal from "../../components/ui/modal/ErrorModal/ErrorModal";
/* Import local pages and component ends */

import "./style.scss";

/* Component starts */
const AccountSettingPage = (props) => {
  /* Props destructuring starts */
  const {} = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [sessionId, setSessionId] = useState();
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showOtpModal, setShowOtpModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [modalDesc, setModalDesc] = useState("");
  const [confirmedVal, setConfirmedVal] = useState();
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */

  // Redux Selector
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isTenant = useSelector(getIsTENANT);
  const user = useSelector(getUser);
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  const USER_DATA = localStorage.getItem("user_data")
    ? JSON.parse(localStorage.getItem("user_data"))
    : null;
  const DATE_FORMAT = USER_DATA ? USER_DATA?.date_format : "";
  const TIME_FORMAT = USER_DATA ? USER_DATA?.time_format : "";
  const DATE_TIME_FORMAT = `${DATE_FORMAT} ${TIME_FORMAT}`;
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  // On Toggling On - Account Suspension
  const onSuspensionClick = (state) => {
    if (!user?.is_account_suspended) {
      setModalDesc(
        "Caution: This action will significantly affect the account. Suspending the account will result in the removal of all your teams and tenants from properties, cancellation of any active contracts or invoices. This action is irreversible. While the account is suspended, you will be unable to add teams or tenants. If you wish to onboard teams and tenants again, you must unsuspend the account."
      );
      setShowConfirmationModal(true);
      setConfirmedVal(`${state}`);
    } else {
      setConfirmedVal(`${state}`);
      confirmSuspension();
    }
  };

  const confirmSuspension = async () => {
    if (!user?.is_account_suspended) {
      console.log("SUSPEND");
      // Backend response. Try, catch
      try {
        const result = await dispatch(clientAccountSuspensionInitiate());
        // Handling success response
        switch (result.meta.requestStatus) {
          case GLOBAL.REJECTED:
            setModalDesc(result.payload);
            setShowConfirmationModal(!showErrorModal);
            setShowErrorModal(true);
            break;
          case GLOBAL.FULFILLED:
            // setModalDesc("Enter the code sent to your email");
            setSessionId(result.payload.Session);
            setShowConfirmationModal(!showErrorModal);
            setShowOtpModal(true);
            break;
        }
      } catch (error) {
        // Handling error response
        console.error(error.message);
      }

      setShowConfirmationModal(!showConfirmationModal);
    } else {
      console.log("ENABLE");
      // Backend response. Try, catch
      try {
        const result = await dispatch(clientAccountEnable());
        // Handling success response
        switch (result.meta.requestStatus) {
          case GLOBAL.REJECTED:
            setModalDesc(result.payload);
            setShowErrorModal(true);
            break;
          case GLOBAL.FULFILLED:
            setModalDesc("Account activated successfully");
            setShowSuccessModal(true);
            break;
        }
      } catch (error) {
        // Handling error response
        console.error(error.message);
      }
    }
  };

  const handleClosedConfiramtion = () => {
    setShowConfirmationModal(false);
  };

  // On verifying otp for Account Suspension / UnSuspension
  const onVerify = async (otp) => {
    if (otp.length !== 4) return;

    // Collecting or creating payload data to be sent
    const payload = {
      OTP: otp,
      session: sessionId,
    };

    // // Backend response. Try, catch
    try {
      const result = await dispatch(clientAccountSuspension(payload));

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          setModalDesc(result.payload);
          setShowOtpModal(false);
          setShowErrorModal(true);
          break;

        case GLOBAL.FULFILLED:
          setModalDesc("Account has been marked suspended.");
          setShowOtpModal(false);
          setShowSuccessModal(true);
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };

  /* Component function definition ends */

  /* Component useEffect starts */

  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <>
      {!isTenant && <BreadCrumb type="settings_account_page" />}
      <div className="account-setting-page-wrapper container_sm">
        <h2 className="font_xxl heading text-center">Account Pause</h2>

        <div className="inner-wrapper">
          <div className="option">
            <p>Account Pause</p>
            <SliderButton
              active={user?.is_account_suspended}
              onChange={(state) => onSuspensionClick(state)}
            />
          </div>
          {user?.suspension_time && (
            <div className="option">
              <p>Suspension At</p>
              <span>
                {moment(user?.suspension_time).format(DATE_TIME_FORMAT)}
              </span>
            </div>
          )}
        </div>
      </div>

      {/* Modals */}
      {/* Confirmation Modal */}
      <ConfirmationModal
        showModal={showConfirmationModal}
        title="Account Pause"
        description={modalDesc}
        onClose={() => handleClosedConfiramtion()}
        onConfirm={() => confirmSuspension(confirmedVal)}
      />

      {/* OTP Modal */}
      <ConfirmationOTPModal
        showModal={showOtpModal}
        onVerify={onVerify}
        title="Enter OTP"
        digits={4}
        description={modalDesc}
        onCancel={() => setShowOtpModal(false)}
      />

      {/* Success Modal */}
      <SuccessModal
        showModal={showSuccessModal}
        title="Successful"
        // title={
        //   !user?.is_account_suspended ? "Account Suspention" : "Enabled Account"
        // }
        description={modalDesc}
        onClose={() => navigate("/")}
      />

      {/* Error Modal */}
      <ErrorModal
        showModal={showErrorModal}
        title="Error"
        description={modalDesc}
        onClose={() => setShowErrorModal(false)}
      />
    </>
  );
};
/* Component ends */

export default AccountSettingPage;
