/**
 *
 */

import React from "react";
/* Import configuration starts */

import { useNavigate } from "react-router-dom";
import { generateStatus } from "./helper";
/* Import configuration ends */

/* Import redux slices component starts */
/* Import redux slices component ends */

/* Import react bootstrap component starts */
import { Table } from "react-bootstrap";
/* Import react bootstrap component ends */

/* Import image and SVG starts */
import { EyeFilledBlue } from "../../../../setup/constants/images";
/* Import image and SVG ends */

/* Import local pages and component starts */
import GLOBAL from "../../../../setup/constants/global";
import moment from "moment";
import IconButton from "../../../ui/button/IconButton/IconButton";
import Pagination from "../../../ui/other/Pagination/Pagination";
/* Import local pages and component ends */

// Styles
import "./style.scss";

/* Component starts */
const TenantMaintenanceTable = (props) => {
  /* Props destructuring starts */
  const { maintenanceList, currentPage, setCurrentPage, totalCount } = props;

  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const navigate = useNavigate();
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  const USER_DATA = localStorage.getItem("user_data")
    ? JSON.parse(localStorage.getItem("user_data"))
    : null;
  const DATE_FORMAT = USER_DATA ? USER_DATA?.date_format : "";
  const TIME_FORMAT = USER_DATA ? USER_DATA?.time_format : "";
  const DATE_TIME_FORMAT = `${DATE_FORMAT} ${TIME_FORMAT}`;
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */

  return (
    <>
      <div className="custom-table-wrapper tenant-maintenance-table-wrapper">
        {maintenanceList?.length > 0 && (
          <Table className="custom-table font_s" borderless responsive>
            {/* Table Heading */}
            <thead>
              <tr>
                <th>Request ID</th>
                <th>Description</th>
                <th>Preferred Time and Date</th>
                <th>Last Updated</th>
                <th>Status</th>
                {/* <th>Sub Status</th> */}
                <th>Action</th>
              </tr>
            </thead>
            {/* Table Body */}
            <tbody>
              {maintenanceList?.map((item) => (
                <tr key={item._id}>
                  <td>{item?.request_id}</td>

                  <td>
                    <div className="main-desc">{item?.description}</div>
                  </td>

                  <td>
                    {moment(item?.prefered_maintenance_date).format(
                      DATE_TIME_FORMAT
                    )}
                  </td>

                  <td>{moment(item?.updatedAt).format(DATE_TIME_FORMAT)}</td>

                  <td
                    className={`${
                      item?.status === "New"
                        ? "font_blue"
                        : item?.status === "Completed"
                        ? "font_green"
                        : item?.status === "Canceled" ||
                          item?.status === "Rejected" ||
                          item?.status === "Closed"
                        ? "font_red"
                        : "font_yellow"
                    }`}
                  >
                    {generateStatus(item).status}
                  </td>

                  {/* <td className={`${generateStatus(item).subStatusColor}`}>
                    {generateStatus(item).subStatusVisible &&
                      generateStatus(item).subStatus}
                  </td> */}

                  {/* Actions */}
                  <td className="text-start action-btn-cell">
                    <IconButton
                      onClick={() =>
                        navigate(item?._id, {
                          state: { item },
                        })
                      }
                      className="action-button"
                    >
                      <img
                        src={EyeFilledBlue}
                        className="h_100"
                        alt="View Details"
                      />
                    </IconButton>

                    {/* <IconButton
                      // onClick={() =>
                      //   navigate("/tenant/guest-pass/edit", {
                      //     state: { item },
                      //   })
                      // }
                      className="action-button"
                    >
                      <img
                        src={DeleteRed}
                        className="h_100"
                        alt="View Details"
                      />
                    </IconButton> */}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}

        {maintenanceList?.length === 0 && (
          <div className=" text-center">
            <h3 className=" font_m font_yellow fw_6">No Data</h3>
          </div>
        )}
      </div>

      {/* PAGINATION */}
      {maintenanceList?.length > 0 && (
        <Pagination
          itemsPerPage="10"
          totalItems={totalCount}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      )}
    </>
  );
};
/* Component Ends */
export default TenantMaintenanceTable;
