import React, { useState, useEffect, useRef } from "react";

/* Import configuration starts */
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  validEmail,
  validateAtleastOneNumber,
  validateAtleastOneSpecialChar,
} from "../../../setup/utils/validations";
import { Formik } from "formik";
import GLOBAL from "../../../setup/constants/global";
import { Toastify } from "../../../setup/utils/toast";
import jwtDecode from "jwt-decode";
/* Import configuration ends */

/* Import redux slices starts */
import {
  resendVerificationCode,
  signup,
  verifyAccount,
  searchUser,
} from "../../../setup/store/slices/authSlice";
import {
  fetchCountries,
  fetchStatesByCountryId,
  fetchCitiesByStateId,
  getCountries,
  getStates,
  getCities,
} from "../../../setup/store/slices/countrySlice";
import { getAuthLoading } from "../../../setup/store/slices/unpersistedSlice";
import { showErrorAlert } from "../../../setup/store/slices/globalAlertSlice";
/* Import redux slices ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import CustomPhoneInput from "../../../components/ui/input/CustomPhoneInput/CustomPhoneInput";
import CustomTextInput from "../../../components/ui/input/CustomTextInput/CustomTextInput";
import CustomPasswordInput from "../../../components/ui/input/CustomPasswordInput/CustomPasswordInput";
import CustomSelectInput from "../../../components/ui/input/CustomSelectInput/CustomSelectInput";
import CustomButton from "../../../components/ui/button/CustomButton/CustomButton";
import SuccessModal from "../../../components/ui/modal/SuccessModal/SuccessModal";
import ConfirmationOTPModal from "../../../components/ui/modal/ConfirmationOTPModal/ConfirmationOTPModal";
import CustomCheckInput from "../../../components/ui/input/CustomCheckInput/CustomCheckInput";
import TextButton from "../../../components/ui/button/TextButton/TextButton";
/* Import local pages and component ends */

/* Component starts */
const SignUpForm = (props) => {
  /* Props destructuring starts */
  const { setShowTerms } = props;
  /* Props destructuring ends */

  /* Component states and useRefs declaration starts */
  const [errorWhileSubmiting, setErrorWhileSubmiting] = useState(null);
  const [registeredEmail, setRegisteredEmail] = useState("");
  const [registeredPassword, setRegisteredPassword] = useState("");
  const [formIntialValue, setFormIntialValue] = useState({
    first_name: "",
    last_name: "",
    mobile: "",
    email: "",
    password: "",
    confirm_password: "",
    business_name: "",
    address_line1: "",
    address_line2: "",
    zipcode: "",
  });

  // Custom input field values
  const [selectedCountry, setSelectedCountry] = useState();
  const [selectedState, setSelectedState] = useState();
  const [selectedCity, setSelectedCity] = useState();
  const [terms, setTerms] = useState(false);
  // Modal state
  const [showSignupSuccessModal, setShowSignupSuccessModal] = useState(false);
  const [showMobileVerificationModal, setShowMobileVerificationModal] =
    useState(false);
  const [message, setMessage] = useState("");

  // useRefs declarations
  const decodedInfo = useRef(null);
  const customPhoneNum = useRef({ code: "", num: "" });
  const formikPasswordValidation = useRef(false);
  /* Component states and useRefs declaration ends */

  /* Other hooks declaration starts */
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const { profession } = jwtDecode(params.encodedToken);
  // Redux selector
  const countries = useSelector(getCountries);
  const states = useSelector(getStates);
  const cities = useSelector(getCities);
  const authLoading = useSelector(getAuthLoading);
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  // Handling On Country code change in Phone field
  const onCountryCodeChanged = (country) => {
    const defaultCountry = countries.filter((c) => {
      return c.iso2.toLowerCase() == country.countryCode;
    })[0];
    setSelectedCountry(defaultCountry);
  };

  // Loading all countries
  const loadCountries = async () => {
    await dispatch(fetchCountries());
  };

  //Loading all states
  const loadStates = async () => {
    if (selectedCountry != null) {
      setSelectedState(null);
      setSelectedCity(null);
      await dispatch(
        fetchStatesByCountryId({ country_id: selectedCountry.id })
      );
    }
  };

  // Loading all cities
  const loadCities = async () => {
    if (selectedState != null) {
      setSelectedCity(null);
      await dispatch(fetchCitiesByStateId({ state_id: selectedState.id }));
    }
  };

  // Error text if the required input field is empty
  const errorMsgForEmptyFields = (fieldName) => {
    switch (fieldName) {
      case "first_name":
        return "First Name is required";
      case "last_name":
        return "Last Name is required";
      case "email":
        return "Email is required";
      case "password":
        return "Password is required";
      case "confirm_password":
        return "Confirm Password is required";
      case "business_name":
        return "Company Name is required";
      case "address_line1":
        return "Address is required";
      case "zipcode":
        return "ZIP code is required";
      case "mobile":
        return "Phone Number is required";
      default:
        return "Invalid Input";
    }
  };

  // Input fields that are not required are prevented to be validated
  const preventToBeValidated = (key) => {
    // Preventing Adress Line 2 to be validated
    return !(key === "address_line2" || key === "zipcode");
  };

  // Input fields that are required are validated
  const requiredFieldsValidation = (errorFields, values) => {
    Object.keys(values).map((key) => {
      /* Preventing address line 2 field to be validated */
      if (values[key].trim() === "" && preventToBeValidated(key)) {
        errorFields[key] = errorMsgForEmptyFields(key);
      }
    });
    return errorFields;
  };

  // Other form validations logics
  const otherFieldValidation = (errorFields, values) => {
    /* Validation for first name starts */
    if (values.first_name && validateAtleastOneNumber(values.first_name)) {
      errorFields.first_name = "First Name Cannot Contains Numbers";
    }
    if (values.first_name && validateAtleastOneSpecialChar(values.first_name)) {
      errorFields.first_name = "First Name Cannot Contains Special Characters";
    }
    /* Validation for first name ends */
    /* *******************************************************************************  */
    /* Validation for last name starts */
    if (values.last_name && validateAtleastOneNumber(values.last_name)) {
      errorFields.last_name = "Last Name Cannot Contains Numbers";
    }
    if (values.last_name && validateAtleastOneSpecialChar(values.last_name)) {
      errorFields.last_name = "Last Name Cannot Contains Special Characters";
    }
    /* Validation for last name ends */
    /* *******************************************************************************  */
    /* Validation for email starts */
    if (values.email && !validEmail(values.email)) {
      errorFields.email = "Email is not valid";
    }
    /* Validation for email ends */
    /* *******************************************************************************  */
    /* Validation for phone number starts */
    if (customPhoneNum.current.num.length < 8) {
      errorFields.mobile = "Phone number is not valid";
    }
    /* Validation for phone number ends */
    /* *******************************************************************************  */
    /* Validation for Password starts */
    if (values.password && formikPasswordValidation.current) {
      errorFields.password = true;
    }
    /* Validation for Password ends */
    /* *******************************************************************************  */
    /* Validation for Confirm Password starts */
    if (
      values.confirm_password &&
      values.password !== values.confirm_password
    ) {
      errorFields.confirm_password =
        "Confirm Password doesn't match with the password";
    }
    /* Validation for Confirm Password ends */
  };

  // Form validations
  const formValidation = (values) => {
    const errorFields = {};
    requiredFieldsValidation(errorFields, values);
    otherFieldValidation(errorFields, values);
    return errorFields;
  };

  // On verifying OTP sent to registered mobile number
  const onVerifyingMobile = async (otp) => {
    if (otp.length != 6) {
      Toastify("error", "Enter valid OTP");
      return;
    }

    // Creating or gathering payload data to be sent
    const payload = {
      otp: otp,
      email: registeredEmail,
      password: registeredPassword,
    };

    // Backend Response. Try, Catch
    try {
      const result = await dispatch(verifyAccount(payload));
      // Handling success response
      switch (result.meta.requestStatus) {
        case "rejected":
          Toastify("error", result.payload);
          break;
        case "fulfilled":
          setShowMobileVerificationModal(false);
          setMessage("You have successfully signed up");
          setShowSignupSuccessModal(true);
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };

  // On resending verification otp
  const onResendAccountVerificationCode = async () => {
    // Creating or gathering payload data to be sent
    const payload = {
      email: registeredEmail,
    };
    try {
      const result = await dispatch(resendVerificationCode(payload));
      // Handling success response
      switch (result.meta.requestStatus) {
        case "rejected":
          Toastify("error", result.payload);
          break;
        case "fulfilled":
          Toastify("success", result.payload.message);
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };

  // On closing any modal
  const onClosingModal = () => {
    navigate("/signin/team/invite/" + params.encodedToken);
  };

  // Searching user with email if already existed or not
  const searchAccount = async (email) => {
    const { role_id } = decodedInfo.current;
    const payload = `role_id=${role_id}&email=${encodeURIComponent(email)}`;
    try {
      const result = await dispatch(searchUser(payload));
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          return false;
          break;

        case GLOBAL.FULFILLED:
          setMessage(
            "User already exist in the system, Sign In by clicking here."
          );
          setShowSignupSuccessModal(true);
          return true;
          break;
      }
    } catch (error) {
      console.log(error);
    }
  };

  // Handling on signing up
  const onSignUp = async (values, { setSubmitting }, userExisted) => {
    setErrorWhileSubmiting(null);
    if (userExisted) return;

    // Creating or gathering payload data to be sent
    const payload = {
      ...values,
      profession: profession || "",
      first_name: values.first_name.trim(),
      last_name: values.last_name.trim(),
      business_name: values.business_name.trim(),
      zipcode: values.zipcode.trim(),
      city: selectedCity?.name || "",
      state: selectedState?.name || "",
      country: selectedCountry?.name || "",
      countrycode: customPhoneNum.current.code,
      mobile: customPhoneNum.current.num,
      email: values.email.toLowerCase(),
      role_id: decodedInfo.current.role_id,
    };
    console.log("Payload", payload);

    // Backend Response. Try, Catch
    try {
      const result = await dispatch(signup(payload));
      console.log("Response", result);
      setSubmitting(false);
      // Handling success response
      switch (result.meta.requestStatus) {
        case "rejected":
          setErrorWhileSubmiting(result.payload);
          break;
        case "fulfilled":
          if (result?.payload?.user?.mobile_verified) {
            navigate("/signin/team/invite/" + params.encodedToken);
          } else {
            setRegisteredEmail(values.email.toLowerCase());
            setRegisteredPassword(values.password);
            setShowMobileVerificationModal(true);
          }
          break;
      }
    } catch (error) {
      // Handling error response
      setSubmitting(false);
    }
  };

  // On submitting form
  const onSubmit = async (values, obj) => {
    if (!terms) {
      dispatch(
        showErrorAlert({
          title: "Error",
          description: "Please confirm terms and conditions",
        })
      );
      return;
    }
    try {
      const userExisted = await searchAccount(values.email.toLowerCase());
      if (!userExisted) await onSignUp(values, obj, userExisted);
    } catch (error) {
      console.log(error);
    }
  };
  /* Component function definition ends */

  /* Component useEffect starts */
  // Loading all countries and fetching user roles initially
  useEffect(() => {
    loadCountries();
  }, []);

  // Fetching decoded token from url and setting to form initial value, on loading of params hook
  useEffect(() => {
    if (params) {
      decodedInfo.current = jwtDecode(params.encodedToken);
      const {
        first_name,
        last_name,
        email,
        countrycode,
        mobile,
        business_name,
        country,
      } = decodedInfo.current;

      setFormIntialValue((prevState) => ({
        ...prevState,
        first_name,
        last_name,
        email,
        mobile: countrycode + mobile,
        business_name: business_name || "",
      }));
      if (country) {
        setSelectedCountry(countries?.filter((d) => d?.name === country)[0]);
      }
      customPhoneNum.current = { code: countrycode, num: mobile };
    }
  }, [params]);

  // Setting selected country after loading countries
  useEffect(() => {
    if (countries?.length > 0) {
      const defaultCountry = countries?.filter(
        (country) => country.iso3 === "USA"
      )[0];
      setSelectedCountry(defaultCountry);
    }
  }, [countries]);

  // Loading states accoring to country selected
  useEffect(() => {
    loadStates();
  }, [selectedCountry]);

  // Setting selected state after loading state
  useEffect(() => {
    if (states?.length > 0) {
      setSelectedState(states[0]);
    }
  }, [states]);

  // Loading cities accoring to state selected
  useEffect(() => {
    loadCities();
  }, [selectedState]);

  // Setting selected city after loading cities
  useEffect(() => {
    if (cities?.length > 0) {
      setSelectedCity(cities[0]);
    }
  }, [cities]);
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div>
      {/* Sign up form */}
      <Formik
        onSubmit={onSubmit}
        initialValues={formIntialValue}
        validate={formValidation}
        validateOnBlur={true}
        enableReinitialize={true}
      >
        {({
          values,
          errors,
          handleChange,
          handleSubmit,
          handleBlur,
          touched,
          isSubmitting,
        }) => (
          <form className="form" onSubmit={handleSubmit}>
            {/* Input for First Name */}
            <div className="form-group">
              <CustomTextInput
                type="text"
                label="First Name"
                handleBlur={handleBlur}
                handleChange={handleChange}
                value={values.first_name}
                name="first_name"
                errors={errors.first_name}
                touched={touched.first_name}
                readOnly={true}
              />
            </div>

            {/* Input for Last Name */}
            <div className="form-group">
              <CustomTextInput
                type="text"
                label="Last Name"
                handleBlur={handleBlur}
                handleChange={handleChange}
                value={values.last_name}
                name="last_name"
                errors={errors.last_name}
                touched={touched.last_name}
                readOnly={true}
              />
            </div>

            {/* Input for Email Address */}
            <div className="form-group">
              <CustomTextInput
                type="text"
                label="Email Address"
                handleBlur={handleBlur}
                handleChange={handleChange}
                value={values.email}
                name="email"
                errors={errors.email}
                touched={touched.email}
                readOnly={true}
              />
            </div>

            {/* Input for Phone Number */}
            <div className="form-group">
              <CustomPhoneInput
                name="mobile"
                label={"Phone Number"}
                customPhoneNum={customPhoneNum}
                handleChange={handleChange}
                handleBlur={handleBlur}
                onCountryCodeChanged={onCountryCodeChanged}
                value={values.mobile}
                countryCodeEditable={true}
                errors={errors.mobile}
                touched={touched.mobile}
                readOnly={true}
              />
            </div>

            {/* Input for Password */}
            <div className="form-group">
              <CustomPasswordInput
                name="password"
                label="Password"
                formikPasswordValidation={formikPasswordValidation}
                handleChange={handleChange}
                handleBlur={handleBlur}
                value={values.password}
                errors={errors.password}
                touched={touched.password}
              />
            </div>

            {/* Input for Confirm Password */}
            <div className="form-group">
              <CustomPasswordInput
                name="confirm_password"
                label="Confirm Password"
                showPasswordValidationErr={false}
                handleChange={handleChange}
                handleBlur={handleBlur}
                value={values.confirm_password}
                errors={errors.confirm_password}
                touched={touched.confirm_password}
              />
            </div>

            {/* Input for Business Name */}
            <div className="form-group">
              <CustomTextInput
                type="text"
                label="Company Name"
                handleBlur={handleBlur}
                handleChange={handleChange}
                value={values.business_name}
                name="business_name"
                errors={errors.business_name}
                touched={touched.business_name}
              />
            </div>

            {/* Input for Address Line 1 */}
            <div className="form-group">
              <CustomTextInput
                type="text"
                label="Address Line 1"
                handleBlur={handleBlur}
                handleChange={handleChange}
                value={values.address_line1}
                name="address_line1"
                errors={errors.address_line1}
                touched={touched.address_line1}
              />
            </div>

            {/* Input for Address Line 2 */}
            <div className="form-group">
              <CustomTextInput
                type="text"
                label="Address Line 2"
                handleBlur={handleBlur}
                handleChange={handleChange}
                value={values.address_line2}
                name="address_line2"
                required={false}
              />
            </div>

            {/* Select field for Cities */}
            <div className="form-group">
              <CustomSelectInput
                name="city"
                label="City"
                handleBlur={handleBlur}
                onChange={handleChange}
                valueExtractor={(item) => item?.name}
                setValue={setSelectedCity}
                options={cities}
                value={selectedCity}
                formikState={false}
                emptyOption={false}
              />
            </div>

            {/* Select field for State and Zip-code */}
            <div className="form-group form-group-flex">
              <CustomSelectInput
                name="state"
                label="State"
                handleBlur={handleBlur}
                onChange={handleChange}
                valueExtractor={(item) => item?.name}
                setValue={setSelectedState}
                options={states}
                value={selectedState}
                formikState={false}
                emptyOption={false}
              />

              {/* Input for Zip Code */}
              <CustomTextInput
                type="text"
                label="Zip Code"
                handleBlur={handleBlur}
                handleChange={handleChange}
                value={values.zipcode}
                name="zipcode"
                errors={errors.zipcode}
                touched={touched.zipcode}
                required={false}
              />
            </div>

            {/* Select field for Countries */}
            <div className="form-group">
              <CustomSelectInput
                name="country"
                label="Country"
                handleBlur={handleBlur}
                onChange={handleChange}
                valueExtractor={(item) => item?.name}
                setValue={setSelectedCountry}
                options={countries}
                value={selectedCountry}
                formikState={false}
                emptyOption={false}
              />
            </div>

            {/* If any error occured from backend while submitting */}
            {errorWhileSubmiting && (
              <Link
                to="/sigin"
                className="error-while-submitting font_m font_red text-center w_100"
              >
                {errorWhileSubmiting}
              </Link>
            )}

            <div className="terms-conditions-wrapper">
              <CustomCheckInput
                value={terms}
                //setValue={setTerms}
              />
              {/* <p className="font_m text">
                <span>Accept</span>{" "}
                <TextButton
                  onClick={() => {
                    setShowTerms(true);
                    setTerms(!terms);
                  }}
                >
                  Terms and Conditions
                </TextButton>
              </p> */}
              <p className="font_m text">
                Accept
                <span
                  className="font_blue fw_6 mx-1 pe-auto"
                  onClick={() => {
                    setShowTerms(true);
                    setTerms(!terms);
                  }}
                >
                  Terms and Conditions
                </span>
              </p>
            </div>

            {/* Submit button and Sign up loading */}
            <div className="button-wrapper w_100">
              {authLoading ? (
                <p className="sigining-up font_m font_grey text-center">
                  Signing you up
                </p>
              ) : (
                <CustomButton size="l" className="" type="submit">
                  Sign Up
                </CustomButton>
              )}
            </div>
          </form>
        )}
      </Formik>

      {/* Modals */}
      {/* Success Modal after sign up*/}
      <SuccessModal
        showModal={showSignupSuccessModal}
        title="Signed Up"
        description={message}
        onClose={onClosingModal}
        btnText="Sign In"
      />

      {/* Mobile Verification OTP Phone modal */}
      <ConfirmationOTPModal
        showModal={showMobileVerificationModal}
        title="Enter Confirmation Code"
        size="lg"
        description="Enter the OTP that has been sent to your registered mobile number"
        onVerify={onVerifyingMobile}
        onCancel={onClosingModal}
      />
    </div>
  );
};
/* Component ends */

export default SignUpForm;
