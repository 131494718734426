/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
/* Import configuration ends */

/* Import redux slices component starts */
import {
  fetchUserRoles,
  getUserRoles,
} from "../../../../setup/store/slices/masterSlice";
import { fetchUser, getUser } from "../../../../setup/store/slices/authSlice";

import {
  updateContractorPassByApproved,
  updateContractorPassByRejected,
} from "../../../../setup/store/slices/visitorPass";
import { setLoading } from "../../../../setup/store/slices/unpersistedSlice";

/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */

/* Import image and SVG ends */

/* Import local pages and component starts */
import GLOBAL from "../../../../setup/constants/global";
import CustomButton from "../../../../components/ui/button/CustomButton/CustomButton";
import { VisitorPass } from "../../../../setup/constants/images";
import NoteModal from "../../../../components/module/Maintenance/NoteModal/NoteModal";
import {
  showErrorAlert,
  showSuccessAlert,
} from "../../../../setup/store/slices/globalAlertSlice";
/* Import local pages and component ends */

/* Component starts */
const Report = (props) => {
  /* Props destructuring starts */
  const { incident } = props;
  console.log(incident, "[inc]");
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [role, setRole] = useState();
  const [openNote, setOpenNote] = useState(false);
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userRoles = useSelector(getUserRoles);
  const user = useSelector(getUser);
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  const USER_DATA = localStorage.getItem("user_data")
    ? JSON.parse(localStorage.getItem("user_data"))
    : null;
  const DATE_FORMAT = USER_DATA ? USER_DATA?.date_format : "";
  const TIME_FORMAT = USER_DATA ? USER_DATA?.time_format : "";
  const DATE_TIME_FORMAT = `${DATE_FORMAT} ${TIME_FORMAT}`;
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  const handleApprovePass = (id) => {
    dispatch(updateContractorPassByApproved(id));
    navigate(-1);
  };

  const handleRejectPass = async (note) => {
    setOpenNote(false);
    // dispatch(updateContractorPassByRejected(id));

    const payload = {
      id: incident?._id,
      reason: note,
      type: incident?.type,
    };

    console.log(payload, "PPP");

    try {
      const result = await dispatch(updateContractorPassByRejected(payload));
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          dispatch(
            showErrorAlert({
              title: "Error!",
              description: result.payload,
            })
          );
          break;

        case GLOBAL.FULFILLED:
          dispatch(
            showSuccessAlert({
              title: "Success!",
              description: result.payload.message,
            })
          );
          break;
      }
    } catch (e) {
      console.error(e.message);
    }

    // navigate(-1);
  };
  /* Component function definition ends */

  /* Component useEffect starts */

  useEffect(() => {
    (async () => {
      try {
        await dispatch(setLoading(true));
        await dispatch(fetchUserRoles());
        await dispatch(fetchUser());
        await dispatch(setLoading(false));
      } catch (e) {}
    })();
  }, [dispatch]);

  // Setting role after all user roles loaded
  useEffect(() => {
    let userRole = userRoles.filter((d) => d._id === user?.role_id)[0]?.name;
    setRole(userRole);
  }, [userRoles]);

  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <>
      <div className="contractor-unit-detail-box">
        <div className="log-header">
          <div className="img-wrapper">
            <img
              src={
                incident?.image !== undefined ? incident?.image : VisitorPass
              }
              alt="Visitor-Pass"
            />
          </div>

          <div className="detail">
            <h3 className="num font_s fw_4 font_grey">
              {" "}
              Ticket No. :-{" "}
              <span className="font_blue fw_6">
                {incident?.visitorpassId}
              </span>{" "}
            </h3>
            <h4 className="fw_6 font_l font_blue">
              <span>{incident?.guest_name}</span>
            </h4>
            <p className="font_s font_grey">
              Type :- <span className="fw_6">{incident?.type}</span>
            </p>
          </div>
        </div>

        <hr />

        <div className="visitor-info">
          <div>
            <p className="fw_3 font_m font_grey">
              Mobile Number <br />
              <span className="fw_4 font_m">
                {incident?.mobile_number !== undefined
                  ? incident?.mobile_number
                  : "-"}
              </span>
            </p>
          </div>

          <div className="reason-info">
            <p className="fw_4 font_m font_grey">
              Reason For Access
              <br />
              <span className="fw_6 font_m">
                {incident?.guest_type !== undefined
                  ? incident?.guest_type
                  : "-"}
              </span>
            </p>
          </div>

          <div className="reason-info">
            <p className="fw_4 font_m font_grey">
              License Plate Number
              <br />
              <span className="fw_6 font_m">
                {incident?.car_number !== undefined
                  ? incident?.car_number
                  : "-"}
              </span>
            </p>
          </div>
        </div>

        <hr />

        <div className="time-info">
          <div>
            <p className="fw_4 font_m font_grey">
              Start Date
              <br />
              <span className="fw_4 font_m">
                {moment(incident?.start_date).format(DATE_TIME_FORMAT)}
              </span>
            </p>
          </div>
          <div>
            <p className="fw_4 font_m font_grey">
              End Date
              <br />
              <span className="fw_4 font_m">
                {moment(incident?.end_date).format(DATE_TIME_FORMAT)}
              </span>
            </p>
          </div>
          <div>
            <p className="fw_4 font_m font_grey">
              Status
              <br />
              <span
                className={`${
                  incident?.contract_status === "pending"
                    ? "font_green"
                    : incident?.contract_status === "rejected" ||
                      incident?.contract_status === "expired"
                    ? "reject"
                    : incident?.contract_status === "approved"
                    ? "font_blue"
                    : "font_yellow"
                } fw_6`}
              >
                {incident?.contract_status?.charAt(0)?.toUpperCase() +
                  incident?.contract_status?.slice(1)}
              </span>
            </p>
          </div>
        </div>

        {incident?.rejected_reason && (
          <>
            <hr />
            <div className="time-info">
              <div>
                <p className="fw_4 font_m font_grey">
                  Rejected Reason
                  <br />
                  <span className="fw_4 font_m">
                    {incident?.rejected_reason}
                  </span>
                </p>
              </div>
            </div>
          </>
        )}

        <hr />

        <div className="request_by">
          <p className="fw_3 font_m font_grey">
            Tenant <br />
            <span className="fw_6 font_m font_blue">{incident?.requester}</span>
          </p>
        </div>

        {incident?.updated_by !== undefined && (
          <>
            <hr />

            <div className="request_by">
              <p className="fw_3 font_m font_grey">
                Updated By <br />
                <span className="fw_3 font_m px-3">
                  {incident?.updated_by?.first_name}{" "}
                  {incident?.updated_by?.last_name}
                </span>
              </p>
            </div>
          </>
        )}

        {incident?.contract_status === "pending" &&
          role === "Property Manager" && (
            <div className="btn-wrapper">
              <CustomButton
                onClick={() => handleApprovePass(incident?._id)}
                size="l"
                className=""
                type="submit"
              >
                Approve
              </CustomButton>
              <CustomButton
                // onClick={() => handleRejectPass(incident?._id)}
                onClick={() => setOpenNote(true)}
                size="l"
                className="reject-btn"
                type="submit"
              >
                Reject
              </CustomButton>
            </div>
          )}
      </div>

      {/* Note Modal */}
      <NoteModal
        showModal={openNote}
        actionWithNote={handleRejectPass}
        title="Reject"
        inputPlaceholder="Comment"
        btnText="Reject"
        showError={true}
        onCancel={() => setOpenNote(false)}
      />
    </>
  );
};
/* Component Ends */
export default Report;
