/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { Formik } from "formik";
import { useNavigate } from "react-router-dom";
/* Import configuration ends */

/* Import redux slices component starts */
import {
  showSuccessAlert,
  showErrorAlert,
} from "../../../../../../setup/store/slices/globalAlertSlice";
import {
  assignIncidentToSO,
  assignSo,
  closeSecurityRequestWithoutViolation,
  smEscalateToPM,
  smNeedMoreInfo,
  updateIncidentEscalateBySM,
  updateIncidentWithResolved,
} from "../../../../../../setup/store/slices/securitySlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import GLOBAL from "../../../../../../setup/constants/global";
import FileViewerModal from "../../../../../ui/modal/FileViewerModal/FileViewerModal";
import CustomTextArea from "../../../../../ui/input/CustomTextArea/CustomTextArea";
import CustomButton from "../../../../../ui/button/CustomButton/CustomButton";
import CustomRadioInput from "../../../../../ui/input/CustomRadioInput/CustomRadioInput";
import CustomSelectInput from "../../../../../ui/input/CustomSelectInput/CustomSelectInput";
import AssignSoModal from "../../AssignSoModal/AssignSoModal";
import Image from "../../../../../ui/other/Image/Image";
import { logTitle } from "../helper";
import { getUserTheme } from "../../../../../../setup/store/slices/authSlice";
import { NoProfPicSet } from "../../../../../../setup/constants/images";
/* Import local pages and component ends */

/* Component starts */
const SMLog = (props) => {
  /* Props destructuring starts */
  const {
    incidentData,
    incident,
    active = false,
    isLast = false,
    report = null,
    user,
    role,
    securityOfficers,
  } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [reason, setReason] = useState("");
  const [selectedOfficer, setSelectedOfficer] = useState();

  const [showSoList, setShowSoList] = useState(false);
  const [showFileViewer, setShowFileViewer] = useState(false);
  const [file, setFile] = useState({});
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userTheme = useSelector(getUserTheme);
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */

  const initialFormValues = {
    comment: "",
  };

  const name = incident?.user
    ? `${incident?.user?.first_name} ${incident?.user?.last_name}`
    : `${incident.tenant.first_name} ${incident.tenant.last_name}`;
  const incidentRole = incident?.user?.role_id?.name;
  const createdAt = incident?.date;
  const comment = incident?.comment;
  const reportData = incident?.report;

  const USER_DATA = localStorage.getItem("user_data")
    ? JSON.parse(localStorage.getItem("user_data"))
    : null;
  const DATE_FORMAT = USER_DATA ? USER_DATA?.date_format : "";
  const TIME_FORMAT = USER_DATA ? USER_DATA?.time_format : "";
  const DATE_TIME_FORMAT = `${DATE_FORMAT} ${TIME_FORMAT}`;
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  const handleCancel = () => {
    navigate(-1);
  };

  const onSecurityOfficerSelected = async (item) => {
    const payload = {
      request_id: incident.tenant_security_request_id,
      so_id: item?._id,
    };

    setShowSoList(!showSoList);

    console.log(payload);

    // try {
    //   const result = await dispatch(assignSo(payload));

    //   // Handling success response
    //   switch (result.meta.requestStatus) {
    //     case GLOBAL.REJECTED:
    //       dispatch(
    //         showErrorAlert({
    //           title: "Error!",
    //           description: result.payload,
    //         })
    //       );
    //       break;
    //     case GLOBAL.FULFILLED:
    //       dispatch(
    //         showSuccessAlert({
    //           title: "Success!",
    //           description: result.payload.message,
    //         })
    //       );
    //       break;
    //   }
    // } catch (error) {
    //   // Handling error response
    //   console.error(error.message);
    // }
  };

  // SM -> Resolved the Request
  const resolveRequest = async (payload) => {
    console.log(payload, "RE_P");

    // Backend response. Try, Catch
    try {
      const result = await dispatch(updateIncidentWithResolved(payload));

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          dispatch(
            showErrorAlert({
              title: "Error!",
              description: result.payload,
            })
          );
          break;
        case GLOBAL.FULFILLED:
          dispatch(
            showSuccessAlert({
              title: "Success!",
              description: result.payload.message,
            })
          );
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };

  // SM -> Escalating To PM
  const escalateToPm = async (payload) => {
    // Backend response. Try, Catch
    try {
      const result = await dispatch(updateIncidentEscalateBySM(payload));

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          dispatch(
            showErrorAlert({
              title: "Error!",
              description: result.payload,
            })
          );
          break;
        case GLOBAL.FULFILLED:
          dispatch(
            showSuccessAlert({
              title: "Success!",
              description: result.payload.message,
            })
          );
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };

  // SM -> Need More Information - Assigned Back to SO
  const smNeedMoreInformation = async (payload) => {
    console.log(payload, "PPPPP");
    // Backend response. Try, Catch
    try {
      const result = await dispatch(assignIncidentToSO(payload));

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          dispatch(
            showErrorAlert({
              title: "Error!",
              description: result.payload,
            })
          );
          break;
        case GLOBAL.FULFILLED:
          dispatch(
            showSuccessAlert({
              title: "Success!",
              description: result.payload.message,
            })
          );
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };

  const respondToReport = async (val) => {
    let payload = {};

    switch (reason) {
      case "need-more":
        // Need more info
        // payload["comment"] = val.comment;
        payload["security_incident_id"] = incidentData?._id;
        payload["note"] = val.comment;
        payload["assigned_to"] = selectedOfficer?._id || null;
        smNeedMoreInformation(payload);

        break;
      case "escalated-PM":
        // Escalate to PM
        // payload["comment"] = val.comment;
        // payload["report"] = incident.report._id;
        payload["note"] = val.comment;
        payload["security_incident_id"] = incidentData?._id;

        escalateToPm(payload);
        break;
      case "resolved":
        // Resolve

        payload["security_incident_id"] = incidentData?._id;
        payload["status"] = "Resolved";
        payload["note"] = val.comment;

        resolveRequest(payload);
        break;
    }
  };

  const getActionButtons = () => {
    if (role !== GLOBAL.USER_ROLE.SM) return;

    console.log(incident, "[incident.status]");

    switch (incident.action) {
      case "Security incident assigned":
      case "Security incident is reassigned to security manager":
        return (
          <>
            <div className="sm-report-form">
              <Formik
                onSubmit={(val) => respondToReport(val)}
                initialValues={initialFormValues}
                validateOnBlur={true}
                enableReinitialize={true}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <div className="form-wrapper p-3">
                      {/* Creating new Security Incident Report */}
                      <>
                        {/* Radio select the Reason */}
                        <div className="form-group form-group-full d-flex justify-content-between">
                          <CustomRadioInput
                            label="Need More Information"
                            onSelecting={() => {
                              setReason("need-more");
                            }}
                            isSelected={reason === "need-more"}
                          />

                          <CustomRadioInput
                            label="Escalated It To Property Manager"
                            onSelecting={() => {
                              setReason("escalated-PM");
                            }}
                            isSelected={reason === "escalated-PM"}
                          />
                        </div>

                        <div className="select-reason">
                          <CustomRadioInput
                            label="Resolved"
                            onSelecting={() => {
                              setReason("resolved");
                            }}
                            isSelected={reason === "resolved"}
                          />
                        </div>

                        {/* Select Officer */}
                        {reason === "need-more" && (
                          <div className="form-group">
                            <p className="mt-3">
                              Select Officers{" "}
                              <span
                                style={{ color: "red", fontWeight: "bold" }}
                              >
                                *
                              </span>
                            </p>
                            <CustomSelectInput
                              name="officer"
                              label="Officers"
                              handleBlur={handleBlur}
                              onChange={handleChange}
                              valueExtractor={(item) =>
                                `${item?.first_name} ${item?.last_name}`
                              }
                              setValue={setSelectedOfficer}
                              options={securityOfficers}
                              value={selectedOfficer}
                              formikState={false}
                              emptyOption={true}
                            />
                          </div>
                        )}

                        {/* Text Area for Reason */}
                        {(reason === "need-more" ||
                          reason === "escalated-PM") && (
                          <div className="form-group form-group-full mt-4">
                            <CustomTextArea
                              name="comment"
                              label="Comment"
                              handleChange={handleChange}
                              handleBlur={handleBlur}
                              value={values.comment}
                              errors={errors.comment}
                              touched={touched.comment}
                              required={true}
                            />
                          </div>
                        )}
                      </>
                    </div>

                    <div className="form-btn-wrapper">
                      <>
                        <CustomButton
                          type="button"
                          size="l"
                          variant="red"
                          onClick={() => handleCancel()}
                        >
                          Cancel
                        </CustomButton>
                        <CustomButton type="submit" size="l" variant="yellow">
                          Submit
                        </CustomButton>
                      </>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </>
        );

      default:
    }
  };

  const renderReport = () => {
    return (
      <div className="so-report-wrapper">
        <div>
          <p className="font_m fw_4 font_grey">
            Description :{" "}
            <span className="font_xs fw_4 font_white">
              {incident?.report?.description}
            </span>
          </p>
        </div>
        {incident?.report?.document?.length > 0 && (
          <>
            <div className="report-doc-wrapper">
              {incident?.report?.document?.map((image, index) => (
                <div className="proof-img" key={index}>
                  <Image
                    key={index}
                    className="media"
                    imgClass="thumbnail"
                    src={image?.url}
                    alt="Attatched Media"
                    objectFit="cover"
                    videoThumbnail={image?.type2 === "video"}
                    onClick={() => {
                      setFile(image);
                      setShowFileViewer(true);
                    }}
                  />
                </div>
              ))}
            </div>
          </>
        )}
      </div>
    );
  };
  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <>
      <div className="time-line simple-log">
        {/* Dot */}

        <div className="dot-wrapper">
          <i className="dot"></i>
          <div className="vl"></div>
        </div>

        <div className="detail-wrapper">
          {/* Title */}
          <h3 className="title font_m fw_6">{logTitle(incident)}</h3>

          {/* Technician Name and Date */}
          <div className="black-container-sm fw_5 font_s bg_black flex-between">
            {/* Name */}
            <p className="left">
              <span className="font_grey">{incidentRole} </span>
              <span className="font_white"> {name}</span>
            </p>

            {/* Date and Time */}
            <p className="right font_grey">
              {moment(createdAt).format(DATE_TIME_FORMAT)}
            </p>
          </div>
          {/* Log Footer */}
          <div className="log-footer">
            {/* Comments */}
            {comment && (
              <p className="comments font_s fw-5">
                <span className="font_grey fw_6">Comments: </span>
                <span className="fw_5 font_red"> {comment}</span>
              </p>
            )}

            {reportData && renderReport()}

            {/* Action Buttons */}
            {active && getActionButtons()}
          </div>
        </div>
      </div>

      {/* MODALS */}
      {/* ASSIGN SO MANUALLY */}
      <AssignSoModal
        showModal={showSoList}
        setShowModal={setShowSoList}
        data={securityOfficers}
        onClose={() => setShowSoList(!showSoList)}
        onSelected={(item) => {
          onSecurityOfficerSelected(item);
        }}
      />

      {/* File viewer modal */}
      <FileViewerModal
        show={showFileViewer}
        type={file.type}
        file={file.url}
        onClose={() => {
          setShowFileViewer(false);
          setFile({});
        }}
      />
    </>
  );
};
/* Component ends */

export default SMLog;
