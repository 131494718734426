/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import { useParams, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import GLOBAL from "../../setup/constants/global";
/* Import configuration ends */

/* Import redux slices component starts */
import {
  fetchMaintenanceById,
  getMaintenance,
} from "../../setup/store/slices/maintenanceSlice";
import { getUser } from "../../setup/store/slices/authSlice";
import {
  fetchPropertyDetailByID,
  getPropertyDetail,
} from "../../setup/store/slices/propertySlice";
import { setLoading } from "../../setup/store/slices/unpersistedSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import NormalMaintenanceDetail from "../../components/module/Maintenance/NormalMaintenanceDetail/NormalMaintenanceDetail";
import MoveInMaintenanceDetail from "../../components/module/Maintenance/MoveInMaintenanceDetail/MoveInMaintenanceDetail";
import MoveOutMaintenanceDetail from "../../components/module/Maintenance/MoveOutMaintenanceDetail/MoveOutMaintenanceDetail";
import BreadCrumb from "../../components/ui/other/BreadCrumb/BreadCrumb";
/* Import local pages and component ends */

/* Component starts */
const MaintnanceDetailPage = (props) => {
  /* Props destructuring starts */
  const {} = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  // const [maintenance, setMaintenance] = useState(null);
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const dispatch = useDispatch();
  const { propertyId, id } = useParams();
  // Redux Selector
  const maintenance = useSelector(getMaintenance);
  const property = useSelector(getPropertyDetail);
  const user = useSelector(getUser);
  const { pathname } = useLocation();
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  const type = maintenance?.type;
  const mgmtModel = maintenance?.property_id?.mgmt_model;
  const isT1 = mgmtModel === 0;
  const isT2 = mgmtModel === 1;
  const isT3 = mgmtModel === 2;
  const status = maintenance?.status;
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */

  /* Component function definition ends */

  /* Component useEffect starts */
  useEffect(() => {
    (async () => {
      if (id) {
        // Backend response. Try, Catch
        try {
          const result = await dispatch(
            fetchMaintenanceById({ maintenance_id: id })
          );
          console.log("Response", result);

          // Handling success response
          switch (result.meta.requestStatus) {
            case GLOBAL.REJECTED:
              // setMaintenance(null);
              break;

            case GLOBAL.FULFILLED:
              // setMaintenance(result?.payload);
              break;
          }
        } catch (error) {
          // Handling error response
          console.error(error.message);
        }
      }
    })();
  }, [id]);
  useEffect(() => {
    (async () => {
      try {
        await dispatch(setLoading(true));
        await dispatch(fetchPropertyDetailByID({ property_id: propertyId }));
        await dispatch(setLoading(false));
      } catch (e) {}
    })();
  }, []);
  /* Component useEffect ends */
  console.log(maintenance);
  /* ************* Component rendering. JSX code ************* */
  if (maintenance)
    return (
      <>
        <BreadCrumb
          type="maintenance_detail"
          from={pathname.includes("move-out") ? "move-out" : ""}
        />
        <div className="maintenance-detail-page-wrapper">
          {/* Normal Maintenance */}
          {type === "Maintenance" && (
            <NormalMaintenanceDetail
              isT1={isT1}
              isT2={isT2}
              isT3={isT3}
              mgmtModel={mgmtModel}
              type={type}
              status={status}
              maintenance={maintenance}
              maintenance_id={id}
              property={property}
              user={user}
            />
          )}

          {/* Move In Maintenance */}
          {type === "Move In" && (
            <MoveInMaintenanceDetail
              isT1={isT1}
              isT2={isT2}
              isT3={isT3}
              mgmtModel={mgmtModel}
              type={type}
              status={status}
              maintenance={maintenance}
              maintenance_id={id}
              property={property}
              user={user}
            />
          )}

          {/* Move Out Maintenance */}
          {(type === "Move Out" || type === "Force move out") && (
            <MoveOutMaintenanceDetail
              isT1={isT1}
              isT2={isT2}
              isT3={isT3}
              mgmtModel={mgmtModel}
              type={type}
              status={status}
              maintenance={maintenance}
              maintenance_id={id}
              property={property}
              user={user}
            />
          )}
        </div>
      </>
    );
};
/* Component ends */

export default MaintnanceDetailPage;
