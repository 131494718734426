/**
 *
 */

import React, { useState } from "react";

/* Import configuration starts */
import {
  logTitle,
  canPMRateService,
  canMTMarkJobComplete,
  canTPTMarkJobComplete,
  canTPMRespondToTicket,
  canFMRespondToTicket,
  canPMapproveMaintenanceJob,
} from "../helper";
import moment from "moment";
import GLOBAL from "../../../../../setup/constants/global";
import { useDispatch } from "react-redux";
/* Import configuration ends */

/* Import redux slices component starts */
import {
  showSuccessAlert,
  showErrorAlert,
} from "../../../../../setup/store/slices/globalAlertSlice";
import { addInternalMaintenanceRating } from "../../../../../setup/store/slices/ratingSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import FileViewerModal from "../../../../ui/modal/FileViewerModal/FileViewerModal";
import Image from "../../../../ui/other/Image/Image";
import CustomButton from "../../../../ui/button/CustomButton/CustomButton";
import RateAndReviewModal from "../../../TenantMaintenance/RateAndReviewModal/RateAndReviewModal";
import {
  fmRespondToInternalMaintenance,
  t2FmRespondToJob,
  t2TechnicianMarkJobComplete,
  t2tpTechnicianMarkJobComplete,
  technicianMarkJobComplete,
  tpTechnicianMarkJobComplete,
  // t2FmCancelMaintenanceWork,
  pmApproveFinalMaintenanceByTpt,
  pmApproveFinalMaintenanceByMt,
} from "../../../../../setup/store/slices/internalMaintenanceSlice";
import AddMaintenanceInspectionReport from "../../../Maintenance/AddMaintenanceInspectionReport/AddMaintenanceInspectionReport";
import NoteModal from "../../../Maintenance/NoteModal/NoteModal";
import AssignTechnicianModal from "../../../Maintenance/AssignTechnicianModal/AssignTechnicianModal";
/* Import local pages and component ends */

/* Component starts */
const MaintenanceFinalReportLog = (props) => {
  /* Props destructuring starts */
  const {
    property,
    maintenance_id,
    maintenance,
    item,
    status,
    isT1,
    isT2,
    isT3,
    takeAction,
    user,
    isTPM,
    isTPT,
    toRateCompany,
    toRateTech,
    technicians,
    requestAssignedToTPM,
  } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [file, setFile] = useState({});
  const [showFile, setShowFile] = useState(false);
  const [totalEstimation, setTotalEstimation] = useState(0);
  const [showRateReviewModal, setShowRateReviewModal] = useState(false);

  const [addInspectionReport, setAddInspectionReport] = useState(false);
  const [openNote, setOpenNote] = useState(false);
  const [assignMT, setAssignMT] = useState(false);
  // const [estimationArray, setEstimationArray] = useState([]);

  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const dispatch = useDispatch();
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  const name = item?.requester
    ? `${item?.requester?.first_name} ${item?.requester?.last_name}`
    : `${item.tenant.first_name} ${item.tenant.last_name}`;
  const role = item?.requester_role?.name;
  const logType = item?.log_type;
  const createdAt = item?.createdAt;
  const maintenanceType = maintenance?.type;
  const USER_DATA = localStorage.getItem("user_data")
    ? JSON.parse(localStorage.getItem("user_data"))
    : null;
  const DATE_FORMAT = USER_DATA ? USER_DATA?.date_format : "";
  const TIME_FORMAT = USER_DATA ? USER_DATA?.time_format : "";
  const DATE_TIME_FORMAT = `${DATE_FORMAT} ${TIME_FORMAT}`;
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  const actionWithNote = (note) => {
    setOpenNote(false);
    // On MT/TPT respond to Maintenance Request
    (canTPMRespondToTicket(item, user) || canFMRespondToTicket(item, user)) &&
      fmRespondToMaintenance(false, note);

    // On respond to Maintenance Request
    canPMapproveMaintenanceJob(item, user) &&
      pmRespondToMaintenance(false, note);
  };

  const calculateTotalEstimation = (items) => {
    const total = items?.reduce((acc, item) => acc + item?.estimate, 0);
    setTotalEstimation(total);
  };

  // PM / FM / OWNER RATING & REVIEW
  const onRated = async (d) => {
    // setShowRating(false);
    const payload = {
      maintenance_id,
      logType,
      company: toRateCompany?._id,
      technician: toRateTech?._id,
      ...d,
    };

    try {
      let result = await dispatch(addInternalMaintenanceRating(payload));
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          dispatch(
            showErrorAlert({
              title: "Error!",
              description: result.payload,
            })
          );
          break;

        case GLOBAL.FULFILLED:
          dispatch(
            showSuccessAlert({
              title: "Success!",
              description: result.payload.message,
            })
          );
          break;
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  // MT Mark Job As Complete
  const techMarkJobComplete = async ({ description, document }) => {
    // Creating or collecting payload data to be sent
    const payload = {
      maintenance_id,
      type: maintenance?.status,
      status: "Completed",
      description,
      document,
    };
    console.log("Payload", payload);

    // Backend response. Try, Catch
    try {
      let result;

      if (isTPT) {
        if (isT2) {
          result = await dispatch(t2tpTechnicianMarkJobComplete(payload));
          // result = await dispatch(tpTechnicianMarkJobComplete(payload));
        } else {
          result = await dispatch(tpTechnicianMarkJobComplete(payload));
        }
      } else {
        if (isT2) {
          result = await dispatch(t2TechnicianMarkJobComplete(payload));
          // result = await dispatch(technicianMarkJobComplete(payload));
        } else {
          result = await dispatch(technicianMarkJobComplete(payload));
        }
      }

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          dispatch(
            showErrorAlert({
              title: "Error!",
              description: result.payload,
            })
          );
          break;

        case GLOBAL.FULFILLED:
          dispatch(
            showSuccessAlert({
              title: "Success!",
              description: result.payload.message,
            })
          );
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };

  // FM / TPM Approve / Reject the Ticket Completed JOB
  const fmRespondToMaintenance = async (accept = true, comment = "") => {
    // setRejectNReassignTech(false);
    let payload = {
      maintenance_id,
      // type,
      logType,
      status: accept ? "accepted" : "rejected",
    };
    if (!accept) {
      payload["comment"] = comment;
    }

    try {
      let result;
      /*       if (accept) {
        if (isT2) {
          result = await dispatch(t2FmRespondToJob(payload));
        } else {
          result = await dispatch(fmRespondToInternalMaintenance(payload));
        }
      } else {
        if (isT2) {
          if (isTPM) {
            result = await dispatch(t2FmCancelMaintenanceWork(payload));
          } else {
            result = await dispatch(t2FmCancelMaintenanceWork(payload));
          }
        } else {
        }
      } */
      if (isT2) {
        result = await dispatch(t2FmRespondToJob(payload));
      } else {
        result = await dispatch(fmRespondToInternalMaintenance(payload));
      }
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          dispatch(
            showErrorAlert({
              title: "Error!",
              description: result.payload,
            })
          );
          break;

        case GLOBAL.FULFILLED:
          dispatch(
            showSuccessAlert({
              title: "Success!",
              description: result.payload.message,
            })
          );
          break;
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  // On Assigning Technician
  const onAssignTechnician = (t, n) => {
    setAssignMT(false);
    // On FM/TPM assigning technician

    (canTPMRespondToTicket(item, user) || canFMRespondToTicket(item, user)) &&
      fmRespondToMaintenance(false, n, t);
  };

  // PM Approve / Reject the Ticket Completed JOB
  const pmRespondToMaintenance = async (accept = true, comment = "") => {
    // setRejectNReassignTech(false);
    let payload = {
      maintenance_id,
      // type,
      logType,
      status: accept ? "accepted" : "rejected",
    };
    if (!accept) {
      payload["comment"] = comment;
    }

    console.log("Payload ", payload);

    try {
      let result;
      if (requestAssignedToTPM) {
        result = await dispatch(pmApproveFinalMaintenanceByTpt(payload));
      } else {
        result = await dispatch(pmApproveFinalMaintenanceByMt(payload));
      }
      console.log("Result ", result);

      switch (result?.meta?.requestStatus) {
        case GLOBAL.REJECTED:
          dispatch(
            showErrorAlert({
              title: "Error!",
              description: result.payload,
            })
          );
          break;

        case GLOBAL.FULFILLED:
          dispatch(
            showSuccessAlert({
              title: "Success!",
              description: result.payload.message,
            })
          );
          break;
      }
    } catch (error) {
      console.error(error.message);
    }
  };
  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="time-line maintenance-final-log">
      {/* Dot */}

      <div className="dot-wrapper">
        <i className="dot"></i>
        <div className="vl"></div>
      </div>

      <div className="detail-wrapper">
        {/* Title */}
        <h3 className="title font_m fw_6">{logTitle(item)}</h3>

        {/* Technician Name and Date */}
        <div className="black-container-sm fw_5 font_s bg_black flex-between">
          {/* Name */}
          <p className="left">
            <span className="font_grey">{role} </span>
            <span className="font_white"> {name}</span>
          </p>

          {/* Date and Time */}
          <p className="right font_grey">
            {moment(createdAt).format(DATE_TIME_FORMAT)}
          </p>
        </div>

        {item?.data?.items && (
          <div className="uirc-detail">
            {/* Details */}
            <div className="black-container-sm uirc-data">
              {item?.data?.items?.map((estimateItem, index) => (
                <div className="report-item">
                  {/* Header */}
                  <h2 className="font_m fw_6 font_blue report-item-heading">
                    Report Item #{index + 1}
                  </h2>
                  <div className="r font_s fw_5">
                    <p className="c1 font_grey">Description</p>
                    {/* <p className="c2 font_grey">Estimate ({property.currency})</p> */}
                  </div>

                  {/* Body */}
                  <div key={index} className="r font_s fw_5">
                    <div className="c1">
                      <p className="font_m fw_5 font_white">
                        {estimateItem?.description}
                      </p>

                      <h3 className="attachments-txt fw_5 font_s font_grey">
                        Attachments
                      </h3>

                      {estimateItem?.documents?.length > 0 ? (
                        <div className="doc-row">
                          {estimateItem?.documents?.map((doc, index) => (
                            <Image
                              onClick={() => {
                                setFile(doc);
                                setShowFile(true);
                              }}
                              src={doc?.url}
                              videoThumbnail={doc?.type2 === "video"}
                              className="doc-item"
                              objectFit="cover"
                            />
                          ))}
                        </div>
                      ) : (
                        <p className="font_xxs font_yellow fw_4">
                          No Attachments Added!
                        </p>
                      )}
                    </div>

                    {/* <div className="c2">
                    <input
                      type="text"
                      className="transparent-input fw_5"
                      readOnly={true}
                      value={estimateItem?.estimate?.toLocaleString("en-US")}
                    />
                  </div> */}
                  </div>
                </div>
              ))}
              <hr />

              {/* <div className="my-2 r font_s fw_5">
              <p className="font_m fw_5 c1 font_grey">Total Amount </p>
              <p className="font_m fw_6 c2 font_blue">
                {totalEstimation?.toLocaleString("en-US")} ({property?.currency}
                )
              </p>
            </div> */}
            </div>
          </div>
        )}

        {takeAction && (
          <>
            <div className="button-wrapper">
              {/* PM rating service */}
              {canPMRateService(item, user) && !maintenance?.is_rated && (
                <CustomButton
                  onClick={() => setShowRateReviewModal(true)}
                  size="m"
                  variant="yellow"
                >
                  Rate and Review
                </CustomButton>
              )}
            </div>

            {(canMTMarkJobComplete(item, user) ||
              canTPTMarkJobComplete(item, user)) && (
              <CustomButton
                onClick={() => setAddInspectionReport(!addInspectionReport)}
                variant="yellow"
                size="m"
              >
                Mark Job Complete
              </CustomButton>
            )}

            {/* FM accepting request */}
            {isT3 && canPMapproveMaintenanceJob(item, user) && (
              <div className="d-flex justify-content-between gap-2">
                <CustomButton
                  onClick={() => pmRespondToMaintenance()}
                  variant="yellow"
                  size="m"
                >
                  Approve
                </CustomButton>
                <CustomButton
                  onClick={() => setOpenNote(true)}
                  variant="red"
                  size="m"
                >
                  Reject
                </CustomButton>
              </div>
            )}

            {/* FM accepting request */}
            {(isT3 || isT2) &&
              (canTPMRespondToTicket(item, user) ||
                canFMRespondToTicket(item, user)) && (
                <div className="d-flex justify-content-between gap-2">
                  <CustomButton
                    onClick={() => fmRespondToMaintenance()}
                    variant="yellow"
                    size="m"
                  >
                    Approve
                  </CustomButton>
                  <CustomButton
                    onClick={() => setOpenNote(true)}
                    variant="red"
                    size="m"
                  >
                    Reject
                  </CustomButton>
                </div>
              )}

            {/* RATING & REVIEW MODAL */}
            {!maintenance?.is_rated && (
              <RateAndReviewModal
                showModal={showRateReviewModal}
                btnText="Submit"
                onCancel={() => setShowRateReviewModal(false)}
                onSubmit={onRated}
                rateTechnician={!isT1}
              />
            )}

            <AddMaintenanceInspectionReport
              showModal={addInspectionReport}
              onClose={() => setAddInspectionReport(false)}
              onAddReport={techMarkJobComplete}
              title="Internal Maintenance Report"
            />

            {/* Note Modal */}
            {/* <NoteModal
              showModal={openNote}
              actionWithNote={fmRespondToMaintenance(false)}
              title="Reject"
              inputPlaceholder="Comment"
              btnText="Reject"
              showError={true}
              onCancel={() => setOpenNote(false)}
            /> */}
          </>
        )}
      </div>

      {/* File Viewer Modal */}
      <FileViewerModal
        show={showFile}
        file={file.url}
        type={file.type}
        onClose={() => {
          setShowFile(false);
          setFile({});
        }}
      />
      {takeAction && (
        <>
          <AssignTechnicianModal
            action={onAssignTechnician}
            showModal={assignMT}
            technicians={technicians}
            onCancel={() => setAssignMT(false)}
            textAreaInput={
              canTPMRespondToTicket(item, user) ||
              canFMRespondToTicket(item, user)
            }
          />

          {/* Note Modal */}
          <NoteModal
            showModal={openNote}
            actionWithNote={actionWithNote}
            title="Reject"
            inputPlaceholder="Comment"
            btnText="Reject"
            showError={true}
            onCancel={() => setOpenNote(false)}
          />
        </>
      )}
    </div>
  );
};
/* Component ends */

export default MaintenanceFinalReportLog;
