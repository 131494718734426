/**
 *
 */

import React from "react";
/* Import configuration starts */
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
/* Import configuration ends */

/* Import redux slices component starts */
/* Import redux slices component ends */

/* Import react bootstrap component starts */
import { Table } from "react-bootstrap";
/* Import react bootstrap component ends */

/* Import image and SVG starts */
import { EyeFilledBlue } from "../../../../setup/constants/images";
/* Import image and SVG ends */

/* Import local pages and component starts */
import CustomStarRate from "../../../ui/other/CustomStarRate/CustomStarRate";
import IconButton from "../../../ui/button/IconButton/IconButton";
import Pagination from "../../../ui/other/Pagination/Pagination";
/* Import local pages and component ends */

// Styles
import "./style.scss";

/* Component starts */
const TeamsRatingTable = (props) => {
  /* Props destructuring starts */
  const { ratings, totalCount, currentPage, setCurrentPage, propertyId } =
    props;

  /* Props destruc/turing ends */

  /* Component states and useRef declaration starts */
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const navigate = useNavigate();
  const { pathname } = useLocation();
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */

  const USER_DATA = localStorage.getItem("user_data")
    ? JSON.parse(localStorage.getItem("user_data"))
    : null;
  const DATE_FORMAT = USER_DATA ? USER_DATA?.date_format : "";
  const TIME_FORMAT = USER_DATA ? USER_DATA?.time_format : "";
  const DATE_TIME_FORMAT = `${DATE_FORMAT} ${TIME_FORMAT}`;
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */

  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */

  return (
    <>
      <div className="custom-table-wrapper teams-rating-table-wrapper">
        {ratings?.length > 0 && (
          <Table className="custom-table font_s" borderless responsive>
            {/* Table Heading */}
            <thead>
              <tr>
                <th>ID</th>
                <th>Date & Time</th>
                <th>Teams</th>
                <th>Location</th>
                <th>Category</th>
                <th>Sub Category</th>
                <th>Inner Category</th>
                <th>Rating</th>
                <th>Action</th>
              </tr>
            </thead>
            {/* Table Body */}
            <tbody>
              {ratings?.map((item) => (
                <tr key={item._id}>
                  {/* ID */}
                  <td>{item?.request_id}</td>

                  {/* Date */}
                  <td>{moment(item?.dateTime).format(DATE_TIME_FORMAT)}</td>

                  {/* Tenant */}
                  <td>{item?.team_name}</td>

                  <td>{item.location}</td>

                  <td>{item?.category ? item?.category : "-"}</td>

                  <td>{item?.subcategory ? item?.subcategory : "-"}</td>

                  {/* Inner Category */}
                  <td>{item?.outercategory ? item?.outercategory : "-"}</td>

                  {/* Rating */}
                  <td className="ratings">
                    {item?.rating !== undefined ? (
                      <CustomStarRate stars={item?.rating} size={20} />
                    ) : (
                      "NA"
                    )}
                  </td>

                  {/* Actions */}
                  <td className="text-start">
                    <IconButton
                      onClick={() =>
                        navigate(
                          `/independent/properties/${propertyId}/maintenances/Closed/${item?._id}`,
                          {
                            state: { pathname: pathname },
                          }
                        )
                      }
                      className="action-button"
                    >
                      <img
                        src={EyeFilledBlue}
                        className="h_100"
                        alt="View Details"
                      />
                    </IconButton>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}

        {ratings?.length === 0 && (
          <div className=" text-center">
            <h3 className=" font_m font_yellow fw_6">No Data</h3>
          </div>
        )}
      </div>

      {/* PAGINATION */}
      {ratings?.length > 0 && (
        <Pagination
          itemsPerPage="10"
          totalItems={totalCount}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      )}
    </>
  );
};
/* Component Ends */
export default TeamsRatingTable;
