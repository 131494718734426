/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import { useParams } from "react-router-dom";
import moment from "moment";
import { logTitle, canFMReviewSpareParts } from "../helper";
import { useDispatch } from "react-redux";
import GLOBAL from "../../../../../setup/constants/global";
/* Import configuration ends */

/* Import redux slices component starts */
import {
  showSuccessAlert,
  showErrorAlert,
} from "../../../../../setup/store/slices/globalAlertSlice";
import { fmRespondToSpareParts } from "../../../../../setup/store/slices/maintenanceSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import NoteModal from "../../NoteModal/NoteModal";
import CustomButton from "../../../../ui/button/CustomButton/CustomButton";
/* Import local pages and component ends */

/* Component starts */
const SparePartLog = (props) => {
  /* Props destructuring starts */
  const {
    isT1,
    status,
    maintenance,
    item,
    takeAction,
    maintenance_id,
    property,
    user,
    isT2,
    isTPM,
    isTPT,
  } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [showNote, setShowNote] = useState(false);
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const dispatch = useDispatch();
  const { propertyId } = useParams();
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  const taskList = maintenance?.phase2_URIC?.items;
  const name = item?.requester 
    ? `${item?.requester?.first_name} ${item?.requester?.last_name}`
    : `${item?.tenant?.first_name} ${item?.tenant?.last_name}`;
  const role = item?.requester_role?.name;
  const createdAt = item?.createdAt;
  const action = item?.action;
  const currentStatus = item?.current_status;
  const logType = item?.log_type;
  const comment = item?.comment;
  const maintenanceType = maintenance?.type;
  const UIRCp2 = maintenance?.phase2_URIC;

  const USER_DATA = localStorage.getItem("user_data")
    ? JSON.parse(localStorage.getItem("user_data"))
    : null;
  const DATE_FORMAT = USER_DATA ? USER_DATA?.date_format : "";
  const TIME_FORMAT = USER_DATA ? USER_DATA?.time_format : "";
  const DATE_TIME_FORMAT = `${DATE_FORMAT} ${TIME_FORMAT}`;
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  // On action with note
  const actionWithNote = (note) => {
    setShowNote(false);
    // On FM rejecting spare parts
    canFMReviewSpareParts(item, user) && fmReviewSpareParts(false, note);
  };

  // On FM reviewing spare parts
  const fmReviewSpareParts = async (accept = true, notes = "") => {
    // Creating or collecting payload data to be sent
    const payload = {
      maintenance_id,
      request_id: item.data._id,
      status: accept ? "approved" : "rejected",
      notes,
      type: maintenanceType,
    };
    console.log("Payload", payload);

    // Backend response. Try, Catch
    try {
      let result;
      result = await dispatch(fmRespondToSpareParts(payload));
      // if (isT2) {
      //   if (isTPM) {
      //   } else {
      //   }
      // } else {
      //   if (isTPM) {
      //   } else {
      //   }
      // }

      console.log("Response", result);

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          dispatch(
            showErrorAlert({
              title: "Error!",
              description: result.payload,
            })
          );
          break;

        case GLOBAL.FULFILLED:
          dispatch(
            showSuccessAlert({
              title: "Success!",
              description: result.payload.message,
            })
          );
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };
  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="time-line spare-part-log">
      {/* Dot */}

      <div className="dot-wrapper">
        <i className="dot"></i>
        <div className="vl"></div>
      </div>

      <div className="detail-wrapper">
        {/* Title */}
        <h3 className="title font_m fw_6">{logTitle(item)}</h3>

        {/* Technician Name and Date */}
        <div className="black-container-sm fw_5 font_s bg_black flex-between">
          <p className="left">
            <span className="font_grey">{role} </span>
            <span className="font_white"> {name}</span>
          </p>
          <p className="right font_grey">
            {moment(createdAt).format(DATE_TIME_FORMAT)}
          </p>
        </div>

        {/* Task List */}
        <div className="spare-part-quote black-container-lg fw_5 font_s">
          <p className="sub-title font_m fw_6">Spare Part List</p>

          <div className="part-list">
            {/* If log type is not Additional, or if this log does not contain additional task */}
            {item?.data?.part?.map((part, index) => (
              <div key={index} className="part">
                <h3 className="part-title font_m fw_6 font_blue">
                  Part #{index + 1}
                </h3>
                {/* Row 1 */}
                <div className="r r1">
                  <p className="label font_s fw_6 font_grey">Part Name</p>
                  <p className="value font_s fw_6">{part?.part_name}</p>
                </div>

                {/* Row 2 */}
                <div className="r r2">
                  <p className="label font_s fw_6 font_grey">Quantity</p>
                  <p className="value font_s fw_6">{part?.quantity}</p>
                </div>

                {/* Row 3 */}
                <div className="r r2">
                  <p className="label font_s fw_6 font_grey">Description</p>
                  <p className="value font_s fw_6">{part?.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Log Footer */}
        <div className="log-footer">
          {takeAction && (
            <div className="button-wrapper">
              {/* MT responding to maintenance request */}
              {canFMReviewSpareParts(item, user) && (
                <>
                  <CustomButton
                    onClick={() => fmReviewSpareParts()}
                    size="m"
                    variant="yellow"
                  >
                    Accept
                  </CustomButton>
                  <CustomButton
                    variant="red"
                    onClick={() => setShowNote(true)}
                    size="m"
                  >
                    Reject
                  </CustomButton>
                </>
              )}
            </div>
          )}
        </div>
      </div>

      {/* Modals */}
      {/* Note Modal */}
      <NoteModal
        showModal={showNote}
        actionWithNote={actionWithNote}
        title="Reject"
        inputPlaceholder="Comment"
        btnText="Reject"
        showError={true}
        onCancel={() => setShowNote(false)}
      />
    </div>
  );
};
/* Component ends */

export default SparePartLog;
