/**
 *
 */

import React, { useState } from "react";
/* Import configuration starts */
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { contractListStatus, moveOutStatus } from "../helper";
import { useDispatch } from "react-redux";
import { Tooltip as ReactTooltip } from "react-tooltip";
/* Import configuration ends */

/* Import redux slices component starts */
import { showWarningAlert } from "../../../../../setup/store/slices/globalAlertSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
import { Table } from "react-bootstrap";
/* Import react bootstrap component ends */

/* Import image and SVG starts */
import {
  DashboardMove,
  DocumentGreen,
  ExcalmatoryYellow,
  UploadClearenceDoc,
} from "../../../../../setup/constants/images";
/* Import image and SVG ends */

/* Import local pages and component starts */
import IconButton from "../../../../ui/button/IconButton/IconButton";
import ViewContractModal from "../../ViewContractModal/ViewContractModal";
import InfoMessageModal from "../../../../ui/modal/InfoMessageModal/InfoMessageModal";
/* Import local pages and component ends */

/* Component starts */
const ContractsTable = (props) => {
  /* Props destructuring starts */
  const { contracts } = props;

  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [showContract, setShowContract] = useState(false);
  const [detail, setDetail] = useState();
  const [infoModal, setInfoModal] = useState({
    showModal: false,
    title: "",
    description: "",
  });
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const navigate = useNavigate();
  const dispatch = useDispatch();
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  const USER_DATA = localStorage.getItem("user_data")
    ? JSON.parse(localStorage.getItem("user_data"))
    : null;
  const DATE_FORMAT = USER_DATA ? USER_DATA?.date_format : "";

  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  const handleShowContract = (item) => {
    setDetail(item);
    setShowContract(!showContract);
  };
  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */

  return (
    <div className="custom-table-wrapper contract-table-wrapper">
      <Table className="custom-table font_s" borderless responsive>
        {/* Table Heading */}
        <thead>
          <tr>
            <th>Start Date</th>
            <th>End Date</th>
            <th>Status</th>
            <th>Moveout Status</th>
            <th>Rent Amount</th>
            <th>Action</th>
          </tr>
        </thead>
        {/* Table Body */}
        <tbody>
          {contracts?.map((item) => (
            <tr key={item._id}>
              {/* Start Date */}
              <td className="txt-nowrap">
                {moment(item?.start_date).format(DATE_FORMAT)}
              </td>

              {/* End Date */}
              <td className="txt-nowrap">
                {moment(item?.end_date).format(DATE_FORMAT)}
              </td>

              {/* Contract Status */}
              <td className={`${contractListStatus(item)?.color} txt-nowrap`}>
                {contractListStatus(item)?.status}
              </td>

              <td className="txt-nowrap">
                {item?.move_out_initiated &&
                !item?.move_out &&
                !item?.is_renewal_pending ? (
                  <p className="font_yellow fw_5">Select Date</p>
                ) : item?.move_out_status ? (
                  <p className="font_yellow fw_5">
                    {moveOutStatus(item)?.status}
                  </p>
                ) : (
                  "-"
                )}
              </td>

              <td className="txt-nowrap">{`${item?.total_rent?.toLocaleString(
                "en-US"
              )} ${item?.currency}`}</td>

              {/* Actions */}
              <td className="text-start txt-nowrap">
                {item.can_move_out && (
                  <IconButton
                    onClick={() => {
                      dispatch(
                        showWarningAlert({
                          title: "Warning",
                          description:
                            "You are trying to move out from the current unit. Remember this is an irreversible action.",
                        })
                      );
                      navigate(`${item._id}/moveout/select_date`);
                    }}
                    className="action-button"
                  >
                    <img src={DashboardMove} className="h_100" alt="Move Out" />
                  </IconButton>
                )}

                {item.move_out_status !== "" && (
                  <IconButton
                    onClick={() =>
                      navigate(`${item._id}/moveout/${item.move_out._id}`, {
                        state: { item },
                      })
                    }
                    className="action-button"
                  >
                    <img
                      src={UploadClearenceDoc}
                      className="h_100"
                      alt="Upload Clearence"
                    />
                  </IconButton>
                )}

                <IconButton
                  onClick={() => handleShowContract(item)}
                  className="action-button"
                >
                  <img
                    src={DocumentGreen}
                    className="h_100"
                    alt="View Details"
                  />
                </IconButton>

                {item?.causes && (
                  <IconButton className="action-button">
                    <img
                      src={ExcalmatoryYellow}
                      alt="Exc-Icon"
                      className="h_100 px-2"
                      data-tooltip-id="my-tooltip-2"
                      data-tooltip-delay-hide={1000}
                    />
                    <ReactTooltip
                      id="my-tooltip-2"
                      place="top-end"
                      variant="warning"
                      style={{
                        backgroundColor: "#FFB900",
                        color: "#fff",
                        padding: "7px 15px",
                        fontWeight: "bold",
                      }}
                      opacity={1}
                    >
                      <ul>
                        {item?.causes?.map((item, index) => (
                          <li key={index}>{item}</li>
                        ))}
                      </ul>
                    </ReactTooltip>
                  </IconButton>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      <ViewContractModal
        showModal={showContract}
        // property={property}
        detail={detail}
        onClose={() => {
          setShowContract(!showContract);
        }}
      />

      <InfoMessageModal
        showModal={infoModal?.showModal}
        description={infoModal?.description}
        title={infoModal?.title}
        onClose={() =>
          setInfoModal({
            showModal: false,
            title: "",
            description: "",
          })
        }
      />
    </div>
  );
};
/* Component Ends */
export default ContractsTable;
