/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import moment from "moment";
import { logTitle } from "../helper";
import { useDispatch } from "react-redux";
import GLOBAL from "../../../../../setup/constants/global";
import { pmCanApproveQuote } from "../helper";
import { useParams } from "react-router-dom";
/* Import configuration ends */

/* Import redux slices component starts */
import {
  showSuccessAlert,
  showErrorAlert,
} from "../../../../../setup/store/slices/globalAlertSlice";
import { pmRespondQuote } from "../../../../../setup/store/slices/moveInSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import CustomButton from "../../../../ui/button/CustomButton/CustomButton";
import NoteModal from "../../NoteModal/NoteModal";
/* Import local pages and component ends */

/* Component starts */
const QuotationLog = (props) => {
  /* Props destructuring starts */
  const {
    isT1,
    status,
    maintenance,
    item,
    takeAction,
    maintenance_id,
    property,
    user,
    isT3,
  } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [isApproved, setIsApproved] = useState(null);
  const [openNote, setOpenNote] = useState(false);
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const dispatch = useDispatch();
  const { propertyId } = useParams();
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  const quote = item?.data;
  const partList = item?.data?.part;
  const quoteNum = item?.data?.spare_part_quote_number;
  const reqNum = item?.data?.spare_part_request_number;
  const totalCost = item?.data?.total_cost;
  const name = item?.requester
    ? `${item?.requester?.first_name} ${item?.requester?.last_name}`
    : `${item.tenant.first_name} ${item.tenant.last_name}`;
  const role = item?.requester_role?.name;
  const createdAt = item?.createdAt;
  const action = item?.action;
  const currentStatus = item?.current_status;
  const logType = item?.log_type;
  const comment = item?.comment;
  const maintenanceType = maintenance?.type;

  const USER_DATA = localStorage.getItem("user_data")
    ? JSON.parse(localStorage.getItem("user_data"))
    : null;
  const DATE_FORMAT = USER_DATA ? USER_DATA?.date_format : "";
  const TIME_FORMAT = USER_DATA ? USER_DATA?.time_format : "";
  const DATE_TIME_FORMAT = `${DATE_FORMAT} ${TIME_FORMAT}`;
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  // On performing respond action with note such as Reject and Approve
  const respondAction = (value) => {
    // PM approving quoatation
    pmCanApproveQuote(item, user) && approveQuote(value);
  };

  // PM responding to quotation created by FM
  const approveQuote = async (notes) => {
    setIsApproved(null);
    setOpenNote(false);
    // Creating or collecting payload data to be sent
    const payload = {
      maintenance_id,
      type: maintenanceType,
      quotation_id: quote?._id,
      status: isApproved ? "Approved" : "Rejected",
      quotation_type: logType === "additional" ? "additional" : "quoatation",
      notes,
      property_id: propertyId,
      paid_by: isT3 && role === "Property Manager" && isApproved && "pmc",
    };

    // Backend response. Try, Catch
    try {
      const result = await dispatch(pmRespondQuote(payload));

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          dispatch(
            showErrorAlert({
              title: "Error!",
              description: result.payload,
            })
          );
          break;

        case GLOBAL.FULFILLED:
          dispatch(
            showSuccessAlert({
              title: "Success!",
              description: result.payload.message,
            })
          );
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };
  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="time-line quotation-log bg_grey">
      {/* Dot */}
      <div className="dot-wrapper">
        <i className="dot"></i>
        <div className="vl"></div>
      </div>

      <div className="detail-wrapper">
        {/* Title */}
        <h3 className="title font_m fw_6">{logTitle(item)}</h3>

        {/* Technician Name and Date */}
        <div className="black-container-sm fw_5 font_s bg_black flex-between">
          <p className="left">
            <span className="font_grey">{role} </span>
            <span className="font_white"> {name}</span>
          </p>
          <p className="right font_grey">
            {moment(createdAt).format(DATE_TIME_FORMAT)}
          </p>
        </div>

        {/* Spare Part Quote */}
        <div className="spare-part-quote black-container-lg fw_5 font_s">
          <p className="sub-title font_m fw_6">Spare Parts Quote</p>

          {/* Parts List */}
          <div className="part-list bg_black">
            {/* Spare Part Request and Quote Number */}
            <div className="r r1 flex-row-2">
              {/* Type */}
              <div className="c type">
                <p className="label font_s fw_6 font_grey">
                  Spare Part Request Number
                </p>
                <p className="value font_s fw_6">{reqNum}</p>
              </div>
              {/* Cost Covered By */}
              <div className="c cost-covered-by">
                <p className="label font_s fw_6 font_grey">
                  Spare Part Quote Number
                </p>
                <p className="value font_s fw_6">{quoteNum}</p>
              </div>
            </div>

            <hr />

            {/* Part Each */}
            {partList?.map((item, index) => (
              <div key={index} className="part">
                <h3 className="part-title font_m fw_6 font_blue">
                  Part #{index + 1}
                </h3>
                {/* Row 1 */}
                <div className="r r1 flex-row-3">
                  {/* Type */}
                  <div className="c type">
                    <p className="label font_s fw_6 font_grey">Type</p>
                    <p className="value font_s fw_6">{item?.type}</p>
                  </div>

                  {item?.type === "Part Quote" && (
                    <>
                      <div className="c cost-covered-by">
                        <p className="label font_s fw_6 font_grey">
                          Cost Covered By
                        </p>
                        <p className="value font_s fw_6">
                          {item?.cost_covered_by}
                        </p>
                      </div>
                      {/* Part Number */}
                      <div className="c part-num">
                        <p className="label font_s fw_6 font_grey">
                          Part Number
                        </p>
                        <p className="value font_s fw_6">{item?.part_number}</p>
                      </div>
                    </>
                  )}
                  {/* Cost Covered By */}
                </div>
                {item?.type === "Service Quote" && (
                  <>
                    <div className="r r2">
                      <p className="label font_s fw_6 font_grey">
                        Service Name
                      </p>
                      <p className="value font_s fw_6">{item?.service_name}</p>
                    </div>
                    <div className="r r2">
                      <p className="label font_s fw_6 font_grey">
                        Service Amount ({property.currency})
                      </p>
                      <p className="value font_s fw_6">
                        {item?.service_amount}
                      </p>
                    </div>
                  </>
                )}

                {item?.type === "Part Quote" && (
                  <>
                    {/* Row 2. Description */}
                    <div className="r r2">
                      <p className="label font_s fw_6 font_grey">Description</p>
                      <p className="value font_s fw_6">{item?.description}</p>
                    </div>

                    {/* Row 3. Company Name */}
                    <div className="r r3">
                      <p className="label font_s fw_6 font_grey">
                        Company Name
                      </p>
                      <p className="value font_s fw_6">{item?.company_name}</p>
                    </div>

                    {/* Row 4 */}
                    <div className="r r4 flex-row-3">
                      {/* Quantity */}
                      <div className="c quantity">
                        <p className="label font_s fw_6 font_grey">Quantity</p>
                        <p className="value font_s fw_6">{item?.quantity}</p>
                      </div>
                      {/* Estimated Delivery */}
                      <div className="c estimated-delivery">
                        <p className="label font_s fw_6 font_grey">
                          Estimated Delivery
                        </p>
                        <p className="value font_s fw_6">
                          {moment(item?.estimated_delivery_time).format(
                            DATE_TIME_FORMAT
                          )}
                        </p>
                      </div>
                      {/* Vendor */}
                      <div className="c vendor">
                        <p className="label font_s fw_6 font_grey">Vendor</p>
                        <p className="value font_s fw_6">{item?.vendor}</p>
                      </div>
                    </div>

                    {/* Row 5. Warranty */}
                    <div className="r r5">
                      <p className="label font_s fw_6 font_grey">Warranty</p>
                      <p className="value font_s fw_6"> {item?.warrenty}</p>
                    </div>

                    {/* Row 6. Price */}
                    <div className="r r6">
                      <p className="label font_s fw_6 font_grey">
                        Price ({property.currency})
                      </p>
                      <p className="value font_s fw_6">
                        {parseInt(item?.price)?.toLocaleString("en-US")}
                      </p>
                    </div>
                  </>
                )}
              </div>
            ))}

            {/* Total Amount of all parts */}
            <div className="total">
              <p className="label fw_6 font_s">Total ({property.currency})</p>
              <p className="value fw_6 font_blue font_s">
                {parseInt(totalCost)?.toLocaleString("en-US")}
              </p>
            </div>
          </div>
        </div>

        {/* Log Footer */}
        <div className="log-footer">
          {takeAction && (
            <div className="button-wrapper">
              {/* PM can approve quote */}
              {pmCanApproveQuote(item, user) && (
                <>
                  <CustomButton
                    onClick={() => {
                      setIsApproved(true);
                      setOpenNote(true);
                    }}
                    size="m"
                    variant="yellow"
                  >
                    Approve
                  </CustomButton>
                  <CustomButton
                    onClick={() => {
                      setIsApproved(false);
                      setOpenNote(true);
                    }}
                    variant="red"
                    size="m"
                  >
                    Reject
                  </CustomButton>
                </>
              )}
            </div>
          )}
        </div>
      </div>

      {/* Modals */}
      {/* Adding Note Modal */}
      <NoteModal
        showModal={openNote}
        title="Note"
        btnText={isApproved ? "Approve" : "Reject"}
        onCancel={() => setOpenNote(false)}
        actionWithNote={respondAction}
      />
    </div>
  );
};
/* Component ends */

export default QuotationLog;
