import GLOBAL from "../../../../setup/constants/global";

export const fieldKeys = {
  tenant: "",
  amount: "",
  description: "",
};

export const validations = (v, canSelectTenant, tenantList, costCoveredBy) => {
  const error = {};

  const { tenant, amount, description } = v;

  if (
    canSelectTenant &&
    tenantList?.length > 0 &&
    tenant === "" &&
    costCoveredBy === GLOBAL.USER_ROLE.TENANT
  ) {
    error.tenant = "Please select Tenant";
  }

  if (amount === "") {
    error.amount = "Amount is required";
  }

  if (description == "") {
    error.description = "Description is required";
  }

  return error;
};
