/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import moment from "moment";
import GLOBAL from "../../../../../setup/constants/global";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  logTitle,
  canOwnerAcceptReq,
  canOwnerCreateQuotation,
  canOwnerStartMaintenance,
  canOwnerCompleteMaintenance,
  canFmReassignManagers,
  canFMAcceptReq,
  canFMAssignMT,
  canMTRespondMaintenance,
  canMTMarkOnTheWay,
  canMTCheckIn,
  canMTNoAccess,
  canMTStrtWrkReq4Parts,
  canManagerCreateQuote,
  canMTHandover,
  canMTMarkJobComplete,
  canTPMPlaceOrder,
  canFMPlaceOrder,
  canFMRecieveSpareParts,
  canTPMReassignManagers,
  canTPMAcceptReq,
  canTPMAssignTPT,
  canTPTRespondMaintenance,
  canTPTMarkOnTheWay,
  canTPTNoAccess,
  canTPTCheckIn,
  canTPTStrtWrkReq4Parts,
  canTPTHandover,
  canTPTMarkJobComplete,
  canOwnerMarkOnTheWay,
  canOwnerMarkCheckIn,
  canOwnerMarkNoAccess,
  canOwnerAddInspectionReport,
  canOwnerPlaceOrder,
  canOwnerRecieveParts,
  canTechnicianUploadInspectionReport,
  canFMReviewQuotation,
  canTechnicianStartWork,
  fmCanEditQuote,
} from "../helper";
/* Import configuration ends */

/* Import redux slices component starts */
import {
  showErrorAlert,
  showSuccessAlert,
} from "../../../../../setup/store/slices/globalAlertSlice";
import {
  ownerRespondToT1Maintenance,
  fmRespondToT3Maintenance,
  fmAssignTechnician,
  technicianTakeAction,
  markTechnicianOnTheWay,
  fmRespondToT2Maintenance,
  reassignMaintenanceTicket,
  technicianCheckedIn,
  technicianMarkAsNoAccess,
  technicianStartWork,
  t2TechnicianStartWork,
  technicianAddSpareParts,
  t2TechnicianHandoverItems,
  technicianHandoverItems,
  t2TechnicianMarkJobComplete,
  technicianMarkJobComplete,
  fmAssignT2Technician,
  tpmRespondToT2Maintenance,
  tpmRespondToT3Maintenance,
  tpmAssignT2Technician,
  tpmAssignTechnician,
  t2TechnicianTakeAction,
  t2tpTechnicianTakeAction,
  tpTechnicianTakeAction,
  t2MarkTechnicianOnTheWay,
  t2tptMarkTechnicianOnTheWay,
  tptMarkTechnicianOnTheWay,
  t2TechnicianMarkAsNoAccess,
  t2tpTechnicianMarkAsNoAccess,
  tpTechnicianMarkAsNoAccess,
  t2TechnicianCheckedIn,
  t2tpTechnicianCheckedIn,
  tpTechnicianCheckedIn,
  t2tpTechnicianStartWork,
  tpTechnicianStartWork,
  t2tpTechnicianHandoverItems,
  tpTechnicianHandoverItems,
  t2tpTechnicianMarkJobComplete,
  tpTechnicianMarkJobComplete,
  ownerMarkOnTheWay,
  ownerMarkCheckedIn,
  ownerMarkAsNoAccess,
  ownerAddT1MaintenanceInspectionReport,
  mtAddInspection_t2_tenant,
  tptAddInspection_t2_tenant,
  fmRespondingToTPMQuote,
  mtAddInspection_t3_tenant,
  tptAddInspection_t3_tenant,
} from "../../../../../setup/store/slices/maintenanceSlice";
import {
  changeOrderStatus,
  pmRespondQuote,
} from "../../../../../setup/store/slices/moveInSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import CustomButton from "../../../../ui/button/CustomButton/CustomButton";
import SparePartQuoteModal from "../../SparePartQuoteModal/SparePartQuoteModal";
import MaintenacneTaskCompleteModal from "../../MaintenacneTaskCompleteModal/MaintenacneTaskCompleteModal";
import ReassignManagerModal from "../../ReassignManagerModal/ReassignManagerModal";
import AssignTechnicianModal from "../../AssignTechnicianModal/AssignTechnicianModal";
import NoteModal from "../../NoteModal/NoteModal";
import CustomRadioInput from "../../../../ui/input/CustomRadioInput/CustomRadioInput";
import SparePartRequestModal from "../../SparePartRequestModal/SparePartRequestModal";
import HandOverItemModal from "../../HandOverItemModal/HandOverItemModal";
import FileViewerModal from "../../../../ui/modal/FileViewerModal/FileViewerModal";
import Image from "../../../../ui/other/Image/Image";
import AddMaintenanceInspectionReport from "../../AddMaintenanceInspectionReport/AddMaintenanceInspectionReport";
import IconButton from "../../../../ui/button/IconButton/IconButton";
import { EyeFilledBlue } from "../../../../../setup/constants/images";
import { pathNamePopped } from "../../../../../setup/utils/global-helper";
import CreateQuotationInvoiceModal from "../../CreateQuotationInvoiceModal/CreateQuotationInvoiceModal";
/* Import local pages and component ends */

/* Component starts */
const SimpleLog = (props) => {
  /* Props destructuring starts */
  const {
    property,
    maintenance_id,
    maintenance,
    item,
    status,
    isT1,
    takeAction,
    user,
    managers,
    technicians,
    isT2,
    isT3,
    isTPM,
    isTPT,
  } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [addQuotation, setAddQuotation] = useState(false);
  const [addReport, setAddReport] = useState(false);
  const [reassignManager, setReassignManager] = useState(false);
  const [assignMT, setAssignMT] = useState(false);
  const [openNote, setOpenNote] = useState(false);
  const [mtOnLocationAction, setMtOnLocationAction] = useState(0);
  const [requestSpareParts, setRequestSpareParts] = useState(false);
  const [showHandover, setShowHandover] = useState(false);
  const [showAddInspectionReport, setShowAddInspectionReport] = useState(false);
  const [showFileViewer, setShowFileViewer] = useState(false);
  const [file, setFile] = useState({});
  const [quotationInvModal, setQuotationInvModal] = useState(false);
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const dispatch = useDispatch();
  const { propertyId } = useParams();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  const name = item?.requester
    ? `${item?.requester?.first_name} ${item?.requester?.last_name}`
    : `${item?.tenant?.first_name} ${item?.tenant?.last_name}`;
  const role = item?.requester_role?.name;
  const createdAt = item?.createdAt;
  const action = item?.action;
  const currentStatus = item?.current_status;
  const logType = item?.log_type;
  const comment = item?.comment;
  const maintenanceType = maintenance?.type;
  const unitCard = maintenance?.unit_card;
  const quote = maintenance?.quotes;
  const applicationID = maintenance?.application_id;
  const reportData = item?.data;

  const USER_DATA = localStorage.getItem("user_data")
    ? JSON.parse(localStorage.getItem("user_data"))
    : null;
  const DATE_FORMAT = USER_DATA ? USER_DATA?.date_format : "";
  const TIME_FORMAT = USER_DATA ? USER_DATA?.time_format : "";
  const DATE_TIME_FORMAT = `${DATE_FORMAT} ${TIME_FORMAT}`;
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  // On responding to maintenance request (accepting/starting) by Owner
  const ownerRespondToMaintenance = async (action) => {
    // Creating or collecting payload data to be sent
    const payload = {
      maintenance_id,
      status: action,
      type: maintenance?.status,
    };

    console.log("Payload", payload);

    // Backend response. Try, Catch
    try {
      let result = await dispatch(ownerRespondToT1Maintenance(payload));
      console.log("Response", result);

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          dispatch(
            showErrorAlert({
              title: "Error!",
              description: result.payload,
            })
          );
          break;

        case GLOBAL.FULFILLED:
          dispatch(
            showSuccessAlert({
              title: "Success!",
              description: result.payload.message,
            })
          );
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };

  const onActionsBeforeStartingMaintenanceByOwner = async (action) => {
    // Creating or collecting payload data to be sent
    const payload = {
      maintenance_id,
      type: maintenance?.status,
    };
    console.log("Payload", payload);

    // Backend response. Try, Catch
    try {
      let result;
      switch (action) {
        case "on_the_way":
          result = await dispatch(ownerMarkOnTheWay(payload));
          break;

        case "checked_in":
          result = await dispatch(ownerMarkCheckedIn(payload));
          break;

        case "no_access":
          result = await dispatch(ownerMarkAsNoAccess(payload));
          break;
      }

      console.log("Response", result);

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          dispatch(
            showErrorAlert({
              title: "Error!",
              description:
                typeof result?.payload === "string" ? result?.payload : "",
            })
          );
          break;

        case GLOBAL.FULFILLED:
          dispatch(
            showSuccessAlert({
              title: "Success!",
              description:
                typeof result?.payload?.message === "string"
                  ? result?.payload?.message
                  : "",
            })
          );
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };

  const onOwnerAddingInspectionReport = async (v) => {
    // Creating or collecting payload data to be sent
    const payload = {
      maintenance_id,
      type: maintenance?.status,
      ...v,
    };
    console.log("Payload", payload);

    // Backend response. Try, Catch
    try {
      const result = await dispatch(
        ownerAddT1MaintenanceInspectionReport(payload)
      );
      // const result = {};
      console.log("Response", result);

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          dispatch(
            showErrorAlert({
              title: "Error!",
              description:
                typeof result?.payload === "string" ? result?.payload : "",
            })
          );
          break;

        case GLOBAL.FULFILLED:
          dispatch(
            showSuccessAlert({
              title: "Success!",
              description:
                typeof result?.payload?.message === "string"
                  ? result?.payload?.message
                  : "",
            })
          );
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };

  const onTechnicianAddingInspectionReport = async (v) => {
    // Creating or collecting payload data to be sent
    const payload = {
      maintenance_id,
      type: maintenance?.status,
      ...v,
    };
    console.log("Payload", payload);

    // Backend response. Try, Catch
    try {
      let result;

      if (isTPT) {
        result = await dispatch(tptAddInspection_t2_tenant(payload));
      } else {
        result = await dispatch(mtAddInspection_t2_tenant(payload));
      }

      // const result = {};

      console.log("Response", result);

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          dispatch(
            showErrorAlert({
              title: "Error!",
              description:
                typeof result?.payload === "string" ? result?.payload : "",
            })
          );
          break;

        case GLOBAL.FULFILLED:
          dispatch(
            showSuccessAlert({
              title: "Success!",
              description:
                typeof result?.payload?.message === "string"
                  ? result?.payload?.message
                  : "",
            })
          );
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };

  // T3 - Technician Add Report
  const onT3TechnicianAddingInspectionReport = async (v) => {
    // Creating or collecting payload data to be sent
    const payload = {
      maintenance_id,
      type: maintenance?.status,
      ...v,
    };
    console.log("Payload", payload);

    // Backend response. Try, Catch
    try {
      let result;

      if (isTPT) {
        result = await dispatch(tptAddInspection_t3_tenant(payload));
      } else {
        result = await dispatch(mtAddInspection_t3_tenant(payload));
      }

      // const result = {};

      console.log("Response", result);

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          dispatch(
            showErrorAlert({
              title: "Error!",
              description:
                typeof result?.payload === "string" ? result?.payload : "",
            })
          );
          break;

        case GLOBAL.FULFILLED:
          dispatch(
            showSuccessAlert({
              title: "Success!",
              description:
                typeof result?.payload?.message === "string"
                  ? result?.payload?.message
                  : "",
            })
          );
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };

  // On adding report
  const onReportAdd = (values) => {
    setAddReport(false);

    // On owner adding inspection report
    isT1 &&
      canOwnerAddInspectionReport(item, user) &&
      onOwnerAddingInspectionReport(values);

    isT2 &&
      canTechnicianUploadInspectionReport(item, user) &&
      onTechnicianAddingInspectionReport(values);

    // On owner adding report
    // isT1 &&
    //   canOwnerCompleteMaintenance(item, user) &&
    //   ownerAddingReport(values);
  };

  // On adding report
  const onInspectionReportAdd = (values) => {
    setShowAddInspectionReport(false);

    // On owner adding inspection report
    isT1 &&
      canOwnerAddInspectionReport(item, user) &&
      onOwnerAddingInspectionReport(values);

    isT2 &&
      canTechnicianUploadInspectionReport(item, user) &&
      onTechnicianAddingInspectionReport(values);

    isT3 &&
      canTechnicianUploadInspectionReport(item, user) &&
      onT3TechnicianAddingInspectionReport(values);

    // On owner adding report
    // isT1 &&
    //   canOwnerCompleteMaintenance(item, user) &&
    //   ownerAddingReport(values);
  };

  // On reassigning managers
  const onReassignManager = (values) => {
    setReassignManager(false);
    // On FM reassign other managers
    (canTPMReassignManagers(item, user) || canFmReassignManagers(item, user)) &&
      onManagerReassign(values);
  };

  // On Assigning Technician
  const onAssignTechnician = (t) => {
    setAssignMT(false);
    // On FM/TPM assigning technician
    (canTPMAssignTPT(item, user) || canFMAssignMT(item, user)) &&
      assignTechnician(t);
  };

  // On MT/TPT respond to maintenance request
  const techRespondToMaintenance = async (accept = true, comment = "") => {
    // Creating or collecting payload data to be sent
    const payload = {
      maintenance_id,
      status: accept ? "accepted" : "rejected",
      comment,
      type: maintenanceType,
    };
    console.log("Payload", payload);

    // Backend response. Try, Catch
    try {
      let result;
      if (isTPT) {
        if (isT2) {
          result = await dispatch(t2tpTechnicianTakeAction(payload));
        } else {
          result = await dispatch(tpTechnicianTakeAction(payload));
        }
      } else {
        if (isT2) {
          result = await dispatch(t2TechnicianTakeAction(payload));
        } else {
          result = await dispatch(technicianTakeAction(payload));
        }
      }
      console.log("Response", result);

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          dispatch(
            showErrorAlert({
              title: "Error!",
              description: result.payload,
            })
          );
          break;

        case GLOBAL.FULFILLED:
          dispatch(
            showSuccessAlert({
              title: "Success!",
              description: result.payload.message,
            })
          );
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };

  // On action with note
  const actionWithNote = (note) => {
    setOpenNote(false);
    // On MT/TPT respond to maintenance request
    (canTPTRespondMaintenance(item, user) ||
      canMTRespondMaintenance(item, user)) &&
      techRespondToMaintenance(false, note);

    canFMReviewQuotation(item, user) && fmReviewQuotation(false, note);
  };

  // On FM/TPM assigning technician
  const assignTechnician = async (t) => {
    // Creating or collecting payload data to be sent
    const payload = {
      maintenance_id,
      type: maintenanceType,
      technician: t._id,
    };
    console.log("Payload", payload);

    // Backend response. Try, Catch
    try {
      let result;
      if (isTPM) {
        if (isT2) {
          result = await dispatch(tpmAssignT2Technician(payload));
        } else {
          result = await dispatch(tpmAssignTechnician(payload));
        }
      } else {
        if (isT2) {
          result = await dispatch(fmAssignT2Technician(payload));
        } else {
          result = await dispatch(fmAssignTechnician(payload));
        }
      }
      console.log("Response", result);

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          dispatch(
            showErrorAlert({
              title: "Error!",
              description: result.payload,
            })
          );
          break;

        case GLOBAL.FULFILLED:
          dispatch(
            showSuccessAlert({
              title: "Success!",
              description: result.payload.message,
            })
          );
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };

  // On reassign other managers
  const onManagerReassign = async (values) => {
    // Creating or collecting payload data to be sent
    const payload = {
      assigning_to: values?._id,
      assigning_role: values?.role_id,
      comment: values?.comment,
      type: maintenanceType,
      maintenance_id,
    };
    console.log("Payload", payload);

    // Backend response. Try, Catch
    try {
      const result = await dispatch(reassignMaintenanceTicket(payload));
      console.log("Response", result);

      // Handling success response
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };

  // On owner adding report
  const ownerAddingReport = async (values) => {
    // Creating or collecting payload data to be sent
    const payload = {
      maintenance_id,
      type: maintenance?.status,
      status: "Completed",
      description: values.description,
      document: values.document,
    };
    console.log("Payload", payload);

    // Backend response. Try, Catch
    try {
      let result = await dispatch(ownerRespondToT1Maintenance(payload));
      // let result = {};
      setAddReport(false);
      console.log("Response", result);

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          dispatch(
            showErrorAlert({
              title: "Error!",
              description: result.payload,
            })
          );
          break;

        case GLOBAL.FULFILLED:
          dispatch(
            showSuccessAlert({
              title: "Success!",
              description: result.payload.message,
            })
          );
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };

  const onAddFinalMaintenanceReport = (v) => {
    isT1 && canOwnerCompleteMaintenance(item, user) && ownerAddingReport(v);

    !isT1 &&
      (canMTMarkJobComplete(item, user) || canTPTMarkJobComplete(item, user)) &&
      techMarkJobComplete(v);
  };

  // On FM/TPM accept request
  const acceptReq = async () => {
    // Creating or collecting payload data to be sent
    const payload = {
      maintenance_id,
      type: maintenanceType,
      status: "accepted",
    };
    console.log("Payload", payload);

    // Backend response. Try, Catch
    try {
      let result;
      if (isTPM) {
        if (isT2) {
          result = await dispatch(tpmRespondToT2Maintenance(payload));
        } else {
          result = await dispatch(tpmRespondToT3Maintenance(payload));
        }
      } else {
        if (isT2) {
          result = await dispatch(fmRespondToT2Maintenance(payload));
        } else {
          result = await dispatch(fmRespondToT3Maintenance(payload));
        }
      }
      console.log("Response", result);

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          dispatch(
            showErrorAlert({
              title: "Error!",
              description: result.payload,
            })
          );
          break;

        case GLOBAL.FULFILLED:
          dispatch(
            showSuccessAlert({
              title: "Success!",
              description: result.payload.message,
            })
          );
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };

  // On MT/TPT marking on the way
  const technicianOnTheWay = async () => {
    // Creating or collecting payload data to be sent
    const payload = {
      maintenance_id,
      type: maintenanceType,
    };
    console.log("Payload", payload);

    // Backend response. Try, Catch
    try {
      let result;
      if (isTPT) {
        if (isT2) {
          result = await dispatch(t2tptMarkTechnicianOnTheWay(payload));
        } else {
          result = await dispatch(tptMarkTechnicianOnTheWay(payload));
        }
      } else {
        if (isT2) {
          result = await dispatch(t2MarkTechnicianOnTheWay(payload));
        } else {
          result = await dispatch(markTechnicianOnTheWay(payload));
        }
      }
      console.log("Response", result);

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          dispatch(
            showErrorAlert({
              title: "Error!",
              description: result.payload,
            })
          );
          break;

        case GLOBAL.FULFILLED:
          dispatch(
            showSuccessAlert({
              title: "Success!",
              description: result.payload.message,
            })
          );
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };

  // On MT/TPT check In
  const technicianCheckIn = async () => {
    // Creating or collecting payload data to be sent
    const payload = {
      maintenance_id,
      type: maintenanceType,
    };
    console.log("Payload", payload);

    // Backend response. Try, Catch
    try {
      let result;
      if (isTPT) {
        if (isT2) {
          result = await dispatch(t2tpTechnicianCheckedIn(payload));
        } else {
          result = await dispatch(tpTechnicianCheckedIn(payload));
        }
      } else {
        if (isT2) {
          result = await dispatch(t2TechnicianCheckedIn(payload));
        } else {
          result = await dispatch(technicianCheckedIn(payload));
        }
      }
      console.log("Response", result);

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          dispatch(
            showErrorAlert({
              title: "Error!",
              description: result.payload,
            })
          );
          break;

        case GLOBAL.FULFILLED:
          dispatch(
            showSuccessAlert({
              title: "Success!",
              description: result.payload.message,
            })
          );
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };

  // On MT/TPT has no access
  const technicianNoAccess = async () => {
    // Creating or collecting payload data to be sent
    const payload = {
      maintenance_id,
      type: maintenanceType,
    };
    console.log("Payload", payload);

    // Backend response. Try, Catch
    try {
      let result;
      if (isTPT) {
        if (isT2) {
          result = await dispatch(t2tpTechnicianMarkAsNoAccess(payload));
        } else {
          result = await dispatch(tpTechnicianMarkAsNoAccess(payload));
        }
      } else {
        if (isT2) {
          result = await dispatch(t2TechnicianMarkAsNoAccess(payload));
        } else {
          result = await dispatch(technicianMarkAsNoAccess(payload));
        }
      }
      console.log("Response", result);

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          dispatch(
            showErrorAlert({
              title: "Error!",
              description: result.payload,
            })
          );
          break;

        case GLOBAL.FULFILLED:
          dispatch(
            showSuccessAlert({
              title: "Success!",
              description: result.payload.message,
            })
          );
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };

  // On MT start work immediately
  const techStartWork = async () => {
    // Creating or collecting payload data to be sent
    const payload = {
      maintenance_id,
      type: maintenanceType,
    };
    console.log("Payload", payload);

    // Backend response. Try, Catch
    try {
      let result;
      if (isTPT) {
        if (isT2) {
          result = await dispatch(t2tpTechnicianStartWork(payload));
        } else {
          result = await dispatch(tpTechnicianStartWork(payload));
        }
      } else {
        if (isT2) {
          result = await dispatch(t2TechnicianStartWork(payload));
        } else {
          result = await dispatch(technicianStartWork(payload));
        }
      }
      console.log("Response", result);

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          dispatch(
            showErrorAlert({
              title: "Error!",
              description: result.payload,
            })
          );
          break;

        case GLOBAL.FULFILLED:
          dispatch(
            showSuccessAlert({
              title: "Success!",
              description: result.payload.message,
            })
          );
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };

  // On MT request for spare parts
  const mtRequestSpareparts = async (parts) => {
    setRequestSpareParts(false);
    // Creating or collecting payload data to be sent
    const payload = {
      maintenance_id,
      type: maintenanceType,
      part: JSON.stringify(parts),
    };
    console.log("Payload", payload);

    // Backend response. Try, Catch
    try {
      let result = await dispatch(technicianAddSpareParts(payload));
      console.log("Response", result);

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          dispatch(
            showErrorAlert({
              title: "Error!",
              description: result.payload,
            })
          );
          break;

        case GLOBAL.FULFILLED:
          dispatch(
            showSuccessAlert({
              title: "Success!",
              description: result.payload.message,
            })
          );
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };

  // On MT start work immediately or rquest for spare parts
  const mtOnLocationSubmit = () => {
    if (mtOnLocationAction === 0) {
      techStartWork();
    } else {
      setRequestSpareParts(true);
    }
  };

  // On MT/TPT handover items
  const techHandoverItems = async (d) => {
    setShowHandover(false);
    // Creating or collecting payload data to be sent
    const payload = {
      maintenance_id,
      type: maintenanceType,
      items: JSON.stringify(d),
    };
    console.log("Payload", payload);

    // Backend response. Try, Catch
    try {
      let result;
      if (isTPT) {
        if (isT2) {
          result = await dispatch(t2tpTechnicianHandoverItems(payload));
        } else {
          result = await dispatch(tpTechnicianHandoverItems(payload));
        }
      } else {
        if (isT2) {
          result = await dispatch(t2TechnicianHandoverItems(payload));
        } else {
          result = await dispatch(technicianHandoverItems(payload));
        }
      }
      console.log("Response", result);

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          dispatch(
            showErrorAlert({
              title: "Error!",
              description: result.payload,
            })
          );
          break;

        case GLOBAL.FULFILLED:
          dispatch(
            showSuccessAlert({
              title: "Success!",
              description: result.payload.message,
            })
          );
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };

  // On MT/TPT mark job complete
  const techMarkJobComplete = async (values) => {
    // Creating or collecting payload data to be sent
    const payload = {
      type: maintenanceType,
      maintenance_id,
      status: "Completed",
      description: values.description,
      document: values.document,
    };
    console.log("Payload", payload);

    // Backend response. Try, Catch
    try {
      let result = {};
      if (isTPT) {
        if (isT2) {
          result = await dispatch(t2tpTechnicianMarkJobComplete(payload));
        } else {
          result = await dispatch(tpTechnicianMarkJobComplete(payload));
        }
      } else {
        if (isT2) {
          result = await dispatch(t2TechnicianMarkJobComplete(payload));
        } else {
          result = await dispatch(technicianMarkJobComplete(payload));
        }
      }
      console.log("Response", result);

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          dispatch(
            showErrorAlert({
              title: "Error!",
              description: result.payload,
            })
          );
          break;

        case GLOBAL.FULFILLED:
          dispatch(
            showSuccessAlert({
              title: "Success!",
              description: result.payload.message,
            })
          );
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };

  // On FM place order
  const fmPlacingOrder = async () => {
    // Creating or collecting payload data to be sent
    const payload = {
      maintenance_id,
      type: maintenanceType,
      quotation_id: maintenance?.quotes?._id,
      status: "Order Placed",
    };
    console.log("Payload", payload);

    // Backend response. Try, Catch
    try {
      const result = await dispatch(changeOrderStatus(payload));
      console.log("Response", result);

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          dispatch(
            showErrorAlert({
              title: "Error!",
              description: result.payload,
            })
          );
          break;

        case GLOBAL.FULFILLED:
          dispatch(
            showSuccessAlert({
              title: "Success!",
              description: result.payload.message,
            })
          );
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };

  // On FM recieving spare parts
  const fmRecievingSpareParts = async () => {
    // Creating or collecting payload data to be sent
    const payload = {
      maintenance_id,
      type: maintenanceType,
      quotation_id: maintenance?.quotes?._id,
      status: "Parts Recieved",
    };
    console.log("Payload", payload);

    // Backend response. Try, Catch
    try {
      const result = await dispatch(changeOrderStatus(payload));
      console.log("Response", result);

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          dispatch(
            showErrorAlert({
              title: "Error!",
              description: result.payload,
            })
          );
          break;

        case GLOBAL.FULFILLED:
          dispatch(
            showSuccessAlert({
              title: "Success!",
              description: result.payload.message,
            })
          );
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };

  // REPORT DATA FOR INTERNAL MAINTENANVCE TICKET
  const renderReport = () => {
    return (
      <>
        {reportData?.items ? (
          <>
            {reportData?.items?.map((item) => (
              <>
                <div className="so-report-wrapper">
                  {item?.description && (
                    <div>
                      <div className="font_m fw_5 font_grey">
                        Description :
                        <p className="font_xs fw_4 font_white report_in_escape">
                          {item?.description ? item?.description : "-"}
                        </p>
                      </div>
                    </div>
                  )}
                  {item?.documents?.length > 0 && (
                    <>
                      <p className="font_yellow fw_4 font_m">Attachments</p>
                      <div className="report-doc-wrapper">
                        {item?.documents?.map((image, index) => (
                          <div className="proof-img" key={index}>
                            <Image
                              key={index}
                              className="media"
                              imgClass="thumbnail"
                              src={image?.url}
                              videoThumbnail={image?.type2 === "video"}
                              alt="Attatched Media"
                              objectFit="cover"
                              onClick={() => {
                                setFile(image);
                                setShowFileViewer(true);
                              }}
                            />
                          </div>
                        ))}
                      </div>
                    </>
                  )}
                  {item?.documents === undefined && (
                    <p className="font_yellow">No Attachments Added!</p>
                  )}
                </div>
              </>
            ))}
          </>
        ) : (
          <div className="so-report-wrapper">
            {(reportData?.comment || reportData?.notes) && (
              <div>
                <div className="font_m fw_5 font_grey">
                  Description :
                  <p className="font_xs fw_4 font_white report_in_escape">
                    {reportData?.comment || reportData?.notes
                      ? reportData?.comment || reportData?.notes || comment
                      : "-"}
                  </p>
                </div>
              </div>
            )}
            {reportData?.document?.length > 0 && (
              <>
                <p className="font_yellow fw_4 font_m">Attachments</p>
                <div className="report-doc-wrapper">
                  {reportData?.document?.map((image, index) => (
                    <div className="proof-img" key={index}>
                      <Image
                        key={index}
                        className="media"
                        imgClass="thumbnail"
                        src={image?.url}
                        videoThumbnail={image?.type2 === "video"}
                        alt="Attatched Media"
                        objectFit="cover"
                        onClick={() => {
                          setFile(image);
                          setShowFileViewer(true);
                        }}
                      />
                    </div>
                  ))}
                </div>
              </>
            )}
          </div>
        )}
      </>
    );
  };

  // On owner updating spare part order status
  const ownerUpdatingOrderStatus = async (status) => {
    // Creating or collecting payload data to be sent
    const payload = {
      maintenance_id,
      type: maintenanceType,
      quotation_id: maintenance?.quotes?._id,
      status,
    };
    console.log("Payload", payload);

    // Backend response. Try, Catch
    try {
      const result = await dispatch(changeOrderStatus(payload));
      console.log("Response", result);

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          dispatch(
            showErrorAlert({
              title: "Error!",
              description:
                typeof result?.payload === "string" ? result?.payload : "",
            })
          );
          break;

        case GLOBAL.FULFILLED:
          dispatch(
            showSuccessAlert({
              title: "Success!",
              description:
                typeof result?.payload?.message === "string"
                  ? result?.payload?.message
                  : "",
            })
          );
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };

  // To View The Tenant Invoice
  const handleView = (id) => {
    const newPathname = pathNamePopped(pathname, 3);

    navigate(`${newPathname}/invoicing/Tenant/others/${id}`, {
      state: { pathname: pathname },
    });
  };

  // On FM review quoatation
  const fmReviewQuotation = async (accept = true, note = "", v) => {
    // Creating or collecting payload data to be sent
    let payload = {
      maintenance_id,
      status: accept ? "Approved" : "Rejected",
      note,
      type: maintenanceType,
      quotation_id: item?.data?._id,
      isThirdParty: maintenance?.assigned_role?.name === "Third Party Manager",
      quotation_type: "quoatation",
    };

    if (accept) {
      payload = { ...payload, ...v };
    }

    // Backend response. Try, Catch
    try {
      let result;
      result = await dispatch(fmRespondingToTPMQuote(payload));

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          dispatch(
            showErrorAlert({
              title: "Error!",
              description: result.payload,
            })
          );
          break;

        case GLOBAL.FULFILLED:
          dispatch(
            showSuccessAlert({
              title: "Success!",
              description: result.payload.message,
            })
          );
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };
  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="time-line simple-log">
      {/* Dot */}
      <div className="dot-wrapper">
        <i className="dot"></i>
        <div className="vl"></div>
      </div>

      <div className="detail-wrapper">
        {/* Title */}
        <h3 className="title font_m fw_6">{logTitle(item)}</h3>
        {/* Technician Name and Date */}
        <div className="black-container-sm fw_5 font_s bg_black flex-between">
          {/* Name */}
          <p className="left">
            <span className="font_grey">{role} </span>
            <span className="font_white"> {name}</span>
          </p>

          {/* Date and Time */}
          <p className="right font_grey">
            {moment(createdAt).format(DATE_TIME_FORMAT)}
          </p>
        </div>

        {action === "Tenant has rescheduled the maintenance date" && (
          <div className="black-container-sm fw_5 font_s bg_black flex-between rescheduled_date_time">
            {/* Name */}
            <p className="left">
              <span className="font_white">Rescheduled Date and Time</span>
            </p>

            {/* Date and Time */}
            <p className="right font_grey">
              {moment(item?.data?.prefered_maintenance_date).format(
                DATE_TIME_FORMAT
              )}
            </p>
          </div>
        )}

        {action === "Waiting for tenant payment" && item?.tenant_invoice_id && (
          <div className="black-container-sm fw_5 font_s bg_black flex-between rescheduled_date_time">
            {/* Name */}
            <p className="left">
              <span className="font_white">Tenant Invoice</span>
            </p>

            {/* Date and Time */}
            <p className="right font_grey">
              {/* //{item?.tenant_invoice_id} */}
              <IconButton onClick={() => handleView(item?.tenant_invoice_id)}>
                <img src={EyeFilledBlue} alt="Image" className="viewImg" />
              </IconButton>
            </p>
          </div>
        )}

        {/* Log Footer */}
        <div className="log-footer">
          {/* Comments */}
          {action !== "Owner accepted the maintenance request" &&
          action !== "Waiting for tenant approval" &&
          action !== "Technician added inspection report" ? (
            <>
              {comment && (
                <p className="comments font_s fw-5">
                  <span className="font_grey fw_6">Comments: </span>
                  <span className="fw_5 font_red"> {comment}</span>
                </p>
              )}
            </>
          ) : null}

          {(action === "Owner accepted the maintenance request" ||
            action === "Waiting for tenant approval" ||
            action === "Technician added inspection report" ||
            action === "Maintenance is rejected by tenant") && (
            <>{reportData && renderReport()}</>
          )}

          {/* Action Buttons */}
          {takeAction && (
            <div className="button-wrapper">
              {/* Owner accepting maintenance request */}
              {isT1 && canOwnerAcceptReq(item, user) && (
                <CustomButton
                  onClick={() => ownerRespondToMaintenance("accepted")}
                  size="l"
                  variant="yellow"
                >
                  Accept Request
                </CustomButton>
              )}

              {isT1 && canOwnerMarkOnTheWay(item, user) && (
                <CustomButton
                  onClick={() =>
                    onActionsBeforeStartingMaintenanceByOwner("on_the_way")
                  }
                  size="l"
                  variant="yellow"
                >
                  On The Way
                </CustomButton>
              )}

              {isT1 && canOwnerMarkCheckIn(item, user) && (
                <CustomButton
                  onClick={() =>
                    onActionsBeforeStartingMaintenanceByOwner("checked_in")
                  }
                  size="l"
                  variant="yellow"
                >
                  Check In
                </CustomButton>
              )}

              {isT1 && canOwnerMarkNoAccess(item, user) && (
                <CustomButton
                  onClick={() =>
                    onActionsBeforeStartingMaintenanceByOwner("no_access")
                  }
                  size="l"
                  variant="red"
                >
                  No Access
                </CustomButton>
              )}

              {isT1 && canOwnerAddInspectionReport(item, user) && (
                <CustomButton
                  onClick={() => setShowAddInspectionReport(true)}
                  size="l"
                  variant="yellow"
                >
                  Add Inspection Report
                </CustomButton>
              )}

              {/* Owner creating quotaion */}
              {isT1 && canOwnerCreateQuotation(item, user) && (
                <CustomButton
                  onClick={() => setAddQuotation(true)}
                  variant="yellow"
                  size="m"
                >
                  I need Parts or Service
                </CustomButton>
              )}

              {/* Owner starting maintenance */}
              {isT1 && canOwnerStartMaintenance(item, user) && (
                <CustomButton
                  onClick={() => ownerRespondToMaintenance("started")}
                  size="m"
                  variant="yellow"
                >
                  Start Maintenance
                </CustomButton>
              )}

              {isT1 && canOwnerPlaceOrder(item, user) && (
                <CustomButton
                  onClick={() => ownerUpdatingOrderStatus("Order Placed")}
                  variant="yellow"
                  size="m"
                >
                  Place The Order
                </CustomButton>
              )}

              {/* Owner adding maintenance report */}
              {isT1 && canOwnerCompleteMaintenance(item, user) && (
                <CustomButton
                  onClick={() => setAddReport(true)}
                  variant="yellow"
                  size="l"
                >
                  Complete Maintenance
                </CustomButton>
              )}

              {/* Owner adding maintenance report */}
              {isT1 && canOwnerRecieveParts(item, user) && (
                <CustomButton
                  onClick={() => ownerUpdatingOrderStatus("Parts Recieved")}
                  variant="yellow"
                  size="l"
                >
                  Recieved Order
                </CustomButton>
              )}

              {/* FM reassigning ticket to other managers */}
              {!isT1 &&
                (canFmReassignManagers(item, user) ||
                  canTPMReassignManagers(item, user)) && (
                  <CustomButton
                    onClick={() => setReassignManager(true)}
                    variant={
                      managers && managers?.length > 0 ? "yellow" : "darkgrey"
                    }
                    size="m"
                  >
                    Reassign Ticket
                  </CustomButton>
                )}

              {/* FM accepting request */}
              {!isT1 &&
                (canTPMAcceptReq(item, user) || canFMAcceptReq(item, user)) && (
                  <CustomButton
                    onClick={() => acceptReq()}
                    variant="yellow"
                    size="m"
                  >
                    Accept Request
                  </CustomButton>
                )}

              {/* FM accepting request */}
              {!isT1 &&
                (canFMAssignMT(item, user) || canTPMAssignTPT(item, user)) && (
                  <CustomButton
                    onClick={() => setAssignMT(true)}
                    variant="yellow"
                    size="m"
                  >
                    Assign Technician
                  </CustomButton>
                )}

              {/* MT responding to maintenance request */}
              {!isT1 &&
                (canTPTRespondMaintenance(item, user) ||
                  canMTRespondMaintenance(item, user)) && (
                  <>
                    <CustomButton
                      onClick={() => techRespondToMaintenance()}
                      size="m"
                      variant="yellow"
                    >
                      Accept
                    </CustomButton>
                    <CustomButton
                      variant="red"
                      onClick={() => setOpenNote(true)}
                      size="m"
                    >
                      Reject
                    </CustomButton>
                  </>
                )}

              {/* MT marking on the way */}
              {!isT1 &&
                (canTPTMarkOnTheWay(item, user) ||
                  canMTMarkOnTheWay(item, user)) && (
                  <CustomButton
                    onClick={() => technicianOnTheWay()}
                    variant="yellow"
                    size="m"
                  >
                    On The Way
                  </CustomButton>
                )}

              {/* Technician upload inspection report */}
              {!isT1 &&
                quote === null &&
                canTechnicianUploadInspectionReport(item, user) && (
                  <CustomButton
                    onClick={() => setShowAddInspectionReport(true)}
                    variant="yellow"
                    size="m"
                  >
                    Upload Inspection Report
                  </CustomButton>
                )}

              {/* MT Checking In */}
              {!isT1 &&
                (canTPTCheckIn(item, user) || canMTCheckIn(item, user)) && (
                  <CustomButton
                    onClick={() => technicianCheckIn()}
                    variant="yellow"
                    size="m"
                  >
                    Check In
                  </CustomButton>
                )}

              {/* MT has No Access */}
              {!isT1 &&
                (canTPTNoAccess(item, user) || canMTNoAccess(item, user)) && (
                  <CustomButton
                    variant="red"
                    onClick={() => technicianNoAccess()}
                    size="m"
                  >
                    No Access
                  </CustomButton>
                )}

              {/* MT starting work or request for spare parts */}
              {!isT1 &&
                (canTPTStrtWrkReq4Parts(item, user) ||
                  canMTStrtWrkReq4Parts(item, user)) && (
                  <>
                    <div className="radio-container spare-parts-start-work-radio w_50">
                      <CustomRadioInput
                        isSelected={mtOnLocationAction === 0}
                        onSelecting={() => setMtOnLocationAction(0)}
                        label="Start work immediately"
                      />
                      <CustomRadioInput
                        isSelected={mtOnLocationAction === 1}
                        onSelecting={() => setMtOnLocationAction(1)}
                        label={
                          currentStatus === "parts_request_rejected"
                            ? "Reupload Spare Part Request"
                            : "I need Parts or Service"
                        }
                      />
                    </div>
                    <CustomButton
                      onClick={() => mtOnLocationSubmit()}
                      variant="yellow"
                      size="m"
                    >
                      Submit
                    </CustomButton>
                  </>
                )}

              {/* FM Creating Quotes */}
              {!isT1 && canManagerCreateQuote(item, user) && (
                <CustomButton
                  onClick={() => setAddQuotation(true)}
                  variant="yellow"
                  size="m"
                >
                  Create Quotation
                </CustomButton>
              )}

              {/*  FM can update quote */}
              {!isT1 && fmCanEditQuote(item, user) && (
                <CustomButton
                  onClick={() => setAddQuotation(true)}
                  variant="yellow"
                  size="m"
                >
                  Generate Quote
                </CustomButton>
              )}

              {/* FM/TPM Placing Order */}
              {!isT1 &&
                (canTPMPlaceOrder(item, user) ||
                  canFMPlaceOrder(item, user)) && (
                  <CustomButton
                    onClick={() => fmPlacingOrder()}
                    variant="yellow"
                    size="m"
                  >
                    Place The Order
                  </CustomButton>
                )}

              {/* FM Creating Quotes */}
              {!isT1 && canFMRecieveSpareParts(item, user) && (
                <CustomButton
                  onClick={() => fmRecievingSpareParts()}
                  variant="yellow"
                  size="m"
                >
                  Recieved Order
                </CustomButton>
              )}

              {/* MT handover items */}
              {/* {!isT1 &&
                (canTPTHandover(item, user) || canMTHandover(item, user)) && (
                  <CustomButton
                    onClick={() => setShowHandover(true)}
                    variant="yellow"
                    size="m"
                  >
                    Handover Old Items
                  </CustomButton>
                )} */}

              {!isT1 && canFMReviewQuotation(item, user) && (
                <>
                  <CustomButton
                    onClick={() => setQuotationInvModal(true)}
                    variant="yellow"
                    size="m"
                  >
                    Accept
                  </CustomButton>
                  <CustomButton
                    variant="red"
                    onClick={() => setOpenNote(true)}
                    size="m"
                  >
                    Reject
                  </CustomButton>
                </>
              )}

              {/* Technician start work */}
              {!isT1 &&
                quote &&
                quote?.status === "Approved" &&
                canTechnicianStartWork(item, user) && (
                  <CustomButton
                    onClick={() => techStartWork()}
                    variant="yellow"
                    size="m"
                  >
                    Start Work
                  </CustomButton>
                )}

              {/* MT mark job complete */}
              {!isT1 &&
                (canMTMarkJobComplete(item, user) ||
                  canTPTMarkJobComplete(item, user)) && (
                  <CustomButton
                    onClick={() => setAddReport(true)}
                    variant="yellow"
                    size="m"
                  >
                    Mark Job Complete
                  </CustomButton>
                )}
            </div>
          )}
        </div>
      </div>

      {takeAction && (
        <>
          <CreateQuotationInvoiceModal
            showModal={quotationInvModal}
            onClose={() => setQuotationInvModal(false)}
            onSubmit={(v) => fmReviewQuotation(true, "", v)}
          />

          {/* Modals */}
          <AddMaintenanceInspectionReport
            showModal={showAddInspectionReport}
            onClose={() => setShowAddInspectionReport(false)}
            onAddReport={onInspectionReportAdd}
          />

          <AddMaintenanceInspectionReport
            showModal={addReport}
            onClose={() => setAddReport(false)}
            onAddReport={onAddFinalMaintenanceReport}
            title="Maintenance Report"
          />

          {/* Spare Part Quotation Modal */}
          <SparePartQuoteModal
            showModal={addQuotation}
            onClose={() => setAddQuotation(false)}
            type="normal"
            maintenance_id={maintenance_id}
            maintenanceType={maintenanceType}
            unitCard={unitCard}
            user={user}
            data={quote}
            currentLog={item}
            isT1={isT1}
            isT2={isT2}
            isT3={isT3}
            isTPM={isTPM}
            maintenance={maintenance}
          />

          {/* Maintenance Task Complete Modal */}
          {/* <MaintenacneTaskCompleteModal
            // showModal={true}
            showModal={addReport}
            callBack={onReportAdd}
            onClose={() => setAddReport(false)}
          /> */}

          {/* Reassigning other managers modal */}
          <ReassignManagerModal
            actionWithNote={onReassignManager}
            showModal={reassignManager}
            managers={managers}
            onCancel={() => setReassignManager(false)}
          />

          {/* Assigning Technician Modal */}
          <AssignTechnicianModal
            action={onAssignTechnician}
            showModal={assignMT}
            technicians={technicians}
            onCancel={() => setAssignMT(false)}
          />

          {/* Note Modal */}
          <NoteModal
            showModal={openNote}
            actionWithNote={actionWithNote}
            title="Reject"
            inputPlaceholder="Comment"
            btnText="Reject"
            showError={true}
            onCancel={() => setOpenNote(false)}
          />

          {/* Spare parts modal */}
          <SparePartRequestModal
            showModal={requestSpareParts}
            callback={mtRequestSpareparts}
            onClose={() => setRequestSpareParts(false)}
          />

          {/* Handove Item Modal */}
          <HandOverItemModal
            showModal={showHandover}
            onClose={() => setShowHandover(false)}
            callBack={techHandoverItems}
          />

          {/* File viewer modal */}
          <FileViewerModal
            show={showFileViewer}
            type={file?.type}
            file={file?.url}
            onClose={() => {
              setShowFileViewer(false);
              setFile({});
            }}
          />
        </>
      )}
    </div>
  );
};
/* Component ends */

export default SimpleLog;
