/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
/* Import configuration ends */

/* Import redux slices component starts */
import {
  fetchTeamRatings,
  fetchTenantTicketRatings,
  getRatings,
} from "../../setup/store/slices/ratingSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import TabButton from "../../components/ui/other/TabButton/TabButton";
import CustomStarRate from "../../components/ui/other/CustomStarRate/CustomStarRate";
import TenantsRatingTable from "../../components/module/RatingAndReview/TenantsRatingTable/TenantsRatingTable";
import TeamsRatingTable from "../../components/module/RatingAndReview/TeamsRatingTable/TeamsRatingTable";
import BreadCrumb from "../../components/ui/other/BreadCrumb/BreadCrumb";
/* Import local pages and component ends */

// styles sheet
import "./style.scss";

const RatingAndReviews = (props) => {
  /* Props destructuring starts */
  const {} = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [ratingCount, setRatingCount] = useState();

  const [currentPage, setCurrentPage] = useState(1);
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const dispatch = useDispatch();
  const ratings = useSelector(getRatings);
  const { propertyId, tab } = useParams();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  const buttonList = [
    { key: "tenants", label: "Tenants" },
    { key: "teams", label: "Teams" },
    // { key: "team member", label: "Team Member" },
  ];

  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  const onTabSelect = (key) => {
    // setCurrentKey(key);
    const pathArr = pathname?.split("/");
    pathArr.pop();
    navigate(pathArr.join("/") + `/${key}`);
    setCurrentPage(1);
  };

  /* Component function definition ends */

  /* Component useEffect starts */
  useEffect(() => {
    if (tab === "tenants") {
      dispatch(
        fetchTenantTicketRatings({ property_id: propertyId, page: currentPage })
      );
    } else if (tab === "teams") {
      dispatch(
        fetchTeamRatings({ property_id: propertyId, page: currentPage })
      );
    }
  }, [tab, currentPage]);

  useEffect(() => {
    const averageRatingCount = (ratings, tab) => {
      let rating = ratings?.maintenanceData
        ? ratings?.maintenanceData
        : ratings;

      if (tab === "tenants") {
        let _5starService = rating?.filter(
          (r) => r?.tech_rating === 5 && r?.tech_rating !== undefined
        ).length;
        let _4starService = rating?.filter(
          (r) => r?.tech_rating === 4 && r?.tech_rating !== undefined
        ).length;
        let _3starService = rating?.filter(
          (r) => r.tech_rating === 3 && r?.tech_rating !== undefined
        ).length;
        let _2starService = rating?.filter(
          (r) => r?.tech_rating === 2 && r?.tech_rating !== undefined
        ).length;
        let _1starService = rating?.filter(
          (r) => r?.tech_rating === 1 && r?.tech_rating !== undefined
        ).length;

        //Sum of individual star.
        let sumOfRating = parseInt(
          _5starService +
            _4starService +
            _3starService +
            _2starService +
            _1starService
        );

        //Total number of rating
        let overallRating = parseInt(
          5 * _5starService +
            4 * _4starService +
            3 * _3starService +
            2 * _2starService +
            1 * _1starService
        );

        //Average of all rating
        let averageServiceRating = parseFloat(overallRating / sumOfRating);
        setRatingCount(
          isNaN(averageServiceRating) !== true
            ? Math.round(averageServiceRating)
            : 0
        );
      } else {
        let _5starService = rating?.filter(
          (r) => r?.rating === 5 && r?.rating !== undefined
        ).length;
        let _4starService = rating?.filter(
          (r) => r?.rating === 4 && r?.rating !== undefined
        ).length;
        let _3starService = rating?.filter(
          (r) => r.rating === 3 && r?.rating !== undefined
        ).length;
        let _2starService = rating?.filter(
          (r) => r?.rating === 2 && r?.rating !== undefined
        ).length;
        let _1starService = rating?.filter(
          (r) => r?.rating === 1 && r?.rating !== undefined
        ).length;

        //Sum of individual star.
        let sumOfRating = parseInt(
          _5starService +
            _4starService +
            _3starService +
            _2starService +
            _1starService
        );

        //Total number of rating
        let overallRating = parseInt(
          5 * _5starService +
            4 * _4starService +
            3 * _3starService +
            2 * _2starService +
            1 * _1starService
        );

        //Average of all rating
        let averageServiceRating = parseFloat(overallRating / sumOfRating);
        setRatingCount(
          isNaN(averageServiceRating) !== true
            ? Math.round(averageServiceRating)
            : 0
        );
      }
    };

    averageRatingCount(ratings, tab);
  }, [ratings, tab]);

  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  /* Component starts */
  return (
    <>
      <BreadCrumb
        type="ratingAndReview"
        from={pathname?.includes("ratingAndReview") ? "ratingAndReview" : ""}
      />
      <div className="ratings-wrapper">
        <div className="ratings-heading">
          <h2 className="font_xxl fw_5">Ratings & Reviews</h2>
        </div>

        <div className="rated-wrapper">
          <div>
            <CustomStarRate size={35} stars={ratingCount} />
          </div>
          <div className="rate-value">
            <p className="font_xxl fw_5 font_grey">{ratingCount}/5</p>
          </div>
        </div>

        {/* TAB BUTTONS */}
        <TabButton
          buttonList={buttonList}
          currentKey={tab}
          onSelect={onTabSelect}
        />

        {/* Display Table Data Based On The Current Tab */}

        {tab === "tenants" ? (
          <TenantsRatingTable
            ratings={ratings}
            propertyId={propertyId}
            totalCount={ratings?.length}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        ) : tab === "teams" ? (
          // <TenantsRatingTable ratings={ratings} propertyId={propertyId} />
          <TeamsRatingTable
            ratings={ratings?.maintenanceData}
            propertyId={propertyId}
            totalCount={ratings?.totalCount}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        ) : (
          "Team Member"
        )}
      </div>
    </>
  );
};
/* Component End */

export default RatingAndReviews;
