/**
 *
 */

import React, { useState, useEffect, useRef } from "react";

/* Import configuration starts */
import { useDispatch, useSelector } from "react-redux";
import { Formik } from "formik";
import { initialValues, validations } from "./helper";
import GLOBAL from "../../../../setup/constants/global";
import { useSearchParams, useParams } from "react-router-dom";
/* Import configuration ends */

/* Import redux slices component starts */
import { setLoading } from "../../../../setup/store/slices/unpersistedSlice";
import {
  fetchPropertyDetailByID,
  getPropertyDetail,
} from "../../../../setup/store/slices/propertySlice";
import { inviteMember } from "../../../../setup/store/slices/teamSlice";
import {
  fetchUserRoles,
  getUserRoles,
} from "../../../../setup/store/slices/masterSlice";
import { getUser } from "../../../../setup/store/slices/authSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import ErrorModal from "../../../ui/modal/ErrorModal/ErrorModal";
import CustomDateInput from "../../../ui/input/CustomDateInput/CustomDateInput";
import CustomTextInput from "../../../ui/input/CustomTextInput/CustomTextInput";
import CustomPhoneInput from "../../../ui/input/CustomPhoneInput/CustomPhoneInput";
import CustomFileInput from "../../../ui/input/CustomFileInput/CustomFileInput";
import CustomButton from "../../../ui/button/CustomButton/CustomButton";
import TextButton from "../../../ui/button/TextButton/TextButton";
import CustomCurrencyInput from "../../../ui/input/CustomCurrencyInput/CustomCurrencyInput";
/* Import local pages and component ends */

import "./style.scss";

/* Component starts */
const EditSMC = (props) => {
  /* Props destructuring starts */
  const { onCancel, onSuccess } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [initialFormValues, setInitialFormValues] = useState(initialValues);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [message, setMessage] = useState("");
  const [showErrModal, setShowErrModal] = useState(false);
  const [smcRole, setSmcRole] = useState();

  // useRef
  const contract_copy_img = useRef(null);
  const customPhoneNum = useRef({ code: "", num: "" });
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const dispatch = useDispatch();
  const { propertyId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  // Redux Slice
  const userRoles = useSelector(getUserRoles);
  const user = useSelector(getUser);
  const property = useSelector(getPropertyDetail);
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  const property_id = searchParams.get("property_id") || propertyId;
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  // Form validations
  const formValidation = (values) => {
    return validations(values, customPhoneNum, contract_copy_img);
  };

  // Handling on submit
  const onSubmit = async (values) => {
    // Creating or collecting payload data to be sent
    const payload = {
      role_id: smcRole._id,
      property_id: property._id,
      first_name: values.first_name,
      last_name: values.last_name,
      profession: "Security Management Company",
      email: values.email.toLowerCase(),
      countrycode: customPhoneNum.current.code,
      mobile: customPhoneNum.current.num,
      employee_id: values.employee_id,
      contract_number: values.contract_number,
      contract_start_date: startDate,
      contract_end_date: endDate,
      contract_value: values.contract_value,
      business_name: values.business_name,
    };
    if (contract_copy_img.current && !contract_copy_img?.current?.url) {
      payload["contract_copy_img"] = contract_copy_img.current;
    }

    // Backend response. Try, Catch
    try {
      console.log(payload);

      // Handling success response
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };
  /* Component function definition ends */

  /* Component useEffect starts */
  // Fetching all user roles on initial load
  useEffect(() => {
    (async () => {
      try {
        await dispatch(setLoading(true));
        await dispatch(fetchUserRoles());
        await dispatch(fetchPropertyDetailByID({ property_id }));
        await dispatch(setLoading(false));
      } catch (e) {}
    })();
  }, []);

  // Setting SMC role after all user roles loaded
  useEffect(() => {
    let userRole = userRoles.filter((d) => d.name == GLOBAL.USER_ROLE.SMC)[0];
    setSmcRole(userRole);
  }, [userRoles]);
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="edit-SMC-wrapper-main">
      <h2 className="heading text-center font_xxl mb-5">
        Edit Security Management Company
      </h2>

      <div className="add-new-form">
        <Formik
          onSubmit={onSubmit}
          initialValues={initialFormValues}
          validate={formValidation}
          validateOnBlur={true}
          enableReinitialize={true}
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
            setFieldValue,
            setFieldTouched,
          }) => (
            <form onSubmit={handleSubmit}>
              <div className="form-wrapper">
                {/* Input for First Name */}
                <div className="form-group">
                  <CustomTextInput
                    name="first_name"
                    label="First Name"
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    touched={touched.first_name}
                    errors={errors.first_name}
                    value={values.first_name}
                  />
                </div>

                {/* Input for Last Name */}
                <div className="form-group">
                  <CustomTextInput
                    name="last_name"
                    label="Last Name"
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    touched={touched.last_name}
                    errors={errors.last_name}
                    value={values.last_name}
                  />
                </div>

                {/* Input for Profession */}
                <div className="form-group">
                  <CustomTextInput
                    name="profession"
                    label="Profession"
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    touched={touched.profession}
                    errors={errors.profession}
                    value={values.profession}
                    readOnly={true}
                  />
                </div>

                {/* Input for Company Name */}
                <div className="form-group">
                  <CustomTextInput
                    name="business_name"
                    label="Company Name"
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    touched={touched.business_name}
                    errors={errors.business_name}
                    value={values.business_name}
                  />
                </div>

                {/* Input for Email */}
                <div className="form-group">
                  <CustomTextInput
                    name="email"
                    label="Email"
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    touched={touched.email}
                    errors={errors.email}
                    value={values.email}
                  />
                </div>

                {/* Input for Phone */}
                <div className="form-group">
                  <CustomPhoneInput
                    name="mobile"
                    label={"Phone Number"}
                    customPhoneNum={customPhoneNum}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    value={values.mobile}
                    countryCodeEditable={true}
                    errors={errors.mobile}
                    touched={touched.mobile}
                  />
                </div>

                {/* Input for Employee ID */}
                <div className="form-group">
                  <CustomTextInput
                    name="employee_id"
                    label="Employee ID"
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    touched={touched.employee_id}
                    errors={errors.employee_id}
                    value={values.employee_id}
                  />
                </div>

                {/* Input for Contract Number */}
                <div className="form-group">
                  <CustomTextInput
                    name="contract_number"
                    label="Contract Number"
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    touched={touched.contract_number}
                    errors={errors.contract_number}
                    value={values.contract_number}
                  />
                </div>

                {/* Input for Start Date */}
                <div className="form-group">
                  <CustomDateInput
                    label="Start Date"
                    value={startDate}
                    customOnchange={(date) => setStartDate(date)}
                  />
                </div>

                {/* Input for End Date */}
                <div className="form-group">
                  <CustomDateInput
                    label="Start Date"
                    value={endDate}
                    customOnchange={(date) => setEndDate(date)}
                  />
                </div>

                {/* Input for Contract Value */}
                <div className="form-group">
                  <CustomCurrencyInput
                    name="contract_value"
                    label={`Contract Value (${property?.currency})`}
                    setFieldValue={setFieldValue}
                    setFieldTouched={setFieldTouched}
                    touched={touched.contract_value}
                    errors={errors.contract_value}
                    value={values.contract_value}
                    currency={user?.currency}
                  />
                </div>

                {/* Input for Contract Copy */}
                <div className="form-group">
                  <CustomFileInput
                    label="Upload Copy Contract"
                    name="contract_copy_img"
                    files={contract_copy_img}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    errors={errors.contract_copy_img}
                    touched={touched.contract_copy_img}
                    values={values.contract_copy_img}
                    formikState={false}
                  />
                </div>
              </div>

              <div className="button-wrapper text-center">
                <CustomButton type="submit" size="l">
                  Update
                </CustomButton>
                <TextButton
                  type="button"
                  onClick={onCancel}
                  className="font_m fw_6"
                >
                  Cancel
                </TextButton>
              </div>
            </form>
          )}
        </Formik>

        {/* Modals */}
        {/* Error Modal */}
        <ErrorModal
          showModal={showErrModal}
          title="Error"
          description={message}
          onClose={() => {
            setShowErrModal(false);
          }}
        />
      </div>
    </div>
  );
};
/* Component ends */

export default EditSMC;
