/**
 *
 */

import React, { useState, useEffect, useRef } from "react";

/* Import configuration starts */
import { useParams } from "react-router-dom";
import { FieldArray, Formik } from "formik";
import { connect, useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "@reduxjs/toolkit";
/* Import configuration ends */

/* Import redux slices component starts */
import { setLoading } from "../../../../setup/store/slices/unpersistedSlice";
import {
  fetchVehicleMake,
  fetchVehicleModel,
} from "../../../../setup/store/slices/masterSlice";
import {
  fetchPropertyDetailByID,
  getPropertyDetail,
} from "../../../../setup/store/slices/propertySlice";

import {
  fetchTenantUnitsBasedPropertyId,
  getTenantsLists,
} from "../../../../setup/store/slices/securitySlice";
import { addVisitorsPass } from "../../../../setup/store/slices/visitorLogBook";

/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */

import GLOBAL from "../../../../setup/constants/global";
import ErrorModal from "../../../ui/modal/ErrorModal/ErrorModal";
import SuccessModal from "../../../ui/modal/SuccessModal/SuccessModal";

import CustomTextInput from "../../../ui/input/CustomTextInput/CustomTextInput";
import CustomFileInput from "../../../ui/input/CustomFileInput/CustomFileInput";
import CustomButton from "../../../ui/button/CustomButton/CustomButton";
import TextButton from "../../../ui/button/TextButton/TextButton";
import CustomSelectInput from "../../../ui/input/CustomSelectInput/CustomSelectInput";
import CustomRadioInput from "../../../ui/input/CustomRadioInput/CustomRadioInput";
import CustomPhoneInput from "../../../ui/input/CustomPhoneInput/CustomPhoneInput";

import { fieldPropExtractor, initialValues, validations } from "./helper";
/* Import local pages and component ends */

// Styles
import "./style.scss";

/* Component starts */

const AddVisitor = (props) => {
  /* Props destructuring starts */
  const { onSuccess, onCancel, vehicleMakeList, vehicleModelList } = props;

  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [initialFormValues, setInitialFormValues] = useState(initialValues);
  const [car, setCar] = useState("No");

  const [selectedVehicleMake, setSelectedVehicleMake] = useState([]);
  const [selectedVehicleModel, setSelectedVehicleModel] = useState([]);
  const [vehicleModels, setVehicleModels] = useState([]);
  const [selectedUnitNo, setSelectedUnitNo] = useState();

  const [showErrorModal, setShowErrorModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [modalDesc, setModalDesc] = useState("");

  /* Component states and useRef declaration ends */

  // useRefs declarations
  const photo = useRef(null);

  const customPhoneNum = useRef({ code: "", num: "" });

  /* Other hooks declaration starts */
  const dispatch = useDispatch();
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  const { propertyId } = useParams();

  const tenantList = useSelector(getTenantsLists);
  const property = useSelector(getPropertyDetail);

  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */

  //Loading all Team Members For Selected Company Name
  const loadCarModel = async () => {
    if (selectedVehicleMake != null) {
      setSelectedVehicleModel(null);

      await dispatch(fetchVehicleModel(selectedVehicleMake?._id));
    }
  };

  // On adding photo field
  const onAddPhoto = (callBackPush) => {
    callBackPush(null);
  };

  // On removing last photo field
  const onRemovePhoto = (callBackPop) => {
    callBackPop();
  };

  // Form validations
  const formValidation = (values) => {
    return validations(values, customPhoneNum, photo);
  };

  const onSubmit = async (values) => {
    let car_data = {
      car_color: values.car_data[0].car_color,
      car_plate: values.car_data[0].car_plate,
      car_plate_issued: values.car_data[0].car_plate_issued,
      car_model: (values.car_data[0].car_model = selectedVehicleModel?.name),
      car_make: (values.car_data[0].car_make = selectedVehicleMake?.name),
    };

    const payload = {
      property_id: property?._id,
      tenant_id: selectedUnitNo?.tenant_id,
      visitor_name: values.visitor_first_name + " " + values.visitor_last_name,
      car_data: (car === "Yes" && car_data) || null,
      unit_no: selectedUnitNo?.unit_id,
      is_car_available: car,
      contact_number: values.mobile,
      company: values.company_name,
      reason: values.reason,
      doc_files: [],
      // upload_id: uploadId.current,
    };

    payload["upload_photo"] = photo.current;

    values.docs.forEach((i) => {
      if (i) {
        payload["doc_files"]?.push(i);
      }
    });

    console.log(payload, "PAY-VISITOR");

    // Backend response. Try, Catch
    try {
      const result = await dispatch(addVisitorsPass(payload));

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          setModalDesc(result.payload);
          setShowErrorModal(true);
          break;

        case GLOBAL.FULFILLED:
          setModalDesc(result.payload.message);
          setShowSuccessModal(true);
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };

  /* Component function definition ends */

  /* Component useEffect starts */

  useEffect(() => {
    (async () => {
      try {
        await dispatch(setLoading(true));
        await dispatch(fetchPropertyDetailByID({ property_id: propertyId }));
        await dispatch(setLoading(false));
      } catch (e) {}
    })();
  }, [propertyId]);

  useEffect(() => {
    dispatch(fetchTenantUnitsBasedPropertyId({ property_id: propertyId }));
    dispatch(fetchVehicleMake());
  }, []);

  useEffect(() => {
    if (tenantList?.length > 0) {
      setSelectedUnitNo(tenantList[0]);
    }
  }, [tenantList]);

  // Loading All Car Models Based on selected Car Make
  useEffect(() => {
    loadCarModel();
  }, [selectedVehicleMake]);

  // Setting selected Model after loading Car Make List
  useEffect(() => {
    if (vehicleModelList?.length > 0) {
      setVehicleModels(vehicleModelList[0]);
    }
  }, [vehicleModelList]);

  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */

  return (
    <div className="add-visitor-module-wrapper">
      <h2 className="heading text-center font_xxl mb-5">Registor Visitor</h2>

      <div className="add-new-form">
        <Formik
          onSubmit={onSubmit}
          initialValues={initialFormValues}
          validate={formValidation}
          validateOnBlur={true}
          enableReinitialize={true}
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
            setFieldValue,
            setFieldTouched,
          }) => (
            <form onSubmit={handleSubmit}>
              <div className="form-wrapper">
                {/* Creating Visitor Request */}
                <>
                  {/* Input for Visitor First Name */}
                  <div className="form-group">
                    <CustomTextInput
                      name="visitor_first_name"
                      label="First Name"
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      touched={touched.visitor_first_name}
                      errors={errors.visitor_first_name}
                      value={values.visitor_first_name}
                    />
                  </div>

                  {/* Input for Visitor Last Name */}
                  <div className="form-group">
                    <CustomTextInput
                      name="visitor_last_name"
                      label="Last Name"
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      touched={touched.visitor_last_name}
                      errors={errors.visitor_last_name}
                      value={values.visitor_last_name}
                    />
                  </div>

                  {/* Select Unit No. */}
                  <div className="form-group">
                    <CustomSelectInput
                      name="unit_num"
                      label="Select Tenant Unit"
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                      valueExtractor={(item) =>
                        `${item?.unit_name} - ${item?.tenant_name}`
                      }
                      setValue={setSelectedUnitNo}
                      options={tenantList}
                      value={selectedUnitNo}
                      formikState={false}
                      emptyOption={false}
                    />
                  </div>

                  {/* Input for Company Name */}
                  <div className="form-group">
                    <CustomTextInput
                      name="company_name"
                      label="Company Name"
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      touched={touched.company_name}
                      value={values.company_name}
                      required={false}
                    />
                  </div>

                  {/* Input for Phone */}
                  <div className="form-group">
                    <CustomPhoneInput
                      name="mobile"
                      label={" Number"}
                      customPhoneNum={customPhoneNum}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      value={values.mobile}
                      countryCodeEditable={true}
                      errors={errors.mobile}
                      touched={touched.mobile}
                    />
                  </div>

                  {/* Radio select the Car */}
                  <div className="form-group form-group-full w_100">
                    <p className="font_m font_grey w_100">Do You Have Car ?</p>
                    <CustomRadioInput
                      label="Yes"
                      onSelecting={() => {
                        setCar("Yes");
                      }}
                      isSelected={car === "Yes"}
                    />
                    <CustomRadioInput
                      label="No"
                      onSelecting={() => {
                        setCar("No");
                      }}
                      isSelected={car === "No"}
                    />
                  </div>
                  {/* Input for Car Details */}
                  {car === "Yes" && (
                    <>
                      <div className="form-group">
                        <CustomSelectInput
                          label="Make"
                          value={selectedVehicleMake}
                          valueExtractor={(item) => item?.name}
                          options={vehicleMakeList}
                          formikState={false}
                          emptyOption={false}
                          customOnchange={(selectedItem) =>
                            setSelectedVehicleMake(selectedItem)
                          }
                        />
                      </div>

                      {/* Input for Vehicle Model */}
                      <div className="form-group">
                        <CustomSelectInput
                          label="Model"
                          value={selectedVehicleModel}
                          valueExtractor={(item) => item?.name}
                          options={vehicleModelList}
                          formikState={false}
                          emptyOption={false}
                          customOnchange={(selectedItem) =>
                            setSelectedVehicleModel(selectedItem)
                          }
                        />
                      </div>

                      {/* Input for Color */}
                      <div className="form-group">
                        <CustomTextInput
                          label="Color"
                          name={`car_data[${0}].car_color`}
                          handleChange={handleChange}
                          value={values.car_data[0].car_color}
                        />
                      </div>

                      {/* Input for Plate Number */}
                      <div className="form-group">
                        <CustomTextInput
                          label="Plate Number"
                          name={`car_data[${0}].car_plate`}
                          handleChange={handleChange}
                          value={values.car_data[0].car_plate}
                        />
                      </div>

                      {/* Input for Plate Issued From */}
                      <div className="form-group">
                        <CustomTextInput
                          label="Plate Issued From"
                          name={`car_data[${0}].car_plate_issued`}
                          handleChange={handleChange}
                          value={values.car_data[0].car_plate_issued}
                        />
                      </div>
                    </>
                  )}

                  {/* Input for Reason For Access */}
                  <div className="form-group">
                    <CustomTextInput
                      name="reason"
                      label="Reason For Access"
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      touched={touched.reason}
                      errors={errors.reason}
                      value={values.reason}
                    />
                  </div>

                  {/* Input for Photo */}
                  <div className="form-group">
                    <CustomFileInput
                      label="Upload Photo"
                      name="photo"
                      files={photo}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      errors={errors.photo}
                      touched={touched.photo}
                      values={values.photo}
                      required={false}
                      formikState={false}
                    />
                  </div>

                  {/* Input for Upload ID */}
                  <FieldArray
                    name="docs"
                    render={({ push, pop }) => (
                      <div className="form-group">
                        {/* Upload Image List */}
                        {values?.docs?.map((item, index) => (
                          <div
                            key={index}
                            className={index >= 1 ? "add-image" : ""}
                          >
                            <CustomFileInput
                              name={`docs[${index}]`}
                              label="Upload ID / Document"
                              setFieldValue={setFieldValue}
                              setFieldTouched={setFieldTouched}
                              value={values.docs[index]}
                              formikState={true}
                              touched={fieldPropExtractor(touched, index)}
                              validaterror={fieldPropExtractor(errors, index)}
                              required={false}
                            />
                          </div>
                        ))}

                        <div className="action-button text-center">
                          {values.docs[values.docs.length - 1] && (
                            <TextButton
                              variant="outline"
                              size="m"
                              className="txtBtn"
                              onClick={() => onAddPhoto(push)}
                            >
                              + Add More Images/Videos
                            </TextButton>
                          )}

                          {values.docs.length > 1 && (
                            <CustomButton
                              className="remove"
                              size="m"
                              onClick={() => onRemovePhoto(pop)}
                            >
                              Remove
                            </CustomButton>
                          )}
                        </div>
                      </div>
                    )}
                  />
                </>
              </div>

              <div className="button-wrapper text-center">
                <>
                  <CustomButton type="submit" size="l">
                    Save
                  </CustomButton>
                  <TextButton
                    type="button"
                    onClick={onCancel}
                    className="font_m fw_6"
                  >
                    Cancel
                  </TextButton>
                </>
              </div>
            </form>
          )}
        </Formik>
      </div>

      {/* Success Modal */}
      <SuccessModal
        showModal={showSuccessModal}
        description={modalDesc}
        title="Successfully Created"
        onClose={() => {
          onSuccess();
          setShowSuccessModal(false);
        }}
      />

      {/* Error Modal */}
      <ErrorModal
        showModal={showErrorModal}
        description={modalDesc}
        title="Error"
        onClose={() => setShowErrorModal(false)}
      />
    </div>
  );
};
/* Component ends */

const mapStateToProps = (state) => {
  return {
    vehicleMakeList: state.master.vehicleMakeList,
    vehicleModelList: state.master.vehicleModelList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchVehicleMake: bindActionCreators(fetchVehicleMake, dispatch),
    fetchVehicleModel: bindActionCreators(fetchVehicleModel, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddVisitor);
