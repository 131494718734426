/**
 *
 */

import React, { useState, useEffect, useCallback } from "react";

/* Import configuration starts */
import { Link } from "react-router-dom";
import GLOBAL from "../../../../setup/constants/global";
/* Import configuration ends */

/* Import redux slices component starts */

/* Import redux slices component ends */

/* Import react bootstrap component starts */
import Table from "react-bootstrap/Table";
/* Import react bootstrap component ends */

/* Import image and SVG starts */
import {
  EyeFilledBlue,
  DeleteGrey,
  EditWhite,
  EditGreen,
  DeleteRed,
} from "../../../../setup/constants/images";
/* Import image and SVG ends */

/* Import local pages and component starts */
import StatusHighLight from "../StatusHighLight/StatusHighLight";
import IconButton from "../../../ui/button/IconButton/IconButton";
import SliderButton from "../../../ui/button/SliderButton/SliderButton";
import TextButton from "../../../ui/button/TextButton/TextButton";
/* Import local pages and component ends */

import "./style.scss";

/* Component starts */
const MTTableUnderFMC = (props) => {
  /* Props destructuring starts */
  const {
    fmcList,
    subMembers,
    userRoles,
    parent,
    disabledStatusSwitch,
    toggleActiveSuspend,
    onViewDetail,
    onEdit,
    onDelete,
    canInvite,

    canResendInvitation,
    canCancelInvitation,
    setSelectedTeamMember,
    showWarning,
  } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [userRole, setUserRole] = useState(null);
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  const showSlider = subMembers?.length > 1;
  const isFM = localStorage.getItem("user_role") === "Facility Manager";
  const isClient = localStorage.getItem("user_role") === "Client";
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  /* Component function definition ends */

  /* Component useEffect starts */
  // Filtering and setting specific user role details on load of all user roles
  useEffect(() => {
    if (userRoles) {
      setUserRole(
        userRoles.filter((role) => role.name === GLOBAL.USER_ROLE.MT)[0]
      );
    }
  }, [userRoles]);
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="mt-table-under-fmc-module-wrapper">
      <div className="employee-heading">
        <h3 className="font_m fw_5">Maintenance Technician</h3>

        {/* <p
          onClick={() => {
            canInvite(GLOBAL.USER_ROLE.MT, `fmc/${parent?.user_id}/mt/add`);
          }}
          // to={`fmc/${parent?.user_id}/mt/add`}
          className="fw_6 font_s font_blue"
        >
          + Add MT
        </p> */}
      </div>

      {subMembers?.length > 0 && (
        <div className="custom-table-wrapper">
          <Table className="custom-table font_s" borderless responsive>
            {/* Table Heading */}
            <thead>
              <tr>
                <th>ID</th>
                <th>Name</th>
                <th></th>
                <th>Status</th>
                <th className="text-start">Actions</th>
              </tr>
            </thead>
            {/* Table Body */}
            <tbody>
              {subMembers.map((item, index) => (
                <tr key={index}>
                  {/* ID */}
                  <td>
                    <p className="col-different1">#{index + 1}</p>
                  </td>

                  {/* MT Name */}
                  <td>
                    <p className="col-different2">
                      {item?.first_name} {item?.last_name}
                    </p>
                  </td>

                  {/* Status Slider Button */}
                  <td>
                    <div className="col-different3">
                      {showSlider && item?.status !== "expired" && (
                        <SliderButton
                          active={item?.status === GLOBAL.USER_STATUS.ACTIVE}
                          onChange={(state) =>
                            (isFM || isClient) &&
                            toggleActiveSuspend(item, state, "mt")
                          }
                          disabled={disabledStatusSwitch(item?.status)}
                        />
                      )}
                    </div>
                  </td>

                  {/* Status */}
                  <td className="fw_6">
                    <p className="col-different4">
                      <StatusHighLight status={item?.status} />
                    </p>
                  </td>

                  {/* Actions */}
                  <td className="text-start">
                    {/* View Details */}
                    <IconButton
                      className="action-btn"
                      onClick={() => onViewDetail(item)}
                    >
                      <img
                        src={EyeFilledBlue}
                        className="h_100"
                        alt="View Details"
                      />
                    </IconButton>

                    {/* Edit */}
                    {(isFM || isClient) &&
                      item?.status === GLOBAL.USER_STATUS.PENDING_APPROVAL && (
                        <IconButton
                          className="action-btn"
                          onClick={() => onEdit(item, "mt")}
                        >
                          <img src={EditGreen} className="h_100" alt="Edit" />
                        </IconButton>
                      )}

                    {/* Delete */}
                    {(isFM || isClient) &&
                      item?.status === GLOBAL.USER_STATUS.SUSPENDED && (
                        <IconButton
                          className="action-btn"
                          onClick={() => onDelete(item)}
                        >
                          <img src={DeleteRed} className="h_100" alt="Delete" />
                        </IconButton>
                      )}

                    {(isFM || isClient) &&
                      canResendInvitation(item?.status) && (
                        <TextButton
                          onClick={() => {
                            setSelectedTeamMember(item);
                            showWarning("resend");
                          }}
                          className="font_m v_middle fw_6 action-txt-btn"
                        >
                          Resend
                        </TextButton>
                      )}

                    {(isFM || isClient) &&
                      canCancelInvitation(item?.status) && (
                        <TextButton
                          onClick={() => {
                            setSelectedTeamMember(item);
                            showWarning("cancel");
                          }}
                          className="font_m v_middle fw_6 font_red action-txt-btn"
                        >
                          Cancel
                        </TextButton>
                      )}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      )}

      {(!subMembers || subMembers?.length === 0) && (
        <div className="not-added-yet-employee text-center">
          <h3 className="bg_darkgrey font_m font_yellow fw_6">
            No Maintenance Technician added yet
          </h3>
        </div>
      )}
    </div>
  );
};
/* Component ends */

export default MTTableUnderFMC;
