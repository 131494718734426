/**
 *
 */

import React, { useEffect, useState } from "react";

/* Import configuration starts */
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
/* Import configuration ends */

/* Import redux slices component starts */
import {
  fetchInfoTilesList,
  getInfoTilesList,
} from "../../../../setup/store/slices/infoTileSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import MUI component starts */
/* Import MUI component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import OverviewItem from "../OverviewItem/OverviewItem";
import CustomSlider from "../../../ui/other/CustomSlider/CustomSlider";
/* Import local pages and component ends */

import "./style.scss";
import { setLoading } from "../../../../setup/store/slices/unpersistedSlice";

/* Component starts */
const OverviewSlider = (props) => {
  /* Props destructuring starts */
  const { propInfoTiles = [], communityInfo = [], path, type = "" } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */

  const [InfoTiles, setInfoTiles] = useState([]);

  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const allInfo = useSelector(getInfoTilesList);

  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */

  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */

  /* Component function definition ends */

  /* Component useEffect starts */
  // useEffect(() => {
  //   dispatch(fetchInfoTilesList());
  // }, []);

  useEffect(() => {
    (async () => {
      try {
        await dispatch(setLoading(true));
        await dispatch(fetchInfoTilesList());
        await dispatch(setLoading(false));
      } catch (e) {}
    })();
  }, []);

  useEffect(() => {
    const path = pathname.split("/")[2];

    if (path === "home" && allInfo) {
      const info = allInfo;
      setInfoTiles(info);
    } else if (type === "property") {
      setInfoTiles(propInfoTiles);
    } else {
      setInfoTiles(communityInfo);
    }
  }, [pathname, type, propInfoTiles, communityInfo, allInfo]);

  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="overview">
      {InfoTiles?.length > 0 && (
        <CustomSlider type="overview">
          {InfoTiles?.map((info, idx) => (
            <div key={idx}>
              <OverviewItem info={info} />
            </div>
          ))}
        </CustomSlider>
      )}

      {InfoTiles?.length === 0 && (
        <p className="text-center font_l font_yellow fw_5">
          No Properties Added Under Community
        </p>
      )}
    </div>
  );
};
/* Component ends */

export default OverviewSlider;
