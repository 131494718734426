/**
 *
 */

import React, { useState, useEffect, useRef } from "react";

/* Import configuration starts */
import moment from "moment";
import { useParams } from "react-router-dom";
import { FieldArray, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
/* Import configuration ends */

/* Import redux slices component starts */
import {
  fetchUnitDetailByID,
  getUnitDetail,
} from "../../../../../../setup/store/slices/unitSlice";
import { addLostItem } from "../../../../../../setup/store/slices/lostAndFoundSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import GLOBAL from "../../../../../../setup/constants/global";

import {
  addDoc,
  fieldPropExtractor,
  initialValues,
  removeDoc,
  validations,
} from "./helper";
import CustomTextInput from "../../../../../ui/input/CustomTextInput/CustomTextInput";
import CustomFileInput from "../../../../../ui/input/CustomFileInput/CustomFileInput";
import CustomTextArea from "../../../../../ui/input/CustomTextArea/CustomTextArea";
import CustomButton from "../../../../../ui/button/CustomButton/CustomButton";
import TextButton from "../../../../../ui/button/TextButton/TextButton";
import SuccessModal from "../../../../../ui/modal/SuccessModal/SuccessModal";
import ErrorModal from "../../../../../ui/modal/ErrorModal/ErrorModal";
/* Import local pages and component ends */

// Styles
import "./style.scss";

/* Component starts */

const AddLostForm = (props) => {
  /* Props destructuring starts */
  const { onSuccess, onCancel } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [initialFormValues, setInitialFormValues] = useState(initialValues);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [modalDesc, setModalDesc] = useState("");

  const [docList, setDocList] = useState([]);
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const { unitId } = useParams();
  const formRef = useRef();
  const dispatch = useDispatch();
  const unit = useSelector(getUnitDetail);
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  const USER_DATA = localStorage.getItem("user_data")
    ? JSON.parse(localStorage.getItem("user_data"))
    : null;
  const DATE_FORMAT = USER_DATA ? USER_DATA?.date_format : "";
  const TIME_FORMAT = USER_DATA ? USER_DATA?.time_format : "";
  const DATE_TIME_FORMAT = `${DATE_FORMAT} ${TIME_FORMAT}`;
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */

  // On adding photo field
  const onAddPhoto = (callBackPush) => {
    callBackPush(null);
  };

  // On removing last photo field
  const onRemovePhoto = (callBackPop) => {
    callBackPop();
  };
  // Form validations
  const formValidation = (values) => {
    return validations(values, docList);
  };

  const onSubmit = async (values) => {
    // Payload To Sent For Backend
    const payload = {
      tenant_id: USER_DATA?._id,
      unit_id: unit?._id,
      itemshortdescription: values.item_name,
      itemlongdescription: values.description,
      // file: [],
    };

    const document = [];

    docList?.forEach((item, index) => {
      if (values[`document_${item}`]) {
        document.push(values[`document_${item}`]);
      }
    });
    payload["file"] = document;

    console.log(payload);

    // Backend response. Try, Catch
    try {
      const result = await dispatch(addLostItem(payload));

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          setModalDesc(result.payload);
          setShowErrorModal(true);
          break;

        case GLOBAL.FULFILLED:
          setModalDesc("Added Lost Item Successfully.");
          setShowSuccessModal(true);
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };

  /* Component function definition ends */

  /* Component useEffect starts */
  useEffect(() => {
    dispatch(fetchUnitDetailByID({ unit_id: unitId }));
  }, [unitId]);
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */

  return (
    <div className="add-lost-module-wrapper">
      <h2 className="heading text-center font_xxl mb-5">Add Lost Item</h2>

      <div className="add-new-form">
        <Formik
          onSubmit={onSubmit}
          initialValues={initialFormValues}
          validate={formValidation}
          validateOnBlur={true}
          enableReinitialize={true}
          innerRef={formRef}
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
            setFieldValue,
            setFieldTouched,
          }) => (
            <form onSubmit={handleSubmit}>
              <div className="form-wrapper">
                {/* Creating Lost Item Report */}
                <>
                  {/* Input for Item Name */}
                  <div className="form-group">
                    <CustomTextInput
                      name="item_name"
                      label="Item Name"
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      touched={touched.item_name}
                      errors={errors.item_name}
                      value={values.item_name}
                    />
                  </div>

                  {/* Input for Item Name */}
                  <div className="form-group">
                    <p className="font_m fw_4 font_grey date-wrapper">
                      Date:{" "}
                      <span className="font_m fw_4 px-1">
                        {" "}
                        {moment(new Date()).format(DATE_TIME_FORMAT)}{" "}
                      </span>
                    </p>
                  </div>

                  {/* Input for Description */}
                  <div className="form-group form-group-full">
                    <CustomTextArea
                      name="description"
                      label="Description"
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      value={values.description}
                      errors={errors.description}
                      touched={touched.description}
                      required={false}
                    />
                  </div>

                  {/* Input for Photo */}
                  <div className="doc-list-wrapper">
                    <h3 className="font_m fw_6">Photos and Videos</h3>
                    <div className="doc-list">
                      {docList?.length === 0 && (
                        <h3 className="text-center font_m font_yellow fw_6 no-attachments">
                          No photos or videos attached!
                        </h3>
                      )}

                      {docList?.map((item, index) => (
                        <div className="form-group doc" key={index}>
                          <div className="file-input-wrapper">
                            <CustomFileInput
                              name={`document_${item}`}
                              label="Document"
                              setFieldValue={setFieldValue}
                              setFieldTouched={setFieldTouched}
                              formikState={true}
                              touched={touched[`document_${item}`]}
                              errors={errors[`document_${item}`]}
                              value={values[`document_${item}`]}
                              required={true}
                              acceptable={true}
                              acceptType="image/*,video/*"
                            />
                          </div>

                          {docList.length > 1 && (
                            <div className="remove-btn-wrapper">
                              <TextButton
                                className="button font_m fw_6"
                                variant="red"
                                onClick={() => {
                                  removeDoc(
                                    formRef,
                                    docList,
                                    setInitialFormValues,
                                    setDocList,
                                    index
                                  );
                                }}
                              >
                                Remove
                              </TextButton>
                            </div>
                          )}
                        </div>
                      ))}
                    </div>

                    <div className="doc_action_btn-wrapper add-btn-wrapper">
                      {(docList.length === 0 ||
                        values[`document_${docList[docList.length - 1]}`]) && (
                        <CustomButton
                          className="font_m fw_6 add-btn"
                          variant="outline"
                          size="s"
                          onClick={() => {
                            addDoc(
                              formRef,
                              docList,
                              setInitialFormValues,
                              setDocList
                            );
                          }}
                        >
                          + Add
                        </CustomButton>
                      )}
                    </div>
                  </div>
                </>
              </div>

              <div className="button-wrapper text-center">
                <>
                  <CustomButton type="submit" size="l">
                    Submit
                  </CustomButton>
                  <TextButton
                    type="button"
                    onClick={onCancel}
                    className="font_m fw_6"
                  >
                    Cancel
                  </TextButton>
                </>
              </div>
            </form>
          )}
        </Formik>
      </div>

      {/* Success Modal */}
      <SuccessModal
        showModal={showSuccessModal}
        description={modalDesc}
        title="Successfully Created"
        onClose={() => {
          onSuccess();
          setShowSuccessModal(false);
        }}
      />

      {/* Error Modal */}
      <ErrorModal
        showModal={showErrorModal}
        description={modalDesc}
        title="Error"
        onClose={() => setShowErrorModal(false)}
      />
    </div>
  );
};
/* Component ends */

export default AddLostForm;
