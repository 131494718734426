/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import { useNavigate } from "react-router-dom";
import GLOBAL from "../../../../../../setup/constants/global";
import moment from "moment";
/* Import configuration ends */

/* Import redux slices component starts */
/* Import redux slices component ends */

/* Import react bootstrap component starts */
import { Table } from "react-bootstrap";
/* Import react bootstrap component ends */

/* Import image and SVG starts */
import {
  DocFilledWhite,
  DocumentGreen,
  EyeFilledBlue,
} from "../../../../../../setup/constants/images";
/* Import image and SVG ends */

/* Import local pages and component starts */
import IconButton from "../../../../../ui/button/IconButton/IconButton";
import Pagination from "../../../../../ui/other/Pagination/Pagination";
/* Import local pages and component ends */

/* Component starts */
const PaidInvoicing = (props) => {
  /* Props destructuring starts */
  const { list, currency, currentPage, setCurrentPage, totalCount } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const navigate = useNavigate();
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  const USER_DATA = localStorage.getItem("user_data")
    ? JSON.parse(localStorage.getItem("user_data"))
    : null;
  const DATE_FORMAT = USER_DATA ? USER_DATA?.date_format : "";

  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */

  const handleViewReport = (item) => {
    navigate(`${item?._id}`, {
      state: item,
    });
  };

  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <>
      <div className="custom-table-wrapper paid-invoice-table">
        {list?.length > 0 && (
          <Table className="custom-table font_s" borderless responsive>
            {/* Table Heading */}
            <thead>
              <tr>
                <th>Invoice</th>
                <th>Type</th>
                <th>Total</th>
                <th>Payable Date</th>
                <th>Status</th>
                <th className="text-center">Action</th>
              </tr>
            </thead>
            {/* Table Body */}
            <tbody>
              {list?.map((item) => (
                <tr key={item?._id}>
                  {/* Invoice */}
                  <td>{item?.invoice_no}</td>

                  {/* Type */}
                  <td>
                    {item?.type?.charAt(0).toUpperCase() + item?.type?.slice(1)}
                  </td>

                  {/* Total */}
                  <td>
                    {item?.total_amount?.toLocaleString("en-US")} {currency}
                  </td>

                  {/*  Payable Date */}
                  <td>{moment(item?.due_date).format(DATE_FORMAT)}</td>

                  {/* Status */}
                  <td
                    className={`${
                      item?.status === "paid" || item?.status === "collected"
                        ? "font_green"
                        : item?.status === "unpaid"
                        ? "font_yellow"
                        : "font_red"
                    }`}
                  >
                    {item?.status === "paid_and_carry_forward"
                      ? "Carry Forward"
                      : item?.status === "collected"
                      ? "Collected"
                      : item?.status === "partially_collected"
                      ? "Partially Collected"
                      : item?.status === "not_collected"
                      ? "Not Collected"
                      : item?.status === "partially_settled"
                      ? "Partially Settled"
                      : item?.status === "settled"
                      ? "Settled"
                      : item?.status?.charAt(0).toUpperCase() +
                        item?.status?.slice(1)}
                  </td>

                  {/* Actions */}
                  <td className="text-center txt-nowrap">
                    <IconButton
                      onClick={() => handleViewReport(item)}
                      className="action-button"
                    >
                      <img
                        src={EyeFilledBlue}
                        className="h_100"
                        alt="View Details"
                      />
                    </IconButton>

                    {/* <IconButton
                      onClick={() => handleViewReport(item)}
                      className="action-button"
                    >
                      <img
                        src={DocumentGreen}
                        className="h_100"
                        alt="View Details"
                      />
                    </IconButton> */}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}

        {list?.length === 0 && (
          <div className=" text-center">
            <h3 className=" font_m font_yellow fw_6">No Data</h3>
          </div>
        )}
      </div>

      {/* PAGINATION */}
      {list?.length > 0 && (
        <Pagination
          itemsPerPage="10"
          totalItems={totalCount}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          // type="invoice"
          // currency={property?.currency}
          // paid={invoiceAggregation?.paid_rent[0]?.total_amount}
          // unPaid={invoiceAggregation?.unpaid_rent[0]?.total_amount}
          // overdue={invoiceAggregation?.overDue_rent[0]?.total_amount}
        />
      )}
    </>
  );
};
/* Component ends */

export default PaidInvoicing;
