/**
 *
 */

import React, { useEffect } from "react";

/* Import configuration starts */
import { useLocation, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
/* Import configuration ends */

/* Import redux slices component starts */
import { setLoading } from "../../setup/store/slices/unpersistedSlice";
import { getPropertyDetail } from "../../setup/store/slices/propertySlice";
import { getUser } from "../../setup/store/slices/authSlice";
import {
  fetchRoutineTaskOnId,
  fetchRoutineTaskTicketBasedOnId,
  getRoutineTaskData,
  getRoutineTaskTicketData,
} from "../../setup/store/slices/routineSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import BreadCrumb from "../../components/ui/other/BreadCrumb/BreadCrumb";
import RoutineTaskDetail from "../../components/module/RoutineRequest/RoutineTaskDetail/RoutineTaskDetail";

/* Import local pages and component ends */

import "./style.scss";

/* Component starts */
const RoutineTaskDetailPage = (props) => {
  /* Props destructuring starts */
  const {} = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const { state } = useLocation();
  const { taskId } = useParams();
  const dispatch = useDispatch();
  const taskDetail = useSelector(getRoutineTaskData);
  const taskTicketDetail = useSelector(getRoutineTaskTicketData);
  const property = useSelector(getPropertyDetail);
  const user = useSelector(getUser);
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  /* Component function definition ends */

  /* Component useEffect starts */
  useEffect(() => {
    (async () => {
      try {
        await dispatch(setLoading(true));
        if (taskId && state?.taskTab) {
          await dispatch(fetchRoutineTaskOnId({ routine_id: taskId }));
        } else {
          await dispatch(
            fetchRoutineTaskTicketBasedOnId({ routine_ticket_id: taskId })
          );
        }
        await dispatch(setLoading(false));
      } catch (e) {}
    })();
  }, [taskId, state]);

  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <>
      <BreadCrumb type="routine_task_details" />
      <div className="routine_task-page-wrapper">
        <RoutineTaskDetail
          taskDetail={state?.taskTab ? taskDetail : taskTicketDetail}
          taskTab={state?.taskTab}
          property={property}
          user={user}
        />
      </div>
    </>
  );
};
/* Component ends */

export default RoutineTaskDetailPage;
