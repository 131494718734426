/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import moment from "moment";
// import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
/* Import configuration ends */

/* Import redux slices component starts */
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
/* Import local pages and component ends */

/* Component starts */
const AnnoucementView = (props) => {
  /* Props destructuring starts */
  const { annoucement, announcementStats } = props;

  /* Props destructuring ends - violation */

  /* Component states and useRef declaration starts */
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */

  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  const USER_DATA = localStorage.getItem("user_data")
    ? JSON.parse(localStorage.getItem("user_data"))
    : null;
  const DATE_FORMAT = USER_DATA ? USER_DATA?.date_format : "";
  const TIME_FORMAT = USER_DATA ? USER_DATA?.time_format : "";
  const DATE_TIME_FORMAT = `${DATE_FORMAT} ${TIME_FORMAT}`;

  const isClient = localStorage.getItem("user_role") === "Client";
  const isOwner = localStorage.getItem("user_role") === "Owner";
  const isFM = localStorage.getItem("user_role") === "Facility Manager";

  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */

  /* Component function definition ends */

  /* Component useEffect starts */

  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="detail-card container-lg document-wrapper mt-3">
      <div className="detail-report">
        <div className="title">
          <p className="font_s fw_4 font_grey">
            Title : <span className="font_s fw_6">{annoucement?.title}</span>
          </p>

          {(isClient || isOwner || isFM) && (
            <p className="font_m fw_4 font_blue">
              Open By :{" "}
              <span className="font_m fw_6 font_yellow">
                {announcementStats?.open_by}
              </span>
            </p>
          )}
        </div>
        <p className="font_s fw_4 font_grey">
          Description <br />
          <span className="font_s">{annoucement?.description}</span>
        </p>

        <div className="data">
          <p className="font_s fw_4 font_grey">
            Date & Time <br />
            <span className="fw_6">
              {moment(annoucement?.createdAt).format(DATE_TIME_FORMAT)}
            </span>
          </p>

          {/* <p className="font_s fw_4 font_grey">
            Announcement For <br />
            <span className="fw_6 font_blue">
              {annoucement?.added_for?.charAt(0)?.toUpperCase() +
                annoucement?.added_for?.slice(1)}
            </span>
          </p> */}

          <p className="font_s fw_4 font_grey">
            Start Date <br />
            <span className="fw_6">
              {moment(annoucement?.start_date).format(DATE_FORMAT)}
            </span>
          </p>

          <p className="font_s fw_4 font_grey">
            End Date <br />
            <span className="fw_6">
              {moment(annoucement?.end_date).format(DATE_FORMAT)}
            </span>
          </p>
        </div>
      </div>

      <hr />

      {annoucement?.html_messege !== undefined && (
        <div
          className="description"
          dangerouslySetInnerHTML={{ __html: annoucement?.html_messege }}
        />
      )}
    </div>
  );
};
/* Component ends */

export default AnnoucementView;
