export const logTitle = (item) => {
  let title = "";
  let role = item?.requester_role?.name;
  let currentStatus = item?.status;
  let logType = item?.log_type;

  //   Generating title according to action string
  switch (item?.action) {
    //
    case "":
      title = "";
      break;

    // Default Case...
    default:
      title = item?.action;
  }

  if (title === "Owner" && currentStatus === "violation_issued") {
    title = "Violation Issued";
  }

  return title;
};

const userActionPermission = (item, user) => {
  const logRequester =
    item?.action === "Routine task report approval is pending"
      ? item?.assigned?._id
      : item?.requester?._id;
  const logRequesterRole =
    item?.action === "Routine task report approval is pending"
      ? item?.assigned_role?._id
      : item?.requester_role?._id;
  const currentUser = user?._id;
  const currentUserRole = user?.role_id;

  if (logRequesterRole === currentUserRole && logRequester === currentUser) {
    return true;
  }
  return false;
};

// If Assignee Add the Report
export const canAssigneeAddReport = (item, user) => {
  const action = item?.action;
  const currentStatus = item?.current_status;
  const logType = item?.log_type;
  const requesterRole = item?.requester_role?.name;

  if (!userActionPermission(item, user)) {
    return false;
  }

  if (action === "Routine task ticket assigned") {
    return true;
  }
  return false;
};

// If Requester Take Action On the Added Report
export const canRequesterTakeActionOnTicket = (item, user) => {
  const action = item?.action;
  const currentStatus = item?.current_status;
  const logType = item?.log_type;
  const requesterRole = item?.requester_role?.name;

  if (!userActionPermission(item, user)) {
    return false;
  }

  if (action === "Routine task report approval is pending") {
    return true;
  }
  return false;
};
