/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import moment from "moment";
import GLOBAL from "../../../../../setup/constants/global";
import { useDispatch } from "react-redux";
import {
  logTitle,
  canAssignTechnician,
  mtRespondUIRCP1Init,
  canFillURICP1,
  ownerCanFillReport,
} from "../helper";
/* Import configuration ends */

/* Import redux slices component starts */
import { mtRespondToAssign_moveout } from "../../../../../setup/store/slices/moveOutSlice";
import {
  showSuccessAlert,
  showErrorAlert,
} from "../../../../../setup/store/slices/globalAlertSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import CustomButton from "../../../../ui/button/CustomButton/CustomButton";
import AssignTechnicianMoveOutModal from "../../AssignTechnicianMoveOutModal/AssignTechnicianMoveOutModal";
import NoteModal from "../../NoteModal/NoteModal";
import UIRCP1 from "../../UIRCP1/UIRCP1";
/* Import local pages and component ends */

/* Component starts */
const SimpleLog = (props) => {
  /* Props destructuring starts */
  const {
    property,
    maintenance_id,
    maintenance,
    item,
    status,
    isT1,
    takeAction,
    user,
  } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [showAssignTechnician, setShowAssignTechnician] = useState(false);
  const [isApproved, setIsApproved] = useState(null);
  const [openNote, setOpenNote] = useState(false);
  const [showURICP1Form, setShowURICP1Form] = useState(false);
  const [addQuotation, setAddQuotation] = useState(false);
  const [additionalTask, setAdditionalTask] = useState([]);
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const dispatch = useDispatch();
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  const name = item?.requester
    ? `${item?.requester?.first_name} ${item?.requester?.last_name}`
    : `${item?.tenant?.first_name} ${item?.tenant?.last_name}`;
  const role = item?.requester_role?.name;
  const createdAt = item?.createdAt;
  const action = item?.action;
  const currentStatus = item?.current_status;
  const logType = item?.log_type;
  const comment = item?.comment;
  const initialURIC = maintenance?.initial_URIC;
  const finalUIRC = maintenance?.final_URIC;
  const maintenanceType = maintenance?.type;
  const unitCard = maintenance?.unit_card;
  const quote = maintenance?.quotes;
  const p2URIC = maintenance?.phase2_URIC;
  const applicationID = maintenance?.application_id;

  const USER_DATA = localStorage.getItem("user_data")
    ? JSON.parse(localStorage.getItem("user_data"))
    : null;
  const DATE_FORMAT = USER_DATA ? USER_DATA?.date_format : "";
  const TIME_FORMAT = USER_DATA ? USER_DATA?.time_format : "";
  const DATE_TIME_FORMAT = `${DATE_FORMAT} ${TIME_FORMAT}`;
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  // On performing respond action with note such as Reject and Approve
  const respondAction = (value) => {
    // MT approving URIC Phase 1 Initial
    mtRespondUIRCP1Init(item, user) && mtRespondToAssign(value);
  };

  // On responding to assign request sent by FM on Tenant Move In process
  const mtRespondToAssign = async (note) => {
    setIsApproved(null);
    setOpenNote(false);
    // Creating or collecting payload data to be sent

    const payload = {
      maintenance_id,
      status: isApproved ? "Accepted" : "Rejected",
      readiness_card: initialURIC._id,
      type: maintenanceType,
      note,
    };

    // Backend response. Try, Catch
    try {
      const result = await dispatch(mtRespondToAssign_moveout(payload));

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          dispatch(
            showErrorAlert({
              title: "Error!",
              description: result.payload,
            })
          );
          break;

        case GLOBAL.FULFILLED:
          dispatch(
            showSuccessAlert({
              title: "Success!",
              description: result.payload.message,
            })
          );
          break;

        default:
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };
  /* Component function definition ends */

  /* Component useEffect starts */
  useState(() => {
    if (maintenance && p2URIC && p2URIC?.items && p2URIC?.items?.length > 0) {
      setAdditionalTask(() =>
        p2URIC?.items.filter(
          (item) =>
            item?.type &&
            item.type === "Additional" &&
            item.status !== "Approved"
        )
      );
    }
  }, [maintenance, p2URIC]);
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="time-line simple-log">
      {/* Dot */}

      <div className="dot-wrapper">
        <i className="dot"></i>
        <div className="vl"></div>
      </div>

      <div className="detail-wrapper">
        {/* Title */}
        <h3 className="title font_m fw_6">{logTitle(item)}</h3>

        {/* Technician Name and Date */}
        <div className="black-container-sm fw_5 font_s bg_black flex-between">
          {/* Name */}
          <p className="left">
            <span className="font_grey">{role} </span>
            <span className="font_white"> {name}</span>
          </p>

          {/* Date and Time */}
          <p className="right font_grey">
            {moment(createdAt).format(DATE_TIME_FORMAT)}
          </p>
        </div>
        {/* Log Footer */}
        <div className="log-footer">
          {/* Comments */}
          {comment && (
            <p className="comments font_s fw-5">
              <span className="font_grey fw_6">Comments: </span>
              <span className="fw_5 font_red"> {comment}</span>
            </p>
          )}

          {/* Action Buttons */}
          {takeAction && (
            <div className="button-wrapper">
              {/* FM Assigning Technician */}
              {canAssignTechnician(item, user) && (
                <CustomButton
                  onClick={() => setShowAssignTechnician(true)}
                  size="m"
                  variant="yellow"
                >
                  Assign Technician
                </CustomButton>
              )}

              {/* Technician Approving UIRC Phase 1 Initial */}
              {mtRespondUIRCP1Init(item, user) && (
                <>
                  <CustomButton
                    onClick={() => {
                      setIsApproved(true);
                      setOpenNote(true);
                    }}
                    size="m"
                    variant="yellow"
                  >
                    Approve
                  </CustomButton>
                  <CustomButton
                    onClick={() => {
                      setIsApproved(false);
                      setOpenNote(true);
                    }}
                    variant="red"
                    size="m"
                  >
                    Reject
                  </CustomButton>
                </>
              )}

              {/* Technician filling URIC Phase 1 Initial */}
              {canFillURICP1(item, user) && (
                <CustomButton
                  onClick={() => {
                    setShowURICP1Form(true);
                  }}
                  size="m"
                  variant="yellow"
                >
                  {/* Initial Unit Readiness Phase1 Form */}
                  {/* Unit Readiness Final Assessment */}
                  {/* Initial Unit Readiness Phase1 Form */}
                  Initial Unit Inspection Report
                </CustomButton>
              )}

              {ownerCanFillReport(item, user) && (
                <CustomButton
                  onClick={() => {
                    setShowURICP1Form(true);
                  }}
                  size="m"
                  variant="yellow"
                >
                  {/* Initial Unit Readiness Phase1 Form */}
                  Unit Readiness Initial Assessment
                </CustomButton>
              )}
            </div>
          )}
        </div>
      </div>

      {/* Modals */}
      {takeAction && (
        <>
          {/* Adding Note Modal */}
          <NoteModal
            showModal={openNote}
            title="Note"
            btnText={isApproved ? "Approve" : "Reject"}
            onCancel={() => setOpenNote(false)}
            actionWithNote={respondAction}
          />

          {/* Assign Technician Modal */}
          <AssignTechnicianMoveOutModal
            property={property}
            maintenance_id={maintenance_id}
            // maintenanceType={maintenance.type}
            maintenanceType="Move Out"
            showModal={showAssignTechnician}
            onClose={() => setShowAssignTechnician(false)}
            initialURIC={initialURIC}
            finalURIC={finalUIRC}
            UIRCP2={p2URIC}
            type={logType}
            additionalTask={additionalTask}
          />

          {/* UIRC Phase 1 Form Modal */}
          <UIRCP1
            isT1={isT1}
            maintenance_id={maintenance_id}
            showModal={showURICP1Form}
            maintenanceType="Move Out"
            initialURIC={initialURIC}
            finalUIRC={finalUIRC}
            onClose={() => setShowURICP1Form(false)}
            keyRejection={action === "Keys Rejected By Tenant"}
            applicationID={applicationID}
            data={logType === "URIC Initial" ? initialURIC : finalUIRC}
            maintenance={maintenance}
            currency={property?.currency}
          />
        </>
      )}
    </div>
  );
};
/* Component ends */

export default SimpleLog;
