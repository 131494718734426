/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import GLOBAL from "../../../setup/constants/global";
/* Import configuration ends */

/* Import redux slices component starts */
import { sendOtp } from "../../../setup/store/slices/otpSlice";
import { deleteCommunity } from "../../../setup/store/slices/communitySlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import MUI component starts */
/* Import MUI component ends */

/* Import image and SVG starts */
import {
  EditWhite,
  DeleteGrey,
  DeleteRed,
  EditGreen,
} from "../../../setup/constants/images";
/* Import image and SVG ends */

/* Import local pages and component starts */
import IconButton from "../../../components/ui/button/IconButton/IconButton";
import SuccessModal from "../../../components/ui/modal/SuccessModal/SuccessModal";
import ErrorModal from "../../../components/ui/modal/ErrorModal/ErrorModal";
import ConfirmationModal from "../../../components/ui/modal/ConfirmationModal/ConfirmationModal";
import ConfirmationOTPModal from "../../../components/ui/modal/ConfirmationOTPModal/ConfirmationOTPModal";
/* Import local pages and component ends */

/* Component starts */
const Heading = (props) => {
  /* Props destructuring starts */
  const { community } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [otpId, setOtpId] = useState();
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showOtpModal, setShowOtpModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [modalDesc, setModalDesc] = useState("");
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const dispatch = useDispatch();
  const navigate = useNavigate();
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  const isClient = localStorage.getItem("user_role") === "Client";
  const isOwner = localStorage.getItem("user_role") === "Owner";
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  // On deleting community
  const onDelete = () => {
    setModalDesc("Are you sure you want to delete this community ?");
    setShowConfirmationModal(true);
  };

  //On confirming delete community
  const confirmDelete = async () => {
    // Collecting or creating payload data to be sent
    const payload = {
      action: "delete_community",
      resource_name: community?.community_name,
    };

    // Backend response. Try, catch
    try {
      const result = await dispatch(sendOtp(payload));

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          setModalDesc(result.payload);
          setShowConfirmationModal(false);
          setShowErrorModal(true);
          break;

        case GLOBAL.FULFILLED:
          setModalDesc("Enter the code sent to your email");
          setOtpId(result.payload.otp._id);
          setShowConfirmationModal(false);
          setShowOtpModal(true);
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };

  // On verifying otp for delete
  const onVerify = async (otp) => {
    if (otp.length !== 6) return;

    // Collecting or creating payload data to be sent
    const payload = { id: community?._id, otp: otp, otpId: otpId };

    // Backend response. Try, catch
    try {
      const result = await dispatch(deleteCommunity(payload));

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          setModalDesc(result.payload);
          setShowOtpModal(false);
          setShowErrorModal(true);
          break;

        case GLOBAL.FULFILLED:
          setModalDesc("Community has been successfully deleted");
          setShowOtpModal(false);
          setShowSuccessModal(true);
          navigate(-1);
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };
  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="header">
      <h2 className="font_xxl fw_6 w-50">Community Details</h2>

      {(isClient || isOwner) && (
        <div className="community-action text-end w-50">
          <IconButton
            onClick={() => navigate("edit")}
            className="option-button"
          >
            <div className="opt">
              <img src={EditGreen} className="w_100" alt="Edit Icon" />
              <span className="fw_3 font_xs">Edit</span>
            </div>
          </IconButton>
          <IconButton onClick={onDelete} className="option-button">
            <div className="opt">
              <img src={DeleteRed} className="w_100" alt="Delete Icon" />
              <span className="fw_3 font_xs font_red">Delete</span>
            </div>
          </IconButton>
        </div>
      )}

      {/* Modals */}
      {/* Confirmation Modal */}
      <ConfirmationModal
        showModal={showConfirmationModal}
        title="Delete Community"
        description={modalDesc}
        onClose={() => setShowConfirmationModal(false)}
        onConfirm={confirmDelete}
      />

      {/* OTP Modal */}
      <ConfirmationOTPModal
        showModal={showOtpModal}
        onVerify={onVerify}
        title="Enter OTP"
        description={modalDesc}
        onCancel={() => setShowOtpModal(false)}
      />

      {/* Success Modal */}
      <SuccessModal
        showModal={showSuccessModal}
        title="Community Deleted"
        description={modalDesc}
        onClose={() => navigate(-1)}
      />

      {/* Error Modal */}
      <ErrorModal
        showModal={showErrorModal}
        title="Error"
        description={modalDesc}
        onClose={() => setShowErrorModal(false)}
      />
    </div>
  );
};
/* Component ends */

export default Heading;
