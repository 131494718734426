export const skipLink = (key, currentIndex, array, type, from) => {
  /* Accountant Move Out Page Starts */
  if (type === "accountant_moveout_clearence") {
    if (
      key === "independent" ||
      key === "properties" ||
      key === "tenants" ||
      key === "application"
      // ||
      // beforeThis(currentIndex, "move-out", array)
    ) {
      return false;
    }

    if (key === "owner") {
      return false;
    }

    return true;
  }
  /* Accountant Move Out Page Ends */

  /* Home Start */
  if (type === "home") {
    if (key === "home" || key === "provider") {
      return false;
    }
    return true;
  }
  /* Home Ends */

  /* Add Community Start */
  if (type === "community_add") {
    if (key === "communities") {
      return false;
    }
    return true;
  }
  /* Add Community  Ends */

  /* Community Detail Start */
  if (type === "community_detail") {
    if (key === "communities") {
      return false;
    }
    return true;
  }
  /* Community Detail Ends */

  /* Property Detail Start */
  if (type === "property_detail") {
    if (
      key === "independent" ||
      key === "properties" ||
      key === "communities"
    ) {
      return false;
    }
    return true;
  }
  /* Property Detail Ends */

  /* NO Property  Start */
  if (type === "no_property") {
    if (key === "home" || key === "provider") {
      return false;
    }
    return true;
  }
  /* No Property  Ends */

  /* ADEVRTISMNET Start */
  if (type === "advertisment_page") {
    if (key === "independent" || key === "property" || key === "communities") {
      return false;
    }
    return true;
  }
  /* ADEVRTISMNET Ends */

  /* ADD Property  Start */
  if (type === "add-property") {
    if (
      key === "independent" ||
      key === "communities" ||
      key === "properties"
    ) {
      return false;
    }

    if (beforeThis(currentIndex, "resume", array)) {
      return false;
    }
    return true;
  }
  /* ADD Property  Ends */

  /* EDIT Property / Community  Start */
  if (type === "edit-property" || type === "community_edit") {
    if (
      key === "independent" ||
      key === "properties" ||
      key === "communities"
    ) {
      return false;
    }
    return true;
  }
  /* EDIT Property  Ends */

  /* Unit Module Start */
  if (type === "unit_list") {
    if (
      key === "independent" ||
      key === "properties" ||
      key === "communities"
    ) {
      return false;
    }
    return true;
  }
  /* Unit Module Ends */

  /* Unit Details Start */
  if (type === "unit_details") {
    if (
      key === "independent" ||
      key === "properties" ||
      key === "communities"
    ) {
      return false;
    }
    return true;
  }
  /* Unit Details Ends */

  /* Unit Add Start */
  if (type === "add_unit") {
    if (
      key === "independent" ||
      key === "properties" ||
      key === "communities"
    ) {
      return false;
    }
    return true;
  }
  /* Unit Add Ends */

  /* Unit Edit Start */
  if (type === "edit_unit") {
    if (
      key === "independent" ||
      key === "properties" ||
      key === "communities"
    ) {
      return false;
    }

    if (beforeThis(currentIndex, "units", array)) {
      return false;
    }
    return true;
  }
  /* Unit Edit Ends */

  /* Unit Invite Start */
  if (type === "invite_tenant") {
    if (
      key === "independent" ||
      key === "properties" ||
      key === "communities"
    ) {
      return false;
    }
    if (key === "add" || key === "tenant") {
      return false;
    }
    // if(beforeThis(currentIndex, 'add', array)){
    //   return false
    // }

    // if(beforeTwo(currentIndex, 'tenant', array)){
    //   return false
    // }
    return true;
  }
  /* Unit Invite Ends */

  /*ASSIGN LICENSE Starts */
  if (type === "assign-license") {
    if (
      key === "independent" ||
      key === "properties" ||
      key === "communities"
    ) {
      return false;
    }

    if (key === "assign") {
      return false;
    }

    return true;
  }

  /*ASSIGN LICENSE Ends */

  // MANAGE TEAMS MODULE

  /* MANAGE TEAMS Start */
  if (type === "manage_teams") {
    if (
      key === "independent" ||
      key === "properties" ||
      key === "communities"
    ) {
      return false;
    }

    return true;
  }
  /* MANAGE TEAMS Ends */

  /* MANAGE TEAMS ADD OWNER Start */
  if (type === "manage_team_owner") {
    if (
      key === "independent" ||
      key === "properties" ||
      key === "communities"
    ) {
      return false;
    }

    if (key === "owner") {
      return false;
    }

    return true;
  }

  /* MANAGE TEAMS ADD OWNER SUBMEMBERS Start */
  if (type === "manage_team_owner_submember") {
    if (
      key === "independent" ||
      key === "properties" ||
      key === "communities"
    ) {
      return false;
    }

    if (beforeThis(currentIndex, "owner", array)) {
      return false;
    }

    if (key === "owner" || key === "or") {
      return false;
    }

    return true;
  }
  /* MANAGE TEAMS ADD OWNER SUBMEMBERS Ends */

  /* MANAGE TEAMS ADD PMC Ends */

  if (type === "manage_team_pmc") {
    if (
      key === "independent" ||
      key === "properties" ||
      key === "communities"
    ) {
      return false;
    }

    if (key === "pmc") {
      return false;
    }

    return true;
  }
  /* MANAGE TEAMS ADD PMC Ends */

  /* MANAGE TEAMS ADD PM Ends */

  if (type === "manage_team_pm") {
    if (
      key === "independent" ||
      key === "properties" ||
      key === "communities"
    ) {
      return false;
    }

    if (key === "pm") {
      return false;
    }

    if (
      beforeThis(currentIndex, "pmc", array) ||
      beforeThis(currentIndex, "pm", array)
    ) {
      return false;
    }

    if (
      key === "pmc" ||
      key === "pm" ||
      key === "pa" ||
      key === "lo" ||
      key === "po" ||
      key === "mo"
    ) {
      return false;
    }

    return true;
  }
  /* MANAGE TEAMS ADD PM Ends */

  /* MANAGE TEAMS ADD ACCOUTANT Ends */

  if (type === "manage_team_acc") {
    if (
      key === "independent" ||
      key === "properties" ||
      key === "communities"
    ) {
      return false;
    }

    if (key === "accountant") {
      return false;
    }

    if (beforeThis(currentIndex, "pmc", array)) {
      return false;
    }

    if (beforeThis(currentIndex, "fmc", array)) {
      return false;
    }

    if (key === "pmc" || key === "fmc") {
      return false;
    }

    return true;
  }
  /* MANAGE TEAMS ADD ACCOUTANT Ends */

  /* MANAGE TEAMS ADD FMC Ends */

  if (type === "manage_team_fmc") {
    if (
      key === "independent" ||
      key === "properties" ||
      key === "communities"
    ) {
      return false;
    }

    if (key === "fmc") {
      return false;
    }

    return true;
  }
  /* MANAGE TEAMS ADD fMC Ends */

  /* MANAGE TEAMS ADD fM Ends */

  if (type === "manage_team_fm") {
    if (
      key === "independent" ||
      key === "properties" ||
      key === "communities"
    ) {
      return false;
    }

    if (key === "fm") {
      return false;
    }

    if (beforeThis(currentIndex, "fmc", array)) {
      return false;
    }

    if (key === "fmc") {
      return false;
    }

    return true;
  }
  /* MANAGE TEAMS ADD fM Ends */

  /* MANAGE TEAMS ADD MT Ends */

  if (type === "manage_team_mt") {
    if (
      key === "independent" ||
      key === "properties" ||
      key === "communities"
    ) {
      return false;
    }

    if (key === "mt") {
      return false;
    }

    if (beforeThis(currentIndex, "fm", array)) {
      return false;
    }

    if (key === "fm") {
      return false;
    }

    return true;
  }
  /* MANAGE TEAMS ADD MT Ends */

  /* MANAGE TEAMS ADD sMC Ends */

  if (type === "manage_team_smc") {
    if (
      key === "independent" ||
      key === "properties" ||
      key === "communities"
    ) {
      return false;
    }

    if (key === "smc") {
      return false;
    }

    return true;
  }
  /* MANAGE TEAMS ADD sMC Ends */

  /* MANAGE TEAMS ADD sM Ends */

  if (type === "manage_team_sm") {
    if (
      key === "independent" ||
      key === "properties" ||
      key === "communities"
    ) {
      return false;
    }

    if (key === "sm") {
      return false;
    }

    if (beforeThis(currentIndex, "smc", array)) {
      return false;
    }

    if (key === "smc") {
      return false;
    }

    return true;
  }
  /* MANAGE TEAMS ADD sM Ends */

  /* MANAGE TEAMS ADD SO Ends */

  if (type === "manage_team_so") {
    if (
      key === "independent" ||
      key === "properties" ||
      key === "communities"
    ) {
      return false;
    }

    if (key === "so") {
      return false;
    }

    if (beforeThis(currentIndex, "sm", array)) {
      return false;
    }

    if (key === "sm") {
      return false;
    }

    return true;
  }
  /* MANAGE TEAMS ADD SO Ends */

  /* MANAGE TEAMS ADD TPC Ends */

  if (type === "manage_team_tpc") {
    if (
      key === "independent" ||
      key === "properties" ||
      key === "communities"
    ) {
      return false;
    }

    if (key === "tpc") {
      return false;
    }

    return true;
  }
  /* MANAGE TEAMS ADD TPC Ends */

  /* MANAGE TEAMS ADD TPM Ends */

  if (type === "manage_team_tpm") {
    if (
      key === "independent" ||
      key === "properties" ||
      key === "communities"
    ) {
      return false;
    }

    if (key === "tpc") {
      return false;
    }

    if (beforeThis(currentIndex, "tpc", array)) {
      return false;
    }

    if (key === "tpm") {
      return false;
    }

    return true;
  }
  /* MANAGE TEAMS ADD tpm Ends */

  /* MANAGE TEAMS ADD TPT Ends */

  if (type === "manage_team_tpt") {
    if (
      key === "independent" ||
      key === "properties" ||
      key === "communities"
    ) {
      return false;
    }

    if (key === "tpt") {
      return false;
    }

    if (beforeThis(currentIndex, "tpc", array)) {
      return false;
    }

    if (key === "tpc") {
      return false;
    }

    return true;
  }
  /* MANAGE TEAMS ADD tpt Ends */

  // MANAGE TEAMS MODULE

  // MANAGE TENANTS MODULE - Start

  /* MANAGE TENANTS Start */
  if (type === "manage_tenants") {
    if (
      key === "independent" ||
      key === "properties" ||
      key === "communities"
    ) {
      return false;
    }

    if (
      key === "existing" ||
      key === "transitioned" ||
      key === "new" ||
      key === "previous-tenants" ||
      key === "requests" ||
      key === "move-out" ||
      key === "renewals" ||
      key === "cancelled"
    ) {
      return false;
    }

    return true;
  }
  /* MANAGE TENANTS Ends */

  /* MANAGE TENANTS EXISTING DETAILS Start */

  if (type === "exixting_tenant_detail") {
    if (
      key === "independent" ||
      key === "properties" ||
      key === "communities"
    ) {
      return false;
    }

    if (key === "tenants" || key === "unit" || key === "detail") {
      return false;
    }

    if (beforeThis(currentIndex, "detail", array)) {
      return false;
    }

    return true;
  }

  /* MANAGE TENANTS EXISTING DETAILS Ends */

  /* MANAGE TENANTS TRANSITIONED DETAILS Start */

  if (type === "transitioned_tenant_application_review") {
    if (
      key === "independent" ||
      key === "properties" ||
      key === "communities"
    ) {
      return false;
    }

    if (key === "tenants") {
      return false;
    }
    return true;
  }

  /* MANAGE TENANTS TRANSITIONED DETAILS Ends */

  /* MANAGE TENANTS MOVE OUT DETAILS Start */

  if (type === "moveout_tenant_detail") {
    if (
      key === "independent" ||
      key === "properties" ||
      key === "communities"
    ) {
      return false;
    }

    if (key === "tenants") {
      return false;
    }

    return true;
  }

  /* MANAGE TENANTS MOVE OUT DETAILS Ends */

  /* MANAGE TENANTS RENEWALS DETAILS Start */

  if (type === "renewal_tenant_detail") {
    if (
      key === "independent" ||
      key === "properties" ||
      key === "communities"
    ) {
      return false;
    }

    if (key === "tenants") {
      return false;
    }
    return true;
  }

  /* MANAGE TENANTS RENEWALS DETAILS Ends */

  // MANAGE TENANTS MODULE - END

  // MAINTENANCE MODULE - START

  /* MANAGE MAINTENANCES Starts */
  if (type === "manage_maintenance") {
    if (
      key === "independent" ||
      key === "properties" ||
      key === "communities"
    ) {
      return false;
    }

    if (
      key === "New" ||
      key === "Ongoing" ||
      key === "Spare%20Parts" ||
      key === "Completed" ||
      key === "Rejected" ||
      key === "Escalated" ||
      key === "Move%20In" ||
      key === "Move%20Out" ||
      key === "Units" ||
      key === "Common%20Area" ||
      key === "Closed"
    ) {
      return false;
    }

    return true;
  }

  /* MANAGE MAINTENANCES Ends */

  /* MANAGE MAINTENANCES DETAIL Starts */
  if (type === "maintenance_detail") {
    if (key === "independent" || key === "properties") {
      return false;
    }
    if (key === "maintenances") {
      return false;
    }
    if (from === "move-out") {
      if (key === "maintenance") {
        return false;
      }
    }

    if (key === "ratingAndReviews") {
      return false;
    }

    return true;
  }

  /* MANAGE MAINTENANCES DETAIL Ends */

  /* Onboarding Application PM Review Start */
  if (type === "onboard_application_pm_review") {
    if (
      key === "independent" ||
      key === "properties" ||
      key === "communities"
    ) {
      return false;
    }
    if (key === "tenants") {
      return false;
    }
    return true;
  }
  /* Onboarding Application PM Review Ends */

  // MAINTENANCE MODULE - END

  // INTER MAINTENANCE MODULE - START

  /* INTER MAINTENANCES Starts */
  if (type === "internal_maintenance") {
    if (
      key === "independent" ||
      key === "properties" ||
      key === "communities"
    ) {
      return false;
    }

    if (
      key === "maintenances" ||
      key === "new" ||
      key === "ongoing" ||
      key === "spareParts" ||
      key === "completed" ||
      key === "rejected" ||
      key === "revision" ||
      key === "closed"
    ) {
      return false;
    }

    return true;
  }

  /* INTER MAINTENANCES Ends */

  /* INTER MAINTENANCES DETAIL Starts */
  if (type === "inter-main-details") {
    if (
      key === "independent" ||
      key === "properties" ||
      key === "communities"
    ) {
      return false;
    }

    if (key === "maintenances" || key === "internal") {
      return false;
    }

    return true;
  }

  /* INTER MAINTENANCES DETAIL Ends */

  /* INTER MAINTENANCES ADD Starts */
  if (type === "internal_maintenance_add") {
    if (
      key === "independent" ||
      key === "properties" ||
      key === "communities"
    ) {
      return false;
    }

    if (key === "maintenances" || key === "internal") {
      return false;
    }

    return true;

    // if (beforeThis(currentIndex, "New", array)) {
    //   return "Maintenance Detail";
    // }
  }

  /* INTER MAINTENANCES ADD Ends */

  // INTER MAINTENANCE MODULE - END

  // INVOICING MODULE

  /* Invoicng Start */
  if (type === "client_invoicing") {
    if (
      key === "independent" ||
      key === "properties" ||
      key === "communities"
    ) {
      return false;
    }
    if (
      key === "Tenant" ||
      key === "rent" ||
      key === "facility" ||
      key === "utility" ||
      key === "deposits" ||
      key === "application" ||
      key === "commission" ||
      key === "others"
    ) {
      return false;
    }

    if (
      key === "Property%20Invoice" ||
      key === "utilities" ||
      key === "others"
    ) {
      return false;
    }

    if (
      key === "Parties%20Invoice" ||
      key === "spare-parts" ||
      key === "contracts"
    ) {
      return false;
    }

    if (key === "Advertisments" || key === "active" || key === "expired") {
      return false;
    }

    return true;
  }
  /* Invoicng Ends */

  /* Invoicng ADD Start */
  if (type === "client_add_invoice") {
    if (
      key === "independent" ||
      key === "properties" ||
      key === "communities"
    ) {
      return false;
    }
    if (
      from !== "accountant-clearence" &&
      (key === "Tenant" ||
        key === "invoicing" ||
        key === "Property%20Invoice" ||
        key === "Parties%20Invoice" ||
        key === "invoice" ||
        key === "unit" ||
        key === "detail" ||
        key === "tenants" ||
        beforeThis(currentIndex, "detail", array))
    ) {
      return false;
    }
    if (
      from === "accountant-clearence" &&
      (key === "tenant" ||
        key === "invoicing" ||
        key === "application" ||
        beforeThis(currentIndex, "move-out", array))
    ) {
      return false;
    }
    return true;
  }
  /* Invoicng Ends */

  /* Invoicng Details Start */
  if (type === "client_invoicing_detail") {
    if (
      key === "independent" ||
      key === "properties" ||
      key === "communities" ||
      key === "tenants"
    ) {
      return false;
    }
    if (key === "Tenant" || key === "invoicing") {
      return false;
    }

    if (
      key === "Property%20Invoice" ||
      key === "Parties%20Invoice" ||
      key === "invoicing"
    ) {
      return false;
    }

    if (from === "onboard_application" && key === "deposits") {
      return false;
    }

    if (from === "accountant_moveout_review") {
      if (key === "deposits" || key === "review") {
        return false;
      }
    }

    return true;
  }
  /* Invoicng Details Ends */

  // INVOICING MODULE

  // VOILATION MODULE

  /* Violations Start */
  if (type === "client_violation") {
    if (
      key === "independent" ||
      key === "properties" ||
      key === "communities"
    ) {
      return false;
    }

    if (key === "tenants" || key === "teams" || key === "others") {
      return false;
    }

    return true;
  }
  /* Violations Ends */

  /* Violations Start ADD */
  if (type === "client_violation_add") {
    if (
      key === "independent" ||
      key === "properties" ||
      key === "communities"
    ) {
      return false;
    }

    if (key === "unit" || key === "detail" || key === "violations") {
      return false;
    }

    return true;
  }
  /* Violations Ends */

  // VOILATION MODULE

  // ADVERTISMENT
  /* ADVERTISMENT DETAIL Start */
  if (type === "advertisment_detail") {
    if (key === "independent" || key === "property" || key === "communities") {
      return false;
    }

    return true;
  }

  // ANNOUCEMENT MODULE

  /* ANNOUCEMENT Start */
  if (type === "client_annoucement") {
    if (
      key === "independent" ||
      key === "properties" ||
      key === "communities" ||
      key === "provider" ||
      key === "home"
    ) {
      return false;
    }

    return true;
  }
  /* ANNOUCEMENT Ends */

  /* ANNOUCEMENT ADD Start */
  if (type === "client_annoucement_add") {
    if (
      key === "independent" ||
      key === "properties" ||
      key === "communities" ||
      key === "provider" ||
      key === "home"
    ) {
      return false;
    }

    return true;
  }
  /* ANNOUCEMENT ADD Ends */

  /* ANNOUCEMENT DETAIL Start */
  if (type === "client_annoucement_detail") {
    if (
      key === "independent" ||
      key === "properties" ||
      key === "communities" ||
      key === "provider" ||
      key === "home"
    ) {
      return false;
    }

    return true;
  }
  /* ANNOUCEMENT Ends */

  // ANNOUCEMENT MODULE

  // LOST & FOUND MODULE

  /* LOST & FOUND Start */
  if (type === "client_lostAndFound") {
    if (
      key === "independent" ||
      key === "properties" ||
      key === "communities"
    ) {
      return false;
    }

    if (key === "lost_and_found") {
      return false;
    }

    return true;
  }
  /* LOST & FOUND Ends */

  /* LOST & FOUND ADD Start */
  if (type === "client_lostAndFound_add") {
    if (
      key === "independent" ||
      key === "properties" ||
      key === "communities"
    ) {
      return false;
    }

    if (key === "lost_and_found") {
      return false;
    }

    return true;
  }
  /* LOST & FOUND ADD Ends */

  /* FOUND DETAILS Start */
  if (type === "client_found_detail") {
    if (
      key === "independent" ||
      key === "properties" ||
      key === "communities"
    ) {
      return false;
    }

    if (key === "lost_and_found") {
      return false;
    }

    return true;
  }
  /* FOUND DETAILS Ends */

  /* LOST DETAILS Start */
  if (type === "client_lost_detail") {
    if (
      key === "independent" ||
      key === "properties" ||
      key === "communities"
    ) {
      return false;
    }

    if (key === "lost_and_found") {
      return false;
    }

    return true;
  }
  /* LOST DETAILS Ends */

  /* CLAIM DETAILS Start */
  if (type === "client_claim_detail") {
    if (
      key === "independent" ||
      key === "properties" ||
      key === "communities"
    ) {
      return false;
    }

    if (key === "lost_and_found") {
      return false;
    }

    return true;
  }
  /* CLAIM DETAILS Ends */

  // LOST & FOUND MODULE

  // SECURITY MODULE

  /* SECURITY Start */
  if (type === "client_security") {
    if (
      key === "independent" ||
      key === "properties" ||
      key === "communities"
    ) {
      return false;
    }

    if (
      key === "open" ||
      key === "complaints" ||
      key === "incidents" ||
      key === "closed"
    ) {
      return false;
    }

    return true;
  }
  /* SECURITY Ends */

  /* SECURITY ADD Start */
  if (type === "security_incident_add") {
    if (
      key === "independent" ||
      key === "properties" ||
      key === "communities"
    ) {
      return false;
    }

    if (key === "incidents" || key === "security") {
      return false;
    }

    return true;
  }

  /* SECURITY ADD Ends */

  /* SECURITY INCIDENT DETAILS Start */
  if (type === "security_incident_detail") {
    if (
      key === "independent" ||
      key === "properties" ||
      key === "communities"
    ) {
      return false;
    }

    if (
      // key === "incidents" ||
      key === "closed" ||
      key === "security"
    ) {
      return false;
    }

    // if(
    //   // beforeThis(currentIndex, "incidents", array)
    //   key === 'incidents' && !beforeThis(currentIndex, "incidents", array)
    // ){
    //   return false
    // }

    return true;
  }
  /* SECURITY INCIDENT DETAILS Ends */

  /* SECURITY Complaint DETAILS Start */
  if (type === "security_complain_detail") {
    if (
      key === "independent" ||
      key === "properties" ||
      key === "communities"
    ) {
      return false;
    }

    if (key === "closed" || key === "security") {
      return false;
    }

    // if(beforeThis(currentIndex, "incidents", array)){
    //   return false
    // }

    return true;
  }
  /* SECURITY Complaint DETAILS Ends */

  // SECURITY MODULE

  /* NORMAL USER CHANGE PASS SETTING Start */
  if (type === "settings_changePass_page") {
    if (key === "password") {
      return false;
    }

    return true;
  }
  /* NORMAL USER CHANGE PASS SETTING Ends */

  /* NORMAL USER CHANGE EMAIL SETTING Start */
  if (type === "settings_changeEmail_page") {
    // if (key === "update-email") {
    //   return false;
    // }
    // return true;
  }
  /* NORMAL USER CHANGE EMAIL SETTING Ends */

  // PROPERTY SETTING MODULE

  /* PROPERTY SETTING Start */
  if (type === "property_setting") {
    if (
      key === "independent" ||
      key === "properties" ||
      key === "communities"
    ) {
      return false;
    }

    return true;
  }
  /* PROPERTY SETTING Ends */

  /* PROPERTY SETTING ADD Start */
  if (type === "property_setting_template") {
    if (
      key === "independent" ||
      key === "properties" ||
      key === "communities"
    ) {
      return false;
    }

    if (
      key === "initial_agreement" ||
      key === "template" ||
      key === "final_agreement" ||
      key === "clearance_cert"
    ) {
      return false;
    }

    return true;
  }
  /* PROPERTY SETTING ADD Ends */

  // PROPERTY SETTING MODULE

  // DASHBOARD MODULE - Start

  /* DASHBOARD Start */
  if (type === "dashboards") {
    if (
      key === "independent" ||
      key === "properties" ||
      key === "communities"
    ) {
      return false;
    }

    if (key === "provider" || "home") {
      return false;
    }

    return true;
  }
  /* DASHBOARD Ends */

  /* DASHBOARD OCCUPANCY Start */
  if (type === "dashboard_occupancy") {
    if (
      key === "independent" ||
      key === "properties" ||
      key === "communities"
    ) {
      return false;
    }

    return true;
  }
  /* DASHBOARD OCCUPANCY Ends */

  /* DASHBOARD CONTRACTS Start */
  if (type === "dashboard_contracts") {
    if (
      key === "independent" ||
      key === "properties" ||
      key === "communities"
    ) {
      return false;
    }

    return true;
  }
  /* DASHBOARD CONTRACTS Ends */

  /* DASHBOARD FINANCIAL Start */
  if (type === "dashboard_financial") {
    if (
      key === "independent" ||
      key === "properties" ||
      key === "communities"
    ) {
      return false;
    }

    return true;
  }
  /* DASHBOARD FINANCIAL Ends */

  /* DASHBOARD TENANT MAINTENANCE Start */
  if (type === "dashboard_tenant_main") {
    if (
      key === "independent" ||
      key === "properties" ||
      key === "communities"
    ) {
      return false;
    }

    return true;
  }
  /* DASHBOARD TENANT MAINTENANCE Ends */

  /* DASHBOARD INTERNAL MAINTENANCE Start */
  if (type === "dashboard_internal_main") {
    if (
      key === "independent" ||
      key === "properties" ||
      key === "communities"
    ) {
      return false;
    }

    return true;
  }
  /* DASHBOARD INTERNAL MAINTENANCE Ends */

  /* DASHBOARD SECURITY Start */
  if (type === "dashboard_security") {
    if (
      key === "independent" ||
      key === "properties" ||
      key === "communities"
    ) {
      return false;
    }

    return true;
  }
  /* DASHBOARD SECURITY Ends */

  /* DASHBOARD SPARE PARTS Start */
  if (type === "dashboard_spare") {
    if (
      key === "independent" ||
      key === "properties" ||
      key === "communities"
    ) {
      return false;
    }

    return true;
  }
  /* DASHBOARD SPARE PARTS Ends */

  /* DASHBOARD RATINGS Start */
  if (type === "dashboard_rating") {
    if (
      key === "independent" ||
      key === "properties" ||
      key === "communities"
    ) {
      return false;
    }

    return true;
  }
  /* DASHBOARD RATINGS Ends */

  /* DASHBOARD TEAMS Start */
  if (type === "dashboard_teams") {
    if (
      key === "independent" ||
      key === "properties" ||
      key === "communities"
    ) {
      return false;
    }

    return true;
  }
  /* DASHBOARD TEAMS Ends */

  /* DASHBOARD VIOLATIONS Start */
  if (type === "dashboard_violation") {
    if (
      key === "independent" ||
      key === "properties" ||
      key === "communities"
    ) {
      return false;
    }

    return true;
  }
  /* DASHBOARD VIOLATIONS Ends */

  /* DASHBOARD MOVE IN Start */
  if (type === "dashboard_moveIn") {
    if (
      key === "independent" ||
      key === "properties" ||
      key === "communities"
    ) {
      return false;
    }

    return true;
  }
  /* DASHBOARD MOVE IN Ends */

  /* DASHBOARD MOVE OUT Start */
  if (type === "dashboard_moveOut") {
    if (
      key === "independent" ||
      key === "properties" ||
      key === "communities"
    ) {
      return false;
    }

    return true;
  }
  /* DASHBOARD MOVE OUT Ends */

  // DASHBOARD MODULE - End

  // RATING & REVIEW MODULE

  /* RATING & REVIEW Start */
  if (type === "ratingAndReview") {
    if (
      key === "independent" ||
      key === "properties" ||
      key === "communities"
    ) {
      return false;
    }

    if (key === "tenants" || key === "teams") {
      return false;
    }

    return true;
  }
  /* RATING & REVIEW Ends */

  // ROUTINE REQUEST MODULE

  /* ROUTINE REQUEST Start */
  if (type === "routineRequests") {
    if (
      key === "independent" ||
      key === "properties" ||
      key === "communities"
    ) {
      return false;
    }

    if (
      key === "task" ||
      key === "request" ||
      key === "active" ||
      key === "expired" ||
      key === "open" ||
      key === "closed"
    ) {
      return false;
    }

    return true;
  }
  /* ROUTINE REQUEST Ends */

  /* ROUTINE REQUEST ADD Start */
  if (type === "add_tasks") {
    if (
      key === "independent" ||
      key === "properties" ||
      key === "communities"
    ) {
      return false;
    }

    if (key === "routine-request" || key === "task") {
      return false;
    }

    return true;
  }
  /* ROUTINE REQUEST ADD Ends */

  /* ROUTINE REQUEST EDIT Start */
  if (type === "edit_routine_task") {
    if (
      key === "independent" ||
      key === "properties" ||
      key === "communities"
    ) {
      return false;
    }

    if (key === "routine-request" || key === "task") {
      return false;
    }

    if (afterThis(currentIndex, "edit", array)) {
      return false;
    }

    return true;
  }
  /* ROUTINE REQUEST EDIT Ends */

  /* ROUTINE REQUEST DETAILS Start */
  if (type === "routine_task_details") {
    if (
      key === "independent" ||
      key === "properties" ||
      key === "communities"
    ) {
      return false;
    }

    if (key === "routine-request" || key === "task" || key === "request") {
      return false;
    }

    return true;
  }

  /* ROUTINE REQUEST DETAILS End */

  // VIOLATION MODUEL
  if (type === "client_violation_detail") {
    if (
      key === "independent" ||
      key === "properties" ||
      key === "communities"
    ) {
      return false;
    }

    if (key === "violations") {
      return false;
    }

    return true;
  }

  // RATING & REVIEW MODULE

  // ACCESS PASS MODULE

  /* ACCESS PASS Start */
  if (type === "acess_pass") {
    if (
      key === "independent" ||
      key === "properties" ||
      key === "communities"
    ) {
      return false;
    }

    if (key === "visitors" || key === "contractors") {
      return false;
    }

    return true;
  }
  /* ACCESS PASS Ends */

  /* ACCESS PASS DETAILS Start */
  if (type === "contractor_access_detail") {
    if (
      key === "independent" ||
      key === "properties" ||
      key === "communities"
    ) {
      return false;
    }

    if (key === "access_pass") {
      return false;
    }

    return true;
  }
  /* ACCESS PASS DETAILS Ends */

  /* ACCESS PASS VISITOR DETAILS Start */
  if (type === "visitor_access_detail") {
    if (
      key === "independent" ||
      key === "properties" ||
      key === "communities"
    ) {
      return false;
    }

    if (key === "access_pass") {
      return false;
    }

    return true;
  }
  /* ACCESS PASS VISITOR DETAILS Ends */

  // ACCESS PASS MODULE

  // LOG BOOK MODULE

  /* LOG BOOK Start */
  if (type === "log_book") {
    if (
      key === "independent" ||
      key === "properties" ||
      key === "communities"
    ) {
      return false;
    }

    // if (key === "visitors" || key === "contractors") {
    //   return false;
    // }

    return true;
  }
  /* LOG BOOK Ends */

  /* LOG BOOK ADD Start */
  if (type === "log_book_add") {
    if (
      key === "independent" ||
      key === "properties" ||
      key === "communities"
    ) {
      return false;
    }

    return true;
  }
  /* LOG BOOK ADD Ends */

  /* LOG BOOK SIGNOUT DETAILS Start */
  if (type === "visitor_log_signOut_detail") {
    if (
      key === "independent" ||
      key === "properties" ||
      key === "communities"
    ) {
      return false;
    }

    if (key === "sign-out") {
      return false;
    }

    return true;
  }
  /* LOG BOOK SIGNOUT DETAILS Ends */

  /* LOG BOOK  DETAILS Start */
  if (type === "visitor_log_detail") {
    if (
      key === "independent" ||
      key === "properties" ||
      key === "communities"
    ) {
      return false;
    }

    return true;
  }
  /* LOG BOOK  DETAILS Ends */

  // LOG BOOK MODULE

  return true;
};

export const renderKey = (key, currentIndex, array, type, from) => {
  /* Accountant Move Out Page Starts */
  if (type === "accountant_moveout_clearence") {
    if (beforeThis(currentIndex, "properties", array)) {
      return "[[property]]";
    }
    if (beforeThis(currentIndex, "communities", array)) {
      return "[[community]]";
    }
    if (beforeThis(currentIndex, "tenants", array)) {
      return "Manage Tenants ( Move Out )";
    }
    if (
      beforeThis(currentIndex, "application", array) ||
      afterThis(currentIndex, "review", array)
    ) {
      return "Move Out Details";
    }
  }
  /* Accountant Move Out Page Ends */

  /* Property Detail Start */
  if (type === "property_detail") {
    if (beforeThis(currentIndex, "properties", array)) {
      return "[[property]]";
    }
    if (beforeThis(currentIndex, "communities", array)) {
      return "[[community]]";
    }
  }
  /* Property Detail Ends */

  /* ADD Property  Start */
  if (type === "add-property") {
    if (beforeThis(currentIndex, "properties", array) && key === "resume") {
      return "[[property]]";
    }
    if (beforeThis(currentIndex, "communities", array)) {
      return "[[community]]";
    }
    if (key === "properties" || key === "add") {
      return "Add ( Property )";
    }

    if (key === "resume") {
      return "Resume Add Property";
    }
  }
  /* ADD Property  Ends */

  /* ADD Property  Start */
  if (type === "community_add") {
    // if (beforeThis(currentIndex, "properties", array)) {
    //   return "[[property]]";
    // }
    // if (beforeThis(currentIndex, "communities", array)) {
    //   return "[[community]]";
    // }
    if (key === "communities" || key === "add") {
      return "Add ( Community )";
    }
    // return true;
  }
  /* ADD Property  Ends */

  /* Community Detail Start */
  if (type === "community_detail") {
    if (beforeThis(currentIndex, "communities", array)) {
      return "[[community]]";
    }
  }
  /* Community Detail Ends */

  /* PROFILE Start */
  if (type === "myProfile_page") {
    // if (beforeThis(currentIndex, "communities", array)) {
    //   return "Communities";
    // }

    if (key === "profile") {
      return "My Profile";
    }
  }
  /* PROFILE Ends */

  /* PROFILE EDIT Start */
  if (type === "edit_myProfile_page") {
    // if (beforeThis(currentIndex, "communities", array)) {
    //   return "Communities";
    // }

    if (key === "profile") {
      return "My Profile";
    }
  }
  /* PROFILE EDIT Ends */

  /* EDIT Property / Community Start */
  if (type === "edit-property" || type === "community_edit") {
    if (beforeThis(currentIndex, "properties", array)) {
      return "[[property]]";
    }
    if (beforeThis(currentIndex, "communities", array)) {
      return "[[community]]";
    }
  }
  /* EDIT Property  Ends */

  /* ADEVRTISMNET Start */
  if (type === "advertisment_page") {
    if (beforeThis(currentIndex, "property", array)) {
      return "[[property]]";
    }

    if (beforeThis(currentIndex, "communities", array)) {
      return "[[community]]";
    }

    if (key === "list") {
      return "Advertisments";
    }
  }
  /* ADEVRTISMNET Ends */

  /* ADEVRTISMNET Start */
  if (type === "advertisment_detail") {
    if (beforeThis(currentIndex, "property", array)) {
      return "[[property]]";
    }

    if (beforeThis(currentIndex, "communities", array)) {
      return "[[community]]";
    }

    if (key === "detail") {
      return "Advertisment Detail";
    }
  }
  /* ADEVRTISMNET Ends */

  /* Unit List Start */
  if (type === "unit_list") {
    if (beforeThis(currentIndex, "properties", array)) {
      return "[[property]]";
    }

    if (beforeThis(currentIndex, "communities", array)) {
      return "[[community]]";
    }

    if (beforeThis(currentIndex, "units", array)) {
      return "Units";
    }
  }
  /* Unit List Ends */

  /* Unit Details Start */
  if (type === "unit_details") {
    if (beforeThis(currentIndex, "properties", array)) {
      return "[[property]]";
    }

    if (beforeThis(currentIndex, "communities", array)) {
      return "[[community]]";
    }

    if (beforeThis(currentIndex, "units", array)) {
      return "Unit Detail";
    }
  }
  /* Unit Details Ends */

  /* Unit Add Start */
  if (type === "add_unit") {
    if (beforeThis(currentIndex, "properties", array)) {
      return "[[property]]";
    }

    if (beforeThis(currentIndex, "communities", array)) {
      return "[[community]]";
    }

    if (beforeThis(currentIndex, "add", array)) {
      return "Add Unit";
    }
  }
  /* Unit Add Ends */

  /* Unit Edit Start */
  if (type === "edit_unit") {
    if (beforeThis(currentIndex, "properties", array)) {
      return "[[property]]";
    }

    if (beforeThis(currentIndex, "communities", array)) {
      return "[[community]]";
    }

    if (key === "edit") {
      return "Edit Unit";
    }
  }
  /* Unit Edit Ends */

  /* Unit Invite Start */
  if (type === "invite_tenant") {
    if (beforeThis(currentIndex, "properties", array)) {
      return "[[property]]";
    }

    if (beforeThis(currentIndex, "communities", array)) {
      return "[[community]]";
    }

    if (beforeThis(currentIndex, "units", array)) {
      return "Unit Detail";
    }

    if (key === "invite") {
      return "Invite";
    }

    // if(beforeThis(currentIndex, "units", array)){
    //   return 'Unit Detail'
    // }
  }
  /* Unit Invite Ends */

  /* MANAGE MAINTENANCES Ends */

  /*ASSIGN LICENSE Starts */
  if (type === "assign-license") {
    if (beforeThis(currentIndex, "properties", array)) {
      return "[[property]]";
    }

    if (beforeThis(currentIndex, "communities", array)) {
      return "[[community]]";
    }

    if (beforeThis(currentIndex, "license", array)) {
      return "Assign License";
    }
  }

  /*ASSIGN LICENSE Ends */

  // MANAGE TEAMS MODULE - Start

  /* MANAGE TEAMS Start */
  if (type === "manage_teams") {
    if (beforeThis(currentIndex, "properties", array)) {
      return "[[property]]";
    }
    if (beforeThis(currentIndex, "communities", array)) {
      return "[[community]]";
    }
    if (key === "teams") {
      return "Teams";
    }
  }
  /* MANAGE TEAMS Ends */

  /* MANAGE TEAMS ADD OWNER Start */
  if (type === "manage_team_owner") {
    if (beforeThis(currentIndex, "properties", array)) {
      return "[[property]]";
    }

    if (beforeThis(currentIndex, "communities", array)) {
      return "[[community]]";
    }

    if (key === "teams") {
      return "Teams ( Owner )";
    }
  }
  /* MANAGE TEAMS ADD OWNER Ends */

  /* MANAGE TEAMS ADD OWNER SUBMEMBERS Start */
  if (type === "manage_team_owner_submember") {
    if (beforeThis(currentIndex, "properties", array)) {
      return "[[property]]";
    }

    if (beforeThis(currentIndex, "communities", array)) {
      return "[[community]]";
    }

    if (key === "teams") {
      return "Teams ( Owner )";
    }
  }
  /* MANAGE TEAMS ADD OWNER SUBMEMBERS Ends */

  /* MANAGE TEAMS ADD PMC Start */
  if (type === "manage_team_pmc") {
    if (beforeThis(currentIndex, "properties", array)) {
      return "[[property]]";
    }

    if (beforeThis(currentIndex, "communities", array)) {
      return "[[community]]";
    }

    if (key === "teams") {
      return "Teams ( PMC )";
    }
  }
  /* MANAGE TEAMS ADD PMC Ends */

  /* MANAGE TEAMS ADD PM Start */
  if (type === "manage_team_pm") {
    if (beforeThis(currentIndex, "properties", array)) {
      return "[[property]]";
    }

    if (beforeThis(currentIndex, "communities", array)) {
      return "[[community]]";
    }

    if (key === "teams") {
      return "Teams ( PM )";
    }
  }
  /* MANAGE TEAMS ADD PM Ends */

  /* MANAGE TEAMS ADD ACC Start */
  if (type === "manage_team_acc") {
    if (beforeThis(currentIndex, "properties", array)) {
      return "[[property]]";
    }

    if (beforeThis(currentIndex, "communities", array)) {
      return "[[community]]";
    }

    if (key === "teams") {
      return "Teams ( ACC )";
    }
  }
  /* MANAGE TEAMS ADD ACC Ends */

  /* MANAGE TEAMS ADD FMC Start */
  if (type === "manage_team_fmc") {
    if (beforeThis(currentIndex, "properties", array)) {
      return "[[property]]";
    }

    if (beforeThis(currentIndex, "communities", array)) {
      return "[[community]]";
    }

    if (key === "teams") {
      return "Teams ( FMC )";
    }
  }
  /* MANAGE TEAMS ADD FMC Ends */

  /* MANAGE TEAMS ADD FM Start */
  if (type === "manage_team_fm") {
    if (beforeThis(currentIndex, "properties", array)) {
      return "[[property]]";
    }

    if (beforeThis(currentIndex, "communities", array)) {
      return "[[community]]";
    }

    if (key === "teams") {
      return "Teams ( FM )";
    }
  }
  /* MANAGE TEAMS ADD FM Ends */

  /* MANAGE TEAMS ADD MT Start */
  if (type === "manage_team_mt") {
    if (beforeThis(currentIndex, "properties", array)) {
      return "[[property]]";
    }

    if (beforeThis(currentIndex, "communities", array)) {
      return "[[community]]";
    }

    if (key === "teams") {
      return "Teams ( MT )";
    }
  }
  /* MANAGE TEAMS ADD MT Ends */

  /* MANAGE TEAMS ADD SMC Start */
  if (type === "manage_team_smc") {
    if (beforeThis(currentIndex, "properties", array)) {
      return "[[property]]";
    }

    if (beforeThis(currentIndex, "communities", array)) {
      return "[[community]]";
    }

    if (key === "teams") {
      return "Teams ( SMC )";
    }
  }
  /* MANAGE TEAMS ADD SMC Ends */

  /* MANAGE TEAMS ADD SM Start */
  if (type === "manage_team_sm") {
    if (beforeThis(currentIndex, "properties", array)) {
      return "[[property]]";
    }

    if (beforeThis(currentIndex, "communities", array)) {
      return "[[community]]";
    }

    if (key === "teams") {
      return "Teams ( SM )";
    }
  }
  /* MANAGE TEAMS ADD SM Ends */

  /* MANAGE TEAMS ADD SO Start */
  if (type === "manage_team_so") {
    if (beforeThis(currentIndex, "properties", array)) {
      return "[[property]]";
    }

    if (beforeThis(currentIndex, "communities", array)) {
      return "[[community]]";
    }

    if (key === "teams") {
      return "Teams ( SO )";
    }
  }
  /* MANAGE TEAMS ADD SO Ends */

  /* MANAGE TEAMS ADD TPC Start */
  if (type === "manage_team_tpc") {
    if (beforeThis(currentIndex, "properties", array)) {
      return "[[property]]";
    }

    if (beforeThis(currentIndex, "communities", array)) {
      return "[[community]]";
    }

    if (key === "teams") {
      return "Teams ( TPC )";
    }
  }
  /* MANAGE TEAMS ADD TPC Ends */

  /* MANAGE TEAMS ADD TPM Start */
  if (type === "manage_team_tpm") {
    if (beforeThis(currentIndex, "properties", array)) {
      return "[[property]]";
    }

    if (beforeThis(currentIndex, "communities", array)) {
      return "[[community]]";
    }

    if (key === "teams") {
      return "Teams ( TPM )";
    }
  }
  /* MANAGE TEAMS ADD TPM Ends */

  /* MANAGE TEAMS ADD TPT Start */
  if (type === "manage_team_tpt") {
    if (beforeThis(currentIndex, "properties", array)) {
      return "[[property]]";
    }

    if (beforeThis(currentIndex, "communities", array)) {
      return "[[community]]";
    }

    if (key === "teams") {
      return "Teams ( TPT )";
    }
  }
  /* MANAGE TEAMS ADD TPT Ends */

  // MANAGE TEAMS MODULE - END

  // MANAGE TENANTS MODULE - Start

  /* MANAGE TENANTS Start */
  if (type === "manage_tenants") {
    if (beforeThis(currentIndex, "properties", array)) {
      return "[[property]]";
    }

    if (beforeThis(currentIndex, "communities", array)) {
      return "[[community]]";
    }

    if (key === "tenants") {
      return "Manage Tenants";
    }
  }
  /* MANAGE TENANTS Ends */

  /* MANAGE TENANTS EXISTING DETAILS Start */

  if (type === "exixting_tenant_detail") {
    if (beforeThis(currentIndex, "properties", array)) {
      return "[[property]]";
    }

    if (beforeThis(currentIndex, "communities", array)) {
      return "[[community]]";
    }

    if (beforeThis(currentIndex, "unit", array)) {
      return "Tenant Detail";
    }

    //&&  beforeThree(currentIndex, "detail", array)
    if (key === "existing" || key === "previous-tenants") {
      return `Manage Tenants (${key === "existing" ? "Existing" : "Previous"})`;
    }

    // if(key === 'previous-tenants'){}
  }

  /* MANAGE TENANTS EXISTING DETAILS Ends */

  /* MANAGE TENANTS TRANSITIONED DETAILS Start */

  if (type === "transitioned_tenant_application_review") {
    if (beforeThis(currentIndex, "properties", array)) {
      return "[[property]]";
    }

    if (beforeThis(currentIndex, "communities", array)) {
      return "[[community]]";
    }

    if (beforeThis(currentIndex, "transitioned", array)) {
      return "Transitioned Tenant Detail";
    }

    if (key === "transitioned") {
      return "Manage Tenants ( Transitioned )";
    }
  }

  /* MANAGE TENANTS TRANSITIONED DETAILS Ends */

  /* Onboarding Application PM Review Start */
  if (type === "onboard_application_pm_review") {
    if (beforeThis(currentIndex, "properties", array)) {
      return "[[property]]";
    }
    if (beforeThis(currentIndex, "new", array)) {
      return "Onboarding Application";
    }

    if (beforeThis(currentIndex, "communities", array)) {
      return "[[community]]";
    }

    if (key === "new") {
      return "Manage Tenants ( New )";
    }
  }
  /* Onboarding Application PM Review Ends */

  /* MANAGE CANCELLED TENANTS DETAILS Start */

  if (type === "onboard_application_pm_review") {
    if (beforeThis(currentIndex, "properties", array)) {
      return "[[property]]";
    }
    if (beforeThis(currentIndex, "cancelled", array)) {
      return "Cancelled Application";
    }

    if (beforeThis(currentIndex, "communities", array)) {
      return "[[community]]";
    }

    if (key === "cancelled") {
      return "Manage Tenants ( Cancelled )";
    }
  }

  /* MANAGE CANCELLED TENANTS DETAILS Ends */

  /* MANAGE TENANTS MOVE OUT DETAILS Start */

  if (type === "moveout_tenant_detail") {
    if (beforeThis(currentIndex, "properties", array)) {
      return "[[property]]";
    }

    if (beforeThis(currentIndex, "communities", array)) {
      return "[[community]]";
    }

    if (
      //array[currentIndex] === 'existing' &&
      beforeThis(currentIndex, "move-out", array)
    ) {
      return "Move Out Tenant Detail";
    }

    if (key === "move-out" || key === "tenants") {
      return "Manage Tenants ( Move Out )";
    }
  }

  /* MANAGE TENANTS MOVE OUT DETAILS Ends */

  /* MANAGE TENANTS RENEWALS DETAILS Start */

  if (type === "renewal_tenant_detail") {
    if (beforeThis(currentIndex, "properties", array)) {
      return "[[property]]";
    }

    if (beforeThis(currentIndex, "communities", array)) {
      return "[[community]]";
    }

    if (
      //array[currentIndex] === 'existing' &&
      beforeThis(currentIndex, "renewals", array)
    ) {
      return "Renewal Tenant Detail";
    }

    if (key === "renewals") {
      return "Manage Tenants ( Renewals )";
    }
  }

  /* MANAGE TENANTS RENEWALS DETAILS Ends */

  // MANAGE TENANTS MODULE - END

  // MAINTENANCE MODULE - START

  /* MANAGE MAINTENANCES Starts */
  if (type === "manage_maintenance") {
    if (beforeThis(currentIndex, "properties", array)) {
      return "[[property]]";
    }

    if (beforeThis(currentIndex, "communities", array)) {
      return "[[community]]";
    }
  }

  /* MANAGE MAINTENANCES Ends */

  /* MANAGE MAINTENANCES DETAIL Starts */
  if (type === "maintenance_detail") {
    if (beforeThis(currentIndex, "properties", array)) {
      return "[[property]]";
    }

    if (beforeThis(currentIndex, "communities", array)) {
      return "[[community]]";
    }

    if (
      beforeThis(currentIndex, "New", array) ||
      beforeThis(currentIndex, "Completed", array) ||
      beforeThis(currentIndex, "Closed", array) ||
      beforeThis(currentIndex, "Move%20In", array) ||
      beforeThis(currentIndex, "Move%20Out", array) ||
      beforeThis(currentIndex, "Spare%20Parts", array) ||
      beforeThis(currentIndex, "Ongoing", array) ||
      beforeThis(currentIndex, "Common%20Area", array) ||
      beforeThis(currentIndex, "Units", array) ||
      beforeThis(currentIndex, "Rejected", array)
    ) {
      return "Maintenance Detail";
    }

    if (key === "New") {
      return "Maintenances ( New )";
    }

    if (key === "Ongoing") {
      return "Maintenances ( Ongoing )";
    }

    if (key === "Spare%20Parts") {
      return "Maintenances ( Spare Parts )";
    }

    if (key === "Units") {
      return "Maintenances ( Units )";
    }

    if (key === "Move%20Out") {
      return "Maintenances ( Move Out )";
    }

    if (key === "Move%20In") {
      return "Maintenances ( Move In )";
    }

    if (key === "Completed") {
      return "Maintenances ( Completed )";
    }

    if (key === "Rejected") {
      return "Maintenances ( Rejected )";
    }

    if (key === "Closed") {
      return "Maintenances ( Closed )";
    }

    if (key === "Common%20Area") {
      return "Maintenances ( Common Area )";
    }
    if (from === "move-out") {
      if (beforeThis(currentIndex, "move-out", array)) {
        return "Move Out Review";
      }
      if (beforeThis(currentIndex, "maintenance", array)) {
        return "Maintenance Detail";
      }
      if (key === "tenants") {
        return `Manage Tenant ( ${key} )`;
      }
    }

    if (key === "teams" || key === "tenants") {
      return `RatingAndReviews ( ${key} )`;
    }

    // if (key === "tenant") {
    //   return "RatingAndReviews ( Tenants )";
    // }
  }

  /* MANAGE MAINTENANCES DETAIL Ends */

  /* MANAGE MAINTENANCES MOVE OUT DETAIL Starts */
  // if (type === "maintenance_detail_moveOut") {
  //   if (beforeThis(currentIndex, "properties", array)) {
  //     return "[[property]]";
  //   }

  //   if (beforeThis(currentIndex, "Move%20In", array)) {
  //     return "Maintenance Detail";
  //   }

  //   if(key === "Move%20In"){
  //     return 'Maintenances'
  //   }
  // }

  /* MANAGE MAINTENANCES MOVE OUT DETAIL Ends */

  // MAINTENANCE MODULE - END

  // INTER MAINTENANCE MODULE - START

  /* INTER MAINTENANCES Starts */
  if (type === "internal_maintenance") {
    if (beforeThis(currentIndex, "properties", array)) {
      return "[[property]]";
    }

    if (beforeThis(currentIndex, "communities", array)) {
      return "[[community]]";
    }
  }

  /* INTER MAINTENANCES Ends */

  /* INTER MAINTENANCES DETAIL Starts */
  if (type === "inter-main-details") {
    if (beforeThis(currentIndex, "properties", array)) {
      return "[[property]]";
    }

    if (beforeThis(currentIndex, "communities", array)) {
      return "[[community]]";
    }

    if (
      beforeThis(currentIndex, "new", array) ||
      beforeThis(currentIndex, "ongoing", array) ||
      beforeThis(currentIndex, "spareParts", array) ||
      beforeThis(currentIndex, "completed", array) ||
      beforeThis(currentIndex, "closed", array) ||
      beforeThis(currentIndex, "revision", array) ||
      beforeThis(currentIndex, "rejected", array)
    ) {
      return "Internal Maintenance Detail";
    }

    if (key === "new") {
      return "Internal Maintenances ( New )";
    }

    if (key === "ongoing") {
      return "Internal Maintenances ( Ongoing )";
    }

    if (key === "spareParts") {
      return "Internal Maintenances ( Spare Parts )";
    }

    if (key === "completed") {
      return "Internal Maintenances ( Completed )";
    }

    if (key === "closed") {
      return "Internal Maintenances ( Closed )";
    }

    if (key === "rejected") {
      return "Internal Maintenances ( Rejected )";
    }

    if (key === "revision") {
      return "Internal Maintenances ( Revision )";
    }
  }

  /* INTER MAINTENANCES DETAIL Ends */

  /* INTER MAINTENANCES ADD Starts */
  if (type === "internal_maintenance_add") {
    if (beforeThis(currentIndex, "properties", array)) {
      return "[[property]]";
    }

    if (beforeThis(currentIndex, "communities", array)) {
      return "[[community]]";
    }

    if (key === "new") {
      return "Internal Maintenances";
    }

    // if (beforeThis(currentIndex, "New", array)) {
    //   return "Maintenance Detail";
    // }
  }

  /* INTER MAINTENANCES ADD Ends */

  // INTER MAINTENANCE MODULE - END

  // INVOICING MODULE

  /* Invoicing Start */
  if (type === "client_invoicing") {
    if (beforeThis(currentIndex, "properties", array)) {
      return "[[property]]";
    }

    if (beforeThis(currentIndex, "communities", array)) {
      return "[[community]]";
    }

    // if (beforeThis(currentIndex, "units", array)) {
    //   return "Units";
    // }
    // if (
    //   key === "Tenant" ||
    //   key === "Property%20Invoice" ||
    //   key === "Party%20Invoice"
    //   // ||
    //   // key === "deposits" ||
    //   // key === "application" ||
    //   // key === "commission" ||
    //   // key === "others" ||
    //   // key === "utilities" ||
    //   // key === "spare-parts" ||
    //   // key === "contracts"
    // ) {
    //   return `Invoicing (${key})`;
    // }
  }
  /* Invoicing Ends */

  /* Invoicing Add Start */
  if (type === "client_add_invoice") {
    if (beforeThis(currentIndex, "properties", array)) {
      return "[[property]]";
    }

    if (beforeThis(currentIndex, "communities", array)) {
      return "[[community]]";
    }

    if (beforeThis(currentIndex, "unit", array)) {
      return "Existing Tenant Detail";
    }

    // if (key === 'add' && beforeThis(currentIndex, "Properties", array) ) {
    //   return "Add Property Invoice";
    // }

    if (key === "add") {
      return "Add Invoice";
    }

    if (
      key === "rent" ||
      key === "facility" ||
      key === "utility" ||
      key === "deposits" ||
      key === "application" ||
      key === "commission" ||
      key === "others" ||
      key === "utilities" ||
      key === "spare-parts" ||
      key === "contracts"
    ) {
      return `Invoicing (${key})`;
    }

    if (
      from === "accountant-clearence" &&
      beforeThis(currentIndex, "application", array)
    ) {
      return "Accountant Clearence";
    }
  }
  /* Invoicing Add Ends */

  /* Invoicing Details Start */
  if (type === "client_invoicing_detail") {
    if (beforeThis(currentIndex, "properties", array)) {
      return "[[property]]";
    }

    if (beforeThis(currentIndex, "communities", array)) {
      return "[[community]]";
    }

    if (
      beforeThis(currentIndex, "rent", array) ||
      beforeThis(currentIndex, "deposits", array) ||
      beforeThis(currentIndex, "others", array) ||
      beforeThis(currentIndex, "application", array) ||
      beforeThis(currentIndex, "commission", array) ||
      beforeThis(currentIndex, "facility", array) ||
      beforeThis(currentIndex, "utility", array) ||
      beforeThis(currentIndex, "others", array) ||
      beforeThis(currentIndex, "utilities", array) ||
      beforeThis(currentIndex, "spare-parts", array) ||
      beforeThis(currentIndex, "contracts", array)
    ) {
      return "Invoice Detail";
    }

    // if (
    //   beforeThis(currentIndex, "others", array) ||
    //   beforeThis(currentIndex, "utilities", array)
    // ) {
    //   return "Invoice Detail";
    // }

    if (
      key === "rent" ||
      key === "facility" ||
      key === "utility" ||
      key === "deposits" ||
      key === "application" ||
      key === "commission" ||
      key === "others" ||
      key === "utilities" ||
      key === "contracts" ||
      key === "spare-parts"
    ) {
      return `Invoicing (${key})`;
    }
    if (from === "onboard_application") {
      if (key === "new") {
        return "Manage Tenants ( New )";
      }
      if (beforeThis(currentIndex, "new", array)) {
        return "Onboarding Application";
      }
    }
  }
  /* Invoicing Details Ends */

  // INVOICING MODULE

  // VOILATION MODULE

  /* Violations Start */
  if (type === "client_violation") {
    if (beforeThis(currentIndex, "properties", array)) {
      return "[[property]]";
    }

    if (beforeThis(currentIndex, "communities", array)) {
      return "[[community]]";
    }
  }
  /* Violations Ends */

  /* Violations Start ADD */
  if (type === "client_violation_add") {
    if (beforeThis(currentIndex, "properties", array)) {
      return "[[property]]";
    }

    if (beforeThis(currentIndex, "communities", array)) {
      return "[[community]]";
    }

    if (key === "tenants" || key === "teams" || key === "others") {
      return `Violation ( ${key} )`;
    }

    if (beforeThree(currentIndex, "detail", array)) {
      return "Add";
    }
  }
  /* Violations Ends */

  // VOILATION MODULE

  // ANNOUCEMENT MODULE

  /* ANNOUCEMENT Start */
  if (type === "client_annoucement") {
    if (beforeThis(currentIndex, "properties", array)) {
      return "[[property]]";
    }

    if (beforeThis(currentIndex, "communities", array)) {
      return "[[community]]";
    }

    // if (beforeThis(currentIndex, "units", array)) {
    //   return "Units";
    // }
  }
  /* ANNOUCEMENT Ends */

  /* ANNOUCEMENT ADD Start */
  if (type === "client_annoucement_add") {
    if (beforeThis(currentIndex, "properties", array)) {
      return "[[property]]";
    }

    if (beforeThis(currentIndex, "communities", array)) {
      return "[[community]]";
    }

    if (key === "add") {
      return "Add";
    }
  }
  /* ANNOUCEMENT ADD Ends */

  /* ANNOUCEMENT Start */
  if (type === "client_annoucement_detail") {
    if (beforeThis(currentIndex, "properties", array)) {
      return "[[property]]";
    }

    if (beforeThis(currentIndex, "communities", array)) {
      return "[[community]]";
    }

    if (beforeThis(currentIndex, "annoucements", array)) {
      return "Annoucement Detail";
    }
  }
  /* ANNOUCEMENT Ends */

  // ANNOUCEMENT MODULE

  // LOST & FOUND MODULE

  /* LOST & FOUND Start */
  if (type === "client_lostAndFound") {
    if (beforeThis(currentIndex, "properties", array)) {
      return "[[property]]";
    }

    if (beforeThis(currentIndex, "communities", array)) {
      return "[[community]]";
    }

    if (beforeThis(currentIndex, "lost_and_found", array)) {
      return "Lost And Found";
    }

    // return true;
  }
  /* LOST & FOUND Ends */

  /* LOST & FOUND ADD Start */
  if (type === "client_lostAndFound_add") {
    if (beforeThis(currentIndex, "properties", array)) {
      return "[[property]]";
    }

    if (beforeThis(currentIndex, "communities", array)) {
      return "[[community]]";
    }

    if (key === "found") {
      return "Lost And Found";
    }
  }
  /* LOST & FOUND ADD Ends */

  /* FOUND DETAILS Start */
  if (type === "client_found_detail") {
    if (beforeThis(currentIndex, "properties", array)) {
      return "[[property]]";
    }

    if (beforeThis(currentIndex, "communities", array)) {
      return "[[community]]";
    }

    if (beforeThis(currentIndex, "found", array)) {
      return "Found Item Detail";
    }

    if (key === "found") {
      return "Lost And Found ( Found )";
    }
  }
  /* FOUND DETAILS Ends */

  /* LOST DETAILS Start */
  if (type === "client_lost_detail") {
    if (beforeThis(currentIndex, "properties", array)) {
      return "[[property]]";
    }

    if (beforeThis(currentIndex, "communities", array)) {
      return "[[community]]";
    }

    if (beforeThis(currentIndex, "lost", array)) {
      return "Lost Item Detail";
    }

    if (key === "lost") {
      return "Lost And Found ( Lost )";
    }
  }
  /* LOST DETAILS Ends */

  /* CLAIM DETAILS Start */
  if (type === "client_claim_detail") {
    if (beforeThis(currentIndex, "properties", array)) {
      return "[[property]]";
    }

    if (beforeThis(currentIndex, "communities", array)) {
      return "[[community]]";
    }

    if (beforeThis(currentIndex, "claimed", array)) {
      return "Lost Item Detail";
    }

    if (key === "claimed") {
      return "Lost And Found ( Claimed )";
    }
  }
  /* CLAIM DETAILS Ends */

  // LOST & FOUND MODULE

  // SECURITY MODULE

  /* SECURITY Start */
  if (type === "client_security") {
    if (beforeThis(currentIndex, "properties", array)) {
      return "[[property]]";
    }

    if (beforeThis(currentIndex, "communities", array)) {
      return "[[community]]";
    }

    if (beforeThis(currentIndex, "security", array)) {
      return "Security";
    }

    // return true;
  }
  /* SECURITY Ends */

  /* SECURITY ADD Start */
  if (type === "security_incident_add") {
    if (beforeThis(currentIndex, "properties", array)) {
      return "[[property]]";
    }

    if (beforeThis(currentIndex, "communities", array)) {
      return "[[community]]";
    }

    if (key === "open") {
      return "Security";
    }

    // return true;
  }
  /* SECURITY ADD Ends */

  /* SECURITY INCIDENT DETAILS Start */
  if (type === "security_incident_detail") {
    if (beforeThis(currentIndex, "properties", array)) {
      return "[[property]]";
    }

    if (beforeThis(currentIndex, "communities", array)) {
      return "[[community]]";
    }

    if (beforeThis(currentIndex, "open", array)) {
      return "Security Incident Detail";
    }

    if (key === "open") {
      return "Security ( Incidents > Open )";
    }

    if (beforeThis(currentIndex, "incidents", array)) {
      return "Security Incident Detail";
    }

    if (key === "incidents" && beforeThis(currentIndex, "closed", array)) {
      return "Security ( Incidents > Closed )";
    }

    // return true;
  }
  /* SECURITY INCIDENT DETAILS Ends */

  /* SECURITY COMPLAINTS DETAILS Start */
  if (type === "security_complain_detail") {
    if (beforeThis(currentIndex, "properties", array)) {
      return "[[property]]";
    }

    if (beforeThis(currentIndex, "communities", array)) {
      return "[[community]]";
    }

    if (beforeThis(currentIndex, "open", array)) {
      return "Security Complain Detail";
    }

    if (key === "open") {
      return "Security ( Complaints > Open )";
    }

    if (beforeThis(currentIndex, "complaints", array)) {
      return "Security Incident Detail";
    }

    if (key === "complaints" && beforeThis(currentIndex, "closed", array)) {
      return "Security ( Complaints > Closed )";
    }

    // return true;
  }
  /* SECURITY COMPLAINTS DETAILS Ends */

  // SECURITY MODULE

  // NORMAL USER SETTING - START
  /* NORMAL USER SETTING Start */
  if (type === "settings_page") {
    if (beforeThis(currentIndex, "properties", array)) {
      return "[[property]]";
    }

    if (beforeThis(currentIndex, "communities", array)) {
      return "[[community]]";
    }

    if (key === "settings") {
      return "Settings";
    }
  }
  /* NORMAL USER SETTING Ends */

  /* NORMAL USER SETTING Start */
  if (type === "notification_page") {
    if (beforeThis(currentIndex, "properties", array)) {
      return "[[property]]";
    }

    if (beforeThis(currentIndex, "communities", array)) {
      return "[[community]]";
    }

    if (key === "notification") {
      return "Notifications";
    }
  }
  /* NORMAL USER SETTING Ends */

  /* NORMAL USER MANAGE LICENSE SETTING Start */
  if (type === "settings_manageLicense_page") {
    if (beforeThis(currentIndex, "properties", array)) {
      return "[[property]]";
    }

    if (beforeThis(currentIndex, "communities", array)) {
      return "[[community]]";
    }

    if (
      //beforeThis(currentIndex, "cards", array)
      key === "manageLicense"
    ) {
      return "Manage Licenses";
    }

    if (key === "settings") {
      return "Settings";
    }
  }
  /* NORMAL USER MANAGE LICENSE  SETTING Ends */

  /* NORMAL USER MANAGE PURCHASE LICENSE SETTING Start */
  if (type === "settings_manageLicense_purchase_page") {
    if (beforeThis(currentIndex, "properties", array)) {
      return "[[property]]";
    }

    if (beforeThis(currentIndex, "communities", array)) {
      return "[[community]]";
    }

    if (key === "manageLicense") {
      return "Manage Licenses";
    }

    if (key === "purchase") {
      return "Purchase License";
    }

    if (key === "settings") {
      return "Settings";
    }
  }
  /* NORMAL USER MANAGE PURCHASE LICENSE SETTING Ends */

  /* NORMAL USER MANAGE LICENSE INVOICE SETTING Start */
  if (type === "settings_manageLicense_invoice_page") {
    if (beforeThis(currentIndex, "properties", array)) {
      return "[[property]]";
    }

    if (beforeThis(currentIndex, "communities", array)) {
      return "[[community]]";
    }

    if (
      //beforeThis(currentIndex, "cards", array)
      key === "manageLicense"
    ) {
      return "Manage Licenses";
    }

    if (key === "invoices") {
      return "License Invoices";
    }

    if (key === "settings") {
      return "Settings";
    }
  }
  /* NORMAL USER MANAGE LICENSE INVOICE SETTING Ends */

  /* NORMAL USER MANAGE LICENSE INVOICE DETAIL SETTING Start */
  if (type === "settings_manageLicense_invoice_detail") {
    if (beforeThis(currentIndex, "properties", array)) {
      return "[[property]]";
    }

    if (beforeThis(currentIndex, "communities", array)) {
      return "[[community]]";
    }

    if (
      //beforeThis(currentIndex, "cards", array)
      key === "manageLicense"
    ) {
      return "Manage Licenses";
    }

    if (beforeThis(currentIndex, "invoices", array)) {
      return "License Invoice Detail";
    }

    if (key === "invoices") {
      return "License Invoices";
    }

    if (key === "settings") {
      return "Settings";
    }
  }
  /* NORMAL USER MANAGE LICENSE INVOICE DETAIL SETTING Ends */

  /* NORMAL USER CARD SETTING Start */
  if (type === "settings_card_page") {
    if (beforeThis(currentIndex, "properties", array)) {
      return "[[property]]";
    }

    if (beforeThis(currentIndex, "communities", array)) {
      return "[[community]]";
    }

    if (
      //beforeThis(currentIndex, "cards", array)
      key === "manageCard"
    ) {
      return "Manage Cards";
    }

    if (key === "settings") {
      return "Settings";
    }
  }
  /* NORMAL USER CARD SETTING Ends */

  /* NORMAL USER PAYMENT SETTING Start */
  if (type === "settings_payment_page") {
    if (beforeThis(currentIndex, "properties", array)) {
      return "[[property]]";
    }

    if (beforeThis(currentIndex, "communities", array)) {
      return "[[community]]";
    }

    if (
      beforeThis(currentIndex, "settings", array)
      // && key === "manage"
    ) {
      return "Payment Settings";
    }

    if (key === "settings") {
      return "Settings";
    }
  }
  /* NORMAL USER PAYMENT SETTING Ends */

  /* NORMAL USER EDIT PAYMENT SETTING Start */
  if (type === "settings_payment_edit_page") {
    if (beforeThis(currentIndex, "properties", array)) {
      return "[[property]]";
    }

    if (beforeThis(currentIndex, "communities", array)) {
      return "[[community]]";
    }

    if (beforeThis(currentIndex, "edit", array) && key === "payment") {
      return "Payment Settings";
    }

    if (key === "settings") {
      return "Settings";
    }

    if (key === "payment") {
      return "Payment Settings";
    }
  }
  /* NORMAL USER PAYMENT SETTING Ends */

  /* NORMAL USER CHANGE PASS SETTING Start */
  if (type === "settings_changePass_page") {
    if (beforeThis(currentIndex, "properties", array)) {
      return "[[property]]";
    }

    if (beforeThis(currentIndex, "communities", array)) {
      return "[[community]]";
    }

    if (beforeThis(currentIndex, "password", array)) {
      return "Change Password";
    }

    if (key === "settings") {
      return "Settings";
    }
  }
  /* NORMAL USER CHANGE PASS SETTING Ends */

  /* NORMAL USER CHANGE EMAIL SETTING Start */
  if (type === "settings_changeEmail_page") {
    if (beforeThis(currentIndex, "properties", array)) {
      return "[[property]]";
    }

    if (beforeThis(currentIndex, "communities", array)) {
      return "[[community]]";
    }

    if (key === "update-email") {
      return "Update Email";
    }

    if (key === "profile") {
      return "My Profile";
    }
  }
  /* NORMAL USER CHANGE EMAIL SETTING Ends */

  /* NORMAL USER CHANGE MOBILE SETTING Start */
  if (type === "settings_changeMobile_page") {
    if (beforeThis(currentIndex, "properties", array)) {
      return "[[property]]";
    }

    if (beforeThis(currentIndex, "communities", array)) {
      return "[[community]]";
    }

    if (key === "update-mobile") {
      return "Update Mobile";
    }

    if (key === "profile") {
      return "My Profile";
    }
  }
  /* NORMAL USER CHANGE MOBILE SETTING Ends */

  /* NORMAL USER CHANGE THEME SETTING Start */
  if (type === "settings_changeTheme_page") {
    if (beforeThis(currentIndex, "properties", array)) {
      return "[[property]]";
    }

    if (beforeThis(currentIndex, "communities", array)) {
      return "[[community]]";
    }

    if (beforeThis(currentIndex, "settings", array)) {
      return "Change Theme";
    }

    if (key === "settings") {
      return "Settings";
    }
  }
  /* NORMAL USER CHANGE THEME SETTING Ends */

  /* NORMAL USER Account SETTING Start */
  if (type === "settings_account_page") {
    if (beforeThis(currentIndex, "properties", array)) {
      return "[[property]]";
    }

    if (beforeThis(currentIndex, "communities", array)) {
      return "[[community]]";
    }

    if (beforeThis(currentIndex, "settings", array)) {
      return "Account Suspension";
    }

    if (key === "settings") {
      return "Settings";
    }
  }
  /* NORMAL USER CHANGE THEME SETTING Ends */

  /* NORMAL USER HELP & SUPPORT Start */
  if (type === "settings_help_page") {
    if (beforeThis(currentIndex, "properties", array)) {
      return "[[property]]";
    }

    if (beforeThis(currentIndex, "communities", array)) {
      return "[[community]]";
    }

    if (
      //beforeThis(currentIndex, "settings", array)
      key === "helpAndSupport"
    ) {
      return "Dgrnte Support";
    }

    if (key === "settings") {
      return "Settings";
    }
  }
  /* NORMAL USER HELP & SUPPORT Ends */

  /* NORMAL USER FAQs Start */
  if (type === "settings_FAQs_page") {
    if (beforeThis(currentIndex, "properties", array)) {
      return "[[property]]";
    }

    if (beforeThis(currentIndex, "communities", array)) {
      return "[[community]]";
    }

    if (key === "settings") {
      return "Settings";
    }
  }
  /* NORMAL USER FAQs Ends */

  // NORMAL USER SETTING - END

  /* PROPERTY SETTING ADD Start */

  // PROPERTY SETTING MODULE

  /* PROPERTY SETTING Start */
  if (type === "property_setting") {
    if (beforeThis(currentIndex, "properties", array)) {
      return "[[property]]";
    }

    if (beforeThis(currentIndex, "communities", array)) {
      return "[[community]]";
    }

    if (
      //beforeThis(currentIndex, "settings", array)
      key === "settings"
    ) {
      return "Property Settings";
    }
  }
  /* PROPERTY SETTING Ends */

  /* PROPERTY SETTING ADD Start */
  if (type === "property_setting_template") {
    if (beforeThis(currentIndex, "properties", array)) {
      return "[[property]]";
    }

    if (beforeThis(currentIndex, "communities", array)) {
      return "[[community]]";
    }

    if (
      //beforeThis(currentIndex, "settings", array)
      // key === "templates" &&
      // key === "initial_agreement"
      key === "settings"
    ) {
      return "Settings";
    }

    // return true;
  }
  /* PROPERTY SETTING ADD Ends */

  // PROPERTY SETTING MODULE

  // DASHBOARD MODULE - Start

  /* DASHBOARD Start */
  if (type === "dashboards") {
    if (
      beforeThis(currentIndex, "properties", array)
      //&& key === 'dashboard'
    ) {
      return "[[property]]";
    }

    if (beforeThis(currentIndex, "communities", array)) {
      return "[[community]]";
    }

    // if( beforeThis(currentIndex, "home", array)  || beforeThis(currentIndex, "provider", array)  ){
    //   return 'Home'
    // }

    // if(key === "provider" && key === "home"){
    //   return 'Properties'
    // }
  }
  /* DASHBOARD Ends */

  /* DASHBOARD OCCUPANCY Start */
  if (type === "dashboard_occupancy") {
    if (beforeThis(currentIndex, "properties", array)) {
      return "[[property]]";
    }

    if (beforeThis(currentIndex, "communities", array)) {
      return "[[community]]";
    }
  }
  /* DASHBOARD OCCUPANCY Ends */

  /* DASHBOARD CONTRACTS Start */
  if (type === "dashboard_contracts") {
    if (beforeThis(currentIndex, "properties", array)) {
      return "[[property]]";
    }

    if (beforeThis(currentIndex, "communities", array)) {
      return "[[community]]";
    }
  }
  /* DASHBOARD CONTRACTS Ends */

  /* DASHBOARD FINANCIAL Start */
  if (type === "dashboard_financial") {
    if (beforeThis(currentIndex, "properties", array)) {
      return "[[property]]";
    }

    if (beforeThis(currentIndex, "communities", array)) {
      return "[[community]]";
    }
  }
  /* DASHBOARD FINANCIAL Ends */

  /* DASHBOARD TENANT MAINTENANCE Start */
  if (type === "dashboard_tenant_main") {
    if (beforeThis(currentIndex, "properties", array)) {
      return "[[property]]";
    }
    if (beforeThis(currentIndex, "communities", array)) {
      return "[[community]]";
    }
  }
  /* DASHBOARD TENANT MAINTENANCE Ends */

  /* DASHBOARD INTERNAL MAINTENANCE Start */
  if (type === "dashboard_internal_main") {
    if (beforeThis(currentIndex, "properties", array)) {
      return "[[property]]";
    }
    if (beforeThis(currentIndex, "communities", array)) {
      return "[[community]]";
    }
  }
  /* DASHBOARD INTERNAL MAINTENANCE Ends */

  /* DASHBOARD SECURITY Start */
  if (type === "dashboard_security") {
    if (beforeThis(currentIndex, "properties", array)) {
      return "[[property]]";
    }
    if (beforeThis(currentIndex, "communities", array)) {
      return "[[community]]";
    }
  }
  /* DASHBOARD SECURITY Ends */

  /* DASHBOARD SPARE PARTS Start */
  if (type === "dashboard_spare") {
    if (beforeThis(currentIndex, "properties", array)) {
      return "[[property]]";
    }
    if (beforeThis(currentIndex, "communities", array)) {
      return "[[community]]";
    }
  }
  /* DASHBOARD SPARE PARTS Ends */

  /* DASHBOARD RATINGS Start */
  if (type === "dashboard_rating") {
    if (beforeThis(currentIndex, "properties", array)) {
      return "[[property]]";
    }
    if (beforeThis(currentIndex, "communities", array)) {
      return "[[community]]";
    }
  }
  /* DASHBOARD RATINGS Ends */

  /* DASHBOARD TEAMS Start */
  if (type === "dashboard_teams") {
    if (beforeThis(currentIndex, "properties", array)) {
      return "[[property]]";
    }
    if (beforeThis(currentIndex, "communities", array)) {
      return "[[community]]";
    }
  }
  /* DASHBOARD TEAMS Ends */

  /* DASHBOARD VIOLATIONS Start */
  if (type === "dashboard_violation") {
    if (beforeThis(currentIndex, "properties", array)) {
      return "[[property]]";
    }
    if (beforeThis(currentIndex, "communities", array)) {
      return "[[community]]";
    }
  }
  /* DASHBOARD VIOLATIONS Ends */

  /* DASHBOARD MOVE IN Start */
  if (type === "dashboard_moveIn") {
    if (beforeThis(currentIndex, "properties", array)) {
      return "[[property]]";
    }
    if (beforeThis(currentIndex, "communities", array)) {
      return "[[community]]";
    }
  }
  /* DASHBOARD MOVE IN Ends */

  /* DASHBOARD MOVE OUT Start */
  if (type === "dashboard_moveOut") {
    if (beforeThis(currentIndex, "properties", array)) {
      return "[[property]]";
    }
    if (beforeThis(currentIndex, "communities", array)) {
      return "[[community]]";
    }
  }
  /* DASHBOARD MOVE OUT Ends */

  // DASHBOARD MODULE - End

  // RATING & REVIEW MODULE

  /* RATING & REVIEW Start */
  if (type === "ratingAndReview") {
    if (beforeThis(currentIndex, "properties", array)) {
      return "[[property]]";
    }

    if (beforeThis(currentIndex, "communities", array)) {
      return "[[community]]";
    }
  }
  /* RATING & REVIEW Ends */

  // ROUTINE REQUEST MODULE

  /* ROUTINE REQUEST Start */
  if (type === "routineRequests") {
    if (beforeThis(currentIndex, "properties", array)) {
      return "[[property]]";
    }

    if (beforeThis(currentIndex, "communities", array)) {
      return "[[community]]";
    }

    if (key === "routine-request") {
      return "Routine Requests";
    }
  }
  /* ROUTINE REQUEST Ends */

  /* ROUTINE REQUEST ADD Start */
  if (type === "add_tasks") {
    if (beforeThis(currentIndex, "properties", array)) {
      return "[[property]]";
    }

    if (beforeThis(currentIndex, "communities", array)) {
      return "[[community]]";
    }

    if (key === "active") {
      return "Routine Requests ( Active )";
    }
  }
  /* ROUTINE REQUEST ADD Ends */

  /* ROUTINE REQUEST EDIT Start */
  if (type === "edit_routine_task") {
    if (beforeThis(currentIndex, "properties", array)) {
      return "[[property]]";
    }

    if (beforeThis(currentIndex, "communities", array)) {
      return "[[community]]";
    }

    if (key === "active") {
      return "Routine Requests ( Active )";
    }

    // if (beforeThis(currentIndex, "edit", array) && key === "edit") {
    //   return "Routine Detail";
    // }
  }
  /* ROUTINE REQUEST EDIT Ends */

  /* ROUTINE REQUEST DETAILS Start */
  if (type === "routine_task_details") {
    if (beforeThis(currentIndex, "properties", array)) {
      return "[[property]]";
    }

    if (beforeThis(currentIndex, "communities", array)) {
      return "[[community]]";
    }

    if (key === "active") {
      return "Routine Requests ( Active )";
    }

    if (key === "expired") {
      return "Routine Requests ( Expired )";
    }

    if (key === "open") {
      return "Routine Requests ( Open )";
    }

    if (key === "closed") {
      return "Routine Requests ( Closed )";
    }

    // if()
    if (
      beforeThis(currentIndex, "open", array) ||
      beforeThis(currentIndex, "closed", array) ||
      beforeThis(currentIndex, "active", array) ||
      beforeThis(currentIndex, "expired", array)
    ) {
      return "Routine Task Detail";
    }
  }

  /* ROUTINE REQUEST DETAILS End */

  // VIOLATION MODULE
  if (type === "client_violation_detail") {
    if (beforeThis(currentIndex, "properties", array)) {
      return "[[property]]";
    }

    if (beforeThis(currentIndex, "communities", array)) {
      return "[[community]]";
    }

    if (key === "tenants" || key === "teams" || key === "other") {
      return `Violations ( ${key} )`;
    }

    if (
      beforeThis(currentIndex, "tenants", array) ||
      beforeThis(currentIndex, "teams", array) ||
      beforeThis(currentIndex, "othetrs", array)
    ) {
      return "Violation Detail";
    }
  }

  // RATING & REVIEW MODULE

  // ACCESS PASS MODULE

  /* ACCESS PASS Start */
  if (type === "acess_pass") {
    if (beforeThis(currentIndex, "properties", array)) {
      return "[[property]]";
    }
    if (beforeThis(currentIndex, "communities", array)) {
      return "[[community]]";
    }
  }
  /* ACCESS PASS Ends */

  /* ACCESS PASS DETAILS Start */
  if (type === "contractor_access_detail") {
    if (beforeThis(currentIndex, "properties", array)) {
      return "[[property]]";
    }
    if (beforeThis(currentIndex, "communities", array)) {
      return "[[community]]";
    }
    if (beforeThis(currentIndex, "contractors", array)) {
      return "Access Pass Detail";
    }

    if (key === "contractors") {
      return "Visitor Access Pass ( Contractors )";
    }
  }
  /* ACCESS PASS DETAILS Ends */

  /* ACCESS PASS VISITOR DETAILS Start */
  if (type === "visitor_access_detail") {
    if (beforeThis(currentIndex, "properties", array)) {
      return "[[property]]";
    }
    if (beforeThis(currentIndex, "communities", array)) {
      return "[[community]]";
    }
    if (beforeThis(currentIndex, "visitors", array)) {
      return "Access Pass Detail";
    }

    if (key === "visitors") {
      return "Visitor Access Pass ( Visitors )";
    }
  }
  /* ACCESS PASS VISITOR DETAILS Ends */

  // ACCESS PASS MODULE

  // LOG BOOK MODULE

  /* LOG BOOK Start */
  if (type === "log_book") {
    if (beforeThis(currentIndex, "properties", array)) {
      return "[[property]]";
    }
    if (beforeThis(currentIndex, "communities", array)) {
      return "[[community]]";
    }
  }
  /* LOG BOOK Ends */

  /* LOG BOOK ADD Start */
  if (type === "log_book_add") {
    if (beforeThis(currentIndex, "properties", array)) {
      return "[[property]]";
    }
    if (beforeThis(currentIndex, "communities", array)) {
      return "[[community]]";
    }
  }
  /* LOG BOOK ADD Ends */

  /* LOG BOOK SIGNOUT DETAILS Start */
  if (type === "visitor_log_signOut_detail") {
    if (beforeThis(currentIndex, "properties", array)) {
      return "[[property]]";
    }
    if (beforeThis(currentIndex, "communities", array)) {
      return "[[community]]";
    }

    if (beforeThis(currentIndex, "sign-out", array)) {
      return "Visitor Log Detail";
    }
  }
  /* LOG BOOK SIGNOUT DETAILS Ends */

  /* LOG BOOK DETAILS Start */
  if (type === "visitor_log_detail") {
    if (beforeThis(currentIndex, "properties", array)) {
      return "[[property]]";
    }
    if (beforeThis(currentIndex, "communities", array)) {
      return "[[community]]";
    }

    if (beforeThis(currentIndex, "visitor-logs", array)) {
      return "Visitor Log Detail";
    }
  }
  /* LOG BOOK DETAILS Ends */

  // LOG BOOK MODULE

  return changeKeyLetters(key);
};

export const changeKeyLetters = (key) => {
  // return key?.charAt(0)?.toUpperCase() + key?.slice(1);

  switch (key) {
    case "properties":
      return "Properties";

    case "communities":
      return "Communities";

    case "units":
      return "Units";
    // case "teams":
    //   return "Teams";

    // case "tenants":
    //   return "Manage Tenants";

    case "license":
      return "Assign License";

    case "maintenances":
      return "Maintenances";

    case "new":
      return "New";

    case "renewals":
      return "Renewals";

    case "Move%20In":
      return "Move In";

    case "move-out":
      return "Move Out";

    case "internal":
      return "Internal Maintenances";

    case "invoicing":
      return "Invoicing";

    case "rent":
      return "Rent";

    case "deposits":
      return "Deposits";

    case "application":
      return "Application";

    case "commission":
      return "Commission";

    case "others":
      return "Others";

    case "annoucements":
      return "Annoucements";

    case "violations":
      return "Violations";

    case "lost_and_found":
      return "Lost And Found";

    // case "settings":
    //   return "Property Settings";

    case "dashboard":
      return "Dashboard Reports";

    case "occupancy":
      return "Occupancy";

    case "contracts":
      return "Contracts";

    case "financial":
      return "Financial";

    case "tenant-maintenance":
      return "Tenant Maintenance";

    case "internal-maintenance":
      return "Internal Maintenance";

    case "security":
      return "Security";

    case "spare-parts":
      return "Spare Parts";

    case "ratings":
      return "Ratings";

    case "move-in":
      return "Move In";

    case "edit":
      return "Edit";

    case "add":
      return "Add";

    // case "existing":
    //   return "Existing";

    case "ratingAndReviews":
      return "Rating And Reviews";

    case "access_pass":
      return "Visitor Access Pass";

    case "visitor-logs":
      return "Visitors Log Book";

    default:
      return key;
  }
};

export const beforeThis = (currIndex, prevValue, array) => {
  const previndex = currIndex - 1;
  if (previndex >= 0) {
    if (array[previndex] === prevValue) {
      return true;
    }
    return false;
  }
};

export const beforeTwo = (currIndex, prevValue, array) => {
  const previndex = currIndex - 2;
  if (previndex >= 0) {
    if (array[previndex] === prevValue) {
      return true;
    }
    return false;
  }
};

export const beforeThree = (currIndex, prevValue, array) => {
  const previndex = currIndex - 3;
  if (previndex >= 0) {
    if (array[previndex] === prevValue) {
      return true;
    }
    return false;
  }
};

export const afterThis = (currIndex, nextValue, array) => {
  const nextindex = currIndex + 1;
  if (nextindex < array?.length) {
    if (array[nextindex] === nextValue) {
      return true;
    }
    return false;
  }
};

export const renderQueryStrings = (key, currentIndex, array, type, from) => {};
