export const formValues = {
  department: "",
  mobile: "",
};

export const validations = (values) => {
  const errorFields = {};

  // Department Starts
  if (values.department === "") {
    errorFields.department = "Department is required";
  }
  // Department Ends

  // Contact Starts
  if (values.mobile === "") {
    errorFields.mobile = "Contact is required";
  }
  // Contact Ends

  return errorFields;
};

export const loadInitialValue = (contact, setInitialFormValues) => {
  setInitialFormValues({
    department: contact?.department || "",
    mobile: (contact?.countrycode || "") + " " + (contact?.mobile || ""),
  });
};
