/**
 *
 */

import React, { useState, useEffect, useRef } from "react";

/* Import configuration starts */
import { Formik } from "formik";
import { useSelector, useDispatch } from "react-redux";
import GLOBAL from "../../../../setup/constants/global";
import {
  fieldValue,
  typeData,
  validation,
  costCoveredByData,
  costCoveredByDataT1,
  addPartDetail,
  removePartDetail,
  prefill,
} from "./helper";
import { useParams } from "react-router-dom";
/* Import configuration ends */

/* Import redux slices component starts */
import {
  addQuote,
  updateQuote,
  addT2Quote,
} from "../../../../setup/store/slices/moveInSlice";
import {
  addInternalQuote,
  addT2InternalQuote,
  addInternalQuoteT3,
} from "../../../../setup/store/slices/internalMaintenanceSlice";
import {
  fetchQuoteTypes,
  getQuoteTypes,
} from "../../../../setup/store/slices/masterSlice";
import {
  showErrorAlert,
  showSuccessAlert,
} from "../../../../setup/store/slices/globalAlertSlice";
import {
  fetchPropertyDetailByID,
  getPropertyDetail,
} from "../../../../setup/store/slices/propertySlice";
import { setLoading } from "../../../../setup/store/slices/unpersistedSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
import { Modal } from "react-bootstrap";
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import CustomButton from "../../../ui/button/CustomButton/CustomButton";
import TextButton from "../../../ui/button/TextButton/TextButton";
import CustomCurrencyInput from "../../../ui/input/CustomCurrencyInput/CustomCurrencyInput";
import CustomTextArea from "../../../ui/input/CustomTextArea/CustomTextArea";
import CustomTextInput from "../../../ui/input/CustomTextInput/CustomTextInput";
import CustomSelectInput from "../../../ui/input/CustomSelectInput/CustomSelectInput";
import CustomNumberInput from "../../../ui/input/CustomNumberInput/CustomNumberInput";
import CustomDateInput from "../../../ui/input/CustomDateInput/CustomDateInput";
import CustomRadioInput from "../../../ui/input/CustomRadioInput/CustomRadioInput";
/* Import local pages and component ends */

import "./style.scss";
import { getUserTheme } from "../../../../setup/store/slices/authSlice";

/* Component starts */
const SparePartQuoteModal = (props) => {
  /* Props destructuring starts */
  const {
    maintenance_id,
    showModal,
    size = "lg",
    onClose,
    maintenanceType,
    user,
    unitCard,
    type,
    edit,
    data = null,
    currentLog,
    callback = null,
    isT2,
    isT1,
    isT3,
    canSelectTenant = false,
    tenantList = [],
    currency = "",
    tenantId = "",
    maintenance,
    isTPM,
  } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [formValue, setFormValue] = useState(fieldValue);
  const [partDetails, setPartDetails] = useState([0]);
  const [totalPrice, setTotalPrice] = useState(0);
  const [totalCost, setTotalCost] = useState(0);
  const [totalCostList, setTotalCostList] = useState([0]);
  const [costCoveredByOptions, setCostCoveredByOptions] = useState([]);
  const [costCoveredBy, setCostCoveredBy] = useState("Other");
  const [selectedTenant, setSelectedTenant] = useState(null);
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const dispatch = useDispatch();
  const formRef = useRef();
  const { propertyId } = useParams();
  const quoteTypes = useSelector(getQuoteTypes);
  const property = useSelector(getPropertyDetail);
  const userTheme = useSelector(getUserTheme);
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  const logType = currentLog?.log_type;
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  const calculateTotalEstimation = () => {
    let total = 0;
    totalCostList.forEach((item) => {
      total += item;
    });

    setTotalCost(total);
  };

  // On updating move in type spare parts quotations
  const moveIn = async (values) => {
    // Creating or collecting payload data to be sent
    const payload = {
      maintenance_id,
      property_id: propertyId,
      // spare_part_request_number: values?.spare_part_request_number,
      // spare_part_quote_number: values?.spare_part_quote_number,
      notes: values?.notes,
      total_cost: totalCost + "",
      type: maintenanceType || "",
      unit_card: unitCard?._id || "",
      part: [],
      isThirdParty: maintenance?.assigned_role?.name === "Third Party Manager",
    };
    // if (edit) {
    //   payload[`quote_id`] = data._id;
    // }
    //  Quotation Type
    if (type === "move_in") {
      payload[`quotation_type`] =
        logType.toLowerCase() === "additional" ? "additional" : "quoatation";
    } else {
      payload[`quotation_type`] = "quoatation";
    }
    // Parts List
    partDetails.forEach((item, index) => {
      const partEach = {};
      partEach["type"] = values[`type_${item}`];
      if (values[`type_${item}`] === "Service Quote") {
        partEach["service_name"] = values[`service_name_${item}`];
        partEach["service_amount"] = values[`service_amount_${item}`];
      } else {
        // partEach["cost_covered_by"] = values[`cost_covered_by_${item}`];
        partEach["part_number"] = values[`part_number_${item}`];
        partEach["part_name"] = values[`part_name_${item}`];
        partEach["description"] = values[`description_${item}`];
        partEach["company_name"] = values[`type_${item}`];
        partEach["quantity"] = values[`quantity_${item}`];
        partEach["vendor"] = values[`vendor_${item}`];
        partEach["estimated_delivery_time"] =
          values[`estimated_delivery_time_${item}`];
        partEach["warrenty"] = values[`warrenty_${item}`];
        partEach["price"] = values[`price_${item}`];
      }
      payload.part.push(partEach);
    });

    if (!isT3) {
      payload["paid_by"] =
        costCoveredBy === GLOBAL.USER_ROLE.TENANT ? "tenant" : "owner";
      payload["payer_description"] = values?.invoice_description || "";
      payload["payer_amount"] = values?.invoice_amount;
    }

    if (
      type === "internal" &&
      costCoveredBy !== "owner" &&
      selectedTenant?.tenant_id
    ) {
      //   payload["tenant_id"] = tenantId;
      // } else {
      payload["tenant_id"] = selectedTenant?.tenant_id;
      payload["unit_id"] = selectedTenant?.unit_id;
    }

    console.log("Payload", payload);

    // Backend response. Try, Catch
    try {
      let result;
      // if (edit) {
      //   result = await dispatch(updateQuote(payload));
      // } else {
      // }
      if (type === "internal") {
        if (isT2) {
          result = await dispatch(addT2InternalQuote(payload));
        } else if (isT3) {
          result = await dispatch(addInternalQuoteT3(payload));
        } else {
          result = await dispatch(addInternalQuote(payload));
        }
      } else {
        if (isT2) {
          result = await dispatch(addT2Quote(payload));
        } else {
          result = await dispatch(addQuote(payload));
        }
      }
      console.log("Response", result);

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          dispatch(
            showErrorAlert({
              title: "Error!",
              description: result.payload,
            })
          );
          break;

        case GLOBAL.FULFILLED:
          dispatch(
            showSuccessAlert({
              title: "Success!",
              description: result.payload.message,
            })
          );
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };

  // On updating normal type maintenance quotation
  const normal = (values) => {};

  // On submitting spare part quotation form
  const onSubmit = async (values) => {
    onClose();
    /**
     * Same API is been used for Normal maintenance and Internal Maintenance also
     * that was used in Move In maintenance
     */
    if (type === "move_in" || type === "normal" || type === "internal") {
      await moveIn(values);
    } else if (type === "normal_maintenance") {
    }
  };

  // This function is just for testing purpose to see if all the values are correctly being captured
  const onSubmitTesting = (v) => {
    console.log("Values captured from Spare Part Quotation form --> ", v);
  };
  /* Component function definition ends */

  /* Component useEffect starts */
  useEffect(() => {
    if (edit && data && data?.part && data?.part?.length > 0) {
      prefill(data, setFormValue, setPartDetails, setTotalCost, setTotalCostList);
    }
  }, [edit, data]);

  useEffect(() => {
    (async () => {
      try {
        await dispatch(setLoading(true));
        await dispatch(fetchQuoteTypes());
        await dispatch(fetchPropertyDetailByID({ property_id: propertyId }));
        await dispatch(setLoading(false));
      } catch (e) {}
    })();
  }, []);

  useEffect(() => {
    if (isT1) {
      if (costCoveredByDataT1?.length > 0) {
        setCostCoveredByOptions([...costCoveredByDataT1]);
      }
    } else {
      if (costCoveredByData?.length > 0) {
        setCostCoveredByOptions([...costCoveredByData]);
      }
    }
  }, [isT1, costCoveredByData, costCoveredByDataT1]);

  useEffect(() => {
    calculateTotalEstimation();
  }, [totalCostList]);
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <Modal
      size={size}
      centered
      show={showModal}
      className="quote-generate-modal-wrapper"
    >
      <Modal.Body
        className={`${
          userTheme === "dark"
            ? "bg_darkTheme"
            : "bg_lightTheme font_lightTheme"
        }`}
      >
        <div className="modal-header-custom">
          <p
            className={`font_l fw_6 ${
              userTheme !== "dark" && "font_blue"
            } font_white text-center`}
          >
            Spare Part Quotation
          </p>
        </div>
        <div className="modal-body-custom">
          <Formik
            onSubmit={onSubmit}
            initialValues={formValue}
            validate={(values) =>
              validation(
                values,
                partDetails,
                setTotalPrice,
                costCoveredBy === GLOBAL.USER_ROLE.TENANT,
                canSelectTenant,
                tenantList?.length || 0,
                isTPM,
                isT3
              )
            }
            validateOnBlur={true}
            enableReinitialize={true}
            innerRef={formRef}
          >
            {({
              values,
              errors,
              touched,
              handleBlur,
              handleChange,
              handleSubmit,
              setFieldValue,
              setFieldTouched,
            }) => (
              <form onSubmit={handleSubmit}>
                {/* Form Fields */}
                <div className="form-wrapper form-wrapper-flex">
                  {/* Input for Spare Part Request Number */}
                  {/* <div className="form-group">
                    <CustomTextInput
                      label="Spare Part Request Number"
                      name={`spare_part_request_number`}
                      value={values[`spare_part_request_number`]}
                      errors={errors[`spare_part_request_number`]}
                      touched={touched[`spare_part_request_number`]}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                    />
                  </div> */}

                  {/* Input for Spare Part Quote Number */}
                  {/* <div className="form-group">
                    <CustomTextInput
                      label="Spare Part Quote Number"
                      name={`spare_part_quote_number`}
                      value={values[`spare_part_quote_number`]}
                      errors={errors[`spare_part_quote_number`]}
                      touched={touched[`spare_part_quote_number`]}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                    />
                  </div> */}

                  {/* <div className="form-group form-group-full">
                    <CustomTextArea
                      label="Notes"
                      name={`notes`}
                      value={values[`notes`]}
                      errors={errors[`notes`]}
                      touched={touched[`notes`]}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      required={false}
                    />
                  </div> */}

                  {/* Spare Part Form List */}
                  <div className="form-list-wrapper w_100">
                    {partDetails?.map((item, index) => (
                      <div key={index} className="form-each">
                        <h3 className="w_100 font_blue fw_6 font_m">
                          Part #{index + 1}
                        </h3>

                        {/* Input for Type */}
                        <div className="form-group">
                          <CustomSelectInput
                            label="Type"
                            name={`type_${item}`}
                            handleBlur={handleBlur}
                            handleChange={handleChange}
                            options={quoteTypes}
                            value={values[`type_${item}`]}
                            errors={errors[`type_${item}`]}
                            touched={touched[`type_${item}`]}
                            valueExtractor={(d) => d?.name}
                          />
                        </div>

                        {values[`type_${item}`] === "Part Quote" ? (
                          <>
                            {/* Input for Cost Covered By */}
                            {/* <div className="form-group">
                              <CustomSelectInput
                                label="Cost Covered By"
                                name={`cost_covered_by_${item}`}
                                handleBlur={handleBlur}
                                handleChange={handleChange}
                                options={costCoveredByOptions}
                                value={values[`cost_covered_by_${item}`]}
                                errors={errors[`cost_covered_by_${item}`]}
                                touched={touched[`cost_covered_by_${item}`]}
                                valueExtractor={(d) => d}
                              />
                            </div> */}

                            {/* Input for Part Number */}
                            <div className="form-group">
                              <CustomTextInput
                                label="Part Number"
                                name={`part_number_${item}`}
                                value={values[`part_number_${item}`]}
                                errors={errors[`part_number_${item}`]}
                                touched={touched[`part_number_${item}`]}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                              />
                            </div>

                            {/* Input for Part Name */}
                            <div className="form-group form-group-full">
                              <CustomTextInput
                                label="Part Name"
                                name={`part_name_${item}`}
                                value={values[`part_name_${item}`]}
                                errors={errors[`part_name_${item}`]}
                                touched={touched[`part_name_${item}`]}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                              />
                            </div>

                            {/* Input for Description */}
                            <div className="form-group form-group-full">
                              <CustomTextArea
                                label="Description"
                                name={`description_${item}`}
                                value={values[`description_${item}`]}
                                errors={errors[`description_${item}`]}
                                touched={touched[`description_${item}`]}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                              />
                            </div>

                            {/* Input for Company Name */}
                            <div className="form-group">
                              <CustomTextInput
                                label="Company Name"
                                name={`company_name_${item}`}
                                value={values[`company_name_${item}`]}
                                errors={errors[`company_name_${item}`]}
                                touched={touched[`company_name_${item}`]}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                              />
                            </div>

                            {/* Input for Quantity */}
                            <div className="form-group">
                              <CustomNumberInput
                                label="Quantity"
                                name={`quantity_${item}`}
                                value={values[`quantity_${item}`]}
                                errors={errors[`quantity_${item}`]}
                                touched={touched[`quantity_${item}`]}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                disableDot={true}
                              />
                            </div>

                            {/* Input for Vendor */}
                            <div className="form-group">
                              <CustomTextInput
                                label="Vendor"
                                name={`vendor_${item}`}
                                value={values[`vendor_${item}`]}
                                errors={errors[`vendor_${item}`]}
                                touched={touched[`vendor_${item}`]}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                              />
                            </div>

                            {/* Input for Estimated Delivery Time */}
                            <div
                              className={`form-group ${
                                (userTheme === "light" ||
                                  userTheme === "automatic") &&
                                "otherTheme"
                              } `}
                            >
                              <CustomDateInput
                                label="Estimated Delivery Time"
                                name={`estimated_delivery_time_${item}`}
                                setFieldTouched={setFieldTouched}
                                setFieldValue={setFieldValue}
                                value={
                                  values[`estimated_delivery_time_${item}`]
                                }
                                errors={
                                  errors[`estimated_delivery_time_${item}`]
                                }
                                touched={
                                  touched[`estimated_delivery_time_${item}`]
                                }
                              />
                            </div>

                            {/* Input for Warranty */}
                            <div className="form-group form-group-full">
                              <CustomTextArea
                                label="Warranty"
                                name={`warrenty_${item}`}
                                value={values[`warrenty_${item}`]}
                                errors={errors[`warrenty_${item}`]}
                                touched={touched[`warrenty_${item}`]}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                              />
                            </div>

                            {/* Input for Price */}
                            <div className="form-group">
                              <CustomCurrencyInput
                                label={`Price (${property?.currency})`}
                                name={`price_${item}`}
                                setFieldTouched={setFieldTouched}
                                setFieldValue={setFieldValue}
                                value={values[`price_${item}`]}
                                errors={errors[`price_${item}`]}
                                touched={touched[`price_${item}`]}
                                currency={property?.currency}
                                onCustomChange={(v) => {
                                  setTotalCostList((p) => {
                                    const d = [...p];
                                    d[index] = v !== "" ? parseFloat(v) : 0;
                                    return d;
                                  });
                                }}
                              />
                            </div>
                          </>
                        ) : values[`type_${item}`] === "Service Quote" ? (
                          <>
                            {/* Input for Service Name */}
                            <div className="form-group">
                              <CustomTextInput
                                label="Service Name"
                                name={`service_name_${item}`}
                                value={values[`service_name_${item}`]}
                                errors={errors[`service_name_${item}`]}
                                touched={touched[`service_name_${item}`]}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                              />
                            </div>

                            {/* Input for Service Amount */}
                            <div className="form-group">
                              <CustomCurrencyInput
                                label={`Service Amount (${property?.currency})`}
                                name={`service_amount_${item}`}
                                setFieldTouched={setFieldTouched}
                                setFieldValue={setFieldValue}
                                value={values[`service_amount_${item}`]}
                                errors={errors[`service_amount_${item}`]}
                                touched={touched[`service_amount_${item}`]}
                                currency={property?.currency}
                                onCustomChange={(v) => {
                                  setTotalCostList((p) => {
                                    const d = [...p];
                                    d[index] = v !== "" ? parseFloat(v) : 0;
                                    return d;
                                  });
                                }}
                              />
                            </div>
                          </>
                        ) : null}

                        {/* Remove Item */}
                        <div className="remove-btn w_100">
                          {partDetails.length > 1 && (
                            <TextButton
                              onClick={() => {
                                removePartDetail(
                                  formRef,
                                  setFormValue,
                                  partDetails,
                                  setPartDetails,
                                  index,
                                  setTotalPrice
                                );
                                setTotalCostList((p) => {
                                  const d = [...p];
                                  d.splice(index, 1);
                                  return d;
                                });
                              }}
                              className="fw_6 font_m font_red v_middle"
                            >
                              Remove
                            </TextButton>
                          )}
                        </div>
                      </div>
                    ))}
                  </div>

                  {/* Add or Remove Field Action */}
                  <div className="action-btn w_100 text-end">
                    <TextButton
                      onClick={() => {
                        addPartDetail(
                          formRef,
                          setFormValue,
                          partDetails,
                          setPartDetails
                        );
                        setTotalCostList((p) => [...p, 0]);
                      }}
                      className="fw_6 font_m font_blue v_middle"
                    >
                      + Add More
                    </TextButton>
                  </div>
                </div>

                <div className="total w_100">
                  <p
                    className={`left font_xl fw_6 ${
                      (userTheme === "light" || userTheme === "automatic") &&
                      "font_lightTheme"
                    }`}
                  >
                    Total
                  </p>
                  <p
                    className={`right font_xl fw_6 ${
                      (userTheme === "light" || userTheme === "automatic") &&
                      "font_lightTheme"
                    }`}
                  >
                    {totalCost?.toLocaleString("en-US")} {property?.currency}
                  </p>
                </div>

                <hr />

                {!isTPM && !isT3 && (
                  <div className="cost-cover-by-section">
                    <p
                      className={`font_s fw_5 please-select-text ${
                        userTheme !== "dark" && "font_grey"
                      }`}
                    >
                      Please select who is going to cover the cost for the items
                      or services mentioned in the quotation.
                    </p>

                    <div className="cost-covered-by-radio-button">
                      <CustomRadioInput
                        className="radio-group"
                        labelClass="font_xs fw_5"
                        label={isT1 ? "Owner" : "Management"}
                        isSelected={costCoveredBy === "Other"}
                        onSelecting={() => setCostCoveredBy("Other")}
                      />
                      {type !== "move_in" &&
                        typeof canSelectTenant === "boolean" &&
                        ((canSelectTenant && tenantList?.length > 0) ||
                          !canSelectTenant) && (
                          <CustomRadioInput
                            className="radio-group"
                            labelClass="font_xs fw_5"
                            label="Tenant"
                            isSelected={
                              costCoveredBy === GLOBAL.USER_ROLE.TENANT
                            }
                            onSelecting={() => {
                              setCostCoveredBy(GLOBAL.USER_ROLE.TENANT);
                            }}
                          />
                        )}
                    </div>

                    {costCoveredBy === GLOBAL.USER_ROLE.TENANT && (
                      <div className="invoice-generate-form">
                        <p
                          className={`font_m fw_5 font_yellow generate-inv-txt`}
                          // className={`font_s fw_5 please-select-text ${
                          //   userTheme !== "dark" && "font_grey"
                          // }`}
                        >
                          Generate invoice for tenant
                        </p>
                        <div className="fields">
                          {/* Amount */}
                          <div className="form-group">
                            <CustomCurrencyInput
                              name="invoice_amount"
                              label={`Amount (${property?.currency})`}
                              setFieldValue={setFieldValue}
                              setFieldTouched={setFieldTouched}
                              touched={touched.invoice_amount}
                              errors={errors.invoice_amount}
                              value={values.invoice_amount}
                              currency={property?.currency}
                              required={true}
                            />
                          </div>

                          {/* Select Tenant */}
                          {typeof canSelectTenant === "boolean" &&
                            canSelectTenant &&
                            tenantList.length > 0 && (
                              <div className="form-group">
                                <CustomSelectInput
                                  label="Tenant"
                                  name={`unit_id`}
                                  handleBlur={handleBlur}
                                  handleChange={handleChange}
                                  options={tenantList}
                                  value={values[`unit_id`]}
                                  errors={errors[`unit_id`]}
                                  touched={touched[`unit_id`]}
                                  valueExtractor={(d) =>
                                    `${d?.tenant_name} (${d?.unit_name})`
                                  }
                                  optionValueExtractor={(d) => `${d?.unit_id}`}
                                  customOnchange={(v) => {
                                    if (v) {
                                      setSelectedTenant(v);
                                    } else {
                                      setSelectedTenant(null);
                                    }
                                  }}
                                />
                              </div>
                            )}

                          {/* Tenant and Unit Details */}
                          {canSelectTenant &&
                            tenantList?.length > 0 &&
                            selectedTenant && (
                              <div className="tenant-details">
                                <div className="custom-line">
                                  <p
                                    className={`label font_s fw_5 ${
                                      userTheme !== "dark" && "font_grey"
                                    }`}
                                  >
                                    Tenant Name
                                  </p>
                                  <p className="value font_s font_blue fw_5">
                                    {selectedTenant?.tenant_name}
                                  </p>
                                </div>
                                <div className="custom-line">
                                  <p
                                    className={`label font_s fw_5 ${
                                      userTheme !== "dark" && "font_grey"
                                    }`}
                                  >
                                    Unit Name
                                  </p>
                                  <p className="value font_s font_blue fw_5">
                                    {selectedTenant?.unit_name}
                                  </p>
                                </div>
                                <div className="custom-line">
                                  <p
                                    className={`label font_s fw_5 ${
                                      userTheme !== "dark" && "font_grey"
                                    }`}
                                  >
                                    Unit Number
                                  </p>
                                  <p className="value font_s font_blue fw_5">
                                    {selectedTenant?.unit_number}
                                  </p>
                                </div>
                              </div>
                            )}

                          {/* Description */}
                          {/* <div className="form-group form-group-full">
                          <CustomTextArea
                            name="invoice_description"
                            label="Description"
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            touched={touched.invoice_description}
                            errors={errors.invoice_description}
                            value={values.invoice_description}
                            required={true}
                          />
                        </div> */}
                        </div>
                      </div>
                    )}

                    {typeof canSelectTenant === "boolean" &&
                      ((canSelectTenant && tenantList?.length > 0) ||
                        !canSelectTenant ||
                        type === "move_in") && (
                        <div className="form-group form-group-full invoice-description">
                          <CustomTextArea
                            name="invoice_description"
                            label="Description"
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            touched={touched.invoice_description}
                            errors={errors.invoice_description}
                            value={values.invoice_description}
                            required={true}
                          />
                        </div>
                      )}
                  </div>
                )}

                {/* Submit and Cancel */}
                <div className="button-wrapper">
                  <>
                    <CustomButton
                      variant="red"
                      size="m"
                      onClick={onClose}
                      className="btn1"
                    >
                      Cancel
                    </CustomButton>
                    <CustomButton type="submit" size="m">
                      Submit
                    </CustomButton>
                  </>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </Modal.Body>
    </Modal>
  );
};
/* Component ends */

export default SparePartQuoteModal;
