/**
 *
 * Slice to manage Party Invoice
 */

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API_URL } from "../../config/devKeys";

import axios, { axiosInstance } from "../axios";
import {
  setLoading,
  setShowUploadProgress,
  setUploadProgress,
} from "./unpersistedSlice";
import { PLATFORM } from "../../platformUtil";

const initialState = {
  partyInvoices: [],
  partyInvoicesAggr: [],
  invoice: null,
  quotesList: [],
};

// FETCH PARTY INVOICES BASED ON PROPERTY_ID
export const fetchPartyInvoices = createAsyncThunk(
  "party_invoice/list_party_invoice",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const { data } = await axios.post(
        API_URL + "/party_invoice/list_party_invoice",
        // "http://localhost:3000/party_invoice/list_party_invoice",
        { ...payload },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      dispatch(setLoading(false));
      if (data.success) {
        return data.data;
      } else {
        rejectWithValue(data.error);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

// GET QUOTES LIST FOR ADDING PARTY INVOICE - TYPE = "SPARE PARTS"
export const fetchQuotesListForPartyInvoices = createAsyncThunk(
  "party_invoice/get_quotes_party_invoice",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const { data } = await axios.get(
        API_URL +
          `/party_invoice/get_quotes_party_invoice?property_id=${payload}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      dispatch(setLoading(false));
      if (data.success) {
        return data.data;
      } else {
        rejectWithValue(data.error);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

// ADD PARTY INVOICE ON PROPERTY_ID
export const createPartyInvoice = createAsyncThunk(
  "party_invoice/add_party_invoice",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const { data } = await axios.post(
        API_URL + "/party_invoice/add_party_invoice",
        { ...payload },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
            "Content-Type": "application/json",
          },
        }
      );
      dispatch(setLoading(false));
      if (data.success) {
        await dispatch(
          fetchPartyInvoices({
            property_id: payload.property_id,
            type: payload.type,
          })
        );
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

// FETCH PARTY INVOICES BASED ON INVOICE_ID
export const fetchPartyInvoiceById = createAsyncThunk(
  "party_invoice/get_party_invoice",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const { data } = await axios.post(
        API_URL + "/party_invoice/get_party_invoice",
        { invoice_id: payload },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      dispatch(setLoading(false));
      if (data.success) {
        return data.data;
      } else {
        return rejectWithValue(data);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

// UPDATE PARTY INVOICES BASED ON INVOICE_ID
export const updatePartyInvoice = createAsyncThunk(
  "party_invoice/update_party_invoice_status",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const formData = new FormData();
      Object.keys(payload).map((key) => {
        if (key == "invoice_doc") {
          if (PLATFORM !== "web") {
            formData.append(key, {
              name: payload[key].name,
              type: payload[key].type,
              uri:
                PLATFORM === "android"
                  ? payload[key].uri
                  : payload[key].uri.replace("file://", ""),
            });
          } else {
            formData.append(key, payload[key], payload[key].name);
          }
        } else {
          formData.append(key, payload[key]);
        }
      });
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("user_token")}`,
      };
      if (PLATFORM !== "web") {
        headers["Content-Type"] = "multipart/form-data";
      }

      // const response = await fetch(
      //   API_URL + "/party_invoice/update_party_invoice_status",
      //   {
      //     method: "POST",
      //     headers: headers,
      //     body: formData,
      //   }
      // );
      // const data = await response.json();
      dispatch(setShowUploadProgress(true));
      const config = {
        onUploadProgress: ({ loaded, total }) => {
          const progress = (loaded / total) * 100;
          dispatch(setUploadProgress(progress));
        },
        method: "POST",
        url: "/party_invoice/update_party_invoice_status",
        headers,
        transformRequest: (data, headers) => {
          return formData;
        },
        data: formData,
        responseType: "json",
      };

      // const data = await response.json();

      const { data } = await axiosInstance.request(config);

      dispatch(setLoading(false));
      if (data.success) {
        dispatch(
          fetchPartyInvoices({
            type: payload.type,
            property_id: payload?.property_id,
          })
        );
        dispatch(
          fetchPartyInvoicesAggregationBasedOnProperty({
            type: payload.type,
            property_id: payload?.property_id,
          })
        );
        return data.data;
      } else {
        return rejectWithValue(data);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

// FETCH INVOICES PARTY AGGREGATION LIST BASED ON PROPERTY ID - { CLIENT }
export const fetchPartyInvoicesAggregationBasedOnProperty = createAsyncThunk(
  "party_invoice/party_invoice_aggrigation",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const { data } = await axios.post(
        API_URL + "/party_invoice/party_invoice_aggrigation",
        // "http://localhost:3000/party_invoice/party_invoice_aggrigation",
        { ...payload },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      dispatch(setLoading(false));
      if (data.success) {
        return data.data;
      } else {
        rejectWithValue(data.error);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

export const updtePartyInvCheque = createAsyncThunk(
  "party_invoice/update_party_invoice_cheque_status",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const formData = new FormData();
      Object.keys(payload).map((key) => {
        if (key == "invoice_doc") {
          if (PLATFORM !== "web") {
            formData.append(key, {
              name: payload[key].name,
              type: payload[key].type,
              uri:
                PLATFORM === "android"
                  ? payload[key].uri
                  : payload[key].uri.replace("file://", ""),
            });
          } else {
            formData.append(key, payload[key], payload[key].name);
          }
        } else {
          formData.append(key, payload[key]);
        }
      });
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("user_token")}`,
      };
      if (PLATFORM !== "web") {
        headers["Content-Type"] = "multipart/form-data";
      }
      dispatch(setShowUploadProgress(true));
      const config = {
        onUploadProgress: ({ loaded, total }) => {
          const progress = (loaded / total) * 100;
          dispatch(setUploadProgress(progress));
        },
        method: "POST",
        url: "/party_invoice/update_party_invoice_cheque_status",
        headers,
        transformRequest: (data, headers) => {
          return formData;
        },
        data: formData,
        responseType: "json",
      };

      const { data } = await axiosInstance.request(config);

      dispatch(setLoading(false));

      if (data.success) {
        // dispatch(fetchPropertyInvoices({ type: payload.type }));
        return data.data;
      } else {
        return rejectWithValue(data);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

export const partyInvoiceSlice = createSlice({
  name: "partyInvoice",
  initialState,
  extraReducers: {
    [fetchPartyInvoices.rejected]: (state, action) => {
      state.partyInvoices = [];
    },
    [fetchPartyInvoices.fulfilled]: (state, action) => {
      state.partyInvoices = action.payload;
    },
    [fetchPartyInvoiceById.rejected]: (state, action) => {
      state.invoice = null;
    },
    [fetchPartyInvoiceById.fulfilled]: (state, action) => {
      state.invoice = action.payload;
    },

    [fetchQuotesListForPartyInvoices.rejected]: (state, action) => {
      state.quotesList = [];
    },
    [fetchQuotesListForPartyInvoices.fulfilled]: (state, action) => {
      state.quotesList = action.payload;
    },

    [fetchPartyInvoicesAggregationBasedOnProperty.rejected]: (
      state,
      action
    ) => {
      state.partyInvoicesAggr = [];
    },
    [fetchPartyInvoicesAggregationBasedOnProperty.fulfilled]: (
      state,
      action
    ) => {
      state.partyInvoicesAggr = action.payload;
    },
  },
});

export const getPartyInvoices = (state) => state.partyInvoice.partyInvoices;

// PARTY INVOICE AGGREGATION
export const getPartyInvoicesAggregation = (state) =>
  state.partyInvoice.partyInvoicesAggr;

// GET QUOTES LIST FOR ADDING PARTY INVOICE - TYPE = "SPARE PARTS"
export const getQuotesList = (state) => state.partyInvoice.quotesList;

export const getPartyInvoice = (state) => state.partyInvoice.invoice;

export default partyInvoiceSlice.reducer;
