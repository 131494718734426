/**
 * @auther Abdul Ahad <abdulahad.ss@smartdatainc.net>
 * Slice to handle notifications
 */
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../axios";
import { API_URL } from "../../config/devKeys";
import { setLoading } from "./unpersistedSlice";

const initialState = {
  notifications: [],
  token: "",
};

export const fetchNotifications = createAsyncThunk(
  "notification/list",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const { data } = await axios.get(
        API_URL + `/notification/list?user_id=${payload}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      dispatch(setLoading(false));
      return data.data;
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

export const markNotificationSeen = createAsyncThunk(
  "notification/seen",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const { data } = await axios.patch(
        API_URL + "/notification/seen?id=" + payload.id,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      return data.data;
    } catch (error) {
      return rejectWithValue(error?.response);
    }
  }
);

export const registerToken = createAsyncThunk(
  "notification/register_token",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const { data } = await axios.patch(
        API_URL + "/notification/register_token",
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      return data.data;
    } catch (error) {
      return rejectWithValue(error?.response);
    }
  }
);

export const deleteNotification = createAsyncThunk(
  "notification/delete",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const { data } = await axios.delete(
        API_URL + "/notification/delete?id=" + payload.id,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      return data.data;
    } catch (error) {
      return rejectWithValue(error?.response);
    }
  }
);

export const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    clearNotificationToken: (state, action) => {
      state.token = "";
    },
    setNotificationToken: (state, action) => {
      state.token = action.payload;
    },
  },
  extraReducers: {
    [fetchNotifications.fulfilled]: (state, action) => {
      state.notifications = action.payload;
    },
    [fetchNotifications.rejected]: (state, action) => {
      state.notifications = [];
    },
  },
});

export const { clearNotificationToken, setNotificationToken } =
  notificationSlice.actions;
export const getNotificationToken = (state) => state.notification.token;

export default notificationSlice.reducer;
